import { Avatar } from "@mui/material";
import { BootstrapTooltip } from "components/TicketCards";
import { VerifiedBadge } from "components/Vectors/SmallTickIcon";
import { Link } from "react-router-dom";

function UserAvatar({
  src = "",
  showBadge = false,
  size = 96,
  TooltipTitle = "Verified Professional",
  toHref,
  ...props
}) {
  return (
    <BootstrapTooltip
      title={
        TooltipTitle && showBadge ? (
          <span className="flex gap-1 py-2">
            <span>{TooltipTitle}</span>{" "}
            <VerifiedBadge width={16} height={16} color="#AAAAAA" />
          </span>
        ) : (
          ""
        )
      }
      placement="top"
    >
      {toHref ?
        <Link to={toHref} className={`relative w-[${size}px] h-[${size}px]`}>
          <Avatar
            src={src}
            alt={props?.alt || "user avatar"}
            sx={{
              width: size,
              height: size,
            }}
            className="w-full"
          />
          <div
            className={`absolute top-0 right-0 ${showBadge ? "block" : "hidden"}`}
          >
            <VerifiedBadge
              width={size / 4 >= 14 ? size / 4 : "14px"}
              height={size / 4 >= 14 ? size / 4 : "14px"}
            />
          </div>
        </Link>
        :
        <div className={`relative w-[${size}px] h-[${size}px]`}>
          <Avatar
            src={src}
            alt={props?.alt || "user avatar"}
            sx={{
              width: size,
              height: size,
            }}
            className="w-full"
          />
          <div
            className={`absolute top-0 right-0 ${showBadge ? "block" : "hidden"}`}
          >
            <VerifiedBadge
              width={size / 4 >= 14 ? size / 4 : "14px"}
              height={size / 4 >= 14 ? size / 4 : "14px"}
            />
          </div>
        </div>
      }
    </BootstrapTooltip>
  );
}

export default UserAvatar;
