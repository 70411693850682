import {Link, useParams} from "react-router-dom";
import {Grid, Button} from "@mui/material";
import EngagementContent from "./EngagementContent";
import {BackBtn2} from "components/BackBtn";
import UserProfileCard from "pages/Admin/Dashboard/Engagements/Widgets/UserProfileCard";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import MessageChat from "./MessageChat";
import {
  useGetSingleEngagementQuery,
  useGetSingleEngagementRelatedDataQuery,
  useMarkEngagementChatAsReadMutation,
} from "services/user/userRegApi";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg, {PageNotFound} from "components/mui-components/ErrorMsg";
import FinalFile from "./EngagementComponent/FinalFile";
import EngagementReviews from "./EngagementComponent/EngagementReviews";
import {useEffect} from "react";
import Title from "components/Title";
import Pusher from "pusher-js";

export const EachClientEngagement = () => {
  const {id} = useParams();
  const {data, isLoading, refetch, error} = useGetSingleEngagementQuery({
    id,
    type: "client",
  });
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("set-engagement-start-date-channel");
    channel.bind("set-engagement-start-date-event", () => {
      refetch();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [refetch]);
  if (isLoading) return <CircularLoader />;
  if (error) return <ErrorMsg error={error} />;
  return <EachEngagement isProvider={false} data={data?.data} />;
};

export const EachProviderEngagement = () => {
  const {id} = useParams();
  const {data, isLoading, refetch, error} = useGetSingleEngagementQuery({
    id,
    type: "service-provider",
  });
  const {
    data: relatedData,
    isLoading: isLoadingRelatedData,
    refetch: refetchRelatedData,
    error: relatedDataError,
  } = useGetSingleEngagementRelatedDataQuery({
    id,
  });

  if (data) console.log(data);
  if (relatedData) console.log(relatedData);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("set-engagement-start-date-channel");
    channel.bind("set-engagement-start-date-event", () => {
      refetch();
      refetchRelatedData();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [refetch, refetchRelatedData]);
  if (isLoading || isLoadingRelatedData) return <CircularLoader />;

  if (error || relatedDataError) return <ErrorMsg error={error} />;
  return (
    <EachEngagement isProvider data={data?.data} relatedData={relatedData} />
  );
};
const EachEngagement = ({isProvider, data, relatedData, isAdmin}) => {
  const status = data?.status;
  const otherUser = isProvider ? data?.client : data?.service_provider;
  const isPaymentPending = data?.engagement?.payment_status === "pending";
  const [markEngagementChatAsRead] = useMarkEngagementChatAsReadMutation();
  useEffect(() => {
    if (!isAdmin) markEngagementChatAsRead(data?.id).unwrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (data === null) return <PageNotFound />;

  console.log(data, relatedData);

  return (
    <section className="bg-[#F4F4F5]">
      <Title titleProp={`${data?.job?.category?.name} | Engagement`} />
      <BackBtn2 />
      <div className="mt-6 grid h-[769px] grid-cols-1 gap-0.5 md:grid-cols-2">
        <div className="flex flex-1 flex-col overflow-y-auto rounded-[24px] rounded-e-none border border-[#DCDCE0] bg-white p-8">
          <FinalFile />
          <div className="mt-10">
            <h3 className="mb-6 font-Bai text-[32px] font-semibold leading-10 text-[#151519]">
              Engagement Detail
            </h3>
            <div className="flex flex-col">
              <h4 className="mb-2 font-Mulish text-xs font-bold uppercase text-primary">
                Beauty & Health
              </h4>
              <p className="mb-4 font-Bai text-xl font-semibold text-[#151519]">
                Happy Ending Massage. A title for two lines and more
              </p>
              <p className="mb-4 font-Mulish text-xs font-medium text-[#6e726e]">
                Posted on 23 June, 2022
              </p>
              <div className="flex items-center justify-between">
                <div className="rounded-[50px] bg-[#E8FAFC] px-2 py-1 font-Mulish text-xs font-bold uppercase text-[#1598AA]">
                  ongoing
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-x-1.5 rounded-lg bg-[#ECECEE] p-2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.86318 9.32185C3.05361 9.63697 2.95254 10.0468 2.63742 10.2372C2.3223 10.4277 1.91247 10.3266 1.72204 10.0115C1.45131 9.56349 1.26762 9.17745 1.15962 8.92824C1.00496 8.57141 1.0274 8.16966 1.21581 7.8332C1.50332 7.31979 2.22963 6.13289 3.34898 5.06645C4.46576 4.00246 6.03718 3 7.99991 3C9.96264 3 11.5341 4.00246 12.6508 5.06645C13.7702 6.13289 14.4965 7.31979 14.784 7.8332C14.9724 8.16966 14.9949 8.57141 14.8402 8.92824C14.7322 9.17745 14.5485 9.56349 14.2778 10.0115C14.0873 10.3266 13.6775 10.4277 13.3624 10.2372C13.0473 10.0468 12.9462 9.63697 13.1366 9.32185C13.3544 8.96154 13.5055 8.64993 13.5971 8.44295C13.3266 7.96622 12.686 6.94154 11.7311 6.0318C10.7448 5.09211 9.48654 4.33333 7.99991 4.33333C6.51327 4.33333 5.25501 5.09211 4.2687 6.0318C3.31381 6.94155 2.67318 7.96622 2.40267 8.44295C2.49432 8.64993 2.64543 8.96154 2.86318 9.32185Z"
                        fill="#69697B"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.99991 5.33333C6.15896 5.33333 4.66657 6.82572 4.66657 8.66667C4.66657 10.5076 6.15896 12 7.99991 12C9.84086 12 11.3332 10.5076 11.3332 8.66667C11.3332 6.82572 9.84086 5.33333 7.99991 5.33333ZM5.99991 8.66667C5.99991 7.5621 6.89534 6.66667 7.99991 6.66667C9.10448 6.66667 9.99991 7.5621 9.99991 8.66667C9.99991 9.77124 9.10448 10.6667 7.99991 10.6667C6.89534 10.6667 5.99991 9.77124 5.99991 8.66667Z"
                        fill="#69697B"
                      />
                    </svg>
                    <span className="font-mulish text-sm font-bold text-[#151519]">
                      2.1k
                    </span>
                  </div>
                  <div className="flex items-center gap-x-1.5 rounded-lg bg-[#ECECEE] p-2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.97224 1.82059C7.3991 2.03677 7.7466 2.28979 8.0001 2.50253C8.25359 2.28979 8.60107 2.03677 9.02791 1.82059C9.9952 1.33069 11.3874 1.0277 12.953 1.82058C13.953 2.32707 14.6295 3.09344 14.9979 4.0239C15.3614 4.94186 15.4108 5.98108 15.2358 7.03546C14.9378 8.83185 13.7099 10.4348 12.4489 11.6699C11.1722 12.9204 9.77223 13.8794 8.9827 14.3794C8.37839 14.7622 7.62183 14.7622 7.01751 14.3794C6.22798 13.8794 4.82802 12.9204 3.55124 11.6699C2.2902 10.4348 1.06236 8.83186 0.764294 7.03546C0.589349 5.98108 0.63873 4.94186 1.00217 4.0239C1.37056 3.09344 2.04707 2.32707 3.04715 1.82058C4.61275 1.02769 6.00493 1.33069 6.97224 1.82059ZM7.49219 3.8568C7.61884 4.00577 7.80453 4.09165 8.00012 4.09165C8.19536 4.09165 8.38074 4.00606 8.50737 3.85757C8.50752 3.8574 8.50766 3.85722 8.50781 3.85705C8.50798 3.85685 8.50815 3.85665 8.50832 3.85645L8.51844 3.84509C8.52881 3.83358 8.54624 3.81462 8.57039 3.78962C8.6188 3.73952 8.69358 3.66584 8.79207 3.57968C8.99055 3.40605 9.27699 3.18903 9.63034 3.01007C10.3243 2.65858 11.258 2.45675 12.3506 3.01007C13.0639 3.37133 13.5114 3.89124 13.7582 4.51473C14.01 5.15073 14.0672 5.93314 13.9205 6.81721C13.6928 8.18907 12.7146 9.54336 11.516 10.7173C10.3331 11.8759 9.01976 12.7777 8.26928 13.253C8.10057 13.3599 7.89964 13.3599 7.73093 13.253C6.98044 12.7777 5.66711 11.8759 4.48419 10.7173C3.28553 9.54336 2.30727 8.18907 2.07964 6.81721C1.93296 5.93314 1.99007 5.15073 2.24187 4.51473C2.48873 3.89124 2.93623 3.37133 3.64956 3.01007C4.74211 2.45675 5.67581 2.65858 6.36983 3.01007C6.72319 3.18903 7.00965 3.40606 7.20813 3.57969C7.30663 3.66585 7.38142 3.73953 7.42983 3.78963C7.45398 3.81463 7.47141 3.83359 7.48179 3.84511L7.49219 3.8568Z"
                        fill="#69697B"
                      />
                    </svg>
                    <span className="font-mulish text-sm font-bold text-[#151519]">
                      82
                    </span>
                  </div>
                </div>
              </div>
              <div className="mb-4 flex items-center gap-x-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 1C8.55228 1 9 1.44772 9 2V3H15V2C15 1.44772 15.4477 1 16 1C16.5523 1 17 1.44772 17 2V3H18C20.2091 3 22 4.79086 22 7V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V7C2 4.79086 3.79086 3 6 3H7V2C7 1.44772 7.44772 1 8 1ZM15 5C15 5.55228 15.4477 6 16 6C16.5523 6 17 5.55228 17 5H18C19.1046 5 20 5.89543 20 7V7.5H4V7C4 5.89543 4.89543 5 6 5H7C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5H15ZM20 9.5H4V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V9.5Z"
                    fill="#5010B3"
                  />
                </svg>
                <span className="font-Mulish text-sm font-medium text-[#3B3D3B]">
                  30 Oct 2022
                </span>
                <span className="font-Mulish text-sm font-medium text-[#69697B]">
                  to
                </span>
                <span className="font-Mulish text-sm font-medium text-[#3B3D3B]">
                  30 Oct 2022
                </span>
              </div>
              <div className="w-fit rounded-lg border border-[#C9C9CF] px-2 py-1 text-xs font-bold uppercase text-[#039855]">
                12 days Left
              </div>
              <span className="my-6 font-Bai text-2xl font-bold text-[#3B3D3B]">
                ₦30,000
              </span>
              <p className="mb-2 font-Bai text-sm font-semibold text-[#151519]">
                Description
              </p>
              <p className="mb-2 font-Mulish text-sm font-semibold text-[#69697B]">
                Ac duis accumsan consectetur pellentesque pellentesque enim eu
                duis eros. Egestas augue quis posuere semper. Mus consectetur
                magna tempus massa. Semper aliquet amet a pulvinar mi gravida
                feugiat id pellentesque ac ultricies vivamus tincidunt...
              </p>
              <button className="w-fit border-none font-Mulish text-sm font-medium text-primary">
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 rounded-[24px] rounded-s-none border border-[#DCDCE0]">
          <MessageChat
            receiver_id={otherUser?.id}
            module_id={data?.id}
            isAdmin={isAdmin}
            status={status}
            isPaymentPending={isPaymentPending}
          />
        </div>{" "}
      </div>
    </section>
  );
};
export default EachEngagement;
