import React from 'react'

const JobIcon2 = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 6H5C3.89543 6 3 6.89543 3 8V16M8 6V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V6M8 6H16M16 6H19C20.1046 6 21 6.89543 21 8V16M3 16V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V16M3 16H21" stroke="#039855" stroke-width="2" stroke-linejoin="round" />
        </svg>

    )
}

export default JobIcon2


export const JobIcon3 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 6H5C3.89543 6 3 6.89543 3 8V16M8 6V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V6M8 6H16M16 6H19C20.1046 6 21 6.89543 21 8V16M3 16V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V16M3 16H21" stroke="#3B3D3B" stroke-width="2" stroke-linejoin="round" />
        </svg>

    )
}