import React from "react";

function EngagementIcon({color}) {
  return (
    <>
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0008 13.8369C18.4567 14.5683 19.7049 15.742 20.6161 17.2096C20.7965 17.5003 20.8867 17.6456 20.9179 17.8468C20.9813 18.2558 20.7016 18.7585 20.3207 18.9204C20.1333 19 19.9225 19 19.5008 19M15.0008 9.53224C16.4825 8.79589 17.5008 7.26686 17.5008 5.5C17.5008 3.73314 16.4825 2.20411 15.0008 1.46776M13.0008 5.5C13.0008 7.98528 10.9861 10 8.50081 10C6.01553 10 4.00081 7.98528 4.00081 5.5C4.00081 3.01472 6.01553 1 8.50081 1C10.9861 1 13.0008 3.01472 13.0008 5.5ZM1.56004 16.9383C3.15435 14.5446 5.67019 13 8.50081 13C11.3314 13 13.8473 14.5446 15.4416 16.9383C15.7909 17.4628 15.9655 17.725 15.9454 18.0599C15.9297 18.3207 15.7588 18.64 15.5504 18.7976C15.2827 19 14.9146 19 14.1785 19H2.82317C2.08698 19 1.71889 19 1.45125 18.7976C1.24286 18.64 1.0719 18.3207 1.05625 18.0599C1.03614 17.725 1.21078 17.4628 1.56004 16.9383Z"
          stroke={color || "#3B3D3B"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default EngagementIcon;
