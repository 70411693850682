import LogoIcon from "components/Vectors/LogoIcon";
import {Link} from "react-router-dom";

const NewAuthLayout = ({children}) => {
  return (
    <div className="relative min-h-screen bg-[#EEEEEE] py-[10%] md:py-[5%]">
      <div className="noScroll mx-auto flex max-h-[90vh] min-h-[380px] w-[95vw] max-w-[500px] flex-col items-center overflow-y-auto rounded-3xl bg-grey_90 p-8 shadow-sm md:p-14">
        <Link to="/">
          <LogoIcon />
        </Link>
        <div className="mt-8 w-full">{children}</div>
      </div>

      <p className=" absolute bottom-[3%] left-[3%] font-Bai text-base font-medium text-grey_30">
        &copy; {new Date().getFullYear()} All rights reserved, Truelocal
      </p>
    </div>
  );
};

export default NewAuthLayout;
