const StopwatchIcon = props => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0013 13.168V18.5013L19.3346 20.5013M16.0013 7.16797C9.74208 7.16797 4.66797 12.2421 4.66797 18.5013C4.66797 24.7605 9.74208 29.8346 16.0013 29.8346C22.2605 29.8346 27.3346 24.7605 27.3346 18.5013C27.3346 12.2421 22.2605 7.16797 16.0013 7.16797ZM16.0013 7.16797V3.16797M13.3346 3.16797H18.668M27.1066 7.95736L25.1066 5.95736L26.1066 6.95736M4.896 7.95736L6.896 5.95736L5.896 6.95736"
        stroke={props.color || "#E98100"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default StopwatchIcon;
