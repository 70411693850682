import ValidatedInput2 from "components/forms/ValidatedInput2";
import {FormProvider, useForm} from "react-hook-form";
import SaveChangeBtn from "../Components/SaveChangeBtn";

const SecurityTabs = () => {
  const methods = useForm();

  return (
    <div className="w-full rounded-24 bg-[white] p-4 md:p-[40px]   ">
      <FormProvider {...methods}>
        <form
          className="flex w-full flex-col"
        >
          <div className="flex items-center justify-between">
            <h2 className="settings-font-h2">Security Setting</h2>
            <SaveChangeBtn />
          </div>
          <fieldset className=" mt-[60px] pb-[50px] md:min-w-[420px] md:self-center ">
            <legend className="mt-[60px] font-Bai text-[20px] font-medium text-grey_10 ">
              Change your Password
            </legend>
            <div className="mt-[40px] flex flex-col gap-8 ">
              <ValidatedInput2
                name="current_password"
                type="password"
                label="Current Password"
              />
              <ValidatedInput2
                name="new_password"
                type="password"
                label="New Password"
              />
              <ValidatedInput2
                name="confirm_password"
                type="password"
                label="Confirm Password"
              />
            </div>
          </fieldset>
        </form>
      </FormProvider>
    </div>
  );
};
export default SecurityTabs;
