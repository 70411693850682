import { createTheme } from "@mui/material/styles";

export const customTheme = createTheme({
  typography: {
    fontFamily: ["Mulish", "sans-serif"],
    // fontFamily: ["Bai Jamjuree", "sans-serif"],
  },
  shape: {
    borderRadius: 5,
  },
  palette: {
    primary: {
      main: "#E98100",
      dark: "#FF910A",
      light: "#FFE5CD",
    },
    primaryInverse: {
      light: "#FFE5CD",
      dark: "#FF910A",
      main: "#E98100",
      contrastText: "#F6A001",
    },
    primaryInverse2: {
      main: "#ffffff",
      dark: "#FF910A",
      light: "#FFE5CD",
      // contrastText: "#E98100",
    },

    secondary: {
      main: "#321E1F",
      light: "#FAEFD7",
    },
    secondaryInverse: {
      light: "#3B3D3B",
      main: "#FAEFD7",
    },
    grey: {
      main: "#1D1D1D",
      light: "#FCFCFD",
      contrastText: "#FCFCFD",
    },
    greyInverse: {
      light: "#1D1D1D",
      main: "#FCFCFD",
      contrastText: "#1D1D1D",
    },
    greyInverse_2: {
      light: "#6E726E",
      main: "#F2F4F7",
      contrastText: "#6E726E",
    },
    error: {
      main: "#B42318",
      light: "#FFEBEB",
      contrastText: "#fff",
    },
    errorInverse: {
      light: "#B42318",
      main: "#FFEBEB",
      contrastText: "#B42318",
    },
    success: {
      main: "#039855",
      light: "#ECFDF3",
      contrastText: "#fff",
    },
    successInverse: {
      light: "#039855",
      main: "#ECFDF3",
      contrastText: "#039855",
    },
    blue: {
      main: "#1570EF",
      contrastText: "#fff",
      light: "#D1E9FF",
    },
    blueInverse: {
      light: "#1570EF",
      contrastText: "#1570EF",
      main: "#D1E9FF",
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#F6A001 !important",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          textTransform: "capitalize",
          boxShadow: "none !important",
          "&:disabled": {
            opacity: 0.6,
            cursor: "not-allowed",
          },
          "&.MuiButton-sizeMedium": {
            padding: "0.6rem 1.3rem",
            fontSize: "0.875rem !important",
            "@media (max-width: 760px)": {
              fontSize: "0.8rem !important",
              padding: "0.6rem",
            },
          },
          "&.MuiButton-sizeSmall": {
            padding: "6px 16px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "2.5rem",
        },
        fieldSet: {
          borderRadius: 5,
        },
        input: {
          padding: "14px 14px",
          paddingRight: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "1rem",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          textTransform: "capitalize",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#181820",
          borderRadius: 6,
        },
      },
    },
  },
});
