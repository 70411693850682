import NewestJobs from "./Tabs/NewestJobs";

const Main = () => {
  return (
    <div className="mt-6 rounded-t-20 bg-white p-3 md:p-6 md:pb-20">
      <NewestJobs />
    </div>
  );
};

export default Main;
