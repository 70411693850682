import React from 'react'

const UserIcon1 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="7" r="5" stroke="#3B3D3B" stroke-width="2" />
            <path d="M20.1313 18.5516L19.367 19.1964L20.1313 18.5516ZM5.2889 22H18.7105V20H5.2889V22ZM4.63239 19.1964C6.24275 17.2879 8.9206 16 11.9997 16V14C8.37679 14 5.12257 15.5141 3.10382 17.9067L4.63239 19.1964ZM11.9997 16C15.0788 16 17.7567 17.2879 19.367 19.1964L20.8956 17.9067C18.8769 15.5141 15.6226 14 11.9997 14V16ZM18.7105 22C19.7367 22 20.6458 21.4844 21.1289 20.6952C21.6346 19.8692 21.6361 18.7843 20.8956 17.9067L19.367 19.1964C19.4611 19.308 19.4802 19.3916 19.4833 19.4419C19.4867 19.4978 19.4732 19.5693 19.4232 19.651C19.319 19.8212 19.0706 20 18.7105 20V22ZM5.2889 20C4.9288 20 4.68044 19.8212 4.57624 19.651C4.52622 19.5693 4.51273 19.4978 4.51614 19.4419C4.5192 19.3916 4.53827 19.308 4.63239 19.1964L3.10382 17.9067C2.36332 18.7843 2.3648 19.8692 2.87051 20.6952C3.35363 21.4844 4.26268 22 5.2889 22V20Z" fill="#3B3D3B" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0373 16.5547C9.59427 15.8901 10.0707 15 10.8694 15H13.1323C13.931 15 14.4074 15.8901 13.9644 16.5547L13.2977 17.5547C13.1122 17.8329 12.8 18 12.4657 18H11.536C11.2017 18 10.8894 17.8329 10.704 17.5547L10.0373 16.5547ZM11.1258 18.5C10.8111 18.5 10.5147 18.6482 10.3258 18.9L9.20084 20.4C8.70641 21.0592 9.17679 22 10.0008 22L14.0008 22C14.8249 22 15.2953 21.0592 14.8008 20.4L13.6758 18.9C13.487 18.6482 13.1906 18.5 12.8758 18.5H11.1258Z" fill="#3B3D3B" />
        </svg>
    )
}

export default UserIcon1