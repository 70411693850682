const VideoIcon2 = (props) => {
  return (
    <svg
      width="75"
      height="65"
      viewBox="0 0 75 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="53" cy="43.5" r="21.5" fill="#FFFAF5" />
      <path
        d="M57 17.8627C57 16.6511 57 16.0453 56.7604 15.7648C56.5525 15.5214 56.2407 15.3922 55.9215 15.4173C55.5538 15.4462 55.1254 15.8746 54.2686 16.7314L47 24L54.2686 31.2686C55.1254 32.1254 55.5538 32.5538 55.9215 32.5827C56.2407 32.6078 56.5525 32.4786 56.7604 32.2352C57 31.9547 57 31.3489 57 30.1373V17.8627Z"
        stroke={props.color || "#E98100"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 19.6C17 16.2397 17 14.5595 17.654 13.2761C18.2292 12.1471 19.1471 11.2292 20.2761 10.654C21.5595 10 23.2397 10 26.6 10H37.4C40.7603 10 42.4405 10 43.7239 10.654C44.8529 11.2292 45.7708 12.1471 46.346 13.2761C47 14.5595 47 16.2397 47 19.6V28.4C47 31.7603 47 33.4405 46.346 34.7239C45.7708 35.8529 44.8529 36.7708 43.7239 37.346C42.4405 38 40.7603 38 37.4 38H26.6C23.2397 38 21.5595 38 20.2761 37.346C19.1471 36.7708 18.2292 35.8529 17.654 34.7239C17 33.4405 17 31.7603 17 28.4V19.6Z"
        stroke={props.color || "#E98100"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default VideoIcon2;
