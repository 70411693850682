import ImageSlideShow from "components/mui-components/Modal/ImageSlideshow";

const JobRequestMedia = () => {
  return (
    <div className="rounded-24 bg-grey_80 p-4 md:p-8 ">
      <h3 className="text-prop mb-3 font-Bai text-[24px] font-semibold text-grey_10 ">
        Media
      </h3>
      <ImageSlideShow
        images={[
          "/images/eng-1.png",
          "/images/eng-2.png",
          "/images/eng-3.png",
          "/images/eng-4.png",
          "/images/eng-5.png",
        ]}
      />
    </div>
  );
};
export default JobRequestMedia;
