import {IconButton} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VideoIcon from "components/Vectors/VideoIcon";
import {useCallback, useEffect, useState} from "react";
import {useFormContext, get} from "react-hook-form";
import {useDropzone} from "react-dropzone";
import TrashIcon from "components/Vectors/TrashIcon";
import ImageFolderIcon from "components/Vectors/ImageFolderIcon";

function UploadMultiImage({
  img,
  videoType = false,
  name,
  maxFiles = 10,
  dashed,
  required,
  bg,
  containerHeight,
  ...props
}) {
  const {
    register,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: {errors},
  } = useFormContext({mode: "all", revalidateMode: "all"});

  const watchImage = watch(name);

  const [imageFile, setImageFile] = useState(watchImage ? watchImage : []);

  const onDrop = useCallback(acceptedFiles => {
    setImageFile(prev => [...prev, ...acceptedFiles]);
  }, []);

  function fileSizeValidator(file) {
    if (file?.size > 1_000_000) {
      return {
        code: "file-too-large",
        message: `File is larger than 1MB`,
      };
    }
    return null;
  }

  const {getRootProps, getInputProps, isDragActive, fileRejections} =
    useDropzone({
      validator: fileSizeValidator,
      onDrop,
      maxFiles: maxFiles || 10,
    });

  useEffect(() => {
    setValue(name, imageFile);
  }, [imageFile, name, setValue]);

  useEffect(() => {
    register(name, {
      required: required ? "This field is required" : "",
    });
  }, [name, register, required]);

  const fileRejectionItems = fileRejections.map(({file, errors}) => (
    <span className="input-err-msg pt-[-10rem]" key={file.path}>
      {file.path} is greater than 1MB
    </span>
  ));

  const onSelectFile = e => {
    const selectedFiles = e.target.files;
    const selectedFileArray = Array.from(selectedFiles);

    if (selectedFiles[0]?.size > 1_000_000) {
      setError(name, {
        message: `${selectedFiles[0]?.name} is greater than 1MB`,
      });
    } else {
      clearErrors(name);
      setImageFile(prev => [...prev, ...selectedFileArray]);
    }
  };

  const handleDelete = id => {
    const updatedFiles = imageFile.filter(file => file !== id);
    setImageFile(updatedFiles);
  };

  const error = get(errors, name);
  return (
    <div>
      <div
        htmlFor={props.name ? props.name : "logo"}
        className={`${bg && bg} relative mb-2 flex ${
          containerHeight || "h-[auto]"
        } min-h-[140px] cursor-pointer items-center justify-center rounded-lg p-[9px] ${
          dashed ? "rounded-24 border-[2px] border-dashed" : ""
        }`}
      >
        {imageFile.length >= 1 ? (
          <div className="relative flex  h-full w-full cursor-pointer flex-wrap gap-[8px] md:grid md:grid-cols-3  ">
            {imageFile?.slice(0, maxFiles).map((item, i) => (
              <div className="relative h-[100px] w-full" key={i}>
                <img
                  src={
                    typeof item === "string" ? item : URL?.createObjectURL(item)
                  }
                  alt="img"
                  className="h-[100px] w-[100%] rounded-[8px] object-cover md:h-[100px] md:w-[150px]"
                />

                <IconButton
                  component="label"
                  sx={{
                    background: "#181820B2 !important",
                    position: "absolute",
                    height: "34px",
                    width: "34px",
                    cursor: "pointer",
                  }}
                  className=" absolute top-2 right-2 cursor-pointer transition  "
                  onClick={() => {
                    handleDelete(item);
                    // removeFile(i);
                  }}
                >
                  <TrashIcon color="white" height="20" width="20" />
                </IconButton>
              </div>
            ))}

            {imageFile.length < maxFiles && (
              <label
                htmlFor={name}
                className="text-medium text-props relative flex h-[100px] w-[125px]
             cursor-pointer flex-col items-center justify-center gap-2 rounded-[8px]
             bg-[#FAF5FF] text-primary "
              >
                <AddCircleOutlineIcon color="#8937CE" />
                Add Image
                <input
                  accept="image/* "
                  required={false}
                  // multiple
                  {...props}
                  type="file"
                  onChange={onSelectFile}
                  id={props.name ? props.name : "photo"}
                  name={props.name ? props.name : "photo"}
                  className="absolute inset-0 opacity-0"
                />
              </label>
            )}
          </div>
        ) : (
          <div {...getRootProps()}>
            <label
              htmlFor={name}
              className="flex cursor-pointer  flex-col items-center gap-y-3 text-xs md:text-sm "
            >
              {videoType ? <VideoIcon /> : <ImageFolderIcon />}

              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <div className="text-center font-Mulish">
                  <p className="text-center text-base font-medium text-grey_30 ">
                    <span className="text-primary "> Upload image </span>or drag
                    and drop
                  </p>

                  <p className="text-prop mt-1 mb-8 px-5 text-grey_30 ">
                    {`.jpg, .jpeg, or .png, not more than 1mb each, max of ${
                      maxFiles || 10
                    } images `}
                  </p>
                </div>
              )}

              <input
                {...getInputProps()}
                required
                {...props}
                type="file"
                accept="image/*"
                multiple
                onChange={onSelectFile}
                id={props.name ? props.name : "photos"}
                name={props.name ? props.name : "photos"}
                className="absolute inset-0 opacity-0"
              />
            </label>
          </div>
        )}
      </div>
      <div className="">{fileRejectionItems}</div>
      {error && error?.message && (
        <div className={` ${!error && "hidden"} input-err-msg pt-[-10rem] `}>
          {error?.message || error?.name?.message || ""}
        </div>
      )}
    </div>
  );
}

export default UploadMultiImage;
