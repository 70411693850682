import {Routes, Route, Outlet, Navigate, useLocation} from "react-router-dom";
import {ProfDashboardLinks, ClientDashboardLinks} from "utils/constants";

import SingleJobRequestPage from "components/SingleJobRequestPage";
import UserDashboardLayout from "templates/UserDashboardLayout";

import LandingPage from "pages/LandingPage";

// Service Providers Pages
import ServiceProviderDashboard from "pages/ServiceProviders";
import ServiceProviderMarketPlace from "pages/ServiceProviders/MarketPlace";
import ServiceProviderJobDetail from "pages/ServiceProviders/MarketPlace/JobDetail";
import ServiceProviderJobRequest from "pages/ServiceProviders/JobRequest";
import ServiceProviderEngagement from "pages/ServiceProviders/Engagement";
import ServiceProviderSubscription from "pages/ServiceProviders/Subscription";
import ServiceProviderTransaction from "pages/ServiceProviders/Transactions";
import ServiceProviderSettings from "pages/ServiceProviders/Settings";
import ServiceProvidersTickets from "pages/ServiceProviders/Tickets";
// import ServiceProvidersChats from "pages/ServiceProviders/Chats";
// import ServiceProvidersArchivedChats from "pages/ServiceProviders/Chats/ArchiveMessages";
import ServiceProvidersProfile from "pages/ServiceProviders/Profile";

// Client Pages
import ClientTransaction from "pages/Clients/Transactions";
import ClientNotification from "pages/Clients/Notification";
import ClientsDashBoard from "pages/Clients/Dashboard";
import ClientTickets from "components/Tickets";
import {
  EachClientEngagement,
  EachProviderEngagement,
} from "pages/Clients/Engagements/EachEngagements/EachEngagement";
import EachTicket from "components/EachTicket";
import ClientEngagements from "pages/Clients/Engagements/ClientEngagements";
import EachClientJobRequest from "pages/Clients/JobRequest/EachClientJobRequest";
// import ClientJobs from "pages/Clients/Jobs";
import PostAJob from "pages/Clients/Jobs/PostAJob";
import ClientProfile from "pages/Clients/Profile";
import ClientServiceProviders from "pages/Clients/ServiceProviders";
import EachClientProvider from "pages/Clients/ServiceProviders/EachProvider";
import ClientJobRequest from "pages/Clients/JobRequest";
import ClientSettings from "pages/Clients/Settings";
// import ClientMessages from "pages/Clients/Messages/ClientMessages";
// import ClientArchivedMessages from "pages/Clients/Messages/ClientArchivedMessages";

//User Auth Pages
import {
  UserCreateUsername,
  UserAccountCreated,
  UserAccountCreation,
  UserForgotPassword,
  UserLogin,
  UserResetPwd,
  UserVerifyOtp,
  // UserCreateClient,
  UserSelectUser,
  // UserCreateProvider,
} from "pages/User/auth";

// Admin Pages
import AdminLogin from "pages/Admin/Auth/Login";
import AdminResetPassword from "pages/Admin/Auth/ResetPassword";
import AdminEnterOTP from "pages/Admin/Auth/EnterOTP";
import AdminEnterNewPassword from "pages/Admin/Auth/EnterNewPassword";
import AdminResetSuccessful from "pages/Admin/Auth/ResetSuccessful";
import AdminDashboard from "pages/Admin/Dashboard/Home";
import AdminEngagements from "pages/Admin/Dashboard/Engagements";
import AdminNotifications from "pages/Admin/Dashboard/Notfications";
import AdminClients from "pages/Admin/Dashboard/Client";
import EachClient from "pages/Admin/Dashboard/Client/EachClient";
import AdminJobs from "pages/Admin/Dashboard/Jobs/AdminJobs";
import AdminServiceProviders from "pages/Admin/Dashboard/ServiceProviders";
import SingleAdminAdmin from "pages/Admin/Dashboard/Administrators/SingleAdminAdmin";
import AdminProfile from "pages/Admin/Dashboard/Profile";
import EditProfile from "pages/Admin/Dashboard/Profile/EditProfile";
import AdminTickets from "pages/Admin/Dashboard/Tickets";
import AdminTicketsDetails from "pages/Admin/Dashboard/Tickets/components/EachTicket";
import AdminTransactions from "pages/Admin/Dashboard/Transactions";
import AdminSettings from "pages/Admin/Dashboard/Settings";
import EachAdminJob from "pages/Admin/Dashboard/Jobs/EachAdminJob";
import AdminJobRequest from "pages/Admin/Dashboard/JobRequest/AdminJobRequest";
import EachAdminJobRequest from "pages/Admin/Dashboard/JobRequest/EachAdminJobRequest";
import AdminTokenPackages from "pages/Admin/Dashboard/TokenPackages/AdminTokenPackages";
import Administrators from "pages/Admin/Dashboard/Administrators";
import SingleCategoryPage from "pages/Admin/Dashboard/Settings/SingleCategoryPage";
import AdminEachEngagement from "pages/Admin/Dashboard/Engagements/AdminEachEngagement";
// import MobileAppPushNotification from "pages/Admin/Dashboard/MobileAppPushNotification";
import EachClientJob from "pages/Clients/Jobs/EachClientJob";
import {useGetUser} from "hooks/getUserHook";
import EachServiceProvider from "pages/Admin/Dashboard/ServiceProviders/EachServiceProvider";
import EachServices from "pages/Clients/ServiceProviders/EachServices";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";

import About from "pages/LandingPage/About";
import {PageNotFound} from "components/mui-components/ErrorMsg";
import ContactUs from "pages/LandingPage/ContactUs";
import CategoriesPage from "pages/LandingPage/widgets/CategoriesPage";
import AllJobs from "pages/LandingPage/widgets/AllJobs";
import PrivacyAndPolicy from "pages/LandingPage/widgets/PrivacyAndPolicy";
import FavoriteServiceProviders from "pages/Clients/Favorites/FavoriteServiceProviders";
import LandingDashboardLayout from "pages/LandingPage/widgets/DashboardLayout";
import UpgradeAccount from "pages/User/auth/UpgradeAccount";
import FollowedJobs from "pages/ServiceProviders/FollowedJobs";
import TermsOfService from "pages/LandingPage/widgets/TermsOfService";
import Title from "components/Title";
import CommunityGuideline from "pages/LandingPage/widgets/CommunityGuideline";
import HowItWorksClients from "pages/LandingPage/HowItWorksClients";
import HowItWorksServiceProviders from "pages/LandingPage/HowItWorksServiceProviders";
import MyJobOffers from "pages/ServiceProviders/JobOffers/MyJobOffers";
import AdminUsers from "pages/Admin/Dashboard/AdminUsers";
import EachAdminUser from "pages/Admin/Dashboard/AdminUsers/EachAdminUser";
import GeneralTemplate from "pages/EmailTemplate/GeneralTemplate";
import GeneralNotification from "pages/EmailTemplate/GeneralNotification";
import RegisteredAsSP from "pages/EmailTemplate/RegisteredAsSP";
import RegisteredAsClient from "pages/EmailTemplate/RegisteredAsClient";
import ReceivedOffer from "pages/EmailTemplate/ReceivedOffer";
import OfferAccepted from "pages/EmailTemplate/OfferAccepted";
import OfferDeclined from "pages/EmailTemplate/OfferDeclined";
import ExtensionRequest from "pages/EmailTemplate/ExtensionRequest";
import WithdrawalRequest from "pages/EmailTemplate/WithdrawalRequest";
import RefundApproved from "pages/EmailTemplate/RefundApproved";
import FAQs from "pages/LandingPage/widgets/FAQs";
import DiscoverJobs from "pages/Clients/DiscoverJobs";
import NewClientJobs from "pages/Clients/Jobs/NewJobPage";
import OpenJobs from "pages/Clients/Jobs/Widgets/OpenJobs";
import AssignedJobs from "pages/Clients/Jobs/Widgets/AssignedJobs";
import NewJobDetail from "pages/Clients/Dashboard/NewJobDetail";

function AllRoutes() {
  let {isLoggedIn, user} = useGetUser();
  // console.log(user);
  const isAdmin = user?.admin_permissions ? true : false;
  const location = useLocation();
  const isClientEngagements = location.pathname.includes("/client/engagements");

  const PrivateRoute = ({type, redirectUrl, children, extraOption = true}) => {
    return (
      <>
        {isLoggedIn && extraOption ? (
          children || <Outlet />
        ) : !extraOption && isLoggedIn ? (
          <Navigate
            to={`/${
              isAdmin
                ? "admin"
                : redirectUrl ||
                  user?.user_type?.toLowerCase().split(" ").join("-") ||
                  "user/login"
            }`}
            state={{from: location}}
          />
        ) : (
          <Navigate
            to={`/${
              type?.toLowerCase() !== "admin" ? "user" : type
            }/login?redirectTo=${location.pathname}`}
            state={{from: location}}
          />
        )}
      </>
    );
  };

  const AdminModule = ({children, module}) => {
    return (
      <>
        {user?.roles[0]?.name.toLowerCase() === "super admin" ||
        user?.admin_permissions?.some(
          el => el.name.toLowerCase() === module?.toLowerCase(),
        ) ? (
          children || <Outlet />
        ) : (
          <DashboardLayout
            title={<span className="capitalize">{module}</span>}
            error={{
              data: {
                message:
                  "Access denied: You don't have permission to view this page.",
              },
              goBack: true,
            }}
          />
        )}
      </>
    );
  };

  return (
    <Routes>
      {/* Landing Screens */}
      <Route path="/" element={<LandingDashboardLayout />}>
        <Route path="" element={<LandingPage />} />
        <Route path="about-us" element={<About />} />
        <Route path="how-it-works/clients" element={<HowItWorksClients />} />
        <Route
          path="how-it-works/service-providers"
          element={<HowItWorksServiceProviders />}
        />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="categories" element={<CategoriesPage />} />
        <Route path="privacy-policy" element={<PrivacyAndPolicy />} />
        <Route path="faqs" element={<FAQs />} />
        <Route path="community-guideline" element={<CommunityGuideline />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="jobs" element={<AllJobs />} />
        <Route path="email-templates" element={<Outlet />}>
          <Route path="general" element={<GeneralTemplate />} />
          <Route
            path="general-notification"
            element={<GeneralNotification />}
          />
          <Route path="registered-as-sp" element={<RegisteredAsSP />} />
          <Route path="registered-as-client" element={<RegisteredAsClient />} />
          <Route path="received-offer" element={<ReceivedOffer />} />
          <Route path="offer-accepted" element={<OfferAccepted />} />
          <Route path="offer-declined" element={<OfferDeclined />} />
          <Route path="extension-request" element={<ExtensionRequest />} />
          <Route path="withdrawal-request" element={<WithdrawalRequest />} />
          <Route path="refund-approved" element={<RefundApproved />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route
        path=""
        element={
          <UserDashboardLayout
            isProfessional={user?.user_type === "Service provider"}
            links={
              user?.user_type === "Client"
                ? ClientDashboardLinks
                : ProfDashboardLinks
            }
          />
        }
      >
        <Route path="services" element={<ClientServiceProviders />} />
        <Route path="services/:id" element={<EachServices />} />
        <Route path="user-profile/:id" element={<EachClientProvider />} />
        <Route path="jobs/:id/*" element={<ServiceProviderJobDetail />} />
        <Route path="job-creator/:id" element={<EachClientProvider />} />
      </Route>

      {/* admin routes */}
      <Route
        path="/admin"
        element={
          <>
            <Title titleProp="Admin" />
            <Outlet />
          </>
        }
      >
        {/* Admin Auth Screens */}
        <Route
          path="login"
          element={isLoggedIn ? <Navigate to="/admin" /> : <AdminLogin />}
        />
        <Route
          path="reset-password"
          element={
            isLoggedIn ? <Navigate to="/admin" /> : <AdminResetPassword />
          }
        />
        <Route
          path="enter-otp"
          element={isLoggedIn ? <Navigate to="/admin" /> : <AdminEnterOTP />}
        />
        <Route
          path="set-password"
          element={
            isLoggedIn ? <Navigate to="/admin" /> : <AdminEnterNewPassword />
          }
        />
        <Route
          path="reset-successful"
          element={
            isLoggedIn ? <Navigate to="/admin" /> : <AdminResetSuccessful />
          }
        />

        <Route
          path=""
          element={<PrivateRoute extraOption={isAdmin} type="admin" />}
        >
          <Route path="" element={<AdminDashboard />} />
          <Route path="notifications" element={<AdminNotifications />} />
          <Route path="account" element={<AdminProfile />} />
          <Route path="edit-profile" element={<EditProfile />} />

          {/* clients module */}
          <Route path="clients" element={<AdminModule module={"Clients"} />}>
            <Route path="" element={<AdminClients />} />
            <Route path=":id" element={<EachClient />} />
          </Route>
          {/* Users module */}
          <Route path="users" element={<AdminModule module={"Users"} />}>
            <Route path="" element={<AdminUsers />} />
            <Route path=":id" element={<EachAdminUser />} />
          </Route>
          {/* service-providers module */}
          <Route
            path="service-providers"
            element={<AdminModule module={"Service Providers"} />}
          >
            <Route path="" element={<AdminServiceProviders />} />
            <Route path=":id" element={<EachServiceProvider />} />
          </Route>
          {/* tickets module */}
          <Route path="tickets" element={<AdminModule module={"tickets"} />}>
            <Route path="" element={<AdminTickets />} />
            <Route path=":id" element={<AdminTicketsDetails />} />
          </Route>

          {/* transactions module */}
          <Route
            path="transactions"
            element={<AdminModule module={"transactions"} />}
          >
            <Route path="" element={<AdminTransactions />} />
          </Route>

          {/* jobs module */}
          <Route path="jobs" element={<AdminModule module={"jobs"} />}>
            <Route path="" element={<AdminJobs />} />
            <Route path=":id" element={<EachAdminJob />} />
          </Route>

          {/* job request module */}
          <Route
            path="job-offers"
            element={<AdminModule module={"job offers"} />}
          >
            <Route path="" element={<AdminJobRequest />} />
            <Route path=":id" element={<EachAdminJobRequest />} />
          </Route>

          {/* engagements module */}
          <Route
            path="engagements"
            element={<AdminModule module={"Engagements"} />}
          >
            <Route path="" element={<AdminEngagements />} />
            <Route path=":id" element={<AdminEachEngagement />} />
          </Route>

          {/* <Route
                path="MobileAppPushNotification"
                element={<MobileAppPushNotification />}
              /> */}

          <Route path="token-packages" element={<AdminTokenPackages />} />
          <Route path="administrators" element={<Administrators />} />
          <Route path="administrators/:id" element={<SingleAdminAdmin />} />

          <Route path="settings" element={<AdminSettings />} />
          <Route
            path="settings/category/:id"
            element={<SingleCategoryPage />}
          />
        </Route>
      </Route>

      {/* Users Auth screens  */}
      <Route
        path="/user"
        element={
          <>
            <Title titleProp="Onboarding" />
            <Outlet />
          </>
        }
      >
        <Route path="login" element={<UserLogin />} />
        <Route path="create-account" element={<UserAccountCreation />} />
        <Route path="account-created" element={<UserAccountCreated />} />
        <Route path="verify-otp" element={<UserVerifyOtp />} />
        <Route path="create-username" element={<UserCreateUsername />} />
        <Route path="select-user" element={<UserSelectUser />} />
        <Route path="forgot-password" element={<UserForgotPassword />} />
        <Route path="reset-password" element={<UserResetPwd />} />

        {/*<Route
              path="provider/register"
              element={
                <PrivateRoute extraOption={!isAdmin} type={"user"}>
                  <Outlet />
                </PrivateRoute>
              }
            >
              <Route path="1" element={<UserCreateProvider number={1} />} />
              <Route path="2" element={<UserCreateProvider number={2} />} />
              <Route path="3" element={<UserCreateProvider number={3} />} />
              <Route path="4" element={<UserCreateProvider number={4} />} />
              <Route path="5" element={<UserCreateProvider number={5} />} />
              <Route path="6" element={<UserCreateProvider number={6} />} />
              <Route path="7" element={<UserCreateProvider number={7} />} />
              <Route path="*" element={<UserCreateProvider number={7} />} />
            </Route>*/}

        {/*<Route
              path="client/register"
              element={
                <PrivateRoute extraOption={!isAdmin} type={"user"}>
                  <Outlet />
                </PrivateRoute>
              }
            >
              <Route path="1" element={<UserCreateClient number={1} />} />
              <Route path="2" element={<UserCreateClient number={2} />} />
              <Route path="3" element={<UserCreateClient number={3} />} />
              <Route path="4" element={<UserCreateClient number={4} />} />
              <Route path="*" element={<UserCreateClient number={4} />} />
            </Route>*/}
        {/* Upgrading to Hybrid */}
        <Route
          path="upgrade-account"
          element={
            <PrivateRoute extraOption={!isAdmin} type={"user"}>
              <Outlet />
            </PrivateRoute>
          }
        >
          <Route path="1" element={<UpgradeAccount number={1} />} />
          <Route path="2" element={<UpgradeAccount number={2} />} />
          <Route path="3" element={<UpgradeAccount number={3} />} />
          <Route path="*" element={<UpgradeAccount number={4} />} />
        </Route>
      </Route>

      {/* Service Provider screens */}
      <Route
        path="/service-provider"
        element={
          <>
            <Title titleProp="Service Provider" />
            <UserDashboardLayout
              isProfessional={user?.user_type === "Service provider"}
              links={ProfDashboardLinks}
            />
          </>
        }
      >
        {/* Unprotected Routes */}
        <Route path="marketplace" element={<ServiceProviderMarketPlace />} />
        <Route
          path="marketplace/:id/*"
          element={<ServiceProviderJobDetail />}
        />
      </Route>

      {/* Service provider protected Routes */}
      <Route
        path="/service-provider"
        type={"service-provider"}
        element={
          <PrivateRoute
            extraOption={!isAdmin && user?.user_type === "Service provider"}
          >
            <Title titleProp="Service Provider" />
            <UserDashboardLayout
              isProfessional={user?.user_type === "Service provider"}
              links={ProfDashboardLinks}
            />
          </PrivateRoute>
        }
      >
        <Route path="" element={<ServiceProviderDashboard />} />
        <Route
          path="notification"
          element={<ClientNotification isProfessional={true} />}
        />
        <Route path="job-offers" element={<MyJobOffers />} />
        <Route path="job-request" element={<ServiceProviderJobRequest />} />
        <Route path="job-request/:id" element={<SingleJobRequestPage />} />
        <Route path="engagements" element={<ServiceProviderEngagement />} />
        <Route path="engagements/:id" element={<EachProviderEngagement />} />
        <Route path="subscription" element={<ServiceProviderSubscription />} />
        <Route
          path="followed-jobs"
          element={
            <section>
              <h1 className="font-h1">Following</h1>

              <div className="mt-[28px]">
                <div className="w-full rounded-[24px] bg-[#fff] px-4 py-5 lg:px-[40px] lg:py-[35px]">
                  <FollowedJobs />
                </div>
              </div>
            </section>
          }
        />
        <Route path="transactions" element={<ServiceProviderTransaction />} />
        <Route path="settings" element={<ServiceProviderSettings />} />
        <Route path="tickets" element={<ServiceProvidersTickets />} />
        <Route path="tickets/:id" element={<EachTicket />} />
        <Route path="account" element={<ServiceProvidersProfile />} />
        <Route
          path="following"
          element={<FavoriteServiceProviders isProfessional={true} />}
        />
      </Route>

      {/* client routes */}
      <Route
        path="/client"
        element={
          <PrivateRoute
            extraOption={!isAdmin && user?.user_type === "Client"}
            type={"client"}
          >
            <Title titleProp="Client" />
            <UserDashboardLayout
              links={ClientDashboardLinks}
              fullWidth={isClientEngagements ? true : false}
            />
          </PrivateRoute>
        }
      >
        <Route path="" element={<ClientsDashBoard />} />
        <Route path="tickets" element={<ClientTickets />} />
        <Route path="tickets/:id" element={<EachTicket />} />
        <Route path="notification" element={<ClientNotification />} />
        <Route path="engagements" element={<ClientEngagements />} />
        <Route path="engagements/:id" element={<EachClientEngagement />} />
        <Route path="jobs" element={<NewClientJobs />} />
        <Route path="jobs/:id" element={<NewJobDetail />} />
        <Route path="jobs/open-jobs" element={<OpenJobs />} />
        <Route path="jobs/assigned-jobs" element={<AssignedJobs />} />
        <Route path="discover-jobs" element={<DiscoverJobs />} />
        {/* <Route path="jobs/:id/*" element={<EachClientJob />} /> */}
        <Route path="post-job" element={<PostAJob />} />
        <Route path="job-request" element={<ClientJobRequest />} />
        <Route path="job-request/:id" element={<EachClientJobRequest />} />
        <Route path="transactions" element={<ClientTransaction />} />
        <Route path="account" element={<ClientProfile />} />
        <Route path="settings" element={<ClientSettings />} />
        <Route path="favorites" element={<FavoriteServiceProviders />} />
      </Route>
    </Routes>
  );
}

export default AllRoutes;
