import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function Overviews({ user }) {
  return (
    <div className="h-fit rounded-2xl bg-grey_80 p-4 md:p-6">
      <h3 className="text-grey_1 mb-12 font-Bai text-2xl font-semibold">
        Overview
      </h3>

      <div>
        <p className="text-lg font-medium text-grey_30">Type</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.is_hybrid === "Yes" ? "Hybrid" : user.user_type}
        </p>
        {user?.is_hybrid?.toLowerCase() === "yes" && (
          <Button
            sx={{ padding: "0px !important" }}
            endIcon={
              <ArrowForwardIosIcon
                sx={{
                  height: "16px",
                  width: "16px",
                }}
              />
            }
          >
            <Link to={`/admin/service-providers/${user.id}`}>
              View Professional Profile
            </Link>
          </Button>
        )}
      </div>

      <div className="mt-6">
        <p className="mb-0 text-lg font-medium text-grey_30">About Me</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.about || "No Bio"}
        </p>
      </div>

      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Email Address</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.email || 'N/A'}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">Languages</p>
        <p className="my-2 text-lg font-medium text-[#000000]">
          {user?.languages.map((value, index) => (
            <span key={value.id}>{(index ? ", " : "") + value.name}</span>
          ))}
        </p>
      </div>
      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Phone</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.phone_number || "N/A"}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">Sex</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.gender || "N/A"}
        </p>
      </div>
      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Date of Birth</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.dob || "N/A"}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">Country</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.country === "undefined" ? "N/A" : user?.country}
        </p>
      </div>
      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Location</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.city?.name ? `${user?.city?.name}, ${user?.state?.name}` : 'N/A'}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">
          Residential Address
        </p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.residential_address === "undefined"
            ? "N/A"
            : user?.residential_address}
        </p>
      </div>
    </div>
  );
}

export default Overviews;
