import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {logOut} from "redux/slice/AuthSlice";
import {API_URL} from "utils/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/user/client/`,
  prepareHeaders: (headers, api) => {
    const {auth} = api.getState();

    if (auth.token) {
      headers.set("authorization", `Bearer ${auth.token}`);
      headers.set("Access-Control-Allow-Origin", `*`);
    }
    headers.set("Accept", `application/json`);
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  let err = result?.error;
  if (result?.error && err.data?._meta?.error?.message.includes("logged in")) {
    setTimeout(() => {
      api.dispatch(logOut({redirect: true}));
    }, 1000);
  }
  return result;
};

export const clientGeneralApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "clientGeneralApi",
  tagTypes: ["serviceProviderData", "jobData", "engagementData", "transaction"],
  endpoints: builder => ({
    // service providers
    addServiceProviderToFavorite: builder.mutation({
      query: body => ({
        url: `service-provider-favourites`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["serviceProviderData"],
    }),

    deleteServiceProviderFromFavorite: builder.mutation({
      query: ({id, body}) => ({
        url: `service-provider-favourites/${id}}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["serviceProviderData"],
    }),

    getFavoriteServiceProviders: builder.query({
      query: () => `favourite-service-providers`,
      providesTags: ["serviceProviderData"],
    }),

    getAllServiceProviders: builder.query({
      query: () => `service-providers`,
      providesTags: ["serviceProviderData"],
    }),

    getServiceProviderWithSearch: builder.query({
      query: () => `service-providers`,
      providesTags: ["serviceProviderData"],
    }),

    getSingleServiceProvider: builder.query({
      query: id => `service-providers/${id}`,
      providesTags: ["serviceProviderData"],
    }),

    // jobs

    postJob: builder.mutation({
      query: body => ({
        url: `post-job`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["jobData"],
    }),

    updateJob: builder.mutation({
      query: ({id, body}) => ({
        url: `update-job/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["jobData"],
    }),
    addJobToMarketPlace: builder.mutation({
      query: id => ({
        url: `my-jobs/${id}/status`,
        method: "POST",
        body: {status: "Active"},
      }),
      invalidatesTags: ["jobData"],
    }),
    archiveJob: builder.mutation({
      query: id => ({
        url: `my-jobs/${id}/status`,
        method: "POST",
        body: {status: "Archived"},
      }),
      invalidatesTags: ["jobData"],
    }),
    getAllMyJobs: builder.query({
      query: params => ({
        url: `my-jobs`,
        params,
      }),
      providesTags: ["jobData"],
    }),

    getJobStatistics: builder.query({
      query: () => `my-job-statistics`,
      providesTags: ["jobData"],
    }),

    getJobsByStatus: builder.query({
      query: ({status, params}) => ({
        url: `job-status/${status}`,
        params,
      }),
      providesTags: ["jobData"],
    }),

    getSingleJobDetail: builder.query({
      query: id => `job/show/${id}`,
      providesTags: ["jobData", "engagementData"],
    }),
    closeJob: builder.mutation({
      query: ({id}) => ({
        url: `close-job/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["jobData"],
    }),
    acceptDeclineRequest: builder.mutation({
      query: ({id, type, body}) => ({
        url: `job-requests/${id}/${type}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["jobData"],
    }),
    getClientTransactions: builder.query({
      query: () => `transaction/get-all`,
      providesTags: ["transaction"],
    }),
    getClientRefundHistory: builder.query({
      query: () => `refund-history/get-all`,
      providesTags: ["transaction"],
    }),
    getClientTransactionStats: builder.query({
      query: () => `transaction/stats`,
      providesTags: ["transaction"],
    }),
  }),
});

export const {
  useAddServiceProviderToFavoriteMutation,
  useDeleteServiceProviderFromFavoriteMutation,
  useGetFavoriteServiceProvidersQuery,
  useGetAllServiceProvidersQuery,
  useGetServiceProviderWithSearchQuery,
  useGetSingleServiceProviderQuery,
  usePostJobMutation,
  useUpdateJobMutation,
  useAddJobToMarketPlaceMutation,
  useArchiveJobMutation,
  useGetAllMyJobsQuery,
  useGetJobStatisticsQuery,
  useGetJobsByStatusQuery,
  useGetSingleJobDetailQuery,
  useAcceptDeclineRequestMutation,
  useCloseJobMutation,
  useGetClientTransactionStatsQuery,
  useGetClientTransactionsQuery,
  useGetClientRefundHistoryQuery,
} = clientGeneralApi;
