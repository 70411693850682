import {useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import {UserSkeleton} from ".";
import {useCreateUsernameMutation} from "services/user/userRegApi";
import {LoadingButton} from "@mui/lab";
import {handleError} from "utils";
import {logOutHandler, showToast} from "redux/store.hooks";
import LogoutIcon from "components/Vectors/logout-icon";
import LogOutModal from "templates/DashboardLayout/widgets/LogOutModal";
import {useState} from "react";

const UserCreateUsername = () => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const navigate = useNavigate();
  const methods = useForm({
    mode: "all",
  });

  const [createUsername, {isLoading}] = useCreateUsernameMutation();

  const onSubmit = async value => {
    try {
      let res = await createUsername(value).unwrap();
      showToast(res?.message, "success");
      navigate("/user/select-user");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <UserSkeleton>
      <div
        className="flex h-fit w-full max-w-[600px] flex-col rounded-2xl
          bg-grey_90 p-4 md:p-8 lg:rounded-[35px] lg:px-12 lg:pb-9"
      >
        <h1 className="text-center font-Bai text-2xl font-semibold leading-[60px] tracking-[-4%] md:text-4xl">
          Create a Username
        </h1>
        <p className="mb-2 text-center font-Mulish text-base text-grey_30 md:mb-4 md:mt-1">
          Hello, create a unique username
        </p>
        <FormProvider {...methods}>
          <form
            className="darkBgForm flex flex-col justify-center gap-y-8"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <ValidatedInput2
              label=""
              // prefixIcon="@"
              placeholder="@username"
              name="username"
              rules={{
                validate: value => {
                  if (/\s/.test(value)) {
                    return "Username can't contain space";
                  }
                  if (value.length < 3) {
                    return "Username must be at least 3 characters";
                  }
                  if (value.length > 20) {
                    return "Username must be at most 20 characters";
                  }
                  return true;
                },
              }}
            />
            <LoadingButton
              sx={{
                marginTop: "8px",
              }}
              type="submit"
              loading={isLoading}
              fullWidth
              variant="contained"
              color="primary"
            >
              Submit
            </LoadingButton>
          </form>
        </FormProvider>
        <button
          onClick={() => setOpenLogoutModal(true)}
          className="mt-3 w-full flex justify-center items-center p-2 text-sm font-bold text-[#D92D20]"
        >
          <LogoutIcon color="#D92D20" />
          <span className="ml-3">Log out</span>
        </button>
      </div>
      <LogOutModal
        open={openLogoutModal}
        close={() => setOpenLogoutModal(false)}
        onLogout={() => logOutHandler({type: "user"})}
        desc="You did not finish setting up your profile, your information is saved already, you will continue from where you stopped when you log back in."
      />
    </UserSkeleton>
  );
};
// /${type}/login?redirectTo=${redirectTo}`

export default UserCreateUsername;
