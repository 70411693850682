import { KeyboardArrowRightOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import NewJobCard from 'components/NewJobCard'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import NewOfferCard from 'components/NewOfferCard';
import NewEngagementCard from 'components/NewEngagementCard';
import { Link } from 'react-router-dom';
import LatestUpdates from 'components/LatestUpdates';
import NewStatCard from './Widgets/NewStatCard';
SwiperCore.use([Navigation]);

const NewClientJobs = () => {
    return (
        <main className='w-full bgwhite'>
            <div className="mb-4 md:mb-6 font-semibold text-2xl md:text-[2rem] text-grey-10 flex gap-2 items-center">
                My Jobs
                <img src="/images/Briefcase.png" alt="briefcase" className="" />
            </div>

            <div className="grid lg:grid-cols-12 gap-12 md:gap-6">
                <div className="lg:col-span-8 grid grid-cols-1 md:grid-cols-12 gap-6">
                    <div
                        style={{
                            backgroundImage: "url('/images/post-job-3d.png'), url('/images/send-vector.png')",
                            backgroundPosition: "95% 110%, 97% 40%",
                            backgroundSize: "20%, 10%"
                        }}
                        className="bg-[#EBFAFF] p-8 rounded-3xl md:col-span-7 bg-cover bg-no-repeat"
                    >
                        <p className="font-semibold font-Bai text-2xl mb-9 text-[#005B7A]">
                            Need to get something done? Post it!
                        </p>
                        {/* <p className="font-medium text-sm text-[#625A50] mt-3 mb-5">
                            Get the best hands on your Job now.
                        </p> */}

                        <IconButton
                            sx={{ backgroundColor: "#00BFFF" }}
                            size='small'
                        >
                            <KeyboardArrowRightOutlined fontSize="medium" color="white" />
                        </IconButton>
                    </div>

                    <div className="md:col-span-5 flex flex-col gap-3">
                        <NewStatCard
                            text={"Open Jobs"}
                            value={"20"}
                            btnColor={"#E98100"}
                            link={"/client/jobs/open-jobs"}
                        />
                        <NewStatCard
                            text={"Assigned Jobs"}
                            value={"20"}
                            btnColor={"#3DBB32"}
                            link={"/client/jobs/assigned-jobs"}
                        />
                    </div>

                </div>
                <LatestUpdates
                    maxHeight={"max-h-[200px]"}
                />

            </div>

            {/* Ongoing Engagements */}
            <section className="bg-grey-100 mt-16 -mx-4 md:-mx-6 lg:-mx-[4vw] px-4 md:px-6 lg:px-[4vw] py-6 lg:py-12">
                <Link to='/client/jobs/assigned-jobs' className="mb-8 font-Bai font-semibold text-black flex gap-3 items-center text-2xl md:text-[2rem]">
                    Assigned Jobs
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M36.1785 21.1786C36.8294 20.5277 36.8294 19.4724 36.1785 18.8216L29.5118 12.1549C28.861 11.504 27.8057 11.504 27.1548 12.1549C26.5039 12.8058 26.5039 13.8611 27.1548 14.5119L30.9763 18.3334L4.99998 18.3334C4.07951 18.3334 3.33331 19.0796 3.33331 20.0001C3.33331 20.9206 4.07951 21.6667 4.99998 21.6667L30.9763 21.6667L27.1548 25.4882C26.5039 26.1391 26.5039 27.1944 27.1548 27.8453C27.8057 28.4961 28.861 28.4961 29.5118 27.8453L36.1785 21.1786Z" fill="black" />
                    </svg>
                </Link>

                <Swiper
                    spaceBetween={24}
                    slidesPerView={2.3}
                    modules={[Navigation]}
                    navigation={true}
                    freeMode
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        640: {
                            slidesPerView: 1.5,
                        },
                        900: {
                            slidesPerView: 2.3,
                        },
                        1300: {
                            slidesPerView: 3.3,
                        },
                    }}
                >
                    {/* {allJobs?.data
                        .filter(job => job.status === "Active")
                        .map((job, index) => (
                          <SwiperSlide key={`single_job_${index}`}>
                            <SingleJobCard
                              showUser={false}
                              key={job?.id}
                              job={job}
                              isProfessional={false}
                            />
                          </SwiperSlide>
                        ))} */}
                    {Array(6).fill("").map((_, i) =>
                        <SwiperSlide key={`single_job_${i}`}>
                            <NewEngagementCard />
                        </SwiperSlide>
                    )}
                </Swiper>
            </section>

            {/*  Pending Offers */}
            <section className="bg-grey-100 pt16 -mx-4 md:-mx-6 lg:-mx-[4vw] px-4 md:px-6 lg:px-[4vw] py-6 lg:py-12">
                <button type='button' className="mb-8 font-Bai font-semibold text-black flex gap-3 justify-start items-center text-2xl md:text-[2rem]">
                    Pending Recieved Offers
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M36.1785 21.1786C36.8294 20.5277 36.8294 19.4724 36.1785 18.8216L29.5118 12.1549C28.861 11.504 27.8057 11.504 27.1548 12.1549C26.5039 12.8058 26.5039 13.8611 27.1548 14.5119L30.9763 18.3334L4.99998 18.3334C4.07951 18.3334 3.33331 19.0796 3.33331 20.0001C3.33331 20.9206 4.07951 21.6667 4.99998 21.6667L30.9763 21.6667L27.1548 25.4882C26.5039 26.1391 26.5039 27.1944 27.1548 27.8453C27.8057 28.4961 28.861 28.4961 29.5118 27.8453L36.1785 21.1786Z" fill="black" />
                    </svg>
                </button>

                <Swiper
                    spaceBetween={24}
                    slidesPerView={2.3}
                    modules={[Navigation]}
                    navigation={true}
                    freeMode
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        640: {
                            slidesPerView: 1.5,
                        },
                        900: {
                            slidesPerView: 2.3,
                        },
                        1300: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {Array(6).fill("").map((_, i) =>
                        <SwiperSlide key={`single_job_${i}`}>
                            <NewOfferCard />
                        </SwiperSlide>
                    )}
                </Swiper>



            </section>



            {/* NEWEST JOB */}
            <section className="bg-grey-100 pt16 -mx-4 md:-mx-6 lg:-mx-[4vw] px-4 md:px-6 lg:px-[4vw] py-6 lg:py-12">
                <Link to='/client/jobs/open-jobs' className="mb-8 font-Bai font-semibold text-black flex gap-3 items-center text-2xl  md:text-[2rem]">
                    My Open Jobs
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M36.1785 21.1786C36.8294 20.5277 36.8294 19.4724 36.1785 18.8216L29.5118 12.1549C28.861 11.504 27.8057 11.504 27.1548 12.1549C26.5039 12.8058 26.5039 13.8611 27.1548 14.5119L30.9763 18.3334L4.99998 18.3334C4.07951 18.3334 3.33331 19.0796 3.33331 20.0001C3.33331 20.9206 4.07951 21.6667 4.99998 21.6667L30.9763 21.6667L27.1548 25.4882C26.5039 26.1391 26.5039 27.1944 27.1548 27.8453C27.8057 28.4961 28.861 28.4961 29.5118 27.8453L36.1785 21.1786Z" fill="black" />
                    </svg>
                </Link>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {Array(9).fill("").map((_, i) =>
                        <NewJobCard key={i}/>
                    )}
                </div>
            </section>


        </main >
    )
}

export default NewClientJobs
