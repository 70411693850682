import {Button, Chip, Skeleton, Stack} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchInput from "components/forms/SearchInput";
import {useState} from "react";
import AddWordModal from "../Modals/AddWordModal";
import {
  useGetAllBannedWordsQuery,
  useDeleteBannedWordMutation,
} from "services/admin/adminGeneralApi";
import {showToast} from "redux/store.hooks";

const BannedWordsTab = () => {
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");

  const {data: allWords, isLoading: isAllWordsLoading} =
    useGetAllBannedWordsQuery({
      pollingInterval: 3000,
    });

  const [deleteWord] = useDeleteBannedWordMutation();

  return (
    <div>
      <div className="my-8 flex items-center justify-between">
        <h1 className="font-Bai text-2xl font-semibold text-grey_20 md:text-3xl">
          Banned Words
        </h1>
        <Button variant="contained" onClick={() => setShowModal(true)}>
          Add Words
        </Button>
      </div>
      <div>
        <p className="mb-6 font-medium text-black md:text-lg">
          The following words are not allowed during conversations
        </p>
        <SearchInput
          placeholder="Search Word"
          value={search}
          onChange={(target) => setSearch(target.value)}
        />
        <Stack direction="row" sx={{mt: "24px", flexWrap: "wrap", gap: 1}}>
          {isAllWordsLoading
            ? [1, 2, 3, 4].map(item => (
                <Skeleton key={item} width={70} height={55} />
              ))
            : search.length > 0
            ? allWords?.data
                .filter(word =>
                  word?.name?.toLowerCase().includes(search.toLowerCase()),
                )
                .map(word => (
                  <Chip
                    key={word?.id}
                    label={word?.name}
                    onDelete={() => {
                      deleteWord(word?.id)
                        .unwrap()
                        .then(() => {
                          showToast(
                            `${word?.name} deleted successfully`,
                            "success",
                          );
                        });
                    }}
                    deleteIcon={<ClearOutlinedIcon />}
                    sx={{
                      bgcolor: "#F2F4F7",
                      borderRadius: "8px",
                      color: "#1d1d1d8f",
                      minHeight: "40px",
                      minWidth: "100px",
                      textTransform: "capitalize",
                      fontSize: "14px",
                    }}
                  />
                ))
            : allWords?.data.map(word => (
                <Chip
                  key={word?.id}
                  label={word?.name}
                  onDelete={() => {
                    deleteWord(word?.id)
                      .unwrap()
                      .then(() => {
                        showToast(
                          `${word?.name} deleted successfully`,
                          "success",
                        );
                      });
                  }}
                  deleteIcon={<ClearOutlinedIcon />}
                  sx={{
                    bgcolor: "#F2F4F7",
                    borderRadius: "8px",
                    color: "#1d1d1d8f",
                    minHeight: "40px",
                    minWidth: "100px",
                    textTransform: "capitalize",
                    fontSize: "14px",
                  }}
                />
              ))}
        </Stack>
      </div>
      <AddWordModal
        open={showModal}
        close={() => setShowModal(false)}
        allWords={allWords?.data}
      />
    </div>
  );
};

export default BannedWordsTab;
