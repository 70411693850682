import {Button} from "@mui/material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import {Link} from "react-router-dom";
import FileIcon from "components/Vectors/FileIcon";
import {useGetAllJobsByStatusQuery} from "services/admin/adminGeneralApi";
import EachJobCard from "../widgets/EachJobCard";
import {DisplayData} from "pages/Clients/Dashboard/Main";

const DashboardJobs = ({status}) => {
  const {data, isLoading, error} = useGetAllJobsByStatusQuery({
    status: status === "open" ? "Active" : status,
    params: {is_paginated: "yes", per_page: 15},
  });
  const jobs = data?.data;
  return (
    <div className="h-full">
      <div className="heading mb-6 flex items-center justify-between">
        <h3 className="font-Bai text-base font-medium capitalize text-grey_10 sm:text-2xl">
          {status} Jobs
        </h3>
        {jobs?.length > 0 && (
          <Button
            component={Link}
            to={`/admin/jobs?tab=${status}`}
            variant="text"
            sx={{p: "0 !important"}}
            endIcon={<ArrowForwardOutlinedIcon color="primary" />}
          >
            View all
          </Button>
        )}
      </div>

      <div className="noScroll flex h-full max-h-[570px] flex-col gap-4 overflow-y-auto rounded-2xl bg-white p-4">
        <DisplayData
          isEmpty={jobs?.length === 0}
          emptyResponse={{
            message: `No ${status} Jobs`,
            icon: <FileIcon width="62px" height="62px" stroke="#3B3D3B" />,
          }}
          isError={error}
          error={error}
          isLoading={isLoading}
        >
          {jobs?.map(job => (
            <EachJobCard job={job} />
          ))}
        </DisplayData>
      </div>
    </div>
  );
};

export default DashboardJobs;
