import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import LoadingBtn from "components/mui-components/LoadingButton";
import MyModal from "components/mui-components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import {showToast} from "redux/store.hooks";
import {useCompleteEngagementMutation} from "services/user/userRegApi";
import {handleError} from "utils";
import {useParams} from "react-router-dom";
import CustomFileUploader from "components/forms/CustomFileUploader";

const CompleteProjectModal = ({open, close}) => {
  const methods = useForm();
  const {id} = useParams();
  const [complete, {isLoading}] = useCompleteEngagementMutation();
  const onSubmit = data => {
    const formData = new FormData();
    formData.append("engagement_id", id);
    formData.append("message", data.message);
    if (data.images) formData.append("images[]", data.images);

    complete(formData)
      .unwrap()
      .then(res => {
        showToast(res?.message);
        methods.reset();
        close();
      })
      .catch(err => {
        handleError(err);
      });
  };
  return (
    <MyModal
      openModal={open}
      closeModal={close}
      title="Complete Project"
      maxWidth={500}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="mb-5 mt-3 rounded-[10]">
            <p className="mb-2 font-Mulish text-sm font-medium text-[#0F0F13]">
              Upload file, if any
            </p>
            <CustomFileUploader
              required={false}
              name="images"
              // placeholder="Drag and Drop or Choose File to upload"
              placeholderTypes="1 MB max file, .png and .jpg only"
              maxSize={null}
            />
          </div>
          <ValidatedTextArea2
            name="message"
            label="Final Comments:"
            placeholder="Type a message"
            bordered={false}
            minHeight="min-h-[130px]"
          />
          <LoadingBtn
            loading={isLoading}
            fullWidth
            type="submit"
            sx={{mt: "32px", width: "fit-content"}}
          >
            Submit
          </LoadingBtn>
        </form>
      </FormProvider>
    </MyModal>
  );
};

export default CompleteProjectModal;
