import {useFormContext} from "react-hook-form";
import ValidatedInput2 from "./ValidatedInput2";
import getVideoId from "get-video-id";

const UploadVidLink = ({name, onClick}) => {
  const {watch, setValue} = useFormContext({mode: "all"});

  const watchEmbed = watch("video_link");
  const watchLink = watch(name);

  const {id, service} = getVideoId(`${watchLink}`);

  const handleEmbed = () => {
    if (service.toLowerCase() === "youtube") {
      setValue("video_link", `https://www.youtube.com/embed/${id}`);
      setValue("unembed_vid_link", `https://www.youtube.com/embed/${id}`);
    } else if (service.toLowerCase() === "vimeo") {
      setValue("video_link", `https://player.vimeo.com/video/${id}`);
      setValue("unembed_vid_link", `https://player.vimeo.com/video/${id}`);
    }
  };

  return (
    <div className="rounded-24 border border-dashed bg-white p-6">
      <p className="font-Bai text-lg font-medium">Embed Video</p>
      <span className="mt-1 mb-4 inline-block text-sm text-[#3B3D3B]">
        Share from Vimeo and YouTube
      </span>
      <ValidatedInput2
        label="Paste Embedded URL"
        type="text"
        name="unembed_vid_link"
        placeholder="Paste link"
        rules={{
          validate: value =>
            value === watchEmbed ||
            "Please embed link ",
          pattern: {
            value: /^((http|https|):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
            message: "Invalid URL",
          },
        }}
      />
      <div className="mt-8 flex items-center gap-x-2">
        <button
          onClick={handleEmbed}
          type="button"
          className=" rounded-xl bg-[#FDF2E6] py-3 px-5 text-primary"
        >
          Embed
        </button>
        <button
          type="button"
          onClick={onClick}
          className=" rounded-xl bg-grey_70 py-3 px-5 text-[#B42318]"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
export default UploadVidLink;
