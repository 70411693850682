// import { Button } from "@mui/material";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import LoadingBtn from "components/mui-components/LoadingButton";
import ContactUsFBIcon from "components/Vectors/ContactUsFBIcon";
import ContactUsIGIcon from "components/Vectors/ContactUsIGIcon";
import ContactUsLinkedInIcon from "components/Vectors/ContactUsLinkedInIcon";
import ContactUsLocationIcon from "components/Vectors/ContactUsLocationIcon";
import ContactUsTwitterIcon from "components/Vectors/ContactUsTwitterIcon";
// import RequestOutline from "components/Vectors/RequestOutline";
// import SlantedFileIcon from "components/Vectors/SlantedFileIcon";
import { FormProvider, useForm } from "react-hook-form";
import { showToast } from "redux/store.hooks";
import { useContactUsMutation } from "services/generalApi";
import { handleError } from "utils";

const ContactUs = () => {
  const [sendMessage, { isLoading }] = useContactUsMutation();
  const methods = useForm();
  const onSubmit = data => {
    sendMessage(data)
      .unwrap()
      .then(res => {
        showToast(res?.message);
        methods.reset();
      })
      .catch(err => {
        handleError(err);
      });
  };
  return (
    <main className="!-mt-16 mx-auto max-w-[1440px] -mb60 bg-[#F6F6F6] !pt-16 font-Bai">
      <section className=" px-4 sm:px-6 md:px-12">
        {/* MOBILE */}
        <div className="h-[396px] relative block md:hidden !rounded-[1.5rem] text-white">
          <img src="/images/contact-us-mob.png" alt="" className="!rounded-[1.5rem] object-cover w-full h-full" />

          <div className="absolute translate-x-[10%] -translatey-1/2 w-full mx-auto max-w-[80%] lex-col justify-center items-center bottom-12 left10">
            <p className="mb-[1rem] leadind-[100%] font-Bai font-semibold text-xl text-grey_50">
              Got Questions or enquires?
            </p>
            <p className="font-Bai max-w-[20rem] leading-[118%] font-bold text-2xl sm:text-[2.25rem] md:text-[4rem] text-white">
              We love questions and feedback and we are always happy to help?
            </p>
          </div>
        </div>

        {/* DESKTOP */}
        <div
          style={{
            backgroundImage: 'url("/images/contact-hero-bg.png")',
            backgroundRepeat: "no-repeat",
            objectFit: "cover",
          }}
          className="h-56 md:h-[30rem] relative hidden md:flex justifybetween !rounded-[56px] p-8 text-white md:px-12 lg:pl-[6.7rem] md:py-[3.75rem]"
        >

          <div className='max-w-[33rem] text-center md:text-left lg:self-end'>
            <p className="mb-[1rem] leadind-[100%] font-Bai font-semibold text-xl text-grey_50">
              Got Questions or enquires?
            </p>
            <p className="font-Bai leading-[118%] font-bold text-3xl lg:text-[2.5rem] text-white">
              We love questions and feedback and we are always happy to help?
            </p>
          </div>

          <div className="w-full max-w-[507px] bg-white lg:absolute hidden lg:block top-[5.63rem] right-[5.4rem] p-4 md:p-10 rounded-[2.5rem]">
            <p className="leading-[100%] font-Bai font-semibold text-lg md:text-xl text-grey_10">
              Kindly fill the form below
            </p>

            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className="mt-6 flex flex-col gap-y-4"
              >
                <ValidatedInput2
                  name="name"
                  // label="E-mail"
                  placeholder="Name / Business Name"
                  bg={'#F2F4F7'}
                  className='bg-[#F2F4F7] !min-w-[427px] !text-[#9D9D9D]'
                  bordered={false}
                />
                <ValidatedInput2
                  name="email"
                  // label="Email address"
                  placeholder="E-mail"
                  bg={'#F2F4F7'}
                  className='bg-[#F2F4F7] !min-w-[427px] !text-[#9D9D9D]'
                  bordered={false}
                />
                <ValidatedInput2
                  name="phone_number"
                  // label="Email address"
                  placeholder="Telephone (optional)"
                  bg={'#F2F4F7'}
                  className='bg-[#F2F4F7] !min-w-[427px] !text-[#9D9D9D]'
                  bordered={false}
                  required={false}
                />
                <ValidatedTextArea2
                  name="message"
                  // label="Message"
                  placeholder="Leave message"
                  bg={'#F2F4F7'}
                  className='bg-[#F2F4F7] !min-w-[427px] !text-[#9D9D9D]'
                  bordered={false}
                  height="min-h-[170px]"
                />
                <LoadingBtn
                  loading={isLoading}
                  type="submit"
                  sx={{ width: "fit-content", mt: '4rem' }}
                >
                  Send Message
                </LoadingBtn>
              </form>
            </FormProvider>
          </div>
        </div>
      </section >

      {/* To show on Mobile */}
      <div className="w-full max-w-[507px] block lg:hidden px-4 sm:px-6 md:px-12 mt-20">
        <p className="leading-[100%] font-Bai font-semibold text-lg md:text-xl text-grey_10">
          Kindly fill the form below
        </p>

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="mt-6 flex flex-col gap-y-4"
          >
            <ValidatedInput2
              name="name"
              // label="E-mail"
              placeholder="Name / Business Name"
              bg={'#ffffff'}
              className='bg-[#ffffff] !min-w-[427px] !text-[#9D9D9D]'
              bordered={false}
            />
            <ValidatedInput2
              name="email"
              // label="Email address"
              placeholder="E-mail"
              bg={'#ffffff'}
              className='bg-[#ffffff] !min-w-[427px] !text-[#9D9D9D]'
              bordered={false}
            />
            <ValidatedInput2
              name="phone_number"
              // label="Email address"
              bg={'#ffffff'}
              placeholder="Telephone (optional)"
              className='bg-[#ffffff] !min-w-[427px] !text-[#9D9D9D]'
              bordered={false}
              required={false}
            />
            <ValidatedTextArea2
              name="message"
              // label="Message"
              bg={'#ffffff'}
              placeholder="Leave message"
              className='bg-[#ffffff] !min-w-[427px] !text-[#9D9D9D]'
              bordered={false}
              height="min-h-[170px]"
            />
            <LoadingBtn
              loading={isLoading}
              type="submit"
              sx={{ width: "fit-content", mt: '1.5rem' }}
            >
              Send Message
            </LoadingBtn>
          </form>
        </FormProvider>
      </div>

      <div className="pl-6 sm:pl-10 md:pl-12 lg:pl-[10.31rem] w-full mt-12 lg:mt-[1.86rem]">
        <p className="font-Mulish mb-6 font-normal max-w-[22.38rem] text-grey_20 text-lg">
          You can also get in touch with us through diverse channels, choose whichever you are most convenient with, we’ll be glad to hear from you.
        </p>

        <ul className="flex font-Mulish font-normal max-w-[22.38rem] text-grey_20 text-lg flex-col gap-3">
          {/* <li className="flex gap-2 items-center">
            <ContactUsLocationIcon />
            P.O. Box 221 Isolo, Texas, Nigeria
          </li> */}
          <li className="flex gap-2 items-center">
            <ContactUsLocationIcon />
            info@truelocal.com
          </li>
          {/* <li className="flex gap-2 items-center">
            <ContactUsLocationIcon />
            01 5961275
          </li> */}
        </ul>
      </div>

      <div className="px-4 sm:px-6 md:px-12 mt-20 lg:mt-[21rem] -mb-32 flex flex-col md:items-center w0full">
        <p className="mb-[1rem] leadind-[100%] font-Bai font-semibold text-lg md:text-xl text-grey_30">
          Reach us via our socials
        </p>

        <div className="flex flex-col gap-5 md:flex-row md:items-center">
          <a
            href='https://facebook.com/Truelocalng'
            target="_blank"
            rel="noreferrer"
            className="rounded-full w-fit px-3 py-1 bg-white flex gap-2 5 items-center font-Mulish font-medium text-grey_20 text-sm md:text-sm"
          >
            <ContactUsFBIcon />
            Facebook
          </a>
          <a
            href='https://www.linkedin.com/company/truelocalng/'
            target="_blank"
            rel="noreferrer"
            className="rounded-full w-fit px-3 py-1 bg-white flex gap-2 5 items-center font-Mulish font-medium text-grey_20 text-sm md:text-sm"
          >
            <ContactUsLinkedInIcon />
            LinkedIn
          </a>
          <a
            href='https://instagram.com/truelocalng'
            target="_blank"
            rel="noreferrer"
            className="rounded-full w-fit px-3 py-1 bg-white flex gap-2 5 items-center font-Mulish font-medium text-grey_20 text-sm md:text-sm"
          >
            <ContactUsIGIcon />
            Instagram
          </a>
          <a
            href='https://twitter.com/Truelocalng'
            target="_blank"
            rel="noreferrer"
            className="rounded-full w-fit px-3 py-1 bg-white flex gap-2 5 items-center font-Mulish font-medium text-grey_20 text-sm md:text-sm"
          >
            <ContactUsTwitterIcon />
            Twitter
          </a>
        </div>
      </div>
    </main >
    // <div className="mb-64">
    //   <section className="relative my-10 overflow-x-clip md:my-28">
    //     diffusing backgrounds...
    //     <div className="absolute left-28 -top-16 -z-10 h-96 w-96 rounded-full bg-gradient-to-br from-red-200 via-purple-200 to-blue-light opacity-[0.15] blur-md"></div>
    //     <div className="absolute top-28 left-80 -z-10 h-96 w-96 rounded-full bg-gradient-to-tr from-orange-100 to-purple-200 opacity-20 blur-md"></div>
    //     <div className="absolute -top-28 right-80 -z-10 h-96 w-96 rounded-full bg-purple-200 opacity-20 blur-md"></div>
    //     <div className="absolute -top-28 right-96 -z-10 h-96 w-96 rounded-full bg-red-200 opacity-20 blur-md"></div>
    //     <div className="absolute right-28 -top-16 -z-10 h-96 w-96 rounded-full bg-[#F2F4F7]  opacity-20 blur-md"></div>
    //     <p className="text-center text-sm font-medium text-primary md:-mb-5">
    //       About us
    //     </p>
    //     <h1 className="text-center font-Bai text-5xl font-semibold text-black_text2 md:text-[80px]">
    //       Got a question? <br />
    //       Let’s talk!
    //     </h1>
    //   </section>
    //   <div className="grid gap-14 px-6 md:grid-cols-2 md:px-20">
    //     <section className="rounded-16 bg-white py-8 px-4 md:rounded-[50px] md:p-10">
    //       <div className="relative h-[72px] w-[72px]">
    //         <div className="absolute -right-16 -bottom-14 h-[80px] w-[80px] rounded-full bg-primary bg-opacity-10"></div>
    //         <RequestOutline
    //           stroke="#E98100"
    //           width={150}
    //           height={150}
    //           transparent
    //         />
    //         <div className="absolute -bottom-[70px] -right-[82px] h-[80px] w-[80px] rounded-full bg-primary bg-opacity-[0.04]"></div>
    //       </div>
    //       <h2 className="mt-24 font-Bai text-3xl font-bold text-black_text2 md:text-6xl">
    //         Help center
    //       </h2>
    //       <p className="mb-20 mt-6 font-medium leading-relaxed text-grey_30 md:text-xl md:leading-[44px]">
    //         Need help with anything? Post it on Truelocal there is always
    //         someone available to help Amet adipiscing pellentesque nisl urna
    //         malesuada vulputate sed volutpat gravida available to help Amet
    //         adipiscing pellentesque nisl urna malesuada vulputate sed volutpat
    //         gravida.
    //       </p>
    //       <Button variant="contained">Visit Help center </Button>
    //     </section>

    //     <section className="rounded-16 bg-white px-4 py-8 md:rounded-[50px] md:p-10">
    //       <SlantedFileIcon />
    //       <h2 className="mt-16 font-Bai text-3xl font-bold text-black_text2 md:text-6xl">
    //         Send us a mail
    //       </h2>
    //       <FormProvider {...methods}>
    //         <form
    //           onSubmit={methods.handleSubmit(onSubmit)}
    //           className="mt-6 mb-16 flex flex-col gap-y-6"
    //         >
    //           <ValidatedInput2
    //             name="email"
    //             label="Email address"
    //             placeholder="Enter your email address"
    //           />
    //           <ValidatedTextArea2
    //             name="message"
    //             label="Message"
    //             placeholder="Enter your message"
    //             height="min-h-[130px]"
    //           />
    //           <LoadingBtn
    //             loading={isLoading}
    //             type="submit"
    //             sx={{width: "fit-content"}}
    //           >
    //             Send Message
    //           </LoadingBtn>
    //         </form>
    //       </FormProvider>
    //     </section>
    //   </div>
    // </div>
  );
};

export default ContactUs;
