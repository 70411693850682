import moment from "moment";
import { Avatar } from "@mui/material";
import { DownloadAttachment } from "./ProjectOverdue";

const Responses = ({ responses, bg = "bg-grey_80" }) => {
  console.log({ responses })
  return (
    responses?.length > 0 && (
      <div className={`mt-7 rounded-xl ${bg} p-4`}>
        <h1 className="mb-10 font-Bai text-[32px] font-semibold text-grey_20">
          Responses
        </h1>
        {responses.map(response => (
          <div key={response.id} className="mb-10 flex items-start gap-4">
            <Avatar
              sx={{ width: "56px", height: "56px" }}
              src={response?.user?.avatar}
              alt="userAvatar"
            />
            <div className="basis-full">
              <h3 className="flex items-center gap-2 text-xl font-medium text-grey_20">
                {response?.user_role === 'Admin' ?
                  'Admin' : response?.user
                    ? `${response?.user.first_name} ${response?.user.last_name}`
                    : ""}{" "}
                <span className="text-sm text-primary">
                  {response?.user_role === 'Admin' ? '' : response?.user?.user_type}
                </span>
              </h3>
              <p className="mt-2 text-sm font-normal text-grey_40">
                {moment(response?.created_at).format("MMM DD, YYYY, h:mm a")}
              </p>
              <p className="mt-5 text-base font-normal text-grey_20">
                {response?.response}
              </p>
              <DownloadAttachment url={response?.attachment} size="small" />
            </div>
          </div>
        ))}
      </div>
    )
  );
};

export default Responses;
