import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import MyModal from "components/mui-components/Modal";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import {useCreateRoleMutation} from "services/admin/adminGeneralApi";
import LoadingBtn from "components/mui-components/LoadingButton";
import MessageModal from "components/mui-components/Modal/MessageModal";
import SendIcon from "components/Vectors/SendIcon";
import {handleError} from "utils";

const CreateRoleModal = ({open, onClose}) => {
  const [showDoneModal, setShowDoneModal] = useState(false),
    [createRole, {isLoading}] = useCreateRoleMutation();

  const {handleSubmit, ...methods} = useForm();
  const onSubmit = async data => {
    try {
      await createRole(data).unwrap();
      methods.reset();
      setShowDoneModal(true);
    } catch (err) {
      handleError(err);
    }
  };
  return (
    <MyModal openModal={open} closeModal={onClose} title="Create Role">
      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-y-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ValidatedInput2
            name="role_name"
            label="Role Name"
            placeholder="Enter Role Name"
          />
          <ValidatedTextArea2
            name="description"
            label="Description"
            placeholder="Write something about the role here"
            height="min-h-[140px]"
          />
          <LoadingBtn type="submit" fullWidth sx={{mt: 2}} loading={isLoading}>
            Submit
          </LoadingBtn>
        </form>
      </FormProvider>
      <MessageModal
        title="Role created successfully"
        icon={<SendIcon />}
        openModal={showDoneModal}
        closeModal={() => {
          onClose();
          setShowDoneModal(false);
        }}
      />
    </MyModal>
  );
};

export default CreateRoleModal;
