import {Button} from "@mui/material";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";
import {useEffect, useState} from "react";
import {
  useCreateEditBannerMutation,
  useGetAdminBannersQuery,
} from "services/admin/adminGeneralApi";
import EachBanner from "../components/EachBanner";
import EmptyResponse from "components/mui-components/EmptyResponse";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import MyModal from "components/mui-components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import {handleError} from "utils";
import {showToast} from "redux/store.hooks";
import CustomFileUploader from "components/forms/CustomFileUploader";
import {LoadingButton} from "@mui/lab";
import ValidatedInput2 from "components/forms/ValidatedInput2";

function HomepageBanner() {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [modalMode, setmodalMode] = useState("create");

  const {data, isLoading, isError, error} = useGetAdminBannersQuery();

  const methods = useForm();
  const [createEdit, {isLoading: isCreateLoading}] =
    useCreateEditBannerMutation();

  const onSubmit = async body => {
    const formData = new FormData();
    formData.append("banner", body.banner);
    formData.append("description", body.description);
    formData.append("title", body.title);

    try {
      let res = await createEdit({
        body: formData,
        type: modalMode === "create" ? "create" : "update",
      }).unwrap();
      showToast(res?.message);
      methods?.reset();
      setmodalMode("create");
      setShowModal(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (selectedItem && modalMode === "edit") {
      methods.setValue("banner", selectedItem?.banner);
      methods.setValue("description", selectedItem?.description);
      methods.setValue("title", selectedItem?.title);
    } else {
      methods?.reset();
    }
  }, [methods, selectedItem, modalMode]);

  if (isLoading) return <CircularLoader />;
  if (isError) return <ErrorMsg error={error} />;

  return (
    <div>
      <div className="my-8 flex items-center justify-between">
        <h1 className="font-Bai text-2xl font-semibold text-grey_20 md:text-3xl">
          Banners
        </h1>
        <Button
          variant="contained"
          onClick={() => {
            setmodalMode("create");
            setselectedItem(null);
            setShowModal(true);
          }}
        >
          Create New
        </Button>
      </div>
      {data?.data?.length <= 0 && (
        <EmptyResponse
          icon={<ViewCarouselOutlinedIcon sx={{color: "#6E726E"}} />}
          message={"No homepage banners has been created yet"}
        />
      )}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {data?.data?.map(item => (
          <EachBanner
            onPreview={() => {
              setselectedItem(item);
            }}
            onEdit={() => {
              setselectedItem(item);
              setmodalMode("edit");
              setShowModal(true);
            }}
            key={`banner_${item?.id}`}
            item={item}
          />
        ))}
      </div>

      <MyModal
        openModal={showModal}
        closeModal={() => {
          methods?.reset();
          setmodalMode("create");
          setShowModal(false);
        }}
        title={`${modalMode === "create" ? "Create New" : "Edit"} Banner`}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="space-y-5">
              {/* <ValidatedInput2
                name="title"
                label="Banner Title"
                placeholder="Enter banner title"
              /> */}
              <ValidatedInput2
                rules={{
                  validate: value =>
                    value.split(" ").length < 3 ||
                    "Changing word must not be more than 2 words",
                }}
                name="title"
                label="Changing Word"
                placeholder="Enter changing word"
              />
              <CustomFileUploader
                required={true}
                fileType={"image"}
                defaultUrl={modalMode === "edit" ? selectedItem?.banner : null}
                className="h-[unset] min-h-[200px] justify-center md:flex-col"
                placeholder={
                  <span>
                    <span className="text-primary">Upload a file</span> or drag
                    and drop file here
                  </span>
                }
                label="Right side image"
                name="banner"
                types={["png", "jpg", "jpeg"]}
              />
              <LoadingButton
                fullWidth
                type="submitx`"
                loading={isCreateLoading}
                variant="contained"
              >
                {modalMode === "create" ? "Create" : "Edit"} Banner
              </LoadingButton>
            </div>
          </form>
        </FormProvider>
      </MyModal>
    </div>
  );
}

export default HomepageBanner;
