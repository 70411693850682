import OtpInput from "components/forms/OtpInput";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {UserSkeleton} from ".";
import {selectEmail, selectPhoneNumber} from "redux/slice/AuthSlice";
import {useDispatch, useSelector} from "react-redux";
import {
  useUserVerifyOtpMutation,
  useUserResendOtpMutation,
} from "services/auth.api";
import {logOutHandler, showToast} from "redux/store.hooks";
import {handleError} from "utils";
import {LoadingButton} from "@mui/lab";
import {CircularProgress} from "@mui/material";
import {setUserDetails, setUserToken} from "redux/slice/AuthSlice";
import LogoutIcon from "components/Vectors/logout-icon";
import LogOutModal from "templates/DashboardLayout/widgets/LogOutModal";

const UserVerifyOtp = () => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setotp] = useState("");
  const [error, seterror] = useState(false);

  const email = useSelector(selectEmail);
  const phone_number = useSelector(selectPhoneNumber);
  const url = window.location.origin;

  const [verifyOtp, {isLoading: isVerifyLoading}] = useUserVerifyOtpMutation();
  const [resendOtpCode, {isLoading: isResendLoading}] =
    useUserResendOtpMutation();

  // countdown timer for otp to be resent
  const [counter, setCounter] = useState(12);
  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setCounter(0);
    }
  }, [counter]);

  const onSubmit = async e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("otp", otp);
    formData.append("redirect_url", `${url}/user/login`);

    if (otp.length < 4) {
      seterror(true);
    } else {
      try {
        let res = await verifyOtp(formData).unwrap();
        dispatch(setUserToken(res?.data?.access_token));
        dispatch(setUserDetails(res?.data));
        navigate("/user/create-username");
        showToast(res?.message, "success");
      } catch (error) {
        handleError(error);
      }
    }
  };

  const handleResend = async () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("phone_number", phone_number);

    try {
      let res = await resendOtpCode(formData).unwrap();
      showToast(res?.message, "success");
      setotp("");
      setCounter(12);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <UserSkeleton>
      <form onSubmit={onSubmit}>
        <div
          className="my-10 flex h-fit flex-col items-center rounded-2xl bg-grey_90 p-4 md:my-0
        md:h-[450px] md:w-full md:max-w-[570px] md:p-[32px] lg:rounded-[35px] lg:py-[60px] lg:px-[3rem]"
        >
          <h1 className="font-Bai text-xl font-semibold leading-[60px] tracking-[-4%] md:text-4xl">
            Verify it’s you
          </h1>
          <p className="text-center font-Mulish text-base font-[400] text-grey_30">
            We sent a one time verification code to
            <span className="text-primary"> {email} </span>
            {/* and{" "}<span className="text-primary "> {phone_number}</span> */}
          </p>
          <div className="">
            <OtpInput
              error={error}
              value={otp}
              onChange={val => {
                if (val.length === 4) seterror(false);
                setotp(val);
              }}
            />
          </div>
          <p className="text-primary">
            {counter > 0 && <span>00 : {counter}</span>}
          </p>{" "}
          {counter <= 0 && (
            <p className="font-Mulish text-[13px] font-normal ">
              Didn’t recieve OTP?{" "}
              {isResendLoading ? (
                <span className="font-Bai text-primary ">Sending...</span>
              ) : (
                <button
                  type="button"
                  // onClick={onResend}
                  onClick={handleResend}
                  className="cursor-pointer font-Bai text-primary "
                >
                  Resend code
                </button>
              )}
            </p>
          )}
          <LoadingButton
            sx={{
              marginTop: "8px",
            }}
            loadingIndicator={
              <CircularProgress
                sx={{
                  color: "#fff",
                }}
                size="1.2rem"
              />
            }
            type="submit"
            loading={isVerifyLoading}
            fullWidth
            variant="contained"
            color="primary"
          >
            Verify
          </LoadingButton>
          <Link
            to={""}
            onClick={() => navigate(-1)}
            className="mt-2 w-full py-4 text-center font-Bai font-medium text-grey_20"
          >
            Cancel
          </Link>
          <button
            onClick={() => setOpenLogoutModal(true)}
            className="mt-3 flex w-full items-center justify-center p-2 text-sm font-bold text-[#D92D20]"
          >
            <LogoutIcon color="#D92D20" />
            <span className="ml-3">Log out</span>
          </button>
        </div>
      </form>

      <LogOutModal
        open={openLogoutModal}
        close={() => setOpenLogoutModal(false)}
        onLogout={() => logOutHandler({type: "user"})}
        desc="You did not finish setting up your profile, your information is saved already, you will continue from where you stopped when you log back in."
      />
    </UserSkeleton>
  );
};
export default UserVerifyOtp;
