import "../../LandingPage/myStyles.scss";
import ActiveEngagement from "components/Vectors/ActiveEngagement";
import Request from "components/Vectors/Request";
import Jobs from "components/Vectors/Jobs";
import NoActiveRequest from "components/Vectors/NoActiveRequest";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import SingleEngagement from "./SingleEngagement";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  useGetOnboardingStatusQuery,
  useGetAllEngagementByStatusQuery,
} from "services/user/userRegApi";
import { useGetAllMyJobsQuery } from "services/user/clientGeneralApi";
import SwiperCore, { Navigation } from "swiper";
import CircularLoader from "components/Loader/CircularLoader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import { Tab } from "@mui/material";
import SingleJobCard from "components/SingleJobCard";
import { useGetUser } from "hooks/getUserHook";
import EmptyResponse from "components/mui-components/EmptyResponse";
import ErrorMsg from "components/mui-components/ErrorMsg";
import ProtectedBtn from "components/ProtectedBtn";
import Users3 from "components/Vectors/Users3";
import ClosedJobIcon from "components/Vectors/ClosedJobIcon";
import { JobIcon3 } from "components/Vectors/JobIcon2";
SwiperCore.use([Navigation]);

// a utility component to display data based on an API request
export const DisplayData = ({
  error,
  isLoading,
  isError,
  children,
  emptyResponse = {},
  isEmpty = false,
}) => (
  <>
    {isLoading ? (
      <CircularLoader />
    ) : isError ? (
      <ErrorMsg error={error} />
    ) : isEmpty ? (
      <EmptyResponse {...emptyResponse} />
    ) : (
      children
    )}
  </>
);

const Main = () => {
  const [activeTab, setActiveTab] = useState("open");
  const navigate = useNavigate();
  const {
    data: allEngagements,
    isLoading: isEngLoading,
    isError: isEngError,
    error: engError,
  } = useGetAllEngagementByStatusQuery({ type: "client", status: "Ongoing" });

  const { data: onBoardingStatus } = useGetOnboardingStatusQuery();
  const { user } = useGetUser();
  const {
    data: allJobs,
    isLoading,
    isError,
    error,
  } = useGetAllMyJobsQuery("", {
    refetchOnMountOrArgChange: true,
  });

  const tabs = [
    {
      title: "Open Jobs",
      count: allJobs?.data?.filter(job => job.status === "Active").length,
      icon: <JobIcon3 color="#039855" />,
    },
    {
      title: `Closed Jobs`,
      count: allJobs?.data.filter(job => job.status === "Closed").length,
      icon: <ClosedJobIcon color="#D92D20" />,
    },
    {
      title: `Ongoing Engagements`,
      count: user?.ongoing_engagements_count,
      icon:<Users3 color="#5010B3" />,
    },
  ];
  if (isLoading || isEngLoading) return <CircularLoader />;
  return (
    <div>
      <main className="rounded-lg md:rounded-2xl md:bg-white">
        <Header
          onBoardingStatus={onBoardingStatus?.data}
          isProfessional={false}
        />
        <div className="hidden h-px bg-[#EEEEEE] md:block"></div>
        <div className="p-2 md:py-10 md:px-10">
          <h2 className="mb-3 font-Bai text-xl font-semibold md:hidden">
            Overview
          </h2>
          <div className="hideScroll flex flex-row gap-4 md:gap-8 overflow-scroll md:overflow-auto">
            {tabs.map(({ title, count, icon }, index) => (
              <div
                key={index}
                className="flex min-w-[200px] basis-1/3 flex-col justify-between gap-y-5 rounded-20 bg-white p-6 md:bg-grey_70"
              >
                <p className="font-Inter text-text">{title}</p>
                <div className="flex flex-row justify-between md:flex-col md:gap-y-5">
                  <span className="font-Bai text-2xl font-semibold text-ltext md:font-normal">
                    {count}
                  </span>
                  {icon}
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-center md:hidden my-7">
            <ProtectedBtn
              fullWidth
              link="/client/post-job"
              btnText="Post A Job"
            />
          </div>

          {/* Engagement */}
          <section className="mt-0 md:mt-16 mb-8">
            <div className="mt-6">
              <TabContext value={activeTab}>
                <TabList
                  variant="scrollable"
                  value={activeTab}
                  className="client_dashboard_fourth mb-4 w-fit rounded-[16px] border border-grey_60 bg-grey_80 p-2"
                  onChange={(_, newValue) => {
                    navigate(`?tab=${newValue}`);
                    setActiveTab(newValue);
                  }}
                  aria-label="basic tabs example"
                >
                  <Tab label="Open Jobs" value="open" />
                  <Tab label="Closed Jobs" value="closed" />
                </TabList>
                <TabPanel value="open">
                  <DisplayData
                    emptyResponse={{
                      message: "No jobs yet",
                      icon: <NoActiveRequest />,
                    }}
                    isError={isError}
                    error={error}
                    isLoading={isLoading}
                    isEmpty={
                      allJobs?.data.filter(job => job.status === "Active")
                        .length === 0
                    }
                  >
                    <Swiper
                      spaceBetween={40}
                      slidesPerView={2.3}
                      modules={[Navigation]}
                      navigation={true}
                      freeMode
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        640: {
                          slidesPerView: 1.5,
                        },
                        900: {
                          slidesPerView: 2.3,
                        },
                        1300: {
                          slidesPerView: 3.3,
                        },
                      }}
                    >
                      {allJobs?.data
                        .filter(job => job.status === "Active")
                        .map((job, index) => (
                          <SwiperSlide key={`single_job_${index}`}>
                            <SingleJobCard
                              showUser={false}
                              key={job?.id}
                              job={job}
                              isProfessional={false}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </DisplayData>
                </TabPanel>

                <TabPanel value="closed">
                  <DisplayData
                    emptyResponse={{
                      message: "No closed jobs yet",
                      icon: <NoActiveRequest />,
                    }}
                    isLoading={isLoading}
                    isEmpty={
                      allJobs?.data.filter(job => job.status === "Closed")
                        .length === 0
                    }
                  >
                    <Swiper
                      spaceBetween={40}
                      slidesPerView={2.3}
                      modules={[Navigation]}
                      navigation={true}
                      freeMode
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        640: {
                          slidesPerView: 1.5,
                        },
                        900: {
                          slidesPerView: 2.3,
                        },
                        1300: {
                          slidesPerView: 3.3,
                        },
                      }}
                    >
                      {allJobs?.data
                        .filter(job => job.status === "Closed")
                        .map((job, index) => (
                          <SwiperSlide key={`single_job_${index}`}>
                            <SingleJobCard
                              showUser={false}
                              key={job?.id}
                              job={job}
                              isProfessional={false}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </DisplayData>
                </TabPanel>
              </TabContext>
            </div>
          </section>
          <section className="my-14">
            <header className="mb-3 flex items-center justify-between font-Bai font-semibold">
              <h2 className="text-xl md:text-3xl">Ongoing Engagements </h2>
              {allEngagements?.data?.length > 0 && (
                <Link
                  to={"/client/engagements"}
                  className="text-lg text-primary"
                >
                  View all
                  <ChevronRightOutlinedIcon color="primary" />
                </Link>
              )}
            </header>
            <DisplayData
              emptyResponse={{
                message: "No Ongoing Engagement yet",
                icon: <NoActiveRequest />,
              }}
              isLoading={isEngLoading}
              isError={isEngError}
              error={engError}
              isEmpty={allEngagements?.data?.length === 0}
            >
              <Swiper
                spaceBetween={40}
                slidesPerView={2.3}
                modules={[Navigation]}
                navigation={true}
                freeMode
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  640: {
                    slidesPerView: 1.5,
                  },
                  900: {
                    slidesPerView: 2,
                  },
                  1300: {
                    slidesPerView: 2.5,
                  },
                }}
              >
                {allEngagements?.data.map((engagement, index) => (
                  <SwiperSlide key={`single_engagement_${index}`}>
                    <SingleEngagement engagement={engagement} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </DisplayData>
          </section>
        </div>
      </main>
    </div>
  );
};

export default Main;
