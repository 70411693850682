const Services = () => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3042_22556)">
        <path
          d="M4.68164 4.5H16.6816V16.5H4.68164V4.5ZM19.6816 4.5H31.6816V16.5H19.6816V4.5ZM4.68164 19.5H16.6816V31.5H4.68164V19.5ZM27.1816 19.5H24.1816V24H19.6816V27H24.1816V31.5H27.1816V27H31.6816V24H27.1816V19.5Z"
          fill="#E98100"
        />
      </g>
      <defs>
        <clipPath id="clip0_3042_22556">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="translate(0.181641)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Services;
