import {useFormContext, get} from "react-hook-form";
import InputField2 from "./InputField2";

function ValidatedInput2({
  name,
  required = true,
  rules,
  errMsg,
  showErrMsg = true,
  ...otherProps
}) {
  const {
    register,
    formState: {errors},
  } = useFormContext({
    mode: "all",
  });

  const error = get(errors, name);

  return (
    <div className="form-group-wrapper">
      <InputField2
        isInvalid={error}
        error={error ? true : false}
        {...otherProps}
        {...register(name, {
          required: required ? "This field is required" : false,
          ...(otherProps.type === "password"
            ? {
                minLength: {
                  value: 8,
                  message: "Password must have at least 8 characters",
                },
                pattern: {
                  value: /(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
                  message:
                    "Password must have at least 1 uppercase, lowercase, number and a symbol",
                },
              }
            : {}),
          // ...(otherProps.type === "number"
          //   ? {
          //       valueAsNumber: true,
          //       pattern: {
          //         value: /^[1-9]\d*/,
          //         message: "Number must be greater than 0",
          //       },
          //     }
          //   : {}),
          ...rules,
        })}
      />
      {showErrMsg && error && (
        <div className="input-err-msg">{error.message || errMsg}</div>
      )}
    </div>
  );
}

export default ValidatedInput2;
