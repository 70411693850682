import {Controller, get, useFormContext} from "react-hook-form";
import {isValidPhoneNumber} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./style.scss";

const PhoneNumberInput = ({
  label = "Phone Number",
  name,
  required = true,
  className,
  textSize = "text-[14px]",
  border
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const error = get(errors, name);

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={name}
          className={`font-Mulish font-[400] ${textSize} text-grey_30`}
        >
          {label}
        </label>
      )}
      <div
        className={`mt-[5px]  rounded-[1rem] ${border && 'border focus-within:border-[#222222]'}  bg-[#F9FAFB] p-4 ${
          error && "invalid border-error focus-within:border-error "
        } `}
      >
        <Controller
          name={name}
          control={control}
          rules={{
            required: required ? "This field is required" : "",
            validate: value =>
              isValidPhoneNumber(value) ||
              "Incorrect format, please include country code e.g +2348012345678",
          }}
          render={({field: {onChange, value}}) => (
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              value={value}
              onChange={onChange}
              defaultCountry="NG"
            />
          )}
        />
      </div>
      {error && (
        <div className="input-err-msg pt-[-10rem] ">{error.message || ""}</div>
      )}
    </div>
  );
};
export default PhoneNumberInput;
