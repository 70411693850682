import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import moment from "moment";
import {useGetBonusTokensQuery} from "services/admin/adminGeneralApi";

const BonusTokenTab = props => {
  const {data, isLoading, error, isError} = useGetBonusTokensQuery();

  const mockData = data?.data.map((item, i) => ({
    user: {
      name: item?.user?.full_name,
      avatar: `${item.professional_information?.business_logo || item.avatar}`,
    },
    package: item?.source?.toLowerCase()?.includes("from admin")
      ? "Admin Bonus"
      : "Sign up Bonus",
    allToken: item?.quantity,
    date: moment(item?.created_at).format("DD MMM YYY h:mm a"),
    id: item?.id,
  }));

  return (
    <div>
      <div className=" w-full ">
        <MyDataTable
          scrollX={false}
          isLoading={isLoading}
          isError={isError}
          data={mockData}
          error={error}
          headCells={headCells}
        />
      </div>
    </div>
  );
};

export default BonusTokenTab;

const headCells = [
  {
    name: "user",
    label: "User",
    options: {
      customBodyRender: value => (
        <UserDisplay name={value.name} img={value.avatar} />
      ),
    },
  },
  {
    name: "package",
    label: "Package Name",
    options: {
      filter: true,
    },
  },
  {
    name: "allToken",
    label: "Number Of Tokens",
  },
  {
    name: "date",
    label: "Date Purchased",
  },
];
