import EmptyJobRequest from "./EmptyJobRequest";
import JobRequestCard from "./JobRequestCard";

const RequestTab = ({data}) => {
  return (
    <>
      {data ? (
        <div className="grid gap-5 md:grid-cols-2">
          {requests.map(request => (
            <JobRequestCard request={request} />
          ))}
        </div>
      ) : (
        <EmptyJobRequest />
      )}
    </>
  );
};
export default RequestTab;

const requests = [
  {
    id: "1",
    imgUrl: "/images/jobRequestImage.png",
    username: "Mirian Achonwa",
    user_type: "Client",
    user_image: "/images/bosun_pic.png",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "2",
    imgUrl: "/images/jobRequestImage.png",
    username: "Mirian Achonwa",
    user_type: "Client",
    user_image: "/images/bosun_pic.png",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "3",
    imgUrl: "/images/jobRequestImage.png",
    username: "Mirian Achonwa",
    user_type: "Client",
    user_image: "/images/bosun_pic.png",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "4",
    imgUrl: "/images/jobRequestImage.png",
    username: "Mirian Achonwa",
    user_type: "Client",
    user_image: "/images/bosun_pic.png",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
];
