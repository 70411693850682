import {useState} from "react";
import {Link, useParams} from "react-router-dom";
import {data} from "pages/ServiceProviders/JobRequest/data";
import {Avatar, Button} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayOutlined";
import GridViewIcon from "@mui/icons-material/GridView";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import StarIcon from "@mui/icons-material/Star";
import Video from "components/mui-components/Modal/Video";
import JobDetailSingleOffer from "pages/ServiceProviders/MarketPlace/JobDetailSingleOffer";
import RequestModal from "components/RequestModal";
import ImageModal from "./mui-components/Modal/ImageModal";
import ImageSlideShow from "./mui-components/Modal/ImageSlideshow";

const SingleJobRequestPage = ({isClient}) => {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  // const navigate = useNavigate();
  const statusText = {
    pending: "pending",
    withdrawn: "Request Withdrawn",
    declined: "Request Declined",
  };
  const handleRejectClick = () => {
    setShowRequestModal(true);
    setIsRejecting(true);
  };
  const handleWithdrawClick = () => {
    setShowRequestModal(true);
    setIsRejecting(true);
  };
  const {id} = useParams();

  const {price, desc, time, location, title, status} = data.find(
    request => request.id === id,
  );
  const detail = {
    name: "You",
    img: "/images/notif_img.png",
    time: "1hr ago",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
    rating: "4.0",
  };

  return (
    <>
      <div>
        <Link
          to={
            isClient ? "/client/job-request" : "/service-provider/job-request"
          }
          className="flex w-fit items-center gap-x-2.5 font-medium text-grey_20"
        >
          <span className="flex h-6 w-6 items-center justify-center rounded-full bg-white">
            <KeyboardArrowLeftIcon sx={{fontSize: "1rem"}} />
          </span>
          Job Requests
        </Link>
      </div>

      <section className="mt-8 rounded-20 bg-white p-4 md:p-6">
        <div className="flex flex-col justify-between gap-y-3 md:flex-row">
          <h1 className="basis-2/5 font-Bai text-2xl font-bold text-grey_20 md:text-4xl">
            {title}
          </h1>
        </div>

        <div className="my-3 flex flex-col items-start justify-between gap-y-3 md:my-0 md:flex-row">
          <p className="inline-block rounded-lg bg-grey_70 py-1.5 px-3 text-sm capitalize text-grey_20 md:mt-8 md:mb-10">
            {isClient ? status : "open"}
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-4">
          <div className="rounded-20 bg-grey_80 p-6">
            <div className="flex items-center justify-between text-grey_20">
              <span>Date Posted</span>
              <CalendarTodayIcon />
            </div>
            <p className="mt-6 text-lg font-bold text-ltext">{time}</p>
          </div>
          <div className="rounded-20 bg-grey_80 p-6">
            <div className="flex items-center justify-between text-grey_20">
              <span>Category</span>
              <GridViewIcon />
            </div>
            <p className="mt-6 text-lg font-bold text-ltext">
              Repairs & Maintenance
            </p>
          </div>
          <div className="rounded-20 bg-grey_80 p-6">
            <div className="flex items-center justify-between text-grey_20">
              <span>Delivery time</span>
              <AccessTimeOutlinedIcon />
            </div>
            <p className="mt-6 text-lg font-bold text-ltext">Flexible </p>
          </div>
          <div className="rounded-20 bg-grey_80 p-6">
            <div className="flex items-center justify-between text-grey_20">
              <span>Location</span>
              <FmdGoodOutlinedIcon />
            </div>
            <p className="mt-6 text-lg font-bold text-ltext">{location} </p>
          </div>
        </div>

        <div className="mt-12 grid justify-between gap-10 md:grid-cols-2">
          {/* First column */}
          <div>
            <p className="font-Bai text-lg capitalize text-grey_30 md:text-xl">
              price
            </p>
            <p className="font-Bai text-2xl font-bold text-grey_20 md:text-3xl">
              {price}
            </p>
            <h2 className="mt-7 font-Bai text-lg capitalize text-grey_30 md:text-xl">
              Description
            </h2>
            <p className="mt-2 mb-8 font-medium text-grey_20 md:pr-20">
              {desc}
            </p>
            {/* <div className="grid grid-cols-2 gap-y-6 md:grid-cols-4">
              {imgList.map((img, index) => (
                // <img
                //   key={index}
                //   src={img}
                //   alt="about"
                //   className="h-[120px] w-[120px] rounded-16 object-cover"
                // />
                <ImageModal
                  key={index}
                  img={img}
                />
              ))}
            </div> */}
            <ImageSlideShow />
            <div className="my-6">
              <h3 className="mb-4 font-Bai font-medium">Videos</h3>
              <Video />
              {isClient ? null : (
                <div className="mb-12 mt-8 grid grid-cols-2 gap-x-4">
                  {[
                    {title: "Total views", count: 10},
                    {title: "Total Offers", count: 10},
                  ].map(({title, count}, index) => (
                    <div
                      className="flex flex-col gap-y-2 rounded-20 bg-grey_80 p-4 font-bold"
                      key={index}
                    >
                      {title}
                      <span className="font-Bai text-2xl font-bold text-grey_20">
                        {count}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* second column */}
          {isClient ? (
            <div>
              <div className="rounded-16 bg-grey_80 p-3 md:rounded-[32px] md:p-8">
                <div className="flex items-start justify-between">
                  <div className="flex items-center gap-x-2 md:gap-x-3">
                    <Avatar
                      alt="Remy Sharp"
                      src="/images/notif_img.png"
                      sx={{
                        width: {xs: 38, md: 48},
                        height: {xs: 38, md: 48},
                      }}
                    />
                    <div className="flex flex-col">
                      <span className="font-Bai text-base font-bold text-grey_20 md:text-lg">
                        Mirian Achonwa
                      </span>
                      <span className="text-sm text-grey_30">@Mirian</span>
                    </div>
                  </div>
                  <div className="flex gap-x-1 rounded-lg bg-white py-1 px-2 text-[13px] text-primary">
                    <StarIcon fontSize="small" sx={{color: "#E98100"}} />
                    4.0
                  </div>
                </div>
                <div className="mt-6 flex flex-col items-center justify-between md:flex-row">
                  <span className="flex items-center text-grey_30">
                    <FmdGoodOutlinedIcon /> Physical - Ketu, Lagos
                  </span>
                  <Button
                    component={Link}
                    to="/services/mirian"
                    endIcon={<ArrowForwardIcon />}
                  >
                    View Profile
                  </Button>
                </div>
                <div className="my-8 h-px bg-grey_50"></div>
                <p className="mb-2 font-Bai text-2xl font-semibold text-grey_30">
                  Request Message
                </p>
                <p className="font-medium leading-relaxed text-grey_20">
                  Ac duis accumsan consectetur pellentesque pellentesque enim eu
                  duis eros. Egestas augue quis posuere semper. Mus consectetur
                  magna tempus massa. Semper aliquet amet a pulvinar mi gravida
                  feugiat id pellentesque ac ultricies vivamus tincidunt
                  habitant.
                </p>
                <div className="mt-10 flex items-center gap-4 md:justify-end">
                  <button
                    type="button"
                    onClick={handleRejectClick}
                    className="w-1/2 rounded-16 border border-[#D92D20] py-4 font-medium text-[#D92D20] hover:bg-[#D92D20] hover:bg-opacity-5 md:w-[140px]"
                  >
                    Decline
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowRequestModal(true)}
                    className="w-1/2 rounded-16 bg-primary py-4 font-medium text-white hover:bg-opacity-90 md:w-[140px]"
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              {/* <div className="rounded-16 bg-grey_80 p-3 md:rounded-[32px] md:p-8">
                <div className="flex items-start justify-between">
                  <div className="flex items-center gap-x-2 md:gap-x-3">
                    <Avatar
                      alt="Remy Sharp"
                      src="/images/notif_img.png"
                      sx={{
                        width: { xs: 38, md: 48 },
                        height: { xs: 38, md: 48 },
                      }}
                    />
                    <div className="flex flex-col">
                      <span className="font-Bai text-base font-bold text-grey_20 md:text-lg">
                        Mirian Achonwa
                      </span>
                      <span className="text-sm text-grey_30">@Mirian</span>
                    </div>
                  </div>
                  <div className="flex gap-x-1 rounded-lg bg-white py-1 px-2 text-[13px] text-primary">
                    <StarIcon fontSize="small" sx={{ color: "#E98100" }} />
                    4.0
                  </div>
                </div>
                <div className="mt-6 flex flex-col items-center justify-between md:flex-row">
                  <span className="flex items-center text-grey_30">
                    <FmdGoodOutlinedIcon /> Physical - Ketu, Lagos
                  </span>
                  <Button
                    component={Link}
                    to="/services/mirian" //I Don't think this link should be like this, it's redirecting to client
                    endIcon={<ArrowForwardIcon />}
                  >
                    View Profile
                  </Button>
                </div>
              </div> */}
              <section className="mt-8">
                <h2 className="font-Bai text-2xl font-semibold text-grey">
                  Your Pitch message
                </h2>
                <div className="mt-6 rounded-24 bg-grey_70 p-4">
                  <div className="rounded-20 bg-white p-3 md:p-6">
                    <div className="flex items-start justify-between">
                      <div className="flex items-center gap-x-3">
                        <Avatar
                          alt={detail.name}
                          src={detail.img}
                          sx={{
                            width: {xs: 38, md: 48},
                            height: {xs: 38, md: 48},
                          }}
                        />
                        <div className="flex flex-col">
                          <span className="font-Bai text-sm font-bold text-grey_20 md:text-lg">
                            {detail.name}
                          </span>
                          <span className="text-xs text-primary md:text-sm">
                            <StarIcon
                              fontSize="small"
                              sx={{color: "#E98100"}}
                            />
                            {detail.rating}
                          </span>
                        </div>
                      </div>
                      <span className="text-xs text-grey_30 md:text-sm">
                        {time}
                      </span>
                    </div>
                    <div className="my-4">
                      <p className="mb-1 text-grey_20">Request Message</p>
                      <p className="text-xs font-medium text-grey_30 md:text-[13px]">
                        {detail.message}
                      </p>
                    </div>
                    <p
                      className={`inline-block rounded-lg py-1.5 px-3 text-sm capitalize ${
                        status === "pending"
                          ? "bg-grey_70 text-grey_20"
                          : "bg-[#d92d2019] text-[#D92D20]"
                      }`}
                    >
                      {statusText[status]}
                    </p>
                  </div>
                  {status === "pending" && (
                    <div className="mt-6 flex justify-end">
                      <Button
                        onClick={handleWithdrawClick}
                        variant="contained"
                        sx={{
                          bgcolor: "#D92D20",
                          "&:hover": {
                            bgcolor: "#881109",
                          },
                        }}
                      >
                        Withdraw Request
                      </Button>
                    </div>
                  )}
                </div>
                <div className="mt-16">
                  <h2 className="font-Bai text-3xl font-semibold text-grey">
                    Other Service Provider Offers
                  </h2>

                  <div className="hideScroll mt-6 flex flex-col gap-y-6 gap-x-3 rounded-24 bg-grey_70 p-4 pb-10">
                    {otherOffers.map((offer, index) => (
                      <JobDetailSingleOffer
                        key={index}
                        offer={offer}
                        fullWidth={true}
                      />
                    ))}
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
        <RequestModal
          open={showRequestModal}
          close={() => setShowRequestModal(false)}
          isRejecting={isRejecting}
        />
      </section>
    </>
  );
};

export default SingleJobRequestPage;
const imgList = [
  "/images/finance.png",
  "/images/design.png",
  "/images/engineering.png",
  "/images/finance.png",
  "/images/design.png",
];
const otherOffers = [
  {
    name: "Mirian Achonwa",
    time: "1hr ago",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
    rating: "4.0",
    img: "/images/notif_img.png",
  },
  {
    name: "Mirian Achonwa",
    time: "1hr ago",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
    rating: "4.0",
    img: "/images/notif_img.png",
  },
  {
    name: "Mirian Achonwa",
    time: "1hr ago",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
    rating: "4.0",
    img: "/images/notif_img.png",
  },
  {
    name: "Mirian Achonwa",
    time: "1hr ago",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
    rating: "4.0",
    img: "/images/notif_img.png",
  },
];
