import {LoadingButton} from "@mui/lab";
import {Button} from "@mui/material";
import MyModal from "components/mui-components/Modal";
import {useGetUser} from "hooks/getUserHook";
import {useServerlogOutMutation} from "services/generalApi";
import {handleError} from "utils";

const LogOutModal = ({
  open,
  close,
  onLogout = () => {},
  btnText = "No, continue",
  desc,
}) => {
  const {user} = useGetUser();
  const isAdmin = user?.admin_permissions ? true : false;

  const [userLogout, {isLoading}] = useServerlogOutMutation();

  return (
    <MyModal openModal={open} closeModal={close} maxWidth={450}>
      <p className="mt-10 text-center font-Bai text-2xl font-medium md:mt-20 md:text-4xl">
        Are you sure you want to log out?
      </p>
      {desc && (
        <p className="mt-4 text-center font-[15px] text-grey_30">{desc}</p>
      )}
      <div className="mt-16 grid grid-cols-2 gap-x-4">
        <Button variant="contained" color="primaryInverse2" onClick={close}>
          {btnText}
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={async () => {
            try {
              await userLogout(isAdmin ? "admin" : "user").unwrap();
              onLogout();
            } catch (error) {
              console.log(error)
              handleError(error);
            }
          }}
        >
          Yes, log out
        </LoadingButton>
      </div>
    </MyModal>
  );
};

export default LogOutModal;
