import {Button} from "@mui/material";
import {useState} from "react";
import OfferCard from "../../Jobs/Widgets/OfferCard";

const RequestOffers = () => {
  const [visible, setVisible] = useState(4);

  const showMore = () => {
    setVisible(prev => prev + 3);
  };

  return (
    <div>
      <h2 className="settings-font-h2">All Offers</h2>
      <div className="mt-[20px] flex flex-col gap-4 rounded-24 bg-grey_70 p-4">
        {offerInfo.slice(0, visible).map((val, index) => (
          <OfferCard
            key={index}
            name={val.name}
            description={val.description}
            date={val.date}
            image={val.image}
            rating={val.rating}
          />
        ))}

        {visible <= offerInfo.length && (
          <div className="mt-[12px] w-fit self-center ">
            <Button
              sx={{
                borderWidth: "1px",
                padding: "20px 40px !important ",
              }}
              className=""
              onClick={showMore}
              variant="outlined"
            >
              Load More
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default RequestOffers;

const offerInfo = [
  {
    name: "Mirian Achonwa",
    rating: 4.0,
    image: "/images/notif_img.png",
    date: "1hr ago",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
  },
  {
    name: "Mike Eke",
    rating: 3.0,
    image: "/images/notif_img.png",
    date: "1hr ago",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
  },
  {
    name: "Mirian Achonwa",
    rating: 4.0,
    image: "/images/notif_img.png",
    date: "1hr ago",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
  },
  {
    name: "Mirian Achonwa",
    rating: 4.0,
    image: "/images/notif_img.png",
    date: "1hr ago",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
  },
  {
    name: "Mirian Achonwa",
    rating: 2.0,
    image: "/images/notif_img.png",
    date: "1hr ago",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
  },
  {
    name: "Mirian Achonwa",
    rating: 2.0,
    image: "/images/notif_img.png",
    date: "1hr ago",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
  },
  {
    name: "Mirian Achonwa",
    rating: 2.0,
    image: "/images/notif_img.png",
    date: "1hr ago",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
  },
];
