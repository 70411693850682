import {useMemo} from "react";
import {selectCurrentUser} from "redux/slice/AuthSlice";
import {useSelector} from "react-redux";

export const useGetUser = () => {
  const user = useSelector(selectCurrentUser);
  const isLoggedIn = user?.id ? true : false;

  return useMemo(() => ({user, isLoggedIn}), [isLoggedIn, user]);
};
