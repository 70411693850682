import React from "react";

function JobRequestsIcon(props) {
  return (
    <>
      <svg
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M9.49865 7.00224C9.67485 6.50136 10.0226 6.079 10.4804 5.80998C10.9381 5.54095 11.4763 5.4426 11.9997 5.53237C12.523 5.62213 12.9977 5.89421 13.3396 6.30041C13.6815 6.70661 13.8687 7.22072 13.8679 7.75168C13.8679 9.25056 11.6196 10 11.6196 10M11.6486 13H11.6586M11.4986 18C16.1931 18 19.9986 14.1944 19.9986 9.5C19.9986 4.80558 16.1931 1 11.4986 1C6.80423 1 2.99865 4.80558 2.99865 9.5C2.99865 10.45 3.1545 11.3636 3.44202 12.2166C3.55022 12.5376 3.60432 12.6981 3.61408 12.8214C3.62371 12.9432 3.61643 13.0286 3.5863 13.1469C3.5558 13.2668 3.48844 13.3915 3.35374 13.6408L1.71808 16.6684C1.48477 17.1002 1.36812 17.3161 1.39423 17.4828C1.41697 17.6279 1.5024 17.7557 1.62782 17.8322C1.77183 17.9201 2.01595 17.8948 2.50419 17.8444L7.62521 17.315C7.78029 17.299 7.85783 17.291 7.92851 17.2937C7.99802 17.2963 8.04709 17.3029 8.11488 17.3185C8.1838 17.3344 8.27047 17.3678 8.4438 17.4345C9.39185 17.7998 10.4219 18 11.4986 18Z"
          stroke={props.color || props.stroke || "#3B3D3B"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default JobRequestsIcon;
