import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination, Navigation} from "swiper";
import ShieldUserIcon from "components/Vectors/ShieldUserIcon";
import AccountBalWalletIcon from "components/Vectors/AccountBalWalletIcon";
import UserArrowDownIcon from "components/Vectors/UserArrowDownIcon";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import {useGetUser} from "hooks/getUserHook";
import JobCard from "pages/ServiceProviders/MarketPlace/Widgets/JobCard";
import ShoppingBagIcon from "components/Vectors/shopping-bag";
import Overview from "./Overview";

const SecondSlider = ({allJobs}) => {
  const {isLoggedIn, user} = useGetUser();
  return (
    <section className="testimonials-slider relative mb-[100px] overflow-x-clip pb-10 lg:mb-52">
      <div className="bg-[#d9d9d9] py-10 px-4 md:px-0">
        <div className="absolute -top-16 left-1/2 h-24 w-[110%] -translate-x-1/2 rounded-bl-[50%] rounded-br-[50%] bg-main-bg md:rounded-br-[100%] md:rounded-bl-[100%]"></div>
        <h3 className="mt-6 mb-10 text-center font-Bai text-3xl font-semibold text-text md:mb-12 md:text-4xl">
          See what
          <span className="text-primary"> people </span>
          are getting done
        </h3>
        {allJobs.length === 0 ? (
          <div className="mx-auto mt-10 flex min-h-[454px] w-full max-w-[494px] flex-col items-center justify-center rounded-[16px] bg-white md:min-w-[638px] md:rounded-[35px]">
            <ShoppingBagIcon width="64" height="64" />

            <p className="mt-5 text-2xl font-semibold text-black md:text-[32px]">
              No Jobs posted yet
            </p>
            <p className="text-grey20 mt-1 mb-10 max-w-[323px] text-center text-sm font-medium md:text-base">
              There are no jobs posted yet, but you can get yours done.
            </p>

            <Button
              size="large"
              sx={{
                // mt: { xs: "1.5rem", md: "2.5rem" },
                borderRadius: "20px !important",
                textTransform: "none",
              }}
              component={Link}
              to={
                isLoggedIn
                  ? user?.user_type === "Client"
                    ? "/client/post-job"
                    : "/admin/jobs"
                  : "/user/login"
              }
              variant="contained"
            >
              Post your own job
            </Button>
          </div>
        ) : (
          <>
            <div className="cursor-pointer">
              <Swiper
                spaceBetween={40}
                // slidesPerView={window.innerWidth > 768 ? 3 : "auto"}
                modules={[Navigation, Pagination, Autoplay]}
                navigation={true}
                grabCursor={true}
                className="pointer-slider"
                loop
                pagination={{
                  type: "bullets",
                  dynamicBullets: true,
                }}
                breakpoints={{
                  420: {
                    slidesPerView: 1,
                  },
                  640: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3.5,
                  },
                  1500: {
                    slidesPerView: 4.5,
                  },
                }}
              >
                {allJobs?.slice(0, 10)?.map((job, index) => (
                  <SwiperSlide key={job?.job_title + job?.id}>
                    <JobCard className="bg-white" job={job} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="flex flex-wrap items-center justify-center gap-4">
              {user?.user_type !== "Service provider" && (
                <Button
                  size="large"
                  sx={{
                    mt: {xs: "1.5rem", md: "2.5rem"},
                    borderRadius: "20px !important",
                    textTransform: "none",
                  }}
                  component={Link}
                  to={
                    isLoggedIn
                      ? user?.user_type === "Client"
                        ? "/client/post-job"
                        : "/admin/jobs"
                      : "/user/login"
                  }
                  variant="contained"
                >
                  Post your own job
                </Button>
              )}
              <Button
                size="large"
                color="btnBlack"
                component={Link}
                to={
                  user?.user_type === "Service provider"
                    ? "/service-provider/marketplace"
                    : "/jobs"
                }
                sx={{
                  mt: {xs: "1.5rem", md: "2.5rem"},
                  borderRadius: "20px !important",
                  textTransform: "none",
                }}
                variant="contained"
              >
                See all jobs
              </Button>
            </div>
          </>
        )}
      </div>

      <Overview />

      <div className="mt-10 py-10 px-4 md:px-0">
        <h2 className="mb-10 text-center font-Bai text-3xl font-semibold text-[#222222] md:text-5xl">
          Feel <span className="text-primary">safe</span> on Truelocal
        </h2>

        <div className="mx-auto grid gap-4 px-2 md:max-w-[1200px] md:grid-cols-2 md:px-[4vw] lg:grid-cols-3">
          <div className="flex flex-1 flex-col items-center justify-center gap-3 rounded-20 bg-white px-6 py-10 text-center">
            <ShieldUserIcon />
            <p className="mt-4 text-2xl font-bold text-[#222222]">
              Safe for everyone
            </p>
            <p className="font-medium text-[#6E726E] ">
              Everyone is verified by us to give you extra peace of mind
            </p>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-3 rounded-20 bg-white px-6 py-10 text-center">
            <AccountBalWalletIcon />
            <p className="mt-4 text-2xl font-bold text-[#222222]">
              Pay securely
            </p>
            <p className="font-medium text-[#6E726E] ">
              Your payment is processed by Paystack. We don’t keep your card
              details on our website
            </p>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-3 rounded-20 bg-white px-6 py-10 text-center">
            <UserArrowDownIcon />
            <p className="mt-4 text-2xl font-bold text-[#222222]">
              Hire with peace of mind
            </p>
            <p className="font-medium text-[#6E726E] ">
              When you hire someone, Truelocal holds your payment securely until
              you are satisfied with the job outcome. No more stories.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SecondSlider;
