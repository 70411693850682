const ArrowRightIcon = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 18L15 12L9 6"
        stroke={props.color ? props.color : "#C8C8C8"}
        // strokeWidth="2"
        // strokeLinecap="round"
        // strokeLinejoin="round"
      />
    </svg>
  );
};
export default ArrowRightIcon;
