import { Avatar, Chip } from "@mui/material";
import BackBtn from "components/BackBtn";
import { useState } from "react";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import StarIcon from "@mui/icons-material/Star";
import KYC from "components/KYC";
import SmallTickIcon from "components/Vectors/SmallTickIcon";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";
import Services from "./components/Services";
import {
  useActivateClientAccountMutation,
  useGetSingleServiceProviderQuery,
  useMarkAsTopVettedMutation,
  useSuspendUserMutation,
} from "services/admin/adminGeneralApi";
import { handleError } from "utils";
import { showToast } from "redux/store.hooks";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import DropDownWrapper from "components/mui-components/DropDownWrapper";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import BookmarkCheckIcon from "components/Vectors/BookmarkCheckIcon";
import Users2 from "components/Vectors/Users2";
import IconAndTitle from "./components/IconAndTitle";
import MainTokenIcon from "components/Vectors/MainTokenIcon";
import TabComponent from "./components/TabComponent";
import UserMinusIcon from "components/Vectors/UserMinusIcon";
import LoadingBtn from "components/mui-components/LoadingButton";
import { LoadingButton } from "@mui/lab";
import GiveTokenModal from "../Client/Modals/GiveTokenModal";
import SuspendAccountModal from "../Client/Modals/SuspendAccountModal";
import { BootstrapTooltip } from "components/TicketCards";

export const MarkAsTopVetted = ({ is_top_vetted, id }) => {
  const [markAsTopVetted, { isLoading }] = useMarkAsTopVettedMutation();

  const onClickHandler = async () => {
    try {
      await markAsTopVetted({
        id,
        body: { is_top_vetted: !is_top_vetted },
      }).unwrap();
      showToast(
        `Successfully ${is_top_vetted ? "Unmarked" : "Marked"} as Top Vetted`,
        "success",
      );
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <LoadingButton
      loading={isLoading}
      sx={{ px: "1rem !important" }}
      onClick={onClickHandler}
      className="text-prop flex items-center gap-[10px] py-2 text-[14px] font-normal !text-[#6E726E]"
    >
      <span>
        <BookmarkCheckIcon />
      </span>
      <span>
        {is_top_vetted ? "Unmark as top vetted" : "Mark as top vetted"}
      </span>
    </LoadingButton>
  );
};

export const SuspendUserBtn = ({ user, setopenModal }) => {
  const [suspendUser, { isLoading }] = useSuspendUserMutation();

  const handleClick = async () => {
    try {
      await suspendUser({
        id: user?.id,
        type: "unsuspend",
      }).unwrap();
      showToast("User suspension lifted");
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <>
      <LoadingButton
        loading={isLoading}
        onClick={() => {
          if (user?.status.toLowerCase() === "suspended") handleClick();
          else setopenModal(true);
        }}
        className="text-prop flex items-center gap-[10px]  px-4 py-2 text-[14px] font-normal !text-[#6E726E] "
      >
        <span>
          <UserMinusIcon />
        </span>
        <span>
          {user?.status.toLowerCase() === "suspended"
            ? "Unsuspend Account"
            : "Suspend Account"}
        </span>
      </LoadingButton>
    </>
  );
};
function EachServiceProvider() {
  const { id } = useParams();
  const [showGiveTokenModal, setShowGiveTokenModal] = useState(false);
  const [openModal, setopenModal] = useState(false);

  const { data, isLoading, error } = useGetSingleServiceProviderQuery(id);
  const [activateAccount, { isLoading: isActivating }] =
    useActivateClientAccountMutation();

  const singleProvider = data?.data;
  const registered = singleProvider?.kyc?.status === "Approved";
  const handleClickScroll = () => {
    const element = document.getElementById("token");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // console.log(singleProvider)

  return (
    <DashboardLayout
      error={error}
      isLoading={isLoading}
      title={"Service Providers"}
      type="admin"
    >
      <main className="mt-8">
        <BackBtn to="/admin/service-providers" text={"All Service Providers"} />
        <div className="rounded-2xl bg-white p-4 md:p-8">
          <div className="flex flex-col justify-between md:flex-row md:items-start">
            <div className="relative flex flex-col gap-4 md:flex-row">
              <div className="relative h-[60px] w-[60px]  md:h-[120px] md:w-[120px]">
                <Avatar
                  src={
                    singleProvider?.professional_information?.business_logo ||
                    singleProvider?.avatar
                  }
                  alt="profile"
                  sx={{
                    height: "60px",
                    width: "60px",
                    "@media (min-width: 780px)": {
                      width: "120px",
                      height: "120px",
                    },
                  }}
                />

                {singleProvider?.is_verified_account && (
                  <div className="absolute -right-2 top-0 md:right-1 md:top-1">
                    <SmallTickIcon width={24} height={24} />
                  </div>
                )}
              </div>
              <div className="mt-3">
                <div className="flex items-center gap-x-2.5">
                  <h3 className="mb-2 font-Bai text-[32px] font-semibold text-black">
                    {singleProvider?.professional_information?.business_name ||
                      singleProvider?.full_name ||
                      "-"}
                  </h3>

                  {/* <span
                    className={`rounded-lg px-3 py-1.5 text-sm ${
                      registered &&
                      singleProvider?.service_provider_type?.toLowerCase() !==
                        "unregistered service provider"
                        ? singleProvider?.is_top_vetted
                          ? "bg-[#5010b319] text-[#5110b2]"
                          : " bg-[#12b76a19] text-[#039855]"
                        : "bg-[#d92d2019] text-[#D92D20]"
                    }`}
                  >
                    {registered &&
                    singleProvider?.service_provider_type?.toLowerCase() !==
                      "unregistered service provider"
                      ? singleProvider?.is_top_vetted
                        ? "Top Vetted"
                        : "Registered"
                      : "Unregistered"}
                  </span> */}
                  {(singleProvider?.user_type?.toLowerCase() === "service provider" &&
                    singleProvider?.is_verified_account &&
                    singleProvider?.cac_certificate && singleProvider?.cac_certificate?.status === 'Approved'
                  ) && (
                      <BootstrapTooltip
                        title="This is a registered Business recognized by the Corporate Affairs Commission (CAC)"
                        placement="bottom"
                      >
                        <span className="flex items-center gap-0.5 rounded-16 border-[0.4px] border-primary bg-[#FEFAF6] px-[8px] py-[3px]">
                          <p className="font-Mulish text-[10px] font-normal text-primary">
                            A Business
                          </p>
                        </span>
                      </BootstrapTooltip>
                    )}

                  {singleProvider?.user_type?.toLowerCase() === "service provider" &&
                    singleProvider?.cac_certificate === null &&
                    (
                      <BootstrapTooltip
                        title="This user is a freelancer/Independent Professional"
                        placement="bottom"
                      >
                        <span className="flex items-center gap-0.5 rounded-16 border-[0.4px] border-primary bg-[#FEFAF6] px-[8px] py-[3px]">
                          <p className="font-Mulish text-[10px] font-normal text-primary">
                            Independent Professional
                          </p>
                        </span>
                      </BootstrapTooltip>
                    )}
                </div>
                <p className="font-Bai text-lg font-medium text-grey_20">
                  @{singleProvider?.username}
                </p>
                <div className="mt-4 flex items-center gap-3">
                  <Chip
                    sx={{ borderRadius: "8px" }}
                    color={
                      singleProvider?.status.toLowerCase() === "active"
                        ? "secondaryInverse"
                        : "greyBorder"
                    }
                    label={singleProvider?.status}
                  />
                  <div>
                    {singleProvider?.is_top_vetted && (
                      <Chip
                        sx={{ borderRadius: "8px" }}
                        color={"secondaryInverse"}
                        label={"Top Vetted"}
                      />
                    )}
                  </div>

                  <p className="text-sm">
                    <span className="text-grey_30">Date Registered:</span>{" "}
                    <span className="text-grey">
                      {moment(singleProvider?.created_at).format(
                        "Do MMMM, YYYY",
                      )}
                    </span>
                  </p>
                </div>

                <div className="mt-8 flex items-center gap-5 md:gap-[60px]">
                  <IconAndTitle
                    icon={<StarIcon color="white" />}
                    title="Rating"
                    text={singleProvider?.average_rating?.toFixed(1)}
                    bg="bg-secondary-10"
                  />
                  <IconAndTitle
                    icon={<ChatBubbleIcon color="white" />}
                    title="Reviews"
                    text={singleProvider?.ratings_received_count}
                    bg="bg-secondary-10"
                  />
                  <IconAndTitle
                    onClick={handleClickScroll}
                    icon={<MainTokenIcon color="white" />}
                    title="Token Balance"
                    text={
                      data?.data?.token_wallet
                        ? data?.data?.token_wallet?.current_balance
                        : 0
                    }
                    bg="bg-primary"
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 flex items-center gap-5">
              {!singleProvider?.email_verified_at && (
                <LoadingBtn
                  onClick={() => {
                    activateAccount(id)
                      .unwrap()
                      .then(res => {
                        showToast(res?.message, "success");
                      })
                      .catch(err => {
                        handleError(err);
                      });
                  }}
                  variant="outlined"
                  sx={{ fontSize: "16px", mt: "0px" }}
                  loading={isActivating}
                >
                  Activate Account
                </LoadingBtn>
              )}

              <DropDownWrapper
                closeOnBtnClick={true}
                contentPadding="px-0"
                className="more-actions"
                position="right"
                border="dropdown-boxShadow border border-[#E4E7EC] !rounded-16"
                action={
                  <button
                    type="button"
                    className="flex h-[52px] w-[52px] items-center
                     justify-center rounded-16 bg-[#F2F4F7]"
                  >
                    <MoreHorizOutlinedIcon color="primary" fontSize="large" />
                  </button>
                }
              >
                <div>
                  <SuspendUserBtn
                    setopenModal={setopenModal}
                    user={singleProvider}
                  />
                  <MarkAsTopVetted
                    id={singleProvider?.id}
                    is_top_vetted={singleProvider?.is_top_vetted}
                  />
                </div>
              </DropDownWrapper>
            </div>
          </div>

          <div className="mt-10 grid gap-4 rounded-20 bg-grey_80 p-4 md:grid-cols-3 md:gap-6 md:p-8">
            {[
              {
                text: "All Jobs Offers",
                value: singleProvider?.offers_count,
              },
              {
                text: "Ongoing Engagements",
                value: singleProvider?.ongoing_engagements_count,
              },
              {
                text: "Completed Engagements",
                value: singleProvider?.completed_engagements_count,
              },
            ].map(({ text, value }, index) => (
              <DashboardBox
                key={index}
                text={text}
                icon={<Users2 stroke="#E98100" />}
                iconBg="bg-[#FEF8F1]"
                value={String(value)}
                bg="bg-[#FFF]"
              />
            ))}
          </div>

          <div className="mt-8 grid grid-cols-1 gap-6 lg:grid-cols-2">
            <TabComponent
              registered={registered}
              singleProvider={singleProvider}
              setShowGiveTokenModal={setShowGiveTokenModal}
              tokens={data?.data?.token_wallet?.current_balance}
            />
            <div>
              <KYC
                data={singleProvider?.kyc}
                cac={singleProvider?.cac_certificate}
                type={singleProvider?.service_provider_type}
                isClient={false}
              />
              <Services services={singleProvider?.services} />
            </div>
          </div>
        </div>
      </main>
      <SuspendAccountModal
        user={singleProvider}
        openModal={openModal}
        setopenModal={setopenModal}
      />
      <GiveTokenModal
        user_id={id}
        open={showGiveTokenModal}
        close={() => setShowGiveTokenModal(false)}
      />
    </DashboardLayout>
  );
}

export default EachServiceProvider;
