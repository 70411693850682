import React from "react";

const NoActiveEngagement = () => {
  return (
    <svg
      width="59"
      height="50"
      viewBox="0 0 59 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.1654 3.24736C44.1166 5.21096 46.832 9.28837 46.832 14C46.832 18.7116 44.1166 22.789 40.1654 24.7526M45.4987 38.7104C49.5293 40.5342 53.1588 43.5066 56.1654 47.3333M2.83203 47.3333C8.02267 40.7269 15.0698 36.6667 22.832 36.6667C30.5942 36.6667 37.6414 40.7269 42.832 47.3333M34.832 14C34.832 20.6274 29.4594 26 22.832 26C16.2046 26 10.832 20.6274 10.832 14C10.832 7.37258 16.2046 2 22.832 2C29.4594 2 34.832 7.37258 34.832 14Z"
        stroke="#3B3D3B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoActiveEngagement;
