import {Cancel} from "@mui/icons-material";
import CancelOutlineIcon from "components/Vectors/CancelOutlineIcon";
import {useEffect, useState} from "react";
import {nanoid} from "@reduxjs/toolkit";
import {useFormContext, get} from "react-hook-form";

const TagsInput = ({name, required}) => {
  const [tags, setTags] = useState([]);
  const [input, setInput] = useState("");

  const {
    register,
    setValue,
    formState: {errors},
  } = useFormContext();

  const error = get(errors, name);

  useEffect(() => {
    register(name, {
      required: required ? "This field is required" : required,
    });
    setValue(name, tags);
  }, [name, register, required, tags, setValue]);

  const handleKeyDown = e => {
    const {key} = e;
    const trimmedInput = input.trim();

    if (key === "," && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags(prevState => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length) {
      e.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();

      setTags(tagsCopy);
      setInput(poppedTag);
    }
  };

  const onChange = e => {
    const {value} = e.target;
    setInput(value);
  };

  const handleDelete = index => {
    setTags(prevState => prevState.filter((tag, i) => i !== index));
  };

  return (
    <>
      <div className="flex h-[auto] flex-wrap items-center gap-2 rounded-[12px] border-[1px] border-[#F9FAFB] bg-[#F9FAFB] p-4 ">
        {tags.map((tag, i) => (
          <div
            key={i}
            className="flex items-center rounded-[8px] bg-[#E4E7EC] px-[12px] py-[8px]   "
          >
            <span className="text-prop text-[14px] font-medium capitalize text-grey_10 ">
              {tag}
            </span>
            <span
              className="ml-2 cursor-pointer "
              onClick={() => handleDelete(i)}
            >
              <CancelOutlineIcon color="#2C2C3A" height={20} width={20} />
            </span>
          </div>
        ))}
        <div className="w-full">
          <input
            onKeyDown={handleKeyDown}
            type="text"
            value={input}
            placeholder="Enter word"
            className="w-full bg-transparent"
            onChange={onChange}
          />
        </div>
      </div>
      {error && <div className="input-err-msg pt-[5px] ">{error.message}</div>}
    </>
  );
};
export default TagsInput;
