import LogoIcon from "components/Vectors/LogoIcon";
import { Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import SuccessfulIcon from "components/Vectors/SuccessfulIcon";

const AdminResetSuccessful = () => {
  const navigate = useNavigate();

  return (
    <div className="relative h-screen bg-[#EEEEEE] py-[10%]  md:py-[5%]">
      <div className="mb-10 flex w-full justify-center">
        <LogoIcon />
      </div>
      <div className="noScroll mx-auto flex max-h-[90vh] min-h-[380px] w-[95vw] max-w-[500px] flex-col items-center overflow-y-auto rounded-3xl bg-grey_90 p-8 shadow-sm md:p-14 ">
        <div className="flex flex-col items-center ">
          <div className="mb-12">
            <SuccessfulIcon />
          </div>

          <h3 className="mb-3 text-center font-Bai text-2xl font-semibold text-[#362E1F] md:text-4xl">
            Password Reset Successful
          </h3>

          <div className="mt-8 w-full">
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                navigate("/admin/login");
              }}
            >
              Proceed to Log in
            </Button>
          </div>
        </div>
      </div>

      <p className=" absolute bottom-[3%] left-[3%] font-Bai text-base font-medium text-grey_30">
        ©2022. Truelocal Limited
      </p>
    </div>
  );
};

export default AdminResetSuccessful;
