import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Switch,
} from "@mui/material";
import InputField from "components/forms/InputField";
import {styled} from "@mui/material/styles";
import {
  useAssignPermissionMutation,
  useDeleteAdminRoleMutation,
  useGetSingleRoleQuery,
  useRevokeRolePermissionMutation,
  useRevokeAllRolesPermissionsMutation,
  useGetAllPermissionsQuery,
} from "services/admin/adminGeneralApi";
import {useNavigate, useParams} from "react-router-dom";
import LoadingBtn from "components/mui-components/LoadingButton";
import {showToast} from "redux/store.hooks";
import {useState, useEffect} from "react";

const Role = () => {
  const [search, setSearch] = useState(""),
    [rolesList, setRolesList] = useState([]),
    [action, setAction] = useState({name: "", value: ""});

  const {id: roleId} = useParams(),
    navigate = useNavigate();

  const {data: roleInfo, isLoading: roleInfoLoading} =
      useGetSingleRoleQuery(roleId),
    [revokeAllPermissions, {isLoading: isRevokeAllLoading}] =
      useRevokeAllRolesPermissionsMutation(),
    [revokePermission, {isLoading: isRevokeLoading}] =
      useRevokeRolePermissionMutation(),
    [assignPermission, {isLoading: isAssignLoading}] =
      useAssignPermissionMutation(),
    [deleteRole, {isLoading: isDeleteLoading}] = useDeleteAdminRoleMutation(),
    {data: allPermission, isLoading: allPermissionsLoading} =
      useGetAllPermissionsQuery();

  useEffect(() => {
    setRolesList(
      search.length > 0
        ? allPermission?.data
            .filter(({name}) =>
              name.toLowerCase().includes(search.toLowerCase()),
            )
            .map(permission => ({
              label: permission.name,
              value: roleInfo?.data.permissions.some(
                ({id}) => id === permission.id,
              ),
              id: permission.id,
            }))
        : allPermission?.data.map(permission => ({
            label: permission.name,
            value: roleInfo?.data.permissions.some(
              ({id}) => id === permission.id,
            ),
            id: permission.id,
          })),
    );
  }, [allPermission, roleInfo, search]);

  const handleRoleDelete = async () => {
    const res = await deleteRole(roleId).unwrap();
    if (res.error === false) {
      showToast("Role deleted successfully");
      navigate("/admin/administrators?tab=all-roles");
    }
  };

  const handleSwitch = label => {
    const role = rolesList.find(({label: l}) => l === label);
    setRolesList(roles => [
      ...roles.map(rol => {
        if (rol.label === role.label) {
          return {label: role.label, value: !role.value, id: role.id};
        }
        return rol;
      }),
    ]);
  };

  const revoke = async label => {
    handleSwitch(label);
    setAction({name: "revoke", value: label});
  };

  const assign = async label => {
    handleSwitch(label);
    setAction({name: "assign", value: label});
  };

  const handleSwitchAll = async () => {
    const isAllChecked = rolesList.every(({value}) => Boolean(value) === true);
    setRolesList(role => [
      ...role.map(rol => ({
        label: rol.label,
        value: isAllChecked ? false : true,
        id: rol.id,
      })),
    ]);
    isAllChecked
      ? setAction({name: "revokeAll", value: ""})
      : setAction({name: "assignAll", value: ""});
  };

  const permissionOpt = {
    revoke: async label => {
      const res = await revokePermission({
        role_id: roleId,
        permission_name: label,
      }).unwrap();
      if (res.error === false) {
        showToast(`Permission ${label} revoked successfully`, "success");
      }
    },
    assign: async label => {
      const res = await assignPermission({
        role_id: roleId,
        permissions: [label],
      }).unwrap();
      if (res.error === false) {
        showToast(`Permission ${label} assigned successfully`, "success");
      }
    },
    revokeAll: async () => {
      const res = await revokeAllPermissions(roleId).unwrap();
      if (res.error === false) {
        showToast("All permissions revoked successfully", "success");
      }
    },
    assignAll: async () => {
      const res = await assignPermission({
        role_id: roleInfo?.data.id,
        permissions: [rolesList?.map(({label}) => label)],
      });
      if (res.data.error === false) {
        showToast("All permissions assigned successfully", "success");
      }
    },
  };

  const handleUpdatePermission = () => {
    permissionOpt[action.name](action.value);
  };

  const IOSSwitch = styled(props => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({theme}) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#E98100",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="font-Bai text-2xl font-semibold text-black md:text-3xl">
          {roleInfoLoading ? (
            <Skeleton variant="rounded" width={300} height={35} />
          ) : (
            roleInfo?.data.name
          )}
        </h1>
        {roleInfo?.data.name === "Super Admin" ? null : (
          <div className="flex items-center gap-x-4">
            <LoadingBtn
              disabled={!action.name}
              onClick={handleUpdatePermission}
              loading={isRevokeAllLoading || isAssignLoading || isRevokeLoading}
            >
              Update
            </LoadingBtn>
            <LoadingBtn
              color="error"
              onClick={handleRoleDelete}
              loading={isDeleteLoading}
            >
              Delete
            </LoadingBtn>
          </div>
        )}
      </div>
      <div className="mt-7 grid gap-7 md:grid-cols-5">
        <div className="rounded-24 bg-grey_80 px-3 py-3 md:col-span-3 md:px-8 md:py-6">
          <div className="flex items-center justify-between">
            <h2 className="font-Bai text-xl font-semibold text-black md:text-2xl">
              Permissions
            </h2>
            <FormControlLabel
              disabled={
                roleInfo?.data.name === "Super Admin" ||
                isRevokeAllLoading ||
                isAssignLoading
              }
              control={
                <Checkbox
                  checked={
                    roleInfo?.data.name === "Super Admin"
                      ? true
                      : rolesList?.length > 0
                      ? rolesList.every(({value}) => value === true)
                      : false
                  }
                  onChange={handleSwitchAll}
                />
              }
              label={`${
                roleInfo?.data.name === "Super Admin"
                  ? "Revoke"
                  : rolesList?.every(role => role.value)
                  ? "Revoke"
                  : "Allow"
              } all permission`}
              labelPlacement="start"
              sx={{
                "& .MuiSvgIcon-root": {
                  width: {xs: "24px", md: "28px"},
                  height: {xs: "24px", md: "28px"},
                },
                "& .MuiFormControlLabel-label": {
                  fontSize: {xs: "14px !important", md: "16px !important"},
                  textTransform: "none",
                },
              }}
            />
          </div>
          <InputField
            placeholder="FEATURES"
            bg="#FFF"
            value={search}
            onChange={({target}) => setSearch(target.value)}
          />
          <FormGroup
            sx={{
              mt: "30px",
              display: "flex",
              flexDirection: "column",
              rowGap: "25px",
            }}
          >
            {allPermissionsLoading
              ? [1, 2, 3, 4, 5].map(i => (
                  <Skeleton key={i} variant="rounded" width={500} height={35} />
                ))
              : rolesList?.map(({label, value, id}) => (
                  <FormControlLabel
                    key={id}
                    control={
                      <IOSSwitch
                        sx={{m: 1}}
                        checked={
                          roleInfo?.data.name === "Super Admin" ? true : value
                        }
                        disabled={roleInfo?.data.name === "Super Admin"}
                        onChange={() => (value ? revoke(label) : assign(label))}
                      />
                    }
                    label={label}
                    labelPlacement="start"
                    sx={{
                      "& .MuiFormControlLabel-label": {mr: "auto"},
                    }}
                  />
                ))}
          </FormGroup>
        </div>
        <div className="rounded-24 bg-grey_80 px-8 py-6 md:col-span-2">
          <h2 className="font-Bai text-2xl font-semibold text-black">
            Description
          </h2>
          <div className="mt-7 font-medium text-grey_20">
            {roleInfoLoading ? (
              <div className="flex flex-col gap-y-4">
                <Skeleton variant="rounded" width={100} height={35} />
                <Skeleton variant="rounded" width={200} height={20} />
                <Skeleton variant="rounded" width={200} height={20} />
              </div>
            ) : (
              roleInfo?.data.description
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Role;
