import {PAYSTACK_BASE_CONFIG} from "utils/constants";
import {
  useCompletePaymentMutation,
  useInitiatePaymentMutation,
} from "services/user/userRegApi";
import {usePaystackPayment} from "react-paystack";
import {showToast} from "redux/store.hooks";
import {useEffect, useState} from "react";
import {handleError} from "utils";
import {CircularProgress} from "@mui/material";
import {LoadingButton} from "@mui/lab";

const usePaystackPaymentHook = ({onPaymentDone, id, module = "Engagement"}) => {
  // this state is true anytime paystack modal pops up (for custom loader)
  const [showInitPaystack, setshowInitPaystack] = useState(false);

  // set paystack config
  const [paystackConfig, setpaystackConfig] = useState({
    ...PAYSTACK_BASE_CONFIG,
  });

  const initializePayment = usePaystackPayment({
    ...paystackConfig,
  });

  //   initialise payment
  const [initPayment, {isLoading: isInitLoading}] =
    useInitiatePaymentMutation();
  const initPaymentHandler = async () => {
    try {
      let res = await initPayment({
        module,
        module_id: id,
      }).unwrap();
      const {user, amount, reference} = res?.data;
      setpaystackConfig(prev => ({
        ...prev,
        amount,
        reference,
        user,
        email: user?.email,
      }));
    } catch (error) {
      handleError(error);
    }
  };

  const [completePayment, {isLoading: isCompleteLoading}] =
    useCompletePaymentMutation();

  // call paystack hook after initializing payment api
  useEffect(() => {
    const completePaymentHandler = async reference => {
      try {
        await completePayment({reference}).unwrap();
        showToast("Payment successful");
        if (typeof onPaymentDone === "function") {
          onPaymentDone();
        }
        setshowInitPaystack(false);
      } catch (error) {
        handleError(error);
      }
    };

    // on paystack payment success
    const onSuccess = reference => {
      completePaymentHandler(reference?.reference);
      setshowInitPaystack(false);
      setpaystackConfig({...PAYSTACK_BASE_CONFIG});
    };

    // on paystack portal close
    const onClose = () => {
      setpaystackConfig({...PAYSTACK_BASE_CONFIG});
      setshowInitPaystack(false);
      showToast("Payment portal closed", "error");
    };

    if (paystackConfig?.email) {
      setshowInitPaystack(true);
      initializePayment(onSuccess, onClose);
    }
  }, [
    completePayment,
    initializePayment,
    onPaymentDone,
    paystackConfig,
    setpaystackConfig,
  ]);

  const isLoading = isInitLoading || isCompleteLoading;

  return {isLoading, initPaymentHandler, showInitPaystack};
};

// custom  payment
export function PaymentBtn({
  onPaymentDone,
  module,
  id,
  btnText = "Proceed to payment",
  ...props
}) {
  const {isLoading, showInitPaystack, initPaymentHandler} =
    usePaystackPaymentHook({
      id,
      onPaymentDone,
      module,
    });

  return (
    <>
      {showInitPaystack && (
        <div className="fixed inset-0 z-[100000] flex items-center justify-center bg-[#000000fa]">
          <CircularProgress color="white" />
        </div>
      )}
      <LoadingButton
        loading={isLoading}
        onClick={initPaymentHandler}
        variant="contained"
        {...props}
      >
        {btnText}
      </LoadingButton>
    </>
  );
}

export default usePaystackPaymentHook;
