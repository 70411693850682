import {IconButton} from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import {useNavigate} from "react-router";
import moment from "moment";
import {useGetAllRolesQuery} from "services/admin/adminGeneralApi";

const RolesTab = () => {
  const navigate = useNavigate();
  const {data: allRoles, isLoading, error, isError} = useGetAllRolesQuery();

  const rolesList = allRoles?.data?.map(
    ({name, updated_at, number_of_users, id, status}) => ({
      name: name,
      number: number_of_users,
      updated: moment(updated_at).format("DD/MM/YYYY"),
      status: (
        <span
          className={`rounded-lg px-3 py-1.5 ${
            status === "Active"
              ? "bg-[#5010b319] text-[#5010B3]"
              : "bg-grey_70 text-[#3B3D3B]"
          }`}
        >
          {status}
        </span>
      ),
      id,
      action: (
        <IconButton onClick={() => navigate(String(id) + "?state=role")}>
          <ArrowCircleRightOutlinedIcon
            sx={{
              color: "#E98100",
            }}
          />
        </IconButton>
      ),
    }),
  );

  return (
    <MyDataTable
      options={{
        viewColumns: false,
        filter: false,
      }}
      headCells={headCells}
      data={rolesList}
      scrollX={false}
      isError={isError}
      error={error}
      isLoading={isLoading}
      emptyData={{
        message: "No role Created",
        icon: ShieldOutlinedIcon,
        iconColor: "#3B3D3B",
      }}
    />
  );
};
export default RolesTab;
const headCells = [
  {
    name: "name",
    label: "Role name",
  },
  {
    name: "number",
    label: "Number of admins",
  },
  {
    name: "updated",
    label: "Last updated",
  },
  {
    name: "status",
    label: "Status",
  },
  {
    name: "action",
    label: "",
  },
];
