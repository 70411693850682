import {Avatar, Chip} from "@mui/material";
import {Link} from "react-router-dom";

function ServiceCard({data}) {
  const expertiseArray = data.expertises?.map(expertise => expertise.name);

  return (
    <Link
      key={data.id}
      className="rounded-20 border border-grey_60 p-4"
      to={`/services/${data.id}`}
      state={{
        category: data.name,
        cover_photo: data.cover_photo,
        expertises: expertiseArray,
      }}
    >
      <div className="5 flex gap-2">
        <Avatar
          src={data?.mobile_app_icon || data?.cover_photo}
          alt={data.name}
          sx={{height: "80px", width: "80px", borderRadius: "12px"}}
        />

        <div>
          <p className="font-Bai text-2xl font-semibold text-grey_20">
            {data.name || "N/A"}
          </p>
          <p className="mt-2 text-sm font-medium text-grey_20">
            {data.expertises_count} Expertise
          </p>
        </div>
      </div>

      {expertiseArray.length > 0 ? (
        <div className="mt-4 flex flex-wrap gap-2">
          {expertiseArray
            .slice(0, 5)
            .concat(`+ ${expertiseArray.length - 5}`)
            .map(expertise => (
              <Chip key={expertise} label={expertise} />
            ))}
        </div>
      ) : (
        <p className="mt-6 text-center text-lg font-bold">No expertise yet</p>
      )}
    </Link>
  );
}

export default ServiceCard;
