const ClockIcon = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1.75C5.16797 1.75 1.25 5.66797 1.25 10.5C1.25 15.332 5.16797 19.25 10 19.25C14.832 19.25 18.75 15.332 18.75 10.5C18.75 5.66797 14.832 1.75 10 1.75ZM10 17.7656C5.98828 17.7656 2.73438 14.5117 2.73438 10.5C2.73438 6.48828 5.98828 3.23438 10 3.23438C14.0117 3.23438 17.2656 6.48828 17.2656 10.5C17.2656 14.5117 14.0117 17.7656 10 17.7656Z"
        fill={props.color || "black"}
      />
      <path
        d="M13.4121 12.9727L10.627 10.959V6.125C10.627 6.03906 10.5566 5.96875 10.4707 5.96875H9.53125C9.44531 5.96875 9.375 6.03906 9.375 6.125V11.5039C9.375 11.5547 9.39844 11.6016 9.43945 11.6309L12.6699 13.9863C12.7402 14.0371 12.8379 14.0215 12.8887 13.9531L13.4473 13.1914C13.498 13.1191 13.4824 13.0215 13.4121 12.9727Z"
        fill={props.color || "black"}
      />
    </svg>
  );
};
export default ClockIcon;
