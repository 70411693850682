import {Link, useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {CircularProgress} from "@mui/material";
import {ReactComponent as Logo} from "assets/images/logo.svg";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import ArrowRightIcon from "components/Vectors/ArrowRightIcon";
import {
  useUserLoginMutation,
  // useUserResendOtpMutation,
} from "services/auth.api";
import {
  setUserDetails,
  // setUserEmail,
  // setUserPhoneNumber,
  setUserToken,
} from "redux/slice/AuthSlice";
import {handleError} from "utils";
import {LoadingButton} from "@mui/lab";
import GoogleBtn from "./Widgets/GoogleBtn";
import {showToast} from "redux/store.hooks";

const UserLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, {isLoading}] = useUserLoginMutation();
  // const [resendOtpCode] = useUserResendOtpMutation();
  const methods = useForm({
    mode: "onSubmit",
  });

  const onSubmit = async data => {
    try {
      let res = await login(data).unwrap();
      showToast(res?.message);
      dispatch(setUserToken(res?.data?.access_token));
      dispatch(setUserDetails(res?.data));
      navigate("/client");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="h-screen bg-white md:bg-[#f5f5f5]">
      <nav
        className={`z-[100] flex items-center justify-between px-4 py-6 lg:px-8`}
      >
        <Link to="/">
          <Logo />
        </Link>
      </nav>
      <section className="relative h-full w-full overflow-y-auto md:grid md:h-full md:grid-flow-row md:grid-cols-2">
        <div className="mt-0 w-full max-w-[650px] p-4 md:mt-5 lg:pl-[150px]">
          <h2 className="mb-[20px] font-Bai text-4xl font-medium text-black_text">
            Welcome back!
          </h2>

          {/* form  */}
          <FormProvider {...methods}>
            <form
              className="flex flex-col gap-[20px]"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <ValidatedInput2
                label="Email"
                placeholder="email@example.com"
                type="email"
                name="email"
                rules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                }}
              />
              <ValidatedInput2
                label="Password"
                placeholder="Enter Your Password"
                type="password"
                name="password"
                showForgetPwd={true}
              />

              <LoadingButton
                loadingIndicator={
                  <CircularProgress
                    sx={{
                      color: "#fff",
                    }}
                    size="1.2rem"
                  />
                }
                type="submit"
                loading={isLoading}
                fullWidth
                variant="contained"
                color="primary"
              >
                Login
              </LoadingButton>
            </form>
          </FormProvider>

          {/* other content  */}
          <div className="mt-4 flex flex-col items-center ">
            <span className="text-prop font-Bai text-base font-medium text-black_text2 ">
              or continue with
            </span>
            <div className="mt-2 flex w-full items-center justify-between gap-4 py-4 md:mt-4 md:py-0 ">
              <GoogleBtn />
              {/* <FacebookBtn /> */}

              {/* <SocialIconCard
                socialIcon={FacebookIcon}
                text="Facebook"
                arrowIcon={RightIcon}
              /> */}
            </div>
            <p className="text-prop mt-4 font-Mulish text-[14px] font-medium text-grey_30">
              By proceeding, I agree to Truelocal’s
              <Link
                to="/terms-of-service"
                className="ml-1.5 text-primary underline"
              >
                Terms of service,
              </Link>
              <Link
                to="/community-guideline"
                className="ml-1.5 text-primary underline"
              >
                Community Guidelines,
              </Link>
              <Link
                to="/privacy-policy"
                className="ml-1.5 text-primary underline"
              >
                & Privacy Policy.
              </Link>
            </p>
            <p className="text-prop mt-[2rem] flex w-full self-start font-Mulish text-base font-medium text-grey_30 ">
              Don't have an account?&nbsp;{" "}
              <Link
                to="/user/create-account"
                className="flex items-center gap-[0] font-Mulish text-primary"
              >
                Sign up
                <ArrowRightIcon color="#E98100" width={16} height={16} />
              </Link>
            </p>

            <p className=" text-prop self-start pt-[3rem] text-base text-grey_30 md:ml-[-3rem] ">
              {" "}
              &copy; {new Date().getFullYear()} All rights reserved, Truelocal
            </p>
          </div>
        </div>
        <div className="relative hidden md:block">
          <img
            src="/images/loginBg.png"
            alt="user"
            className="absolute bottom-0 right-0 h-[100%]"
          />
        </div>
      </section>
    </div>
    // <LandingDashboardLayout showFooter={false}>
    // </LandingDashboardLayout>
  );
};
export default UserLogin;
