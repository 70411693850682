import EmailLayout from "./widgets/EmailLayout";
import EmailTitle from "./widgets/EmailTitle";
import {Button} from "@mui/material";
import {toCurrency} from "utils";
import moment from "moment";
import Calendar2Icon from "components/Vectors/Calendar2Icon";

const OfferAccepted = () => {
  const engagement = {
    start_date: "2023-07-15",
    end_date: "2023-07-25",
    amount: 50000,
    category: {
      name: "Repairs & Maintenance",
      image_url: "/images/maintenance.png",
      description: "I will repair everything that is faulty in your house",
    },
  };
  const handleViewOffer = () => {
    console.log("clicked");
  };
  return (
    <EmailLayout>
      <EmailTitle
        title="Congratulations, your offer was accepted"
        hasDecoration
      />
      <div className="mx-auto mt-12 w-10/12">
        <div className="flex justify-between">
          <div className="flex gap-x-3">
            <img
              src={engagement?.category?.image_url || "/images/broken-img.png"}
              alt={engagement?.category?.name}
              className="h-16 w-16 rounded-xl object-cover"
            />
            <div>
              <p className="text-xs text-primary">
                {engagement?.category?.name}
              </p>
              <p className="max-w-[260px] font-Bai font-semibold text-ltext">
                {engagement?.category?.description}
              </p>
            </div>
          </div>
          <span className="font-Bai text-xl font-bold text-grey_20">
            {toCurrency(engagement?.amount)}
          </span>
        </div>
        <p className="mt-4 flex w-full items-center gap-1 rounded-lg bg-grey_80 p-4 text-xs text-grey_30">
          <Calendar2Icon color="#000" className="h-5 w-4" />{" "}
          {moment(engagement?.start_date).format("DD MMM YYYY")}{" "}
          <span className="mx-1 inline-block text-black"> to </span>{" "}
          {moment(engagement?.end_date).format("DD MMM YYYY")}
        </p>
      </div>

      <div className="mx-auto mb-16 mt-10 w-11/12">
        <p className="font-Bai font-bold text-grey_20">Acceptance Message</p>
        <p className="mt-1 text-grey_30">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
          porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis
          imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.
        </p>
      </div>
      <Button
        onClick={handleViewOffer}
        variant="contained"
        sx={{
          display: "block",
          width: "218px",
          height: "60px",
          mb: 8,
          mx: "auto",
        }}
      >
        View Engagement
      </Button>
    </EmailLayout>
  );
};

export default OfferAccepted;
