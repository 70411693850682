import {useState} from "react";
import {Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import Overviews from "../Tabs/Overviews";
import Subscription from "./Subscription";
import {CustomTabStyle3} from "App";
import ProfessionalTab from "../Tabs/ProfessionalTab";

const TabComponent = ({
  registered,
  singleProvider,
  setShowGiveTokenModal,
  tokens,
}) => {
  const [activeTab, setactiveTab] = useState("basic");
  const navigate = useNavigate();

  return (
    <div>
      <TabContext value={activeTab}>
        <div className="rounded-24 bg-grey_80 p-4 md:p-8">
          <TabList
            variant="scrollable"
            value={activeTab}
            className="mb-6 rounded-[16px] border border-grey_60 bg-grey_80 p-2"
            onChange={(_, newValue) => {
              navigate(`?tab=${newValue}`);
              setactiveTab(newValue);
            }}
            sx={{
              fontWeight: 600,
              ...CustomTabStyle3,
              textTransform: "unset",
            }}
            aria-label="basic tabs example"
          >
            <Tab label="Basic" value="basic" />
            <Tab label="Professional" value="professional" />
          </TabList>
          <TabPanel value="basic">
            <Overviews data={singleProvider} />
          </TabPanel>

          <TabPanel value="professional">
            <ProfessionalTab data={singleProvider} />
          </TabPanel>
        </div>
        <TabPanel value="basic">
          <Subscription
            registered={registered}
            onBtnClick={() => setShowGiveTokenModal(true)}
            tokens={tokens}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default TabComponent;
