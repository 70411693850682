import React, {createElement} from "react";
import {useGetTransactionStatsQuery} from "services/admin/adminGeneralApi";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";
import UserIcon03 from "components/Vectors/UserIcon03";
import UserCircleIcon from "components/Vectors/user-circle";
import UserIcon from "components/Vectors/user";
import MoneyAddIcon from "components/Vectors/MoneyAddIcon";
import {toCurrency} from "utils";

export const TransactionsCardData = [
  {
    icon: UserIcon03,
    text: "Available Balance",
    key: "available_balance",
    isMoney: true,
  },
  {
    icon: UserCircleIcon,
    text: "Total Payouts",
    key: "total_completed_payouts_count",
    extraKey: "total_pending_payouts_count",
    isMoney: false,
  },
  {
    icon: UserIcon,
    text: "Truelocal Commission (Professional)",
    key: "all_time_commission_on_sp_engagements",
    isMoney: true,
  },
  {
    icon: MoneyAddIcon,
    value: "₦200,000",
    text: "Successful Transactions",
    key: "successfull_transactions",
    isMoney: true,
  },
  {
    icon: MoneyAddIcon,
    value: "₦200,000",
    text: "Escrow transactions",
    key: "current_escrow_on_ongoing_engagements",
    isMoney: true,
  },
  {
    icon: MoneyAddIcon,
    text: "Total Refund",
    key: "all_time_refunds",
    isMoney: true,
  },
  // {
  //   icon: MoneyAddIcon,
  //   value: "₦200,000",
  //   text: "Failed Transaction",
  //   key: "",
  //   isMoney: true,
  // },
  // {
  //   icon: MoneyAddIcon,
  //   text: `VAT`,
  //   key: "all_time_vats",
  //   isMoney: true,
  // },
];
function TransactionsTab() {
  const {data, isLoading, isError} = useGetTransactionStatsQuery();
  const stats = data?.data;

  return (
    <div>
      <h3 className="mb-5 font-Bai text-2xl font-medium text-black">
        Overview
      </h3>

      <div className="mb-8 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {TransactionsCardData.map((item, i) => {
          const {icon, key, text} = item;
          return (
            <DashboardBox
              key={i}
              isError={isError}
              isLoading={isLoading}
              icon={createElement(icon, {color: "#e98100", stroke: "#e98100"})}
              text={text}
              value={
                stats
                  ? item?.isMoney
                    ? toCurrency(stats[key] + (stats[item?.extraKey] || 0) || 0)
                    : `${stats[key] + (stats[item?.extraKey] || 0) || 0}`
                  : "N/A"
              }
            />
          );
        })}
      </div>
    </div>
  );
}

export default TransactionsTab;
