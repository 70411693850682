import { ReactComponent as Play } from "assets/images/play.svg";
import { useState } from "react";
import { ReactComponent as Arrow } from "assets/images/arrow.svg";
import ModalVideo from "react-modal-video";
import { useGetUser } from "hooks/getUserHook";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// import FlipText from "components/Fliptext";

const Header = ({ banners }) => {
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const { isLoggedIn, user } = useGetUser();
  const navigate = useNavigate();

  const [inputVal, setinputVal] = useState("");

  const images =
    banners
      ? banners?.map(el => el?.banner)
      : ["plumber", "photographer", "engineer"].map(
        el => `/images/landingpage/${el}.png`,
      );

  const flip_texts =
    banners
      ? banners?.map(el => el?.title)
      : ["Plumbing", "Photography", "Engineer"];



  return (
    <div className="relative mx-auto flex w-full max-w-[1800px] md:mt-[9.95rem]">
      <div className="w-full">
        <div className="relative z-[5] -mt-6 grid w-full grid-cols-1 items-center justify-between gap-6 md:-mt-[7rem] lg:grid-cols-2 lg:pl-[4vw]">
          <header className="mx-auto w-full pl-4  pr-2 text-center lg:pr-10 lg:text-left">
            <div className="mx-auto font-Bai text-3xl font-semibold text-text md:max-w-[511px] lg:mx-0 lg:text-5xl lg:leading-[59px] xl:max-w-[561px]">
              <span className="mr-[0.6rem] text-4xl lg:text-5xl">
                Get your {""}
                <span className="inline-block translate-y-1 text-left md:translate-x-3 lg:translate-y-5 xl:translate-y-5">
                  <Swiper
                    direction="vertical"
                    loop
                    spaceBetween={15}
                    modules={[Autoplay]}
                    navigation={false}
                    className="inline-block max-h-[34px] md:max-h-[38px] lg:max-h-[60px]"
                    allowTouchMove={false}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                  >
                    {flip_texts?.map(el => (
                      <SwiperSlide key={el}>
                        <span className={'text-3xl font-semibold text-primary lg:text-left lg:text-5xl'}>{el}</span>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </span>
                <br />
                tasks done on agreed terms.
              </span>
            </div>
            <p className="font-poppins mx-auto my-5 max-w-[511px] text-base text-text lg:mx-0 lg:text-lg">
              Need help with anything? Post it on Truelocal, there is always someone available to help
            </p>

            <label
              htmlFor="service"
              className="mx-auto flex w-full max-w-[681px] items-center justify-between rounded-16 bg-white p-2 shadow-input md:rounded-25 lg:mx-0 lg:-mr-32 lg:pl-4"
            >
              <div className="flex w-full max-w-[80%] items-center justify-between gap-2 lg:justify-start lg:gap-6">
                <input
                  id="service"
                  type="text"
                  value={inputVal}
                  onChange={e => setinputVal(e.target.value)}
                  placeholder="Eg: I need a designer for my project"
                  className="font-poppins w-full py-1 text-base font-medium placeholder:text-xs placeholder:text-gray-300 focus:outline-none md:placeholder:min-w-fit md:placeholder:text-base lg:py-3 lg:pr-9 lg:text-base"
                />
              </div>
              <button
                type="button"
                className="font-poppins min-w-max rounded-[12px] bg-primary px-3 py-2 text-xs font-medium text-white transition-all hover:bg-[#aa5e01] md:rounded-20 lg:min-w-fit lg:px-8 lg:py-4 lg:text-base"
                onClick={() => {
                  isLoggedIn
                    ? user?.user_type === "Client"
                      ? navigate(`/client/post-job?q=${inputVal}`)
                      : user?.user_type === "Service provider"
                        ? navigate(`/service-provider/marketplace?q=${inputVal}`)
                        : navigate(`/admin/jobs?q=${inputVal}`)
                    : navigate(`/user/login`);
                }}
              >
                {isLoggedIn
                  ? user?.user_type === "Service provider"
                    ? "Search For Jobs"
                    : "Post Your Job"
                  : "Post Your Job"}
              </button>
            </label>

            <div className="relative">
              <Arrow className="absolute top-[5px] left-[150px] hidden h-[160px] w-28 lg:block lg:w-auto" />
              <button
                onClick={() => setOpenVideoModal(prev => !prev)}
                type="button"
                className="my-6 flex items-center gap-x-2 text-base font-bold text-ltext lg:mt-10 lg:ml-4 lg:pb-0"
              >
                <Play />
                <span>See how it works</span>
              </button>
            </div>
          </header>
          <div className="header-slider px-4 md:mr-[53px] md:px-6 lg:px-0">
            {/* {images?.length === 0 ? (
              <img
                src="/images/landingpage/plumber-2.png"
                alt=""
                className="!rounded-br-[56px]"
              />
            ) : ( */}
            <div className="max-h[400px] xl:max-h[530px] aspect-square w-full cursor-pointer overflow-hidden rounded-[28px] md:rounded-[0] lg:rounded-br-[56px] lg:!rounded-tr-[0px]">
              {/* <ImageSlider images={images} /> */}
              <Swiper
                spaceBetween={20}
                slidesPerView={1}
                modules={[Autoplay]}
                navigation={false}
                className="pointer-slider h-full"
                loop
                allowTouchMove={false}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                centeredSlides={true}
                centeredSlidesBounds={true}
              // initialSlide={1}
              >
                {images?.map(el => {
                  return (
                    <SwiperSlide key={el}>
                      <img
                        src={el}
                        alt="banner"
                        className="h-full w-full rounded-[28px]  object-cover md:rounded-[0] lg:rounded-br-[56px]"
                      />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
            {/* )} */}
          </div>
        </div>

        <div className="px-4 md:px-6 relative mt-20 flex flex-col mb-10 lg:mb-0 lg:items-center lg:justify-center font-Inter lg:flex-row">
          <span className="mb-2 text-base font-medium text-primary lg:mb-0 lg:text-lg">
            3 Easy Steps
          </span>
          <ol className="flex flex-col itemscenter gap-y-6 text-sm text-text lg:ml-16 lg:flex-row lg:gap-x-14 lg:text-base">
            {[
              "Describe what you need done",
              "Set your budget",
              "Receive quotes & pick the best provider",
            ].map((item, index) => (
              <li key={index}>
                <span className="mr-3 inline-flex h-8 w-8 items-center justify-center rounded-full bg-primary font-Bai text-white lg:h-12 lg:w-12">
                  {index + 1}
                </span>
                {item}
              </li>
            ))}
          </ol>
        </div>
        <ModalVideo
          channel="youtube"
          youtube={{ autoplay: 1, mute: 1 }}
          isOpen={openVideoModal}
          videoId="_Z3QKkl1WyM"
          onClose={() => setOpenVideoModal(false)}
        />
      </div>
    </div>
  );
};

export default Header;
