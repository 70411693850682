import { Avatar } from "@mui/material";
import { Chip } from "@mui/material";
import UserAvatar from "./UserAvatar";
import NewDefaultAvatar from "components/Vectors/NewDefaultAvatar";

function UserDisplay({
  name,
  img,
  email,
  pad,
  chip,
  imgRounded = true,
  truncate = false,
  max_width = "150px",
  size = "medium",
  textWhite = false,
  badge = false,
  fontSize,
  emailTextColor,
  isOnline,
  ...props
}) {
  return (
    <div
      onClick={props.handleClick}
      className={`flex ${props.reverse ? "flex-row-reverse" : ""
        } ${props.handleClick ? "cursor-pointer" : ""} items-center gap-x-3 ${pad ? "p-3" : ""} ${props.bg || ""} ${props.className || ""
        }`}
    >
      {badge ? (
        <UserAvatar
          size={
            size === "medium"
              ? 40
              : size === "small"
                ? 30
                : size === "large"
                  ? 64
                  : 50
          }
          src={img}
          alt={name}
          showBadge={badge}
        />
      ) : (
        <div className={isOnline ? "relative" : "static"}>
          <Avatar
            src={img}
            sx={{
              borderRadius: imgRounded ? "50%" : "4px",
              width:
                size === "medium"
                  ? 40
                  : size === "small"
                    ? 30
                    : size === "large"
                      ? 64
                      : 50,
              height:
                size === "medium"
                  ? 40
                  : size === "small"
                    ? 30
                    : size === "large"
                      ? 64
                      : 50,
            }}
          >
            <NewDefaultAvatar />
          </Avatar>
          {isOnline && (
            <span className="absolute -bottom-0 right-0 h-3 w-3 rounded-full border-2 border-grey_80 bg-green-600"></span>
          )}
        </div>
      )}
      <div>
        <p
          style={{ maxWidth: max_width }}
          className={`${truncate && `truncate`} ${size === "small"
            ? "text-sm"
            : size === "large"
              ? "text-2xl font-semibold"
              : "text-base"
            } ${textWhite ? "text-white" : "text-grey_20"} ${fontSize ? fontSize : "font-medium"
            } ${props.clickable && "cursor-pointer hover:text-primary"
            } font-Bai capitalize`}
        >
          {name}
        </p>
        {email && (
          <p
            className={`w-fit ${truncate ? "truncate" : ""} ${size === "medium"
              ? "text-sm"
              : size === "small"
                ? "text-xs"
                : "text-base"
              } mt-0 ${textWhite
                ? "text-white"
                : emailTextColor
                  ? emailTextColor
                  : "text-[#aaaaaa]"
              } ${props.highlight && "mt-1 rounded-[6px] bg-grey_80 p-2"} ${props.reverse && 'ml-auto'} `}
          >
            {email} &nbsp;{" "}
            {chip && (
              <Chip
                color={props.chipColor}
                sx={{ width: "fit-content" }}
                label={props.chipLabel}
              />
            )}
          </p>
        )}
      </div>
    </div>
  );
}

export default UserDisplay;
