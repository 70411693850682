import {DatePicker} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";
import {Controller, get, useFormContext} from "react-hook-form";
import CalendarStrokeIcon2 from "components/Vectors/CalendarStrokeIcon2";

const FormDatePicker = ({
  maxDate,
  minDate,
  name,
  placeholder,
  bordered = true,
  required = true,
  ...props
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const error = get(errors, name);

  return (
    <div>
      <div className={bordered ? " " : "no-border"}>
        <Controller
          name={name}
          control={control}
          rules={{
            required: required ? "This field is required" : "",
            validate: value =>
              value !== undefined || "Please enter date of birth",
          }}
          render={({field: {onChange, value = null}}) => (
            <DatePicker
              maxDate={maxDate}
              minDate={minDate}
              inputFormat="dd/MM/yyyy"
              value={value}
              keyboard
              disableOpenPicker={false}
              components={{
                OpenPickerIcon: CalendarStrokeIcon2,
              }}
              onChange={onChange}
              {...props}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{
                    background: "#F9FAFB",
                    borderRadius: "16px",
                    borderWidth: "1px !important",
                    width: "100%",
                  }}
                  placeholder={placeholder}
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    placeholder: placeholder,
                  }}
                />
              )}
            />
          )}
        />
      </div>
      {error && (
        <div className="input-err-msg pt-[-10rem] ">{error.message || ""}</div>
      )}
    </div>
  );
};
export default FormDatePicker;
