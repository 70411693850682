import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {useNavigate} from "react-router-dom";
import {Avatar} from "@mui/material";
import {ReactComponent as ChatIcon} from "assets/icons/chat.svg";
import {BootstrapTooltip} from "components/TicketCards";

function JobRequestCard({request, isNavigate = true}) {
  const navigate = useNavigate();
  const {
    id = "1",
    imgUrl = "/images/jobRequestImage.png",
    requestCategory = "Repairs & Maintenance",
    requestDetails = "Fix my faucet and mixers",
    amount = "₦50,000",
    location = "Physical - Ketu, Lagos",
    dateTime = "Sun, 20th Feb 2022 - Anytime",
  } = request;

  return (
    <div
      className={`relative p-2 ${
        isNavigate && "cursor-pointer"
      } flex flex-col gap-2 rounded-24 bg-grey_70`}
      onClick={() => {
        if (isNavigate) navigate(id);
      }}
    >
      <div className="relative">
        <img src={imgUrl} alt="request_image" className="rounded-0" />
        {id % 2 === 0 && (
          <BootstrapTooltip title="New chat" placement="top">
            <Avatar
              sx={{width: 34, height: 34}}
              className={`!absolute left-4 top-4 !bg-white`}
            >
              <ChatIcon />
            </Avatar>
          </BootstrapTooltip>
        )}
        <div
          className={`${
            id % 2 === 0 ? "text-[#12B76A]" : "text-[#D27400]"
          } absolute right-4 top-4 w-fit rounded-[8px] bg-white px-[12px] py-[6px] `}
        >
          {id % 2 === 0 ? "Completed" : "In progress"}
        </div>
      </div>

      <div className="rounded-16 bg-white px-3 py-4">
        <p className="mb-1 text-sm font-medium text-primary">
          {requestCategory}
        </p>
        <h4 className="font-Bai text-2xl font-semibold text-grey">
          {requestDetails}
        </h4>

        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center  rounded-[12px] bg-[#FDF2E6] px-3 py-2">
            <p className="flex items-center gap-2 text-sm font-medium text-[#693A00]">
              New Requests
              <span className="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-primary font-Bai text-xs font-medium text-white">
                {id % 2 === 0 ? "12" : "3"}
              </span>
              <span>of 12</span>
            </p>
          </div>
          <h4 className="font-Bai text-2xl font-semibold text-grey">
            {amount}
          </h4>
        </div>
      </div>

      <div className="rounded-16 bg-white px-3 py-4">
        <span className="mb-2 flex items-center gap-x-1">
          <FmdGoodOutlinedIcon fontSize="small" />
          {location}
        </span>
        <span className="flex items-center gap-x-1">
          <AccessTimeIcon fontSize="small" />
          {dateTime}
        </span>
      </div>
    </div>
  );
}

export default JobRequestCard;
