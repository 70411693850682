import {useState} from "react";
// import { useController } from "react-hook-form";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import {ReactComponent as Registered} from "assets/icons/registered-provider.svg";
import {ReactComponent as RegisteredActive} from "assets/icons/registered-provider-active.svg";
import {ReactComponent as Unregistered} from "assets/icons/unregistered-provider.svg";
import {ReactComponent as UnregisteredActive} from "assets/icons/unregistered-provider-active.svg";
import {CircularProgress} from "@mui/material";
import {useServiceProviderTypeMutation} from "services/user/userRegApi";
import {handleError} from "utils";
import {LoadingButton} from "@mui/lab";

const UserName = ({onBtnClick}) => {
  // const controller = useController();
  const [choice, setChoice] = useState("");

  const [createType, {isLoading}] = useServiceProviderTypeMutation();

  const handleChoice = e => {
    setChoice(e.currentTarget.value);
  };
  const handleBtnClick = async () => {
    const formData = new FormData();

    formData.append("service_provider_type", choice);

    try {
      await createType(formData).unwrap();
      // dispatch(setUserDetails(res?.data));
      onBtnClick();
    } catch (error) {
      handleError(error);
    }
  };

  const users = [
    {
      name: "Registered Service Provider",
      icon: <Registered />,
      activeIcon: <RegisteredActive />,
      title: "Registered Service Provider",
      description:
        "You are fully registered wth the CAC and you have all your informations ready.",
    },

    {
      name: "Unregistered Service Provider",
      icon: <Unregistered />,
      activeIcon: <UnregisteredActive />,
      title: "Unregistered Service Provider/Freelancer",
      description:
        "You are fully registered wth the CAC and you have all your informations ready.",
    },
  ];
  return (
    <div className="w-full rounded-24 bg-white py-10">
      <h1 className="text-prop px-6 font-Bai text-2xl font-bold md:px-8 md:text-[2rem]">
        Let’s Know Who You Are
      </h1>
      <p
        className="text-prop mb-4 mt-2 px-6 font-Mulish text-base
       text-grey_30 md:mb-[20px] md:px-8"
      >
        Yay! Happy to have you🤩
        <br /> Let’s get to know you
      </p>
      <div className="mb-4 border-t-[1px] border-grey_60"></div>
      <div className="flex flex-col items-center gap-y-6 px-6 md:my-12 md:flex-row md:gap-x-4">
        {users.map(({name, icon, activeIcon, title, description}, index) => (
          <button
            name={name}
            key={index}
            onClick={e => handleChoice(e)}
            value={name}
            type="button"
            className={`flex min-h-[230px] basis-1/2 flex-col gap-y-3 rounded-24 p-6 font-Bai ${
              choice === name ? "bg-[#FEFAF6]" : "bg-grey_80"
            }`}
          >
            {choice === name ? activeIcon : icon}
            <p
              className={`text-left text-lg font-bold ${
                choice === name ? "text-[#8C4D00]" : ""
              }`}
            >
              {title}
            </p>
            <p
              className={`text-left font-Mulish text-[13px] ${
                choice === name ? "text-[#AF6100]" : "text-grey_30"
              }`}
            >
              {description}
            </p>
          </button>
        ))}
      </div>
      {choice && (
        <LoadingButton
          endIcon={<EastRoundedIcon fontSize="large" />}
          sx={{
            padding: "18px 40px !important",
            textTransform: "none",
            display: "flex",
            width: {xs: "90%", md: "75%"},
            mx: "auto",
            mt: {xs: "1.5rem", md: "1rem"},
          }}
          onClick={handleBtnClick}
          loadingIndicator={
            <CircularProgress
              sx={{
                color: "#fff",
              }}
              size="1.2rem"
            />
          }
          type="button"
          loading={isLoading}
          fullWidth
          variant="contained"
          color="primary"
        >
          Proceed as{" "}
          {choice.toLowerCase().includes("unregistered")
            ? "an Unregistered "
            : "a Registered "}
          Service Provider
        </LoadingButton>
      )}
    </div>
  );
};
export default UserName;
