import StarFilledIcon from "components/Vectors/StarFilledIcon";

const OfferCard = ({rating, description, image, name, date}) => {
  return (
    <div className=" rounded-10 bg-white py-[24px] px-4 ">
      {/* image and name  */}
      <div className="flex items-center">
        <img
          src={image}
          alt="img"
          className="h-[48px] w-[48px] rounded-full  "
        />
        <div className="ml-[16px] flex w-full items-center justify-between ">
          <div className="">
            <h3 className="text-prop text-base font-medium text-grey_20 ">
              {name}
            </h3>
            <p className="text-prop flex items-center gap-1 text-[13px] font-medium text-[#E98100] ">
              <span>
                <StarFilledIcon height="12" width="12" color="#E98100" />{" "}
              </span>{" "}
              <span>{rating}.0</span>
            </p>
          </div>
          <span className=" text-[13px] font-medium text-[#AAAAAA] ">
            {date}
          </span>{" "}
        </div>
      </div>

      {/* rating  */}

      <div className=" mt-4 ">
        <span className="text-prop text-[14px] font-semibold text-[#3B3D3B] ">
          Request Message
        </span>

        <p className="mt-[4px] text-[14px] font-normal leading-[22px] tracking-[-4%] text-grey_30 ">
          {description}
        </p>
      </div>
    </div>
  );
};
export default OfferCard;
