const SearchIcon = props => {
  return (
    <>
      <svg
        width={props.width || "12"}
        height={props.height || "12"}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.25 11.25L9.20839 9.20833M10.6667 5.70833C10.6667 8.44675 8.44675 10.6667 5.70833 10.6667C2.96992 10.6667 0.75 8.44675 0.75 5.70833C0.75 2.96992 2.96992 0.75 5.70833 0.75C8.44675 0.75 10.6667 2.96992 10.6667 5.70833Z"
          stroke={props.stroke || "#AAAAAA"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {/* <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 16L13.5834 13.0833M15.6667 8.08333C15.6667 11.9954 12.4954 15.1667 8.58333 15.1667C4.67132 15.1667 1.5 11.9954 1.5 8.08333C1.5 4.17132 4.67132 1 8.58333 1C12.4954 1 15.6667 4.17132 15.6667 8.08333Z" stroke="#AAAAAA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg> */}
    </>
  );
};

export default SearchIcon;
