import {useState} from "react";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import MyModal from "components/mui-components/Modal";
import {useGetAllPackagesQuery} from "services/user/userRegApi";
import CircularLoader from "components/Loader/CircularLoader";
import ActiveTokenModal from "./ActiveTokenModal";
import LoadingBtn from "components/mui-components/LoadingButton";
import {CircularProgress} from "@mui/material";
import {useGetUserRequest} from "hooks/useGetUserRequest";
import usePaystackPaymentHook from "hooks/usePaystackPaymentHook";

const PurchaseTokenModal = ({openModal, closeModal, refetch}) => {
  const [choice, setChoice] = useState(null);
  const [showActiveToken, setShowActiveToken] = useState(false);
  const {refetch: refetchUser} = useGetUserRequest();
  const {data, isLoading} = useGetAllPackagesQuery();

  // function will be called after payment
  const onPaymentDone = () => {
    refetchUser();
    if (typeof refetch === "function") {
      refetch();
    }
    setChoice(null);
  };

  // custom hook to process payment
  const {
    isLoading: isPaystackLoading,
    showInitPaystack,
    initPaymentHandler,
  } = usePaystackPaymentHook({
    id: choice,
    onPaymentDone,
    module: "TokenPackage",
  });

  // call init hook on form submit
  const handleSubmit = async e => {
    e.preventDefault();
    initPaymentHandler();
  };

  return (
    <>
      {showInitPaystack && (
        <div className="fixed inset-0 z-[100000] flex items-center justify-center bg-[#000000fa]">
          <CircularProgress color="white" />
        </div>
      )}
      <MyModal
        openModal={openModal}
        closeModal={closeModal}
        title="Purchase Package"
      >
        {/* <ActiveTokenModal
        openModal={showActiveToken}
        closeModal={() => {
          setShowActiveToken(false);
          closeModal();
        }}
      /> */}
        {isLoading ? (
          <CircularLoader />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-3 md:gap-5">
              {data?.data?.map(({name, tokens, amount, id}) => (
                <button
                  key={`token_${id}`}
                  type="button"
                  onClick={e => setChoice(id)}
                  className="flex flex-col justify-between gap-y-5 rounded-20 bg-grey_80 p-3 md:p-4"
                >
                  <div className="flex w-full items-center justify-between">
                    <p
                      className={`font-Bai font-medium md:text-lg ${
                        choice === name ? "text-primary" : "text-grey"
                      }`}
                    >
                      {name}
                    </p>
                    <span
                      className={`h-5 w-5 rounded-full border md:h-8 md:w-8 ${
                        choice === id
                          ? "flex items-center justify-center border-primary"
                          : "border-grey_60"
                      }`}
                    >
                      {choice === id && (
                        <span className="h-3 w-3 rounded-full bg-primary md:h-5 md:w-5"></span>
                      )}
                    </span>
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <p className="text-sm capitalize text-grey_30">
                      <span className="text-grey">{tokens}</span> tokens
                    </p>
                    <span className="font-Bai text-sm font-medium text-grey md:text-base">
                      ₦{amount.toLocaleString()}
                    </span>
                  </div>
                </button>
              ))}
            </div>
            {choice && (
              <LoadingBtn
                loading={isPaystackLoading}
                startIcon={<LockOpenOutlinedIcon sx={{color: "#FFF"}} />}
                type="submit"
                fullWidth
                sx={{mt: "32px"}}
              >
                Purchase with Card
              </LoadingBtn>
            )}
          </form>
        )}
      </MyModal>
      <ActiveTokenModal
        openModal={showActiveToken}
        closeModal={() => {
          setShowActiveToken(false);
          closeModal();
        }}
      />
    </>
  );
};
export default PurchaseTokenModal;
