import EmptyResponse from "components/mui-components/EmptyResponse";
import LocationIcon from "components/Vectors/LocationIcon";
import {Button, Skeleton} from "@mui/material";
import CityDetail from "./CityDetail";

function Cities({state, isLoading}) {
  return (
    <div>
      <h3 className="mb-4 font-Bai text-[32px] font-semibold text-grey_20">
        Cities/Towns
      </h3>
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height={250} />
      ) : (
        <div className="noScroll relative max-h-[723px] overflow-y-auto rounded-2xl bg-grey_70 p-4">
          <>
            <div className="mb-2">
              {state?.name && (
                <h3 className="sticky -top-4 z-[9] mb-4 bg-grey_70 py-2 font-Bai text-2xl font-semibold text-grey_20">
                  {`Cities/Towns in ${state?.name}`}
                </h3>
              )}
              <div>
                {state?.cities?.length > 0 ? (
                  <>
                    <div className="flex flex-col gap-2">
                      {state?.cities?.map((city, i) => (
                        <CityDetail key={i} data={city} />
                      ))}
                    </div>
                  </>
                ) : (
                  <EmptyResponse
                    icon={
                      <LocationIcon
                        width={"62px"}
                        height="62px"
                        stroke={"#3B3D3B"}
                      />
                    }
                    message={"No Location Added yet"}
                    btn={<Button variant="contained">Add City/State</Button>}
                  />
                )}
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
}

export default Cities;
