import { AccessTimeOutlined, FavoriteBorderOutlined, LocationOnOutlined, RemoveRedEyeOutlined } from '@mui/icons-material'
import { Button, Chip, IconButton } from '@mui/material'
import BackBtn from 'components/BackBtn'
import UserDisplay from 'components/mui-components/TableComponent/UserDisplay'
import React, { useState } from 'react'
import NewEachOffer from './widgets/NewEachOffer'
import { Link } from 'react-router-dom'
import NewJobCard from 'components/NewJobCard'
import NewMakeOfferModal from './Modals/NewMakeOfferModal'
import NewWithdrawOfferModal from './Modals/NewWithdrawOfferModal'

const NewJobDetail = () => {
    const [openModal, setOpenModal] = useState(false)
    const [openWithdrawOffer, setOpenWithdrawOffer] = useState(false)
    return (
        <>
            <main className='bg-grey-100 min-h-screen -mx-4 md:-mx-6 lg:-mx-[4vw] -my-4 lg:-my-9 px-4 md:px-6 lg:px-[4vw] py-4 lg:py-9'>
                <section className="max-w-[1000px] mx-auto">
                    <BackBtn />
                    <div className="flex flex-col-reverse md:grid gap-6 mt-3 md:grid-cols-12">
                        <div className="bg-white md:col-span-8 rounded-3xl px-12 py-8">
                            <p className="font-bold text-xs text-primary">
                                Beauty & Health
                            </p>

                            <p className="font-Bai my-3 font-semibold text-2xl md:text-[2rem] text-grey-10">
                                Happy Ending Massage. A title for two lines and more
                            </p>

                            <p className="font-medium text-xs text-grey-30 mb-4">
                                Posted on <span className="text-grey_10">
                                    23 June, 2022
                                </span>
                            </p>

                            <div className="flex justify-between mb-4 items-center">
                                <Chip
                                    label="Open"
                                    color="successInverse"
                                />

                                <div className="flex gap-2 items-center">
                                    <span className="px-2 py-3 bg-grey-100 rounded-lg flex gap-1 items-center font-semibold text-xs text-grey-10">
                                        <RemoveRedEyeOutlined fontSize='small' />

                                        2.1K Views
                                    </span>

                                    <span className="px-2 py-3 bg-grey-100 rounded-lg flex gap-1 items-center font-semibold text-xs text-grey-10">
                                        <FavoriteBorderOutlined fontSize='small' />

                                        82 saves
                                    </span>
                                </div>
                            </div>

                            <UserDisplay
                                name='Opeyemi Daudu'
                                email={"@opedaudu"}
                            />

                            <p className="mt-8 font-Bai font-semibold text-sm text-grey-10 mb-1">
                                Description
                            </p>

                            <p className="font-medium text-grey_base text-sm">
                                Ac duis accumsan consectetur pellentesque pellentesque enim eu duis eros. Egestas augue quis posuere semper. Mus consectetur magna tempus massa. Semper aliquet amet a pulvinar mi gravida feugiat id pellentesque ac ultricies vivamus tincidunt...
                            </p>

                            <Button
                                size="small"
                            >
                                Read More
                            </Button>

                            <div className="my-4 flex gap-1 items-center font-semibold text-xs text-grey_30">
                                <AccessTimeOutlined fontSize='small' sx={{ color: '#5010B3' }} />
                                4 Weeks
                            </div>
                            <div className="flex gap-1 itemscenter font-semibold text-xs text-grey_30">
                                <LocationOnOutlined fontSize='small' sx={{ color: '#5010B3' }} />

                                <div>
                                    <p>Ketu, Lagos</p>
                                    <p>4517 Washington Ave. Manchester, Kentucky 39495</p>
                                </div>
                            </div>

                            <div className='mt-10'>
                                <div className="flex gap-2 items-center mb-4">
                                    <p className="font-Bai text-lg md:text-xl text-grey-10 font-semibold">
                                        Your Offer
                                    </p>


                                </div>

                                <div className="flex flex-col gap-2">
                                    <NewEachOffer
                                        yourOffer={true}
                                        setOpenWithdrawOfferModal={setOpenWithdrawOffer}
                                    />
                                </div>
                            </div>

                            <div className='mt-10'>
                                <div className="flex gap-2 items-center mb-4">
                                    <p className="font-Bai text-lg md:text-xl text-grey-10 font-semibold">
                                        Other Offers
                                    </p>

                                    <span className="p-2 bg-grey-100 rounded-full text-grey-10 text-xs font-semibold">
                                        12
                                    </span>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <NewEachOffer />
                                    <NewEachOffer />
                                </div>
                            </div>
                        </div>

                        <div className="bg-white max-w-[300px] md:col-span-4 h-fit rounded-3xl p-4 md:p-6">
                            <p className="font-bold text-3xl md:text-4xl text-grey_20 font-Bai">
                                ₦30,000
                            </p>

                            <div className="mt-6 flex gap-3 items-center">
                                <Button
                                    variant='contained'
                                    size='small'
                                    onClick={() => setOpenModal(true)}
                                >
                                    Make  offer
                                </Button>

                                <IconButton
                                    sx={{ border: "1px solid #C9C9CF", borderRadius: "10px !important" }}
                                >
                                    <FavoriteBorderOutlined fontSize='small' />
                                </IconButton>
                            </div>
                        </div>
                    </div>

                </section>

                <section className=" pt16 -mx-4 md:-mx-6 lg:-mx-[4vw] px-4 md:px-6 lg:px-[4vw] py-6 lg:py-12">
                    <div className="bg-grey-100 max-w-[1200px] mx-auto">
                        <Link to='/client/discover-jobs' className="mb-8 font-Bai font-semibold text-black flex gap-3 items-center text-2xl  md:text-[2rem]">
                            Explore similar Jobs
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M36.1785 21.1786C36.8294 20.5277 36.8294 19.4724 36.1785 18.8216L29.5118 12.1549C28.861 11.504 27.8057 11.504 27.1548 12.1549C26.5039 12.8058 26.5039 13.8611 27.1548 14.5119L30.9763 18.3334L4.99998 18.3334C4.07951 18.3334 3.33331 19.0796 3.33331 20.0001C3.33331 20.9206 4.07951 21.6667 4.99998 21.6667L30.9763 21.6667L27.1548 25.4882C26.5039 26.1391 26.5039 27.1944 27.1548 27.8453C27.8057 28.4961 28.861 28.4961 29.5118 27.8453L36.1785 21.1786Z" fill="black" />
                            </svg>
                        </Link>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {Array(9).fill("").map((_, i) =>
                                <NewJobCard
                                    link="/client/jobs/123"
                                />
                            )}
                        </div>
                    </div>
                </section>
            </main >

            <NewMakeOfferModal
                openModal={openModal}
                setOpenModal={setOpenModal}
            />

            <NewWithdrawOfferModal
                openModal={openWithdrawOffer}
                setOpenModal={setOpenWithdrawOffer}
            />
        </>
    )
}

export default NewJobDetail