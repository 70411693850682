import { KeyboardArrowDown } from '@mui/icons-material'
import React, { useState } from 'react'

const FreqQuestionDropdown = ({ actionText, listItems }) => {
    const [showDropDown, setShowDropDown] = useState(false)
    return (
        <div className='p-4 md:p-6 bg-white rounded-16'>
            <div
                onClick={() => setShowDropDown(!showDropDown)}
                className="flex items-center gap-4 cursor-pointer"
            >
                <p className="w-full ma-w-[485px] font-Bai font-semibold text-base md:text-xl text-grey_10">
                    {actionText}
                </p>
                <KeyboardArrowDown fontSize='large' />
            </div>

            {showDropDown &&
                <ul className="flex px-4 md:px-6 mt-5 transition-all duration-200 flex-col gap-4">
                    {listItems.map((el, i) =>
                        <li
                            key={el + i}
                            className=""
                        >
                            {el}
                        </li>
                    )}
                </ul>
            }
        </div>
    )
}

export default FreqQuestionDropdown