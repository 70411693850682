import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";

import {Provider} from "react-redux";
import store, {persistor} from "redux/store";
import {PersistGate} from "redux-persist/integration/react";

// MATERIAL UI
import {ThemeProvider} from "@mui/material/styles";
import {customTheme} from "./utils/mui";
import Loader from "components/Loader/Loader";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <ThemeProvider theme={customTheme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
