import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Avatar} from "@mui/material";
import {Link} from "react-router-dom";
import moment from "moment";

function EachVerification({user, icon}) {
  const {
    id,
    avatar,
    user_type,
    created_at,
    service_provider_type,
    full_name,
    cac,
    kyc,
  } = user;

  return (
    <div key={id}>
      <div className="rounded-xl bg-grey_80 p-3">
        <div className="mb-3 flex items-center gap-3">
          <div className="icon-box flex h-[56px] w-[56px] items-center justify-center rounded bg-white">
            {icon}
          </div>
          <div className="stats">
            <p className="text-base font-bold text-grey_30">
              {user_type === "Client"
                ? "CAC Certificate"
                : service_provider_type === "Registered Service Provider"
                ? "CAC Certificate"
                : "Government ID"}{" "}
              Verification
            </p>
          </div>
        </div>
        <p className="text-[13px] font-medium capitalize text-[#aaaaaa]">
          {moment(created_at).fromNow()}
        </p>
        <Link
          to={
            user?.user_type
              ? user?.user_type === "Client"
                ? `/admin/clients/${id}`
                : `/admin/service-providers/${id}`
              : ""
          }
          className="mt-2 flex w-full items-center justify-between rounded p-1 transition-all hover:bg-grey_70"
        >
          <div className="flex items-center gap-2">
            <Avatar src={avatar} alt={full_name} />
            <h4 className="text-base font-normal text-grey_20">{full_name}</h4>
          </div>
          <KeyboardArrowRightIcon color="secondary" />
        </Link>
      </div>
    </div>
  );
}

export default EachVerification;
