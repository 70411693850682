import { Button } from '@mui/material';
import { useGetOnboardingStatus } from 'hooks/getOnboardingStatus';
import { useGetUser } from 'hooks/getUserHook';
import IncompleteModal from 'pages/Clients/Dashboard/IncompleteModal';
import UnApprovedKycModal from 'pages/Clients/Dashboard/UnApprovedKycModal';
import NoServiceModal from 'pages/ServiceProviders/Modals/NoServiceModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAllSPServicesQuery } from 'services/user/providerGeneralApi';
const ProtectedBtn = ({
  isProfessional,
  btnText = "Post a Job",
  link = "/client/post-job",
  handleExternalClick,
  ...props
}) => {
  const { user } = useGetUser();
  const { data: services } = useGetAllSPServicesQuery();
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);
  const [showNoserviceModal, setShowNoServiceModal] = useState(false);
  const [showUnApprovedKycModal, setShowUnApprovedKycModal] = useState(false);
  const navigate = useNavigate();
  const { isOnboardingComplete, status } = useGetOnboardingStatus(
    user?.user_type?.toLowerCase(),
  );
  const skippedSteps = Object.keys(status || {}).filter(
    step => status[step] === false,
  );
  const objMap = {
    BasicInformation: "Basic Information",
    ProfessionalInformation: "Professional Information",
    Kyc: "KYC",
    BankAccount: "Account details",
    CacCertificate: "CAC certificate",
  };
  const handleProvider = () => {
    if (services?.data?.length === 0) {
      setShowNoServiceModal(true);
    } else {
      navigate(link);
    }
  };
  const handleUnApprovedKyc = () => {
    setShowUnApprovedKycModal(true);
  };
  return (
    <>
      <Button
        onClick={() =>
          handleExternalClick
            ? handleExternalClick()
            : !isProfessional
              ? !isOnboardingComplete
                ? setShowIncompleteModal(true)
                : user?.kyc?.status !== "Approved"
                  ? handleUnApprovedKyc()
                  : navigate(link)
              : handleProvider()
        }
        variant={props?.variant || "outlined"}
        {...props}
      >
        {btnText}
      </Button>
      <IncompleteModal
        isProfessional={isProfessional}
        issues={skippedSteps.map(step => objMap[step]).join(", ")}
        open={showIncompleteModal}
        close={() => setShowIncompleteModal(false)}
      />
      <NoServiceModal
        open={showNoserviceModal}
        close={() => setShowNoServiceModal(false)}
      />
      <UnApprovedKycModal
        open={showUnApprovedKycModal}
        close={() => setShowUnApprovedKycModal(false)}
      />

    </>
  );
};

export default ProtectedBtn