const PlayStoreBlue = ({width, height}) => {
  return (
    <svg
      width={width || "26"}
      height={height || "28"}
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.73739 1.54015C0.433614 1.84765 0.257812 2.3264 0.257812 2.9464V25.0614C0.257812 25.6814 0.433614 26.1602 0.73739 26.4677L0.812365 26.5352L13.6278 14.1477V13.8552L0.812365 1.46766L0.73739 1.54015Z"
        fill="#5010B3"
      />
      <path
        d="M19.4468 18.2773L15.1797 14.1461V13.8536L19.4519 9.72235L19.5476 9.7761L24.6071 12.5598C26.051 13.3498 26.051 14.6498 24.6071 15.4448L19.5476 18.2236L19.4468 18.2773Z"
        fill="#5010B3"
      />
      <path
        d="M18.774 18.994L14.4061 14.7702L1.51562 27.2365C1.9952 27.724 2.77727 27.7827 3.66662 27.2952L18.774 18.994Z"
        fill="#5010B3"
      />
      <path
        d="M18.774 9.00672L3.66662 0.705472C2.77727 0.222972 1.9952 0.281721 1.51562 0.76922L14.4061 13.2305L18.774 9.00672Z"
        fill="#5010B3"
      />
    </svg>
  );
};

export default PlayStoreBlue;
