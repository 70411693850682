import React, {useEffect, useState} from "react";
import {Button, Chip} from "@mui/material";
import moment from "moment";
import {useGetUser} from "hooks/getUserHook";
import {toCurrency} from "utils";
import UserAvatar from "components/mui-components/TableComponent/UserAvatar";
import {Link} from "react-router-dom";
import CancelEngagementModal from "pages/Clients/Engagements/EachEngagements/Modal/CancelEngagementModal";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {useGetOfferChatsQuery} from "services/generalApi";
import {SendOfferMsgForm} from "pages/ServiceProviders/MarketPlace/Widgets/CurrentProviderOffer";
import {ConversationList} from "pages/Clients/Engagements/EachEngagements/MessageChat";
import Pusher from "pusher-js";
import StarFilledIcon from "components/Vectors/StarFilledIcon";
import {useMarkOfferChatAsReadMutation} from "services/user/userRegApi";

export const CancelEngBtn = ({
  btnText = "Cancel engagement",
  engagement_id,
  ...props
}) => {
  const [openModal, setopenModal] = useState(false);

  return (
    <>
      <Button
        size="small"
        onClick={() => setopenModal(true)}
        variant="outlined"
        color="error"
        {...props}
      >
        {btnText}
      </Button>
      <CancelEngagementModal
        onOpen={openModal}
        engagement_id={engagement_id}
        onClose={() => setopenModal(false)}
      />
    </>
  );
};

function EachRequestCard({
  detail,
  isAdmin = false,
  acceptDeclineHandler,
  creatorId,
}) {
  const ChipStatusColor = {
    pending: "default",
    accepted: "successInverse",
    withdrawn: "errorInverse",
    declined: "errorInverse",
    ongoing: "secondaryInverse",
    active: "success",
    suspended: "greyDark",
    terminated: "errorInverse",
    cancelled: "errorInverse",
    closed: "error",
    drafted: "greyBorder",
    open: "greyBorder",
    completed: "successInverse",
    refunded: "secondaryInverse",
  };

  const {status, pitch_message, amount_offered, user, engagement = {}} = detail;
  const linkTo = !isAdmin
    ? `/user-profile/${user?.id}?type=service provider`
    : user?.user_type.toLowerCase() === "client"
    ? `/admin/clients/${user?.id}`
    : `/admin/service-providers/${user?.id}`;
  const {user: loggedInUser} = useGetUser();

  const {
    data: allChats,
    isLoading: isChatsLoading,
    refetch,
  } = useGetOfferChatsQuery(detail?.id);
  const [markOfferMsgsAsRead] = useMarkOfferChatAsReadMutation();
  useEffect(() => {
    if (!isAdmin) markOfferMsgsAsRead(detail?.id).unwrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("offer-conversation-created");

    channel.bind("offer-conversations-event", data => {
      refetch();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [refetch]);

  // generate engagement status
  const isPaymentPending =
    detail?.engagement?.payment_status === "pending" &&
    detail?.engagement?.status?.toLowerCase() === "ongoing";

  const hasEngagementStarted =
    !isPaymentPending &&
    !detail?.engagement?.start_date &&
    detail?.engagement?.status?.toLowerCase() === "ongoing";

  const engagementStatus = isPaymentPending
    ? "Pending payment"
    : hasEngagementStarted
    ? "Pending Start"
    : detail?.engagement?.status;

  return (
    <div>
      <div className="rounded-16 bg-white p-3 md:rounded-24 md:p-6">
        <div className="flex items-start justify-between">
          <Link to={linkTo} className="flex items-center gap-x-2 md:gap-x-3">
            <UserAvatar
              showBadge={user?.is_verified_account}
              alt={user?.full_name}
              src={user?.professional_information?.business_logo}
              size={48}
            />
            <div className="flex gap-2">
              <div className="flex flex-col">
                <span className="font-Bai text-base font-bold text-grey_20 transition-colors hover:text-primary md:text-lg">
                  {user?.professional_information?.business_name ||
                    user?.full_name}
                </span>
                <span className="text-xs text-grey_30">@{user?.username}</span>
              </div>
              {user?.is_top_vetted &&
                user?.user_type.toLowerCase() === "service provider" && (
                  <Chip
                    avatar={<StarRoundedIcon />}
                    color="secondaryInverse"
                    label="Top Vetted"
                    sx={{
                      fontSize: "10px",
                      border: "1px solid #5010B3",
                      "& .MuiChip-avatar": {
                        color: "#5010B3",
                        fontSize: "11px",
                      },
                    }}
                    size="small"
                  />
                )}
            </div>
          </Link>
          <div>
            <div className="flex justify-end gap-x-1 py-1 px-2 text-[13px] text-primary">
              <StarFilledIcon height="16" width="17" color="#E98100" />
              {user?.average_rating?.toFixed(1)}
            </div>
            <p className="mt-2 text-right text-[13px] font-medium text-grey_20">
              {moment(detail?.created_at).fromNow()}
            </p>
          </div>
        </div>

        <div className="mt-6">
          <div className="mb-2 flex items-center justify-between gap-3">
            <div className="flex items-center gap-3">
              <p className="text-sm font-semibold text-grey_20">
                Request Message
              </p>
            </div>
            <p className="font-Bai text-sm font-bold">
              {toCurrency(amount_offered)}
            </p>
          </div>
          <p className="text-[13px] font-medium leading-relaxed text-grey_30">
            {pitch_message}
          </p>
        </div>
        <div className="mt-5 flex gap-2">
        <div>
          <p className="mb-1 text-[13px] text-grey_30">Offer Status</p>
          <Chip
            sx={{borderRadius: "8px", mb:1}}
            label={status}
            color={ChipStatusColor[status?.toLowerCase()]}
          />
        </div>
        {detail?.engagement && (
          <div>
            <p className="mb-1 text-[13px] text-grey_30">Engagement Status</p>
            <Chip
              sx={{borderRadius: "8px"}}
              // size="small"
              label={engagementStatus}
              color={ChipStatusColor[engagementStatus?.toLowerCase()]}
            />
          </div>
        )}
      </div>
        {status?.toLowerCase() === "accepted" && (
          <div className="my-4 flex h-[40px] gap-2">
            <Button
              component={Link}
              to={
                isAdmin
                  ? `/admin/engagements/${engagement?.id}`
                  : `/client/engagements/${engagement?.id}`
              }
              size="small"
              variant="contained"
            >
              View engagement
            </Button>
            {/* {engagement?.payment_status === "pending" &&
              engagement?.status.toLowerCase() === "ongoing" && (
                <CancelEngBtn engagement_id={engagement?.id} />
              )} */}
          </div>
        )}

        {isChatsLoading && (
          <p className="flex py-4">conversations loading...</p>
        )}
        <div>
          {(allChats?.conversations || [])?.length > 0 && (
            <div className="mb-4 rounded-20 bg-grey_80 p-5 text-[13px]">
              <h1 className="mb-4 font-Bai text-sm font-semibold text-grey_20">
                Replies
              </h1>
              <div className="space-y-4">
                <ConversationList
                  provider_id={allChats?.user_id}
                  chats={allChats?.conversations}
                />
              </div>
            </div>
          )}
          {isAdmin === false && (
            <SendOfferMsgForm
              module_id={detail?.id}
              status={status}
              receiver_id={
                loggedInUser?.id === creatorId ? user?.id : creatorId
              }
            />
          )}
        </div>
        {status?.toLowerCase() === "pending" && isAdmin === false && (
          <div className="mt-6 flex items-center gap-4 md:justify-end">
            <Button
              variant="outlined"
              color="error"
              type="button"
              onClick={() =>
                acceptDeclineHandler({type: "decline", id: detail.id})
              }
              className="w-1/2 !max-w-[140px]"
            >
              Decline
            </Button>
            <Button
              type="button"
              color="success"
              variant="contained"
              onClick={() =>
                acceptDeclineHandler({type: "accept", id: detail.id})
              }
              className="w-1/2 !max-w-[140px]"
            >
              Approve
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EachRequestCard;
