function BriefcaseIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5749 5.81628C16.8665 5.03294 15.6832 4.64128 13.9665 4.64128H13.7665V4.60794C13.7665 3.20794 13.7665 1.47461 10.6332 1.47461H9.36653C6.2332 1.47461 6.2332 3.21628 6.2332 4.60794V4.64961H6.0332C4.3082 4.64961 3.1332 5.04128 2.42487 5.82461C1.59987 6.74128 1.62487 7.97461 1.7082 8.81628L1.71654 8.87461L1.77732 9.51289C1.79159 9.66267 1.87234 9.79805 1.99834 9.88027C2.20039 10.0121 2.51013 10.2109 2.69987 10.3163C2.81654 10.3913 2.94154 10.4579 3.06654 10.5246C4.49154 11.3079 6.0582 11.8329 7.64987 12.0913C7.72487 12.8746 8.06653 13.7913 9.89153 13.7913C11.7165 13.7913 12.0749 12.8829 12.1332 12.0746C13.8332 11.7996 15.4749 11.2079 16.9582 10.3413C17.0082 10.3163 17.0415 10.2913 17.0832 10.2663C17.4034 10.0853 17.7352 9.86361 18.0398 9.64493C18.153 9.56366 18.2253 9.43753 18.2407 9.29902L18.2499 9.21628L18.2915 8.82461C18.2999 8.77461 18.2999 8.73294 18.3082 8.67461C18.3749 7.83294 18.3582 6.68294 17.5749 5.81628ZM10.9082 11.5246C10.9082 12.4079 10.9082 12.5413 9.8832 12.5413C8.8582 12.5413 8.8582 12.3829 8.8582 11.5329V10.4829H10.9082V11.5246ZM7.42487 4.64128V4.60794C7.42487 3.19128 7.42487 2.66628 9.36653 2.66628H10.6332C12.5749 2.66628 12.5749 3.19961 12.5749 4.60794V4.64961H7.42487V4.64128Z"
        fill={props.color || "#EAEAF0"}
      />
      <path
        d="M17.2599 11.509C17.614 11.3417 18.0212 11.6223 17.9858 12.0123L17.7002 15.1583C17.5252 16.8249 16.8419 18.5249 13.1752 18.5249H6.82519C3.15853 18.5249 2.4752 16.8249 2.3002 15.1666L2.02948 12.1888C1.99444 11.8033 2.39276 11.5233 2.7458 11.6819C3.705 12.113 5.317 12.8064 6.35299 13.0854C6.51699 13.1295 6.65014 13.2476 6.72454 13.4003C7.23875 14.4555 8.32794 15.0166 9.89186 15.0166C11.4404 15.0166 12.5429 14.4339 13.059 13.3755C13.1335 13.2227 13.2666 13.1047 13.4307 13.0603C14.5325 12.7618 16.2538 11.9845 17.2599 11.509Z"
        fill={props.color || "#EAEAF0"}
      />
    </svg>
  );
}

export default BriefcaseIcon;
