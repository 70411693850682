import JobRequestsIcon from "components/Vectors/JobRequestsIcon";

const EmptyJobRequest = () => {
  return (
    <div className="flex h-[200px] w-full flex-col items-center justify-center p-4 md:h-[400px] ">
      <JobRequestsIcon height="55" width="43" stroke="#3B3D3B" />
      <p className="text-prop pt-4 font-Bai text-[32px] font-semibold text-grey_20 ">
        No job offers yet
      </p>
    </div>
  );
};
export default EmptyJobRequest;
