import { KeyboardArrowRightOutlined } from '@mui/icons-material'
import { Avatar, Button, IconButton } from '@mui/material'
import NewJobCard from 'components/NewJobCard'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import EachUpdate from 'components/EachUpdate';
import NewOfferCard from 'components/NewOfferCard';
import NewEngagementCard from 'components/NewEngagementCard';
import { Link } from 'react-router-dom';
import SelectCategoryModal from './SelectCategoryModal';
import { useState } from 'react';
SwiperCore.use([Navigation]);

const NewMain = () => {
    const [openSelectCategoryModal, setOpenSelectCategoryModal] = useState(false)
    return (
        <>
            <main className='w-full bgwhite'>
                <p className="mb-4 md:mb-6 font-semibold text-2xl md:text-[2rem] text-grey-60 ">Hello, <span className="text-grey-10">Emmanuel</span> 😀</p>

                <div className="grid lg:grid-cols-12 gap-12 md:gap-6">
                    <div className="lg:col-span-8 grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div
                            style={{
                                backgroundImage: "url('/images/star-3d-circle.png'), url('/images/star-vector.png')",
                                backgroundPosition: "right bottom,  70% top",
                                backgroundSize: "25%,10%"
                            }}
                            className="bg-primary-10 md:col-span-2 p-8 rounded-3xl bg-cover bg-no-repeat"
                        >
                            <p className="font-semibold font-Bai text-2xl md:text-[2rem] text-primary-80">
                                Earn 20 Tokens
                            </p>
                            <p className="font-medium text-sm text-[#625A50] mt-3 mb-5">
                                Upload Basic Info and get rewarded
                            </p>

                            <Button
                                size="small"
                                sx={{ color: "#151519 !important", fontWeight: 600, padding: 0 }}
                                endIcon={<KeyboardArrowRightOutlined />}
                            >
                                Upload Basic Info
                            </Button>
                        </div>
                        <div
                            style={{
                                backgroundImage: "url('/images/post-job-3d.png'), url('/images/send-vector.png')",
                                backgroundPosition: "95% bottom, 97% 6%",
                                backgroundSize: "35%, 10%"
                            }}
                            className="bg-[#EBFAFF] p-8 rounded-3xl bg-cover bg-no-repeat"
                        >
                            <p className="font-semibold font-Bai text-2xl md:text-[2rem] text-primary-80">
                                Post a Job
                            </p>
                            <p className="font-medium text-sm text-[#625A50] mt-3 mb-5">
                                Get the best hands on your Job now.
                            </p>

                            <IconButton
                                sx={{ backgroundColor: "#00BFFF" }}
                                size='small'
                            >
                                <KeyboardArrowRightOutlined fontSize="medium" color="white" />
                            </IconButton>
                        </div>
                        <div
                            style={{
                                backgroundImage: "url('/images/earn-money-3d.png'), url('/images/coin-vector.png')",
                                backgroundPosition: "right bottom, 80% 20%",
                                backgroundSize: "30%"
                            }}
                            className="bg-[#F3ECFD] p-8 rounded-3xl bg-cover bg-no-repeat"
                        >
                            <p className="font-semibold font-Bai text-2xl md:text-[2rem] text-primary-80">
                                Earn Money!
                            </p>
                            <p className="font-medium text-sm text-[#625A50] mt-3 mb-5">
                                Get a Job done and earn.
                            </p>

                            <IconButton
                                sx={{ backgroundColor: "#5010B3" }}
                                size='small'
                            >
                                <KeyboardArrowRightOutlined fontSize="medium" color="white" />
                            </IconButton>
                        </div>
                    </div>

                    <div className="relative max-h-[440px]  lg:col-span-4 p-4 md:p-6 grid border border-[#DCDCE0] rounded-3xl">
                        <div className="absolute -top-[18px] bg-white left-10 rounded-[200px] font-semibold text-black text-xs px-3 py-2 border-[#DCDCE0] border">
                            🔥 Latest Updates
                        </div>

                        <div className="flex flex-col gap-2 overflow-y-auto scrollBarstyle">
                            <EachUpdate />
                            <EachUpdate />
                            <EachUpdate />
                            <EachUpdate />
                            <EachUpdate />
                            <EachUpdate />
                            <EachUpdate />
                            <EachUpdate />
                            <EachUpdate />
                            <EachUpdate />
                            <EachUpdate />
                        </div>
                    </div>
                </div>

                {/* Ongoing Engagements */}
                <section className="bg-grey-100 mt-16 -mx-4 md:-mx-6 lg:-mx-[4vw] px-4 md:px-6 lg:px-[4vw] py-6 lg:py-12">
                    <button type='button' className="mb-8 font-Bai font-semibold text-black flex gap-3 items-center text-2xl md:text-[2rem]">
                        Ongoing Engagements
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.1785 21.1786C36.8294 20.5277 36.8294 19.4724 36.1785 18.8216L29.5118 12.1549C28.861 11.504 27.8057 11.504 27.1548 12.1549C26.5039 12.8058 26.5039 13.8611 27.1548 14.5119L30.9763 18.3334L4.99998 18.3334C4.07951 18.3334 3.33331 19.0796 3.33331 20.0001C3.33331 20.9206 4.07951 21.6667 4.99998 21.6667L30.9763 21.6667L27.1548 25.4882C26.5039 26.1391 26.5039 27.1944 27.1548 27.8453C27.8057 28.4961 28.861 28.4961 29.5118 27.8453L36.1785 21.1786Z" fill="black" />
                        </svg>
                    </button>

        <Swiper
                    spaceBetween={24}
                    slidesPerView={2.3}
                    modules={[Navigation]}
                    navigation={true}
                    freeMode
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        640: {
                            slidesPerView: 1.5,
                        },
                        900: {
                            slidesPerView: 2.3,
                        },
                        1300: {
                            slidesPerView: 3.3,
                        },
                    }}
                >
        {/* {allJobs?.data
                        .filter(job => job.status === "Active")
                        .map((job, index) => (
                          <SwiperSlide key={`single_job_${index}`}>
                            <SingleJobCard
                              showUser={false}
                              key={job?.id}
                              job={job}
                              isProfessional={false}
                            />
                          </SwiperSlide>
                        ))} */}
                        {Array(6).fill("").map((_, i) =>
                            <SwiperSlide key={`single_job_${i}`}>
                                <NewEngagementCard />
                            </SwiperSlide>
                        )}
                    </Swiper>
                </section>

                {/*  Pending Offers */}
                <section className="bg-grey-100 pt16 -mx-4 md:-mx-6 lg:-mx-[4vw] px-4 md:px-6 lg:px-[4vw] py-6 lg:py-12">
                    <button type='button' className="mb-8 font-Bai font-semibold text-black flex gap-3 justify-start items-center text-2xl md:text-[2rem]">
                        Pending Offers
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.1785 21.1786C36.8294 20.5277 36.8294 19.4724 36.1785 18.8216L29.5118 12.1549C28.861 11.504 27.8057 11.504 27.1548 12.1549C26.5039 12.8058 26.5039 13.8611 27.1548 14.5119L30.9763 18.3334L4.99998 18.3334C4.07951 18.3334 3.33331 19.0796 3.33331 20.0001C3.33331 20.9206 4.07951 21.6667 4.99998 21.6667L30.9763 21.6667L27.1548 25.4882C26.5039 26.1391 26.5039 27.1944 27.1548 27.8453C27.8057 28.4961 28.861 28.4961 29.5118 27.8453L36.1785 21.1786Z" fill="black" />
                        </svg>
                    </button>

                    <Swiper
                        spaceBetween={24}
                        slidesPerView={2.3}
                        modules={[Navigation]}
                        navigation={true}
                        freeMode
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 1.5,
                            },
                            900: {
                                slidesPerView: 2.3,
                            },
                            1300: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {Array(6).fill("").map((_, i) =>
                            <SwiperSlide key={`single_job_${i}`}>
                                <NewOfferCard />
                            </SwiperSlide>
                        )}
                    </Swiper>


                    <div
                        style={{
                            backgroundImage: "url('iages/category_bg.png'),linear-gradient(to right, #34343D,#151519)",
                            // backgroundColor: "#151519"
                        }}
                        className="rounded-[2rem] flex flex-col justify-center min-h-[314px] bg-cover bg-no-repeat max-w-[1200px] mx-auto p-7 md:p-14"
                    >
                        {/* <div className="bg-[#34343D] rounded-l-[2rem] max-h-full p-14 max-w-[520px]"> */}

                        <p className="font-bold font-Bai max-w-[491px] text-white text-2xl md:text-[2.5rem]">
                            Choose what you want to see the most
                        </p>

                        <p className="mt-3 mb-8 font-medium text-grey-90 text-sm">
                            Select Your categories of interest
                        </p>

                        <Button
                            size="small"
                            variant='contained'
                            onClick={() => setOpenSelectCategoryModal(true)}
                            sx={{ width: "fit-content" }}
                        >
                            Select categories
                        </Button>
                        {/* </div> */}
                    </div>
                </section>



                {/* NEWEST JOB */}
                <section className="bg-grey-100 pt16 -mx-4 md:-mx-6 lg:-mx-[4vw] px-4 md:px-6 lg:px-[4vw] py-6 lg:py-12">
                    <Link to='/client/discover-jobs' className="mb-8 font-Bai font-semibold text-black flex gap-3 items-center text-2xl  md:text-[2rem]">
                        Newest Jobs
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.1785 21.1786C36.8294 20.5277 36.8294 19.4724 36.1785 18.8216L29.5118 12.1549C28.861 11.504 27.8057 11.504 27.1548 12.1549C26.5039 12.8058 26.5039 13.8611 27.1548 14.5119L30.9763 18.3334L4.99998 18.3334C4.07951 18.3334 3.33331 19.0796 3.33331 20.0001C3.33331 20.9206 4.07951 21.6667 4.99998 21.6667L30.9763 21.6667L27.1548 25.4882C26.5039 26.1391 26.5039 27.1944 27.1548 27.8453C27.8057 28.4961 28.861 28.4961 29.5118 27.8453L36.1785 21.1786Z" fill="black" />
                        </svg>
                    </Link>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {Array(9).fill("").map((_, i) =>
                            <NewJobCard
                                link="/client/jobs/123"
                            />
                        )}
                    </div>
                </section>

                <section className="bg-grey-100 mt16 -mx-4 md:-mx-6 lg:-mx-[4vw] px-4 md:px-6 lg:px-[4vw] py-6 lg:py-12">
                    <h1 className="mb-8 font-Bai font-semibold text-black text-center text-3xl md:text-[2.5rem]">
                        What do you want to get done? Post it!

                    </h1>

                    <Swiper
                        spaceBetween={24}
                        slidesPerView={2.3}
                        modules={[Navigation]}
                        navigation={true}
                        freeMode
                        breakpoints={{
                            320: {
                                slidesPerView: 1.5,
                            },
                            640: {
                                slidesPerView: 3,
                            },
                            900: {
                                slidesPerView: 4,
                            },
                            1300: {
                                slidesPerView: 5,
                            },
                        }}
                    >
                        {/* {allJobs?.data
                        .filter(job => job.status === "Active")
                        .map((job, index) => (
                          <SwiperSlide key={`single_job_${index}`}>
                            <SingleJobCard
                              showUser={false}
                              key={job?.id}
                              job={job}
                              isProfessional={false}
                            />
                          </SwiperSlide>
                        ))} */}
                        {Array(6).fill("").map((_, i) =>
                            <SwiperSlide key={`single_job_${i}`}>
                                <PostCard key={i} />
                            </SwiperSlide>
                        )}
                    </Swiper>
                </section>
            </main >

            <SelectCategoryModal
                openModal={openSelectCategoryModal}
                setOpenModal={setOpenSelectCategoryModal}
            />
        </>
    )
}

export default NewMain;

const PostCard = () => {
  return (
    <div className="flex flex-col items-center gap-2 rounded-2xl bg-white p-8">
      <Avatar
        src="/images/car-repair-vector.png"
        alt="post-image"
        sx={{width: "54px", height: "54px"}}
      />

      <p className="font-Bai text-base font-semibold text-grey-10">
        Car Repair
      </p>
    </div>
  );
};
