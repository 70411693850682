import { Button } from "@mui/material";
import firstOverviewImg from "assets/images/overview-first-combined.png";
import secondOverviewImg from "assets/images/overview-second-combined.png";
import { useGetUser } from "hooks/getUserHook";
import { useNavigate } from "react-router-dom";

const Overview = () => {
  const navigate = useNavigate();
  const { user, isLoggedIn } = useGetUser();

  return (
    <div className="mx-auto bgwhite my-28 max-w-[1440px] px-4 sm:px-6 md:px-[4vw]">
      <div className="flex flex-col items-start gap-12 md:grid md:grid-cols-2 md:gap-20">
        <section className="max-w-[525px] flex-1">
          <h2 className="font-Bai text-2xl font-semibold leading-snug text-grey_10 md:text-[40px]">
            Need help with anything?
          </h2>
          <p className="mt-2 mb-4 font-medium text-grey_20">
            Discover a diverse pool of service providers redy to take on any task. Simply post any task and “Booooom” its done.
          </p>
          <div className="flex items-center justify-start gap-x-5">
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() => navigate("/client/post-job")}
            >
              Post a Job
            </Button>
            <Button
              sx={{
                bgcolor: "#FDF2E6",
                color: "#D27400",
                textTransform: "none",
                "&:hover": {
                  bgcolor: "#FDF2E6",
                  color: "#D27400",
                  opacity: 0.8,
                },
              }}
              variant="contained"
              onClick={() => navigate("/how-it-works/clients")}
            >
              See how it works
            </Button>
          </div>
        </section>
        <img className="flex-1" src={firstOverviewImg} alt="" />
      </div>

      <div className="mt-20 flex flex-col items-start gap-12 md:mt-32 md:grid md:grid-cols-2 md:gap-20">
        <section className="max-w-[525px] flex-1">
          <h2 className="font-Bai text-2xl font-semibold leading-snug text-grey_10 md:text-[40px]">
            Looking for extra cash?
          </h2>
          <p className="mt-2 mb-4 font-medium text-grey_20">
            Discover opportunities that aligns with yur schedule and skill sets. Truelocal is flexible. Make extra cash doing what you love.
          </p>
          <div className="flex items-center justify-start gap-x-5">
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() =>
                isLoggedIn
                  ? user?.user_type?.toLowerCase() === "service provider"
                    ? navigate("/service-provider/marketplace")
                    : navigate("/client")
                  : navigate("/user/login")
              }
            >
              Explore Opportunities
            </Button>
            <Button
              sx={{
                bgcolor: "#FDF2E6",
                color: "#D27400",
                textTransform: "none",
                "&:hover": {
                  bgcolor: "#FDF2E6",
                  color: "#D27400",
                  opacity: 0.8,
                },
              }}
              variant="contained"
              onClick={() => navigate("/how-it-works/service-providers")}
            >
              See how it works
            </Button>
          </div>
        </section>
        <img className="flex-1" src={secondOverviewImg} alt="" />
      </div>
    </div>
  );
};

export default Overview;
