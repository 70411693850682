function VideoIcon(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.9997 37.9168H14.9997C5.94967 37.9168 2.08301 34.0502 2.08301 25.0002V15.0002C2.08301 5.95016 5.94967 2.0835 14.9997 2.0835H24.9997C34.0497 2.0835 37.9163 5.95016 37.9163 15.0002V25.0002C37.9163 34.0502 34.0497 37.9168 24.9997 37.9168ZM14.9997 4.5835C7.31634 4.5835 4.58301 7.31683 4.58301 15.0002V25.0002C4.58301 32.6835 7.31634 35.4168 14.9997 35.4168H24.9997C32.683 35.4168 35.4163 32.6835 35.4163 25.0002V15.0002C35.4163 7.31683 32.683 4.5835 24.9997 4.5835H14.9997Z"
        fill={props.color || "#A2A2B9"}
      />
      <path
        d="M35.8002 13.1001H4.2002C3.51686 13.1001 2.9502 12.5334 2.9502 11.8501C2.9502 11.1668 3.5002 10.6001 4.2002 10.6001H35.8002C36.4835 10.6001 37.0502 11.1668 37.0502 11.8501C37.0502 12.5334 36.5002 13.1001 35.8002 13.1001Z"
        fill={props.color || "#A2A2B9"}
      />
      <path
        d="M14.2002 12.8666C13.5169 12.8666 12.9502 12.2999 12.9502 11.6166V3.5166C12.9502 2.83327 13.5169 2.2666 14.2002 2.2666C14.8835 2.2666 15.4502 2.83327 15.4502 3.5166V11.6166C15.4502 12.2999 14.8835 12.8666 14.2002 12.8666Z"
        fill={props.color || "#A2A2B9"}
      />
      <path
        d="M25.7998 12.1166C25.1165 12.1166 24.5498 11.5499 24.5498 10.8666V3.5166C24.5498 2.83327 25.1165 2.2666 25.7998 2.2666C26.4831 2.2666 27.0498 2.83327 27.0498 3.5166V10.8666C27.0498 11.5666 26.4998 12.1166 25.7998 12.1166Z"
        fill={props.color || "#A2A2B9"}
      />
      <path
        d="M18.4833 30.2001C17.8833 30.2001 17.3167 30.0501 16.8 29.7668C15.6667 29.1001 15 27.7668 15 26.0834V22.0834C15 20.4001 15.6667 19.0501 16.8167 18.3834C17.9667 17.7168 19.4667 17.8168 20.9167 18.6668L24.3833 20.6668C25.8333 21.5001 26.6833 22.7501 26.6833 24.0834C26.6833 25.4168 25.8333 26.6668 24.3667 27.5001L20.9 29.5001C20.1 29.9668 19.2667 30.2001 18.4833 30.2001ZM18.5 20.4501C18.3333 20.4501 18.1833 20.4834 18.0667 20.5501C17.7167 20.7501 17.5 21.3168 17.5 22.0834V26.0834C17.5 26.8334 17.7167 27.4001 18.0667 27.6168C18.4167 27.8168 19.0167 27.7168 19.6667 27.3334L23.1333 25.3334C23.7833 24.9501 24.1833 24.4834 24.1833 24.0834C24.1833 23.6834 23.8 23.2168 23.1333 22.8334L19.6667 20.8334C19.2333 20.5834 18.8167 20.4501 18.5 20.4501Z"
        fill={props.color || "#A2A2B9"}
      />
    </svg>
  );
}

export default VideoIcon;
