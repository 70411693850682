import {useRef, useState} from "react";
import BackBtn from "components/BackBtn";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import {Avatar, Button, CircularProgress} from "@mui/material";
import MessageModal from "components/mui-components/Modal/MessageModal";
import CheckIcon2 from "components/Vectors/CheckIcon2";
import {FormProvider, useForm} from "react-hook-form";
import ValidatedInput from "components/forms/ValidatedInput";
import {LoadingButton} from "@mui/lab";
import {useUpdateAdminInfoMutation} from "services/admin/adminProfileApi";
import {useDispatch} from "react-redux";
import {setUserDetails} from "redux/slice/AuthSlice";
import {handleError} from "utils";
import {useGetUser} from "hooks/getUserHook";
import ValidatedRadioBtns from "components/forms/ValidatedRadioBtns";
import {showToast} from "redux/store.hooks";

function EditProfile() {
  const [openModal, setOpenModal] = useState(false),
    [showFileTooLarge, setShowFileTooLarge] = useState(false);
  const profileRef = useRef();

  const [imageCont, setImageCont] = useState(null);

  const dispatch = useDispatch();
  const {user: currentUser} = useGetUser();
  const {handleSubmit, control, reset, ...methods} = useForm({
    defaultValues: {
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name,
      gender: currentUser?.gender,
    },
  });

  const [updateInfo, {isLoading: isUpdateInfoLoading}] =
    useUpdateAdminInfoMutation();

  const onSubmit = async data => {
    const formData = new FormData();

    if (imageCont) {
      formData.append("profile_picture", imageCont);
    }

    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("gender", data?.gender);
    try {
      const res = await updateInfo(formData).unwrap();
      setOpenModal(prev => !prev);
      showToast(res?.message, "success");
      dispatch(setUserDetails({...currentUser, ...res?.data}));
    } catch (error) {
      handleError(error);
    }
  };

  const photoChange = e => {
    const image = e.target.files[0];
    if (image.size > 1_000_000) {
      setShowFileTooLarge(true);
      return;
    }
    setShowFileTooLarge(false);
    setImageCont(image);
    methods.setValue("profile_picture", image);
  };

  return (
    <DashboardLayout title={"Account"} type="admin">
      <main>
        <BackBtn text="Accounts" />
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-8 rounded-2xl bg-white p-4 md:p-8"
          >
            <div className="items flex justify-between">
              <div className="relative flex items-center gap-4">
                {showFileTooLarge && (
                  <span className="absolute -bottom-4 left-1 text-[10px] text-error">
                    Image size should not be more than 1MB
                  </span>
                )}
                <Avatar
                  src={
                    imageCont
                      ? URL.createObjectURL(imageCont)
                      : currentUser?.photo_url
                  }
                  alt="img"
                  sx={{
                    height: "120px",
                    width: "120px",
                  }}
                />
                <div className="flex flex-col gap-3">
                  <input
                    accept="image/*"
                    hidden
                    type="file"
                    id="file"
                    ref={profileRef}
                    // {...methods.register("profile_picture")}
                    onChange={photoChange}
                    // {...methods.register("profile_picture", {
                    //   onChange: photoChange,
                    // })}
                  />
                  <Button
                    variant="contained"
                    sx={{borderRadius: "12px", padding: "12px 18px !important"}}
                    color="primaryInverse2"
                    onClick={() => profileRef.current.click()}
                  >
                    Change Photo
                  </Button>

                  <Button
                    variant="contained"
                    sx={{borderRadius: "12px", padding: "12px 18px !important"}}
                    color="errorInverse"
                    onClick={() => {
                      setImageCont(null);
                    }}
                  >
                    Remove Photo
                  </Button>
                </div>
              </div>
              <div className="mt-3 hidden sm:block">
                <LoadingButton
                  variant="contained"
                  sx={{fontSize: "16px", width: "171px"}}
                  type="submit"
                  loading={isUpdateInfoLoading}
                  loadingIndicator={
                    <CircularProgress size={24} sx={{color: "#FFF"}} />
                  }
                >
                  Save Changes
                </LoadingButton>
              </div>
            </div>

            <div className="w-full md:w-1/2">
              <div className="mt-7 rounded-2xl bg-grey_80 p-4 md:p-6">
                <div className="w-full">
                  <ValidatedInput
                    name="first_name"
                    variant="nofree"
                    bg="white"
                    textSize="text-sm"
                    label="First Name"
                  />
                  <ValidatedInput
                    name="last_name"
                    bg="white"
                    textSize="text-sm"
                    label="Last Name"
                  />
                  <ValidatedRadioBtns
                    name="gender"
                    options={["Male", "Female", "Prefer Not To Say"]}
                    label="Sex"
                  />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </main>

      <MessageModal
        openModal={openModal}
        icon={<CheckIcon2 />}
        closeModal={() => setOpenModal(prev => !prev)}
        iconBg={"bg-[#FDF2E6]"}
        title={"Changes Saved"}
        buttonTitle="Close"
        // description="The credential has been sent to the client’s email"
      />
    </DashboardLayout>
  );
}

export default EditProfile;
