import InputField from "./InputField";
// import SearchIcon from "@mui/icons-material/Search";
import SearchIcon from "components/Vectors/SearchIcon";

function SearchInput(props) {
  const {className = "", textSize = "", maxWidth, ...otherProps} = props;
  return (
    <InputField
      prefixIcon={props.icon || <SearchIcon />}
      className={`w-full !rounded-[25px] ${className} ${
        maxWidth ? maxWidth : "max-w-[420px]"
      } ${textSize} `}
      spaceY={false}
      {...otherProps}
      placeholder={props.placeholder || "Search, user, services, campaigns..."}
    />
  );
}

export default SearchInput;
