import {LoadingButton} from "@mui/lab";
import {Avatar} from "@mui/material";
import moment from "moment";
import {showToast} from "redux/store.hooks";
import {useBlockUnblockUserMutation} from "services/user/userRegApi";
import {handleError} from "utils";

function BlockedUserCard({user}) {
  const [block, {isLoading}] = useBlockUnblockUserMutation();

  const blockHandler = async () => {
    try {
      const res = await block({
        type: "unblock",
        body: {user_id: user?.id},
      }).unwrap();
      showToast(res?.message);
    } catch (error) {
      handleError();
    }
  };
  return (
    <div className="flex items-center justify-between rounded-20 bg-grey_80 p-4">
      <div className="flex items-center gap-2">
        <Avatar
          src={user?.avatar}
          alt={user?.full_name}
          sx={{height: "55px", width: "55px"}}
        />
        <div>
          <p className="text-lg font-medium text-grey_20">{user?.full_name}</p>
          <p className="mt-1.5 text-sm font-semibold text-grey_40">
            {moment(user?.created_at).format("DD MMM YYYY")}
          </p>
        </div>
      </div>
      <LoadingButton
        loading={isLoading}
        onClick={blockHandler}
        variant="contained"
      >
        Unblock
      </LoadingButton>
    </div>
  );
}

export default BlockedUserCard;
