import { forwardRef } from "react";
import "./style.scss";

const TextAreaField2 = forwardRef(
  (
    {
      label,
      height,
      className = "",
      spaceY = true,
      variant = "free",
      bg = "#F9FAFB",
      bg2 = "#F9FAFB",
      textSize = "text-[14px]",
      isInvalid,
      bordered = true,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="">
        {label && (
          <label
            className={`font-Mulish  font-[400] ${textSize} text-grey_30  `}
          >
            {label}
          </label>
        )}
        <div
          style={{ backgroundColor: bg2 }}
          className={` form-group textarea-wrap mt-[6px] rounded-[16px] ${bordered ? 'border-[1.5px] border-[#DCDCE5]' : ''} ${height ? height : "min-h-[80px]"
            } bg-[${bg}] ${className}`}
        >
          <textarea
            ref={ref}
            className={`placeholder-neutral z-10 w-full resize-none  bg-transparent font-Bai placeholder:text-[14px] focus:rounded-16 focus:outline focus:outline-primary ${height ? height : "min-h-[80px]"
              }`}
            {...props}
          />
        </div>
      </div>
    );
  },
);
export default TextAreaField2;
