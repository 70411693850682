import React from 'react'

const DraftedJobIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5C9 4.44772 9.44772 4 10 4H14C14.5523 4 15 4.44772 15 5H9ZM14 2C15.6569 2 17 3.34315 17 5H19C20.6569 5 22 6.34315 22 8V16V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V16V8C2 6.34315 3.34315 5 5 5H7C7 3.34315 8.34315 2 10 2H14ZM4 8C4 7.44772 4.44772 7 5 7H8H16H19C19.5523 7 20 7.44772 20 8V15H17.811C17.9344 15.3196 18 15.6547 18 16C18 16.3453 17.9344 16.6804 17.811 17H20V19C20 19.5523 19.5523 20 19 20H12H5C4.44772 20 4 19.5523 4 19V17H6.18903C6.06563 16.6804 6 16.3453 6 16C6 15.6547 6.06563 15.3196 6.18903 15H4V8Z" fill="#5010B3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5775 11.0012C14.7205 10.4677 14.4039 9.9194 13.8704 9.77646L11.9386 9.25882C11.4051 9.11588 10.8568 9.43246 10.7138 9.96593L9.93738 12.8637L9.52496 14.4029C9.4575 14.6547 9.49101 14.9228 9.61836 15.1503L10.3186 16.4008C10.5934 16.8916 11.219 17.0592 11.7024 16.7716L12.9341 16.0387C13.1581 15.9054 13.3212 15.6899 13.3887 15.4382L13.8011 13.899L14.5775 11.0012Z" fill="#5010B3" />
        </svg>
    )
}

export default DraftedJobIcon