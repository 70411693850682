const CreationCompletedIcon = (props) => {
  return (
    <svg
      width="128"
      height="127"
      viewBox="0 0 128 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M95.2277 26.4511C96.3174 29.0865 98.409 31.1813 101.043 32.2749L110.278 36.1003C112.913 37.192 115.007 39.2859 116.099 41.9215C117.19 44.5571 117.19 47.5185 116.099 50.154L112.276 59.3827C111.184 62.0194 111.182 64.9838 112.28 67.6193L116.095 76.8452C116.637 78.1505 116.915 79.5498 116.915 80.9629C116.916 82.376 116.637 83.7753 116.097 85.0808C115.556 86.3864 114.763 87.5726 113.764 88.5717C112.765 89.5707 111.578 90.3631 110.272 90.9034L101.044 94.7261C98.4089 95.8158 96.3142 97.9074 95.2206 100.541L91.3953 109.776C90.3037 112.412 88.2098 114.506 85.5743 115.598C82.9388 116.689 79.9776 116.689 77.3421 115.598L68.1138 111.775C65.4782 110.686 62.5181 110.688 59.8841 111.781L50.6492 115.601C48.0152 116.69 45.0565 116.689 42.4232 115.599C39.7898 114.508 37.6971 112.416 36.6047 109.783L32.7783 100.545C31.6887 97.9101 29.5971 95.8153 26.9635 94.7216L17.7285 90.8962C15.0942 89.805 13.0009 87.7124 11.9089 85.0783C10.8168 82.4442 10.8153 79.4843 11.9047 76.8491L15.7272 67.6205C16.8162 64.9848 16.814 62.0245 15.721 59.3905L11.904 50.1486C11.3629 48.8433 11.0842 47.444 11.084 46.0309C11.0838 44.6178 11.362 43.2185 11.9027 41.9129C12.4435 40.6074 13.2362 39.4212 14.2355 38.4221C15.2349 37.4231 16.4213 36.6307 17.727 36.0904L26.9553 32.2677C29.5883 31.179 31.6817 29.09 32.7761 26.4593L36.6014 17.224C37.693 14.5884 39.7869 12.4945 42.4224 11.4028C45.0579 10.3111 48.0191 10.3111 50.6546 11.4028L59.8829 15.2254C62.5185 16.3144 65.4786 16.3122 68.1126 15.2192L77.3514 11.4087C79.9865 10.3176 82.9471 10.3178 85.5821 11.4093C88.217 12.5008 90.3107 14.5942 91.4025 17.2291L95.2289 26.4671L95.2277 26.4511Z"
        fill={props.color ? props.color : "#FAEFD7"}
      />
      <path
        d="M48.1256 63.5006L58.709 74.084L82.5215 50.2715M95.2277 26.4511C96.3174 29.0865 98.4089 31.1813 101.043 32.2749L110.278 36.1003C112.913 37.192 115.007 39.286 116.099 41.9215C117.19 44.5571 117.19 47.5185 116.099 50.1541L112.276 59.3827C111.184 62.0194 111.182 64.9838 112.28 67.6193L116.095 76.8452C116.637 78.1505 116.915 79.5498 116.915 80.9629C116.916 82.376 116.637 83.7753 116.097 85.0808C115.556 86.3864 114.763 87.5726 113.764 88.5717C112.765 89.5707 111.578 90.3631 110.272 90.9034L101.044 94.7261C98.4089 95.8157 96.3142 97.9074 95.2206 100.541L91.3953 109.776C90.3037 112.412 88.2098 114.506 85.5743 115.598C82.9388 116.689 79.9776 116.689 77.3421 115.598L68.1138 111.775C65.4782 110.686 62.5181 110.688 59.8841 111.781L50.6492 115.601C48.0152 116.69 45.0565 116.689 42.4232 115.599C39.7898 114.508 37.6971 112.416 36.6047 109.783L32.7783 100.545C31.6887 97.9101 29.5971 95.8153 26.9635 94.7216L17.7285 90.8962C15.0942 89.805 13.0009 87.7124 11.9089 85.0783C10.8168 82.4442 10.8153 79.4843 11.9047 76.8491L15.7272 67.6205C16.8162 64.9848 16.814 62.0245 15.721 59.3905L11.904 50.1486C11.3629 48.8433 11.0842 47.444 11.084 46.0309C11.0838 44.6178 11.362 43.2185 11.9027 41.9129C12.4435 40.6074 13.2362 39.4212 14.2355 38.4221C15.2349 37.4231 16.4213 36.6307 17.727 36.0904L26.9553 32.2677C29.5883 31.179 31.6817 29.09 32.7761 26.4593L36.6014 17.224C37.693 14.5884 39.7869 12.4945 42.4224 11.4028C45.0579 10.3111 48.0191 10.3111 50.6546 11.4028L59.8829 15.2254C62.5185 16.3144 65.4786 16.3122 68.1126 15.2192L77.3514 11.4087C79.9865 10.3176 82.9471 10.3178 85.5821 11.4093C88.2171 12.5008 90.3107 14.5942 91.4025 17.2291L95.2289 26.4671L95.2277 26.4511Z"
        stroke={props.color ? props.color : "#F6A001"}
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CreationCompletedIcon;
