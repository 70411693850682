import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Button, Tab} from "@mui/material";
import {CustomTabStyle2} from "App";
import OverviewCard from "components/OverviewCard";
import TokenIcon from "components/Vectors/TokenIcon";
import {useState} from "react";
import {useNavigate} from "react-router";
import {useGetAllPackagesQuery} from "services/admin/adminGeneralApi";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import AddPackageModal from "./Modal/PackageModal";
import BonusTokenTab from "./Tabs/BonusTokenTab";
import HistoryTab from "./Tabs/HistoryTab";
import PackagesTab from "./Tabs/PackagesTab";

const AdminTokenPackages = () => {
  const [activeTab, setactiveTab] = useState("packages");
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const {data, isLoading, error} = useGetAllPackagesQuery();
  return (
    <DashboardLayout title={"Token Packages"} type="admin">
      <section>
        <section className="rounded-2xl border border-[#E4E7EC] bg-white p-4 md:py-6 ">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="font-Bai text-2xl font-medium text-black">
              Overview
            </h3>

            <Button variant="contained" onClick={() => setOpenModal(true)}>
              Add a package
            </Button>
          </div>

          <div className=" grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
            <OverviewCard
              title={"All Packages"}
              isLoading={isLoading}
              icon={<TokenIcon height="14" width="20" stroke={"#E98100"} />}
              value={`${data?.data?.length || 0}`}
            />
            <OverviewCard
              title={"Active Packages"}
              isLoading={isLoading}
              icon={<TokenIcon height="14" width="20" stroke={"#5010B3"} />}
              value={`${
                data?.data?.filter(el => el.status.toLowerCase() === "active")
                  .length || 0
              }`}
            />
            <OverviewCard
              title={"Inactive Packages"}
              icon={<TokenIcon height="14" width="20" stroke={"#3B3D3B"} />}
              isLoading={isLoading}
              value={`${
                data?.data?.filter(el => el.status.toLowerCase() === "inactive")
                  .length || 0
              }`}
            />
          </div>
        </section>

        <section className="mt-6 rounded-2xl bg-white p-4 md:py-6 ">
          <TabContext value={activeTab}>
            <TabList
              variant="scrollable"
              value={activeTab}
              className="mb-4 w-fit  rounded-[16px] border border-grey_60 bg-grey_80 py-2"
              onChange={(_, newValue) => {
                navigate(`?tab=${newValue}`);
                setactiveTab(newValue);
              }}
              sx={{
                fontWeight: 500,
                color: "#6E726E",
                backgroundColor: "#F9FAFB !important",
                borderColor: "#E4E7EC !important",
                borderWidth: "1px",
                borderRadius: "16px",
                width: "fit-content",
                padding: "8px",
                ...CustomTabStyle2,
                textTransform: "unset",
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Packages" value="packages" />
              <Tab label="Subscription History" value="history" />
              <Tab label="Admin Bonus Token" value="bonus" />
            </TabList>
            <TabPanel value="packages">
              <PackagesTab
                data={data?.data}
                isLoading={isLoading}
                error={error}
              />
            </TabPanel>
            <TabPanel value="history">
              <HistoryTab />
            </TabPanel>
            <TabPanel value="bonus">
              <BonusTokenTab />
            </TabPanel>
          </TabContext>
        </section>
      </section>
      <AddPackageModal
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </DashboardLayout>
  );
};
export default AdminTokenPackages;
