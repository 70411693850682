import React from 'react'

const SuspendedJobIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5C9 4.44772 9.44772 4 10 4H14C14.5523 4 15 4.44772 15 5H9ZM14 2C15.6569 2 17 3.34315 17 5H19C20.6569 5 22 6.34315 22 8V16V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V16V8C2 6.34315 3.34315 5 5 5H7C7 3.34315 8.34315 2 10 2H14ZM4 8C4 7.44772 4.44772 7 5 7H8H16H19C19.5523 7 20 7.44772 20 8V15H17.811C17.9344 15.3196 18 15.6547 18 16C18 16.3453 17.9344 16.6804 17.811 17H20V19C20 19.5523 19.5523 20 19 20H12H5C4.44772 20 4 19.5523 4 19V17H6.18903C6.06563 16.6804 6 16.3453 6 16C6 15.6547 6.06563 15.3196 6.18903 15H4V8Z" fill="#6E726E" />
            <path d="M10 12V16" stroke="#6E726E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14 12V16" stroke="#6E726E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default SuspendedJobIcon