function AlignBoxIcon({color}) {
  return (
    <div>
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 7C14.9319 7 15.3978 7 15.7654 6.84776C16.2554 6.64477 16.6448 6.25542 16.8478 5.76537C17 5.39782 17 4.93188 17 4C17 3.06812 17 2.60218 16.8478 2.23463C16.6448 1.74458 16.2554 1.35523 15.7654 1.15224C15.3978 1 14.9319 1 14 1L6 1C5.06812 1 4.60218 1 4.23463 1.15224C3.74458 1.35523 3.35523 1.74458 3.15224 2.23463C3 2.60218 3 3.06812 3 4C3 4.93188 3 5.39782 3.15224 5.76537C3.35523 6.25542 3.74458 6.64477 4.23463 6.84776C4.60218 7 5.06812 7 6 7L14 7Z"
          stroke={color || "#E98100"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 17C16.9319 17 17.3978 17 17.7654 16.8478C18.2554 16.6448 18.6448 16.2554 18.8478 15.7654C19 15.3978 19 14.9319 19 14C19 13.0681 19 12.6022 18.8478 12.2346C18.6448 11.7446 18.2554 11.3552 17.7654 11.1522C17.3978 11 16.9319 11 16 11H4C3.06812 11 2.60218 11 2.23463 11.1522C1.74458 11.3552 1.35523 11.7446 1.15224 12.2346C1 12.6022 1 13.0681 1 14C1 14.9319 1 15.3978 1.15224 15.7654C1.35523 16.2554 1.74458 16.6448 2.23463 16.8478C2.60218 17 3.06812 17 4 17L16 17Z"
          stroke={color || "#E98100"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default AlignBoxIcon;
