import React, {useState} from "react";
import {Chip, Button} from "@mui/material";
import DropDownWrapper from "components/mui-components/DropDownWrapper";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import AddIcon from "@mui/icons-material/Add";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import DeleteIcon2 from "components/Vectors/DeleteIcon2";
import MessageModal2 from "components/mui-components/Modal/MessageModal2";
import AddCityModal from "../Modals/AddCityModal";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import moment from "moment/moment";
import EditCityModal from "../Modals/EditCityModal";

function CityDetail({data}) {
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [openEditCityModal, setOpenEditCityModal] = useState(false);

  

  return (
    <div className=" rounded-2xl bg-white p-4">
      <div className="flex justify-between">
        <h3 className="font-Bai text-2xl font-semibold text-grey_20">
          {data.name}
        </h3>

        <DropDownWrapper
          contentPadding="p-[0]"
          position="right"
          border="border border-[#F2F4F7] "
          action={
            <div className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F2F4F7]">
              <MoreHorizRoundedIcon
                sx={{
                  fontSize: "16px",
                  color: "#e98100",
                }}
              />
            </div>
          }
        >
          <div>
            <Button
              variant="text"
              sx={{
                color: "#6E726E",
              }}
              startIcon={
                <EditRoundedIcon sx={{fontSize: "14px", color: "#6E726E"}} />
              }
              onClick={() => setOpenEditCityModal(true)}
            >
              Edit City
            </Button>
            <Button
              variant="text"
              sx={{
                color: "#D92D20",
              }}
              startIcon={
                <DeleteIcon2 sx={{fontSize: "16px", color: "#D92D20"}} />
              }
              onClick={() => setopenDeleteModal(true)}
            >
              Delete City/Town
            </Button>
          </div>
        </DropDownWrapper>
      </div>

      <p className="mt-8 text-sm font-medium text-grey_30">
        Last Modified: {moment(data.updated_at).format("Do MMMM, YYYY")}
      </p>

      <MessageModal2
        openModal={openDeleteModal}
        closeModal={() => setopenDeleteModal(false)}
        otherState={() => {
          setopenDeleteModal(false);
        }}
        title={`Are you sure you want to delete ${data.name}?`}
        actionBtnText="Delete"
      />
      <EditCityModal
        cityID={data.id}
        stateID={data.state_id}
        cityName={data.name}
        openModal={openEditCityModal}
        setopenModal={setOpenEditCityModal}
      />
    </div>
  );
}

export default CityDetail;
