import EachEngagementCard from "./EachEngagementCard";

function EngagementBody({ user, list }) {
  return (
    <div className="mb-5 rounded-2xl bg-[#F2F4F7] p-4 md:p-6">
      {list.map(({ icon, text, valueKey, buttonText, link }, i) => (
        <div key={text + i} className="flex flex-col gap-5">
          <EachEngagementCard
            icon={icon}
            text={text}
            value={user ? user[valueKey] : 0}
            link={link}
            buttonText={buttonText}
          />
        </div>
      ))}
    </div>
  );
}

export default EngagementBody;
