import {Button} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {useDelBannerMutation} from "services/admin/adminGeneralApi";
import {showToast} from "redux/store.hooks";
import {handleError} from "utils";
import {LoadingButton} from "@mui/lab";
import EditIcon2 from "components/Vectors/EditIcon2";
import DeleteIcon2 from "components/Vectors/DeleteIcon2";

function EachBanner({item, onPreview, onEdit}) {
  const [deleteBanner, {isLoading}] = useDelBannerMutation();

  const onDeleted = async () => {
    try {
      await deleteBanner(item?.id).unwrap();
      showToast("Banner deleted");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div
      className="relative flex aspect-square h-[350px] w-full flex-col items-center justify-between rounded-25 bg-cover bg-center bg-no-repeat px-[23px] pt-4 pb-[23px]"
      style={{
        backgroundColor: "linear-gradient(to left, #e4e7ec, #e4e7ec)",
      }}
    >
      <img
        src={item?.banner || ""}
        alt=""
        className="absolute inset-0 h-full w-full rounded-25 object-cover"
      />

      <div className="absolute bottom-4 w-[90%] rounded-25 bg-white p-3.5 md:p-5">
        <div className="mt-2 flex items-center justify-between text-primary">
          {/* <Button
            startIcon={<VisibilityOutlinedIcon />}
            className="text-sm md:text-base"
            onClick={onPreview}
          >
            Preview
          </Button> */}

          <div>
            {" "}
            <p className="text-[10px] font-medium text-grey_30">
              Changing Word
            </p>
            <p className="max-w-full truncate font-Bai text-sm font-semibold text-ltext">
              {item?.title}
            </p>
          </div>

          <div className="flex h-[40px] gap-2">
            <Button
              className="w-[40px] !min-w-[unset]"
              sx={{background: "#FDF2E6"}}
              onClick={onEdit}
            >
              <EditIcon2 stroke="#E98100" />
            </Button>
            <LoadingButton
              color="error"
              sx={{background: "#FFF1F0"}}
              loading={isLoading}
              onClick={onDeleted}
              className="w-[40px] !min-w-[unset]"
            >
              <DeleteIcon2 color="#D92D20" />
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EachBanner;
