import {useMemo} from "react";
import {selectOnboarding} from "redux/slice/AuthSlice";
import {useSelector} from "react-redux";
import {filterObj} from "utils";
import {useGetOnboardingStatusQuery} from "services/user/userRegApi";
import {useGetUser} from "./getUserHook";

export const useGetOnboardingStatus = (
  type = "client",
  includeBank = true,
  skip = false,
) => {
  const {isLoggedIn} = useGetUser();
  const {data, refetch} = useGetOnboardingStatusQuery("", {
    skip: skip || !isLoggedIn,
  });
  const reduxStatus = useSelector(selectOnboarding);

  const status = filterObj(
    data?.data || reduxStatus || {},
    ([key]) =>
      key !== "Services" &&
      (type !== "client" ? key !== "BankAccount" : key) &&
      (type === "client" ? key !== "ProfessionalInformation" : key),
  );

  const isOnboardingComplete = Object.values(status)?.every(value => value);

  return useMemo(
    () => ({status, isOnboardingComplete, refetch}),
    [isOnboardingComplete, status, refetch],
  );
};
