import { Outlet } from "react-router-dom";
import "./style.scss";
import { Badge, IconButton } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Footer, Nav } from "../components";
import { useGetUser } from "hooks/getUserHook";
import ClientAndProfNavbar from "components/ClientAndProfNavbar";
import { ClientDashboardLinks, ProfDashboardLinks } from "utils/constants";
import ScrollToTopButton from "components/ScrollToTopButton";

export const NotificationLinkIcon = ({ baseUrl = "admin" }) => (
  <Badge color="error" variant="dot" overlap="circular" badgeContent={3}>
    <IconButton sx={{ backgroundColor: "#ffffff19" }}>
      <NotificationsNoneOutlinedIcon sx={{ color: "#fff" }} />
    </IconButton>
  </Badge>
);

const LandingDashboardLayout = ({
  showFooter = true,
  children,
  className = "",
}) => {
  const { isLoggedIn, user } = useGetUser();
  const isAdmin = user?.admin_permissions;
  return (
    <div className={className}>
      <div className="pb-10">
        {isLoggedIn ? (
          isAdmin ? (
            <Nav />
          ) : (
            <ClientAndProfNavbar
              padx
              links={
                user?.user_type === "Client"
                  ? ClientDashboardLinks
                  : ProfDashboardLinks
              }
              isProfessional={user?.user_type === "Client" ? false : true}
            />
          )
        ) : (
          <Nav />
        )}
      </div>
      <ScrollToTopButton />
      <main className="mt-28">{children || <Outlet />}</main>
      {showFooter && (
        <Footer
          isUser={isLoggedIn && user?.user_type}
          isProfessional={user?.user_type === "Service provider"}
        />
      )}
    </div>
  );
};

export default LandingDashboardLayout;
