import React from 'react'

const CategoryIcon = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="22" height="22" rx="4" fill="#F8D8B0" />
            <rect x="28" width="22" height="22" rx="4" fill="#E98100" />
            <rect y="28" width="22" height="22" rx="4" fill="#E98100" />
            <rect x="28" y="28" width="22" height="22" rx="4" fill="#E98100" />
        </svg>
    )
}

export default CategoryIcon