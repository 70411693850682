import React, {useState} from "react";
import {Chip, Button} from "@mui/material";
import DropDownWrapper from "components/mui-components/DropDownWrapper";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddIcon from "@mui/icons-material/Add";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import DeleteIcon2 from "components/Vectors/DeleteIcon2";
import MessageModal2 from "components/mui-components/Modal/MessageModal2";
import AddCityModal from "../Modals/AddCityModal";
import moment from "moment/moment";
import {
  useDeleteStateMutation,
  useUpdateStateAvailabilityMutation,
} from "services/admin/adminGeneralApi";
import EditStateModal from "../Modals/EditStateModal";
import {useEffect} from "react";

function StateCard({data, onCardClick}) {
  const [openDisableModal, setopenDisableModal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [openAddNewCityModal, setopenAddNewCityModal] = useState(false);
  const [openEditStateModal, setOpenEditStateModal] = useState(false);

  const [deleteState, {isLoading: isDeleteLoading}] = useDeleteStateMutation();
  const [updateAvailability, {isLoading: isUpdateLoading}] =
    useUpdateStateAvailabilityMutation();

  const [availabilityState, setAvailabilityState] = useState(
    data?.availability,
  );

  useEffect(() => {
    setAvailabilityState(data?.availability);
  }, [data?.availability]);

  return (
    <div
      onClick={onCardClick}
      className="cursor-pointer rounded-2xl border border-grey_70 bg-grey_90 p-4 hover:bg-grey_70"
    >
      <div className="flex justify-between">
        <div className="flex items-center gap-3">
          <h3 className="font-Bai text-2xl font-semibold text-grey_20">
            {data.name}
          </h3>
          <Chip
            sx={{borderRadius: "6px"}}
            color={
              availabilityState.toLowerCase() === "physical availability"
                ? "secondaryInverse"
                : "greyBorder"
            }
            label={data?.availability}
          />
        </div>

        <DropDownWrapper
          contentPadding="p-[0]"
          position="right"
          border="border border-[#F2F4F7] "
          action={
            <div className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-white">
              <MoreHorizRoundedIcon
                sx={{
                  fontSize: "16px",
                  color: "#e98100",
                }}
              />
            </div>
          }
        >
          <div>
            <Button
              variant="text"
              sx={{
                color: "#6E726E",
              }}
              startIcon={
                <EditRoundedIcon sx={{fontSize: "14px", color: "#6E726E"}} />
              }
              onClick={() => setOpenEditStateModal(true)}
            >
              Edit State
            </Button>
            <Button
              variant="text"
              startIcon={<AddIcon sx={{fontSize: "16px", color: "#e98100"}} />}
              onClick={() => setopenAddNewCityModal(true)}
            >
              Add City/Town
            </Button>
            {availabilityState === "Physical Availability" ? (
              <Button
                variant="text"
                sx={{
                  color: "#6E726E",
                }}
                startIcon={
                  <NotInterestedIcon
                    sx={{fontSize: "16px", color: "#6E726E"}}
                  />
                }
                onClick={() => setopenDisableModal(true)}
              >
                Disable Physical Availability
              </Button>
            ) : (
              <Button
                variant="text"
                sx={{
                  color: "#039855",
                }}
                startIcon={
                  <NotInterestedIcon
                    sx={{fontSize: "16px", color: "#039855"}}
                  />
                }
                onClick={() =>
                  updateAvailability({
                    id: data.id,
                    availability: "Physical Availability",
                  })
                }
              >
                Enable Physical Availability
              </Button>
            )}
            <Button
              variant="text"
              sx={{
                color: "#D92D20",
              }}
              startIcon={
                <DeleteIcon2 sx={{fontSize: "16px", color: "#D92D20"}} />
              }
              onClick={() => setopenDeleteModal(true)}
            >
              Delete State
            </Button>
          </div>
        </DropDownWrapper>
      </div>

      <div className="mt-8 flex flex-wrap items-center justify-between">
        <div className="flex flex-1 items-center gap-2">
          <p className="flex gap-1 text-base font-bold text-grey">
            {data?.cities.length}{" "}
            <span className="font-medium text-grey_30"> Cities/Town</span>
          </p>

          <Chip
            sx={{borderRadius: "6px"}}
            label={data.status}
            color={
              data.status.toLowerCase() === "active"
                ? "successInverse"
                : "greyBorder"
            }
          />
        </div>
        <p className="text-sm font-medium text-grey_30">
          Last Modified: {moment(data.updated_at).format("Do MMMM, YYYY")}
        </p>
      </div>

      <MessageModal2
        openModal={openDisableModal}
        closeModal={() => setopenDisableModal(false)}
        otherState={() =>
          updateAvailability({id: data.id, availability: "Virtual Only"})
        }
        isLoading={isUpdateLoading}
        title={`Are you sure you want to Disable ${data.name} state?`}
        actionBtnText="Disable"
      />

      <MessageModal2
        openModal={openDeleteModal}
        closeModal={() => setopenDeleteModal(false)}
        otherState={() => deleteState(data.id)}
        title={`Are you sure you want to Delete ${data.name} state?`}
        actionBtnText="Delete"
        isLoading={isDeleteLoading}
      />

      <AddCityModal
        stateID={data.id}
        openModal={openAddNewCityModal}
        setopenModal={setopenAddNewCityModal}
      />

      <EditStateModal
        stateID={data?.id}
        stateName={data.name}
        stateAvailability={data?.availability}
        openModal={openEditStateModal}
        setopenModal={setOpenEditStateModal}
      />
    </div>
  );
}

export default StateCard;
