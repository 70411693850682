import React from "react";

const FourStar = ({ fill, stroke }) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4997 0.458008L8.62467 8.12467L0.958008 10.9997L8.62467 13.8747L11.4997 21.5413L14.3747 13.8747L22.0413 10.9997L14.3747 8.12467L11.4997 0.458008Z"
        fill={fill || "#E98100"}
        stroke={stroke || ""}
      />
    </svg>
  );
};

export default FourStar;
