import { Button } from "@mui/material";
import EmptyTicket from "./EmptyTicket";
import TicketCards from "./TicketCards";
import { useSelector } from "react-redux";
import { selectAllTickets, selectTotalTicketsCount } from "redux/slice/ApiSlice";

const TicketLists = ({ tickets, action }) => {
  const totalTicketsCount = useSelector(selectTotalTicketsCount);
  const allTickets = useSelector(selectAllTickets);

  if (tickets.length === 0) return <EmptyTicket />;
  return (
    <div className="mt-4 flex flex-col gap-6">
      {
        tickets.map((ticket) => (
          <TicketCards ticket={ticket} key={ticket.id} />
        ))
      }
      {totalTicketsCount - 1 >= allTickets.length && (
          <div className="w-fit self-center">
            <Button
              sx={{
                borderWidth: "1px",
                ml: { xs: "0", md: "1.5rem" },
                mt: { xs: "1rem", md: 0 },
                order: { xs: 2, md: 0 },
              }}
              onClick={action}
              variant="outlined"
            >
              Load More
            </Button>
          </div>
        )
      }
    </div>
  );
};
export default TicketLists;
