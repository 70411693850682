import BackBtn from 'components/BackBtn'
import NewEngagementCard from 'components/NewEngagementCard'
import React from 'react'

const AssignedJobs = () => {
    return (
        <div className='bg-grey-100 -mx-4 md:-mx-6 lg:-mx-[4vw] -my-4 lg:-my-9 px-4 md:px-6 lg:px-[4vw] py-4 lg:py-9'>
            <BackBtn />

            <p className="font-Bai mt-10 font-semibold text-2xl md:text-[2rem] text-grey-10 mb-9">
                Assigned Jobs
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {Array(9).fill("").map((_, i) =>
                    <NewEngagementCard key={i} />
                )}
            </div>
        </div>
    )
}

export default AssignedJobs