const CalendarStrokeIcon2 = props => {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 9.5H1M14 1.5V5.5M6 1.5V5.5M5.8 21.5H14.2C15.8802 21.5 16.7202 21.5 17.362 21.173C17.9265 20.8854 18.3854 20.4265 18.673 19.862C19 19.2202 19 18.3802 19 16.7V8.3C19 6.61984 19 5.77976 18.673 5.13803C18.3854 4.57354 17.9265 4.1146 17.362 3.82698C16.7202 3.5 15.8802 3.5 14.2 3.5H5.8C4.11984 3.5 3.27976 3.5 2.63803 3.82698C2.07354 4.1146 1.6146 4.57354 1.32698 5.13803C1 5.77976 1 6.61984 1 8.3V16.7C1 18.3802 1 19.2202 1.32698 19.862C1.6146 20.4265 2.07354 20.8854 2.63803 21.173C3.27976 21.5 4.11984 21.5 5.8 21.5Z"
        stroke={props.color || "black"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CalendarStrokeIcon2;
