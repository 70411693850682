import React from 'react'

function PlusStarIcon({ color }) {
    return (
        <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0L11.3015 5.20599C11.5555 6.22185 11.6824 6.72978 11.9469 7.14309C12.1808 7.50868 12.4913 7.81915 12.8569 8.05308C13.2702 8.31756 13.7782 8.44454 14.794 8.6985L20 10L14.794 11.3015C13.7782 11.5555 13.2702 11.6824 12.8569 11.9469C12.4913 12.1808 12.1808 12.4913 11.9469 12.8569C11.6824 13.2702 11.5555 13.7782 11.3015 14.794L10 20L8.6985 14.794C8.44454 13.7782 8.31756 13.2702 8.05309 12.8569C7.81915 12.4913 7.50868 12.1808 7.14309 11.9469C6.72978 11.6824 6.22185 11.5555 5.20599 11.3015L0 10L5.20599 8.6985C6.22185 8.44454 6.72978 8.31756 7.14309 8.05308C7.50868 7.81915 7.81915 7.50868 8.05308 7.14309C8.31756 6.72978 8.44454 6.22185 8.6985 5.20599L10 0Z" fill={color || "white"} />
                <path d="M10 0L11.3015 5.20599C11.5555 6.22185 11.6824 6.72978 11.9469 7.14309C12.1808 7.50868 12.4913 7.81915 12.8569 8.05308C13.2702 8.31756 13.7782 8.44454 14.794 8.6985L20 10L14.794 11.3015C13.7782 11.5555 13.2702 11.6824 12.8569 11.9469C12.4913 12.1808 12.1808 12.4913 11.9469 12.8569C11.6824 13.2702 11.5555 13.7782 11.3015 14.794L10 20L8.6985 14.794C8.44454 13.7782 8.31756 13.2702 8.05309 12.8569C7.81915 12.4913 7.50868 12.1808 7.14309 11.9469C6.72978 11.6824 6.22185 11.5555 5.20599 11.3015L0 10L5.20599 8.6985C6.22185 8.44454 6.72978 8.31756 7.14309 8.05308C7.50868 7.81915 7.81915 7.50868 8.05308 7.14309C8.31756 6.72978 8.44454 6.22185 8.6985 5.20599L10 0Z" fill={color || "white"} />
            </svg>

        </div>
    )
}

export default PlusStarIcon