import {useState} from "react";
import SearchInput from "components/forms/SearchInput";
import ServiceCard from "./widgets/ServiceCard";
import TablePagination from "components/mui-components/TablePagination";
import {useGetAllServiceCategoriesQuery} from "services/generalApi";
import CircularLoader from "components/Loader/CircularLoader";
import EmptyResponse from "components/mui-components/EmptyResponse";
import ServicesIcon from "components/Vectors/ServicesIcon";

function ClientServiceProviders() {
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(15);

  const {data, isLoading} = useGetAllServiceCategoriesQuery({
    is_paginated: "yes",
    page,
    per_page: rowPerPage,
  });
  const [filterChoice, setFilterChoice] = useState("");
  const categories = data?.data;
  const allCt = categories?.data?.filter(el =>
    filterChoice
      ? el?.name.toLowerCase().indexOf(filterChoice.toLowerCase()) >= 0
      : el,
  );

  if (isLoading) return <CircularLoader />;
  return (
    <div>
      <h1 className="font-Bai text-4xl font-semibold text-grey_20">
        Discover Services
      </h1>
      <p className="mb-6 max-w-[444px] text-lg font-medium text-grey_30">
        Explore and find different service providers that offer different unique
        services.
      </p>
      <div className="relative rounded-2xl bg-white p-4 md:p-6">
        <SearchInput
          value={filterChoice}
          onChange={e => setFilterChoice(e.target.value)}
          maxWidth="max-w-[360px]"
          placeholder="Search service providers, service"
          bg="#F9FAFB"
        />
        {allCt?.length > 0 ? (
          <>
            <div className="mt-8 grid grid-cols-1 gap-5 sm:grid-cols-2 md:gap-[60px] lg:grid-cols-3">
              {allCt.map(data => (
                <ServiceCard data={data} />
              ))}
            </div>
            <div className="mt-6">
              <TablePagination
                meta={{
                  totalCount: categories.total,
                  currentPage: categories?.current_page,
                  totalPages: categories?.last_page,
                }}
                from={categories?.from}
                to={categories?.to}
                page={page}
                setPage={setPage}
                perPage={rowPerPage}
                setPerPage={setRowPerPage}
              />
            </div>
          </>
        ) : (
          <EmptyResponse
            icon={<ServicesIcon color="#3b3d3b" />}
            message="No Service Found"
          />
        )}
      </div>
    </div>
  );
}

export default ClientServiceProviders;
