import { Chip } from "@mui/material";
import CategoryInfo from "components/CategoryCard";
import DescriptionComp from "components/DescriptionComp";
import ImageSlideShow from "components/mui-components/Modal/ImageSlideshow";
import Video from "components/mui-components/Modal/Video";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ClockIcon from "components/Vectors/ClockIcon";
import GridIcon from "components/Vectors/GridIcon";
import MarkerPinIcon from "components/Vectors/MarkerPinIcon";
import moment from "moment";
import { moneyFormatter } from "utils";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import MessageChatIcon from "components/Vectors/MessageChatIcon";
import AlertIcon from "components/Vectors/AlertIcon";

const JobOverview = ({ data }) => {
  const status = data?.status?.toLowerCase();
  return (
    <>
      <div className="rounded-24 bg-[#F2F4F7] p-1">
        <div className="rounded-24 bg-grey_80 p-4 md:p-8">
          <h1 className="text-prop font-Bai text-[24px] font-semibold text-primary">
            Overview
          </h1>

          {/* budget  */}
          <div className="mt-8 flex justify-between">
            <div>
              <h3 className="text-prop font-Bai text-[24px] font-semibold text-grey_30">
                Budget
              </h3>
              <span className="settings-font-h2 !text-[32px] ">
                {!data?.price
                  ? "No Budget"
                  : `₦${data?.price.toLocaleString()}`}
              </span>
            </div>
          </div>

          {status?.includes("suspend") && data?.reason_for_suspension && (
            <div className="mt-6 rounded-[18px] bg-[#F6E5E5] bg-opacity-70  p-5">
              <div className="flex flex-wrap items-center justify-between gap-3">
                <p className={`text-sm font-bold text-grey_10`}>
                  Reason for suspension
                </p>
                <p className="text-xs text-grey_30">
                  Suspended on{" "}
                  <span className="text-black">
                    {moment(data?.suspended_at).format("DD MMM YYYY")}
                  </span>
                </p>
              </div>
              <p className="my-2 text-sm text-grey_30">
                {data?.reason_for_suspension}
              </p>
            </div>
          )}

          {/* description  */}
          <div className="mt-6">
            <DescriptionComp description={data?.description} />
          </div>
        </div>
      </div>

      <div className="my4 rounded-[14px] p-4 md:p-6 bg-[#FF1D0D] bg-opacity-[0.06]">
        <span className="flex gap-3 items-center">
          <AlertIcon />
          <p className="font-Mulish font-medium text-base md:text-lg text-[#B42318]">Notice</p>
        </span>
        <p className="mt-3 font-Mulish font-medium text-sm md:text-base text-[#271A19]">
          Please ensure all engagements and transactions are conducted within the system for guaranteed security, transparency, and a full refund in case your expectations are not met.
        </p>
      </div>

      {/* category, location...  */}
      <div className="my8 grid grid-cols-2 gap-4 md:gap-8">
        <CategoryInfo
          title="Date Posted"
          content={moment(data?.created_at).format("DD MMM YYYY")}
          icon={<CalendarTodayRoundedIcon />}
        />
        <CategoryInfo
          title="Category"
          content={data?.category?.name}
          icon={<GridIcon />}
          info={data?.expertise?.name}
        />

        <CategoryInfo
          title="Views"
          content={moneyFormatter(data?.views_count)}
          icon={
            <VisibilityOutlinedIcon fontSize="small" sx={{ color: "#3B3D3B" }} />
          }
        />
        <CategoryInfo
          title="No. of Offers"
          content={moneyFormatter(data?.offers_count)}
          icon={<MessageChatIcon />}
        />
        <CategoryInfo
          title="Expected Timeline"
          content={`${data?.duration} ${data?.duration === 1
            ? data?.duration_type.slice(0, -1)
            : data?.duration_type
            }`}
          icon={<ClockIcon />}
        />
        <CategoryInfo
          title="Location"
          content={
            data?.location_type === "Physical"
              // ? `${data?.city?.name}, ${data?.state?.name}`
              ? "Physical"
              : "Virtual"
          }
          icon={<MarkerPinIcon />}
          info={data?.location_type === "Physical" && `${data?.state?.name} / ${data?.address}`}
        />
      </div>
      {/* Features */}
      {data?.task_features?.length > 0 && (
        <div>
          <h3 className="text-prop font-Bai text-[24px] font-semibold text-primary capitalize">
            Job features
          </h3>
          <div className="flex flex-wrap items-center gap-2">
            {data?.task_features.map((feature, i) => (
              <Chip
                key={`${feature.task_feature}_${i}`}
                label={feature.task_feature}
                sx={{ fontSize: "16px" }}
              />
            ))}
          </div>
        </div>
      )}
      {/* Media */}
      <div className="rounded-24 bg-grey_80 p-4 md:p-8">
        <h2 className="text-prop font-Bai text-[24px] font-semibold text-primary">
          Media
        </h2>
        <p className="my-4 font-Bai text-lg font-semibold text-black">Images</p>
        <ImageSlideShow images={data?.images} />
        {data?.videos.length > 0 && (
          <>
            <div className="mt-6">
              <p className="font-Bai text-lg font-semibold text-black">
                Videos
              </p>
              <div className="mt-4 grid grid-cols-1 gap-4">
                {data?.videos.map(el => (
                  <Video
                    type={el?.video_type}
                    videoUrl={el?.video_url}
                    key={el?.video_url}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default JobOverview;
