import {useState} from "react";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import Modal from "components/mui-components/Modal";
import MessageModal from "components/mui-components/Modal/MessageModal";
import CheckIcon2 from "components/Vectors/CheckIcon2";
import {FormProvider, useForm} from "react-hook-form";
import {useSuspendUserMutation} from "services/admin/adminGeneralApi";
import {handleError} from "utils";
import LoadingBtn from "components/mui-components/LoadingButton";

function SuspendAccountModal({openModal, setopenModal, otherState, user}) {
  const methods = useForm();
  const [openSuspendAcctModal, setOpenSuspendAcctModal] = useState(false);
  const [suspendUser, {isLoading}] = useSuspendUserMutation();

  const handleClick = async body => {
    try {
      await suspendUser({
        id: user?.id,
        type:
          user?.status.toLowerCase() === "suspended" ? "unsuspend" : "suspend",
        body,
      }).unwrap();
      if (otherState) {
        otherState();
      }
      methods.reset();
      setopenModal(false);
      setOpenSuspendAcctModal(true);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Modal
        title="Suspend Account"
        openModal={openModal}
        closeModal={() => setopenModal(false)}
        maxWidth={550}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleClick)} className="w-full">
            <ValidatedTextArea
              name="message"
              bg="grey_70"
              label="Send Message"
              height="h-[120px]"
              placeholder="Type something"
            />
            <LoadingBtn
              loading={isLoading}
              fullWidth
              color="error"
              type="submit"
              sx={{mt: 8}}
            >
              Suspend Account
            </LoadingBtn>
          </form>
        </FormProvider>
      </Modal>

      <MessageModal
        openModal={openSuspendAcctModal}
        icon={<CheckIcon2 />}
        closeModal={() => setOpenSuspendAcctModal(false)}
        iconBg={"bg-[#FDF2E6]"}
        title={"Account Suspended"}
        buttonTitle="Close"
      />
    </>
  );
}

export default SuspendAccountModal;
