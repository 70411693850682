import MoneyAddIcon from "./Vectors/MoneyAddIcon";

const EmptyHistory = ({title, subtitle, icon}) => {
  return (
    <div className="flex flex-col items-center justify-center bg-white py-20 md:py-28">
      {icon ? icon : <MoneyAddIcon width={64} height={64} color="#3B3D3B" />}
      <p className="mt-4 mb-1 font-Bai text-2xl font-semibold text-grey_20 md:text-3xl">
        {title}
      </p>
      {typeof subtitle === "string" ? (
        <p className="max-w-fit text-center font-medium text-grey_30 md:text-left">
          {subtitle}
        </p>
      ) : (
        subtitle
      )}
    </div>
  );
};

export default EmptyHistory;
