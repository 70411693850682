import {LoadingButton} from "@mui/lab";
import {Button, CircularProgress} from "@mui/material";
import MyModal from "components/mui-components/Modal";
import {useNavigate} from "react-router-dom";
import {showToast} from "redux/store.hooks";
import {handleError} from "utils";

const ActionModal = ({openModal, close, action, isLoading, onConfirm}) => {
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      let res = await onConfirm();
      showToast(res?.message, "success");
      navigate("/admin/administrators");
      close();
    } catch (error) {
      handleError(error);
      close();
    }
  };

  return (
    <MyModal openModal={openModal} closeModal={close} maxWidth={500}>
      <section>
        <div className="my-[80px] flex h-full flex-col items-center justify-center">
          <p className="text-center font-Bai text-3xl font-semibold">{`Are you sure you want to ${action} this admin?`}</p>
        </div>
        <div className="flex items-center gap-x-4">
          <Button
            variant="contained"
            color="primaryInverse2"
            fullWidth
            onClick={close}
          >
            Never mind
          </Button>

          <LoadingButton
            loadingIndicator={
              <CircularProgress
                sx={{
                  color: "#fff",
                }}
                size="1.2rem"
              />
            }
            loading={isLoading}
            fullWidth
            variant="contained"
            color="error"
            onClick={handleClick}
          >
            {action}
          </LoadingButton>
        </div>
      </section>
    </MyModal>
  );
};

export default ActionModal;
