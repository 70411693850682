import {useEffect, useState} from "react";
import SearchInput from "components/forms/SearchInput";
import {useGetAllJobsQuery} from "services/user/providerGeneralApi";
import Physical from "pages/LandingPage/widgets/Dropdowns/Physical";
import StateCity from "pages/LandingPage/widgets/Dropdowns/StateCity";
import Category from "pages/LandingPage/widgets/Dropdowns/Category";
import {useSearchParams} from "react-router-dom";
import {debounce, removeEmpty} from "utils";
import TablePagination from "components/mui-components/TablePagination";
import JobsContent from "./JobsContent";
import {Button} from "@mui/material";
import {DisplayData} from "pages/Clients/Dashboard/Main";
import Pusher from "pusher-js";

function NewestJobs() {
  const [filterChoice, setFilterChoice] = useState("");
  const [rowPerPage, setRowPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const [isParamsChanged, setisParamsChanged] = useState(false);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("q"))
      setChoice(prev => ({...prev, query: searchParams.get("q")}));
  }, [searchParams]);

  const initialFilter = {
    type: "",
    category: "",
    state: "",
    query: "",
  };
  const [choice, setChoice] = useState(initialFilter);

  function handleInput(e) {
    setChoice(prev => ({...prev, query: e.target.value}));
  }
  const debouncedHandleInput = debounce(handleInput, 1000);

  const queryParams = {
    location_type: choice?.type,
    order: choice?.order,
    state: choice?.state,
    category: choice?.category,
    query: choice?.query,
    page,
    per_page: rowPerPage,
    is_paginated: "yes",
  };

  const {
    data: allJobs,
    isLoading,
    error,refetch,
    isFetching: isJobFetching,
  } = useGetAllJobsQuery(removeEmpty(queryParams), {
    refetchOnMountOrArgChange: true,
  });
  const jobs = allJobs?.data;
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("new-job-created-channel");
    channel.bind("new-job-created-event", () => {
      refetch();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [refetch]);
  useEffect(() => {
    if (choice) setisParamsChanged(isJobFetching);
  }, [choice, isJobFetching]);

  return (
    <>
      <section className="my-6 flex flex-wrap items-center gap-3 gap-y-4">
        <SearchInput
          placeholder="Search Jobs"
          value={choice.query}
          onChange={debouncedHandleInput}
          maxWidth="max-w-[200px]"
        />
        <Physical
          gap={"gap-3"}
          filterChoice={filterChoice}
          setFilterChoice={setFilterChoice}
          choice={choice}
          setChoice={setChoice}
        />
        <StateCity
          filterChoice={filterChoice}
          setFilterChoice={setFilterChoice}
          choice={choice}
          setChoice={setChoice}
        />
        <Category
          filterChoice={filterChoice}
          setFilterChoice={setFilterChoice}
          choice={choice}
          setChoice={setChoice}
        />
        <Button variant="contained" onClick={() => setChoice(initialFilter)}>
          Clear Filter
        </Button>
      </section>

      <DisplayData
        isLoading={isLoading || isParamsChanged}
        isError={error}
        error={error}
      >
        <>
          <JobsContent data={jobs} setShouldReload={setisParamsChanged} />
          {!isJobFetching && (
            <div className="mt-4">
              {jobs?.data?.length > 0 && (
                <TablePagination
                  meta={{
                    totalPages: jobs?.last_page,
                    totalCount: jobs?.total,
                    currentPage: jobs?.current_page,
                  }}
                  from={jobs?.from}
                  to={jobs?.to}
                  page={page}
                  setPage={setPage}
                  perPage={rowPerPage}
                  setPerPage={setRowPerPage}
                />
              )}
            </div>
          )}
        </>
      </DisplayData>
    </>
  );
}

export default NewestJobs;
