import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import EmailLayout from "./widgets/EmailLayout";
import EmailTitle from "./widgets/EmailTitle";
import {Button, Chip} from "@mui/material";
import {toCurrency} from "utils";
import StarFilledIcon from "components/Vectors/StarFilledIcon";
import moment from "moment";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

const ReceivedOffer = () => {
  const service_provider = {
    professional_information: {
      business_logo: "/images/mirian2.png",
      business_name: "Mirian Achonwa",
    },
    username: "Mirian",
    is_top_vetted: true,
    is_verified_account: true,
    rating: "4.0",
  };
  const handleViewOffer = () => {
    console.log("clicked");
  };
  return (
    <EmailLayout>
      <EmailTitle title="You received an offer" />
      <div className="mx-auto mt-12 flex w-11/12 items-center justify-between rounded-20 border border-grey_60 p-6">
        <UserDisplay
          className="!items-start"
          name={
            <span className="flex items-center gap-1">
              <span className="min-w-max">
                {service_provider?.professional_information?.business_name}
              </span>
              {service_provider?.is_top_vetted && (
                <Chip
                  avatar={<StarRoundedIcon />}
                  color="secondaryInverse"
                  label="Top Vetted"
                  component="span"
                  sx={{
                    fontSize: "10px",
                    border: "1px solid #5010B3",
                    "& .MuiChip-avatar": {
                      color: "#5010B3",
                      fontSize: "11px",
                    },
                  }}
                  size="small"
                />
              )}
            </span>
          }
          img={service_provider?.professional_information?.business_logo}
          badge={service_provider?.is_verified_account}
          email={`@${service_provider?.username}`}
          size="medium"
        />
        <span className="flex flex-col">
          <span className="flex items-center text-primary">
            <StarFilledIcon color="#E98100" />
            <span>{service_provider?.rating}</span>
          </span>
          <span className="text-sm text-grey_30">
            {moment("2023-07-16").fromNow()}
          </span>
        </span>
      </div>
      <div className="mx-auto mb-10 mt-6 w-11/12">
        <div className="flex items-center justify-between font-Bai font-bold text-grey_20">
          <span>Offer Message</span>
          <span>{toCurrency(45000)}</span>
        </div>

        <p className="mt-1 text-grey_30">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
          porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis
          imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.
        </p>
      </div>
      <Button
        onClick={handleViewOffer}
        variant="contained"
        sx={{
          display: "block",
          width: "218px",
          height: "60px",
          mb: 10,
          mx: "auto",
        }}
      >
        View Offer
      </Button>
    </EmailLayout>
  );
};

export default ReceivedOffer;
