import { Button } from "@mui/material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import EmptyResponse from "components/mui-components/EmptyResponse";
import EngagementIcon from "components/Vectors/EngagementIcon";
import ActiveEngagementCard from "../widgets/ActiveEngagementCard";
import { useGetEngagementByStatusQuery } from "services/admin/adminGeneralApi";
import ErrorMsg from "components/mui-components/ErrorMsg";
import CircularLoader from "components/Loader/CircularLoader";
import { Link } from "react-router-dom";

const ActiveEngagements = () => {
  const { data, isError, isLoading, error } = useGetEngagementByStatusQuery({
    status: "Ongoing",
    params: { is_paginated: "yes", per_page: 10 }
  }
  );
  const engagements = data?.data;

  return (
    <>
      <div className="">
        <div className="heading mb-6 flex items-center justify-between">
          <h3 className="font-Bai text-base font-medium text-grey_10 sm:text-2xl">
            Ongoing Engagements
          </h3>
          <Button
            variant="text"
            component={Link}
            to="/admin/engagements"
            sx={{ p: "0px !important" }}
            endIcon={<ArrowForwardOutlinedIcon color="primary" />}
          >
            View all{" "}
          </Button>
        </div>

        <div className="noScroll flex max-h-[569px] flex-col gap-4 overflow-y-auto rounded-2xl bg-white p-4">
          {isLoading ?
            <CircularLoader />
            : isError ?
              <ErrorMsg error={error} />
              : engagements?.length === 0
                ? <EmptyResponse
                  icon={
                    <EngagementIcon
                      width={"62px"}
                      height="62px"
                      stroke={"#3B3D3B"}
                    />
                  }
                  message={"No Active Engagements"}
                /> : engagements?.map(engagement => (
                  <ActiveEngagementCard
                    engagement={engagement}
                    key={`engagement_${engagement?.id}`}
                  />
                ))
          }
        </div>
      </div>
    </>
  );
};

export default ActiveEngagements;
