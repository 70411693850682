import {Button, Chip} from "@mui/material";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import MoneyAddIcon from "components/Vectors/MoneyAddIcon";
import {toCurrency} from "utils";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useState} from "react";
import {useGetClientRefundHistoryQuery} from "services/user/clientGeneralApi";
import moment from "moment";
import {JobsStatusColor} from "utils/constants";
import MyModal from "components/mui-components/Modal";
import {Link} from "react-router-dom";
import {ReactComponent as UbaLogo} from "assets/Uba.svg";
import {useGetAllBanksQuery} from "services/generalApi";

const ChipStatus = {
  completed: "success",
  pending_refund: "warning",
  refunded: "secondary",
};

const headCells = [
  {
    name: "engagement",
    label: "Engagement",
    options: {
      customBodyRender: value => (
        <UserDisplay imgRounded={false} name={value.name} img={value.avatar} />
      ),
    },
  },
  {
    name: "service_provider",
    label: "service provider",
    options: {
      customBodyRender: value => (
        <UserDisplay name={value.name} img={value.avatar} badge={value.badge} />
      ),
    },
  },
  {
    name: "transaction_ref",
    label: "Transaction ID",
  },
  {
    name: "amount",
    label: "Amount",
  },
  {
    name: "vat",
    label: "vat",
  },
  {
    name: "status",
    label: "Status",
    options: {
      customBodyRender: value => (
        <Chip label={value} color={ChipStatus[value]} />
      ),
    },
  },
  {
    name: "option",
    label: "Option",
  },
];

const RefundHistoryTab = () => {
  const [openDetails1Modal, setopenDetails1Modal] = useState(false);
  const [selectedDetails, setselectedDetails] = useState(null);

  const {data, isLoading, isError, error} = useGetClientRefundHistoryQuery();
  const {data: allBanks} = useGetAllBanksQuery();
  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
  };

  // const navigate = useNavigate();

  // mock data to be updated by api
  const mockData = data?.data?.map((el, i) => ({
    engagement: {
      name: el?.engagement?.job_detail?.job_title,
      avatar:
        el?.engagement?.job_detail?.images?.length > 0
          ? el?.engagement?.job_detail?.images[0]?.image_url
          : "/images/broken-image.png",
    },
    service_provider: {
      name: el?.engagement?.service_provider?.full_name,
      avatar: el?.engagement?.service_provider?.avatar,
      badge: el?.engagement?.service_provider?.is_verified_account,
    },
    transaction_ref: `#${el?.engagement?.transaction?.transaction_ref}`,
    amount: toCurrency(el?.engagement?.transaction?.amount),
    date: el?.date_paid
      ? moment(el?.date_paid).format("DD MMMM, YYYY h:mma")
      : "Payment pending",
    vat: toCurrency(selectedDetails?.engagement?.transaction?.vat || 0),
    status: el?.status === "pending" ? "Pending refund" : el?.status,
    option: (
      <>
        <Button
          onClick={() => {
            setopenDetails1Modal(true);
            setselectedDetails(el);
          }}
          sx={{p: "0.5rem !important"}}
          endIcon={<ArrowForwardIcon />}
        >
          VIew Details
        </Button>
      </>
    ),
    id: `row_${i}`,
  }));

  return (
    <div>
      <div className=" w-full ">
        <MyDataTable
          scrollX={false}
          emptyData={{
            icon: MoneyAddIcon,
            message: "No Refund History",
            extraText:
              "transactions transactions will be updated on this page.",
          }}
          data={mockData}
          headCells={headCells}
          options={options}
          hideChipDot
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      </div>

      <MyModal
        maxWidth={700}
        title={"Refund Details"}
        openModal={openDetails1Modal}
        closeModal={() => setopenDetails1Modal(false)}
      >
        <div className="text-sm md:text-base">
          <div className="mt-2 flex items-start gap-3 rounded-20 bg-[#F9FAFB] p-4">
            <img
              src={
                selectedDetails?.engagement?.job_detail?.images?.length > 0
                  ? selectedDetails?.engagement?.job_detail?.images[0]
                      ?.image_url
                  : "/images/broken-image.png"
              }
              alt="transaction"
              className="h-[80px] w-[80px] rounded-16 object-cover"
            />
            <div className="flex-grow">
              <div className="mb-2 flex items-center justify-between font-semibold">
                <p className="text-xl capitalize">
                  {selectedDetails?.engagement?.job_detail?.job_title}
                </p>
                <p className="font-Mulish italic text-grey_30">
                  {moment(selectedDetails?.engagement?.created_at).format(
                    "DD MMM YYYY",
                  )}
                </p>
              </div>
              <Button
                variant="contained"
                size="small"
                component={Link}
                to={`/client/engagements/${
                  selectedDetails?.engagement?.id || ""
                }`}
              >
                View Engagement
              </Button>
            </div>
          </div>
          <div className="my-6 flex flex-wrap justify-between gap-4 border">
            <div className="w-[48%] flex-grow rounded-20 border border-[#F2F4F7] p-4">
              <div className="flex items-center justify-between font-semibold">
                <p className="text-xl text-grey_30">Amount</p>
                <Chip
                  color={
                    JobsStatusColor[selectedDetails?.status?.toLowerCase()]
                  }
                  label={
                    selectedDetails?.status === "pending"
                      ? "Pending refund"
                      : selectedDetails?.status
                  }
                />
              </div>
              <p className="text-3xl font-semibold">
                {toCurrency(selectedDetails?.engagement?.transaction?.amount)}
              </p>
            </div>

            {selectedDetails?.status === "refunded" && (
              <div className="w-[48%] rounded-20 border border-[#F2F4F7] p-4">
                <p className="mb-3 font-Bai text-lg font-medium text-grey_30">
                  Account
                </p>

                <div className="flex items-center gap-3">
                  {/* <img src="/images/refundDetailsImage.png" alt="EngagementImage" className="" /> */}
                  <UbaLogo />

                  <div className="">
                    <p className="text-base font-medium text-grey">
                      {selectedDetails?.client?.bank_account?.account_name}
                    </p>
                    <p className="text-base font-medium text-grey_30">
                      {selectedDetails?.client?.bank_account?.account_number} -{" "}
                      {
                        allBanks?.data?.find(
                          bank =>
                            bank?.code ===
                            selectedDetails?.client?.bank_account.bank_code,
                        )?.name
                      }
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mb-2 grid grid-cols-3 rounded-20 border border-[#F2F4F7] p-4 font-semibold">
            <div className="flex-1 border-r">
              <p className="text-lg text-grey_30">VAT</p>
              <p className="text-xl">
                {toCurrency(selectedDetails?.engagement?.transaction?.vat || 0)}
              </p>
            </div>
            <div className="flex flex-1 justify-center">
              <div>
                <p className="text-lg text-grey_30">Transaction ID</p>
                <p className="text-xl">
                  #{selectedDetails?.engagement?.transaction?.transaction_ref}
                </p>
              </div>
            </div>
            {selectedDetails?.date_paid && (
              <div className="flex flex-1 justify-end border-l">
                <div>
                  <p className="text-lg text-grey_30">Date</p>
                  <p className="text-xl">
                    {moment(selectedDetails?.date_paid).format("DD MMM YYYY")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </MyModal>
    </div>
  );
};

export default RefundHistoryTab;
