import {
  Avatar,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import {useState, useRef} from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";
import SaveChangeBtn from "../Components/SaveChangeBtn";
// import {Country, State} from "country-state-city";
import AutoCompleteField from "components/forms/AutoCompleteField";
import {useUpdateProfessionalInfoMutation} from "services/user/userRegApi";
import {useGetUser} from "hooks/getUserHook";
import {
  useGetAllStatesQuery,
  useGetCityByStateQuery,
} from "services/generalApi";
import {useEffect} from "react";
import {setUserDetails} from "redux/slice/AuthSlice";
import {handleError} from "utils";
import {useDispatch} from "react-redux";
import {showToast} from "redux/store.hooks";

const ProfessionalTab = () => {
  const {user} = useGetUser();
  const dispatch = useDispatch();

  const profInfo = user?.professional_information;

  const [imagePreview, setImagePreview] = useState(profInfo?.business_logo);
  const [imageFile, setImageFile] = useState([]);

  const [updateInfo, {isLoading}] = useUpdateProfessionalInfoMutation();
  const {data: allStates, isLoading: isStatesLoading} = useGetAllStatesQuery({
    pollingInterval: 3000,
  });

  const imageRef = useRef();
  const methods = useForm();

  const {watch, setValue} = methods;

  const watchLogo = watch("logo");

  const watchCity = watch("city");
  const watchStates = watch("state");
  const watchBusinessName = watch("business_name_option");

  const stateCities = allStates?.data
    .find(state => state?.name?.toLowerCase() === watchStates?.toLowerCase())
    ?.cities.map(el => el?.name);

  const selectedStateID = allStates?.data.find(
    state => state?.name?.toLowerCase() === watchStates?.toLowerCase(),
  )?.id;

  const states = allStates?.data?.map(state => state.name);

  const {data: cityData} = useGetCityByStateQuery(selectedStateID);

  useEffect(() => {
    if (profInfo) {
      const FIELDS = [
        "about",
        "years_of_experience",
        "office_address",
        "business_name",
        "business_name_option",
      ];
      FIELDS.forEach(el => {
        if (el === 'office_address') {
          setValue(el, profInfo['residential_address'] || "");
        }else  setValue(el, profInfo[el] || "");
      });
      setValue("state", profInfo?.state?.name || "");
      setValue("city", profInfo?.city?.name || "");
      setValue("country", profInfo?.country || "");
    }
  }, [profInfo, setValue]);

  const cityID = cityData?.data.find(
    city => city?.name?.toLowerCase() === watchCity?.toLowerCase(),
  )?.id;

  const imageChange = e => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setImageFile(e.target.files);
    setImagePreview(imageUrl);
  };

  const watchLocation = watch("location");

  const onSubmit = async data => {
    const formData = new FormData();
    formData.append("about", data.about);
    formData.append("years_of_experience", data.years_of_experience);

    if (watchBusinessName?.toLowerCase() === "use my name") {
      formData.append("business_name_option", "Use my name");
    }
    if (watchBusinessName?.toLowerCase() !== "use my name") {
      formData.append("business_name_option", "Enter a business name");
      formData.append("business_name", data.business_name);
    }
    if (watchLogo && watchLogo.toLowerCase() !== "upload logo") {
      formData.append("business_logo_option", "Use Profile photo");
    }
    if (watchLogo && watchLogo.toLowerCase() === "upload logo") {
      formData.append("business_logo_option", "Upload logo");
      if (imageFile.length > 0) {
        formData.append("business_logo", imageFile[0]);
      }
    }

    if (
      watchLocation &&
      watchLocation.toLowerCase() !== "choose new location"
    ) {
      formData.append("location_option", "Use Personal Location");
    }

    if (
      watchLocation &&
      watchLocation.toLowerCase() === "choose new location"
    ) {
      formData.append("location_option", "Choose new location");
      formData.append("country", data.country);
      formData.append("state_id", selectedStateID);
      formData.append("city_id", cityID);
      formData.append("residential_address", data.office_address);
    }

    try {
      let res = await updateInfo(formData).unwrap();
      dispatch(
        setUserDetails({...user, professional_information: {...res?.data}}),
        showToast("Updated Successfully", "success"),
      );
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="w-full rounded-24 bg-[white] px-6 py-6 md:px-10 md:py-10 ">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex w-full flex-col"
        >
          <h2 className="settings-font-h2">Professional Info Settings</h2>

          <fieldset className="mt-8 pb-[50px] md:mt-[50px] md:min-w-[420px] md:self-center ">
            <legend className="mb-[45px] font-Bai text-xl font-medium text-grey_10">
              Edit your Professional Information
            </legend>
            <div className="mb-8 flex flex-col gap-[25px]">
              {/* Logo  */}
              <div className="flex flex-col">
                <label className="font-400 text-prop mb-[-2px] text-[14px] text-[#6E726E] ">
                  Logo
                </label>
                <FormControl
                  sx={{
                    "& .MuiFormGroup-root": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <Controller
                    name="logo"
                    row
                    defaultValue={profInfo?.business_logo_option}
                    render={({field}) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={"Use Profile photo"}
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 28,
                                },
                              }}
                            />
                          }
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: "Mulish",
                              color: "#3B3D3B",
                            },
                          }}
                          label="Use Profile photo"
                        />
                        <FormControlLabel
                          value={"Upload logo"}
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 28,
                                },
                              }}
                            />
                          }
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: "Mulish",
                              color: "#3B3D3B",
                            },
                          }}
                          label="Upload logo"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>

                {watchLogo && watchLogo.toLowerCase() === "upload logo" && (
                  <div className="flex cursor-pointer items-center gap-4 ">
                    <input
                      ref={imageRef}
                      hidden
                      className="opacity-0"
                      type="file"
                      name="profile_image"
                      accept="image/*"
                      onChange={e => imageChange(e)}
                    />
                    <Avatar
                      sx={{width: 120, height: 120}}
                      src={imagePreview}
                      onClick={() => imageRef.current.click()}
                    />
                    <button
                      onClick={() => imageRef.current.click()}
                      type="button"
                      className="text-prop relative rounded-[14px] bg-[#F2F4F7]
                      px-[24px] py-[18px] text-[15px] text-[#E98100]  "
                    >
                      Change Logo
                    </button>
                  </div>
                )}
              </div>

              {/* business name  */}
              <div className="flex flex-col">
                <label className="font-400 text-prop text-[14px] text-[#6E726E] ">
                  Business Name
                </label>

                <FormControl
                  sx={{
                    "& .MuiFormGroup-root": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <Controller
                    name="business_name_option"
                    row
                    defaultValue={profInfo?.business_name_option}
                    render={({field}) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={"Use my name"}
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 28,
                                },
                              }}
                            />
                          }
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: "Mulish",
                              color: "#3B3D3B",
                            },
                          }}
                          label="Use your name"
                        />
                        <FormControlLabel
                          value={"Enter a business name"}
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 28,
                                },
                              }}
                            />
                          }
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: "Mulish",
                              color: "#3B3D3B",
                            },
                          }}
                          label="Enter a business name"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>

                {watchBusinessName?.toLowerCase() ===
                  "enter a business name" && (
                  <div className="mt-1">
                    <ValidatedInput2
                      label="Business Name"
                      placeholder="Olobiriyipo and kids"
                      name="business_name"
                      rules={{required: "Business name is required"}}
                    />
                  </div>
                )}
              </div>

              {/* about  */}
              <ValidatedTextArea2
                name="about"
                maxLength="250"
                label="About"
                height="h-[130px]"
                placeholder="A brief overview of your business, company, expertise"
              />

              {/* years of experience  */}
              <ValidatedInput2
                type="number"
                label="Years of Experience"
                placeholder="12"
                name="years_of_experience"
                required={true}
              />

              {/* Location  */}
              <div className="flex flex-col">
                <label className="font-400 text-prop mb-[-2px] text-[14px] text-[#6E726E] ">
                  Location
                </label>
                <FormControl
                  sx={{
                    "& .MuiFormGroup-root": {
                      display: "flex",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <Controller
                    name="location"
                    row
                    defaultValue={profInfo?.location_option}
                    render={({field}) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={"Use Personal Location"}
                          label="Use Personal Location"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 25,
                                },
                              }}
                            />
                          }
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: "Mulish",
                              color: "#3B3D3B",
                            },
                          }}
                        />
                        <FormControlLabel
                          value={"Choose new location"}
                          label="Choose new location"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 25,
                                },
                              }}
                            />
                          }
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: "Mulish",
                              color: "#3B3D3B",
                            },
                          }}
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </div>
              {watchLocation &&
                watchLocation.toLowerCase() === "choose new location" && (
                  <>
                    {/* countries  */}
                    <AutoCompleteField
                      name="country"
                      selectOption={["Nigeria"]}
                      label="Country"
                      placeholder="Select Country"
                      className="font-Mulish text-sm text-grey_30"
                      defaultValue={profInfo?.country ? profInfo?.country : " "}
                    />

                    <div className="grid items-end gap-4 md:grid-cols-2">
                      {/* state  */}
                      <AutoCompleteField
                        name="state"
                        selectOption={states}
                        label="State"
                        loading={isStatesLoading}
                        className="font-Mulish text-sm text-grey_30"
                        placeholder="Lagos"
                        defaultValue={
                          profInfo?.state.name ? profInfo?.state.name : " "
                        }
                      />

                      {/* city */}
                      <AutoCompleteField
                        name="city"
                        selectOption={stateCities}
                        label="City"
                        loading={isStatesLoading}
                        className="font-Mulish text-sm text-grey_30"
                        placeholder="Ikeja"
                        defaultValue={
                          profInfo?.city.name ? profInfo?.city.name : " "
                        }
                      />
                    </div>

                    {/* address  */}
                    <ValidatedTextArea2
                      label="Professional Address"
                      placeholder="Ikeja"
                      name="office_address"
                      required={true}
                    />
                  </>
                )}
            </div>
            <SaveChangeBtn isLoading={isLoading} type="submit" />
          </fieldset>
        </form>
      </FormProvider>
    </div>
  );
};
export default ProfessionalTab;
