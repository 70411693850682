import React from "react";
import {Button, Chip} from "@mui/material";
import MyModal from "components/mui-components/Modal";
import {useNavigate} from "react-router-dom";
import {ReactComponent as UbaLogo} from "assets/Uba.svg";
import {TChipStatus} from "../Tabs/TransactionTab";
import moment from "moment";
import {toCurrency} from "utils";
import {JobsStatusColor} from "utils/constants";

function DetailsModal({openModal, setopenModal, data}) {
  const navigate = useNavigate();

  return (
    <MyModal
      maxWidth={700}
      title={
        data?.status === "Completed" ? "Transaction Title" : "Refund Details"
      }
      openModal={openModal}
      closeModal={() => setopenModal(false)}
    >
      <div className="text-sm md:text-base">
        <div className="mt-2 flex items-start gap-3 rounded-20 bg-[#F9FAFB] p-4">
          <img
            src={
              data?.engagement?.job_detail?.images?.length > 0
                ? data?.engagement?.job_detail?.images[0]?.image_url
                : "/images/broken-image.png"
            }
            alt="transaction"
            className="h-[80px] w-[80px] rounded-16 object-cover"
          />
          <div className="flex-grow">
            <div className="mb-2 flex items-center justify-between font-semibold">
              <p className="text-xl capitalize">
                {data?.engagement?.job_detail?.job_title}
              </p>
              <p className="font-Mulish italic text-grey_30">
                {moment(data?.engagement?.created_at).format("DD MMM YYYY")}
              </p>
            </div>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                navigate(`/client/engagements/${data?.engagement?.id || ""}`)
              }
            >
              View Engagement
            </Button>
          </div>
        </div>
        {data?.status === "Refunded" ? (
          <div className="my-6 grid grid-cols-2 gap-5 border">
            <div className=" rounded-20 border border-[#F2F4F7] p-4">
              <div className="flex items-center justify-between font-semibold">
                <p className="text-xl text-grey_30">Amount</p>
                <Chip
                  color={JobsStatusColor[data?.status?.toLowerCase()]}
                  label={data?.status}
                />
              </div>
              <p className="text-3xl font-semibold">₦500,000</p>
            </div>

            <div className=" rounded-20 border border-[#F2F4F7] p-4">
              <p className="mb-3 font-Bai text-lg font-medium text-grey_30">
                Account
              </p>

              <div className="flex items-center gap-3">
                {/* <img src="/images/refundDetailsImage.png" alt="EngagementImage" className="" /> */}
                <UbaLogo />

                <div className="">
                  <p className="text-base font-medium text-grey">
                    Adebanwo Aderibigbe
                  </p>
                  <p className="text-base font-medium text-grey_30">
                    2311493403 - UBA
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="my-6 rounded-20 border border-[#F2F4F7] p-4">
            <div className="flex items-center justify-between font-semibold">
              <p className="text-xl text-grey_30">Amount</p>
              <Chip
                color={TChipStatus[data?.status.toLowerCase()]}
                label={data?.status}
                className="Dot-chip"
              />
            </div>
            <p className="text-3xl font-semibold">{toCurrency(data?.amount)}</p>
          </div>
        )}

        <div className="mb-2 grid grid-cols-3 rounded-20 border border-[#F2F4F7] p-4 font-semibold">
          <div className="flex-1 border-r">
            <p className="text-lg text-grey_30">VAT</p>
            <p className="text-xl">{toCurrency(data?.vat)}</p>
          </div>
          <div className="flex flex-1 justify-center">
            <div>
              <p className="text-lg text-grey_30">Transaction ID</p>
              <p className="text-xl">#{data?.transaction_ref}</p>
            </div>
          </div>
          {data?.status === "Completed" && (
            <div className="flex flex-1 justify-end border-l">
              <div>
                <p className="text-lg text-grey_30">Date</p>
                <p className="text-xl">
                  {moment(data?.created_at).format("DD MMM YYYY")}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </MyModal>
  );
}

export default DetailsModal;
