import RequestOutline from "components/Vectors/RequestOutline";
import TopDetail from "components/TopDetail.jsx";
import JobRequestBody from "components/JobRequestBody.jsx";
const JobRequest = () => {
  return (
    <>
      <TopDetail details={topDetails} />
      <JobRequestBody />
    </>
  );
};

export default JobRequest;
const topDetails = [
  {
    title: "All Requests",
    text: 10,
    icon: <RequestOutline stroke="#E98100" transparent={true} />,
  },
  {
    title: "Pending Request",
    text: 10,
    icon: <RequestOutline stroke="#6E726E" transparent={true} />,
  },
  {
    title: "Withdrawn Requests",
    text: 10,
    icon: <RequestOutline stroke="#D92D20" transparent={true} />,
  },
  {
    title: "Declined Requests",
    text: 10,
    icon: <RequestOutline stroke="#D92D20" transparent={true} />,
  },
];
