import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import StateCityField from "pages/ServiceProviders/Settings/Components/StateCityField";

const PhysicalComponent = ({setStateCity}) => {
  return (
    <div>
      <div className="mb-5 mt-2 flex flex-col items-center gap-4 md:flex-row">
        <StateCityField setStateCity={setStateCity} />{" "}
      </div>
      <div>
        <ValidatedTextArea2
          bordered={"false"}
          name="address"
          label="Address"
          placeholder="Enter Address"
          height="h-[90px]"
        />
        <span className="text-sm text-grey-10">
          This address is protected until you reach a final agreement.
        </span>
      </div>

      <div>
        {/* <ValidatedRadioBtns
          label="Duration"
          name="duration_type"
          options={[
            { label: "Days", value: "days" },
            { label: "Weeks", value: "weeks" },
            { label: "Months", value: "months" },
          ]}
        /> */}
        {/* {durationType && ( */}
        {/* <ValidatedInput2
            name="duration"
            label={durationType === 'days'? "Number of days": durationType === 'weeks'? "Number of weeks": durationType === 'months'? "Number of months": "Number of days"}
            placeholder="E.g: 3"
            type="number"
            inputMode="numeric"
            rules={{
              valueAsNumber: true,
              pattern: {
                message: "Number must be greater than 0",
              },
            }}
          /> */}
        {/* )} */}
      </div>
      {/* <div
        className={`mt-6 hidden flex-col justify-between gap-3 md:flex-row ${durationError || durationTypeError
          ? "items-stretch md:items-start"
          : "items-stretch md:items-center"
          }`}
      >
        <ValidatedInput2
          name="duration"
          label={durationType === 'days'? "Number of days": durationType === 'weeks'? "Number of weeks": durationType === 'months'? "Number of months": "Number of days"}
          placeholder="E.g: 3"
          type="number"
          inputMode="numeric"
          rules={{
            valueAsNumber: true,
            pattern: {
              message: "Number must be greater than 0",
            },
          }}
        />
        <div className="md:basis-[35%] ">
          <AutoCompleteField
            name="duration_type"
            selectOption={["Days", "Weeks", "Months"]}
            label="Duration Type"
            bordered={"true"}
            placeholder="Select duration type"
          // showRequiredIndication={false}
          // required={true}
          />
        </div>
      </div> */}
    </div>
  );
};

export default PhysicalComponent;
