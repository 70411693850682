import { Link, useNavigate } from "react-router-dom";
import truelocalLogo from "assets/logo-white.svg";
import { ReactComponent as Logo } from "assets/images/logo-white.svg";
import { useGetUser } from "hooks/getUserHook";

const Footer = ({ isUser, isProfessional }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useGetUser();
  const linksArray = isLoggedIn
    ? isProfessional
      ? providerLinks
      : isUser
        ? clientLinks
        : adminLinks
    : guestLinks;
  return (
    <footer className="relative mt-64 overflow-x-clip bg-grey_10 px-6 text-white md:px-20">
      <div className="absolute left-1/2 -top-24 -z-10 h-44 w-[120%] -translate-x-1/2 rounded-tl-[50%] rounded-tr-[50%] bg-grey_10 md:rounded-tr-[100%] md:rounded-tl-[100%]">
        hello
      </div>
      {isUser ? (
        <div className="mb-[57px] flex items-center justify-center">
          <img src={truelocalLogo} alt="" className="w-56" />
        </div>
      ) : (
        <div
          className="custom-bg mx-auto mb-[60px] flex flex-col items-center
       rounded-[32px] md:mb-[57px] lg:w-[95%] md:flex-row md:justify-center p-4 sm:p-6 md:py-[50px] md:px-[64px]"
        >
          <div className="flex flex-1 flex-col gap-4 ">
            <Logo height="48px" width="190px" />
            <p className="font-Bai text-base font-semibold tracking-[-2%] md:text-[20px]">
              We connect people looking to get their to-do list done with people
              looking to make extra cash.
            </p>
          </div>
          <ul
            role="group"
            className="flex w-full mt-10 flex-1 items-center justify-center gap-x-4 md:w-auto"
          >
            {["Offers Service", "Provide a Service"].map((item, index) => (
              <li key={index}>
                <button
                  onClick={() =>
                    navigate(
                      isLoggedIn
                        ? `${item.includes("Provide a Service")
                          ? "/client/post-job"
                          : ""
                        }`
                        : "/user/login",
                    )
                  }
                  type="button"
                  className="rounded-[20px] bg-btn-sec px-4 py-[22px] text-sm font-medium text-primary md:w-full md:px-8 md:text-[16px]"
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-8 md:flex justify-between mb-[60px] lg:w-[95%] lg:mx-auto">
        {linksArray.map((item) =>
          <div
            key={item.title}
            className="flex-1"
          >
            <p className="font-Mulish text-base md:text-lg font-medium text-grey_50 mb-5">{item.title}</p>
            <ul className="flex flex-col gap-5 font-Mulish text-base md:text-lg font-medium">
              {item.links.map(link =>
                <li
                  key={link.title}
                  className='text-white'>
                  <Link to={link.link}
                  >
                    {link.title}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}

        <div className="md:border-l border-opacity-20 flex-1 md:flex flex-col items-end ">
          <ul className="flex  flex-col gap-5 font-Mulish text-base md:text-lg font-medium">
            <p className="font-Mulish text-base md:text-lg font-medium text-grey_50">Socials</p>
            {socialLinks.map(link =>
              <li
                key={link.title}
                className='text-white w-fit'
              >
                <a
                  target="_blank"
                  href={link.link}
                  rel="noreferrer"
                >
                  {link.title}
                </a>

              </li>
            )}
          </ul>
        </div>
      </div>

      <div className="h-px bg-white bg-opacity-20"></div>
      <div className="flex flex-wrap-reverse items-center justify-center gap-3 py-6 text-sm font-medium text-[#C8C8C8] md:justify-between md:text-base">
        <span>
          &copy; {new Date().getFullYear()} All rights reserved, Truelocal
        </span>
        <div className="flex flex-wrap items-center gap-x-4">
          <Link to="/privacy-policy">Privacy & Policy</Link>
          <Link to="/terms-of-service">Terms of service</Link>
          <a
            href="https://nativebrands.co/"
            target="_blank"
            title="Built by nativebrands"
            rel="noopener noreferrer"
            className="h-[39px] w-[39px]"
          >
            <img
              src="/images/built-by-native-brands.png"
              alt="built-by-native-brands"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;


const guestLinks = [
  {
    title: "Get something done",
    links: [
      {
        title: "Post a Task",
        link: "/user/login",
      },
      {
        title: "Browse Jobs",
        link: "/jobs",
      },
   
    ],
  },
  {
    title: "How it works",
    links: [
      {
        title: "For Clients",
        link: "/how-it-works/clients",
      },
      {
        title: "For Service Providers",
        link: "/how-it-works/service-providers",
      },
      {
        title: "Find Service Providers",
        link: "/user/login",
      },
      {
        title: "Services",
        link: "/user/login",
      },
    ],
  },

  {
    title: "Company",
    links: [
      {
        title: "About Us",
        link: "/about-us",
      },
      {
        title: "Contact Us",
        link: "/contact-us",
      },
      {
        title: "FAQs",
        link: "/faqs",
      },
    ],
  },
];

const clientLinks = [
  {
    title: 'Get something done',
    links: [
      { title: "Post a Job", link: "/client/post-job" },
      { title: "Categories", link: "/categories" },
      { title: "Tickets", link: "/client/tickets" },
      { title: "My Jobs", link: "/client/jobs" },
    ]
  },
  {
    title: 'How it works',
    links: [
      {
        title: 'For Clients',
        link: '/how-it-works/clients'
      },
      {
        title: 'For Service Providers',
        link: '/how-it-works/service-providers'
      },
    ]
  },

  {
    title: 'Company',
    links: [
      {
        title: 'About Us',
        link: '/about-us'
      },
      {
        title: 'Contact Us',
        link: '/contact-us'
      },
      {
        title: "FAQs",
        link: "/faqs",
      },
    ]

  },
]
const providerLinks = [
  {
    title: "Get something done",
    links: [
      { title: "Discover Jobs", link: "/service-provider/marketplace" },
      { title: "Tickets", link: "/service-provider/tickets" },
      { title: "Engagements", link: "/service-provider/engagements" },
    ],
  },
  {
    title: "How it works",
    links: [
      {
        title: "For Clients",
        link: "/how-it-works/clients",
      },
      {
        title: "For Service Providers",
        link: "/how-it-works/service-providers",
      },
    ],
  },

  {
    title: "Company",
    links: [
      {
        title: "About Us",
        link: "/about-us",
      },
      {
        title: "Contact Us",
        link: "/contact-us",
      },
      {
        title: "FAQs",
        link: "/faqs",
      },
    ],
  },
];
const adminLinks = [
  {
    title: "Get something done",
    links: [
      { title: "Jobs", link: "/admin/jobs" },
      { title: "Categories", link: "/categories" },
    ],
  },
  {
    title: "How it works",
    links: [
      {
        title: "For Clients",
        link: "/how-it-works/clients",
      },
      {
        title: "For Service Providers",
        link: "/how-it-works/service-providers",
      },
    ],
  },

  {
    title: "Company",
    links: [
      {
        title: "About Us",
        link: "/about-us",
      },
      {
        title: "Contact Us",
        link: "/contact-us",
      },
      // {
      //   title: "FAQs",
      //   link: "/faqs",
      // },
    ],
  },
];
const socialLinks = [
  {
    title: 'Instagram',
    link: 'https://instagram.com/truelocalng'
  },
  {
    title: 'Facebook',
    link: 'https://facebook.com/Truelocalng'
  },
  {
    title: 'Linkedin',
    link: 'https://www.linkedin.com/company/truelocalng/'
  },
  {
    title: 'Twitter',
    link: 'https://twitter.com/Truelocalng'
  },
]