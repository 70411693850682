import {MenuItem, Pagination, Select} from "@mui/material";
import "./style.scss";

function TablePagination({
  meta: {totalPages = 10, currentPage = 1, rowCount, totalCount = 20},
  page,
  setPage,
  setPerPage = () => {},
  perPage = 5,
  from,
  to,
  hideRowPerPage,
  perPageOptions = [5, 10, 15, 20],
}) {

  const handlePageChange = (event, page) => {
    setPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
      <div className="pagination-wrap gap-4">
        <p className="rounded-xl border-2 border-grey_70 bg-grey_90 p-4">
          Showing {from || 1} - {to || perPage} out of {totalCount}
        </p>

        <div className="flex flex-wrap items-center gap-4 rounded-xl border-2 border-grey_70 bg-grey_90 px-4 py-2 sm:justify-end">
          {!hideRowPerPage && (
            <div className="flex items-center gap-3">
              <Select
                value={perPage}
                onChange={e => {
                  setPerPage(e.target.value);
                }}
              >
                {perPageOptions.map(el => (
                  <MenuItem
                    key={`row_per_page${el}`}
                    value={el}
                    className="center"
                  >
                    {el}
                  </MenuItem>
                ))}
              </Select>
              <p>Rows per page</p>
            </div>
          )}
          <Pagination
            color="primary"
            count={totalPages}
            defaultPage={currentPage}
            page={page}
            onChange={handlePageChange}
            // onChange={(e, val) => setPage(val)}
            variant="text"
            shape="rounded"
            siblingCount={0}
          />
        </div>
      </div>
    </>
  );
}

export default TablePagination;
