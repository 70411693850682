import {useState, useEffect, useRef} from "react";
import Pusher from "pusher-js";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import {
  useGetAllAdminNotificationQuery,
  useMarkMultipleNotificationAsReadMutation,
} from "services/admin/adminGeneralApi";
import {handleError} from "utils";
import SingleNotification from "components/SingleNotification";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";

const AdminNotifications = () => {
  const containerRef = useRef(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const {data, error, isError, refetch, isLoading} =
    useGetAllAdminNotificationQuery({
      params: {
        is_paginated: "yes",
        per_page: perPage,
        page,
      },
    });

  const [markAllAsRead] = useMarkMultipleNotificationAsReadMutation();

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("admin-in-app-notification-received");
    channel.bind("admin-in-app-notification-event", data => {
      refetch();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [refetch]);

  useEffect(() => {
    markAllAsRead().unwrap().catch(handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="" type="admin">
      <div ref={containerRef} className="rounded-2xl bg-white p-4 md:py-6">
        <h1 className="my-4 font-Bai text-2xl font-semibold text-grey_20 md:text-[32px]">
          Notifications
        </h1>
        <MyDataTable
          isLoading={isLoading}
          error={error}
          isError={isError}
          emptyData={data?.total === 0}
          scrollX={false}
          hideChipDot={true}
          options={{
            viewColumns: false,
            filter: false,
            customToolbar: null,
            print: false,
            count: data?.total,
            rowsPerPage: perPage,
            serverSide: true,
            onTableChange: (action, tableState) => {
              switch (action) {
                case "changePage":
                  console.log({action});
                  setPage(tableState.page + 1);
                  containerRef.current.scrollIntoView({
                    behavior: "smooth",
                  });
                  break;
                case "changeRowsPerPage":
                  setPerPage(tableState.rowsPerPage);
                  containerRef.current.scrollIntoView({
                    behavior: "smooth",
                  });
                  break;
                case "propsUpdate":
                  containerRef.current.scrollIntoView({
                    behavior: "smooth",
                  });
                  break;
                default:
                  break;
              }
            },
          }}
          headCells={[
            {
              name: "notifications",
              label: "",
              options: {
                customBodyRender: notification => {
                  return (
                    <SingleNotification detail={notification} isAdmin={true} />
                  );
                },
              },
            },
          ]}
          data={data?.data?.map(notification => ({
            notifications: notification,
          }))}
        />
      </div>
    </DashboardLayout>
  );
};

export default AdminNotifications;
