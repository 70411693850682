import {Avatar} from "@mui/material";

const ProfessionalTab = ({data}) => {
  return (
    <div className="h-fit rounded-2xl bg-grey_80">
      <div className="flex items-center gap-3">
        <Avatar
          sx={{width: "4rem", height: "4rem"}}
          src={data?.professional_information?.business_logo}
          alt={data?.professional_information?.business_name}
        />

        <p className="text-lg font-bold text-black">
          {data?.professional_information?.business_name}
        </p>
      </div>

      <div className="mt-6">
        <p className="mb-0 text-lg font-medium text-grey_30">About Business</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {data?.professional_information?.about}
        </p>
      </div>

      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Email Address</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {data?.email}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">
          Years of Experience
        </p>
        <p className="my-2 text-lg font-medium text-[#000000]">12</p>
      </div>

      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">Country</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {data?.country === "undefined" ? "N/A" : data?.country}
        </p>
      </div>
      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Location</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {data?.location === "undefined" ? "N/A" : data?.location}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">
          Professional Address
        </p>
        <p className="my-2 font-medium capitalize text-[#000000] md:text-lg">
          {data?.professional_information?.residential_address || "N/A"}
        </p>
      </div>
    </div>
  );
};

export default ProfessionalTab;
