import {get, useFormContext, Controller} from "react-hook-form";
import {FileUploader} from "react-drag-drop-files";
import {useEffect, useState} from "react";
import AddImageIcon from "components/Vectors/AddImageIcon";
import {Avatar, IconButton} from "@mui/material";
import EditIcon from "components/Vectors/EditIcon";
import FileIcon from "components/Vectors/FileIcon";
import {getFileSize, isImage} from "utils";

const CustomFileUploader = ({
  label,
  types,
  name,
  required,
  defaultUrl,
  maxSize = "1",
  fileType,
  placeholder,
  placeholderTypes,
  className = "min-h-20 flex-col md:h-[167px]",
}) => {
  const {
    register,
    setError,
    control,
    formState: {errors},
  } = useFormContext();
  const [localImgUrl, setLocalImgUrl] = useState(defaultUrl || "");

  useEffect(() => {
    setLocalImgUrl(defaultUrl);
  }, [defaultUrl]);

  useEffect(() => {
    register(name, {
      required: required ? "This field is required" : false,
    });
  }, [register, name, required]);

  const error = get(errors, name);

  console.log(error?.message);

  const getFileUrl = async file => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener(
        "load",
        function () {
          setLocalImgUrl(reader?.result || "");
        },
        false,
      );
    }
  };

  const DisplayValue = ({value, isImage}) => {
    return (
      <>
        {isImage ? (
          <div className="z-10 mb-4 h-full w-full cursor-pointer object-cover">
            <Avatar
              variant="rounded"
              sx={{
                width: "100%",
                objectFit: "cover",
                height: "100%",
              }}
              src={localImgUrl}
            />
          </div>
        ) : (
          <div
            className="flex h-full w-full flex-col
    items-center justify-center gap-3 space-x-3 rounded-20 bg-[#F4F4F5] py-6"
          >
            <FileIcon />
            <div className="max-w-[80%] font-medium">
              <p className="-mb-2 max-w-full truncate text-sm">{value?.name}</p>
              <small className="text-xs text-grey">
                {getFileSize(value?.size)}
              </small>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col ">
      <label className="mb-2 text-sm font-semibold text-[#8585A3]">
        {label}
      </label>

      <Controller
        rules={{
          required: required ? "This field is required" : "",
        }}
        control={control}
        name={name}
        defaultValue={defaultUrl}
        render={({field}) => {
          const {onChange, value} = field;
          return (
            <FileUploader
              label={placeholder || `Upload Image or drag and drop file here`}
              maxSize={maxSize}
              hoverTitle="Drop Here!"
              onSizeError={file => {
                setError(name, {
                  message: `File size greater than ${maxSize}`,
                });
              }}
              handleChange={e => {
                onChange(e);
                getFileUrl(e);
              }}
              types={types}
            >
              <div
                className={`flex h-[167px] flex-col items-center justify-center gap-3 rounded-20
                bg-[#F4F4F5] py-6 text-base ${className}`}
              >
                <AddImageIcon />
                <div className="text-center md:text-left">
                  {field?.value && !error ? (
                    <div className="absolute inset-0 z-10 h-full w-full rounded-20 bg-white">
                      <DisplayValue
                        isImage={fileType === "image" || isImage(value)}
                        value={value}
                      />
                      <IconButton className="!rounded-4 !absolute right-2 top-2 !bg-grey_100">
                        <EditIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <div>
                      <p className=" mb-0.5 text-center text-base font-bold text-grey_20">
                        {placeholder || (
                          <>
                            Drag and Drop or{" "}
                            <span className="text-primary">Choose File</span> to
                            upload
                          </>
                        )}
                      </p>
                      <p className="text-center text-sm font-semibold text-[#A2A2B9]">
                        {placeholderTypes ||
                          " .jpg, .jpeg, .png, or .pdf not more than 1mb"}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </FileUploader>
          );
        }}
      />
      {error && error?.message && (
        <div className={` ${!error && "hidden"} input-err-msg pt-[-10rem] `}>
          {error?.message === "File size greater than 1"
            ? "The image must not be greater than 1024 kilobytes"
            : error?.message || error?.name?.message || ""}
          {/* {error?.message || error?.name?.message || ""} */}
        </div>
      )}
    </div>
  );
};
export default CustomFileUploader;
