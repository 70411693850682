import React from "react";

function CoinHandIcon() {
  return (
    <div>
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.5793 19.7713C32.0528 20.8595 30.1848 21.4997 28.1673 21.4997C23.0127 21.4997 18.834 17.321 18.834 12.1663C18.834 7.01168 23.0127 2.83301 28.1673 2.83301C31.5086 2.83301 34.4398 4.58879 36.0887 7.22805M13.5007 51.0654H20.4614C21.369 51.0654 22.271 51.1734 23.1508 51.3894L30.5057 53.1767C32.1016 53.5655 33.7641 53.6033 35.3766 53.2901L43.5086 51.708C45.6568 51.2895 47.6329 50.2609 49.1816 48.7544L54.935 43.1576C56.5781 41.562 56.5781 38.9728 54.935 37.3745C53.4557 35.9355 51.1133 35.7735 49.4397 36.9938L42.7342 41.886C41.7739 42.5879 40.6055 42.9659 39.4037 42.9659H32.9287L37.0502 42.9657C39.3732 42.9657 41.2549 41.1352 41.2549 38.8754V38.0574C41.2549 36.181 39.9422 34.5449 38.0715 34.0913L31.7102 32.5443C30.675 32.2932 29.6148 32.1663 28.549 32.1663C25.9762 32.1663 21.319 34.2965 21.319 34.2965L13.5007 37.566M50.834 14.833C50.834 19.9877 46.6553 24.1663 41.5007 24.1663C36.346 24.1663 32.1673 19.9877 32.1673 14.833C32.1673 9.67835 36.346 5.49967 41.5007 5.49967C46.6553 5.49967 50.834 9.67835 50.834 14.833ZM2.83398 36.433L2.83398 51.8997C2.83398 53.3931 2.83398 54.1399 3.12463 54.7103C3.3803 55.2121 3.78824 55.62 4.29001 55.8757C4.86044 56.1663 5.60718 56.1663 7.10065 56.1663H9.23398C10.7275 56.1663 11.4742 56.1663 12.0446 55.8757C12.5464 55.62 12.9543 55.2121 13.21 54.7103C13.5007 54.1399 13.5007 53.3931 13.5007 51.8997V36.433C13.5007 34.9395 13.5007 34.1928 13.21 33.6224C12.9543 33.1206 12.5464 32.7127 12.0446 32.457C11.4742 32.1663 10.7275 32.1663 9.23399 32.1663L7.10065 32.1663C5.60718 32.1663 4.86044 32.1663 4.29001 32.457C3.78824 32.7127 3.3803 33.1206 3.12463 33.6224C2.83398 34.1928 2.83398 34.9395 2.83398 36.433Z"
          stroke="#3B3D3B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default CoinHandIcon;
