import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {logOut} from "redux/slice/AuthSlice";
import {API_URL} from "utils/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/user/service-provider/`,
  prepareHeaders: (headers, api) => {
    const {auth} = api.getState();

    if (auth.token) {
      headers.set("authorization", `Bearer ${auth.token}`);
      headers.set("Access-Control-Allow-Origin", `*`);
    }
    headers.set("Accept", `application/json`);
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  let err = result?.error;
  if (result?.error && err.data?._meta?.error?.message.includes("logged in")) {
    setTimeout(() => {
      api.dispatch(logOut({redirect: true}));
    }, 1000);
  }
  return result;
};

export const providerGeneralApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "providerGeneralApi",
  tagTypes: ["jobData", "services", "transactionData"],
  endpoints: builder => ({
    getSingleUser: builder.query({
      query: id => `${id}`,
    }),
    // marketplace
    getAJobDetail: builder.query({
      query: id => `view-job/${id}`,
      providesTags: ["jobData"],
    }),

    getAllJobs: builder.query({
      query: params => ({
        url: "list-jobs",
        params: {...params},
      }),
      providesTags: ["jobData"],
    }),
    getFollowedJobs: builder.query({
      query: params => ({
        url: "followed-jobs",
        params: {...params},
      }),
      providesTags: ["jobData"],
    }),
    getOffersStats: builder.query({
      query: params => ({
        url: `offers-stats`,
        params,
      }),
      providesTags: ["jobData"],
    }),
    getNewestJobs: builder.query({
      query: params => ({
        url: `dashboard/newest-jobs`,
        params,
      }),
      providesTags: ["jobData"],
    }),
    followJob: builder.mutation({
      query: body => ({
        url: "followed-jobs",
        method: "POST",
        body,
      }),
      invalidatesTags: ["jobData"],
    }),
    unFollowJob: builder.mutation({
      query: id => ({
        url: `followed-jobs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["jobData"],
    }),

    // service and expertise
    addSPServices: builder.mutation({
      query: ({body}) => ({
        url: `services`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["services"],
    }),

    getAllSPServices: builder.query({
      query: () => `services`,
      providesTags: ["services"],
    }),

    getSingleSPService: builder.query({
      query: id => `services/${id}`,
      providesTags: ["services"],
    }),

    deleteSingleService: builder.mutation({
      query: id => ({
        url: `services/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["services"],
    }),

    // Transactions
    getTransactionsStat: builder.query({
      query: () => `transaction/statistics`,
      providesTags: ["transactionData"],
    }),
    getEarningHistory: builder.query({
      query: () => "earnings/list/earnings-histories",
      providesTags: ["transactionData"],
    }),
    getWithdrawalHistory: builder.query({
      query: () => "withdrawal/list/withdrawal-histories",
      providesTags: ["transactionData"],
    }),
    getSingleWithdrawalHistory: builder.query({
      query: id => `withdrawal/withdrawal-history/${id}`,
      providesTags: ["transactionData"],
    }),
    requestWithdrawal: builder.mutation({
      query: body => ({
        url: "withdrawal/request-withdrawal",
        method: "POST",
        body,
      }),
      invalidatesTags: ["transactionData"],
    }),
  }),
});

export const {
  useGetSingleUserQuery,
  useGetAJobDetailQuery,
  useGetAllJobsQuery,
  useGetOffersStatsQuery,
  useGetNewestJobsQuery,
  useFollowJobMutation,
  useUnFollowJobMutation,
  useGetFollowedJobsQuery,
  useAddSPServicesMutation,
  useGetAllSPServicesQuery,
  useDeleteSingleServiceMutation,
  useGetSingleSPServiceQuery,
  useGetTransactionsStatQuery,
  useGetEarningHistoryQuery,
  useGetWithdrawalHistoryQuery,
  useGetSingleWithdrawalHistoryQuery,
  useRequestWithdrawalMutation,
} = providerGeneralApi;
