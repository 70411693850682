import {Avatar, Button} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import {Link} from "react-router-dom";
import {useMarkNotificationAsReadMutation} from "services/user/userRegApi";
import {convertToSlug, handleError} from "utils";
import EngagementIcon from "./Vectors/EngagementIcon";
import JobIcon from "./Vectors/JobIcon";
import TicketIcon from "./Vectors/ticket";
import MessageChatIcon from "./Vectors/MessageChatIcon";
import TransactionIcon from "./Vectors/TransactionIcon";
import TokenIcon from "./Vectors/TokenIcon";
import PlugIcon from "./Vectors/PlugIcon";
import UserIcon from "./Vectors/user";
import {createElement} from "react";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {useGetUser} from "hooks/getUserHook";
import {useMarkOneNotificationAsReadMutation} from "services/admin/adminGeneralApi";

const MappedIcon = {
  Engagement: EngagementIcon,
  Offer: JobIcon,
  Ticket: TicketIcon,
  ChatMessage: MessageChatIcon,
  Conversation: MessageChatIcon,
  Payout: TransactionIcon,
  ClientJob: JobIcon,
  EarningHistory: TransactionIcon,
  RefundHistory: TransactionIcon,
  TokenWallet: TokenIcon,
  Kyc: PlugIcon,
  CacCertificate: PlugIcon,
  User: UserIcon,
  TicketResponse: TicketIcon,
};

export const StatusColor = {
  active: "#12B76A",
  suspended: "#E98100",
  terminated: "#D92D20",
  cancelled: "#D92D20",
  closed: "#D92D20",
  drafted: "#E98100",
  ongoing: "#5010B3",
  open: "#E98100",
  completed: "#12B76A",
  pending: "#E98100",
  refunded: "#5010B3",
};

const Links = {
  Engagement: "/engagements",
  Offer: "/marketplace",
  Ticket: "/tickets",
  Payout: "/transactions",
  EarningHistory: "/transactions",
  RefundHistory: "/transactions",
  TokenWallet: "/subscriptions",
  Kyc: "/settings?tab=kyc",
  CacCertificate: "/settings?tab=kyc",
  TicketResponse: "/tickets",
  ClientJob: "/marketplace",
};

const getNotificationIcon = type => {
  let icon = MappedIcon[type];
  if (!icon) {
    for (const key in MappedIcon) {
      if (type.includes(key)) {
        icon = MappedIcon[key];
        break;
      }
    }
  }
  return icon;
};

const modulesWithId = [
  "Ticket",
  "TicketResponse",
  "Engagement",
  "Offer",
  "Conversation",
  'ClientJob'
];

const getNotificationLink = (type, id, userType = "client") => {
  let link =
    userType === "Client" && type.includes("Offer")
      ? "/jobs"
      : Links[type?.split("\\")?.pop()];
  if (id && link && modulesWithId.includes(type?.split("\\")?.pop())) {
    link = link + `/${id}`;
  }
  return link;
};

  const SingleNotification = ({ detail, isAdmin }) => {
    const isOpened = Boolean(detail?.seen);
    const [markAsRead] = useMarkNotificationAsReadMutation();
    const [markAsReadAdmin] = useMarkOneNotificationAsReadMutation();

    const handleOpening = () => {
      if (isOpened) return;
      if (isAdmin) {
        markAsReadAdmin({
          notification_id: detail?.id,
        })
          .unwrap()
          .then()
          .catch(err => {
            handleError(err);
          });
      } else {
        markAsRead({
          notification_id: detail?.id,
        })
          .unwrap()
          .then(res => { })
          .catch(err => {
            handleError(err);
          });
      }
    };

    // console.log(detail)
    const sender = detail?.sender || detail?.from_admin;
    const { user } = useGetUser();

    const getConversationModule = (type, data) => {
      return type?.split("\\")?.pop() === "Conversation"
        ? data?.get_conversation_module?.client_job_id ||
        data?.get_conversation_module?.engagement_id
        : data?.module_id;
    };

  return (
    <div
      role="button"
      className={`relative cursor-pointer rounded-xl p-4 ${
        isOpened ? "bg-transparent" : "bg-grey_80"
      }  flex items-center justify-between`}
      onClick={handleOpening}
    >
      {!isOpened && (
        <div className="absolute top-2 left-2 h-[8px] w-[8px] rounded-full bg-primary md:h-[12px] md:w-[12px]"></div>
      )}
      <div className="flex items-center gap-3">
        <div className="relative h-[64px] w-[64px]">
          <Avatar
            sx={{
              width: "100%",
              height: "100%",
              minWidth: {xs: "64px", md: "auto"},
            }}
            src={sender?.avatar}
            alt={sender?.full_name}
          />
          <div
            style={{
              background:
                StatusColor[detail?.notifiable?.status?.toLowerCase()] ||
                "#E98100",
            }}
            className={`absolute -bottom-1 -right-1 flex h-[32px] w-[32px] items-center justify-center rounded-full`}
          >
            {createElement(
              getNotificationIcon(detail?.notifiable_type.split("\\").pop()) ||
                NotificationsNoneOutlinedIcon,
              {
                width: 16,
                height: 16,
                color: "#fff",
                stroke: "#fff",
              },
            )}
          </div>
        </div>

        <div>
          <p className="mb-2 text-base font-bold text-black">
            <>
              {sender?.full_name}{" "}
              <span className="font-normal">
                {detail?.notifiable_type?.includes("Conversation")
                  ? "Sent "
                  : ""}
                {detail?.message === 'New job created'
                  ? 'created a new job you  might be interested in'
                  : detail?.message}{" "}
              </span>
            </>
          </p>
          <div className="flex items-center justify-between">
            {getNotificationLink(
              detail?.notifiable?.module_type || detail?.notifiable_type,
            ) && (
              <Button
                component={Link}
                to={`/${
                  user?.admin_permissions
                    ? "admin" +
                      getNotificationLink(
                        detail?.notifiable?.module_type ||
                          detail?.notifiable_type,
                        detail?.notifiable_type.includes("Offer")
                          ? detail?.notifiable?.client_job_id
                          : getConversationModule(
                              detail?.notifiable_type,
                              detail?.notifiable,
                            ) || detail?.notifiable_id,
                        user?.user_type,
                      )
                    : convertToSlug(user?.user_type) +
                      getNotificationLink(
                        detail?.notifiable?.module_type ||
                          detail?.notifiable_type,
                        detail?.notifiable_type.includes("Offer")
                          ? detail?.notifiable?.client_job_id
                          : getConversationModule(
                              detail?.notifiable_type,
                              detail?.notifiable,
                            ) || detail?.notifiable_id,
                        user?.user_type,
                      )
                }`}
                endIcon={<NavigateNextIcon color="primary" sx={{ml: -1}} />}
              >
                View{" "}
                {(detail?.notifiable?.module_type || detail?.notifiable_type)
                  ?.split("\\")
                  ?.pop() === 'ClientJob'? 'Job': (detail?.notifiable?.module_type || detail?.notifiable_type)
                  ?.split("\\")
                  ?.pop()}
              </Button>
            )}

            <p className="text-sm font-normal text-grey_30 md:hidden">
              {moment(detail?.created_at).fromNow()}
            </p>
          </div>
        </div>
      </div>
      <p className="hidden min-w-max text-base font-normal text-grey_30 md:block">
        {moment(detail?.created_at).fromNow()}
      </p>
    </div>
  );
};

export default SingleNotification;
