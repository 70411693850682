import {Button} from "@mui/material";
import EmailLayout from "./widgets/EmailLayout";
import EmailTitle from "./widgets/EmailTitle";

const GeneralTemplate = () => {
  return (
    <EmailLayout>
      <>
        <EmailTitle title="Random notification title goes here" />
        <p className="my-12 px-6 leading-relaxed text-grey_30">
          Ornare pellentesque tincidunt turpis sem risus semper elementum.
          Bibendum tellus scelerisque tellus eu velit. Sem commodo ullamcorper
          elementum pellentesque ut eu lobortis tortor. Ipsum morbi imperdiet
          bibendum ut parturient elit tellus mi et nisl non. Euismod ut
          adipiscing cursus dolor at
        </p>
        <Button
          variant="contained"
          color="primary"
          sx={{display: "block", width: "218px", height: "60px", mx: "auto"}}
        >
          Corresponding action
        </Button>
      </>
    </EmailLayout>
  );
};

export default GeneralTemplate;
