import BackBtn from "components/BackBtn";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";
import { useGetUser } from "hooks/getUserHook";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetSingleUserQuery as useGetUserForRegisteredUserQuery } from "services/user/providerGeneralApi";
import { useGetSingleUserQuery } from "services/user/userRegApi";
import PersonalInformation from "./widgets/PersonalInformation";
import Reviews from "./widgets/Reviews";
import Services from "./widgets/Services";
import AlignBoxIcon from "components/Vectors/AlignBoxIcon";
import { createElement } from "react";

const ProviderEngagementData = [
  {
    icon: AlignBoxIcon,
    text: "Completed Engagements",
    valueKey: "completed_engagements_count",
  },
  {
    icon: AlignBoxIcon,
    text: "Ongoing Engagements",
    valueKey: "ongoing_engagements_count",
  },
];
const ClientEngagementData = [
  {
    icon: AlignBoxIcon,
    text: "All Jobs",
    valueKey: "jobs_count",
  },
  {
    icon: AlignBoxIcon,
    text: "All Engagements",
    valueKey: "all_engagements_count",
  },
];

const EachClientProvider = () => {
  const { isLoggedIn, user } = useGetUser();
  const { id } = useParams();
  const { data: guestUserData, isLoading, error } = useGetSingleUserQuery(id);
  const { data: userData } = useGetUserForRegisteredUserQuery(id);
  const data = isLoggedIn
    ? user?.admin_permissions
      ? guestUserData
      : userData
    : guestUserData;

  const [searchParams] = useSearchParams();
  const user_type =
    searchParams.get("type")?.toLowerCase() ||
    data?.data?.user_type?.toLowerCase();

  if (isLoading) return <CircularLoader />;
  if (error) return <ErrorMsg error={error} />;

  return (
    <div className={isLoggedIn ? "" : "mt-20"}>
      <BackBtn text={"Back"} />
      <h1 className="mt-6 font-Bai text-4xl font-semibold text-grey_20">
        Profile Details
      </h1>
      <div className="mt-6 grid grid-cols-1 gap-5 md:grid-cols-2">
        <div className="flex flex-col gap-7">
          <PersonalInformation data={{ ...data?.data, user_type }} />
          {user_type !== "client" && (
            <Reviews reviews={data?.data?.ratings_received} />
          )}
        </div>

        <div className="flex flex-col gap-7">
          <div className="grid grid-cols-1 gap-4 rounded-20 bg-white p-4 md:grid-cols-2">
            {(user_type !== "client"
              ? ProviderEngagementData
              : ClientEngagementData
            ).map(({ icon, text, valueKey }, i) => (
              <div key={text + i} className="flex flex-col gap-5">
                <div className="min-h-[170px] rounded-2xl border bg-[#F2F4F7] bg[#FDF3E6] p-4">
                  <div className="flex w-full flex-col gap-4">
                    <div className="mt-3 flex flex-1 gap-4">
                      <div className="flex h-[52px] w-[52px] items-center justify-center rounded-[10px] bg-white">
                        {createElement(icon, { color: "#e98100" })}
                      </div>
                      <p className="mb-7 text-base font-normal text-grey_20">
                        {text}
                      </p>
                    </div>
                    <p className="font-Bai text-3xl font-semibold text-grey">
                      {data?.data[valueKey] || 0}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {user_type === "client" ? (
            <Reviews reviews={data?.data?.ratings_received} />
          ) : (
            <Services services={data?.data?.services || []} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EachClientProvider;
