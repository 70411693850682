const UserMinusIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "14"}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6673 3.66675H14.6673M10.6673 13.0001V12.2001C10.6673 11.08 10.6673 10.5199 10.4493 10.0921C10.2576 9.71578 9.95162 9.40981 9.5753 9.21807C9.14748 9.00008 8.58742 9.00008 7.46732 9.00008H4.53398C3.41388 9.00008 2.85383 9.00008 2.426 9.21807C2.04968 9.40981 1.74372 9.71578 1.55197 10.0921C1.33398 10.5199 1.33398 11.08 1.33398 12.2001V13.0001M8.33398 4.00008C8.33398 5.28875 7.28932 6.33341 6.00065 6.33341C4.71199 6.33341 3.66732 5.28875 3.66732 4.00008C3.66732 2.71142 4.71199 1.66675 6.00065 1.66675C7.28932 1.66675 8.33398 2.71142 8.33398 4.00008Z"
        stroke={color || "#6E726E"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserMinusIcon;
