const ImageFolderIcon = (props) => {
  return (
    <svg
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.3327 7.66732H7.99935C6.58486 7.66732 5.22831 8.22922 4.22811 9.22941C3.22792 10.2296 2.66602 11.5862 2.66602 13.0007V39.6673M2.66602 39.6673V45.0007C2.66602 46.4151 3.22792 47.7717 4.22811 48.7719C5.22831 49.7721 6.58486 50.334 7.99935 50.334H39.9994C41.4138 50.334 42.7704 49.7721 43.7706 48.7719C44.7708 47.7717 45.3327 46.4151 45.3327 45.0007V34.334M2.66602 39.6673L14.8954 27.438C15.8955 26.4381 17.2518 25.8765 18.666 25.8765C20.0802 25.8765 21.4365 26.4381 22.4367 27.438L29.3327 34.334M45.3327 23.6673V34.334M45.3327 34.334L41.1033 30.1047C40.1032 29.1048 38.7469 28.5431 37.3327 28.5431C35.9185 28.5431 34.5622 29.1048 33.562 30.1047L29.3327 34.334M29.3327 34.334L34.666 39.6673M39.9994 7.66732H50.666M45.3327 2.33398V13.0007M29.3327 18.334H29.3594"
        stroke={props.color || "#E98100"}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ImageFolderIcon;
