function TransactionIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 20.3334C13.6133 20.3334 11.6667 18.3867 11.6667 16.0001C11.6667 13.6134 13.6133 11.6667 16 11.6667C18.3867 11.6667 20.3333 13.6134 20.3333 16.0001C20.3333 18.3867 18.3867 20.3334 16 20.3334ZM16 13.6667C14.72 13.6667 13.6667 14.7201 13.6667 16.0001C13.6667 17.2801 14.72 18.3334 16 18.3334C17.28 18.3334 18.3333 17.2801 18.3333 16.0001C18.3333 14.7201 17.28 13.6667 16 13.6667Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M24.6667 20.3334C24.12 20.3334 23.6667 19.8801 23.6667 19.3334V12.6667C23.6667 12.1201 24.12 11.6667 24.6667 11.6667C25.2133 11.6667 25.6667 12.1201 25.6667 12.6667V19.3334C25.6667 19.8801 25.2133 20.3334 24.6667 20.3334Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M6.66668 30.3334C4.45334 30.3334 2.37335 29.1601 1.25335 27.2534C0.653347 26.2934 0.333344 25.1601 0.333344 24.0001C0.333344 20.5067 3.17334 17.6667 6.66668 17.6667C10.16 17.6667 13 20.5067 13 24.0001C13 25.1601 12.68 26.2934 12.08 27.2668C10.96 29.1601 8.88001 30.3334 6.66668 30.3334ZM6.66668 19.6667C4.28001 19.6667 2.33334 21.6134 2.33334 24.0001C2.33334 24.7867 2.54668 25.5601 2.96001 26.2267C3.73335 27.5334 5.16001 28.3334 6.66668 28.3334C8.17334 28.3334 9.60001 27.5334 10.3733 26.2401C10.7867 25.5601 11 24.8001 11 24.0001C11 21.6134 9.05334 19.6667 6.66668 19.6667Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M5.9065 26.3202C5.65316 26.3202 5.39983 26.2269 5.19983 26.0269L3.87984 24.7069C3.49318 24.3202 3.49318 23.6801 3.87984 23.2935C4.26651 22.9068 4.90651 22.9068 5.29317 23.2935L5.93319 23.9335L8.06649 21.9601C8.46649 21.5868 9.10649 21.6135 9.47982 22.0135C9.85315 22.4135 9.82652 23.0535 9.42652 23.4268L6.58651 26.0535C6.38651 26.2268 6.1465 26.3202 5.9065 26.3202Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M22.6667 27.6666H11.3333C10.7867 27.6666 10.3333 27.2133 10.3333 26.6666C10.3333 26.1199 10.7867 25.6666 11.3333 25.6666H22.6667C26.48 25.6666 28.3333 23.8133 28.3333 19.9999V11.9999C28.3333 8.18659 26.48 6.33325 22.6667 6.33325H9.33332C5.51999 6.33325 3.66666 8.18659 3.66666 11.9999V20.3999C3.66666 20.9466 3.21332 21.3999 2.66666 21.3999C2.11999 21.3999 1.66666 20.9466 1.66666 20.3999V11.9999C1.66666 7.13325 4.46666 4.33325 9.33332 4.33325H22.6667C27.5333 4.33325 30.3333 7.13325 30.3333 11.9999V19.9999C30.3333 24.8666 27.5333 27.6666 22.6667 27.6666Z"
        fill={props.color || "#8937CE"}
      />
    </svg>
  );
}

export default TransactionIcon;
