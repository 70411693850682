import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import {Chip, IconButton} from "@mui/material";
import {Link} from "react-router-dom";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
const AllClientTab = ({data, filterBy, isLoading, isError, error}) => {
  const headCells = [
    {
      name: "client",
      label: "Client",
      options: {
        customBodyRender: value => (
          <UserDisplay name={value.name} img={value.img} />
        ),
      },
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "location",
      label: "Location",
    },
    {
      name: "engagement",
      label: "Ongoing Engagement",
    },
    {
      name: "all_jobs",
      label: "All Jobs",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: value => (
          <Chip
            sx={{borderRadius: "8px"}}
            color={
              value.toLowerCase() === "active"
                ? "secondaryInverse"
                : "greyBorder"
            }
            label={value}
          />
        ),
      },
    },
    {
      name: "action",
      label: "",
    },
  ];

  const mockData = data?.data
    ?.filter(el =>
      filterBy === "all" ? el.status : el.status.toLowerCase() === filterBy,
    )
    ?.map((item, i) => ({
      client: {
        name: `${item.first_name} ${item.last_name}`,
        searchBy: item?.username,
        img: item?.avatar,
      },
      type: item?.is_hybrid === "Yes" ? "Hybrid" : item.user_type,
      location: item?.location || "N/A",
      engagement: item?.ongoing_engagements_count,
      all_jobs: item?.jobs_count,
      status: item?.status,
      id: `row_${i}`,
      action: (
        <IconButton component={Link} to={`/admin/clients/${item?.id}`}>
          <ArrowCircleRightOutlinedIcon color="primary" />
        </IconButton>
      ),
    }));

  return (
    <MyDataTable
      options={{
        viewColumns: false,
      }}
      scrollX={false}
      data={mockData}
      isLoading={isLoading}
      isError={isError}
      error={error}
      hideChipDot
      headCells={headCells}
      // options={options}
    />
  );
};

export default AllClientTab;
