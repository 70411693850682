import TopDetail from "components/TopDetail";
import {RequestData} from "./widgets/data";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import JobRequestsIcon from "components/Vectors/JobRequestsIcon";
import JobRequestCard from "./widgets/JobRequestCard";
import {useState} from "react";
import {Button} from "@mui/material";

const ClientJobRequest = () => {
  const [itemCount, setItemCount] = useState(6);

  return (
    <>
      <TopDetail details={topDetails} />
      <div className="rounded-24 border border-grey_60 bg-white p-3 md:p-8">
        <h1 className="font-Bai text-[32px] font-semibold text-grey_20">
          All Job Requests
        </h1>

        <div className="my-10 grid grid-cols-1 gap-5 gap-y-7 md:grid-cols-2 lg:grid-cols-3">
          {RequestData.slice(0, itemCount).map(request => (
            <JobRequestCard key={request.id} request={request} />
          ))}
        </div>
        {itemCount + 3 <=
          // .filter(({ status }) => status === btnChoice)
          RequestData.length && (
          <div className="flex items-center justify-center">
            <Button
              onClick={() => setItemCount(count => count + 3)}
              variant="outlined"
              endIcon={<KeyboardArrowDownIcon />}
            >
              See More
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default ClientJobRequest;
const topDetails = [
  {
    title: "All Requests",
    text: 10,
    icon: <JobRequestsIcon height="17" width="18" stroke={"#E98100"} />,
  },
  {
    title: "Pending Request",
    text: 10,
    icon: <JobRequestsIcon stroke="#6E726E" height="17" width="18" />,
  },
  {
    title: "Declined Requests",
    text: 10,
    icon: <JobRequestsIcon stroke="#D92D20" height="17" width="18" />,
  },
];
