import React from 'react'

const Users3 = ({color}) => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3338 15.8369C19.7897 16.5683 21.038 17.742 21.9491 19.2096C22.1295 19.5003 22.2197 19.6456 22.2509 19.8468C22.3143 20.2558 22.0346 20.7585 21.6538 20.9204C21.4663 21 21.2555 21 20.8338 21M16.3338 11.5322C17.8155 10.7959 18.8338 9.26686 18.8338 7.5C18.8338 5.73314 17.8155 4.20411 16.3338 3.46776M14.3338 7.5C14.3338 9.98528 12.3191 12 9.83382 12C7.34854 12 5.33382 9.98528 5.33382 7.5C5.33382 5.01472 7.34854 3 9.83382 3C12.3191 3 14.3338 5.01472 14.3338 7.5ZM2.89305 18.9383C4.48736 16.5446 7.00319 15 9.83382 15C12.6644 15 15.1803 16.5446 16.7746 18.9383C17.1239 19.4628 17.2985 19.725 17.2784 20.0599C17.2627 20.3207 17.0918 20.64 16.8834 20.7976C16.6157 21 16.2477 21 15.5115 21H4.15618C3.41999 21 3.0519 21 2.78426 20.7976C2.57587 20.64 2.40491 20.3207 2.38925 20.0599C2.36915 19.725 2.54378 19.4628 2.89305 18.9383Z" stroke={color||"#5010B3"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Users3