import {useState} from "react";
import EachRequestCard from "./EachRequestCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Button} from "@mui/material";
import EmptyResponse from "components/mui-components/EmptyResponse";
import ShoppingBagIcon from "components/Vectors/shopping-bag";
import RequestModal from "components/RequestModal";
import {LoadingButton} from "@mui/lab";

const JobRequestContent = ({
  data,
  isAdmin = false,
  status,
  handleAddToMarketplace = () => {},
  isLoading,
  creatorId,
}) => {
  const [itemCount, setItemCount] = useState(4);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [requestPayload, setrequestPayload] = useState({
    type: "accept",
    id: "",
    name: "",
    amount_offered: ''
  });

  return (
    <div>
      {data && data?.length > 0 ? (
        <>
          <div className="scrollbar-style mb-10 grid grid-cols-1 gap-8">
            {data?.slice(0, itemCount).map(request => (
              <EachRequestCard
                isAdmin={isAdmin}
                creatorId={creatorId}
                key={`request_${request.id}`}
                detail={request}
                acceptDeclineHandler={({type, id}) => {
                  setShowRequestModal(true);
                  setrequestPayload({
                    type,
                    id,
                    name: request?.user?.first_name,
                    amount_offered: request?.amount_offered
                  });
                }}
              />
            ))}
          </div>
          {itemCount < data.length && (
            <div className="flex items-center justify-center">
              <Button
                onClick={() => setItemCount(count => count + 10)}
                variant="outlined"
                endIcon={<KeyboardArrowDownIcon />}
              >
                See More
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className="flex min-h-[450px] items-center justify-center rounded-20 bg-white p-4">
          <EmptyResponse
            icon={<ShoppingBagIcon />}
            message={
              status.toLowerCase() === "drafted" && !isAdmin
                ? "Post to marketplace to receive offers"
                : "No job offers yet"
            }
            btn={
              status.toLowerCase() === "drafted" &&
              !isAdmin && (
                <LoadingButton
                  loading={isLoading}
                  onClick={handleAddToMarketplace}
                  variant="contained"
                >
                  Post to marketplace
                </LoadingButton>
              )
            }
          />
        </div>
      )}
      <RequestModal
        provider_name={requestPayload?.name}
        type={requestPayload?.type}
        open={showRequestModal}
        close={() => setShowRequestModal(false)}
        id={requestPayload?.id}
        amount_offered={requestPayload?.amount_offered}
      />
    </div>
  );
};
export default JobRequestContent;
