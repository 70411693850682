import {Button} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {useNavigate} from "react-router-dom";
function BackBtn({to, text, ...props}) {
  const navigate = useNavigate();
  return (
    <Button
      color="secondary"
      onClick={e => {
        e.preventDefault();
        navigate(to ? to : -1);
        window.scrollTo({top: 0, behavior: "smooth"});
      }}
      startIcon={
        <div className="ml-1 flex h-[22px] w-[22px] items-center justify-between rounded-full bg-white lg:ml-0">
          <KeyboardArrowLeftIcon fontSize="small" color="#3B3D3B" />
        </div>
      }
      sx={{
        padding: "0 !important",
        // textTransform: "lowercase",
        alignItems: "center",
        width: "fit-content",
        mb: {
          xs: 2,
          md: props.removeMargin ? 0 : 2,
        },
      }}
      {...props}
    >
      <span
        className={`text-lg font-medium ${
          props.textColor ? props.textColor : "text-grey_20"
        }`}
      >
        {text || "back"}
      </span>
    </Button>
  );
}

export default BackBtn;

export function BackBtn2({text, to, ...props}) {
  const navigate = useNavigate();
  return (
    <button
      onClick={e => {
        e.preventDefault();
        navigate(to ? to : -1);
        window.scrollTo({top: 0, behavior: "smooth"});
      }}
      className="flex w-fit items-center gap-1 !rounded-10 !border border-[#C9C9CF] bg-white px-4 py-3 text-sm font-semibold text-grey-10"
    >
      <svg
        width="16"
        height="8"
        viewBox="0 0 16 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.71967 4.53033C0.426777 4.23744 0.426777 3.76256 0.71967 3.46967L3.71967 0.46967C4.01256 0.176777 4.48744 0.176777 4.78033 0.46967C5.07322 0.762563 5.07322 1.23744 4.78033 1.53033L3.06066 3.25L14.75 3.25C15.1642 3.25 15.5 3.58579 15.5 4C15.5 4.41421 15.1642 4.75 14.75 4.75L3.06066 4.75L4.78033 6.46967C5.07322 6.76256 5.07322 7.23744 4.78033 7.53033C4.48744 7.82322 4.01256 7.82322 3.71967 7.53033L0.71967 4.53033Z"
          fill="black"
        />
      </svg>
      {text || "back"}
    </button>
  );
}
