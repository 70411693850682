import AutoCompleteField from "components/forms/AutoCompleteField";
import {memo, useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import {useGetAllStatesQuery} from "services/generalApi";

const StateCityField = memo(
  ({
    stateName = "state_id",
    cityName = "city_id",
    defaultCityId,
    defaultStateId,
    bg,
    setStateCity = () => {},
  }) => {
    const {data, isLoading} = useGetAllStatesQuery();

    const {watch, setValue} = useFormContext();

    let stateVal = String(watch(stateName));

    const [cities, setCities] = useState(
      data?.data
        ?.find(el => String(el.id) === stateVal)
        ?.cities.map(item => ({
          label: item.name,
          value: item.id,
        })),
    );

    useEffect(() => {
      if (data) {
        setCities(
          data?.data
            ?.find(el => String(el.id) === stateVal)
            ?.cities?.map(item => ({
              label: item.name,
              value: item.id,
            })),
        );
      }
    }, [stateVal, data]);

    return (
      <>
        <AutoCompleteField
          loading={isLoading}
          name={stateName}
          defaultValue={defaultStateId}
          selectOption={(data?.data || [])?.map(item => ({
            label: item.name,
            value: item.id,
          }))}
          handleCustomChange={value => {
            setCities(
              data?.data
                ?.find(el => String(el.id) === String(value?.value))
                ?.cities?.map(item => ({
                  label: item.name,
                  value: `${item.id}`,
                })),
            );
            setValue(cityName, "");
            setStateCity(prev => ({...prev, state: value?.label}));
          }}
          label="State"
          bg={bg}
        />
        {cityName && (
          <AutoCompleteField
            loading={isLoading}
            defaultValue={defaultCityId}
            name={cityName}
            selectOption={cities}
            label="City"
            handleCustomChange={value => {
              setStateCity(prev => ({...prev, city: value?.label}));
            }}
            bg={bg}
          />
        )}
      </>
    );
  },
);

export default StateCityField;
