import {FormProvider, useForm} from "react-hook-form";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import SmallNairaIcon from "components/Vectors/SmallNairaIcon";
import {
  useAddPackageMutation,
  useEditPackageMutation,
  useDelPackageMutation,
} from "services/admin/adminGeneralApi";
import {showToast} from "redux/store.hooks";
import {handleError} from "utils";
import {LoadingButton} from "@mui/lab";
import MyModal from "components/mui-components/Modal";
import {useEffect} from "react";
import LoadingBtn from "components/mui-components/LoadingButton";

const AddPackageModal = ({closeModal, openModal, type = "add", data}) => {
  const methods = useForm();

  useEffect(() => {
    if (data) {
      methods.setValue("tokens", data?.tokens);
      methods.setValue("name", data?.name);
      methods.setValue("amount", data?.amount);
    }
  }, [data, methods]);

  const [addPackage, {isLoading}] = useAddPackageMutation();
  const [editPackage, {isLoading: isEditLoading}] = useEditPackageMutation();
  const onSubmit = async body => {
    if (type === "add") {
      try {
        let res = await addPackage(body).unwrap();
        closeModal();
        showToast(res?.message);
      } catch (error) {
        handleError(error);
      }
    } else {
      try {
        let res = await editPackage({body, id: data?.id}).unwrap();
        closeModal();
        showToast(res?.message);
      } catch (error) {
        handleError(error);
      }
    }
  };
  const [delPackage, {isLoading: isDelLoading}] = useDelPackageMutation();

  return (
    <MyModal
      title={`${type === "add" ? "Add a" : "Manage"} Package`}
      openModal={openModal}
      closeModal={closeModal}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="mt-[16px] flex flex-col gap-4 ">
            <ValidatedInput2 name="name" type="text" label="Package Name" />
            <ValidatedInput2
              name="tokens"
              label="Number of tokens"
              type="number"
            />
            <ValidatedInput2
              prefixIcon={<SmallNairaIcon />}
              name="amount"
              label="Amount"
              type="number"
            />
            <div className="mt-[50px]">
              <LoadingBtn
                type="submit"
                variant="contained"
                fullWidth
                loading={isLoading || isEditLoading}
              >
                Save
              </LoadingBtn>
            </div>
          </div>
        </form>
      </FormProvider>
    </MyModal>
  );
};
export default AddPackageModal;
