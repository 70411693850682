import {useGetFavoriteServiceProvidersQuery} from "services/user/clientGeneralApi";
import ServiceProviderCard from "components/ServiceProviderCard";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";

const FavoriteServiceProviders = () => {
  const {data, isLoading, error} = useGetFavoriteServiceProvidersQuery();

  return (
    <section>
      <h1 className="font-h1">Favorites</h1>

      <div className="mt-[28px]">
        <div className="mt-8 flex w-full flex-col p-2 md:flex-row md:items-start md:justify-start md:gap-10 md:p-0">
          <div className="w-full rounded-[24px] bg-[#fff] px-4 py-5 lg:px-[40px] lg:py-[35px]">
            {isLoading ? (
              <CircularLoader />
            ) : error ? (
              <ErrorMsg error={error} />
            ) : (
              <>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
                  {data?.data.map(item => (
                    <ServiceProviderCard data={item} />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default FavoriteServiceProviders;
