import {useFormContext} from "react-hook-form";
import PhysicalComponent from "./PhysicalComponent";
import VirtualComponent from "./VirtualComponent";
import Avatar from "@mui/material/Avatar";
// import RadioBtns from "components/forms/RadioBtns";
import RadioBtns2 from "components/forms/RadioBtns2";
import AutoCompleteField from "components/forms/AutoCompleteField";
import ValidatedInput2 from "components/forms/ValidatedInput2";

const JobTimeAndLocation = ({locationType = "Physical", setStateCity}) => {
  const {watch} = useFormContext({
    mode: "all",
  });
  const img = watch("image");
  const category = watch("category");
  const title = watch("job_title");
  // console.log(locationType);
  return (
    <div className="bg-[#f5f5f5] md:min-w-[500px]">
      {img && img.length > 0 && (
        <>
          <div className="flex items-center gap-x-6 rounded-24 bg-white p-[18px]">
            <Avatar
              src={
                typeof img[0] === "string"
                  ? img[0]
                  : URL.createObjectURL(img[0]) || ""
              }
              alt={title}
              sx={{borderRadius: "12px", height: "80px", width: "80px"}}
            />
            <div className="flex flex-col">
              <span className="text-sm text-primary">{category}</span>
              <span className="font-Bai text-xl font-semibold text-ltext">
                {title}
              </span>
            </div>
          </div>
          <div className="h-10 bg-[#f5f5f5]"></div>
        </>
      )}
      <section className="rounded-t-24 bg-white">
        <h1 className="p-3 font-Bai text-2xl font-bold md:p-8 md:pb-5 md:text-[2rem]">
          Duration & Location
        </h1>
        <div className="h-px bg-grey_60"></div>{" "}
        <div className="flex flex-col gap-y-5"></div>
        <div className="p-3 md:p-8">
          <div className={` items-center} mb-7 flex justify-between gap-x-3`}>
            <ValidatedInput2
              name="duration"
              label="Job Duration"
              placeholder="Enter Duration"
              type="number"
              inputMode="numeric"
              // suffixComponent={
              //   <div className="!max-w-[86px] !max-h[44px] !ml-auto">
              //     <AutoCompleteField
              //       name="duration_type"
              //       bg="white"
              //       selectOption={["Days", "Weeks", "Months"]}
              //       // label="Duration Type"
              //       bordered={false}
              //       placeholder="Days"
              //       required={true}
              //     />
              //   </div>
              // }
              rules={{
                valueAsNumber: true,
                pattern: {
                  value: /^[1-9]\d*/,
                  message: "Number must be greater than 0",
                },
              }}
            />
            <div className="basis-[30%]">
              <AutoCompleteField
                name="duration_type"
                selectOption={["Days", "Weeks", "Months"]}
                label="Duration Type"
                bordered={true}
                placeholder="e.g Weeks"
                required={true}
              />
            </div>
          </div>
          <RadioBtns2
            containerClass=""
            name="location_type"
            wrapperClass="grid-cols-1 md:grid-cols-3"
            options={["Physical", "Virtual"]}
            labelClassname="!font-Bai !font-semibold !text-lg !text-grey_20"
            label="Location"
          />

          <div className="mt-6 flex items-center gap-2 rounded-2xl bg-[#F3ECFD] px-3 py-2.5 text-xs font-medium text-[#1C063F]">
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="44" height="44" rx="22" fill="white" />
              <path
                d="M22.0001 17.0003C22.4603 17.0003 22.8334 17.3734 22.8334 17.8337V22.8337C22.8334 23.2939 22.4603 23.667 22.0001 23.667C21.5398 23.667 21.1667 23.2939 21.1667 22.8337V17.8337C21.1667 17.3734 21.5398 17.0003 22.0001 17.0003Z"
                fill="#440E98"
              />
              <path
                d="M20.9584 25.9587C20.9584 26.534 21.4248 27.0003 22.0001 27.0003C22.5754 27.0003 23.0417 26.534 23.0417 25.9587C23.0417 25.3834 22.5754 24.917 22.0001 24.917C21.4248 24.917 20.9584 25.3834 20.9584 25.9587Z"
                fill="#440E98"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.6667 22.0003C13.6667 17.398 17.3977 13.667 22.0001 13.667C26.6025 13.667 30.3334 17.398 30.3334 22.0003C30.3334 26.6027 26.6025 30.3337 22.0001 30.3337C17.3977 30.3337 13.6667 26.6027 13.6667 22.0003ZM22.0001 15.3337C18.3182 15.3337 15.3334 18.3184 15.3334 22.0003C15.3334 25.6822 18.3182 28.667 22.0001 28.667C25.682 28.667 28.6667 25.6822 28.6667 22.0003C28.6667 18.3184 25.682 15.3337 22.0001 15.3337Z"
                fill="#440E98"
              />
            </svg>

            {locationType === "Virtual"
              ? "Kindly be informed that this task will be executed exclusively online."
              : "The job will be done and delivered in person"}
          </div>
          {locationType === "Physical" ? (
            <PhysicalComponent setStateCity={setStateCity} />
          ) : locationType === "Virtual" ? // <VirtualComponent />
          null : null}
        </div>
      </section>
    </div>
  );
};

export default JobTimeAndLocation;
