import React from "react";

function CheckIcon2(props) {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.0007 29.0002L25.0007 37.0002L41.0007 21.0002M55.6673 29.0002C55.6673 43.7278 43.7282 55.6668 29.0007 55.6668C14.2731 55.6668 2.33398 43.7278 2.33398 29.0002C2.33398 14.2726 14.2731 2.3335 29.0007 2.3335C43.7282 2.3335 55.6673 14.2726 55.6673 29.0002Z"
        stroke={props.color || "#E98100"}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckIcon2;
