import {Link} from "react-router-dom";
import {ReactComponent as Logo} from "assets/images/logo.svg";
import {Button} from "@mui/material";
import PlayStoreBlue from "components/Vectors/PlayStoreBlue";
import AppleIcon from "@mui/icons-material/Apple";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

const EmailLayout = ({children}) => {
  return (
    <div className="mx-auto my-12 flex w-10/12 flex-col items-center bg-grey_80 py-16">
      <Link to="/" className="mb-12">
        <Logo />
      </Link>
      <div className="mx-auto w-8/12 max-w-3xl rounded-24 bg-white p-6">
        {children}
        <div className="mt-12 flex h-[20rem] flex-col items-center justify-evenly rounded-24 bg-grey_90">
          <div className="mx-auto grid w-2/3 grid-cols-2 justify-center gap-5">
            <Button
              startIcon={<AppleIcon sx={{color: "#5010B3"}} />}
              variant="contained"
              sx={{
                color: "#5010B3",
                bgcolor: "#CFB5F933",
                "&:hover": {
                  color: "#5010B3",
                  bgcolor: "#CFB5F933",
                  opacity: "0.8",
                },
                ".MuiSvgIcon-root": {
                  fontSize: "2.5rem !important",
                },
              }}
            >
              <span className="flex flex-col">
                <span className="font-Bai text-sm">Download on the</span>
                <span className="font-Bai text-lg font-bold">App Store</span>
              </span>
            </Button>

            <Button
              startIcon={<PlayStoreBlue />}
              variant="contained"
              sx={{
                color: "#5010B3",
                bgcolor: "#CFB5F933",
                "&:hover": {
                  color: "#5010B3",
                  bgcolor: "#CFB5F933",
                  opacity: "0.8",
                },
              }}
            >
              <span className="flex flex-col">
                <span className="font-Bai text-sm uppercase">GET IT ON</span>
                <span className="font-Bai text-lg font-bold">Google Play</span>
              </span>
            </Button>
          </div>
          <p className="text-grey_40">
            Any Questions? Contact us at{" "}
            <a
              href="mailto:hello@truelocal.com"
              className="text-primary underline"
            >
              hello@truelocal.com
            </a>
          </p>
          <div className="mx-auto grid w-8/12 grid-cols-5 justify-around">
            <a
              href="https://www.linkedin.com"
              className="flex h-[46px] w-[46px] items-center  justify-center rounded-full bg-[#F3EEFC]"
            >
              <LinkedInIcon sx={{color: "#5010B3"}} />
            </a>

            <a
              href="https://www.twitter.com"
              className="flex h-[46px] w-[46px] items-center  justify-center rounded-full bg-[#F3EEFC]"
            >
              <TwitterIcon sx={{color: "#5010B3"}} />
            </a>

            <a
              href="https://www.facebook.com"
              className="flex h-[46px] w-[46px] items-center  justify-center rounded-full bg-[#F3EEFC]"
            >
              <FacebookRoundedIcon sx={{color: "#5010B3"}} />
            </a>
            <a
              href="https://www.instagram.com"
              className="flex h-[46px] w-[46px] items-center  justify-center rounded-full bg-[#F3EEFC]"
            >
              <InstagramIcon sx={{color: "#5010B3"}} />
            </a>
            <a
              href="https://www.youtube.com"
              className="flex h-[46px] w-[46px] items-center  justify-center rounded-full bg-[#F3EEFC]"
            >
              <YouTubeIcon sx={{color: "#5010B3"}} />
            </a>
          </div>
          <p className="text-sm text-grey_40">
            Truelocal Inc., the address if any.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailLayout;
