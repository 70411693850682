import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import MainAdminDashboard from "./MainAdminDashboard";
import {Avatar} from "@mui/material";
import {useGetUser} from "hooks/getUserHook";

function AdminDashboard() {
  const {user} = useGetUser();

  const isSuperAdmin = user?.roles.some(item =>
    item.name.toLowerCase().includes("super admin"),
  );

  return (
    <DashboardLayout title={"Dashboard"} type="admin">
      <main>
        {isSuperAdmin ? (
          <MainAdminDashboard />
        ) : (
          <div
            className="flex min-h-[400px] flex-col items-center
           justify-center rounded-2xl border border-grey_60 bg-white"
          >
            <Avatar
              sx={{height: "200px", width: "200px"}}
              src={user?.photo_url || user?.profile_picture || ""}
              alt={user?.first_name || "img"}
            />
            <h1 className="my-2 font-Bai text-[32px] font-semibold capitalize text-black">
              <span className="text-grey_30">Hello, </span>
              {user?.first_name} {user?.last_name}
            </h1>
            <p className="rounded-[6px] bg-grey_80 px-3 py-2 text-[13px] font-medium text-grey_30">
              {user?.roles.length > 0 ? user?.roles[0].name || "N/A" : "N/A"}
            </p>
          </div>
        )}
      </main>
    </DashboardLayout>
  );
}

export default AdminDashboard;
