import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import {IconButton} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import UserCircleIcon from "components/Vectors/user-circle";

const ServiceProviderTab = ({data, isLoading, isError, error}) => {
  const navigate = useNavigate();
  const headCells = [
    {
      name: "client",
      label: "Client",
      options: {
        filter: true,
        customBodyRender: value => (
          <UserDisplay
            name={value.name}
            img={value.img}
            size="text-[1rem]"
            badge={value.badge}
          />
        ),
      },
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "top-vetted",
      label: "Top vetted",
      options: {
        customBodyRender: value => <span className="capitalize">{value}</span>,
      },
    },
    {
      name: "location",
      label: "Location",
    },
    {
      name: "engagement",
      label: "Ongoing Engagement",
    },
    {
      name: "services",
      label: "All Services",
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        customBodyRender: value => (
          <span
            className={`rounded-lg px-3 py-1.5 capitalize ${
              value.toLowerCase() === "active"
                ? "bg-[#5010b319] text-[#5010B3] "
                : "bg-grey_70 text-[#3B3D3B]"
            }`}
          >
            {value}
          </span>
        ),
      },
    },
    {
      name: "action",
      label: "",
      options: {
        customBodyRender: value => (
          <IconButton onClick={() => navigate(`${value}`)}>
            <ArrowCircleRightOutlinedIcon
              sx={{
                color: "#E98100",
              }}
            />
          </IconButton>
        ),
      },
    },
  ];
  const mockData = data?.map(value => ({
    client: {
      name: `${
        value.professional_information?.business_name || value.full_name || "-"
      }`,
      searchBy: value?.username,
      img: `${value.professional_information?.business_logo || value.avatar}`,
      badge: value?.is_verified_account,
    },
    type: `${value.is_hybrid === "Yes" ? "Hybrid" : "SP"}`,
    "top-vetted": `${value?.is_top_vetted ? "Yes" : "No"}`,
    location: `${value.location || "N/A"}`,
    engagement: value?.ongoing_engagements_count,
    services: value?.services_count,
    status: value?.status,
    action: `${value?.id}`,
    id: `row_${value?.id}`,
  }));

  return (
    <MyDataTable
      options={{
        viewColumns: false,
      }}
      headCells={headCells}
      data={mockData}
      isError={isError}
      error={error}
      scrollX={false}
      isLoading={isLoading}
      emptyData={{
        icon: UserCircleIcon,
        message: "No Service Provider Created",
        iconColor: "#3B3D3B",
      }}
    />
  );
};

export default ServiceProviderTab;
