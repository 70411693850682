import JobIcon from "components/Vectors/JobIcon";
import Wallet from "components/Vectors/Wallet";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useNavigate } from "react-router-dom/dist";
import { IconButton } from "@mui/material";
import { useGetUser } from "hooks/getUserHook";
import { filterObj, formatCurrency } from "utils";
import ProtectedBtn from "components/ProtectedBtn";

const Header = ({ isProfessional, onBoardingStatus }) => {
  const { user } = useGetUser();
  // filter status based on user_type i.e clients should not have professional info and services
  const UserOnboardingStatus = filterObj(onBoardingStatus || {}, ([key]) =>
    user?.user_type === "Client"
      ? key !== "ProfessionalInformation" && key !== "Services"
      : key,
  );
  const navigate = useNavigate();
  const skippedSteps = Object.keys(UserOnboardingStatus || {}).filter(
    step => UserOnboardingStatus[step] === false,
  );
  const objMap = {
    BasicInformation: "Basic Information",
    ProfessionalInformation: "Professional Information",
    Kyc: "KYC",
    BankAccount: "Account details",
    CacCertificate: "CAC certificate",
  };
  const isComplete = Object.values(UserOnboardingStatus || {})?.every(
    value => value,
  );

  return (
    <header className="flex flex-col items-start justify-between gap-4 p-4 md:flex-row md:items-center md:py-6 md:px-10">
      <div className="first_step flex flex-col first_step">
        <h1 className="font-Bai text-2xl font-semibold text-grey_20 md:text-[32px] ">
          Dashboard
        </h1>
        <p className="font-Bai font-medium text-grey_30">
          Welcome,{" "}
          {isProfessional
            ? user?.professional_information?.business_name || user?.full_name
            : user?.full_name}
          . You are logged as a
          <span className="text-grey_10"> {user?.user_type}.</span>
        </p>
      </div>
      {!isComplete ? (
        <div className="flex items-center justify-between gap-8 rounded-[24px] border-[2px] border-dashed border-primary p-[20px] ">
          <div className="flex items-center gap-3 ">
            <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#E98100] ">
              <JobIcon color="#fff" height={20} width={16} />
            </div>
            <div className="">
              <p className="font-Bai text-lg font-medium text-black md:text-[22px] ">
                Finish setting up your profile
              </p>
              <span className="text-[14px] font-[400] text-grey_30">
                {skippedSteps.filter(el => el !== "Services").length > 0
                  ? skippedSteps
                    .filter(el => el !== "Services")
                    .map((step, index) => (
                      <span key={index}>
                        {objMap[step]}
                        {index === skippedSteps.length - 1 ? "." : ", "}
                      </span>
                    ))
                  : "Add Service and expertise"}
              </span>
            </div>
          </div>
          <IconButton
            onClick={() =>
              navigate(
                skippedSteps.filter(el => el !== "Services").length > 0
                  ? `settings?tab=kyc`
                  : `account?tab=services`,
              )
            }
          >
            <ChevronRightRoundedIcon
              color="primary"
              sx={{
                height: "25px",
                width: "25px",
              }}
            />
          </IconButton>
        </div>
      ) : isProfessional ? (
        <>
          <button
            className="hidden md:flex items-center rounded-20 border bg-grey_70 px-3 md:border-none md:px-6"
            type="button"
            onClick={() => navigate("transactions")}
          >
            <div className="py-2.5 pr-1.5 md:py-5 md:pr-3">
              <Wallet />
            </div>
            <div className="flex flex-col border-l-[3px] border-l-white py-2.5 pl-1.5 hover:border-l-[3px] md:py-5 md:pl-3">
              <span className="font-Inter text-xs text-black_text2 md:text-base">
                Wallet Balance
              </span>
              <span className="font-Bai text-base font-semibold text-ltext md:text-xl">
                {formatCurrency(user?.wallet)}
              </span>
            </div>
            </button>
            
          <div className="flex md:hidden w-full justify-between items-center">
            <button
              className="flex items-center rounded-20 border bg-grey_70 px-3 md:border-none md:px-6"
              type="button"
              onClick={() => navigate("transactions")}
            >
              <div className="py-2.5 pr-1.5 md:py-5 md:pr-3">
                <Wallet />
              </div>
              <div className="flex flex-col border-l-[3px] border-l-white py-2.5 pl-1.5 hover:border-l-[3px] md:py-5 md:pl-3">
                <span className="font-Inter text-xs text-black_text2 md:text-base">
                  Wallet Balance
                </span>
                <span className="font-Bai text-base font-semibold text-ltext md:text-xl">
                  {formatCurrency(user?.wallet)}
                </span>
              </div>
            </button>

            <div>
              <ProtectedBtn
                isProfessional={true}
                link="/service-provider/marketplace"
                btnText="Discover Jobs"
              />
            </div>
          </div>
        </>
      ) : null}
    </header>
  );
};

export default Header;
