import NewAuthLayout from "templates/AuthLayout/NewAuthLayout";
import {Link, useNavigate} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import {FormProvider, useForm} from "react-hook-form";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import {useAdminLoginMutation} from "services/auth.api";
import {handleError} from "utils";
import {useDispatch} from "react-redux";
import {setUserDetails, setUserToken} from "redux/slice/AuthSlice";

const AdminLogin = () => {
  const navigate = useNavigate();
  const methods = useForm();
  const dispatch = useDispatch();
  const [login, {isLoading}] = useAdminLoginMutation();

  const onSubmit = async data => {
    try {
      let res = await login(data).unwrap();
      dispatch(setUserToken(res?.data?.access_token));
      dispatch(setUserDetails(res?.data));
      navigate("/admin");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <NewAuthLayout>
      <div className="input_area">
        <h3 className="text-center font-Bai text-2xl font-semibold text-[#362E1F] md:text-4xl">
          Login To Dashboard
        </h3>
        <FormProvider {...methods}>
          <form
            className="flex w-full flex-col gap-3 pt-6"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <ValidatedInput2
              textSize="text-sm"
              label="Email address"
              type="email"
              name="email"
              placeholder="Enter email"
            />
            <ValidatedInput2
              textSize="text-sm"
              label="Password"
              type="password"
              name="password"
              placeholder="*******"
            />

            <div className="mt-[-1px] flex w-full justify-end text-base font-normal">
              <Link className="text-[#F6A001]" to="/admin/reset-password">
                Forgot Password?
              </Link>
            </div>
            <LoadingButton
              sx={{mt: 4}}
              type="submit"
              loading={isLoading}
              fullWidth
              variant="contained"
              color="primary"
            >
              Login
            </LoadingButton>
          </form>
        </FormProvider>
      </div>
    </NewAuthLayout>
  );
};

export default AdminLogin;
