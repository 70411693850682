import BackBtn from "components/BackBtn";
import DropDownWrapper from "components/mui-components/DropDownWrapper";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetSingleJobQuery,
  useSuspendJobMutation,
} from "services/admin/adminGeneralApi";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import JobOverview from "./Components/JobOverview";
import RequestOffers from "pages/Admin/Dashboard/Jobs/Components/RequestOffers";
import SuspendJobModal from "./Modals/SuspendJobModal";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import LoadingBtn from "components/mui-components/LoadingButton";
import { showToast } from "redux/store.hooks";
import { handleError } from "utils";
import { Avatar, Chip } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayOutlined";
import { JobsStatusColor } from "utils/constants";

const EachAdminJob = () => {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const { data, isLoading, error, isError } = useGetSingleJobQuery(id);
  const jobData = data?.data;

  const [unSuspendJob, { isLoading: isUnsuspendingJob }] =
    useSuspendJobMutation();
  const handleUnSuspendJob = () => {
    unSuspendJob({
      id,
      body: {
        status: "Active",
      },
    })
      .unwrap()
      .then(res => {
        showToast(res?.message, "success", 6000);
      })
      .catch(err => {
        handleError(err);
      });
  };
  const status = jobData?.status?.toLowerCase();

  return (
    <>
      <DashboardLayout
        isLoading={isLoading}
        isError={isError}
        error={error}
        title="Jobs"
        type="admin"
      >
        <section className="mt-2">
          <BackBtn text="All Jobs" />
          <div className="rounded-2xl bg-white p-4 md:p-8">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-4">
                <Avatar
                  src={
                    jobData?.images.length > 0
                      ? jobData?.images[0]?.image_url
                      : ""
                  }
                  sx={{ height: "75px", width: "75px", borderRadius: "14px" }}
                />
                <div className="flex flex-col gap-y-2">
                  <h2 className="settings-font-h2">{jobData?.job_title}</h2>
                  <div className="flex gap-3 items-center">
                    <Chip
                      label={jobData?.status?.replace("Active", "Open")}
                      sx={{ borderRadius: "8px" }}
                      size="small"
                      color={JobsStatusColor[jobData?.status?.toLowerCase()]}
                    />
                    <Chip
                      label={`Expected Timeline: ${jobData?.duration} ${jobData?.duration === 1
                        ? jobData?.duration_type.slice(0, -1)
                        : jobData?.duration_type
                        } `}
                      avatar={<CalendarTodayIcon />}
                      size="small"
                      sx={{ borderRadius: "8px" }}
                    />
                  </div>
                  {/* <p className="flex items-center gap-1 text-grey_30">
                    <CalendarTodayIcon
                      fontSize="small"
                      sx={{ color: "#3B3D3B" }}
                    />
                    <span>
                      {jobData?.duration}{" "}
                      {jobData?.duration === 1
                        ? jobData?.duration_type.slice(0, -1)
                        : jobData?.duration_type}
                    </span>
                  </p> */}
                </div>
              </div>
              {jobData?.status !== "Closed" && (
                <DropDownWrapper
                  contentPadding="px-0"
                  className="more-actions"
                  closeOnBtnClick={false}
                  position="right"
                  border="dropdown-boxShadow border border-[#E4E7EC] !rounded-16"
                  action={
                    <button
                      type="button"
                      className="flex h-[52px] w-[52px] items-center justify-center rounded-16 bg-[#F2F4F7]"
                    >
                      <MoreHorizOutlinedIcon color="primary" fontSize="large" />
                    </button>
                  }
                >
                  <div className="min-w-[150px]">
                    <LoadingBtn
                      loading={isUnsuspendingJob}
                      fullWidth
                      variant="text"
                      sx={{ color: "#6E726E" }}
                      startIcon={<DoNotDisturbIcon />}
                      onClick={() => {
                        if (status === "suspended") {
                          handleUnSuspendJob();
                        } else {
                          setOpenModal(true);
                        }
                      }}
                    >
                      {status === "suspended" ? "Unsuspend Job" : "Suspend Job"}
                    </LoadingBtn>
                  </div>
                </DropDownWrapper>
              )}
            </div>

            <div className="mt-6 grid grid-cols-1 gap-[24px] md:grid-cols-2">
              <div className="flex flex-col gap-6">
                <JobOverview data={jobData} />
              </div>
              <div className="flex flex-col gap-6">
                <RequestOffers isAdmin={true} jobData={jobData} />
              </div>
            </div>
          </div>
        </section>
      </DashboardLayout>
      <SuspendJobModal
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </>
  );
};
export default EachAdminJob;
export const formatDate = (startDate, duration, durationType) => {
  const endDate = startDate.clone().add(duration, durationType);
  const formattedStartDate = startDate.format("Do MMM YYYY");
  const formattedEndDate = endDate.format("Do MMM YYYY");
  return [formattedStartDate, formattedEndDate];
};
