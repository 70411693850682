import {Avatar, Chip, IconButton} from "@mui/material";
import BackBtn from "components/BackBtn";
import {useState} from "react";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import StarIcon from "@mui/icons-material/Star";
import Overviews from "./components/Overviews";
import KYC from "components/KYC";
import EngagementBody from "./components/EngagementBody";
import SuspendAccountModal from "./Modals/SuspendAccountModal";
import GiveTokenModal from "./Modals/GiveTokenModal";
import {
  useActivateClientAccountMutation,
  useGetSingleClientQuery,
} from "services/admin/adminGeneralApi";
import {useParams} from "react-router-dom";
import {getUserDetails, handleError} from "utils";
import DropDownWrapper from "components/mui-components/DropDownWrapper";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import {StatusChip} from "../Settings/SingleCategoryPage";
import {showToast} from "redux/store.hooks";
import {LoadingButton} from "@mui/lab";
import {SuspendUserBtn} from "../ServiceProviders/EachServiceProvider";

function EachClient() {
  const [openModal, setOpenModal] = useState(false);
  const {id} = useParams();

  const [showGiveTokenModal, setShowGiveTokenModal] = useState(false);
  const {data, isLoading, error} = useGetSingleClientQuery(id);
  const [activateAccount, {isLoading: isActivating}] =
    useActivateClientAccountMutation();

  const client = data?.data;
  return (
    <DashboardLayout
      isLoading={isLoading}
      error={error}
      title="Clients"
      type="admin"
    >
      <main className="mt-8">
        <BackBtn to="/admin/clients" text="All Clients" />
        <div className="rounded-2xl bg-white p-4 md:p-8">
          <div className="flex flex-col justify-between md:flex-row md:items-start">
            <div className="relative flex flex-col gap-4 md:flex-row">
              <div className="relative h-[60px] w-[60px]  md:h-[120px] md:w-[120px]">
                <Avatar
                  src={client?.avatar}
                  sx={{
                    width: {xs: "60px", md: "120px"},
                    height: {xs: "60px", md: "120px"},
                  }}
                />
              </div>
              <div className="mt-3">
                <div className="flex items-center gap-x-2.5">
                  <h3 className="mb-2 font-Bai text-[32px] font-semibold text-black">
                    {getUserDetails(client).name}
                  </h3>
                </div>
                <p className="font-Bai text-lg font-medium text-grey_20">
                  @{client?.username || "N/A"}
                </p>
                <div className="mt-4 flex items-center gap-3">
                  <Chip
                    sx={{borderRadius: "8px"}}
                    color={StatusChip[client?.status?.toLowerCase()]}
                    label={client?.status}
                  />
                  <p className="text-sm">
                    <span className="text-grey_30">Date Registered:</span>{" "}
                    <span className="text-grey">
                      {moment(client?.created_at).format("DD MMM, YYYY")}
                    </span>
                  </p>
                </div>

                <div className="mt-8 flex items-center justify-between">
                  <div className="flex gap-4">
                    <div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-secondary-10">
                      <StarIcon color="white" />
                    </div>
                    <div className="mt-1">
                      <h3 className="font-Bai text-3xl font-medium text-black">
                        {client?.average_rating?.toFixed(1)}
                      </h3>
                      <p className="text-sm font-normal text-grey_30">Rating</p>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-secondary-10">
                      <ChatBubbleIcon color="white" />
                    </div>
                    <div className="mt-1">
                      <h3 className="font-Bai text-3xl font-medium text-black">
                        {client?.ratings_received_count}
                      </h3>
                      <p className="text-sm font-normal text-grey_30">
                        Reviews
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 flex items-center gap-2">
              {!client?.email_verified_at && (
                <LoadingButton
                  onClick={() => {
                    activateAccount(id)
                      .unwrap()
                      .then(res => {
                        showToast(res?.message);
                      })
                      .catch(err => {
                        handleError(err);
                      });
                  }}
                  variant="outlined"
                  sx={{fontSize: "16px", mt: "0px"}}
                  loading={isActivating}
                >
                  Activate Account
                </LoadingButton>
              )}
              <DropDownWrapper
                position="right"
                contentPadding="px-0"
                closeOnBtnClick={false}
                action={
                  <IconButton
                    size="large"
                    sx={{
                      height: "54.2px",
                      width: "54.2px",
                      bgcolor: "#F2F4F7",
                      borderRadius: "16px",
                      "&:hover": {
                        bgcolor: "#F2F4F7",
                      },
                    }}
                  >
                    <MoreHoriz color="primary" width={24} height={24} />
                  </IconButton>
                }
              >
                <SuspendUserBtn setopenModal={setOpenModal} user={client} />
              </DropDownWrapper>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-1 gap-5 md:grid-cols-2">
            <div>
              <Overviews user={client} />
            </div>
            <div>
              <KYC isClient={true} data={data?.data?.kyc} />
              <EngagementBody user={data?.data} />
            </div>
          </div>
        </div>
      </main>

      <SuspendAccountModal
        user={client}
        openModal={openModal}
        setopenModal={setOpenModal}
      />
      <GiveTokenModal
        user_id={id}
        open={showGiveTokenModal}
        close={() => setShowGiveTokenModal(false)}
      />
    </DashboardLayout>
  );
}

export default EachClient;
