const HomeIcon2 = ({stroke}) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10.0653C1 9.49094 1 9.20376 1.07403 8.9393C1.1396 8.70503 1.24737 8.48469 1.39203 8.2891C1.55534 8.0683 1.78202 7.89199 2.23539 7.53937L9.0177 2.26424C9.36902 1.99099 9.54468 1.85436 9.73865 1.80184C9.9098 1.7555 10.0902 1.7555 10.2613 1.80184C10.4553 1.85436 10.631 1.99099 10.9823 2.26424L17.7646 7.53937C18.218 7.89199 18.4447 8.0683 18.608 8.2891C18.7526 8.48469 18.8604 8.70503 18.926 8.9393C19 9.20376 19 9.49094 19 10.0653V17.3002C19 18.4203 19 18.9804 18.782 19.4082C18.5903 19.7845 18.2843 20.0905 17.908 20.2822C17.4802 20.5002 16.9201 20.5002 15.8 20.5002H4.2C3.07989 20.5002 2.51984 20.5002 2.09202 20.2822C1.71569 20.0905 1.40973 19.7845 1.21799 19.4082C1 18.9804 1 18.4203 1 17.3002V10.0653Z"
        stroke={stroke || "#3B3D3B"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon2;
