import {Chip} from "@mui/material";
import MyModal from "components/mui-components/Modal";
import moment from "moment";
import {useGetAllBanksQuery} from "services/generalApi";
import {toCurrency} from "utils";
import {statusColor} from "./Earning";

const TransactionDetailModal = ({open, close, transaction}) => {
  const {data: allBanks} = useGetAllBanksQuery();
  const bankName = allBanks?.data?.find(
    bank => bank.code === transaction?.bank_account?.bank_code,
  )?.name;
  return (
    <MyModal openModal={open} closeModal={close} title="Transaction Details">
      <div className="mb-auto flex flex-col items-center justify-center">
        <p className="font-Bai font-semibold text-grey_30 md:text-2xl">Price</p>
        <p className="my-1 font-Bai text-2xl font-semibold md:my-2 md:text-3xl">
          {toCurrency(transaction?.amount)}
        </p>
        <p className="text-grey_30">
          {moment(transaction?.request_date_time).format(
            "Do MMMM YYYY, h:mm a",
          )}
        </p>
      </div>

      <div className="relative mt-4 grid w-full grid-cols-5 rounded-20 border border-grey_70 p-3 md:mt-8 md:p-4">
        <div className="col-span-2">
          <div>
            <p className="mb-1.5 text-grey_30 md:mb-3 md:text-lg">Account</p>
            <p className="text-sm font-medium text-grey md:text-base">
              {transaction?.bank_account?.account_number} - {bankName}
            </p>
          </div>
          <div className="mt-3 md:mt-6">
            <p className="mb-1.5 text-grey_30 md:mb-3">Status</p>
            <Chip
              sx={{borderRadius: "8px"}}
              color={statusColor[transaction?.status?.toLowerCase()]}
              label={transaction?.status}
            />
          </div>
        </div>
        <div className="absolute left-[38%] top-[50%] h-[80%] w-px -translate-y-1/2 bg-grey_60"></div>
        <div className="col-span-3">
          <p className="mb-1.5 font-Bai font-medium text-grey_30 md:mb-3 md:text-lg">
            Description
          </p>
          <p className="text-sm font-medium text-grey md:text-base">
            {transaction?.description}
          </p>
        </div>
      </div>
    </MyModal>
  );
};

export default TransactionDetailModal;
