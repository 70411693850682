import {Link, useParams} from "react-router-dom";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {Avatar, Button, Chip, Skeleton} from "@mui/material";
import {useState} from "react";
import SingleCapacityCard from "./components/SingleCapacityCard";
import CreateCapabilityModal from "./Modals/CreateCapabilityModal";
import {useGetSingleCategoryQuery} from "services/admin/adminGeneralApi";
import moment from "moment/moment";
import EditCategoryModal from "./Modals/EditCategoryModal";

export const StatusChip = {
  suspended: "default",
  active: "secondaryInverse",
  inactive: "primaryInverse",
};
const SingleCategoryPage = () => {
  const {id} = useParams();

  const [showCapabilityModal, setShowCapabilityModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const {
    data: category,
    isLoading,
    error,
    isError,
  } = useGetSingleCategoryQuery(id);
  return (
    <DashboardLayout
      isLoading={isLoading}
      error={error}
      isError={isError}
      title="Settings"
      type="admin"
    >
      <main>
        <Link
          to="/admin/settings?tab=categories"
          className="flex w-fit items-center gap-x-2.5 font-medium text-grey_20"
        >
          <span className="flex h-6 w-6 items-center justify-center rounded-full bg-white">
            <KeyboardArrowLeftIcon sx={{fontSize: "1rem"}} />
          </span>
          Categories settings
        </Link>
        <div className="mt-6 rounded-2xl bg-white p-3 md:min-h-screen md:p-6">
          <div className="flex flex-col items-start justify-between md:flex-row">
            <div className="flex items-center gap-x-5">
              <Avatar
                src={category?.data.cover_photo || ""}
                alt="img"
                sx={{
                  height: "60px",
                  width: "60px",
                  borderRadius: "16px",
                  "@media (min-width: 780px)": {
                    width: "140px",
                    height: "120px",
                    borderRadius: "24px",
                  },
                }}
              />

              <div className="flex flex-col items-start gap-y-1">
                <h1 className="font-Bai text-2xl font-semibold text-grey_20 md:text-3xl">
                  <span>{category?.data.name}</span>
                </h1>
                <div className="flex flex-wrap gap-1.5 text-sm ">
                  <p className="font-medium text-grey">
                    <span className="text-grey_30">Date Created: </span>
                    <span>
                      {moment(category?.data.created_at).format(
                        "Do MMMM, YYYY",
                      )}
                    </span>
                  </p>
                  <p className="font-medium text-grey">
                    <span className="text-grey_30">Last Modified: </span>
                    <>
                      <span>
                        {moment(category?.data.updated_at).format(
                          "Do MMMM, YYYY",
                        )}
                      </span>
                    </>
                  </p>
                </div>
                <Chip
                  sx={{borderRadius: "8px !important"}}
                  label={category?.data?.status}
                  color={StatusChip[category?.data?.status?.toLowerCase()]}
                />
              </div>
            </div>
            <div className="mt-6 flex gap-x-2 md:mt-0 md:gap-x-4">
              {category?.data?.status !== "Suspended" && (
                <Button
                  onClick={() => setShowCapabilityModal(true)}
                  variant="contained"
                  color="primaryInverse2"
                >
                  Add Expertise
                </Button>
              )}
              <Button
                onClick={() => setShowCategoryModal(true)}
                variant="contained"
              >
                Manage Category
              </Button>
            </div>
          </div>

          <div className="mt-6 mb-10 grid md:grid-cols-2">
            <div>
              <p className="mb-2 text-lg font-medium text-grey_30">
                Description
              </p>
              <p className="font-medium text-black">
                {isLoading ? (
                  <Skeleton variant="rounded" width={300} height={50} />
                ) : (
                  <span>{category?.data.description}</span>
                )}
              </p>
            </div>
          </div>
          {category?.data.expertises.length > 0 && (
            <section>
              <h2 className="font-Bai text-xl font-semibold">Expertise</h2>
              <div className="mt-6 grid items-start justify-start gap-6 md:grid-cols-3">
                {category?.data.expertises.map(exp => (
                  <SingleCapacityCard key={exp.id} exp={exp} />
                ))}
              </div>
            </section>
          )}
        </div>
        <CreateCapabilityModal
          open={showCapabilityModal}
          close={() => setShowCapabilityModal(false)}
        />
        <EditCategoryModal
          open={showCategoryModal}
          data={category?.data}
          id={id}
          close={() => setShowCategoryModal(false)}
        />
      </main>
    </DashboardLayout>
  );
};

export default SingleCategoryPage;
