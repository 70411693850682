import {Drawer, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function DrawerWrapper({
  maxWidth = "400px",
  open,
  closeDrawer,
  title,
  children,
}) {
  return (
    <Drawer
      sx={{"& .MuiPaper-root": {width: "85vw", maxWidth}}}
      anchor={"right"}
      open={open}
      onClose={() => closeDrawer()}
    >
      <div className="application_modal">
        <IconButton className="!mr-auto" onClick={closeDrawer}>
          <CloseIcon
            sx={{
              height: "25px",
              width: "25px",
            }}
          />
        </IconButton>
        {children}
      </div>
    </Drawer>
  );
}

export default DrawerWrapper;
