import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import LoadingBtn from "components/mui-components/LoadingButton";
import MyModal from "components/mui-components/Modal";
import {FormProvider, useForm, Controller} from "react-hook-form";
import {useParams} from "react-router-dom";
import {showToast} from "redux/store.hooks";
import {useCreateExpertiseMutation} from "services/admin/adminGeneralApi";
import {handleError} from "utils";

const CreateCapabilityModal = ({open, close}) => {
  const {id} = useParams();
  const methods = useForm();

  const {control, reset} = methods;

  const [create, {isLoading}] = useCreateExpertiseMutation();

  const onSubmit = async data => {
    const formData = new FormData();
    const VALUES = ["name", "category_id", "description", "location_type"];
    VALUES.forEach(value => {
      if (value === "location_type") formData.append(value, data.location);
      else if (value === "category_id") formData.append(value, id);
      else formData.append(value, data[value]);
    });

    try {
      create(formData)
        .unwrap()
        .then(res => {
          showToast(res?.message, "success");
          reset();
          close();
        });
    } catch (error) {
      handleError(error);
      close();
    }
  };

  return (
    <MyModal openModal={open} closeModal={close} title={"Create Expertise"}>
      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-y-4"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <ValidatedInput2
            name="name"
            label="Expertise Name"
            placeholder="Enter Expertise Name"
          />
          <ValidatedTextArea2
            name="description"
            label="Description"
            placeholder="Write something about the expertise here"
            height="min-h-[140px]"
          />

          <div className="">
            <label className="text-prop pb-[10px] text-[14px] font-normal text-grey_30 ">
              Location
            </label>
            <div className="">
              <FormControl
                sx={{
                  "& .MuiFormGroup-root": {
                    display: "flex",
                    gap: "40px",
                    justifyContent: "space-between !important",
                  },
                }}
              >
                <Controller
                  name="location"
                  row
                  control={control}
                  render={({field}) => (
                    <RadioGroup {...field} row>
                      <FormControlLabel
                        value={"Virtual"}
                        label={
                          <span className="text-prop text-[14px] font-medium text-grey_10 ">
                            Virtual
                          </span>
                        }
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 25,
                              },
                            }}
                          />
                        }
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontFamily: "Mulish",
                            color: "#3B3D3B",
                          },
                        }}
                      />
                      <FormControlLabel
                        value="Physical"
                        label={
                          <span className="text-prop text-[14px] font-medium text-grey_10 ">
                            Physical
                          </span>
                        }
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 28,
                              },
                            }}
                          />
                        }
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontFamily: "Mulish",
                            color: "#3B3D3B",
                          },
                        }}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </div>
          </div>

          <LoadingBtn sx={{mt: 10}} type="submit" fullWidth loading={isLoading}>
            Save
          </LoadingBtn>
        </form>
      </FormProvider>
    </MyModal>
  );
};

export default CreateCapabilityModal;
