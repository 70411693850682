import { Button, Skeleton } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import UserIcon from "components/Vectors/user";
import { useState } from "react";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";
import { useNavigate } from "react-router-dom";
import { CustomTabStyle2 } from "App";
import AllClientTab from "./Tabs/AllClient";
import { useGetAllClientsQuery } from "services/admin/adminGeneralApi";
import AddNewClientModal from "./Modals/AddNewClientModal";

function AdminClients() {
  const [activeTab, setActiveTab] = useState("all");
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const navigate = useNavigate();
  const { data: allClients, isLoading, isError, error } = useGetAllClientsQuery();
  return (
    <DashboardLayout title={"Clients"} type="admin">
      <main>
        <div className="rounded-2xl bg-white p-4 md:py-6">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="font-Bai text-2xl font-medium text-black">
              Overview
            </h3>
            <Button
              sx={{ fontSize: "0.75rem", px: "0.5rem" }}
              variant="contained"
              onClick={() => setShowAddClientModal(true)}
            >
              Add New Client
            </Button>
          </div>

          <div className="boxes grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
            <DashboardBox
              text={"All Clients"}
              icon={<UserIcon stroke={"#E98100"} />}
              value={
                isLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : isError ? (
                  "..."
                ) : (
                  String(allClients?.data.length)
                )
              }
            />
            <DashboardBox
              text={"Active Clients"}
              icon={<UserIcon stroke={"#039855"} />}
              value={
                isLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : isError ? (
                  "..."
                ) : (
                  String(
                    allClients?.data.filter(
                      value => value.status.toLowerCase() === "active",
                    ).length,
                  )
                )
              }
            />
            <DashboardBox
              text={"Suspended Clients"}
              icon={<UserIcon stroke={"#6E726E"} />}
              value={
                isLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : isError ? (
                  "..."
                ) : (
                  String(
                    allClients?.data?.filter(
                      value => value.status.toLowerCase() === "suspended",
                    ).length,
                  )
                )
              }
            />
          </div>
        </div>

        <div className="mt-6 rounded-2xl bg-white p-4 md:py-6 ">
          <TabContext value={activeTab}>
            <TabList
              variant="scrollable"
              value={activeTab}
              className="mb-4 w-fit  rounded-[16px] border border-grey_60 bg-grey_80 p-2"
              onChange={(_, newValue) => {
                navigate(`?tab=${newValue}`);
                setActiveTab(newValue);
              }}
              sx={{
                fontWeight: 600,
                ...CustomTabStyle2,
                textTransform: "unset",
              }}
              aria-label="basic tabs example"
            >
              <Tab label="All Clients" value="all" />
              <Tab label="Active Clients" value="active" />
              <Tab label="Suspended Clients" value="suspended" />
            </TabList>
            <TabPanel value="all">
              <AllClientTab
                data={allClients}
                filterBy="all"
                isLoading={isLoading}
                isError={isError}
                error={error}
              />
            </TabPanel>
            <TabPanel value="active">
              <AllClientTab
                data={allClients}
                filterBy="active"
                isLoading={isLoading}
                isError={isError}
                error={error}
              />
            </TabPanel>
            <TabPanel value="suspended">
              <AllClientTab
                data={allClients}
                filterBy="suspended"
                isLoading={isLoading}
                isError={isError}
                error={error}
              />
            </TabPanel>
          </TabContext>
        </div>
      </main>
      <AddNewClientModal
        openModal={showAddClientModal}
        setOpenModal={setShowAddClientModal}
      />
    </DashboardLayout>
  );
}

export default AdminClients;
