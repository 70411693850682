const OpenMailIcon = (props) => {
  return (
    <svg
      width="128"
      height="127"
      viewBox="0 0 128 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.7722 13.9358C58.1125 11.7646 59.7827 10.679 61.5822 10.2566C63.1729 9.88322 64.8284 9.88322 66.4191 10.2566C68.2186 10.679 69.8888 11.7646 73.2291 13.9358L113.065 39.829C114.472 40.744 115.176 41.2015 115.686 41.8117C116.138 42.3519 116.477 42.9767 116.684 43.6495C116.917 44.4096 116.917 45.249 116.917 46.9279V85.7248C116.917 94.6156 116.917 99.061 115.187 102.457C113.665 105.444 111.236 107.873 108.249 109.395C104.854 111.125 100.408 111.125 91.5173 111.125H36.484C27.5932 111.125 23.1477 111.125 19.7519 109.395C16.7648 107.873 14.3362 105.444 12.8143 102.457C11.084 99.061 11.084 94.6156 11.084 85.7248V46.9279C11.084 45.249 11.084 44.4096 11.3177 43.6495C11.5246 42.9767 11.8637 42.3519 12.3151 41.8117C12.825 41.2015 13.5288 40.744 14.9364 39.8291L54.7722 13.9358Z"
        fill={props.color ? props.color : "#FAEFD7"}
      />
      <path
        d="M54.7722 70.7305L16.5446 45.8825C14.7247 44.6996 13.8147 44.1081 13.4996 43.358C13.2241 42.7025 13.2241 41.9637 13.4996 41.3082C13.8147 40.5582 14.7247 39.9667 16.5446 38.7837L54.7722 13.9358C58.1125 11.7646 59.7827 10.679 61.5822 10.2566C63.1729 9.88322 64.8284 9.88322 66.4191 10.2566C68.2186 10.679 69.8888 11.7646 73.2291 13.9358L111.457 38.7837C113.277 39.9667 114.187 40.5582 114.502 41.3082C114.777 41.9637 114.777 42.7025 114.502 43.358C114.187 44.1081 113.277 44.6996 111.457 45.8825L73.2291 70.7305C69.8888 72.9017 68.2186 73.9873 66.4191 74.4097C64.8284 74.783 63.1729 74.783 61.5822 74.4097C59.7827 73.9873 58.1125 72.9017 54.7722 70.7305Z"
        fill={props.color ? props.color : "#FAEFD7"}
      />
      <path
        d="M73.2291 13.9358L113.065 39.829C114.472 40.744 115.176 41.2015 115.686 41.8117C116.138 42.3519 116.477 42.9767 116.684 43.6495C116.917 44.4096 116.917 45.249 116.917 46.9279V85.7248C116.917 94.6156 116.917 99.061 115.187 102.457C113.665 105.444 111.236 107.873 108.249 109.395C104.854 111.125 100.408 111.125 91.5173 111.125H36.484C27.5932 111.125 23.1477 111.125 19.7519 109.395C16.7648 107.873 14.3362 105.444 12.8143 102.457C11.084 99.061 11.084 94.6156 11.084 85.7248V46.9279C11.084 45.249 11.084 44.4096 11.3177 43.6495C11.5246 42.9767 11.8637 42.3519 12.3151 41.8117C12.825 41.2015 13.5288 40.744 14.9364 39.8291L54.7722 13.9358M73.2291 13.9358C69.8888 11.7646 68.2186 10.679 66.4191 10.2566C64.8284 9.88322 63.1729 9.88322 61.5822 10.2566C59.7827 10.679 58.1125 11.7646 54.7722 13.9358M73.2291 13.9358L111.457 38.7837C113.277 39.9667 114.187 40.5582 114.502 41.3082C114.777 41.9637 114.777 42.7025 114.502 43.358C114.187 44.1081 113.277 44.6996 111.457 45.8825L73.2291 70.7305C69.8888 72.9017 68.2186 73.9873 66.4191 74.4097C64.8284 74.783 63.1729 74.783 61.5822 74.4097C59.7827 73.9873 58.1125 72.9017 54.7722 70.7305L16.5446 45.8825C14.7247 44.6996 13.8147 44.1081 13.4996 43.358C13.2241 42.7025 13.2241 41.9637 13.4996 41.3082C13.8147 40.5582 14.7247 39.9667 16.5446 38.7837L54.7722 13.9358"
        stroke={props.color ? props.color : "#F6A001"}
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default OpenMailIcon;
