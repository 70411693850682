import MyModal from "components/mui-components/Modal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const UnApprovedKycModal = ({ open, close }) => {
  return (
    <MyModal openModal={open} closeModal={close} maxWidth={500}>
      <div className="mt-6 flex flex-col items-center">
        <span className="flex h-[90px] w-[90px] items-center justify-center rounded-full bg-[#F2F4F7]">
          <ErrorOutlineOutlinedIcon
            color="primary"
            sx={{ height: "64px", width: "64px" }}
          />
        </span>
        <p className="text-center font-Bai text-4xl font-semibold">
          Your KYC is yet to be approved
        </p>
        <p className="mb-6 mt-2 text-center text-lg font-medium text-grey_30">
          Your KYC is still pending approval from the admin. You will not be able to post a job or send messages to service providers.
        </p>
        {/* <Button
          variant="contained"
          onClick={() => {
            close();
            navigate("/service-provider/account?tab=services");
          }}
          sx={{minWidth: "180px"}}
        >
          Add Service
        </Button> */}
      </div>
    </MyModal>
  );
};

export default UnApprovedKycModal;
