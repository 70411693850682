import {Button} from "@mui/material";
import MyModal from "components/mui-components/Modal";
import SendIcon from "components/Vectors/SendIcon";

const SentModal = ({
  close,
  open,
  title = "Credential Sent",
  description = "The credential has been sent to the user, you’ll be notified when activated",
}) => {
  return (
    <MyModal openModal={open} closeModal={close}>
      <div className="mb-auto flex flex-col items-center">
        <span className="flex h-[90px] w-[90px] items-center justify-center rounded-full bg-[#FDF2E6] md:h-[110px] md:w-[110px]">
          <SendIcon />
        </span>
        <p className="font-Bai text-2xl font-medium text-grey md:text-[32px]">
          {title}
        </p>
        <p className="mt-2 max-w-sm text-center text-grey_30">{description}</p>
      </div>
      <Button onClick={close} variant="contained" sx={{width: "100%"}}>
        Close
      </Button>
    </MyModal>
  );
};

export default SentModal;
