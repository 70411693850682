import {Fade, IconButton, Slide} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Button} from "@mui/material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import React from "react";
import SelectCategory from "./SelectCategory";
import AddMediaFiles from "./AddMediaFiles";
import AddAreaOfExpertise from "./AddAreaOfExpertise";
import {useGetAllServiceCategoriesQuery} from "services/generalApi";
import {useAddSPServicesMutation} from "services/user/providerGeneralApi";
import {handleError} from "utils";
import {LoadingButton} from "@mui/lab";
import {showToast} from "redux/store.hooks";

const AddService = ({
  setIsFinished,
  setOpenModal,
  maxWidth = 550,
  openModal = true,
  serviceData,
}) => {
  const closeModal = () => {
    setOpenModal(false);
    setPresentStep(1);
  };
  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    defaultValues: {
      features: [{feature: ""}],
    },
  });
  const [presentStep, setPresentStep] = useState(1);
  const [postService, {isLoading: isServiceLoading}] =
    useAddSPServicesMutation();

  useEffect(() => {
    if (serviceData) {
      methods.setValue("category", serviceData?.category_id || "");
      methods.setValue("description", serviceData?.description || "");
      methods.setValue("video_link", serviceData?.videos[0]?.video || "");
      serviceData?.expertise.forEach((el, i) => {
        methods.setValue(`expertise_prices.${i}.id`, el?.expertise_id || "");
        methods.setValue(`expertise_prices.${i}.min`, el?.min_price || 0);
        methods.setValue(`expertise_prices.${i}.max`, el?.max_price || "");
        methods.setValue(`expertise_prices.${i}.checked`, true);
      });
      if (serviceData?.images) {
        serviceData?.images.forEach((el, i) => {
          methods.setValue(`images.${i}`, el?.image || "");
        });
      }
    }
  }, [methods, serviceData]);

  const onSubmit = async data => {
    const expertise_prices = data.expertise_prices.reduce(
      (accumulator, currentValue, index) => {
        if (currentValue?.checked) {
          accumulator.push({
            id: currentValue.id,
            min: currentValue.min,
            max: currentValue.max,
          });
        }
        return accumulator;
      },
      [],
    );
    const formData = new FormData();
    formData.append("category_id", data?.category);
    formData.append("description", data?.description);

    if (data?.images?.length > 0) {
      for (let i = 0; i < data?.images?.length; i++) {
        const img = data?.images[i];
        if (typeof img !== "string") {
          formData.append(`images[${i}][file]`, img);
        }
      }
    }
    if (data?.video_file) {
      formData.append(`videos[0][file]`, data?.video_file);
    }
    if (data?.video_link) {
      formData.append(`videos[0][embed_url]`, data?.video_link);
    }
    if (expertise_prices?.length > 0) {
      for (let i = 0; i < expertise_prices.length; i++) {
        formData.append(
          `expertise_prices[${i}][expertise_id]`,
          expertise_prices[i]?.id,
        );
        expertise_prices[i]?.min &&
          formData.append(
            `expertise_prices[${i}][min_price]`,
            expertise_prices[i]?.min || 0,
          );
        if (expertise_prices[i]?.max) {
          if (!expertise_prices[i]?.min) {
            formData.append(`expertise_prices[${i}][min_price]`, 0);
          }
          formData.append(
            `expertise_prices[${i}][max_price]`,
            expertise_prices[i]?.max,
          );
        }
      }
    }
    try {
      const res = await postService({body: formData}).unwrap();
      methods.reset();
      showToast(res?.message);
      closeModal();
      setIsFinished(true);
      setPresentStep(1);
    } catch (error) {
      handleError(error);
    }
  };

  const {data: categoryData, isLoading} = useGetAllServiceCategoriesQuery();

  const watchCategoryID = methods.watch("category");
  const expertises = categoryData?.data.find(
    val => val?.id === watchCategoryID,
  )?.expertises;

  return (
    <Fade in={openModal}>
      <div
        onClick={e => e.target === e.currentTarget && closeModal}
        className="modal-wrap"
      >
        <Slide direction="up" in={openModal} mountOnEnter unmountOnExit>
          <div
            style={{maxWidth}}
            className="modal-content scrollbar-style !p-0"
          >
            <IconButton
              className="close-modal-btn absolute top-1.5 right-1.5 z-30 lg:top-4 lg:right-4"
              onClick={closeModal}
            >
              <ClearIcon />
            </IconButton>
            <p className="mb-2 w-[85%] px-4 pt-4 font-Bai text-2xl font-bold text-[#362E1F] md:mb-4 md:px-10 md:pt-10 md:text-[32px]">
              Add service
            </p>
            {/* FORMS  */}
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
                className="relative"
              >
                <div className="flex max-w-[600px] flex-1 flex-col gap-6 rounded-24 bg-white pb-[40px]">
                  {presentStep >= 1 && (
                    <div
                      className={`${presentStep === 1 ? "block" : "hidden"}`}
                    >
                      <SelectCategory
                        categories={categoryData?.data}
                        isLoading={isLoading}
                      />
                    </div>
                  )}
                  <div className={`${presentStep === 2 ? "block" : "hidden"}`}>
                    <AddAreaOfExpertise
                      serviceData={serviceData}
                      expertises={expertises}
                    />
                  </div>

                  {presentStep >= 3 && (
                    <div
                      className={`${presentStep === 3 ? "block" : "hidden"}`}
                    >
                      <AddMediaFiles isEdit={serviceData ? true : false} />
                    </div>
                  )}

                  <div className="ml-3 flex gap-x-4 md:ml-8">
                    {/* If the presentStep is not the first step, display the previous button */}
                    {presentStep !== 1 && (
                      <Button
                        onClick={() => {
                          window.scrollTo({top: 0, behavior: "smooth"});
                          setPresentStep(prev => prev - 1);
                        }}
                        startIcon={<ArrowBackOutlinedIcon />}
                        type="button"
                        variant="outlined"
                      >
                        Previous
                      </Button>
                    )}

                    {presentStep === 3 ? (
                      <LoadingButton
                        loading={isServiceLoading}
                        type="submit"
                        variant="contained"
                        sx={{minWidth: "130px"}}
                      >
                        Finish
                      </LoadingButton>
                    ) : (
                      <Button
                        endIcon={<ArrowForwardOutlinedIcon />}
                        type="button"
                        variant="contained"
                        onClick={() => {
                          setPresentStep(prev => prev + 1);
                          window.scrollTo({top: 0, behavior: "smooth"});
                        }}
                        sx={{minWidth: "130px"}}
                      >
                        Proceed
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </Slide>
      </div>
    </Fade>
  );
};

export default AddService;
