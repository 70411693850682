import EmptyResponse from "components/mui-components/EmptyResponse";
import ErrorMsg from "components/mui-components/ErrorMsg";
import CheckVerifiedIcon from "components/Vectors/CheckVerifiedIcon";
import NewFileIcon from "components/Vectors/NewFileIcon";
import UserSquareIcon from "components/Vectors/UserSquareIcon";
import EachVerification from "../widgets/EachVerification";
import CircularLoader from "components/Loader/CircularLoader";
import { useDashboardUnverifiedUsersQuery } from "services/admin/adminGeneralApi";

const PendingVerification = () => {
  const {
    data: unverifiedUsers,
    isLoading,
    isError,
    error,
  } = useDashboardUnverifiedUsersQuery();
  if (isLoading) return <CircularLoader />;
  if (isError) return <ErrorMsg error={error} />;
  if (unverifiedUsers?.data?.length === 0)
    return (
      <EmptyResponse
        icon={<CheckVerifiedIcon />}
        message="No pending verifications"
      />
    );
  return (
    <div>
      <h3 className="mb-4 font-Bai text-base font-medium text-grey_10 sm:text-2xl">
        Pending Verification
      </h3>
      {isLoading && <CircularLoader />}
      {unverifiedUsers?.data && (
        <div className="noScroll flex h-full max-h-[569px] flex-col gap-4 overflow-y-auto rounded-2xl bg-white p-4">
          {unverifiedUsers?.data?.slice(0, 12).map(user => (
            <EachVerification
              icon={
                user?.service_provider_type ===
                  "Registered Service Provider" ? (
                  <UserSquareIcon />
                ) : (
                  <NewFileIcon />
                )
              }
              user={user}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default PendingVerification;
