import Responses from "pages/Admin/Dashboard/Tickets/components/Responses";
import ProjectOverdue from "pages/Admin/Dashboard/Tickets/components/ProjectOverdue";
import {useReplyTicketMutation} from "services/user/userRegApi";

const TicketContent = ({ticket}) => {
  const [reply, {isLoading}] = useReplyTicketMutation();
  return (
    <div className="w-full flex-1">
      <ProjectOverdue
        bg="bg-white"
        detail={ticket || {}}
        reply={reply}
        isLoading={isLoading}
      />
      {/* responses  */}
      <Responses responses={ticket?.responses || []} bg="bg-white " />
    </div>
  );
};
export default TicketContent;
