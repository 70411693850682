import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {logOut} from "redux/slice/AuthSlice";
import {API_URL} from "utils/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, api) => {
    const {auth} = api.getState();

    if (auth.token) {
      headers.set("authorization", `Bearer ${auth.token}`);
      headers.set("Access-Control-Allow-Origin", `*`);
    }
    headers.set("Accept", `application/json`);
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  let err = result?.error;
  if (result?.error && err.data?._meta?.error?.message.includes("logged in")) {
    // logout
    console.log("Something went wrong, Please login again", "error");
    setTimeout(() => {
      api.dispatch(logOut({redirect: true}));
    }, 1000);
  }
  return result;
};

export const generalApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "generalApi",
  tagTypes: [
    "locationData",
    "userData",
    "ticketData",
    "categoryData",
    "engagementChats",
    "offerChats",
  ],
  endpoints: builder => ({
    getAllStates: builder.query({
      query: () => `location/states`,
      providesTags: ["locationData"],
    }),

    getSingleState: builder.query({
      query: id => `location/state/${id}`,
      providesTags: ["locationData"],
    }),

    getCityByState: builder.query({
      query: id => `location/get-cities/${id}`,
      providesTags: ["locationData"],
    }),

    getAllBanks: builder.query({
      query: () => `banks`,
      providesTags: ["userData"],
    }),

    getAllServiceCategories: builder.query({
      query: params => ({
        url: `categories`,
        params,
      }),
      providesTags: ["categoryData"],
    }),
    getProvidersByCategories: builder.query({
      query: ({params, id}) => ({
        url: `categories/${id}/service-providers`,
        params,
      }),
      providesTags: ["categoryData"],
    }),

    getCategoryServiceProviders: builder.query({
      query: id => ({
        url: `categories/${id}/service-providers`,
      }),
      providesTags: ["categoryData"],
    }),
    // jobs
    getAllJobs: builder.query({
      query: params => ({
        url: "job/list",
        params: {...params},
      }),
      providesTags: ["jobData"],
    }),
    getTopServices: builder.query({
      query: () => `top_categories`,
      providesTags: ["categoryData"],
    }),
    getSingleJob: builder.query({
      query: id => ({
        url: `job/show/${id}`,
      }),
      providesTags: ["jobData"],
    }),
    contactUs: builder.mutation({
      query: body => ({
        url: `contact-us/send-message`,
        method: "POST",
        body,
      }),
    }),
    getEngagementChats: builder.query({
      query: id => ({
        url: `chat/engagement/${id}`,
      }),
      providesTags: ["engagementChats"],
    }),
    getOfferChats: builder.query({
      query: id => ({
        url: `chat/offer/${id}`,
      }),
      providesTags: ["offerChats"],
    }),
    sendEngagementMsg: builder.mutation({
      query: body => ({
        url: `user/engagement/conversation/send-message`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["engagementChats"],
    }),
    sendOfferMsg: builder.mutation({
      query: body => ({
        url: `user/offer/conversation/send-message`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["offerChats"],
    }),
    serverlogOut: builder.mutation({
      query: (type = "user") => ({
        url: `${type}/logout`,
        method: "POST",
      }),
    }),
    getHomepageBanners: builder.query({
      query: () => ({
        url: `/home-page-banner/list`,
      }),
    }),
  }),
});

export const {
  useGetAllStatesQuery,
  useGetSingleStateQuery,
  useGetCityByStateQuery,
  useGetAllBanksQuery,
  useGetAllServiceCategoriesQuery,
  useGetProvidersByCategoriesQuery,
  useGetTopServicesQuery,
  useGetCategoryServiceProvidersQuery,
  // jobs
  useGetAllJobsQuery,
  useGetSingleJobQuery,
  useContactUsMutation,

  // chats
  useGetEngagementChatsQuery,
  useSendEngagementMsgMutation,
  useGetOfferChatsQuery,
  useSendOfferMsgMutation,
  useServerlogOutMutation,
  useGetHomepageBannersQuery,
} = generalApi;
