import FlagIcon from "components/Vectors/FlagIcon";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import {flagColor} from "components/TicketCards";
import {useNavigate} from "react-router-dom";
const TicketCard = ({id, priority, name, avatar, type, tag, badge}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/admin/tickets/${id}?tag=${tag}`)}
      className="flex cursor-pointer items-center justify-between rounded-xl bg-grey_80 px-4 py-4 transition-all hover:bg-grey_70 focus:outline"
    >
      <div className=" flex w-[80%] flex-col gap-1">
        <UserDisplay
          name={name}
          img={avatar}
          size="medium"
          email={type}
          badge={badge}
        />
        <div className="mt-2">
          <p className="tex-grey_30 flex text-sm font-normal ">
            Priority: &nbsp;{" "}
            <span className="flex items-center gap-2 text-sm font-normal text-grey_20">
              <FlagIcon
                color={flagColor(priority)}
                width="10px"
                height="13px"
              />
              {priority}
            </span>
          </p>
        </div>
      </div>

      <span>
        <KeyboardArrowRightIcon color="secondary" />
      </span>
    </div>
  );
};

export default TicketCard;
