import ValidatedInput2 from "components/forms/ValidatedInput2";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import MyModal from "components/mui-components/Modal";
import {useEffect} from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {showToast} from "redux/store.hooks";
import {handleError} from "utils";
import {
  useUpdateCategoryMutation,
  useUpdateCategoryStatusMutation,
} from "services/admin/adminGeneralApi";
import LoadingBtn from "components/mui-components/LoadingButton";
import {useState} from "react";
import CustomFileUploader from "components/forms/CustomFileUploader";

const EditCategoryModal = ({open, close, data, id}) => {
  const {handleSubmit, setValue, ...methods} = useForm({
    mode: "all",
    reValidateMode: "all",
  });
  const [featured, setFeatured] = useState("No");
  const [update, {isLoading}] = useUpdateCategoryMutation();
  const [suspend, {isLoading: isSuspending}] =
    useUpdateCategoryStatusMutation();

  useEffect(() => {
    setValue("name", data?.name);
    setValue("mobile_app_icon", data?.mobile_app_icon);
    setValue("description", data?.description);
    setValue("cover_photo", data?.cover_photo);
    setFeatured(data?.is_featured);
  }, [setValue, data]);

  const handleSuspend = () => {
    suspend(id)
      .unwrap()
      .then(res => {
        showToast(res?.message, "success");
        close();
      })
      .catch(err => {
        handleError(err);
        close();
      });
  };

  const onSubmit = async body => {
    const formData = new FormData();
    formData.append("name", body.name);
    formData.append("description", body.description);
    if (typeof body?.mobile_app_icon !== "string") {
      formData.append("mobile_app_icon", body.mobile_app_icon);
    }
    if (typeof body?.cover_photo !== "string") {
      formData.append("cover_photo", body.cover_photo);
    }
    formData.append("is_featured", featured);


    try {
      let res = await update({id, body: formData}).unwrap();
      showToast(res?.message, "success");
      close();
    } catch (error) {
      handleError(error);
      close();
    }
  };

  return (
    <MyModal openModal={open} closeModal={close} title="Manage Category">
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-y-4"
        >
          <ValidatedInput2
            name="name"
            label="Category Name"
            placeholder="Enter Category name"
          />
          <CustomFileUploader
            required={true}
            fileType={"image"}
            defaultUrl={data?.cover_photo}
            name="cover_photo"
            label="Cover Photo"
            types={["png", "jpg", "jpeg"]}
          />
          <ValidatedTextArea2
            name="description"
            label="Description"
            placeholder="Write something about the role here"
            height="min-h-[140px]"
          />
          <CustomFileUploader
            required={true}
            fileType={"image"}
            defaultUrl={data?.mobile_app_icon}
            name="mobile_app_icon"
            label="Mobile App Icon"
            types={["png", "jpg", "jpeg"]}
          />
          <FormControlLabel
            // sx={{mt: 2}}
            value="available"
            control={
              <Checkbox
                checked={featured === "Yes" ? true : false}
                onChange={event =>
                  setFeatured(event.target.checked ? "Yes" : "No")
                }
              />
            }
            label="Feature On Homepage"
          />
          <div className="mt-8 grid grid-cols-2 items-end gap-x-4">
            <LoadingBtn
              variant="outlined"
              color="error"
              loading={isSuspending}
              onClick={handleSuspend}
            >
              {data?.status === "Suspended" ? "Unsuspend" : "Suspend"}
            </LoadingBtn>
            <LoadingBtn type="submit" loading={isLoading}>
              Save
            </LoadingBtn>
          </div>
        </form>
      </FormProvider>
    </MyModal>
  );
};

export default EditCategoryModal;
