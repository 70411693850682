const Users2 = props => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.75 3.46776C18.2317 4.20411 19.25 5.73314 19.25 7.5C19.25 9.26686 18.2317 10.7959 16.75 11.5322M18.75 16.7664C20.2615 17.4503 21.6225 18.565 22.75 20M2.75 20C4.69649 17.5226 7.33918 16 10.25 16C13.1608 16 15.8035 17.5226 17.75 20M14.75 7.5C14.75 9.98528 12.7353 12 10.25 12C7.76472 12 5.75 9.98528 5.75 7.5C5.75 5.01472 7.76472 3 10.25 3C12.7353 3 14.75 5.01472 14.75 7.5Z"
        stroke={props.color || props.stroke || "#5010B3"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Users2;
