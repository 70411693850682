import { LoadingButton } from "@mui/lab";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import MyModal from "components/mui-components/Modal";
import MessageModal from "components/mui-components/Modal/MessageModal";
import { FormProvider, useForm } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import { useCancelEngagementMutation } from "services/user/userRegApi";
import { convertToSlug, handleError } from "utils";
import { useGetUser } from "hooks/getUserHook";
import { useNavigate } from "react-router-dom";

const CancelEngagementModal = ({
  onOpen,
  onClose,
  engagement_id,
  refetchData = () => { },
}) => {
  const method = useForm();
  const [openModal, setopenModal] = useState(false);
  const [cancelEng, { isLoading }] = useCancelEngagementMutation();
  const { user } = useGetUser();
  const [showDoneModal, setShowDoneModal] = useState(false);
  const navigate = useNavigate();
  const onSubmit = async body => {
    try {
      await cancelEng({
        body: { ...body, engagement_id },
        type: convertToSlug(user?.user_type),
      }).unwrap();
      setopenModal(false);
      setShowDoneModal(true);
      refetchData();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <MyModal
        openModal={openModal}
        closeModal={() => setopenModal(false)}
        maxWidth={575}
        className="!min-h-[unset]"
        title={"Reason for Cancellation"}
      >
        <FormProvider {...method}>
          <form
            className="flex-grow-2 flex flex-col"
            onSubmit={method.handleSubmit(onSubmit)}
          >
            <div className="space-y-5">
              <ValidatedTextArea
                className="flex"
                name="message"
                label="Write a message"
                bg="grey_80"
                placeholder="Type something"
              />
            </div>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              sx={{
                width: "100%",
                mt: "20px",
              }}
              color={"error"}
              type="submit"
            >
              Send
            </LoadingButton>
          </form>
        </FormProvider>
      </MyModal>
      <MessageModal
        icon={<ClearIcon color="error" />}
        iconBg="bg-error-light"
        title={"Cancel Engagement"}
        description={
          "By cancelling this engagement you won't be able interact with the engagement anymore"
        }
        cta={
          <div className="mt-4 grid grid-cols-2 gap-x-2">
            <LoadingButton
              onClick={onClose}
              variant="contained"
              sx={{
                color: "#D27400",
                bgcolor: "#FDF3E6",
                "&:hover": { bgcolor: "#64646456" },
                minWidth: "200px",
              }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                onClose();
                setopenModal(true);
              }}
              sx={{
                minWidth: "200px",
              }}
              variant="contained"
              color="error"
            >
              Proceed
            </LoadingButton>
          </div>
        }
        className="msg-modal !max-w-[500px] items-center gap-y-5 pb-10 !pt-10"
        openModal={onOpen}
        closeModal={onClose}
      />
      <MessageModal
        icon={<ClearIcon color="primary" />}
        openModal={showDoneModal}
        iconBg="bg-[#F2F4F7]"
        title="The engagement has been cancelled"
        actionBtnText="Done"
        closeModal={() => {
          setShowDoneModal(false);
          navigate(
            `/${convertToSlug(user?.user_type)}/engagements/${engagement_id}`,
          );
        }}
      />
    </>
  );
};

export default CancelEngagementModal;
