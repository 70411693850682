import MyModal from "components/mui-components/Modal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NoServiceModal = ({
  open,
  close,
}) => {
  const navigate = useNavigate();
  return (
    <MyModal openModal={open} closeModal={close} maxWidth={500}>
      <div className="mt-6 flex flex-col items-center">
        <span className="flex h-[90px] w-[90px] items-center justify-center rounded-full bg-[#F2F4F7]">
          <ErrorOutlineOutlinedIcon
            color="primary"
            sx={{ height: "64px", width: "64px" }}
          />
        </span>
        <p className="text-center font-Bai text-4xl font-semibold">
          You're yet to add a service
        </p>
        <p className="mb-6 mt-2 text-center text-lg font-medium text-grey_30">
          Your service settings is still pending completion. You
          won't be able to apply for a Job or send
          Messages to Clients
        </p>
        <Button
          variant="contained"
          onClick={() => {
            close();
            navigate("/service-provider/account?tab=services");
          }}
          sx={{ minWidth: "180px" }}
        >
          Add Service
        </Button>
      </div>
    </MyModal>
  );
};

export default NoServiceModal;
