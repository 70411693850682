import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import MyModal from "components/mui-components/Modal";

const AccountDoesNotExistModal = ({open, close}) => {
  return (
    <MyModal openModal={open} closeModal={close}>
      <p className="mt-12 text-center font-Bai text-3xl font-semibold text-grey">
        Account details does not exist
      </p>
      <p className="mb-auto mt-4 text-grey_30">
        Go to settings to update your account information
      </p>
      <Button
        component={Link}
        to="/service-provider/settings"
        variant="contained"
        fullWidth
      >
        Go to Settings
      </Button>
    </MyModal>
  );
};

export default AccountDoesNotExistModal;
