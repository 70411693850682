import {CircularProgress} from "@mui/material";
import AutoCompleteField from "components/forms/AutoCompleteField";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import MyModal from "components/mui-components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import {handleError} from "utils";
import {LoadingButton} from "@mui/lab";
import {
  useGetAllRolesQuery,
  useUpdateAdminMutation,
} from "services/admin/adminGeneralApi";
import {useEffect} from "react";
import {showToast} from "redux/store.hooks";

const EditAdminModal = ({
  open,
  onClose,
  adminData,
  id,
  setOpenEditAdminModal,
}) => {
  const methods = useForm();

  const {setValue, reset} = methods;

  const [updateAdmin, {isLoading}] = useUpdateAdminMutation();

  const {data: allRoles, isLoading: isRoleLoading} = useGetAllRolesQuery();

  const roleNames = allRoles?.data.map(role => role.name);

  useEffect(() => {
    setTimeout(() => {
      setValue("first_name", adminData?.first_name);
      setValue("last_name", adminData?.last_name);
      setValue("email", adminData?.email);
      setValue("phone_number", adminData?.phone_number);
      setValue(
        "roles",
        adminData?.roles.map(role => role.name),
      );
    });
  }, [setValue, adminData]);

  const onSubmit = async body => {
    try {
      let res = await updateAdmin({id, ...body, roles: [body.roles]}).unwrap();
      setOpenEditAdminModal(prev => !prev);
      onClose();
      showToast(res?.message, "success");
      reset();
    } catch (error) {
      handleError(error);
      onClose();
    }
  };
  return (
    <>
      <MyModal openModal={open} closeModal={onClose} title="Edit Admin">
        <FormProvider {...methods}>
          <form
            className="flex flex-col gap-y-4"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="flex items-center gap-x-4">
              <ValidatedInput2
                name="first_name"
                label="First Name"
                placeholder="Input first name"
              />
              <ValidatedInput2
                name="last_name"
                label="Last Name"
                placeholder="Input last name"
              />
            </div>
            <ValidatedInput2
              name="email"
              label="Email Address"
              placeholder="Input Email Address"
              type="email"
            />
            <ValidatedInput2
              name="phone_number"
              label="Phone Number"
              placeholder="+234"
              type="tel"
            />
            <AutoCompleteField
              selectOption={roleNames}
              name="roles"
              label="Role"
              loading={isRoleLoading}
              required={false}
              placeholder="Select Role"
            />

            <LoadingButton
              sx={{mt: 4}}
              loadingIndicator={
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size="1.2rem"
                />
              }
              type="submit"
              loading={isLoading}
              fullWidth
              variant="contained"
              color="primary"
            >
              Submit
            </LoadingButton>
          </form>
        </FormProvider>
      </MyModal>
    </>
  );
};

export default EditAdminModal;
