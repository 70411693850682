import MoneyAddIcon from "components/Vectors/MoneyAddIcon";
import {useState} from "react";
import OverviewCard from "components/OverviewCard";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@mui/material";
import {useNavigate} from "react-router-dom";
import TransactionsTab from "./Tabs/TransactionTab";
import RefundHistoryTab from "./Tabs/RefundHistoryTab";
import {useGetClientTransactionStatsQuery} from "services/user/clientGeneralApi";

function ClientTransaction() {
  const [activeTab, setactiveTab] = useState("transactions");
  const navigate = useNavigate();
  const {data, isLoading} = useGetClientTransactionStatsQuery();
  const stat = data?.data;
  return (
    <>
      <div>
        <h1 className="font-h1 ">Transactions</h1>
        <section className="mb-10 mt-8 rounded-20 bg-white p-6">
          <p className="dashboard-overview  ">Overview</p>
          <div className="mt-[12px] grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <OverviewCard
              isLoading={isLoading}
              title={"All Payments"}
              icon={<MoneyAddIcon />}
              value={stat?.all_payments || 0}
            />
            <OverviewCard
              isLoading={isLoading}
              title={"Completed Payments"}
              icon={<MoneyAddIcon color="#12B76A" />}
              value={stat?.completed_payments || 0}
            />
            <OverviewCard
              isLoading={isLoading}
              title={"Escrow Payments"}
              icon={<MoneyAddIcon />}
              value={stat?.escrow_payments || 0}
            />
            <OverviewCard
              isLoading={isLoading}
              title={"Refunded Payments"}
              icon={<MoneyAddIcon color="#5010B3" />}
              value={stat?.refunded_payments || 0}
            />
          </div>
        </section>

        <section className="rounded-20 border border-grey_60 bg-white p-5 ">
          <TabContext value={activeTab}>
            <TabList
              variant="scrollable"
              value={activeTab}
              className="mb-4"
              onChange={(_, newValue) => {
                navigate(`?tab=${newValue}`);
                setactiveTab(newValue);
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Transactions" value="transactions" />
              <Tab label="Refund History" value="refund_history" />
            </TabList>
            <TabPanel value="transactions">
              <TransactionsTab type="client" />
            </TabPanel>
            <TabPanel value="refund_history">
              <RefundHistoryTab data />
            </TabPanel>
          </TabContext>
        </section>
      </div>
    </>
  );
}

export default ClientTransaction;
