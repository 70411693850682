import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useEffect, useState} from "react";
import {useSearchParams, useNavigate} from "react-router-dom";
import BasicInfoTab from "./Tabs/BasicInfoTab";
import AccountDetails from "pages/User/auth/AccountCreationComp/AccountDetails";
import KycSettings from "pages/Clients/Settings/Tabs/KycSettings";
import ProfessionalTab from "./Tabs/ProfessionalTab";
import SecurityTabs from "./Tabs/SecurityTabs";
import {useGetUserRequest} from "hooks/useGetUserRequest";
import {Tab} from "@mui/material";
import NotificationsSettings from "pages/Clients/Settings/Tabs/NotificationsSettings";

const ServiceProviderSettings = () => {
  const [activeTab, setActiveTab] = useState("basic");
  const {refetch} = useGetUserRequest();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
      refetch();
    }
  }, [refetch, tab]);

  const tabPanels = [
    {
      value: "basic",
      component: <BasicInfoTab />,
    },
    {
      value: "professional",
      component: <ProfessionalTab />,
    },
    {
      value: "kyc",
      component: (
        <div>
          <KycSettings />
        </div>
      ),
    },
    // {
    //   value: "bvn",
    //   component: <BvnSettings />,
    // },
    // {
    //   value: "bvn",
    //   component: <BvnSettings />,
    // },
    // {
    //   value: "services",
    //   component: <ServicesTab />,
    // },
    {
      value: "account",
      component: <AccountDetails isOnboarding={false} />,
    },
    {
      value: "security",
      component: <SecurityTabs />,
    },
    {
      value: "notification",
      component: <NotificationsSettings />,
    },
  ];
  return (
    <section>
      <h1 className="font-h1 mb-5 md:mb-[32px] ">Settings</h1>
      <div className="mt-8 flex w-full flex-col p-2 md:flex-row md:items-start md:justify-start md:gap-[3.75rem] md:p-0">
        <TabContext value={activeTab}>
          <div className="flex flex-col gap-y-5">
            {/* mobile */}
            <TabList
              variant="scrollable"
              scrollButtons
              value={activeTab}
              className="mb-4 w-fit rounded-[16px] border border-grey_60 bg-grey_80 p-2 md:!hidden"
              onChange={(_, newValue) => {
                navigate(`?tab=${newValue}`);
                setActiveTab(newValue);
              }}
              sx={{
                fontWeight: 600,
                ".MuiTab-root": {
                  minWidth: "max-content",
                },
                textTransform: "unset",
              }}
              aria-label="tickets tab"
            >
              {steps.map(({value, label}, index) => (
                <Tab
                  key={`tab_${value}`}
                  onClick={() => {
                    setActiveTab(value);
                    navigate(`?tab=${value}`);
                  }}
                  label={label}
                  value={value}
                />
              ))}
            </TabList>
            {/* desktop  */}
            <div className="hidden h-fit min-w-fit flex-col items-start gap-y-2 rounded-2xl bg-white p-8 md:flex md:min-w-[300px]">
              {steps.map(({value, label}, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => {
                    setActiveTab(value);
                    navigate(`?tab=${value}`);
                  }}
                  className={`min-w-full rounded-[14px] py-3 px-5 text-left font-Mulish text-lg font-medium transition duration-500 hover:bg-grey_60 hover:font-semibold hover:text-[#693A00] ${
                    activeTab === value
                      ? "bg-grey_60 font-semibold text-[#693A00]"
                      : "text-grey_50"
                  }}`}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>

          <div className="w-full md:max-w-[720px]">
            {tabPanels.map(({value, component}, index) => (
              <TabPanel key={index} value={value}>
                {component}
              </TabPanel>
            ))}
          </div>
        </TabContext>
      </div>
    </section>
  );
};
export default ServiceProviderSettings;

const steps = [
  {label: "Basic Info", value: "basic"},
  {label: "Professional Info", value: "professional"},
  {label: "KYC", value: "kyc"},
  // {label: "BVN Verification", value: "bvn"},
  // {label: "Services", value: "services"},
  {label: "Bank Details", value: "account"},
  {label: "Security", value: "security"},
  {label: "Notification", value: "notification"},
];
