import MyModal from "components/mui-components/Modal";
import { useForm, FormProvider } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { DateRange } from "react-date-range";
import { useState } from "react";
import MoneyAddIcon from "./Vectors/MoneyAddIcon";
import MoneyTickIcon from "./Vectors/MoneyTickIcon";
import { useAcceptDeclineRequestMutation } from "services/user/clientGeneralApi";
import {
  useGetSettingsListQuery,
  useStartEngagementMutation,
} from "services/user/userRegApi";
import { formatCurrency, handleError } from "utils";
import ValidatedInput2 from "./forms/ValidatedInput2";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { PaymentBtn } from "hooks/usePaystackPaymentHook";
import MessageModal from "./mui-components/Modal/MessageModal";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import moment from "moment";
import ValidatedTextArea2 from "./forms/ValidatedTextArea2";
import { showToast } from "redux/store.hooks";
import { useGetUser } from "hooks/getUserHook";
export const SetTimelineModal = ({
  openDateModal,
  setopenDateModal,
  engagement_id,
}) => {
  const [dateRange, setdateRange] = useState([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
      key: "selection",
    },
  ]);
  const [setDate, { isLoading }] = useStartEngagementMutation();
  const [openMsgModal, setopenMsgModal] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const res = await setDate({
        start_date: moment(dateRange[0]?.startDate).format("DD/M/YYYY"),
        end_date: moment(dateRange[0]?.endDate).format("DD/M/YYYY"),
        engagement_id,
      }).unwrap();
      showToast(res?.message, "success", 6000);
      setopenMsgModal(true);
      setopenDateModal(false);
    } catch (error) {
      handleError();
    }
  };
  return (
    <>
      <MyModal
        openModal={openDateModal}
        closeModal={() => setopenDateModal(false)}
        title="Set your agreed timeline"
        maxWidth={500}
      >
        <form onSubmit={onSubmit} className="flex flex-col items-center gap-6">
          <p className="text-lg font-medium">Select Date</p>
          <DateRange
            rangeColors={["#E98100", "#FDF2E6"]}
            className="mx-auto mb-2 -mt-2"
            editableDateInputs={true}
            onChange={item => setdateRange([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            minDate={new Date()}
          />
          <LoadingButton
            loading={isLoading}
            variant="contained"
            fullWidth
            type="submit"
            className="mt-6"
          >
            Select
          </LoadingButton>
        </form>
      </MyModal>
      <MessageModal
        maxWidth={500}
        openModal={openMsgModal}
        closeModal={() => setopenMsgModal(false)}
        title={<span className="text-2xl">The engagement has commenced</span>}
        description="Ac duis accumsan consectetur pellentesque pellentesque enim eu duis eros. Egestas augue quis posuere semper."
        iconBg="bg-[#FDF2E6]"
        icon={<TaskAltIcon color="primary" />}
        cta={
          <LoadingButton
            variant="contained"
            className="!mt-6 !min-w-[200px]"
            component={Link}
            onClick={() => setopenMsgModal(false)}
            to={`/client/engagements/${engagement_id}`}
          >
            Proceed
          </LoadingButton>
        }
      />
    </>
  );
};

// engagement payment modal
export const EngagementPaymentModal = ({
  openPayModal,
  setopenPayModal,
  engagementId,
  provider_name,
  skipStep1 = false,
}) => {
  // payment process steps
  const [presentStep, setpresentStep] = useState(1);
  const [openDateModal, setopenDateModal] = useState(false);

  const steps = [
    !skipStep1
      ? {
        icon: <TaskAltIcon color="primary" />,
        title: `It’s official, you have hired ${provider_name} to do this Job`,
        description: `Next step is to make payment and update the agreed terms “Timeline and Budget” if any`,
        cta: (
          <LoadingButton
            onClick={() => setpresentStep(prev => prev + 1)}
            variant="contained"
          >
            Proceed
          </LoadingButton>
        ),
        titleSize: "text-2xl",
      }
      : null,
    {
      icon: <MoneyAddIcon />,
      title: `Make payment`,
      description: `To start engagement on this job, you have to make payment. You will be redirected to the payment portal after you hit the Proceed button.`,
      cta: (
        <PaymentBtn
          module="Engagement"
          id={engagementId}
          onPaymentDone={() => {
            setpresentStep(prev => prev + 1);
          }}
        />
      ),
    },
    {
      icon: <MoneyTickIcon color="#E98100" />,
      title: `Payment successful`,
      description: `Your payment has been processed successfully, the job has started.`,
      cta: (
        <LoadingButton
          onClick={() => {
            setopenPayModal(false);
            setopenDateModal(true);
          }}
          variant="contained"
        >
          Done
        </LoadingButton>
      ),
    },
  ].filter(el => el);

  return (
    <>
      <MyModal
        closeOnOverlayClick={false}
        openModal={openPayModal}
        closeModal={() => setopenPayModal(false)}
        className="msg-modal !max-w-[500px] items-center gap-y-5 pb-10 !pt-10"
      >
        <div className={`icon-wrap bg-[#FDF2E6]`}>
          {steps[presentStep - 1].icon}
        </div>
        <div className="my-auto">
          <p
            className={`mx-auto max-w-[350px] font-Bai ${steps[presentStep - 1]?.titleSize || "text-4xl"
              } font-semibold text-[#362E1F]`}
          >
            {steps[presentStep - 1]?.title}
          </p>
          <div className="my-2 mx-auto max-w-[350px] text-lg text-grey_30">
            {steps[presentStep - 1].description}
          </div>
        </div>
        {steps[presentStep - 1]?.cta}
      </MyModal>
      <SetTimelineModal
        engagement_id={engagementId}
        openDateModal={openDateModal}
        setopenDateModal={setopenDateModal}
      />
    </>
  );
};

const RequestModal = ({
  open,
  close,
  type = "accept",
  id,
  provider_name,
  amount_offered,
}) => {
  const method = useForm();
  const { user } = useGetUser();
  const [openPayModal, setopenPayModal] = useState(false);
  const [engagementId, setengagementId] = useState("");

  const [acceptDecline, { isLoading }] = useAcceptDeclineRequestMutation();
  const isAdmin = user?.admin_permissions;
  const { data: settingsList } = useGetSettingsListQuery(isAdmin);
  const serviceCharge = settingsList?.data?.find(
    item => item.option === "buyers_project_payment_Commission",
  );
  const onSubmit = async data => {
    const body = type === "accept" ? data : { message: data?.message };
    try {
      let res = await acceptDecline({ type, id, body }).unwrap();
      setengagementId(res?.data?.id);
      close();
      if (type === "accept") {
        setopenPayModal(true);
      }
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <>
      <MyModal
        openModal={open}
        closeModal={close}
        maxWidth={500}
        className="!min-h-[unset]"
        title={type === "decline" ? "Decline Request" : "Accept Request"}
      >
        <FormProvider {...method}>
          <form
            className="flex-grow-2 flex flex-col"
            onSubmit={method.handleSubmit(onSubmit)}
          >
            <div className="space-y-5">
              {type === "accept" && (
                <p className="text-sm">
                  Amount Offered by {provider_name}:{" "}
                  {formatCurrency(amount_offered)}
                </p>
              )}
              <ValidatedTextArea2
                name="message"
                label="Write an acceptance message"
                bg="#F9FAFB"
                placeholder="Type something"
              />
              {type === "accept" && (
                <>
                  <ValidatedInput2
                    name="agreed_amount"
                    label="Final Agreed Amount"
                    prefixIcon='₦'
                    type="number"
                    placeholder="Enter Amount"
                  />
                  <div className="mt-2 flex gap-2">
                    <InfoOutlinedIcon sx={{ color: "#3B3D3B" }} />
                    <span className=" text-xs text-[#3B3D3B] md:ml-3 md:text-sm">
                      {/* Please Note: VAT of 7.5% and a Truelocal Service Charge of{" "}
                      {formatCurrency(serviceCharge?.value)} applies for the
                      payment. */}

                      You will be charged a VAT when you make payment (7.5% of the agreed amount)
                    </span>
                  </div>
                  <div className='mt-6 bg-red-200 rounded-16 p-4'>
                    <span className="text-sm md:text-base font-medium text-[#271A19]">
                      Please ensure to enter the actual amount agreed, as this
                      might lead to conflict and termination of the project - you
                      can still renegotiate if not conclusive.
                    </span>
                  </div>

                </>
              )}
            </div>
            <LoadingButton
              variant="contained"
              fullWidth
              sx={{ mt: type === "accept" ? 2 : 5 }}
              color={type === "decline" ? "error" : "primary"}
              type="submit"
              loading={isLoading}
            >
              {type === "accept" ? "Proceed to Payment" : "Send"}
            </LoadingButton>
          </form>
        </FormProvider>
      </MyModal>
      {type === "accept" && (
        <>
          <EngagementPaymentModal
            engagementId={engagementId}
            openPayModal={openPayModal}
            setopenPayModal={setopenPayModal}
            provider_name={provider_name}
          />
        </>
      )}
    </>
  );
};

export default RequestModal;
