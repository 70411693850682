import SlantedFileIcon from "components/Vectors/SlantedFileIcon";
import ScrollToTopButton from "components/ScrollToTopButton";

const PrivacyAndPolicy = () => {
  return (
    <>
      <ScrollToTopButton />
      <main className="-mt-20 mb-64 px-6 md:mt-20 lg:px-[4vw]">
        <div className="noScroll mx-auto w-[90vw] max-w-[980px] overflow-y-auto rounded-24 bg-white  shadow-sm">
          <div className="p-4 md:p-6">
            <SlantedFileIcon />

            <h3 className="font-Bai text-2xl font-bold text-[#222222] md:text-[50px] md:leading-[60px]">
              Privacy policy
            </h3>
          </div>

          <div className="h-px bg-grey_60"></div>

          <div className="p-4 md:p-6">
            <p className="mb-8 text-base font-medium font-Inter text-grey_20 md:text-lg">
              Truelocal operates  <a href="http://www.truelocal.ng" target="_blank" rel="noopener noreferrer" className="underline font-bold">www.truelocal.ng</a> (hereinafter referred to as “Service”).
              <br /><br />
              Our Privacy Policy governs your visit to <a href="http://www.truelocal.ng" target="_blank" rel="noopener noreferrer" className="underline font-bold">www.truelocal.ng</a>, and explains how we collect, safeguard and disclose information that results from your use of our Service.
              <br /><br />

              We use the  data provided to us in accordance with the law to provide and improve our Services. By using our Services, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
              <br /><br />

              Our Terms and Conditions (“Terms”) govern all use of our Services, and together with the Privacy Policy constitutes your agreement with us (“agreement”).
              <br /><br />

              Truelocal is the data controller for the personal data collected in connection with use of Truelocal’s services anywhere else.
              <br /><br />
              Truelocal is the data controller for the personal data collected in connection with use of Truelocal’s services in Nigeria and elsewhere where we operate or have third party providers.
              <br /><br />

              <span className="font-bold text-lg md:text-xl">DEFINITIONS:</span> <br />
              <span className="font-bold text-lg md:text-xl">SERVICE</span> means the <a href="http://www.truelocal.ng" target="_blank" rel="noopener noreferrer" className="underline font-bold">www.truelocal.ng</a> website operated by Truelocal
              <br /><br />
              <span className="font-bold text-lg md:text-xl"> PERSONAL DATA</span> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
              <br /><br />

              <span className="font-bold text-lg md:text-xl">USAGE DATA</span> is data collected automatically, either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).
              <br /><br />

              <span className="font-bold text-lg md:text-xl"> COOKIES</span> are small files stored on your device (computer or mobile device).
              <br /><br />

              <span className="font-bold text-lg md:text-xl"> DATA CONTROLLER</span> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which, and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, Truelocal is the Data Controller of your data.
              <br /><br />

              <span className="font-bold text-lg md:text-xl"> DATA PROCESSOR (OR SERVICE PROVIDER)</span> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.
              <br /><br />

              <span className="font-bold text-lg md:text-xl">DATA SUBJECT</span> is any living individual who is the subject of Personal Data.
              <br /><br />

              <span className="font-bold text-lg md:text-xl">THE USER</span> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
              <br /><br />

              <span className="font-bold text-lg md:text-xl">PARTNER or PROVIDER</span>  is any verified individual given a <a href="http://www.truelocal.ng" target="_blank" rel="noopener noreferrer" className="underline font-bold">www.truelocal.ng</a> task or job to perform through our service.
              <br /><br />

              <span className="font-bold text-lg md:text-xl"> CUSTOMER</span> is any individual or business paying (directly or indirectly) for a task or job to be performed through our service.
              <br /><br />

              <span className="font-bold text-lg md:text-xl"> SCOPE OF TRUELOCAL SERVICES</span>
              <br /><br />

              <span className="font-bold text-lg md:text-xl">Basic Principles</span> <br />
              <li className="ml-4 list-disc"> Truelocal provides the Truelocal Platform to enable customers or users to publish or post jobs or tasks.</li><br />
              <li className="ml-4 list-disc">Service providers will have to accept job posts to connect with the customer at a price which will be displayed on the platform. Communication between both parties may occur on the Truelocal platform.</li><br />
              <li className="ml-4 list-disc">  A Customer or user  may revoke or modify a Posted  job or Task at any time. Truelocal reserves the right to cancel any job post made on Truelocal platform at any time. </li><br />
              <li className="ml-4 list-disc">If a service provider accepts a job on the Truelocal platform, contacts of both parties will be shared across and the communication channel will be opened on the platform for parties that want to continue their conversation on the platform.</li><br />
              <li className="ml-4 list-disc"> Upon acceptance of a job post, Truelocal has rendered Truelocal Services and the Service fee is due and deducted immediately.</li><br />
              <li className="ml-4 list-disc">Once the job or task has been accepted, the customer or user  and service provider may vary the original job description including the price for the job. Service providers and customers  are encouraged to use Truelocal’s private messaging system to amend or vary the job or  task description (including the Agreed Price) or to otherwise communicate.</li><br />
              <li className="ml-4 list-disc">Once the Services are complete, the service provider must provide notice of that on the Truelocal Platform.</li><br />
              <li className="ml-4 list-disc">Once the Services are complete, the customer or user must provide notice of that on the Truelocal Platform.</li><br />
              <li className="ml-4 list-disc">Once the posted job or task has been completed and the customer or user confirms the Services are completed, or if Truelocal is satisfied the Services have been completed, the service providers funds will be released by Truelocal from the Payment Account to the service provider bank account. However this process does not apply to payments made directly to the service provider outside the Truelocal platform.</li><br />
              <li className="ml-4 list-disc">After the job or task has been completed, the parties are encouraged to review and provide feedback of the Services on the Truelocal platform.</li><br />

              <span className="font-bold text-lg md:text-xl">POSTING AND ACCEPTING A JOB</span> <br />
              <li className="ml-4 list-disc">Registering and creating an account with Truelocal is free. There is no charge for a customer to post jobs or tasks, or for other Truelocal users to review content on the Truelocal platform, including Posted jobs or  tasks.</li><br />
              <li className="ml-4 list-disc">Truelocal accepts no liability for any aspect of the customer and service provider interaction, including but not limited to the job description,reviews, performance or delivery of Services.</li><br />
              <li className="ml-4 list-disc"> Truelocal has no responsibility and makes no warranty as to the truth or accuracy of any aspect of any information provided by customer or users, including, but not limited to, the ability of service provider to perform jobs or tasks or supply items, or the honesty or accuracy of any information provided by customer or the customers' ability to pay for the Services requested.</li><br />
              <li className="ml-4 list-disc">Accepting a job does not mean that the customer must hire you for the job.</li><br />
              <li className="ml-4 list-disc">You understand and agree that admin fee (as specified by Truelocal)  will be deducted from your Truelocal account automatically as you accept each job. Truelocal admin fees are not refundable.</li><br />
              <li className="ml-4 list-disc">You understand and agree that only funded accounts can accept job posts and that the funds on your Truelocal account are also non refundable.</li><br />
              <li className="ml-4 list-disc">You understand that five (5) service providers or as specified by the customer are allowed to accept each job post to maintain competition, reduce monopoly and give choice to customers.</li><br />
              <li className="ml-4 list-disc">Customer reserves the right not hire anyone from his/her lists of service providers as long as it is proven beyond reasonable doubt the inadequacy or lack of competency of service providers.</li><br />
              <li className="ml-4 list-disc"> Truelocal reserves the right to delete or amend job posts if found to contravene Truelocal guideline and to the extent of deleting or suspending such an account.</li><br />
              <li className="ml-4 list-disc">Truelocal at its discretion may delete any account without warming.</li><br />
              <br />
              <span className="font-bold text-lg md:text-xl">TRUELOCAL'S ROLE AND OBLIGATIONS/DISCLAIMER</span><br />
              Truelocal provides the Truelocal platform only, enabling customers or users to publish or post jobs or tasks. Service providers can accept (at a price)  a job post before they can connect with the customer or user.<br /><br />
              Truelocal only permits individuals over 18 years of age to become Users.<br /><br />
              Users must be natural persons, but can specify within their account description that they represent a business entity.<br /><br />
              At its absolute discretion, Truelocal may refuse to allow any person to register or create an account with Truelocal or cancel or suspend or modify any existing account including if Truelocal reasonably forms the view that a User's conduct (including a breach of this Agreement) is detrimental to the operation of the Truelocal Platform.<br /><br />
              Registering and creating an account with Truelocal is free. There is no charge for a customer to post jobs or tasks, or for other Truelocal users to review content on the Truelocal platform, including Posted jobs or  tasks.<br /><br />
              Truelocal accepts no liability for any aspect of the customer and service provider interaction, including but not limited to the job description,reviews, performance or delivery of Services.<br /><br />
              Truelocal has no responsibility and makes no warranty as to the truth or accuracy of any aspect of any information provided by customer or users, including, but not limited to, the ability of service provider to perform jobs or tasks or supply items, or the honesty or accuracy of any information provided by customer or the customers' ability to pay for the Services requested.<br /><br />
              Truelocal has no obligation to any customer or user to assist or involve itself in any dispute between customer and service provider, although may do so to improve User experience.<br /><br />
              You understand and agree that Truelocal does not undertake any investigation in relation to any customer /user,  service provider  or third party service provider before they are admitted to the platform, including criminal checks, verification of qualification or licence held, or any character or other checks of the suitability of a service provider or third party service provider to perform any job or task which they may claim to be able to provide on the platform. You understand and agree that you are solely responsible for conducting any appropriate background checks and obtaining references, licences, certifications, or proof of insurance prior to engaging a service provider to perform services. You further understand and agree that you are solely responsible for making your own evaluations, decisions and assessments about choosing a service provider. You agree to assume all risks and you agree to expressly release, indemnify and hold harmless Truelocal from any and all loss, liability, injury, death, damage, or costs arising or in any way related to the services.<br /><br />
              <br /><br />
              <span className="font-bold text-lg md:text-xl">USER OBLIGATIONS</span><br />
              You will at all times:<br />
              (a) comply with this Agreement (including all Policies) and all applicable laws and regulations;<br />
              (b) only post accurate information on the Truelocal Platform;<br />
              (c) ensure that You are aware of any laws that apply to You as a customer/user or service provider, or in relation to using the Truelocal platform.<br /><br />
              You agree that any content (whether provided by Truelocal, a customer/user, service provider or a third party) on the Truelocal platform may not be used on third party sites or for other business purposes without Truelocal's prior permission.<br /><br />
              You must not use the Truelocal platform for any illegal or immoral purpose.<br /><br />
              You must maintain control of Your Truelocal account at all times. This includes not allowing others to use Your account, or by transferring or selling Your account or any of its content to another person.<br /><br />
              You grant Truelocal an unrestricted, worldwide, royalty-free licence to use, reproduce, modify and adapt any content and information posted on the Truelocal platform for the purpose of publishing material on the Truelocal Platform and as otherwise may be required to provide the Truelocal Service, for the general promotion of the Truelocal Service, and as permitted by this Agreement.<br />
              You agree that any information posted on the Truelocal Platform must not, in any way whatsoever, be potentially or actually harmful to Truelocal or any other person. Harm includes, but is not limited to, economic loss that will or may be suffered by Truelocal.<br /><br />
              Without limiting any provision of this Agreement, any information You supply to Truelocal or publish in a job post (including as part of an Offer) must be up to date and kept up to date and must not:<br /><br />
              (a) be false, inaccurate or misleading or deceptive;<br />
              (b) be fraudulent or involve the sale of counterfeit or stolen items;<br />
              (c) infringe any third party's copyright, patent, trademark, trade secret or other proprietary rights or intellectual property rights, rights of publicity, confidentiality or privacy;<br />
              (d) violate any applicable law, statute, ordinance or regulation (including, but not limited to, those governing export and import control, consumer protection, unfair competition, criminal law, antidiscrimination and trade practices/fair trading laws);<br />
              (e) be defamatory, libellous, threatening or harassing;<br />
              (f) be obscene or contain any material that, in Truelocal's sole and absolute discretion, is in any way inappropriate or unlawful, including, but not limited to obscene, inappropriate or unlawful images; or<br />
              (g) contain any malicious code, data or set of instructions that intentionally or unintentionally causes harm or subverts the intended function of any Truelocal platform, including, but not limited to viruses, trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, modify, delete, detrimentally interfere with, surreptitiously intercept, access without authority or expropriate any system, data or Personal Information.<br /><br />
              Truelocal platform may from time to time engage location-based or map-based functionality. The Truelocal platform may display the location of customer or user and service provider to persons browsing the Truelocal Platform.<br /><br />
              A customer or user should never disclose personal details such as the customer's full name, street number, phone number or email address or any form of identification in a job or task post or in any other public communication on the Truelocal platform.<br /><br />
              If you are a service provider, you must have the right to provide Services under a service agreement and to work in the jurisdiction where the Services are performed. You must comply with tax and regulatory obligations in relation to any payment received.<br /><br />
              You must not, when supplying Services, charge a customer or user more than the agreed price. However, both parties may agree to amend the agreed Price through the Truelocal Platform.<br /><br />
              You must not request payments outside of the Truelocal platform from the customer or user for the Services except agreed by both parties to continue transactions outside of the Truelocal  platform. In such a case, Truelocal can not and will not be held responsible for any misconduct arising from such engagement.<br /><br />
              If a service provider agrees to pay some costs of completing the Services (such as equipment to complete the Services), the service provider is solely responsible for obtaining any reimbursement from the customer or user. Truelocal advises service providers not to agree to incur costs in advance of receiving the payment for these costs, unless the payment has been made by the customer or user to Truelocal to hold and release at service completion.<br /><br />
              For the proper operation of the Truelocal platform (including insurance, proper pricing and compliance with Policies), the service provider must ensure that, if it subcontracts any part of the performance of the Services to a third party in accordance with a job or task agreement with customer, then that third party must also be a registered user of the Truelocal Platform.<br /><br />
              If Truelocal determines at its sole discretion that You have breached any obligation under this agreement, it reserves the right to remove any content, Posted job or task or Offer You have submitted to the Truelocal Service or cancel or suspend Your account and/or any job post.<br /><br />
              <br />
              <span className="font-bold text-lg md:text-xl">PAYMENTS AND REFUNDS</span><br />

              <li className="ml-4 list-disc"> A customer or user reserves the right to cancel, withdraw or not continue with his/her posted job or choose not to hire anyone before a service provider has started work on the job.</li><br />
              <li className="ml-4 list-disc">If a customer or user chooses to cancel a job after it has been started by a service provider, both parties should agree on payment/refund settlement if their original communication and payment was outside the Truelocal platform.</li><br />
              <li className="ml-4 list-disc"> Truelocal mediation is available if all communications and payments were carried out on the Truelocal platform. If both parties cannot agree before commencing mediation through Truelocal, Truelocal reserves the right to act in such manner it deems fair in settling the issue.</li><br />
              <li className="ml-4 list-disc">Refunds and payout to either customer/user or service provider may take up to 14 days to appear into your nominated bank account as this process is controlled by a third party payment processor.</li><br />
              <li className="ml-4 list-disc">It is your responsibility to provide accurate bank details for refunds and payout.</li><br />

              <span className="font-bold text-lg md:text-xl"> Information Collection and Use</span><br />
              We collect several different types of information for various purposes to provide and improve our Services to you. <br />

              <span className="font-bold text-lg md:text-xl">Types of Data Collected</span><br />
              Personal Data: While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:
              <br />
              <li className="ml-4 list-disc"> Email address</li><br />
              <li className="ml-4 list-disc">First name and last name</li><br />
              <li className="ml-4 list-disc"> Phone number</li><br />
              <li className="ml-4 list-disc"> Address, State, Country</li><br />
              <li className="ml-4 list-disc"> Cookies and Usage Data</li><br />
              <li className="ml-4 list-disc"> Bank Account Details</li><br />
              <li className="ml-4 list-disc"> Bank Verification Number</li><br />
              <li className="ml-4 list-disc"> Guarantor Details</li><br />
              <li className="ml-4 list-disc"> Proficiency Certificate</li><br />
              <li className="ml-4 list-disc"> Onboarding Certificates</li><br />
              <br />
              We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link.
              <br /><br />

              Usage Data: We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through any device (“Usage Data”).
              <br /><br />

              This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
              <br /><br />

              When you access Service with a device, this Usage Data may include information such as the type of device you use, your device’s unique ID, your device’s IP address, your device’s operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.
              <br /><br />

              Location Data: We may use and store information about your location sometimes with or without your permission (“Location Data”). We use this data to provide features of our Services, to improve and customise our Service.
              <br /><br />

              You can enable or disable location services when you use our Service at any time by changing your device settings.
              <br /><br />

              Tracking Cookies Data: We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
              <br /><br />

              Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse our Service.
              <br /><br />

              You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
              <br /><br />

              Examples of Cookies we use:
              <br />

              <li className="ml-4 list-disc">Session Cookies: We use Session Cookies to operate our Service.</li><br />

              <li className="ml-4 list-disc"> Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</li><br />

              <li className="ml-4 list-disc"> Security Cookies: We use Security Cookies for security purposes.</li><br />

              <li className="ml-4 list-disc"> Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</li><br />

              <li className="ml-4 list-disc"> <span className="font-bold text-lg md:text-xl"> Other Data:</span> While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at place of residence and actual address, telephone number (work, mobile), education documents details, qualification, professional training, employment agreements, non-disclosure agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location and other data.</li><br />

              <span className="font-bold text-lg md:text-xl"> Use of Data</span><br />

              Truelocal uses the collected data for various purposes:
              <br /><br />

              <li className="ml-4 list-disc"> to provide and maintain our Service;</li><br />
              <li className="ml-4 list-disc"> to notify you about changes to our Service;</li><br />
              <li className="ml-4 list-disc"> to allow you to participate in interactive features of our Service when you choose to do so;</li><br />
              <li className="ml-4 list-disc"> to provide customer support;</li><br />
              <li className="ml-4 list-disc">to gather analysis or valuable information so that we can improve our Service;</li><br />
              <li className="ml-4 list-disc">to monitor the usage of our Service;</li><br />
              <li className="ml-4 list-disc">to detect, prevent and address technical issues;</li><br />
              <li className="ml-4 list-disc"> to fulfil any other purpose for which you provide it;</li><br />
              <li className="ml-4 list-disc"> to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</li><br />
              <li className="ml-4 list-disc"> to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</li><br />
              <li className="ml-4 list-disc"> to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</li><br />
              <li className="ml-4 list-disc">  in any other way we may describe when you provide the information;</li><br />
              <li className="ml-4 list-disc">  for any other purpose with your consent.</li><br />

              <span className="font-bold text-lg md:text-xl">Retention of Data</span><br />

              We will retain your provided INFORMATION and DATA only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
              <br /><br />

              We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
              <br /><br />

              <span className="font-bold text-lg md:text-xl"> Transfer of Data</span><br />

              Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
              <br /><br />

              If you are located outside Nigeria and choose to provide information to us, please note that we transfer the data, including Personal Data, to Nigeria and process it there.
              <br /><br />

              Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
              <br /><br />

              Truelocal will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
              <br /><br />


              <span className="font-bold text-lg md:text-xl"> Disclosure of Data</span><br />

              We may disclose personal information that we collect, or you provide:
              <br />
              <li className="ml-4 list-disc">Disclosure for Law Enforcement.</li><br />
              <br />
              Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.
              <br />
              <li className="ml-4 list-disc">Business Transaction.</li><br />
              <br />
              If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.
              <br />
              <li className="ml-4 list-disc"> Other cases. We may disclose your information also:</li><br />
              <br />
              <li className="ml-4 list-disc"> to our subsidiaries and affiliates;</li><br />
              <br />
              <li className="ml-4 list-disc"> to contractors, service providers, and other third parties we use to support our business;</li><br />
              <br />
              iii. to fulfil the purpose for which you provide it;
              <br />
              <li className="ml-4 list-disc"> for the purpose of including your company’s logo on our website;</li><br />
              <br />
              <li className="ml-4 list-disc"> for any other purpose disclosed by us when you provide the information;</li><br />
              <br />
              <li className="ml-4 list-disc"> with your consent in any other cases;</li><br />
              <br />
              vii. if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.
              <br />
              <br />
              <span className="font-bold text-lg md:text-xl"> Security of Data</span><br />

              The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
              <br /><br />

              <span className="font-bold text-lg md:text-xl">Third party business partners</span><br />

              We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used which may include API’s and further data required.
              <br /><br />

              These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
              <br /><br />

              <span className="font-bold text-lg md:text-xl"> Analytics</span><br />

              We may use third-party Service Providers to monitor and analyse the use of our Service.
              <br /><br />

              <span className="font-bold text-lg md:text-xl">CI/CD tools</span><br />

              We may use third-party Service Providers to automate the development process of our Service.
              <br /><br />
              <span className="font-bold text-lg md:text-xl"> Behavioral Remarketing</span><br />

              We may use remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.
              <br /><br />
              <span className="font-bold text-lg md:text-xl"> Payments</span><br />

              We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors, payment gateways).
              <br /><br />
              We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information. The terms at https://paystack.com/ are incorporated into this Agreement and will prevail over this Agreement to the extent of any inconsistency in relation to the provision of the Payment Account. If Truelocal changes its Payment Provider You may be asked to agree to any further additional terms with those providers. If you do not agree to them, you may be given alternative means of payment.
              <br /><br />

              <span className="font-bold text-lg md:text-xl"> Links to Other Sites</span><br />

              Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.
              <br /><br />
              We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
              <br /><br />
              <span className="font-bold text-lg md:text-xl"> Children’s Privacy</span><br />

              Our Services are not intended for use by children under the age of 18 (“Child” or “Children”).
              <br /><br />
              We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.

              <span className="font-bold text-lg md:text-xl"> Changes to This Privacy Policy</span><br />

              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
              We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.
              You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
              <br /><br />
              <span className="font-bold text-lg md:text-xl">  Contact Us</span><br />

              If you have any questions about this Privacy Policy, please email us: <a href="mailto:info@truelocal.ng" className="underline">info@truelocal.ng</a>
            </p>
          </div>
        </div>
      </main>
    </>
  );
};

export default PrivacyAndPolicy;
