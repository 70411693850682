import moment from "moment";
import TokenIcon from "components/Vectors/TokenIcon";
import Users2 from "components/Vectors/Users2";
import StopwatchIcon from "components/Vectors/StopwatchIcon";
import EmptyResponse from "./mui-components/EmptyResponse";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";

const ColorStatus = {
  warning: {
    color: "#E98100",
    bg: "#E981001A",
  },
  error: {
    color: "#D92D20",
    bg: "#D92D201A",
  },
  success: {
    color: "#12B76A",
    bg: "#12b76A1A",
  },
};

const getUserIcon = (status = "warning") => ({
  icon: <Users2 color={ColorStatus[status].color} />,
  iconBg: `${ColorStatus[status].bg}`,
});
const getMoneyIcon = (status = "warning") => ({
  icon: <TokenIcon color={ColorStatus[status].color} />,
  iconBg: `${ColorStatus[status].bg}`,
});
const getTimeIcon = (status = "warning") => ({
  icon: <StopwatchIcon color={ColorStatus[status].color} />,
  iconBg: `${ColorStatus[status].bg}`,
});
const getDoneIcon = (status = "warning") => ({
  icon: <HowToRegRoundedIcon sx={{color: ColorStatus[status].color}} />,
  iconBg: `${ColorStatus[status].bg}`,
});
const activityData = {
  "engagement created": getUserIcon("success"),
  "payment made": getMoneyIcon(),
  "engagement started": getTimeIcon(),
  "engagement cancelled": getTimeIcon("error"),
  "engagement terminated": getTimeIcon("error"),
  "deadline extension requested": getTimeIcon(),
  "deadline extension request accepted": getTimeIcon("success"),
  "deadline extension request declined": getTimeIcon("error"),
  "engagement completion request declined": getDoneIcon("error"),
  "engagement completion requested": getDoneIcon(),
  "engagement completed": getDoneIcon("success"),
};

const getActivity = title => {
  let iconBg = activityData[title.toLowerCase()]?.iconBg;
  let icon = activityData[title.toLowerCase()]?.icon;
  if (!iconBg && !icon) {
    for (const key in activityData) {
      if (title.toLowerCase().includes(key.toLowerCase())) {
        iconBg = activityData[key]?.iconBg;
        icon = activityData[key]?.icon;
        break;
      }
    }
  }
  return {icon, iconBg};
};

const EachActivityCard = ({value}) => {
  return (
    <div className="flex items-center justify-between rounded-[14px] bg-grey_80 p-6">
      <div className="flex items-center gap-4">
        <span
          className={`flex h-[60px] min-w-[60px] items-center justify-center rounded-full`}
          style={{
            backgroundColor: getActivity(value.title)?.iconBg,
          }}
        >
          {getActivity(value.title)?.icon}
        </span>
        <div>
          <h4 className="text-prop text-base font-bold text-[#000]">
            {value.title}
          </h4>
          <p className="text-prop text-[14px] font-[400] text-grey_30 ">
            {value.message || ""}
          </p>
        </div>
      </div>

      <p className="text-prop min-w-fit text-base font-[400] text-grey_30">
        {moment(value?.created_at).format("DD MMM YYYY")}
      </p>
    </div>
  );
};
const ActivityCards = ({data}) => {
  return (
    <div className="flex flex-col gap-3">
      {data?.length <= 0 && (
        <EmptyResponse
          message={"No Activity recorded yet"}
          icon={<AnalyticsOutlinedIcon />}
        />
      )}
      {data?.map(value => (
        <EachActivityCard key={value?.id} value={value} />
      ))}
    </div>
  );
};
export default ActivityCards;
