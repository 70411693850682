import {Checkbox, CircularProgress, FormControlLabel} from "@mui/material";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import MyModal from "components/mui-components/Modal";
import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import AddCityModal from "./AddCityModal";
import {useCreateStateMutation} from "services/admin/adminGeneralApi";
import {LoadingButton} from "@mui/lab";
import {handleError} from "utils";

const AddNewStateModal = ({openModal, setopenModal}) => {
  const [openAddCityModal, setopenAddCityModal] = useState(false);
  const [availability, setAvailability] = useState("Virtual Only");
  const [stateID, setStateID] = useState();

  const [createState, {isLoading}] = useCreateStateMutation();

  const handleChange = event => {
    if (event.target.checked === true) {
      setAvailability("Physical Availability");
    } else {
      setAvailability("Virtual Only");
    }
  };

  const methods = useForm();

  const onSubmit = async data => {
    const formData = new FormData();
    formData.append("name", data.state);
    formData.append("availability", availability);

    try {
      let res = await createState(formData).unwrap();
      setStateID(res?.data.id);
      setopenModal(false);
      setopenAddCityModal(true);
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <>
      <MyModal
        openModal={openModal}
        closeModal={() => setopenModal(false)}
        title="Add New State"
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex h-[290px] flex-col justify-between"
          >
            <div className="">
              <ValidatedInput2
                label="State"
                name="state"
                placeholder="Input state"
              />
              <FormControlLabel
                sx={{mt: 2}}
                value="available"
                control={<Checkbox onChange={handleChange} />}
                label="Available for physical jobs"
              />
            </div>

            <LoadingButton
              sx={{mt: 4}}
              loadingIndicator={
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size="1.2rem"
                />
              }
              type="submit"
              loading={isLoading}
              fullWidth
              variant="contained"
              color="primary"
            >
              Add Cities/Town
            </LoadingButton>
          </form>
        </FormProvider>
      </MyModal>

      <AddCityModal
        stateID={stateID}
        openModal={openAddCityModal}
        setopenModal={setopenAddCityModal}
      />
    </>
  );
};

export default AddNewStateModal;
