import {LoadingButton} from "@mui/lab";
import {CircularProgress} from "@mui/material";

const SaveChangeBtn = ({type, isLoading}) => {
  return (
    <LoadingButton
      loading={isLoading}
      loadingIndicator={
        <CircularProgress
          sx={{
            color: "#fff",
          }}
          size="1.2rem"
        />
      }
      type={type}
      sx={{
        fontWeight: "500",
        fontSize: "1rem",
        padding: {
          xs: "12px 32px !important",
          md: "20px 32px !important",
        },
      }}
      variant="contained"
      // className="text-prop rounded-16 bg-[#E98100] px-[32px] py-[20px] text-base font-medium text-white "
    >
      Save Changes
    </LoadingButton>
  );
};
export default SaveChangeBtn;
