const SuccessfulIcon = props => {
  return (
    <>
      <svg
        width="114"
        height="113"
        viewBox="0 0 114 113"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M88.2277 19.4501C89.3174 22.0855 91.409 24.1803 94.0426 25.2739L103.278 29.0993C105.913 30.191 108.007 32.285 109.099 34.9206C110.19 37.5562 110.19 40.5175 109.099 43.1531L105.276 52.3817C104.184 55.0185 104.182 57.9828 105.28 60.6183L109.095 69.8442C109.637 71.1496 109.915 72.5488 109.915 73.9619C109.916 75.375 109.637 76.7743 109.097 78.0799C108.556 79.3854 107.763 80.5716 106.764 81.5707C105.765 82.5697 104.578 83.3621 103.272 83.9025L94.0442 87.7251C91.4089 88.8148 89.3142 90.9064 88.2206 93.5401L84.3953 102.775C83.3037 105.411 81.2098 107.505 78.5743 108.597C75.9388 109.688 72.9776 109.688 70.3421 108.597L61.1138 104.774C58.4782 103.685 55.5181 103.687 52.8841 104.78L43.6492 108.6C41.0152 109.689 38.0565 109.688 35.4232 108.598C32.7898 107.507 30.6971 105.415 29.6047 102.782L25.7783 93.5445C24.6887 90.9091 22.5971 88.8143 19.9635 87.7206L10.7285 83.8953C8.09419 82.804 6.00092 80.7114 4.90886 78.0773C3.8168 75.4432 3.81532 72.4833 4.90474 69.8481L8.72723 60.6195C9.8162 57.9838 9.81398 55.0236 8.72105 52.3895L4.90405 43.1477C4.36287 41.8423 4.08421 40.4431 4.08398 39.0299C4.08376 37.6168 4.36197 36.2175 4.90273 34.912C5.44348 33.6064 6.23618 32.4202 7.23553 31.4212C8.23488 30.4221 9.42128 29.6297 10.727 29.0894L19.9553 25.2668C22.5883 24.178 24.6817 22.089 25.7761 19.4583L29.6014 10.223C30.693 7.58745 32.7869 5.49348 35.4224 4.40178C38.0579 3.31008 41.0191 3.31008 43.6546 4.40178L52.8829 8.22441C55.5185 9.31342 58.4786 9.3112 61.1126 8.21822L70.3514 4.40771C72.9865 3.31662 75.9471 3.31685 78.5821 4.40833C81.217 5.49981 83.3107 7.59318 84.4025 10.2281L88.2289 19.4661L88.2277 19.4501Z"
          fill="#FAEFD7"
        />
        <path
          d="M41.1256 56.4997L51.709 67.083L75.5215 43.2705M88.2277 19.4501C89.3174 22.0855 91.4089 24.1803 94.0426 25.2739L103.278 29.0993C105.913 30.191 108.007 32.285 109.099 34.9206C110.19 37.5562 110.19 40.5175 109.099 43.1531L105.276 52.3817C104.184 55.0185 104.182 57.9828 105.28 60.6183L109.095 69.8442C109.637 71.1496 109.915 72.5488 109.915 73.9619C109.916 75.375 109.637 76.7743 109.097 78.0799C108.556 79.3854 107.763 80.5716 106.764 81.5707C105.765 82.5697 104.578 83.3621 103.272 83.9024L94.0442 87.7251C91.4089 88.8148 89.3142 90.9064 88.2206 93.5401L84.3953 102.775C83.3037 105.411 81.2098 107.505 78.5743 108.597C75.9388 109.688 72.9776 109.688 70.3421 108.597L61.1138 104.774C58.4782 103.685 55.5181 103.687 52.8841 104.78L43.6492 108.6C41.0152 109.689 38.0565 109.688 35.4232 108.598C32.7898 107.507 30.6971 105.415 29.6047 102.782L25.7783 93.5445C24.6887 90.9091 22.5971 88.8143 19.9635 87.7206L10.7285 83.8953C8.09419 82.804 6.00092 80.7114 4.90886 78.0773C3.8168 75.4432 3.81532 72.4833 4.90474 69.8481L8.72723 60.6195C9.8162 57.9838 9.81398 55.0236 8.72105 52.3895L4.90405 43.1477C4.36287 41.8423 4.08421 40.4431 4.08398 39.0299C4.08376 37.6168 4.36197 36.2175 4.90273 34.912C5.44348 33.6064 6.23618 32.4202 7.23553 31.4212C8.23488 30.4221 9.42128 29.6297 10.727 29.0894L19.9553 25.2668C22.5883 24.178 24.6817 22.089 25.7761 19.4583L29.6014 10.223C30.693 7.58745 32.7869 5.49348 35.4224 4.40178C38.0579 3.31008 41.0191 3.31008 43.6546 4.40178L52.8829 8.22441C55.5185 9.31342 58.4786 9.3112 61.1126 8.21822L70.3514 4.40771C72.9865 3.31662 75.9471 3.31685 78.5821 4.40833C81.2171 5.49981 83.3107 7.59318 84.4025 10.2281L88.2289 19.4661L88.2277 19.4501Z"
          stroke="#F6A001"
          strokeWidth="7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default SuccessfulIcon;
