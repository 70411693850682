import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MyAccordion = ({data, bgcolor = "#F9FAFB", radius = "20px"}) => {
  return data.map(({title, content}, index) => (
    <div key={index} className="mb-3">
      <Accordion
        disableGutters
        elevation={0}
        sx={{bgcolor: bgcolor, borderRadius: radius}}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={"panel" + index + "a-content"}
          id={"panel" + index + "a-header"}
          sx={{px: "32px", py: "16px"}}
        >
          <p className="font-Bai text-lg font-semibold">{title}</p>
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </Accordion>
    </div>
  ));
};

export default MyAccordion;
