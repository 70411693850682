import {Button} from "@mui/material";
import MyModal from "components/mui-components/Modal";
import MailIcon2 from "components/Vectors/MailIcon2";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {selectEmail} from "redux/slice/AuthSlice";

const ResetSuccessfulModal = ({openModal, closeModal}) => {
  const userEmail = useSelector(selectEmail);
  return (
    <MyModal openModal={openModal} closeModal={closeModal} maxWidth={500}>
      <div className="flex flex-col items-center">
        <MailIcon2 />
        <p className="mt-6 mb-3 text-center font-Bai text-2xl font-semibold text-[#362E1F] md:text-4xl">
          We've sent you a mail
        </p>
        <p className="mb-2 max-w-[411px] text-center text-sm font-normal text-grey_30 md:text-base">
          We sent a one time verification code to{" "}
          <span className="text-primary">{userEmail}</span>
        </p>
        <Button
          variant="contained"
          fullWidth
          component={Link}
          to="/admin/enter-otp"
          sx={{mt: 3}}
        >
          Enter OTP
        </Button>
      </div>
    </MyModal>
  );
};

export default ResetSuccessfulModal;
