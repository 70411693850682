import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import UserIcon from "components/Vectors/user";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import moment from "moment";
import { useGetAllUsersQuery } from "services/admin/adminGeneralApi";
const NewUserAccount = () => {
  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
  };
  const { data, isLoading, isError, error } = useGetAllUsersQuery({
    is_paginated: "yes", per_page: 15
  });
  const headCells = [
    {
      name: "user",
      label: "user",
      options: {
        customBodyRender: value => (
          <UserDisplay
            truncate={false}
            name={value?.name}
            img={value?.img}
            badge={value?.badge}
            email={value?.username}
          />
        ),
      },
    },
    {
      name: "email",
      label: "email",
    },
    {
      name: "phone",
      label: "phone",
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "location",
      label: "Location",
    },
    {
      name: "date_joined",
      label: "date joined",
    },
    {
      name: "action",
      label: "",
    },
  ];
  const mockData = data?.data.map(user => ({
    user: {
      name:
        user?.user_type !== "Client"
          ? user?.professional_information?.business_name || user?.full_name
          : user?.full_name,
      img:
        user?.user_type !== "Client"
          ? user?.professional_information?.business_logo || user?.avatar
          : user?.avatar,
      username: user?.username ? `@${user?.username}` : "",
      badge: user?.user_type !== "Client" && user?.is_verified_account,
    },
    email: user?.email || "N/A",
    phone: user?.phone_number || "N/A",
    type: user?.is_hybrid?.toLowerCase() !== "no"
      ? "Hybrid"
      : user?.user_type || "Uncategorized",
    location: user?.location || "N/A",
    date_joined: moment(user?.created_at).format("DD MMM YYYY"),
    id: user?.id,
    action: (
      <IconButton
        component={Link}
        to={`/admin/users/${user?.id}`}
      >
        <ArrowCircleRightOutlinedIcon color="primary" />
      </IconButton>
    ),
  }));

  return (
    <div>
      <MyDataTable
        scrollX={false}
        isLoading={isLoading}
        data={mockData}
        isError={isError}
        error={error}
        headCells={headCells}
        options={options}
        emptyData={{
          icon: <UserIcon width={"48px"} height="45.33px" stroke={"#3B3D3B"} />,
          message: "No New User",
        }}
      />
    </div>
  );
};

export default NewUserAccount;
