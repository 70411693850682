import { useEffect, useState } from "react";
import "./style.scss";

export const generateId = () => Math.random().toString(36).substr(2, 9);

const OtpInput = ({
  length = 4,
  onChange = () => {},
  error,
  containerStyle,
  inputStyle,
}) => {
  const [otp, setOtp] = useState(Array(length).fill(""));
  const updateOtp = (index, value) => {
    setOtp(otp.map((n, i) => (i === index ? value : n)));
  };

  useEffect(() => {
    onChange(otp.join(""));
  }, [onChange, otp]);

  const [inputIds] = useState(
    Array(length)
      .fill("")
      .map((_field) => generateId())
  );

  const otpInputs = [];

  useEffect(() => {
    inputFocus(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInput = (value, index) => {
    if (/\D/.test(value)) {
      if (value === "Backspace") {
        // delete the current input
        updateOtp(index, "");
        // if current input is empty, focus the previous input and delete it
        if (otp[index] === "" && index > 0) {
          updateOtp(index - 1, "");
          inputFocus(index - 1);
        }
      }
      return;
    }

    updateOtp(index, value);

    if (index !== otp.length - 1) {
      inputFocus(index + 1);
    }
  };

  // @ts-ignore-next-line
  const inputFocus = (index) => otpInputs[index]?.focus();

  return (
    <div style={{ ...containerStyle }} className="otp-input-wrap">
      {inputIds.map((id, index) => (
        <div
          style={{ ...inputStyle }}
          className={`input-container !rounded-[16px]  ${
            error ? "invalid" : ""
          }`}
          key={`otp-input-${id}`}
          onClick={() => inputFocus(index)}
        >
          <input
            // readOnly
            maxLength={1}
            type='text'
            pattern="\d{1}"
            inputMode="numeric"
            autoComplete="one-time-code"
            id={id}
            ref={(element) => {
              otpInputs[index] = element;
            }}
            value={otp[index]}
            onKeyDown={(e) => handleInput(e.key, index, e)}
          />
        </div>
      ))}
    </div>
  );
};

export default OtpInput;
