import "components/forms/formInput.styles.scss";
import OtpInput from "components/forms/OtpInput";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import OpenMailIcon from "components/Vectors/OpenMailIcon";
import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {UserSkeleton} from ".";
import {
  useUserPwdVerifyOtpMutation,
  useUserSendPwdOtpMutation,
} from "services/auth.api";
import {handleError} from "utils";
import {LoadingButton} from "@mui/lab";
import {showToast} from "redux/store.hooks";
import {CircularProgress} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
  setUserEmail,
  selectEmail,
  setUserPhoneNumber,
  selectPhoneNumber,
  setUserOTP,
} from "redux/slice/AuthSlice";

const UserForgotPassword = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(selectEmail);
  const userPhoneNumber = useSelector(selectPhoneNumber);
  const [presentStep, setPresentStep] = useState(1);
  const [resendOtp, setResendOtp] = useState(false);
  const [otp, setotp] = useState("");
  const [error, seterror] = useState(false);

  const [sendOtp, {isLoading: isSendOtpLoading}] = useUserSendPwdOtpMutation();
  const [verifyOtp, {isLoading: isVerifyOtpLoading}] =
    useUserPwdVerifyOtpMutation();

  // countdown timer for otp to be resent
  const [counter, setCounter] = useState(12);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearTimeout(timer);
  }, [counter]);

  const handleNextStep = () => {
    setPresentStep(prev => prev + 1);
  };

  const navigate = useNavigate();

  const otpMethods = useForm({
    mode: "onSubmit",
  });
  const pwdMethods = useForm();

  const onOtpSubmit = async () => {
    const formData = new FormData();

    formData.append("otp", otp);

    if (otp.length < 4) {
      seterror(true);
    } else {
      try {
        let res = await verifyOtp(formData).unwrap();
        dispatch(setUserOTP(otp));
        navigate("/user/reset-password");
        showToast(res?.message, "success");
      } catch (error) {
        handleError(error);
      }
    }
  };

  const onPwdSubmit = async data => {
    try {
      let res = await sendOtp(data).unwrap();
      handleNextStep();
      dispatch(setUserEmail(data.email));
      dispatch(setUserPhoneNumber(res?.data.data.phone_number));
      showToast(res?.message, "success");
    } catch (error) {
      handleError(error);
    }
  };

  const handleResend = async () => {
    try {
      let res = await sendOtp({email: userEmail}).unwrap();
      showToast(res?.message, "success");
      setResendOtp(true);
      setCounter(15);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <UserSkeleton>
      {presentStep <= 1 ? (
        // forget password  card
        <div
          className="my-8 flex h-fit w-full max-w-[600px] flex-col items-center justify-center rounded-2xl bg-grey_90 p-5 md:h-fit
        md:p-8 lg:my-16 lg:rounded-[35px] lg:p-9 lg:px-20
        "
        >
          <OpenMailIcon />
          <p className="mt-8 text-center font-Bai text-2xl font-semibold leading-[60px] tracking-[-4%] md:mt-[30px] md:text-left md:text-4xl">
            Reset Password
          </p>
          <p className="text-prop mt-2 w-full max-w-[400px] text-center font-Mulish text-base font-normal">
            To Reset your password, Enter the Email linked to your account, an
            OTP will be sent to you
          </p>
          <FormProvider {...pwdMethods}>
            <form
              key={1}
              className="darkBgForm mt-[24px] w-full"
              onSubmit={pwdMethods.handleSubmit(onPwdSubmit)}
            >
              <ValidatedInput2
                placeholder="email@example.com"
                type="email"
                name="email"
                rules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
              />
              <div className="mt-4 md:mt-[20px] ">
                <LoadingButton
                  fullWidth
                  loadingIndicator={
                    <CircularProgress
                      sx={{
                        color: "#fff",
                      }}
                      size="1.2rem"
                    />
                  }
                  sx={{
                    fontFamily: "Bai Jamjuree",
                    borderRadius: "16px",
                    fontWeight: "500",
                    marginTop: "8px",
                  }}
                  type="submit"
                  variant="contained"
                  loading={isSendOtpLoading}
                >
                  Send OTP
                </LoadingButton>
                <button
                  onClick={() => navigate(-1)}
                  type="button"
                  className="mt-[8px]  w-full py-[16px] font-Bai font-medium text-grey_20 "
                >
                  Cancel
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      ) : (
        // verify otp card
        <div className="">
          <FormProvider {...otpMethods}>
            <form key={2} onSubmit={otpMethods.handleSubmit(onOtpSubmit)}>
              <div
                className="flex h-fit w-full max-w-[600px] flex-col items-center rounded-2xl
              bg-grey_90 p-4 md:h-[450px] md:p-[32px] lg:rounded-[35px] lg:py-[60px] lg:px-[3rem]"
              >
                <p className="font-Bai text-2xl font-semibold leading-[60px] tracking-[-4%] md:text-4xl">
                  Verify it’s you
                </p>
                <p className="text-center font-Mulish text-base font-[400] text-grey_30">
                  We sent a one time verification code to
                  <span className="text-primary "> {userEmail}</span> and
                  <span className="text-primary "> {userPhoneNumber}</span>
                </p>
                <div className="">
                  <OtpInput
                    error={error}
                    value={otp}
                    onChange={val => {
                      if (val.length === 4) seterror(false);
                      setotp(val);
                    }}
                  />
                </div>
                <p className="text-primary">
                  {counter > 0 && <span>00 : {counter}</span>}
                </p>{" "}
                {counter <= 0 && (
                  <p className="font-Mulish text-[13px] font-normal ">
                    Didn’t recieve OTP?{" "}
                    {isSendOtpLoading ? (
                      <span className="font-Bai text-primary ">Sending...</span>
                    ) : (
                      <button
                        type="button"
                        // onClick={onResend}
                        onClick={handleResend}
                        className="cursor-pointer font-Bai text-primary "
                      >
                        Resend code
                      </button>
                    )}
                  </p>
                )}
                <LoadingButton
                  fullWidth
                  loadingIndicator={
                    <CircularProgress
                      sx={{
                        color: "#fff",
                      }}
                      size="1.2rem"
                    />
                  }
                  sx={{
                    fontFamily: "Bai Jamjuree",
                    borderRadius: "16px",
                    fontWeight: "500",
                    marginTop: "8px",
                  }}
                  loading={isVerifyOtpLoading}
                  type="submit"
                  variant="contained"
                >
                  Verify
                </LoadingButton>
                <button
                  type="button"
                  className="mt-[8px]  w-full py-[16px] font-Bai font-medium text-grey_20 "
                >
                  <Link to="/user/login">Cancel</Link>
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      )}
    </UserSkeleton>
  );
};
export default UserForgotPassword;
