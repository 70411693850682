import {useNavigate, Link} from "react-router-dom";
import React, {useState} from "react";
import {Button, Tab} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {EachRequestDetails} from "./widgets/data";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import {CustomTabStyle2} from "App";
import {BadgeText} from "pages/LandingPage/widgets/DashboardLayout/MenuLinks";
import JobRequestContent from "./widgets/JobRequestContent";
import HandHeartIcon from "components/Vectors/HandHeartIcon";

const tabOptions = [
  {
    text: "Pending",
    value: "pending",
    badge: true,
  },
  {
    text: "Declined",
    value: "declined",
  },
  {
    text: "Withdrawn",
    value: "withdrawn",
  },
];

const EachClientJobRequest = () => {
  const [activeTab, setActiveTab] = useState("pending");

  const navigate = useNavigate();

  return (
    <div>
      {/* <BackBtn text="Job Requests" /> */}
      <Link
        to={"/client/job-request"}
        className="flex w-fit items-center gap-x-2.5 font-medium text-grey_20"
      >
        <span className="flex h-6 w-6 items-center justify-center rounded-full bg-white">
          <KeyboardArrowLeftIcon sx={{fontSize: "1rem"}} />
        </span>
        Job Requests
      </Link>

      <section className="mt-8 grid grid-cols-1 gap-10 rounded-20 bg-white p-4 md:p-6 lg:grid-cols-2">
        <div>
          <TabContext value={activeTab}>
            <div className="mb-6 flex items-center justify-between md:mb-8">
              <TabList
                variant="scrollable"
                value={activeTab}
                indicator="none"
                onChange={(e, newValue) => {
                  setActiveTab(newValue);
                  navigate(`?tab=${newValue}`);
                }}
                sx={{
                  fontWeight: 500,
                  color: "#6E726E",
                  backgroundColor: "#F9FAFB !important",
                  borderWidth: "1px",
                  borderRadius: "16px",
                  width: "fit-content",
                  padding: "8px",
                  ...CustomTabStyle2,
                  textTransform: "unset",
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
                aria-label="basic tabs example"
              >
                {tabOptions.map(({text, value, badge}, index) => (
                  <Tab
                    key={index}
                    label={
                      <div className="flex items-center gap-2">
                        {text}
                        {badge && <BadgeText color="bg-[#D92D20]" number={2} />}
                      </div>
                    }
                    value={value}
                  ></Tab>
                ))}
                s
              </TabList>
            </div>

            <TabPanel value="pending">
              <JobRequestContent
                data={EachRequestDetails.filter(el => el.status === "pending")}
                title="2 New Requests"
                status="pending"
              />
            </TabPanel>
            <TabPanel value="withdrawn">
              <JobRequestContent
                data={EachRequestDetails.filter(
                  el => el.status === "withdrawn",
                )}
                title="Withdrawn Requests"
                status="withdrawn"
              />
            </TabPanel>
            <TabPanel value="declined">
              <JobRequestContent
                data={EachRequestDetails.filter(el => el.status === "declined")}
                title="Declined Requests"
                status="declined"
              />
            </TabPanel>
          </TabContext>
        </div>

        {/* RIGHT COLUMN */}
        <div className="flex flex-col gap-6">
          <img
            src="/images/jobRequestImage.png"
            alt="jobRequestImage"
            className="h-[290px]"
          />

          <h3 className="font-Bai text-xl font-bold text-grey_20 md:text-4xl">
            I will repair everything that is faulty in your house
          </h3>

          <div>
            <h2 className="font-Bai text-base font-semibold capitalize text-grey_30 md:text-2xl">
              Description
            </h2>
            <p className="mt-2 mb-8 font-medium text-grey_20">
              Ac duis accumsan consectetur pellentesque pellentesque enim eu
              duis eros. Egestas augue quis posuere semper. Mus consectetur
              magna tempus massa. Semper aliquet amet a pulvinar mi gravida
              feugiat id pellentesque ac ultricies vivamus tincidunt habitant.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="rounded-20 bg-grey_80 p-6">
              <div className="flex items-center justify-between text-grey_20">
                <span>Category</span>
                <GridViewIcon />
              </div>
              <p className="mt-6 text-lg font-bold text-ltext">
                Repairs & Maintenance
              </p>
            </div>
            <div className="rounded-20 bg-grey_80 p-6">
              <div className="flex items-center justify-between text-grey_20">
                <span>Location</span>
                <FmdGoodOutlinedIcon />
              </div>
              <p className="mt-6 text-lg font-bold text-ltext">
                Physical - Ketu, Lagos{" "}
              </p>
            </div>
            <div className="rounded-20 bg-grey_80 p-6">
              <div className="flex items-center justify-between text-grey_20">
                <span>Duration</span>
                <AccessTimeOutlinedIcon />
              </div>
              <p className="mt-6 text-lg font-bold text-ltext">4 Weeks </p>
            </div>

            <div className="rounded-20 bg-grey_80 p-6">
              <div className="flex items-center justify-between text-grey_20">
                <span>Requested hands</span>
                <HandHeartIcon />
              </div>
              <p className="mt-6 text-lg font-bold text-ltext">3/5 </p>
            </div>
          </div>

          <div>
            <p className="font-Bai text-base capitalize text-grey_30 md:text-2xl">
              price
            </p>
            <p className=" mt-2 font-Bai text-2xl font-bold text-grey_20 md:text-4xl">
              ₦50,000
            </p>
          </div>

          <Button variant="outlined" sx={{width: "fit-content"}}>
            View job details
          </Button>
        </div>
      </section>
    </div>
  );
};

export default EachClientJobRequest;
