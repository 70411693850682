import {forwardRef, useState} from "react";
import "./style.scss";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {Link} from "react-router-dom";
const InputField2 = forwardRef(
  (
    {
      label,
      error,
      className = "",
      spaceY = true,
      variant = "free",
      bg = "#F9FAFB",
      prefixIcon,
      suffixIcon,
      isInvalid,
      value = "",
      showForgetPwd = false,
      textSize = "text-[14px]",
      otherStyle,
      bordered = true,
      suffixComponent,
      ...props
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
      <div className="">
        <div className="mb-[6px] flex w-full items-center justify-between">
          {label && (
            <label
              className={`font-Mulish font-[400] ${textSize} text-grey_30`}
            >
              {label}
              {props.required ? <span className="text-error">*</span> : ""}
            </label>
          )}

          {showForgetPwd && (
            <button
              type="button"
              className={`font-[400]  ${textSize} text-primary`}
            >
              <Link to="/user/forgot-password">Forgot Password</Link>
            </button>
          )}
        </div>
        <div
          style={{background: bg}}
          className={`form-group ${
            bordered && "border-[1.55px] border-[#DCDCE5]"
          } ${
            props.rounded_none ? "" : "rounded-[1rem]"
          }  focus-within:border-primary
        ${className}`}
        >
          {prefixIcon && (
            <span className="icon-btn -ml-1 mr-2 select-none">
              {prefixIcon}
            </span>
          )}
          <input
            {...props}
            ref={ref}
            onBlur={props.onBlur}
            defaultValue={value}
            value={props.mainValue}
            type={showPassword ? "text" : props.type}
            className={`placeholder-neutral  z-10 w-full bg-transparent placeholder:text-sm 
            placeholder:font-normal focus:outline-none ${otherStyle} `}
          />
          {suffixIcon && (
            <span className="icon-btn ml-1 select-none">{suffixIcon}</span>
          )}
          {suffixComponent && suffixComponent}
          {props.type === "password" && (
            <button
              type="button"
              className="icon-btn -mr-1 select-none"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <VisibilityOffOutlinedIcon sx={{color: "#8585A3"}} />
              ) : (
                <RemoveRedEyeOutlinedIcon sx={{color: "#8585A3"}} />
              )}
            </button>
          )}
        </div>
      </div>
    );
  },
);

export default InputField2;
