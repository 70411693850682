import {Avatar} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import SmallTickIcon from "components/Vectors/SmallTickIcon";
import moment from "moment";
import {Link} from "react-router-dom";
const JobDetailSingleOffer = ({offer, fullWidth}) => {
  const {name, img, rating, pitch_message, created_at, is_verified} = offer;
  return (
    <div
      className={`rounded-16 bg-white p-3 md:rounded-20 md:p-6 ${
        fullWidth ? "md:min-w-[480px]" : ""
      }`}
    >
      <div className="flex items-start justify-between">
        <Link
          to={`/user-profile/${offer?.user_id}?type=service provider`}
          className="flex items-center gap-x-3"
        >
          <div className="relative h-[38px] w-[38px] md:h-[48px] md:w-[48px]">
            <Avatar
              alt={name}
              src={img}
              sx={{
                width: {xs: 38, md: 48},
                height: {xs: 38, md: 48},
              }}
            />
            {is_verified && (
              <div className="absolute top-[-3px] right-[-2px] md:top-[-2px]">
                <SmallTickIcon />
              </div>
            )}
          </div>

          <div className="flex flex-col">
            <span className="cursor-pointer font-Bai text-sm font-bold text-grey_20 transition-colors hover:text-primary md:text-lg">
              {name}
            </span>
            <span className="text-xs text-primary md:text-sm">
              <StarIcon fontSize="small" sx={{color: "#E98100"}} />
              {rating?.toFixed(1)}
            </span>
          </div>
        </Link>
        <span className="text-xs text-grey_30 md:text-sm">
          {moment(created_at).fromNow()}
        </span>
      </div>
      <div className="mt-4">
        <p className="mb-1 text-grey_20">Request Message</p>
        <p className="text-xs font-medium text-grey_30 md:text-[13px]">
          {pitch_message}
        </p>
      </div>
    </div>
  );
};

export default JobDetailSingleOffer;
