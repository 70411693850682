import { Fade, IconButton, Slide } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import "./style.scss";
const MyModal = ({
  children,
  closeModal,
  maxWidth = 600,
  openModal = true,
  title,
  className = "",
  closeOnOverlayClick = true,
  hideScroll = false,
  postJobModal = false,
}) => {
  return (
    <Fade in={openModal}>
      <div
        onClick={e => {
          if (e.target === e.currentTarget && closeOnOverlayClick) {
            closeModal();
          }
        }}
        className="modal-wrap"
      >
        <Slide direction={postJobModal ? "left" : "up"} in={openModal} mountOnEnter>
          <div
            style={{ maxWidth }}
            className={`modal-content ${className} ${hideScroll ? "noScrollBar" : "scrollbar-style"
              }`}
          >
            {title && <p className="modal-title w-[85%]">{title}</p>}
            {postJobModal ?
              null
              : <IconButton
                className="close-modal-btn"
                onClick={closeModal}
                sx={{ border: "1px solid #C9C9CF", borderRadius: "10px !important" }}
              >
                <ClearIcon sx={{ fontSize: "18px" }} />
              </IconButton>}
            {children}
          </div>
        </Slide>
      </div>
    </Fade>
  );
};

export default MyModal;
