import {useNavigate, useParams} from "react-router-dom";
import {Button} from "@mui/material";
import TicketSenderInfo from "./TicketSenderInfo";
import TicketContent from "./TicketContent";
import {useGetSingleTicketQuery} from "services/user/userRegApi";
import BackBtn from "components/BackBtn";
import CircularLoader from "./Loader/CircularLoader";
import ErrorMsg from "./mui-components/ErrorMsg";
import {useGetUser} from "hooks/getUserHook";
import {convertToSlug} from "utils";

const EachTicket = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {data: ticket, isLoading, error} = useGetSingleTicketQuery(id);
  const {user} = useGetUser();
  if (isLoading) return <CircularLoader />;
  if (error) return <ErrorMsg error={error} />;
  return (
    <section>
      <BackBtn text="Tickets" />
      <div className="mt-8">
        <div className="flex items-center justify-between">
          <h2 className="settings-font-h2 !text-4xl">Ticket Details</h2>
          {ticket?.data.engagement_id && (
            <Button
              variant="contained"
              onClick={() =>
                navigate(
                  `/${
                    user?.admin_permissions
                      ? "admin"
                      : convertToSlug(user?.user_type)
                  }/engagements/${ticket?.data.engagement_id}`,
                )
              }
            >
              View Engagement
            </Button>
          )}
        </div>

        {/* grid  */}
        <div className="mt-10 flex gap-10">
          <TicketSenderInfo ticket={ticket?.data} />
          <TicketContent ticket={ticket?.data} />
        </div>
      </div>
    </section>
  );
};
export default EachTicket;
