function NotFoundSvg(props) {
  return (
    <svg
      width="165"
      height="180"
      viewBox="0 0 165 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M105.439 110.281C105.041 110.4 104.655 110.535 104.279 110.688C104.077 110.77 103.86 110.808 103.643 110.801C103.425 110.793 103.212 110.741 103.016 110.647C102.82 110.553 102.646 110.419 102.506 110.254C102.365 110.089 102.261 109.896 102.2 109.689L95.9932 88.9814L25.3027 109.871L42.1558 166.105C42.7458 168.071 44.0978 169.723 45.9145 170.699C47.7312 171.675 49.8639 171.895 51.8436 171.31L107.607 154.829C109.587 154.243 111.251 152.901 112.234 151.097C113.217 149.293 113.438 147.175 112.848 145.209L108.865 131.914C108.802 131.707 108.783 131.489 108.81 131.275C108.837 131.06 108.909 130.854 109.021 130.669C109.133 130.483 109.283 130.324 109.461 130.199C109.639 130.075 109.842 129.989 110.055 129.947C110.458 129.873 110.857 129.777 111.25 129.661C117.103 127.926 120.545 122.19 118.942 116.836C117.339 111.482 111.291 108.552 105.439 110.281Z"
        fill="#B093DD"
      />
      <g style={{mixBlendMode: "multiply"}} opacity="0.25">
        <path
          d="M86.5366 163.094C86.5366 163.094 55.6083 171.714 48.7967 166.709C43.0574 162.502 42.7932 158.165 34.6473 141.053C34.5555 155.799 42.66 180 42.66 180L86.5366 163.094Z"
          fill="#99ADF9"
        />
      </g>
      <g style={{mixBlendMode: "multiply"}} opacity="0.25">
        <path
          d="M51.6348 104.868C51.6348 104.868 54.6529 119.215 65.592 115.984C76.5311 112.752 71.1479 99.0972 71.1479 99.0972L51.6348 104.868Z"
          fill="#99ADF9"
        />
      </g>
      <path
        d="M76.9203 25.3398L13.69 44.0196C11.7107 44.6049 10.0465 45.9469 9.06358 47.7505C8.08066 49.5541 7.85947 51.6715 8.44866 53.637L25.3039 109.871L49.5626 102.715C49.7542 102.658 49.9557 102.641 50.1543 102.665C50.3529 102.69 50.5441 102.755 50.7158 102.857C50.8874 102.959 51.0357 103.096 51.1512 103.258C51.2666 103.42 51.3467 103.605 51.3862 103.8C51.4575 104.161 51.5478 104.522 51.657 104.884C53.4041 110.693 59.1805 114.113 64.5704 112.519C69.9602 110.925 72.9216 104.929 71.1788 99.1133C71.0696 98.7534 70.9473 98.3999 70.8076 98.0573C70.7346 97.8729 70.7011 97.6756 70.7092 97.4777C70.7172 97.2798 70.7667 97.0857 70.8544 96.9079C70.9421 96.73 71.0662 96.5722 71.2187 96.4445C71.3711 96.3169 71.5487 96.2221 71.7401 96.1663L95.9988 88.9971L76.9203 25.3398Z"
        fill="#E98100"
      />
      <g style={{mixBlendMode: "multiply"}} opacity="0.52">
        <path
          d="M17.2407 87.0864C17.2407 87.0864 8.56847 56.3757 13.598 49.6121C17.8369 43.9132 25.3386 40.578 34.1986 37.9584C19.3482 37.8673 0.212891 43.5185 0.212891 43.5185L17.2407 87.0864Z"
          fill="#FEC272"
        />
      </g>
      <path
        d="M76.9289 25.3362L82.3602 43.4564C82.4482 43.745 82.4729 44.049 82.4327 44.3479C82.3925 44.6468 82.2883 44.9337 82.1271 45.1892C81.9659 45.4448 81.7515 45.6631 81.4983 45.8295C81.2451 45.996 80.9589 46.1066 80.659 46.1541C80.0926 46.2378 79.5329 46.3609 78.9839 46.5227C73.1333 48.2576 69.6893 53.9933 71.2944 59.3474C72.8996 64.7015 78.9381 67.6377 84.7931 65.9094C85.3423 65.7479 85.8788 65.5464 86.3982 65.3066C86.6752 65.1855 86.975 65.1244 87.2776 65.1273C87.5802 65.1302 87.8788 65.1971 88.1533 65.3234C88.4279 65.4498 88.6722 65.6328 88.8701 65.8602C89.0679 66.0876 89.2147 66.3543 89.3006 66.6424L95.9943 88.9783L119.085 82.1517C119.746 81.9512 120.301 81.499 120.629 80.8937C120.956 80.2884 121.029 79.5791 120.832 78.9206L120.812 78.8555C119.065 73.0439 122.031 67.0414 127.42 65.4497C132.81 63.858 138.593 67.2756 140.334 73.0873L140.353 73.1501C140.552 73.8105 141.006 74.3658 141.616 74.6943C142.226 75.0227 142.942 75.0976 143.607 74.9023L159.222 70.2877C161.202 69.7024 162.866 68.3603 163.849 66.5567C164.832 64.7532 165.053 62.6357 164.464 60.6702L149.832 11.8479C149.242 9.88245 147.891 8.23001 146.074 7.254C144.258 6.27799 142.125 6.05835 140.146 6.6434L76.9289 25.3362Z"
        fill="#FCECD9"
      />
      <g style={{mixBlendMode: "multiply"}} opacity="0.19">
        <path
          d="M78.9759 46.5207C78.9759 46.5207 72.9134 49.7041 75.1235 57.0901C77.3336 64.4761 82.5029 65.4368 85.9032 66.0396C79.7883 71.3265 61.7188 64.0489 61.7188 64.0489L68.5456 45.8137L78.9759 46.5207Z"
          fill="#FF97C9"
        />
      </g>
      <g style={{mixBlendMode: "multiply"}} opacity="0.19">
        <path
          d="M159.989 45.7235C159.989 45.7235 150.338 15.3012 142.404 12.3671C135.717 9.89719 126.145 8.98857 117.289 11.606C129.678 3.46315 150.266 0 150.266 0L159.989 45.7235Z"
          fill="#FF97C9"
        />
      </g>
      <path
        d="M33.6998 72.1721C35.7647 72.1721 37.4386 70.51 37.4386 68.4596C37.4386 66.4092 35.7647 64.7471 33.6998 64.7471C31.6349 64.7471 29.9609 66.4092 29.9609 68.4596C29.9609 70.51 31.6349 72.1721 33.6998 72.1721Z"
        fill="white"
      />
      <path
        d="M61.4322 63.979C63.4971 63.979 65.171 62.3169 65.171 60.2665C65.171 58.2161 63.4971 56.554 61.4322 56.554C59.3673 56.554 57.6934 58.2161 57.6934 60.2665C57.6934 62.3169 59.3673 63.979 61.4322 63.979Z"
        fill="white"
      />
      <path
        d="M45.5156 73.0486C45.5156 73.0486 45.6204 70.158 49.0535 69.1453C52.4866 68.1326 54.0612 70.5266 54.0612 70.5266"
        fill="white"
      />
      <path
        d="M45.5156 73.0486C45.5156 73.0486 45.6204 70.158 49.0535 69.1453C52.4866 68.1326 54.0612 70.5266 54.0612 70.5266"
        stroke="white"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M56.9634 136.764C59.0283 136.764 60.7023 135.102 60.7023 133.052C60.7023 131.002 59.0283 129.339 56.9634 129.339C54.8985 129.339 53.2246 131.002 53.2246 133.052C53.2246 135.102 54.8985 136.764 56.9634 136.764Z"
        fill="white"
      />
      <path
        d="M84.6978 128.57C86.7627 128.57 88.4366 126.908 88.4366 124.858C88.4366 122.807 86.7627 121.145 84.6978 121.145C82.6329 121.145 80.959 122.807 80.959 124.858C80.959 126.908 82.6329 128.57 84.6978 128.57Z"
        fill="white"
      />
      <path
        d="M109.06 46.1878C109.648 48.1525 108.187 49.1001 106.206 49.6856C104.225 50.2711 102.48 50.2711 101.891 48.3064C101.739 47.8357 101.684 47.3397 101.727 46.8475C101.771 46.3552 101.914 45.8766 102.146 45.4396C102.378 45.0027 102.696 44.6161 103.081 44.3027C103.466 43.9893 103.91 43.7552 104.387 43.6143C104.864 43.4733 105.364 43.4283 105.859 43.4818C106.354 43.5354 106.833 43.6864 107.268 43.926C107.703 44.1657 108.086 44.4892 108.394 44.8775C108.702 45.2659 108.928 45.7113 109.06 46.1878Z"
        fill="#1C3177"
      />
      <path
        d="M136.805 37.9915C137.394 39.9583 135.931 40.9038 133.95 41.4893C131.969 42.0748 130.225 42.0748 129.635 40.1101C129.483 39.6394 129.428 39.1434 129.472 38.6512C129.515 38.1589 129.658 37.6803 129.89 37.2433C130.123 36.8064 130.441 36.4199 130.825 36.1064C131.21 35.793 131.654 35.5589 132.131 35.418C132.608 35.277 133.109 35.232 133.603 35.2855C134.098 35.3391 134.577 35.4901 135.012 35.7297C135.448 35.9694 135.83 36.2929 136.138 36.6813C136.446 37.0696 136.672 37.515 136.805 37.9915Z"
        fill="#1C3177"
      />
      <path
        d="M127.686 49.1546C127.549 51.026 125.26 50.8265 121.943 51.8067C118.625 52.7869 117.04 54.4089 115.673 52.7045C113.925 50.5359 116.924 47.1096 120.241 46.1295C123.559 45.1493 127.889 46.3962 127.686 49.1546Z"
        fill="#1C3177"
      />
      <path
        d="M79.034 132.422L65.627 136.383C64.3531 136.76 63.6276 138.09 64.0067 139.355C64.3857 140.62 65.7258 141.341 66.9997 140.964L80.4067 137.003C81.6806 136.627 82.4061 135.296 82.027 134.031C81.648 132.766 80.3079 132.046 79.034 132.422Z"
        fill="white"
      />
      <path
        d="M139.312 122.655L143.04 129.356"
        stroke="#E98100"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M147.238 99.6722L154.225 96.4346"
        stroke="#E98100"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M146.811 112.069L155.819 115.01"
        stroke="#E98100"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default NotFoundSvg;
