import JobIcon from "components/Vectors/JobIcon";

const EmptyJob = ({title}) => {
  return (
    <div className="flex h-[200px] w-full flex-col items-center justify-center p-4 md:h-[400px] ">
      <JobIcon height="55" width="43" stroke="#3B3D3B" />
      <p className="text-prop pt-4 font-Bai text-[32px] font-semibold text-grey_20 ">
        {title || "No job posted yet"}
      </p>
    </div>
  );
};
export default EmptyJob;
