import { useState } from "react";
import { CircularProgress, LinearProgress } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import { Country } from "country-state-city";
import AutoCompleteField from "components/forms/AutoCompleteField";
import UploadImage3 from "components/forms/UploadImage3";
import { PrevBtn } from "..";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import { handleError } from "utils";
import { LoadingButton } from "@mui/lab";
import { useSubmitUserProfessionalInfoMutation } from "services/user/userRegApi";
import {
  useGetAllStatesQuery,
  useGetCityByStateQuery,
} from "services/generalApi";
import RadioBtns from "components/forms/RadioBtns";

const ProfessionalInfo = ({ onNextClick, onPrevClick, onSkip, presentStep, steps }) => {
  const [useBusinessName, setUseBusinessName] = useState("false");
  const [useProfilePhoto, setUseProfilePhoto] = useState("true");
  const [useLocation, setUseLocation] = useState("false")
  const [createInfo, { isLoading }] = useSubmitUserProfessionalInfoMutation();

  const { data: allStates, isLoading: isStatesLoading } = useGetAllStatesQuery({
    pollingInterval: 3000,
  });


  const methods = useForm({
    mode: "all",
  });

  const { watch, setValue } = methods;

  const countryValue = watch("countryValue")
  const watchStates = watch("state");
  const watchCity = watch("city");
  const watchCityId = watch("cityId")
  const watchStateId = watch("cityId")

  const allCountries = Country.getAllCountries()?.filter(
    country => country.name === "Nigeria",
  );

  const stateCities = allStates?.data
    .find(state => state?.name?.toLowerCase() === watchStates?.toLowerCase())
    ?.cities.map(el => el?.name);

  const selectedStateID = allStates?.data.find(
    state => state?.name?.toLowerCase() === watchStates?.toLowerCase(),
  )?.id;

  const { data: cityData } = useGetCityByStateQuery(selectedStateID);

  const cityID = cityData?.data.find(
    city => city?.name?.toLowerCase() === watchCity?.toLowerCase(),
  )?.id;

  const states = allStates?.data.map(state => state.name);

  const onSubmit = async data => {
    const formData = new FormData();
    formData.append("about", data.about);
    formData.append("years_of_experience", data.years_of_experience);
    formData.append("location_option", "Choose new location");
    formData.append("country", data.personal_country);
    formData.append("state_id", selectedStateID);
    formData.append("city_id", cityID);
    formData.append("residential_address", data.office_address);

    if (useBusinessName === "false") {
      formData.append("business_name_option", "Use my name");
    }
    if (useBusinessName === "true") {
      formData.append("business_name_option", "Enter a business name");
      formData.append("business_name", data.business_name);
    }
    if (useProfilePhoto === "true") {
      formData.append("business_logo_option", "Use Profile photo");
    }
    if (useProfilePhoto === "false") {
      formData.append("business_logo_option", "Upload logo");
      formData.append("business_logo", data.business_logo[0]);
    }

    try {
      await createInfo(formData).unwrap();
      onNextClick();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <section className="w-full rounded-24 bg-white py-10">
      <div className="flex flex-col items-start px-6 md:px-8">
        <div className="relative w-full mb-7">
          <LinearProgress
            className="flex-grow !bg-[#efefef] md:flex-grow-0"
            variant="determinate"
            value={(presentStep / steps.length) * 100}
            sx={{
              borderRadius: 12,
              height: 4,
              maxHeight: 6,
              mb: 2,
            }}
          />{" "}
          <p className="flex items-start">
            <span className="mr-1 text-3xl font-bold text-grey">
              {presentStep}{" "}
            </span>
            <span className="text-base font-normal text-grey_40">
              / {steps.length}
            </span>
          </p>
        </div>
        <h1 className="text-prop  font-Bai text-2xl font-bold md:text-[2rem]">
          Professional Information
        </h1>
        <p className=" mt-2 mb-8 max-w-[327px] font-Mulish text-xs text-[#69697B]">
          Fill up the forms with accurate information Offered. You can always confirm each requirement
        </p>
      </div>
      {/* <div className="my-4 w-full border-t-[1px] border-grey_60 md:my-5"></div> */}
      {/* form content  */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="mb-[50px] flex flex-col gap-4 px-6 md:px-8">

            <RadioBtns
              name="business-logo"
              options={[
                { label: "Use your name", value: "false" },
                { label: "Enter a business name", value: "true" },
              ]}
              onChange={val => setUseBusinessName(val)}
              selectedOption={useBusinessName}
              label="Business Name"
            />
            <p className={`font-Mulish font-[400] text-[13px] text-[#E98100] mt-[-15px]`}
            >Use your Name from Basic Information</p>

            {
              useBusinessName === "true" && (
                <div className="mt-[10px]">
                  <ValidatedInput2
                    textSize="text-[14px]"
                    label="Business Name"
                    placeholder="Olobiriyipo and kids"
                    name="business_name"
                    rules={{ required: "Business name is required" }}
                    bg="#F4F4F5"
                  />
                </div>
              )
            }

            <div>
              <ValidatedTextArea2
                maxLength="250"
                label="About business"
                name="about"
                placeholder="A brief overview of your business, company, expertise"
                height="h-[130px]"
                bg2="#F4F4F5"
              />
            </div>

            {/* years of experience  */}
            <ValidatedInput2
              type="number"
              label="Years of Experience"
              placeholder="12"
              name="years_of_experience"
              required={true}
              bg="#F4F4F5"
            />
            {/* Location  */}
            <div className="flex flex-col ">

              <div className="mt-5 flex flex-col gap-8  ">


                <div className="flex items-center gap-4 ">

                  {/* countries  */}
                  <AutoCompleteField
                    name="personal_country"
                    selectOption={allCountries.map(item => item.name)}
                    label="Country"
                    placeholder="Select Country"
                    className="font-Mulish text-sm text-grey_30"
                    bg="#F4F4F5"
                  />
                  {/* state  */}
                  <AutoCompleteField
                    name="state"
                    selectOption={states}
                    label="State"
                    loading={isStatesLoading}
                    className="font-Mulish text-sm text-grey_30"
                    placeholder="Lagos"
                    bg="#F4F4F5"
                  />
                </div>
                {/* city */}
                <AutoCompleteField
                  name="city"
                  selectOption={stateCities}
                  label="City"
                  loading={isStatesLoading}
                  className="font-Mulish text-sm text-grey_30"
                  placeholder="Ikeja"
                  bg="#F4F4F5"
                />
                <ValidatedTextArea2
                  type="text"
                  label="Professional Address"
                  name="office_address"
                  placeholder="Ikeja"
                  bg2="#F4F4F5"
                />
                {/* business image */}
                <RadioBtns
                  name="business-logo"
                  options={[
                    { label: "Use Profile photo", value: "true" },
                    { label: "Upload logo", value: "false" },
                  ]}
                  onChange={val => setUseProfilePhoto(val)}
                  selectedOption={useProfilePhoto}
                  label="Business Logo"
                />
                <p className={`font-Mulish font-[400] text-[13px] text-[#E98100] mt-[-15px]`}>Use your Photo from Basic Information</p>
                {useProfilePhoto === "false" && (
                  <div className="my-[8px] flex flex-col gap-[5px] ">
                    <label
                      className="text-sm font-normal text-grey_30 "
                      htmlFor="logo"
                    >
                      Logo
                    </label>
                    <UploadImage3
                      id="logo"
                      name="business_logo"
                      required={true}
                      fileText="photo"
                      containerHeight="h-[150px]"
                      dashed={true}
                      light
                    />
                  </div>
                )}
              </div>
            </div>

          </div>

          {/* Buttons */}
          <div className="flex items-stretch justify-start gap-x-6 px-6 md:px-8">
            <PrevBtn onClick={onPrevClick} />
            <LoadingButton
              loading={isLoading}
              loadingIndicator={
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size="1.2rem"
                />
              }
              endIcon={<EastRoundedIcon fontSize="large" />}
              variant="contained"
              type="submit"
            >
              Next
            </LoadingButton>
          </div>
        </form>
      </FormProvider>
    </section>
  );
};
export default ProfessionalInfo;
