import { Button, useMediaQuery } from "@mui/material";
import { MobileApp } from "./widgets/components";
import { useNavigate } from "react-router-dom";
import FreqQuestionDropdown from "./widgets/components/FreqQuestionDropdown";
import { useEffect } from "react";

const HowItWorksClients = () => {
  const navigate = useNavigate();
  const isDesktopScreen = useMediaQuery("(min-width:1240px)");

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const pageScrollTop =
  //       document.documentElement.scrollTop || document.body.scrollTop || 0;
  //     const firstElementHeight = document
  //       .querySelector(".timeline-el-1")
  //       .getBoundingClientRect().height;
  //     const firstElementVisible = pageScrollTop < firstElementHeight * 3;
  //     const secondElementVisible =
  //       pageScrollTop > firstElementHeight * 3 &&
  //       pageScrollTop < firstElementHeight * 6;
  //     const thirdElementVisible = pageScrollTop >= firstElementHeight * 6;
  //     const firstElement = document.querySelector(".timeline-el-1");
  //     const secondElement = document.querySelector(".timeline-el-2");
  //     const thirdElement = document.querySelector(".timeline-el-3");
  //     const elements = [firstElement, secondElement, thirdElement];
  //     if (isDesktopScreen) {
  //       if (firstElementVisible) {
  //         elements.forEach(el => {
  //           el.classList.remove("opacity-100");
  //           el.classList.add("opacity-0");
  //         });
  //         firstElement.classList.add("opacity-100");
  //         firstElement.classList.remove("invisible");
  //       } else if (secondElementVisible) {
  //         elements.forEach(el => {
  //           el.classList.remove("opacity-100");
  //           el.classList.add("opacity-0");
  //         });
  //         secondElement.classList.add("opacity-100");
  //       } else if (thirdElementVisible) {
  //         elements.forEach(el => {
  //           el.classList.remove("opacity-100");
  //           el.classList.add("opacity-0");
  //         });
  //         thirdElement.classList.add("opacity-100");
  //       }
  //     }
  //   };
  //   document.addEventListener("scroll", handleScroll);
  //   return () => {
  //     document.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isDesktopScreen]);

  return (
    <main className="!-mt-20 md:!-mt-16 -mb-60 bg-white !pt-16 font-Bai">
      <section className="mx-auto max-w-[1440px] px-4 sm:px-6 md:px-12">
        {/* MOBILE */}
        <div className="h-[427px] relative block md:hidden !rounded-[1.5rem] text-white">
          <img src="/images/how-it-works-mob-2.png" alt="" className="!rounded-[1.5rem] object-cover w-full h-full" />

          <div className="absolute translate-x-[10%] -translatey-1/2 w-full mx-auto max-w-[80%] flex flex-col justify-center items-center bottom-12 left10">
            <span className="rounded-full bg-white bg-opacity-25 px-3 py-2 text-center text-xl text-white md:px-4 md:py-3 md:text-2xl">
              For client
            </span>

            <h4 className="mb-8 mt-4 max-w-[647px] text-center font-Bai text-[2rem] font-bold leading-[126.5%] md:text-[3.125rem]">
              Relax, get the best hands on your task in few steps, you’re in
              charge.
            </h4>
            <Button
              variant='contained'
              onClick={() => {
                navigate("/client/post-job");
              }}
            >
              Post a Job
            </Button>
          </div>
        </div>
        {/* DESKTOP */}
        <div
          style={{
            backgroundImage: 'url("/images/howitworks-clients.png")',
            backgroundRepeat: "no-repeat",
            objectFit: "cover",
          }}
          className="md:flex hidden h-[300px] flex-col items-center justify-center !rounded-[56px] p-8 text-white md:h-[555px] md:py-24"
        >
          <span className="rounded-full bg-white bg-opacity-25 px-3 py-2 text-center text-xl text-white md:px-4 md:py-3 md:text-2xl">
            For client
          </span>

          <h4 className="mb-6 mt-4 max-w-[647px] text-center font-Bai text-2xl font-bold leading-[100%] md:text-[50px]">
            Relax, get the best hands on your task in few steps, you’re in
            charge.
          </h4>
          {/* <button
            type="button"
            className="font-poppins min-w-max rounded-[12px] bg-primary px-3 py-2 text-xs font-medium text-white transition-all hover:bg-[#aa5e01] md:rounded-20 lg:min-w-fit lg:px-8 lg:py-4 lg:text-base"
            onClick={() => {
              navigate("/client/post-job");
            }}
          >
            Post a Job
          </button> */}
          <Button
            variant='contained'
            onClick={() => {
              navigate("/client/post-job");
            }}
          >
            Post a Job
          </Button>
        </div>
      </section>

      <h3 className="my-10 text-center font-Bai text-4xl font-bold text-grey_20 md:my-[70px] md:text-5xl">
        Here's how it works
      </h3>
      {/* <div className="relative flex flex-col gap-12 md:block md:h-[310rem]">
       
      </div> */}
      <FirstSlide />
        <SecondSlide />
        <ThirdSlide />

      <section
        className="bg-[#f6f6f6] p-4 py-20 sm:p-6 md:p-10 lg:px-32 lg:py-[134px]"
      >
        <div className=" mx-auto grid max-w-[1440px] grid-cols-1 gap-10 md:grid-cols-2 md:gap-28">
          <p className="max-w-[376px] text-3xl font-bold !leading-[108%] text-[#222222] md:text-[40px]">
            Frequently asked questions
          </p>

          <div className="flex flex-col gap-2">
            {FreqQuestions.map(el => (
              <FreqQuestionDropdown
                key={el.id}
                actionText={el.actionText}
                listItems={el.listItems}
              />
            ))}
          </div>
        </div>

        <div className="mt-[152px]">
          <MobileApp />
        </div>
      </section>
    </main>
  );
};

export default HowItWorksClients;

const FirstSlide = () => {
  const navigate = useNavigate();
  return (
    <section
      className={`timeline-el-1 top-24 mx-auto my-[200px] grid w-10/12 items-center justify-center gap-8 font-Mulish  md:w-9/12 md:grid-cols-2`}
    >
      <img
        src="/images/howitworks-1.png"
        alt="banner"
        className="h-[34rem] max-h-full rounded-[48px] object-contain"
      />
      <div>
        <p className="text-base text-[#222222] md:text-xl">Step 01</p>
        <p className="font-Bai text-4xl font-bold text-[#222222] md:text-5xl">
          Describe what you need done
        </p>
        <p className="my-4 text-sm font-medium text-grey_20 md:text-base">
          Easily upload your task to be done, providing all the necessary
          details and specifications, include specific requirements, deadlines,
          and any supporting documents or references to ensure service providers
          have a clear understanding of the task.
        </p>

        <Button
          sx={{ width: "fit-content" }}
          variant="outlined"
          onClick={() => navigate("/client/post-job")}
        >
          Post a job
        </Button>
      </div>
    </section>
  );
};
const SecondSlide = () => {
  const navigate = useNavigate();
  return (
    <section
      className={`timeline-el-2 top-24 mx-auto my-[200px] grid w-10/12 items-center justify-center gap-8 bg-white font-Mulish  md:w-9/12 md:grid-cols-2`}
    >
      <img
        src="/images/howitworks-2.png"
        alt="banner"
        className="h-[34rem] max-h-full rounded-[48px] object-contain"
      />
      <div>
        <p className="text-base text-[#222222] md:text-xl">Step 02</p>
        <p className="font-Bai text-4xl font-bold text-[#222222] md:text-5xl">
          Set Your Budget or Explore Flexible Options
        </p>
        <p className="my-4 text-sm font-medium text-grey_20 md:text-base">
          Take control of your budget by setting a fixed amount for your task.
          Alternatively, leave it open to explore flexible options and receive
          competitive quotes from service providers who can suggest their own
          pricing based on your task details.
        </p>

        <Button
          sx={{ width: "fit-content" }}
          variant="outlined"
          onClick={() => navigate("/client/post-job")}
        >
          Post a job
        </Button>
      </div>
    </section>
  );
};
const ThirdSlide = () => {
  const navigate = useNavigate();
  return (
    <section
      className={`timeline-el-3 top-24 mx-auto my-[200px] grid w-10/12 items-center justify-center gap-8 bg-white font-Mulish  md:w-9/12 md:grid-cols-2`}
    >
      <img
        src="/images/howitworks-3.png"
        alt="banner"
        className="h-[33.8rem] max-h-full rounded-[48px] object-contain pb-4"
      />
      <div className="pb-4 md:pb-0">
        <p className="text-base text-[#222222] md:text-xl">Step 03</p>
        <p className="font-Bai text-4xl font-bold text-[#222222] md:text-5xl">
          Receive Tailored Quotes and Make Informed Decisions
        </p>
        <p className="my-4 text-sm font-medium text-grey_20 md:text-base">
          Sit back and let service providers impress you with their proposals.
          Receive customized quotes, along with estimated costs and timelines,
          enabling you to evaluate and select the best fit for your task
        </p>

        <Button
          sx={{ width: "fit-content" }}
          onClick={() => navigate("/client/post-job")}
          variant="outlined"
        >
          Post a job
        </Button>
      </div>
    </section>
  );
};

const FreqQuestions = [
  {
    id: 1,
    actionText: "I posted a task. What are some safety tips I should follow?",
    listItems: [
    "  When getting a Truelocal to complete your tasks, being aware of the following tips will help you get your tasks done quick and easy,",
     " 1.	It’s important for safety not to disclose your personal info like your full name, address or phone number when you post a task or comment on the Task Wall.",
     " 2.	Once you’ve chosen a Truelocal to complete your task, you can now discuss in private within the platform to share more information about your task, however you should try to communicate through Truelocal private messaging – that way, if anything needs to be sorted in the interest of trust and safety, it’s all there in writing.",
    "  3.	Don’t be shy to ask your provider to show his/her Truelocal ID before commencing the task. If you are getting a trade related task done, ask for a trade license ID if applicable.",
    "  4.	Try to be as specific as possible when you communicate with your Truelocal about your task. This helps to make sure both parties are always on the same page. Don't be afraid to ask the Truelocal if they have the experience to do your task. You may ask for pictures of previous jobs done.",
     " 5.	Always pay directly on the platform knowing we will only pay your service provider when you are satisfied with work done. If you are ever in a situation where you are not satisfied with the job, try to work it out with your Truelocal first, but if you need to take further action, you can always contact us.",
    "  6.	If you ever feel uncomfortable with another member at any stage in the process, you should let us know. If at any time you feel you are in danger, you should always call for help."
      ,
    ],
  },
  {
    id: 2,
    actionText: "When I meet a service provider, how do I know they are from Truelocal?",
    listItems: [
     `When you’re connecting with someone on Truelocal for the first time, remember to always check their reviews and public profile before accepting any offers for your task. 
     In addition to that you should try to meet in a public place, like a busy street, restaurant,  shopping center or outside your house when meeting for the task. We recommend you ask the Truelocal for identification and make sure it matches the details that are present on their Truelocal profile. Also never meet at night, or very early in the morning.
     If you feel something isn’t right, excuse yourself and call us immediately.
     `
    ],
  },
  {
    id: 3,
    actionText:
      "How does Truelocal help to keep me safe when I am completing a task?",
    listItems: [
`Your common sense is the most important safety tool. To help you along the way we’ve built some safety features like extensive profiles for each member and verification badges. We also recommend that you communicate with the other members via the comment section and if unsure about anything you can always reach out to us and we will be happy to support you in any possible way.`
    ],
  },
  {
    id: 4,
    actionText:
      "My offer was accepted. What are some safety tips I should follow?",
    listItems: [
      "When completing a task on Truelocal, here are a few tips you can follow to help you get the most out of the Truelocal platform",
      "1.	Before you commit to running a task, ask as many questions as you need to ensure that you can meet expectations and earn positive reviews.",
      "2.	When first meeting, try to meet people in a public place like a busy street, office or shopping center etc and access the other person's profile. Ask a question: (e.g) what's in their bag, if they are carrying one, before going elsewhere.",
      "3.	Upload extra photos on your profile and choose photos that clearly show your face. This lets people know you are happy to show who you are and that you’re a trustworthy person!",
      "4.	Always let someone else (Friends and family) know when you are running a task and where you’ll be. Remember to keep in touch and stay contactable on your mobile.",
      "5.	If you will be working in a house, ask how many people you will be expecting to meet in the house. If you will be completing the task with someone else (your company staff member(s)) discuss it during information exchange.",
      "6.	If the person that hires you changes the task location, please call us immediately. ",
      "7.	If you feel unsafe about the task you are about to do or you consider the location unsafe, give us a call and if you ever feel like you are in danger call for help.",

    ],
  },
  {
    id: 5,
    actionText: "How do I reschedule a task?",
    listItems: [
      "To reschedule a task, navigate to the task page and select 'Reschedule'. You will then be prompted to:",
      
      "1.	Select the new date and preferred time for the task to be completed by",
      "2.	Select ‘Submit’",
      "The other member will then receive a notification with the proposed new date and time. They will be prompted to accept the proposed new date and time, or given the option to select ‘Reschedule’ should they choose to propose different arrangements to the ones you have suggested.",
      "If the other member selects ‘Reschedule’ you will be required to accept the revised date and time proposed by them in order for it to be finalized.",
      "Once you have both agreed on a new date and time, the task will be updated with the revised details.",
      "We encourage members to only reschedule tasks if they have discussed and agreed on the new date and time with each other prior to initiating the rescheduling process.",
      
    ],
  },
  {
    id: 6,
    actionText: "What is the cancellation fee for tasks on Truelocal, and how can I avoid it when I need to cancel a task?",
    listItems: [
      "Here at Truelocal, we're committed to helping communities help each other. As you’re probably aware, canceling tasks once assigned doesn’t promote the best environment for other people in the Truelocal Community. We understand that circumstances can change and sometimes the task can’t go ahead and you may need to cancel.",
      `Before making this decision, we strongly encourage you to see if there's another way to get this task done. For example, did you know you can change the time and date of the task by rescheduling it  or increase the task price, which might help avoid the cancellation. A cancellation fee of 10% will apply to all cancellations to the maximum of 1,000 Naira.
      To cancel a task on Truelocal please follow the steps below:
      `,
      "1.	Go to My Tasks",
      "2.	Select the task",
      "3.	Select ‘More Options’",
      "4.	Select ‘Cancel Task’",
      "You will then be guided through canceling the task. Once the cancellation is complete, the task refund will be processed. Cancelation fee applies to only tasks on the engagement stage.",

    ],
  },
  {
    id: 7,
    actionText: "Why can't I post my phone number or email?",
    listItems: [
      "To protect your safety and security, disclosure of private contact details or 3rd party links are not allowed to be shared in any public areas of the site including any comments and attachments. This includes business websites, Facebook, LinkedIn, Twitter, personal emails, phone numbers, addresses or personal websites.",
      "This is for the purpose of your safety, privacy and to prevent spam. After you have accepted a job post or your job has been accepted by a provider, you can start an in-depth  communication via private messaging on the platform regarding the job.",
    ],
  },
  {
    id: 8,
    actionText: "How do I chat with customers before accepting their job?",
    listItems: [
      "Unfortunately you can not start private communication before accepting a job post. If you are interested in a job post, use the public comments area on the task page, use this space to go into more detail or clear up any confusion about your task or ask potential service providers any questions you want to be answered.",
      "Please don’t share any private information in any public areas of the site (including in comments or attachments) for your privacy, safety and to prevent spam.",
      "Once you’ve picked the right service provider for your task you’re free to privately chat. This can be done directly through private messaging.",
    ],
  },
  {
    id: 9,
    actionText: "How can I change the price once the task is assigned?",
    listItems: [
      "Realized there’s more to be done or the task is more complicated than initially thought? Not to worry, increasing the task price is simple.",
      "Once an offer has been accepted, either the customer or the service provider can increase the task price.",
      "The price can be increased at any time.",
      "Just so you know, the 10% service fee is applicable on all price increases.",
      "  1.	On the task, select increase price.",
      "2.	Enter how much you’d like to add and mention why you’re increasing the price.",
      "3.	Once payment is made, the task price will be updated.",

    ],
  },
  {
    id: 10,
    actionText: "The task is complete, how can I get / release payment?",
    listItems: [
      `Once you complete the task, there are a couple more steps to finalizing the deal.
      If you’re the Truelocal Worker, you need to request the payment by selecting Request payment. This lets the Job Poster know you’re ready to move forward. If you have requested the payment already, you may need to let the Job Poster know through a private message.
      If you’re the Job Poster, once the Truelocal Worker has requested payment you’ll be prompted to select Release payment. Payment will then be securely transferred and you’re free to leave a review on the task for each other.
      `,
    ],
  },
  {
    id: 11,
    actionText: "Inviting people into your home",
    listItems: [
      "To protect yourself, home or family, we suggest you start off by hiring someone for a home chores when you are home. Example . Stay home while your day maid  does the cooking. You should be home while someone tutors the children. This way you build relationships and trust before leaving your home or children under their care.",
    ],
  },
];
