import {useEffect, useState} from "react";
import {Button} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {data as requests} from "pages/ServiceProviders/JobRequest/data";
import SingleJobCard from "pages/ServiceProviders/JobRequest/SingleJobCard";
import EmptyHistory from "./EmptyHistory";
import RequestOutline from "./Vectors/RequestOutline";
import {useNavigate} from "react-router-dom";
import RequestModal from "./RequestModal";
const JobRequestBody = ({isClient}) => {
  const [itemCount, setItemCount] = useState(4);
  const [btnChoice, setBtnChoice] = useState("pending");
  const navigate = useNavigate();
  const handleChoice = ({currentTarget}) => {
    const {value} = currentTarget;
    setItemCount(4);
    navigate(`?tab=${value}`);
    setBtnChoice(value);
  };
  useEffect(() => {
    const activeTab = new URLSearchParams(window.location.search).get("tab");
    if (activeTab) setBtnChoice(activeTab);
  }, []);

  const [showRequestModal, setShowRequestModal] = useState(false);
  const [requestPayload, setrequestPayload] = useState({
    type: "accept",
    id: "",
    name: "",
  });

  const tabOptions = isClient
    ? [
        {
          text: "Pending Requests",
          value: "pending",
        },

        {
          text: "Declined Requests",
          value: "declined",
        },
      ]
    : [
        {
          text: "Pending Requests",
          value: "pending",
        },
        {
          text: "Withdrawn Requests",
          value: "withdrawn",
        },
        {
          text: "Declined Requests",
          value: "declined",
        },
      ];
  return (
    <div className="rounded-24 border border-grey_60 bg-white p-3 md:p-8">
      <div className="flex justify-between rounded-16 border border-grey_60 bg-grey_80 p-2 md:w-fit md:justify-start md:gap-x-4">
        {tabOptions.map(({text, value}, index) => (
          <button
            key={index}
            onClick={handleChoice}
            value={value}
            className={`rounded-[14px] p-3 font-Bai text-[13px] capitalize transition-all duration-300  md:p-4 md:text-base ${
              btnChoice === value
                ? "border border-[#e4e7ec] bg-white font-semibold text-[#693A00] hover:bg-opacity-70"
                : "font-medium hover:bg-white hover:bg-opacity-50"
            }`}
          >
            {text}
          </button>
        ))}
      </div>
      {requests.filter(request => request.status === btnChoice).length > 0 ? (
        <>
          <div className="my-10 grid gap-8 md:grid-cols-2">
            {requests
              .filter(request => request.status === btnChoice)
              .slice(0, itemCount)
              .map(request => (
                <SingleJobCard
                  key={request.id}
                  request={request}
                  acceptDeclineHandler={({type, id}) => {
                    setShowRequestModal(true);
                    setrequestPayload({
                      type,
                      id,
                      name: request?.user?.first_name,
                    });
                  }}
                  isDeclined={isClient ? btnChoice === "declined" : true}
                />
              ))}
          </div>
          {itemCount + 2 <=
            requests.filter(({status}) => status === btnChoice).length && (
            <div className="flex items-center justify-center">
              <Button
                onClick={() => setItemCount(count => count + 2)}
                variant="outlined"
                endIcon={<KeyboardArrowDownIcon />}
              >
                See More
              </Button>
            </div>
          )}
        </>
      ) : (
        <EmptyHistory
          title="No request received"
          subtitle="You will be notified when you receive a request"
          icon={
            <RequestOutline
              stroke="#3B3D3B"
              transparent={true}
              width={64}
              height={64}
            />
          }
        />
      )}

      <RequestModal
        provider_name={requestPayload?.name}
        type={requestPayload?.type}
        open={showRequestModal}
        close={() => setShowRequestModal(false)}
        id={requestPayload?.id}
      />
    </div>
  );
};

export default JobRequestBody;
