import { Button, Skeleton, Tab } from "@mui/material";
import ShieldTickIcon from "components/Vectors/ShieldTickIcon";
import { useEffect, useState } from "react";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AdminTab from "./AdminTab";
import RolesTab from "./RolesTab";
import CreateAdminModal from "./CreateAdminModal";
import CreateRoleModal from "./CreateRole";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetAllAdminQuery,
  useGetAllRolesQuery,
} from "services/admin/adminGeneralApi";
import { CustomTabStyle2 } from "App";

const Administrator = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("administrators");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("tab")) setActiveTab(searchParams.get("tab"));
  }, [searchParams]);

  const [showCreateAdmin, setShowCreateAdmin] = useState(false),
    [showCreateRole, setShowCreateRole] = useState(false);
  const { data: allRoles, isLoading, error } = useGetAllRolesQuery(),
    {
      data: allAdmins,
      isLoading: allAdminsLoading,
      error: getAdminsError,
    } = useGetAllAdminQuery();

  return (
    <DashboardLayout
      error={error || getAdminsError}
      title="Administrators"
      type="admin"
    >
      <main>
        <div className="rounded-2xl bg-white p-4 md:py-6">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="mb-4 font-Bai text-2xl font-medium text-black">
              Overview
            </h3>
            <div className="flex items-center gap-x-4">
              <Button
                variant="contained"
                onClick={() => setShowCreateAdmin(true)}
              >
                Create Admin
              </Button>
              <Button
                variant="contained"
                onClick={() => setShowCreateRole(true)}
                sx={{
                  color: "#E98100",
                  bgcolor: "#F2F4F7",
                  "&:hover": {
                    color: "#E98100",
                    bgcolor: "#F2F4F7",
                    opacity: "0.8",
                  },
                }}
              >
                Create Roles
              </Button>
            </div>
          </div>

          <div className="boxes grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
            <DashboardBox
              text="Admins"
              icon={<ShieldTickIcon color="#E98100" />}
              value={
                allAdminsLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : (
                  String(allAdmins?.data.length)
                )
              }
            />
            <DashboardBox
              text="Roles"
              icon={<ShieldOutlinedIcon sx={{ color: "#5010B3" }} />}
              value={
                isLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : (
                  String(allRoles?.data.length)
                )
              }
            />
          </div>
        </div>
        <div className="mt-6 rounded-2xl bg-white p-4 md:py-6 ">
          <TabContext value={activeTab}>
            <div className="mb-6 flex items-center justify-between md:mb-8">
              <TabList
                variant="scrollable"
                value={activeTab}
                indicator="none"
                onChange={(e, newValue) => {
                  setActiveTab(newValue);
                  navigate(`?tab=${newValue}`);
                }}
                sx={{
                  fontWeight: 500,
                  color: "#6E726E",
                  backgroundColor: "#F9FAFB !important",
                  borderWidth: "1px",
                  borderRadius: "16px",
                  width: "fit-content",
                  padding: "8px",
                  ...CustomTabStyle2,
                  textTransform: "unset",
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
                aria-label="roles and administrators"
              >
                <Tab label="Administrators" value="administrators" />
                <Tab label="Roles" value="all-roles" />
              </TabList>
            </div>

            <TabPanel value="administrators">
              <AdminTab />
            </TabPanel>
            <TabPanel value="all-roles">
              <RolesTab />
            </TabPanel>
          </TabContext>
        </div>
      </main>
      <CreateAdminModal
        open={showCreateAdmin}
        onClose={() => setShowCreateAdmin(false)}
      />
      <CreateRoleModal
        open={showCreateRole}
        onClose={() => setShowCreateRole(false)}
      />
    </DashboardLayout>
  );
};

export default Administrator;
