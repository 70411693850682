import { Avatar, Button, Chip } from "@mui/material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import StarIcon from "@mui/icons-material/Star";
import PlusStarIcon from "components/Vectors/PlusStarIcon";
import PlugIcon from "components/Vectors/PlugIcon";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import ProfileInformationTab from "./Tabs/ProfileInformationTab";
import ServicesTab from "./Tabs/ServicesTab";
// import DropDownWrapper from "components/mui-components/DropDownWrapper";
// import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
// import NotInterestedIcon from "@mui/icons-material/NotInterested";
import BlockedUsersModal from "./Modals/BlockedUsersModal";
// import moment from "moment";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";
import OnboardingClientModal from "components/mui-components/Modal/OnboardingClientModal";
import { useGetUserRequest } from "hooks/useGetUserRequest";
import { VerifiedBadge } from "components/Vectors/SmallTickIcon";
import EditIcon2 from "components/Vectors/EditIcon2";
import { WarningRounded } from "@mui/icons-material";
import { useGetAllSPServicesQuery } from "services/user/providerGeneralApi";
import { BootstrapTooltip } from "components/TicketCards";
function ServiceProvidersProfile() {
  const { data, isLoading, error } = useGetUserRequest();
  const { data: services } = useGetAllSPServicesQuery();
  const user = data?.data;
  const [activeTab, setactiveTab] = useState("profile_info");
  const [searchParam] = useSearchParams();
  useEffect(() => {
    const tab = searchParam.get("tab");
    if (tab) setactiveTab(tab);
  }, [searchParam]);
  const navigate = useNavigate();
  const [openBecomeClient, setopenBecomeClient] = useState(false);
  const [openBlockUsersModal, setopenBlockUsersModal] = useState(false);

  if (isLoading) return <CircularLoader />;
  if (error) return <ErrorMsg error={error} />;
  return (
    <div>
      <h1 className="mb-5 font-Bai text-2xl font-semibold text-grey_20 md:text-4xl">
        My Profile
      </h1>

      <main className="rounded-2xl bg-white p-5 md:p-8">
        <div className="items flex w-full flex-wrap justify-between">
          <div className="flex gap-4">
            <Avatar
              src={user?.avatar}
              alt="profile"
              sx={{
                height: "56px",
                width: "56px",
                "@media (min-width: 780px)": {
                  width: "120px",
                  height: "120px",
                },
              }}
            />
            <div className="w-full md:mt-3">
              <h3 className="flex items-center gap-2 text-xl font-semibold text-black md:mb-2 md:text-3xl">
                {user?.professional_information?.business_name ||
                  user?.full_name}
                <span>
                  {user?.is_verified_account && (
                    <VerifiedBadge
                      width={20}
                      height={20}
                      TooltipTitle={"Verified Professional"}
                    />
                  )}
                </span>
              </h3>
              <p className="text-sm font-medium text-grey_20 md:text-lg">
                @{user?.username}
              </p>
              <div className="mt-4 hidden items-center gap-3 md:flex">
                <Chip
                  sx={{ borderRadius: "8px" }}
                  color="secondaryInverse"
                  label={user?.user_type}
                />
                {user?.is_top_vetted && (
                  // <Chip
                  //   sx={{ borderRadius: "8px" }}
                  //   color="secondaryInverse"
                  //   label={"Top Vetted"}
                  // />
                  <BootstrapTooltip
                    title="This is a top vetted Service Provider we can recommend"
                    placement="bottom"
                  >
                    <span className="flex items-center gap-0.5 rounded-16 border-[0.4px] border-[#5010B3] bg-[#5010B314] px-[5px] py-[3px]">
                      <StarIcon sx={{ fontSize: "12px", color: "#5010B3" }} />
                      <p className="font-Mulish text-[10px] font-normal text-[#5010B3]">
                        Top vetted
                      </p>
                    </span>
                  </BootstrapTooltip>
                )}
                {/* <p className="text-sm font-normal ">
                  <span className="text-grey_30">Date Registered:</span>{" "}
                  <span className="text-grey">
                    {moment(user?.created_at).format("Do MMMM, YYYY")}
                  </span>
                </p> */}

                {(user?.user_type?.toLowerCase() === "service provider" &&
                  user?.is_verified_account &&
                  user?.cac_certificate && user?.cac_certificate?.status === 'Approved'
                ) && (
                    <BootstrapTooltip
                      title="This is a registered Business recognized by the Corporate Affairs Commission (CAC)"
                      placement="bottom"
                    >
                      <span className="flex items-center gap-0.5 rounded-16 border-[0.4px] border-primary bg-[#FEFAF6] px-[8px] py-[3px]">
                        <p className="font-Mulish text-[10px] font-normal text-primary">
                          A Business
                        </p>
                      </span>
                    </BootstrapTooltip>
                  )}

                {user?.user_type?.toLowerCase() === "service provider" &&
                  user?.cac_certificate === null &&
                  (
                    <BootstrapTooltip
                      title="This user is a freelancer/Independent Professional"
                      placement="bottom"
                    >
                      <span className="flex items-center gap-0.5 rounded-16 border-[0.4px] border-primary bg-[#FEFAF6] px-[8px] py-[3px]">
                        <p className="font-Mulish text-[10px] font-normal text-primary">
                          Independent Professional
                        </p>
                      </span>
                    </BootstrapTooltip>
                  )}
              </div>
            </div>
          </div>
          <div className="mt-0 flex h-12 items-stretch gap-2 md:mt-3 md:h-14 md:w-auto">
            <Button
              variant="contained"
              onClick={() => navigate("/service-provider/settings")}
              className="w-[40px] !min-w-[unset] md:!min-w-[170px]"
            >
              <span className="hidden md:inline"> Edit Profile</span>
              <span className="inline md:hidden">
                <EditIcon2 color="#fff" />
              </span>
            </Button>

            {/* <DropDownWrapper
              contentPadding="p-[0]"
              position="right"
              border="border border-[#F2F4F7]"
              action={
                <button className="flex h-full w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F2F4F7] md:w-[60px]">
                  <MoreHorizRoundedIcon
                    sx={{
                      fontSize: "16px",
                      color: "#e98100",
                    }}
                  />
                </button>
              }
            >
              <div>
                <Button
                  variant="text"
                  sx={{
                    color: "#6E726E",
                  }}
                  startIcon={
                    <NotInterestedIcon
                      sx={{fontSize: "16px", color: "#6E726E"}}
                    />
                  }
                  onClick={() => setopenBlockUsersModal(true)}
                >
                  View blocked users
                </Button>
              </div>
            </DropDownWrapper> */}
          </div>
        </div>
        <div className="mx-auto w-full max-w-[950px]">
          <div className="mt-12 flex w-full flex-wrap items-center justify-between gap-4">
            <div className="flex flex-wrap items-center gap-8">
              <div className="flex gap-3">
                <div className="flex h-[43px] w-[43px] items-center justify-center rounded-full bg-secondary-10 md:h-[48px] md:w-[48px]">
                  <StarIcon color="white" />
                </div>
                <div>
                  <h3 className="font-Bai text-2xl font-medium text-black md:text-3xl">
                    {user?.average_rating?.toFixed(1)}
                  </h3>
                  <p className="text-sm font-normal text-grey_30">Rating</p>
                </div>
              </div>
              <div className="flex gap-3">
                <div className="flex h-[43px] w-[43px] items-center justify-center rounded-full bg-secondary-10 md:h-[48px] md:w-[48px]">
                  <ChatBubbleIcon color="white" />
                </div>
                <div>
                  <h3 className="font-Bai text-2xl font-medium text-black md:text-3xl">
                    {user?.ratings_received_count}
                  </h3>
                  <p className="text-sm font-normal text-grey_30">Reviews</p>
                </div>
              </div>
              <Link to="/service-provider/subscription" className="flex gap-3">
                <div className="flex h-[43px] w-[43px] items-center justify-center rounded-full bg-primary md:h-[48px] md:w-[48px]">
                  <PlusStarIcon color="white" />
                </div>
                <div>
                  <h3 className="font-Bai text-2xl font-medium text-black md:text-3xl">
                    {user?.token_wallet?.current_balance || 0}
                  </h3>
                  <p className="text-sm font-normal text-grey_30">Tokens</p>
                </div>
              </Link>
            </div>
            <Button
              variant="contained"
              color="secondaryInverse2"
              sx={{
                fontSize: "16px",
                width: "187px",
                display: user?.is_hybrid === "Yes" ? "none" : "",
              }}
              onClick={() => setopenBecomeClient(true)}
            >
              Become A Client
            </Button>
          </div>
          {!user?.kyc && (
            <div className="mt-6 flex items-center justify-between rounded-[24px] border-[3px] border-dashed border-primary p-6">
              <div className="flex items-center gap-3">
                <div className="flex h-[53px] w-[53px] items-center justify-center rounded-full bg-primary">
                  <PlugIcon />
                </div>
                <div>
                  <p className="font-Bai text-2xl font-medium text-black">
                    Update KYC to be eligible
                  </p>
                  <p className="text-base font-normal text-[#000000CC]">
                    Upload your KYC infomation
                  </p>
                </div>
              </div>

              <Button
                variant="contained"
                color="primaryInverse2"
                sx={{ fontSize: "16px", width: "187px" }}
              >
                Upload KYC
              </Button>
            </div>
          )}

          <TabContext value={activeTab}>
            <TabList
              variant="scrollable"
              value={activeTab}
              className="my-6 mt-8 rounded-[16px] border border-grey_60 bg-grey_80 p-2"
              onChange={(_, newValue) => {
                navigate(`?tab=${newValue}`);
                setactiveTab(newValue);
              }}
              sx={{
                fontWeight: 600,
                textTransform: "unset",
                ".MuiTab-root": {
                  minWidth: {
                    xs: "50%",
                    md: "auto",
                  },
                },
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Profile Information" value="profile_info" />
              <Tab
                label={
                  <span className="inline-flex items-center gap-x-2">
                    Services
                    {services?.data?.length === 0 && (
                      <WarningRounded color="warning" />
                    )}
                  </span>
                }
                value="services"
              />
            </TabList>
            <TabPanel value="profile_info">
              <ProfileInformationTab />
            </TabPanel>
            <TabPanel value="services">
              <ServicesTab />
            </TabPanel>
          </TabContext>
        </div>
      </main>

      {/* <BecomeAClientModal
        openModal={openBecomeClient}
        setopenModal={setopenBecomeClient}
      /> */}
      <OnboardingClientModal
        open={openBecomeClient}
        close={() => setopenBecomeClient(false)}
      />
      <BlockedUsersModal
        openModal={openBlockUsersModal}
        setopenModal={setopenBlockUsersModal}
      />
    </div>
  );
}

export default ServiceProvidersProfile;
