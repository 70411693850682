import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {truncateString} from "utils";
import "../myStyles.scss";
const CategoryCard2 = props => {
  const {
    image = "it-services",
    type = "IT Services",
    description = "For software, web development, networking, programming, etc.",
    count = "3,546",
    isActive,
    isNext,
    expertises = [],
  } = props;

  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundImage: `url('${image || "/images/broken-image.png"}')`,
      }}
      className={`category-card ${isActive ? "active" : ""} ${
        isNext ? "next" : ""
      } min-h-[373px] overflow-hidden bg-cover bg-center bg-no-repeat font-medium text-white`}
    >
      <div className="content relative">
        <h3
          className={`pointer-events-none absolute bottom-0 left-[50%] ${
            isActive ? "opacity-0" : "opacity-1 -translate-x-[50%] -bottom-[60%] -rotate-90"
          } flex h-full w-max items-center gap-2 text-xl md:text-3xl`}
        >
          {truncateString(type, 7)}{" "}
          <span className="flex min-w-max items-center gap-3 rounded-10 bg-[#08080866] px-3.5 py-2 text-sm font-bold text-white md:text-lg">
            {Number(count || 0).toLocaleString()}{" "}
            {Number(count) === 1 ? "Job" : "Jobs"}
          </span>
        </h3>
        <div className="category-desc translate-y-[120px]">
          <h3 className="pointer-events-none mb-3 flex items-center gap-3 text-xl md:text-3xl">
            <span>{type}</span>
            <span className="min-w-max rounded-10 bg-[#08080866] px-3.5 py-2 text-sm font-bold text-white md:text-lg">
              {Number(count || 0).toLocaleString()}{" "}
              {Number(count) === 1 ? "Job" : "Jobs"}
            </span>
          </h3>
          <p className="mb-4 font-Mulish text-base font-[500] leading-[28px] tracking-[-1%] md:text-xl">
            {description}
          </p>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/jobs`, {
                state: {
                  category: type,
                  description,
                  cover_photo: image,
                  expertises: expertises?.map(el => el?.name),
                },
              });
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }}
            sx={{textTransform: "none"}}
          >
            View more
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard2;
