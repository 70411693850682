import React from 'react'

const NewDefaultAvatar = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_535_5283)">
                <rect width="40" height="40" rx="20" fill="#F4F4F5" />
                <circle cx="20.247" cy="17.2833" r="5.92593" fill="#AEAEB7" />
                <path d="M20.4939 26.667C26.6304 26.667 31.605 31.6416 31.605 37.7781C31.605 43.9146 26.6304 48.8892 20.4939 48.8892C14.3574 48.8892 9.38276 43.9146 9.38276 37.7781L9.38276 28.4741C9.38276 27.4761 10.1918 26.667 11.1898 26.667L20.4939 26.667Z" fill="#C9C9CF" />
            </g>
            <defs>
                <clipPath id="clip0_535_5283">
                    <rect width="40" height="40" rx="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default NewDefaultAvatar