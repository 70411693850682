import {ReactComponent as Apple} from "assets/images/apple.svg";
import {ReactComponent as PlayStore} from "assets/images/google.svg";
import appImg from "assets/images/app.png";
import {Checkbox} from "@mui/material";

const MobileApp = () => {
  return (
    <div className="mx-auto mb-32 flex w-[85%] max-w-[1000px] flex-col-reverse items-center justify-between gap-6 rounded-36 bg-white md:w-[95%] md:grid-cols-2 md:flex-row md:gap-10 md:p-16 md:py-0 md:pr-0">
      <div className="w-full basis-[60%] px-2 py-6 md:py-[54px] text-center md:p-0 md:text-left">
        <p className="font-Bai text-3xl font-semibold text-text md:text-4xl">
          Try our mobile App
        </p>
        <ul className="mx-auto mt-6 mb-8 flex flex-col gap-2 px-2 text-left md:gap-y-1 md:px-0">
          {[
            " Receive new job notifications once they get posted",
            " Browse hundreds of jobs on the go, wherever you are",
            " Private message with your customer",
            " Get notified once payment is made",
          ].map((text, i) => (
            <li
              key={text + i}
              className="text-grey_20 flex items-center gap-2 text-base font-normal"
            >
              <span className="my-2 mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-[#FDF2E6] md:my-1">
                <Checkbox checked disabled />
              </span>
              {text}
            </li>
          ))}
        </ul>

        <ul
          role="group"
          className="flex flex-col items-center gap-4 md:flex-row md:gap-6"
        >
          {["App Store", "Google Play"].map((item, index) => (
            <li key={index}>
              <button className="font-poppins flex items-center gap-x-4 rounded-20 bg-primary py-4 px-8 font-medium text-white">
                {item === "App Store" ? <Apple /> : <PlayStore />}
                <span className="text-sm text-left">
                  Available on <br />
                  <span className="block text-sm font-bold md:inline-block md:text-base">
                    {item}
                  </span>
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="-mt-20 flex w-full flex-1 justify-end">
        <img
          src={appImg}
          alt="Mobile App"
          className="h-[250px] rounded-br-36 md:h-[450px]"
        />
      </div>
    </div>
  );
};

export default MobileApp;
