import DropDownWrapper from "components/mui-components/DropDownWrapper";
import {useState} from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const RatingDropdown = ({
  title,
  data = [
    "0.0  -  1.0",
    "1.0  -  2.0",
    "2.0  -  3.0 ",
    "3.0  -  4.0",
    "4.0  -  5.0",
  ],
}) => {
  const [rating, setRating] = useState(data.at(-1));

  return (
    <div className="filter-select flex h-[56px] items-center gap-1 rounded-xl bg-[#F9FAFB] p-4">
      <span className="text-base font-normal text-grey_30">
        {title || "Rating"}:
      </span>

      <DropDownWrapper
        position="right"
        action={
          <div className="flex cursor-pointer items-center gap-3 text-base font-normal capitalize text-grey_10">
            <span className="max-w-[100px] overflow-hidden overflow-ellipsis whitespace-nowrap">
              {rating}
            </span>
            <span>
              <KeyboardArrowDownRoundedIcon />
            </span>
          </div>
        }
      >
        <div className="flex cursor-pointer flex-col gap-3 py-2">
          {data.map((item, index) => (
            <div key={index} className="flex">
              <div className="flex items-center gap-2">
                <p onClick={() => setRating(item)}>{item}</p>
              </div>
            </div>
          ))}
        </div>
      </DropDownWrapper>
    </div>
  );
};
export default RatingDropdown;
