export const data = [{
    id: "1",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "withdrawn",
  },
  {
    id: "2",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "withdrawn",
  },
  {
    id: "3",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "withdrawn",
  },
  {
    id: "4",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "withdrawn",
  },
  {
    id: "5",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "pending",
  },
  {
    id: "6",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "pending",
  },
  {
    id: "7",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "pending",
  },
  {
    id: "8",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "pending",
  },
  {
    id: "9",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "declined",
  },
  {
    id: "10",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "declined",
  },
  {
    id: "11",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "declined",
  },
  {
    id: "12",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "declined",
  },
  {
    id: "13",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "withdrawn",
  },
  {
    id: "14",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "withdrawn",
  },
  {
    id: "15",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "withdrawn",
  },
  {
    id: "16",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "withdrawn",
  },
  {
    id: "17",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "pending",
  },
  {
    id: "18",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "pending",
  },
  {
    id: "19",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "pending",
  },
  {
    id: "20",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "pending",
  },
  {
    id: "21",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "declined",
  },
  {
    id: "22",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "declined",
  },
  {
    id: "23",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "declined",
  },
  {
    id: "24",
    title: "I will repair everything that is faulty in your house",
    desc: "Dakun please comman help me do some wiring in my house. Lol, we don’t expect people as local as this sha. It’s a joke by the way, a dry one, yes, I just said it. Thanks",
    img: "/images/request.png",
    profilePic: "/images/notif_img.png",
    profileName: "Mirian Achonwa",
    price: "₦500,000",
    duration: "4 Weeks",
    date: "30 Oct 2022",
    time: '1 hr',
    location: "Physical - Ketu, Lagos",
    status: "declined",
  },
];