// import CampaignIcon from "components/Vectors/CampaignIcon";
// // import HeartIcon from "components/Vectors/HeartIcon";
// import Layout from "components/Vectors/Layout";
// // import TasksIcon from "components/Vectors/TasksIcon";
// // import MessageIcon from "components/Vectors/MessageIcon";
// // import MessageQuestionIcon from "components/Vectors/MessageQuestionIcon";
// // import SettingsIcon from "components/Vectors/SettingsIcon";
// // import TicketsIcon from "components/Vectors/TicketIcon";
// // import WalletIcon from "components/Vectors/WalletIcon";
// // import ServicesIcon from 'components/Vectors/ServicesIcon'

import TicketIcon from "components/Vectors/ticket";

const MenuLinks = [
  {
    icon: TicketIcon,
    name: "Tasks",
    link: "/tasks",
  },
  {
    name: "Discover Providers",
    link: "/discover",
  },
  {
    name: "How It Works",
    link: "/how-it-works",
  },
];

export const ExtraLinks = [
  {
    icon: TicketIcon,
    name: "Wallet",
    link: "wallet",
  },
  // {
  //   // // icon: TicketsIcon,
  //   name: "Tickets",
  //   link: "tickets",
  //   border: true,
  // },
  // {
  //   // // icon: SettingsIcon,
  //   name: "Settings",
  //   link: "settings",
  // },
  // {
  //   // // icon: HeartIcon,
  //   name: "Favourites",
  //   link: "favourites",
  // },
];

export default MenuLinks;

export const BadgeText = ({ number, color = "bg-error" }) => (
  <span
    className={`flex ${color} h-[20px] w-fit min-w-[20px] items-center rounded-[10px] px-[6px] text-xs text-white`}
  >
    {number.toLocaleString()}
  </span>
);
