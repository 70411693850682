import OverviewCard from "components/OverviewCard";
import Users3 from "components/Vectors/Users3";
import { useGetUser } from "hooks/getUserHook";
import EngagementDetails from "pages/Clients/Engagements/EngagementDetails";
import Joyride from "react-joyride";
import { useHasVisitedEngagementPageMutation } from "services/user/userRegApi";

const ServiceProviderEngagement = () => {
  const { user } = useGetUser();
  const ongoingEngagementCount = user?.ongoing_engagements_count;
  const completedEngagementCount = user?.completed_engagements_count;
  const cancelledEngagementCount = user?.cancelled_engagements_count;
  const terminatedEngagementCount = user?.terminated_engagements_count;
  const [markAsVisited] = useHasVisitedEngagementPageMutation();
  const handleJoyrideCallback = (data) => {
    const { action } = data;
    if (action === 'reset') markAsVisited().unwrap()
  };

  return (
    <section>
      <Joyride
        run={user?.has_visited_engagement_page ? false : true}
        callback={handleJoyrideCallback}
        scrollToFirstStep={true}
        continuous={true}
        showProgress={true}
        locale={{ back: "Previous" }}
        spotlightClick={false}
        hideCloseButton={true}
        steps={[
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[16px] font-semibold leading-[150%] text-grey_30">
                  Engagements
                </p>
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  Manage all your engagement activities with Service Providers
                  here
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                  Let's show you around
                </p>
              </div>
            ),
            target: "body",
            placement: "center",
            locale: {
              skip: <button onClick={() => {
                markAsVisited().unwrap()
              }}>Nevermind, Thanks</button>, next: <p>Begin Tour</p>
            },
            showProgress: false,
            hideCloseButton: true,
            showSkipButton: true,
          },
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  Ongoing Engagements
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                  Mauris pellentesque dictumst consectetur malesuada egestas
                  augue feugiat ut. Varius neque amet quis est id tortor vitae
                  vitae.
                </p>
              </div>
            ),
            target: "button.sp_engagement_first_step",
            locale: { next: <p>Next</p> },
            showProgress: true,
            hideBackButton: true,
          },
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  Completed Engagements
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                  Mauris pellentesque dictumst consectetur malesuada egestas
                  augue feugiat ut. Varius neque amet quis est id tortor vitae
                  vitae.
                </p>
              </div>
            ),
            target: "button.sp_engagement_second_step",
            locale: { next: <p>Next</p> },
            showProgress: true,
          },
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  Cancelled Engagments
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                  Mauris pellentesque dictumst consectetur malesuada egestas
                  augue feugiat ut. Varius neque amet quis est id tortor vitae
                  vitae.
                </p>
              </div>
            ),
            target: "button.sp_engagement_third_step",
            locale: { next: <p>Next</p> },
            showProgress: true,
          },
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  Terminated Engagements
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                  Mauris pellentesque dictumst consectetur malesuada egestas
                  augue feugiat ut. Varius neque amet quis est id tortor vitae
                  vitae.
                </p>
              </div>
            ),
            target: "button.sp_engagement_fourth_step",
            locale: { last: <p>Next</p> },
            showProgress: true,
          },
        ]}
        styles={{
          tooltip: {
            borderRadius: "24px",
            padding: "10px 20px 30px",
            width: "486px",
          },
          buttonSkip: {
            display: "flex",
            padding: "16px 24px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "12px",
            background: "var(--gray-scale-gray-scale-70, #F2F4F7)",
            color: "#E98100",
            textAlign: "center",
            fontSize: "16px",
            fontFamily: "Mulish",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            letterSpacing: "-0.48px",
          },
          buttonBack: {
            display: "flex",
            padding: "16px 24px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "12px",
            background: "var(--gray-scale-gray-scale-70, #F2F4F7)",
            color: "#E98100",
            textAlign: "center",
            fontSize: "16px",
            fontFamily: "Mulish",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            letterSpacing: "-0.48px",
            marginLeft: "none",
          },
          buttonNext: {
            display: "flex",
            padding: "16px 24px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "12px",
            background: "#E98100",
          },
          tooltipFooter: {
            justifyContent: "flex-start",
            gap: "12px",
          },
          buttonClose: {
            left: "20px",
          },
          // spotlight:{
          //   display:"none",
          // }
        }}
      />
      <h1 className="font-h1">Engagements</h1>
      <p className="font-p font-Bai">Manage all your engagement activities here</p>
      <div
        className="mt-8 rounded-16 border-[#E4E7EC] md:rounded-24
       md:border md:bg-white md:px-8 md:py-[28px]"
      >
        <h2 className="dashboard-overview">Overview</h2>

        {/* overview cards  */}
        <div className="mt-3 grid grid-cols-2 gap-4 md:grid-cols-4">
          <OverviewCard
            title="Ongoing Engagements"
            icon={<Users3 color="#5010B3" />}
            value={ongoingEngagementCount}
          />

          <OverviewCard
            title="Completed Engagements"
            icon={<Users3 color="#12B76A" />}
            value={completedEngagementCount}
          />
          <OverviewCard
            title="Cancelled Engagements"
            icon={<Users3 color="#D92D20" />}
            value={cancelledEngagementCount}
          />
          <OverviewCard
            title="Terminated Engagements"
            icon={<Users3 color="#D92D20" />}
            value={terminatedEngagementCount}
          />
        </div>
      </div>

      <div className="mt-8 rounded-16 md:rounded-24 md:bg-white md:p-[40px]">
        <EngagementDetails type={"service-provider"} />
      </div>
    </section>
  );
};

export default ServiceProviderEngagement;
