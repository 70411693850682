import {useState} from "react";
import {Button} from "@mui/material";
import MoneyAddIcon from "components/Vectors/MoneyAddIcon";
import PurchaseTokenModal from "./PurchasetokenModal";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import {
  useGetAllSubscriptionsQuery,
  useGetSubscriptionStatQuery,
  useGetTokenUsageQuery,
} from "services/user/userRegApi";
import {toCurrency} from "utils";
import moment from "moment";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";

const options = {
  search: false,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
};

const PurchaseHistory = () => {
  const {data, isLoading, error, isError} = useGetAllSubscriptionsQuery();
  const tableData = data?.data?.map(el => ({
    id: `#${el.package?.id || el?.id}`,
    name: el?.package?.name || el?.source,
    tokens: el.quantity,
    amount: toCurrency(el.amount),
    created_at: moment(el.created_at).format("DD MMM YYYY"),
  }));
  return (
    <div>
      <MyDataTable
        isLoading={isLoading}
        isError={isError}
        error={error}
        emptyData={{
          message: "No Package has been bought by you",
          icon: MoneyAddIcon,
        }}
        options={options}
        scrollX={false}
        data={tableData}
        headCells={purchaseHistoryHeadCells}
      />
    </div>
  );
};

const UsageHistory = () => {
  const {data, isLoading, error, isError} = useGetTokenUsageQuery();

  const tableData = data?.data?.map(el => ({
    id: `#${el.id}`,
    tokens: el?.quantity,
    job: {
      img: el?.client_job?.images[0]?.image_url,
      name: el?.client_job?.job_title,
    },
    created_at: moment(el.created_at).format("DD MMM YYYY"),
    client: {
      img: el?.client_job?.creator.avatar,
      name: el?.client_job?.creator?.full_name,
    },
  }));
  return (
    <div>
      <MyDataTable
        isLoading={isLoading}
        isError={isError}
        error={error}
        emptyData={{
          message: "No record found",
          icon: MoneyAddIcon,
        }}
        options={options}
        scrollX={false}
        data={tableData}
        headCells={usageHistoryHeadCells}
      />
    </div>
  );
};

const ServiceProviderSubscription = () => {
  const [btnChoice, setBtnChoice] = useState("purchase");
  const [showTokenModal, setShowTokenModal] = useState(false);
  const {data, isLoading} = useGetSubscriptionStatQuery();

  return (
    <>
      <PurchaseTokenModal
        openModal={showTokenModal}
        closeModal={() => setShowTokenModal(false)}
      />
      <div className="flex items-center justify-between">
        <h1 className="font-Bai text-2xl font-semibold text-grey_20 md:text-4xl">
          Subscription
        </h1>
        <Button onClick={() => setShowTokenModal(true)} variant="contained">
          Purchase token
        </Button>
      </div>
      <div className="my-8 rounded-20 border border-grey_60 bg-white p-4 md:p-6">
        <p className="font-Bai text-xl text-black">Overview</p>
        <div className="my-2 h-px bg-grey_60"></div>
        <div className="grid gap-y-6 md:grid-cols-3 md:gap-x-4">
          <DashboardBox
            icon={<MoneyAddIcon width={32} height={32} />}
            text={"Available Token"}
            value={`${data?.data?.available_tokens}` || "N/A"}
            isLoading={isLoading}
          />
          <DashboardBox
            icon={<MoneyAddIcon width={32} height={32} color="#D92D20" />}
            text={"Token Used"}
            value={`${data?.data?.used_tokens}` || "N/A"}
            isLoading={isLoading}
          />
          <DashboardBox
            icon={<MoneyAddIcon width={32} height={32} />}
            text={"Last Package"}
            value={data?.data?.last_token?.name || "N/A"}
            stats={`Date Purchase: ${moment(
              data?.data?.last_token?.created_at,
            ).format("DD MMM YYYY")}`}
            isLoading={isLoading}
          />
        </div>
      </div>

      <div className="rounded-20 border border-grey_60 bg-white p-2 md:p-6">
        <div className="mb-6 mt-8 flex justify-between rounded-16 border border-grey_60 bg-grey_80 p-2 md:w-fit md:justify-start md:gap-x-4">
          {["usage", "purchase"].map(value => (
            <button
              key={value}
              value={value}
              onClick={e => setBtnChoice(e.target.value)}
              className={`rounded-[14px] p-3 font-Bai text-sm capitalize transition-all duration-300 md:p-4 md:text-base ${
                value === btnChoice
                  ? "border border-[#e4e7ec] bg-white font-semibold text-[#693A00]"
                  : "font-medium"
              }`}
            >
              {value} history
            </button>
          ))}
        </div>

        {btnChoice === "purchase" ? <PurchaseHistory /> : <UsageHistory />}
      </div>
    </>
  );
};

const purchaseHistoryHeadCells = [
  {
    name: "id",
    label: "Package ID",
  },
  {
    name: "name",
    label: "Package name",
  },
  {
    name: "tokens",
    label: "Number of tokens",
  },
  {
    name: "amount",
    label: "Amount",
  },
  {
    name: "created_at",
    label: "Date Purchased",
  },
];
const usageHistoryHeadCells = [
  {
    name: "id",
    label: "ID",
  },
  {
    name: "tokens",
    label: "Number of tokens",
  },
  {
    name: "job",
    label: "Job",
    options: {
      customBodyRender: value => (
        <UserDisplay imgRounded={false} name={value.name} img={value.img} />
      ),
    },
  },
  {
    name: "created_at",
    label: "Date Purchased",
  },
  {
    name: "client",
    label: "Client",
    options: {
      customBodyRender: value => (
        <UserDisplay name={value.name} img={value.img} />
      ),
    },
  },
];

export default ServiceProviderSubscription;
