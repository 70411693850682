import {Button} from "@mui/material";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import  {useState} from "react";
import RequestDetailsModal from "./RequestDetailsModal";
import RefundDetailsModal from "./RefundDetailsModal";
import {toCurrency} from "utils";
import moment from "moment";

function RequestCard({request, btnText = "View request"}) {
  const [openModal, setopenModal] = useState(false);
  return (
    <div className="rounded-2xl bg-grey_80 p-4">
      <UserDisplay
        name={
          request?.service_provider?.professional_information?.business_name
        }
        img={request?.service_provider?.professional_information?.business_logo}
        size="text-[1rem]"
        email={`@${request?.service_provider?.username}`}
      />

      <div className="my-6 flex items-center justify-between">
        <p className="font-Bai text-xl font-bold text-grey_20">
          {toCurrency(request?.amount)}
        </p>
        <p className="text-sm font-medium text-grey_40">
          {moment(request?.request_date_time).fromNow()}
        </p>
      </div>
      <p className="mb-2 text-base font-medium text-grey_20">
        {request?.description}
      </p>
      <div className="flex justify-end">
        <Button
          variant="text"
          sx={{fontSize: "16px !important"}}
          endIcon={<KeyboardArrowRightIcon />}
          onClick={() => {
            setopenModal(true);
          }}
        >
          {btnText}
        </Button>
      </div>
      {btnText === "View request" ? (
        <RequestDetailsModal
          openModal={openModal}
          setopenModal={setopenModal}
          request={request}
        />
      ) : (
        <RefundDetailsModal openModal={openModal} setopenModal={setopenModal} />
      )}
    </div>
  );
}

export default RequestCard;
