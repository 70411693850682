import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
  logOut,
  setOnboardingStatus,
  setUserDetails,
} from "redux/slice/AuthSlice";
import {API_URL} from "utils/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/user/`,
  prepareHeaders: (headers, api) => {
    const {auth} = api.getState();

    if (auth.token) {
      headers.set("authorization", `Bearer ${auth.token}`);
      headers.set("Access-Control-Allow-Origin", `*`);
    }
    headers.set("Accept", `application/json`);
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  let err = result?.error;
  if (err && err.data?.message?.includes("Unauthenticated")) {
    // logout
    console.log("Something went wrong, Please login again", "error");
    setTimeout(() => {
      api.dispatch(
        logOut({
          redirect: true,
          type: window.location.pathname.includes("admin") ? "admin" : "user",
        }),
      );
    }, 1000);
  }
  return result;
};

export const userRegistrationApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "userRegistrationeApi",
  tagTypes: [
    "userData",
    "tokens",
    "ticketData",
    "engagementData",
    "transaction",
    "chat",
    "block",
    "notification",
  ],
  endpoints: builder => ({
    createUsername: builder.mutation({
      query: body => ({
        url: `create-username`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userData"],
    }),

    chooseUserType: builder.mutation({
      query: body => ({
        url: `choose-usertype`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userData"],
    }),
    switchAccount: builder.mutation({
      query: () => ({
        url: `switch-account`,
        method: "POST",
      }),
      invalidatesTags: ["userData"],
    }),
    serviceProviderType: builder.mutation({
      query: body => ({
        url: `service-provider/choose-type`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userData"],
    }),

    userBasicInfo: builder.mutation({
      query: body => ({
        url: `submit-basic-information`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userData"],
    }),

    submitUserProfessionalInfo: builder.mutation({
      query: body => ({
        url: `submit-professional-information`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userData"],
    }),

    submitKycInfo: builder.mutation({
      query: body => ({
        url: `Kyc`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userData"],
    }),

    validateBankAccount: builder.mutation({
      query: body => ({
        url: `validate-bank-account`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userData"],
    }),

    submitBankAccount: builder.mutation({
      query: body => ({
        url: `bank-accounts`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userData"],
    }),

    getOnboardingStatus: builder.query({
      query: () => `onboarding-completed-status`,
      providesTags: ["userData"],
      // dispatch new entry each time this endpoint is called with a new entry
      async onCacheEntryAdded(id, {dispatch, cacheDataLoaded}) {
        try {
          const {data} = await cacheDataLoaded;
          if (data?.data) {
            dispatch(setOnboardingStatus(data?.data));
          }
        } catch (err) {}
      },
    }),
    getUserProfile: builder.query({
      query: () => `profile`,
      providesTags: ["userData"],
      // dispatch new entry each time this endpoint is called with a new entry
      async onCacheEntryAdded(id, {dispatch, cacheDataLoaded}) {
        try {
          const {data} = await cacheDataLoaded;
          if (data?.data) {
            dispatch(setUserDetails(data?.data));
          }
        } catch (err) {}
      },
    }),
    updateBasicInfo: builder.mutation({
      query: body => ({
        url: `basic-information`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userData"],
    }),

    updateProfessionalInfo: builder.mutation({
      query: body => ({
        url: `professional-information`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userData"],
    }),

    getBasicInfo: builder.query({
      query: () => `basic-information`,
      provideTags: ["userData"],
    }),

    getKycStatus: builder.query({
      query: id => ({
        url: `check-status/${id}`,
        method: "POST",
      }),
      provideTags: ["userData"],
    }),
    submitCacCert: builder.mutation({
      query: body => ({
        url: "service-provider/submit-cac-certificate",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userData"],
    }),
    updateUserPassword: builder.mutation({
      query: body => ({
        url: "change-password",
        method: "POST",
        body,
      }),
    }),
    // Tickets
    getAllTickets: builder.query({
      query: ({params}) => ({
        url: "tickets",
        params,
      }),
      providesTags: ["ticketData"],
    }),
    getSingleTicket: builder.query({
      query: id => `tickets/${id}`,
      providesTags: ["ticketData"],
    }),
    createTicket: builder.mutation({
      query: body => ({
        url: "tickets",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ticketData"],
    }),
    replyTicket: builder.mutation({
      query: ({body}) => ({
        url: "ticket-responses",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ticketData"],
    }),

    // token packages
    getAllPackages: builder.query({
      query: () => "packages",
    }),
    getAllSubscriptions: builder.query({
      query: () => "subscriptions",
      providesTags: ["tokens"],
    }),
    getTokenUsage: builder.query({
      query: () => "token-usage/list?is_paginated=no",
      providesTags: ["tokens", "jobData"],
    }),
    getSubscriptionStat: builder.query({
      query: () => "subscriptions/stat",
      providesTags: ["tokens"],
    }),

    // payments
    initiatePayment: builder.mutation({
      query: body => ({
        url: "payments/init",
        method: "POST",
        body,
      }),
    }),

    getProviderOffers: builder.query({
      query: params => ({
        url: `/service-provider/offers`,
        params,
      }),
      providesTags: ["jobData", "engagementData"],
    }),
    completePayment: builder.mutation({
      query: body => ({
        url: "payments/complete",
        method: "POST",
        body,
      }),
      invalidatesTags: ["tokens", "engagementData"],
    }),
    makeJobOffer: builder.mutation({
      query: body => ({
        url: "service-provider/offers",
        method: "POST",
        body,
      }),
      invalidatesTags: ["jobData", "userData"],
    }),

    withdrawJobOffer: builder.mutation({
      query: body => ({
        url: "service-provider/offers/withdraw",
        method: "POST",
        body,
      }),
      invalidatesTags: ["jobData", "userData"],
    }),
    getMinTokenRequired: builder.query({
      query: () => `settings/tokens_to_get_Job`,
    }),
    getSettingsList: builder.query({
      query: isAdmin => (isAdmin ? "" : "settings/list"),
    }),
    // get single user by id
    getSingleUser: builder.query({
      query: id => `detail/${id}`,
      providesTags: ["serviceProviderData"],
    }),

    // engagements
    getSingleEngagement: builder.query({
      query: ({id, type = "client"}) => `${type}/engagement/show/${id}`,
      providesTags: ["engagementData"],
    }),
    getSingleEngagementRelatedData: builder.query({
      query: ({id}) => `engagement/related-data/${id}?conversations_per_per=15&submission_logs_per_per=15&activities_per_per=15&ratings_per_per=15`,
      providesTags: ["engagementData"],
    }),
    getAllEngagements: builder.query({
      query: ({type = "client", params}) => ({
        url: `${type}/engagement/list`,
        params,
      }),
      providesTags: ["engagementData"],
    }),
    getAllEngagementByStatus: builder.query({
      query: ({type = "client", status, params}) => ({
        url: `${type}/engagement/status/${status}`,
        params,
      }),
      providesTags: ["engagementData"],
    }),
    startEngagement: builder.mutation({
      query: body => ({
        url: `client/engagement/set-dates`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["jobData", "engagementData"],
    }),
    cancelEngagement: builder.mutation({
      query: ({type = "client", body}) => ({
        url: `${type}/engagement/cancel`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["jobData", "engagementData"],
    }),
    requestExtension: builder.mutation({
      query: body => ({
        url: `service-provider/engagement/request/deadline-extension`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["engagementData"],
    }),
    terminateEngagement: builder.mutation({
      query: body => ({
        url: `service-provider/engagement/terminate`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["jobData", "engagementData"],
    }),
    completeEngagement: builder.mutation({
      query: body => ({
        url: `service-provider/engagement/complete`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["jobData", "engagementData"],
    }),
    acceptTermination: builder.mutation({
      query: body => ({
        url: `client/engagement/accept-decline/termination-request`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["engagementData"],
    }),
    acceptRejectExtensionRequest: builder.mutation({
      query: body => ({
        url: "client/engagement/deadline-extension/request/accept-decline",
        method: "POST",
        body,
      }),
      invalidatesTags: ["engagementData", "jobData"],
    }),
    acceptRejectProjectCompletion: builder.mutation({
      query: body => ({
        url: "client/engagement/completion/accept-decline",
        method: "POST",
        body,
      }),
      invalidatesTags: ["engagementData"],
    }),
    // Ratings/ review
    reviewServiceProvider: builder.mutation({
      query: body => ({
        url: "ratings",
        method: "POST",
        body,
      }),
      invalidatesTags: ["engagementData"],
    }),
    getTransactions: builder.query({
      query: type => `${type}/transaction/get-all`,
      providesTags: ["transaction"],
    }),
    getTransactionStats: builder.query({
      query: type => `${type}/transaction/stats`,
      providesTags: ["transaction"],
    }),
    // Chats
    getChats: builder.query({
      query: userId => `chat/fetch-messages/${userId}`,
      providesTags: ["chat"],
    }),
    getUnreadChats: builder.query({
      query: params => ({
        url: "chat/get-unread-chats",
        params,
      }),
      providesTags: ["chat"],
    }),
    getUnreadOfferMsgCount: builder.query({
      query: () => ({
        url: "unread-offer-conversation-count",
      }),
      providesTags: ["engagementData"],
    }),
    getUnreadEngagementMsgCount: builder.query({
      query: () => ({
        url: "unread-engagement-conversation-count",
      }),
      providesTags: ["engagementData"],
    }),
    markEngagementChatAsRead: builder.mutation({
      query: id => ({
        url: `engagement/${id}/mark-all-chats-as-read`,
      }),
      invalidatesTags: ["engagementData"],
    }),
    markOfferChatAsRead: builder.mutation({
      query: id => ({
        url: `offer/${id}/mark-all-chats-as-read`,
      }),
      invalidatesTags: ["engagementData"],
    }),
    getContacts: builder.query({
      query: params => ({
        url: "chat/list-contacts",
        params,
      }),
      providesTags: ["chat"],
    }),
    sendMsg: builder.mutation({
      query: body => ({
        url: "chat/send-message",
        method: "POST",
        body,
      }),
      invalidatesTags: ["chat"],
    }),
    archiveChat: builder.mutation({
      query: body => ({
        url: "chat/archive-chat",
        method: "POST",
        body,
      }),
      invalidatesTags: ["chat"],
    }),
    // Notification
    getAllUserNotifications: builder.query({
      query: ({type = "client", params}) => ({
        url: `${type}/in-app-notification/get-all`,
        params,
      }),
      providesTags: ["notification"],
    }),
    getAllSPNotifs: builder.query({
      query: () => "service-provider/in-app-notification/get-all",
      providesTags: ["notification"],
    }),
    getUserNotificationSetting: builder.query({
      query: () => "notifications-setting",
      providesTags: ["notification"],
    }),
    updateUserNotificationSetting: builder.mutation({
      query: body => ({
        url: "notification-setting/update",
        method: "POST",
        body,
      }),
      invalidatesTags: ["notification"],
    }),
    markNotificationAsRead: builder.mutation({
      query: body => ({
        url: "in-app-notification/mark-one-as-read",
        method: "POST",
        body,
      }),
      invalidatesTags: ["notification"],
    }),
    markMultipleAsRead: builder.mutation({
      query: () => ({
        url: "in-app-notification/mark-multiple-as-read",
        method: "POST",
      }),
      invalidatesTags: ["notification"],
    }),
    // block users
    getBlockedUsers: builder.query({
      query: isLoggedIn => (isLoggedIn ? "blocking/get-blocked-users" : ""),
      providesTags: ["block"],
    }),
    blockUnblockUser: builder.mutation({
      query: ({type, body}) => ({
        url: `blocking/${type}-user`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["block"],
    }),
    // Page visits
    hasVisitedLandingPage: builder.mutation({
      query: () => ({
        url: "hasVisitedLandingPage",
        method: "POST",
      }),
      invalidatesTags: ["userData"],
    }),
    hasVisitedEngagementPage: builder.mutation({
      query: () => ({
        url: "hasVisitedEngagementPage",
        method: "POST",
      }),
      invalidatesTags: ["userData"],
    }),
    hasVisitedJobsPage: builder.mutation({
      query: () => ({
        url: "hasVisitedJobsPage",
        method: "POST",
      }),
      invalidatesTags: ["userData"],
    }),
  }),
});

export const {
  useCreateUsernameMutation,
  useChooseUserTypeMutation,
  useSwitchAccountMutation,
  useServiceProviderTypeMutation,
  useUserBasicInfoMutation,
  useSubmitUserProfessionalInfoMutation,
  useSubmitKycInfoMutation,
  useSubmitCacCertMutation,
  useValidateBankAccountMutation,
  useSubmitBankAccountMutation,
  useGetOnboardingStatusQuery,
  useUpdateBasicInfoMutation,
  useGetBasicInfoQuery,
  useGetKycStatusQuery,
  useUpdateProfessionalInfoMutation,
  useGetUserProfileQuery,
  useUpdateUserPasswordMutation,
  // Tickets
  useGetAllTicketsQuery,
  useGetSingleTicketQuery,
  useCreateTicketMutation,
  useReplyTicketMutation,
  // token packages
  useGetAllPackagesQuery,
  useGetAllSubscriptionsQuery,
  useGetSubscriptionStatQuery,
  useGetTokenUsageQuery,
  // payments
  useInitiatePaymentMutation,
  useCompletePaymentMutation,
  // Offers
  useGetProviderOffersQuery,
  useMakeJobOfferMutation,
  useWithdrawJobOfferMutation,
  useGetMinTokenRequiredQuery,
  // user details
  useGetSingleUserQuery,

  // engagement
  useGetSingleEngagementQuery,
  useGetSingleEngagementRelatedDataQuery,
  useGetAllEngagementsQuery,
  useGetAllEngagementByStatusQuery,
  useStartEngagementMutation,
  useCancelEngagementMutation,
  useTerminateEngagementMutation,
  useAcceptTerminationMutation,
  useAcceptRejectExtensionRequestMutation,
  useAcceptRejectProjectCompletionMutation,
  useRequestExtensionMutation,
  useCompleteEngagementMutation,
  useReviewServiceProviderMutation,
  // Chat
  useGetChatsQuery,
  useGetUnreadChatsQuery,
  useGetContactsQuery,
  useSendMsgMutation,
  useArchiveChatMutation,
  useMarkEngagementChatAsReadMutation,
  useMarkOfferChatAsReadMutation,
  useGetUnreadOfferMsgCountQuery,
  useGetUnreadEngagementMsgCountQuery,
  useGetSettingsListQuery,
  // Notification
  useGetUserNotificationSettingQuery,
  useGetAllUserNotificationsQuery,
  useUpdateUserNotificationSettingMutation,
  useMarkNotificationAsReadMutation,
  useMarkMultipleAsReadMutation,
  // block users
  useGetBlockedUsersQuery,
  useBlockUnblockUserMutation,
  // Page visits
  useHasVisitedLandingPageMutation,
  useHasVisitedEngagementPageMutation,
  useHasVisitedJobsPageMutation,
} = userRegistrationApi;
