const UserIcon = ({stroke, ...props}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0001 13C6.83002 13 4.01089 14.5306 2.21609 16.906C1.8298 17.4172 1.63665 17.6728 1.64297 18.0183C1.64785 18.2852 1.81546 18.6219 2.02546 18.7867C2.29728 19 2.67396 19 3.42733 19H16.5729C17.3262 19 17.7029 19 17.9747 18.7867C18.1847 18.6219 18.3523 18.2852 18.3572 18.0183C18.3635 17.6728 18.1704 17.4172 17.7841 16.906C15.9893 14.5306 13.1702 13 10.0001 13Z"
        stroke={stroke || "#3B3D3B"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 10C12.4854 10 14.5001 7.98528 14.5001 5.5C14.5001 3.01472 12.4854 1 10.0001 1C7.51481 1 5.5001 3.01472 5.5001 5.5C5.5001 7.98528 7.51481 10 10.0001 10Z"
        stroke={stroke || "#3B3D3B"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
