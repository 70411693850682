import React from "react";

const Wallet = () => {
  return (
    <svg
      viewBox="0 0 51 51"
      className="h-6 w-6 md:h-[51px] md:w-[51px]"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.2695 8.96875H10.2904C8.90903 8.96875 7.58427 9.51748 6.60752 10.4942C5.63076 11.471 5.08203 12.7957 5.08203 14.1771V36.8229C5.08203 38.2042 5.63076 39.529 6.60752 40.5058C7.58427 41.4825 8.90903 42.0312 10.2904 42.0312H41.2695C42.6509 42.0312 43.9756 41.4825 44.9524 40.5058C45.9291 39.529 46.4779 38.2042 46.4779 36.8229V14.1771C46.4779 12.7957 45.9291 11.471 44.9524 10.4942C43.9756 9.51748 42.6509 8.96875 41.2695 8.96875ZM44.3945 29.6562H31.0195C29.9145 29.6562 28.8547 29.2173 28.0732 28.4359C27.2918 27.6545 26.8529 26.5947 26.8529 25.4896C26.8529 24.3845 27.2918 23.3247 28.0732 22.5433C28.8547 21.7619 29.9145 21.3229 31.0195 21.3229H44.3945V29.6562ZM31.0195 19.2396C29.3619 19.2396 27.7722 19.8981 26.6001 21.0702C25.428 22.2423 24.7695 23.832 24.7695 25.4896C24.7695 27.1472 25.428 28.7369 26.6001 29.909C27.7722 31.0811 29.3619 31.7396 31.0195 31.7396H44.3945V36.8229C44.3945 37.6517 44.0653 38.4466 43.4792 39.0326C42.8932 39.6187 42.0983 39.9479 41.2695 39.9479H10.2904C9.46156 39.9479 8.66671 39.6187 8.08066 39.0326C7.4946 38.4466 7.16536 37.6517 7.16536 36.8229V14.1771C7.16536 13.3483 7.4946 12.5534 8.08066 11.9674C8.66671 11.3813 9.46156 11.0521 10.2904 11.0521H41.2695C42.0983 11.0521 42.8932 11.3813 43.4792 11.9674C44.0653 12.5534 44.3945 13.3483 44.3945 14.1771V19.2396H31.0195Z"
        fill="#E98100"
      />
      <path
        d="M31.0286 27.5749C32.1792 27.5749 33.112 26.6421 33.112 25.4915C33.112 24.3409 32.1792 23.4082 31.0286 23.4082C29.8781 23.4082 28.9453 24.3409 28.9453 25.4915C28.9453 26.6421 29.8781 27.5749 31.0286 27.5749Z"
        fill="#E98100"
      />
    </svg>
  );
};

export default Wallet;
