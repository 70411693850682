import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import BannedWordsTab from "./tabs/BannedWordsTab";
import CategoriesTab from "./tabs/CategoriesTab";
import ChargesTab from "./tabs/ChargesTab";
import LocationsTab from "./tabs/LocationsTab";
import HomepageBanner from "./tabs/HomepageBanner";

const AdminSettings = () => {
  const navigate = useNavigate();
  const [btnChoice, setBtnChoice] = useState("categories");
  useEffect(() => {
    const activeTab = new URLSearchParams(window.location.search).get("tab");
    if (activeTab) setBtnChoice(activeTab);
  }, []);

  return (
    <DashboardLayout title="Settings" type="admin">
      <main>
        <div className="mt-6 rounded-2xl bg-white p-4 md:min-h-screen md:p-6">
          <TabContext value={btnChoice}>
            <TabList
              variant="scrollable"
              value={btnChoice}
              indicator="none"
              onChange={(e, newValue) => {
                navigate("?tab=" + newValue);
                setBtnChoice(newValue);
              }}
              aria-label="basic tabs example"
            >
              {[
                "categories",
                "service_charges",
                "banned_words",
                "locations",
                "banners",
                // "landing_page",
              ].map((text, index) => (
                <Tab
                  key={index}
                  label={text.split("_").join(" ")}
                  value={text}
                />
              ))}
            </TabList>
            <TabPanel value="categories">
              <CategoriesTab />
            </TabPanel>
            <TabPanel value="service_charges">
              <ChargesTab />
            </TabPanel>
            <TabPanel value="banned_words">
              <BannedWordsTab />
            </TabPanel>
            <TabPanel value="locations">
              <LocationsTab />
            </TabPanel>
            <TabPanel value="banners">
              <HomepageBanner />
            </TabPanel>
          </TabContext>
        </div>
      </main>
    </DashboardLayout>
  );
};

export default AdminSettings;
