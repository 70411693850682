import {useState} from "react";
import {Button, Tab} from "@mui/material";
import MoneyAddIcon from "components/Vectors/MoneyAddIcon";
import EarningComponent from "./Earning";
import WithdrawalComponent from "./Withdrawal";
import RequestWithdrawalModal from "./RequestWithdrawalModal";
import {
  useGetEarningHistoryQuery,
  useGetTransactionsStatQuery,
  useGetWithdrawalHistoryQuery,
} from "services/user/providerGeneralApi";
import {toCurrency} from "utils";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {CustomTabStyle2} from "App";
import {useNavigate} from "react-router-dom";
import {useGetUser} from "hooks/getUserHook";
import IncompleteModal from "pages/Clients/Dashboard/IncompleteModal";

const ServiceProviderTransaction = () => {
  const {user} = useGetUser();
  const navigate = useNavigate();
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [activeTab, setActiveTab] = useState("earning");
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);
  const {data: stats, isLoading: isStatsLoading} =
    useGetTransactionsStatQuery();
  const {
    data: earningHistory,
    isLoading: isEarningLoading,
    error: earningError,
    isError: isEarningError,
  } = useGetEarningHistoryQuery();
  const {
    data: withdrawalHistory,
    isLoading: isWithdrawalLoading,
    error: withdrawalError,
    isError: isWithdrawalError,
  } = useGetWithdrawalHistoryQuery();

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="font-Bai text-2xl font-semibold text-grey_20 md:text-4xl">
          Transactions
        </h1>
        <Button
          onClick={() => {
            Boolean(user?.bank_account)
              ? setShowWithdrawModal(true)
              : setShowIncompleteModal(true);
          }}
          variant="contained"
        >
          Request Withdrawal
        </Button>
      </div>

      <div className="my-8 rounded-20 border border-grey_60 bg-white p-4 md:p-6">
        <p className="font-Bai text-xl text-black">Overview</p>
        <div className="mb-4 mt-2 h-px bg-grey_60"></div>
        <div className="grid gap-y-6 md:grid-cols-3 md:gap-x-4">
          {[
            {
              title: "Token Balance",
              text: `${stats?.data?.token_balance} Tokens`,
            },
            {
              title: "Upcoming Payment",
              text: toCurrency(stats?.data?.upcoming_payment),
            },
            {
              title: "Total Earning",
              text: toCurrency(stats?.data?.total_earning),
            },
            {
              title: "TrueLocal Commission",
              text: toCurrency(stats?.data?.truelocal_commission),
            },
            {
              title: "Net Earning",
              text: toCurrency(stats?.data?.net_earning),
            },
            {
              title: "Total Withdrawals",
              text: toCurrency(stats?.data?.total_withdrawal),
            },
          ].map(({title, text}, index) => (
            <DashboardBox
              key={index}
              text={title}
              value={text}
              icon={<MoneyAddIcon width={32} height={32} />}
              isLoading={isStatsLoading}
            />
          ))}
        </div>
      </div>

      <div className="rounded-20 border-2 border-grey_60 bg-white px-2 py-4 md:py-8 md:px-6">
        <TabContext value={activeTab}>
          <TabList
            variant="scrollable"
            value={activeTab}
            className="mb-4 w-fit  rounded-[16px] border border-grey_60 bg-grey_80 p-2"
            onChange={(_, newValue) => {
              navigate(`?tab=${newValue}`);
              setActiveTab(newValue);
            }}
            sx={{
              fontWeight: 600,
              ...CustomTabStyle2,
              textTransform: "unset",
            }}
            aria-label="earnings and withdrawals"
          >
            <Tab label="Earning History" value="earning" />
            <Tab label="Withdrawal History" value="withdrawal" />
          </TabList>
          <TabPanel value="earning">
            <EarningComponent
              data={earningHistory?.data}
              isLoading={isEarningLoading}
              error={earningError}
              isError={isEarningError}
            />
          </TabPanel>
          <TabPanel value="withdrawal">
            <WithdrawalComponent
              data={withdrawalHistory?.data}
              isLoading={isWithdrawalLoading}
              error={withdrawalError}
              isError={isWithdrawalError}
            />
          </TabPanel>
        </TabContext>
      </div>
      <RequestWithdrawalModal
        openModal={showWithdrawModal}
        closeModal={() => setShowWithdrawModal(false)}
      />
      <IncompleteModal
        open={showIncompleteModal}
        close={() => setShowIncompleteModal(false)}
        isProfessional={true}
        description="Your bank details is still pending completion. You won't be able to request for withdrawal until you fill in your bank details"
      />
    </>
  );
};

export default ServiceProviderTransaction;
