import NewAuthLayout from "templates/AuthLayout/NewAuthLayout";
import MailIcon2 from "components/Vectors/MailIcon2";
import {Link} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import {useAdminSendPwdResetLinkMutation} from "services/auth.api";
import {setUserEmail} from "redux/slice/AuthSlice";
import {handleError} from "utils";
import {useDispatch} from "react-redux";
import {showToast} from "redux/store.hooks";
import LoadingBtn from "components/mui-components/LoadingButton";
import {useState} from "react";
import ResetSuccessfulModal from "./Modals/ResetSuccessfulModal";

const AdminResetPassword = () => {
  const [showModal, setShowModal] = useState(false);
  const methods = useForm();
  const dispatch = useDispatch();
  const [sendPwdLink, {isLoading}] = useAdminSendPwdResetLinkMutation();
  const url = window.location.origin;

  const onSubmit = async data => {
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("redirect_url", `${url}/admin/set-password`);
    try {
      const res = await sendPwdLink(formData).unwrap();
      dispatch(setUserEmail(data.email));
      setShowModal(true);
      showToast(res?.message, "success");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <NewAuthLayout>
      <div className="flex flex-col items-center">
        <MailIcon2 />
        <h3 className="mt-8 mb-3 text-center font-Bai text-2xl font-semibold text-[#362E1F] md:text-4xl">
          Reset Password
        </h3>
        <p className="mb-2 max-w-[411px] text-center text-sm font-normal text-grey_30 md:text-base">
          To Reset your password, Enter the Email linked to your account, an OTP
          will be sent to you
        </p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full">
            <ValidatedInput2
              type="email"
              name="email"
              placeholder="email@example.com"
            />
            <LoadingBtn
              fullWidth
              loading={isLoading}
              sx={{my: 2}}
              type="submit"
            >
              Send OTP
            </LoadingBtn>
            <div className="text-center">
              <Link to="/admin/login" className="text-grey_30">
                Cancel
              </Link>
            </div>
          </form>
        </FormProvider>

        <ResetSuccessfulModal
          openModal={showModal}
          closeModal={() => setShowModal(false)}
        />
      </div>
    </NewAuthLayout>
  );
};

export default AdminResetPassword;
