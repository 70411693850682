import EmptyResponse from "components/mui-components/EmptyResponse";
import AccordionWrap from "components/mui-components/FilterModals/AccordionWrap";
import BriefcaseIcon from "components/Vectors/BriefcaseIcon";
import ServiceDetailsTab from "pages/ServiceProviders/Profile/Tabs/ServiceDetailsTab";
import React from "react";

function Services({services = []}) {
  return (
    <div className="rounded-2xl bg-white p-4 md:p-6">
      <h3 className="mb-4 font-Bai text-[32px] font-semibold text-grey_20">
        Services
      </h3>
      {services?.length <= 0 && (
        <div>
          <EmptyResponse icon={<BriefcaseIcon />} message="No Services found" />
        </div>
      )}
      <div className="flex flex-col gap-7">
        {services?.map(service => (
          <AccordionWrap
            key={`service${service?.id}`}
            title={service?.category?.name}
          >
            <ServiceDetailsTab showCta={false} service={service} />
          </AccordionWrap>
        ))}
      </div>
    </div>
  );
}

export default Services;
