import { Button } from "@mui/material";
import MyModal from "components/mui-components/Modal";

const ActiveTokenModal = ({ openModal, closeModal }) => {
  return (
    <MyModal openModal={openModal} closeModal={closeModal}>
      <p className="mt-16 text-center font-Bai text-2xl font-semibold text-grey md:text-3xl">
        You have an active package.
      </p>
      <p className="mt-7 mb-auto text-center font-medium text-grey_30">
        You need to finish your current Token
      </p>
      <Button onClick={closeModal} variant="contained" sx={{ width: "100%" }}>
        Okay, thanks
      </Button>
    </MyModal>
  );
};

export default ActiveTokenModal;
