import { Button, Chip } from "@mui/material";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { Link, useNavigate } from "react-router-dom";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import moment from "moment";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { formatCurrency } from "utils";
import LocationIcon from "components/Vectors/LocationIcon";
import Calendar2Icon from "components/Vectors/Calendar2Icon";

const SingleEngagement = ({ engagement }) => {
  const {
    id,
    amount,
    days_left,
    status,
    service_provider,
    start_date,
    end_date,
    client,
    get_new_engagement_conversation_count,
  } = engagement;

  const job = engagement?.job_without_relationship || engagement?.job;
  const isInClientRoute = window.location.pathname.includes("client");
  const statusColor = {
    Completed: "#12B76A",
    Ongoing: "#5010B3",
    Cancelled: "#D92D20",
    Terminated: "#D92D20",
  };
  const navigate = useNavigate();

  return (
    <div key={id} className="rounded-24 bg-grey_70 p-2">
      <div className="grid gap-2 md:gap-x-0">
        <div className="relative">
          {status === "Ongoing" &&
            get_new_engagement_conversation_count > 0 && (
              <div className="absolute top-[10px] left-[10px] max-w-fit rounded-[50px] bg-white px-1.5 py-1 text-xs">
                <span className="mr-2.5 inline-flex h-[22px] w-[22px] items-center justify-center rounded-full bg-primary font-Bai text-white">
                  {get_new_engagement_conversation_count}
                </span>
                new
                {get_new_engagement_conversation_count <= 1
                  ? " message"
                  : " messages"}
              </div>
            )}

          <p
            className={`absolute top-[10px] right-[10px] max-w-fit rounded-[50px] bg-white px-2 py-1 text-sm font-semibold capitalize shadow text-[${statusColor[status]}]`}
          >
            {status}
          </p>
          <img
            src={
              // job?.images?.length < 0
              //   ?
              job?.images[0]?.image_url || "/images/broken-image.png"
            }
            alt={job?.job_title}
            onClick={() =>
              navigate(
                isInClientRoute
                  ? `/client/engagements/${id}`
                  : `/service-provider/engagements/${id}`,
              )
            }
            className="h-full max-h-[125px] w-full cursor-pointer !rounded-lg object-cover md:max-h-[150px]"
          />
        </div>
        <div className="rounded-16 bg-white p-3">
          <p className="text-sm text-primary">{job?.category?.name}</p>
          <Link
            to={
              isInClientRoute
                ? `/client/engagements/${id}`
                : `/service-provider/engagements/${id}`
            }
            className="col-span-2 font-Bai text-base font-semibold text-[#444444] line-clamp-2 md:text-xl"
          >
            {job?.job_title}
          </Link>
          <div className="mt-3.5 flex w-full items-center justify-between">
            <div className="flex flex-col items-center">
              <UserDisplay
                className="!items-start"
                name={
                  <span className="flex flex-col items-start gap-1">
                    {isInClientRoute && service_provider?.is_top_vetted && (
                      <Chip
                        avatar={<StarRoundedIcon />}
                        color="secondaryInverse"
                        label="Top Vetted"
                        component="span"
                        sx={{
                          fontSize: "10px",
                          "& .MuiChip-avatar": {
                            color: "#5010B3",
                            fontSize: "11px",
                          },
                        }}
                        size="small"
                      />
                    )}
                    <span>
                      {isInClientRoute
                        ? service_provider?.is_verified_account
                          ? service_provider?.professional_information?.business_name
                          : service_provider?.full_name
                        : client?.full_name}
                    </span>
                  </span>
                }
                img={
                  isInClientRoute
                    ? service_provider?.is_verified_account
                      ? service_provider?.professional_information?.business_logo
                      : service_provider?.avatar
                    : client?.avatar
                }
                badge={
                  isInClientRoute
                    ? service_provider?.is_verified_account
                    : false
                }
                email={`@${isInClientRoute
                  ? service_provider?.username
                  : client?.username
                  }`
                }
              />
            </div>
            <p className="font-Bai text-xl font-bold text-grey_20">
              {formatCurrency(amount)}
            </p>
          </div>
        </div>

        <div className="flex flex-col items-start gap-y-2 rounded-16 bg-white p-3 text-sm text-ltext">
          <p className="flex justify-start gap-x-1">
            <LocationIcon color="#000" className="h-5 w-4" /> Location (
            {job?.location_type}){" "}
            {job?.location_type === "Physical" ? `- ${job?.address}` : ""}
          </p>
          {start_date && end_date ? (
            <div className="flex gap-x-2.5">
              <p className="flex w-full items-center gap-1 text-xs">
                <Calendar2Icon color="#000" className="h-5 w-4" />{" "}
                {moment(start_date).format("DD MMM YYYY")}{" "}
                <span className="mx-1 inline-block text-black"> to </span>{" "}
                {moment(end_date).format("DD MMM YYYY")}
              </p>
              {status?.toLowerCase() !== "completed" && (
                <Chip
                  color={
                    days_left < 0
                      ? "errorInverse"
                      : days_left === 0
                        ? "greyInverse"
                        : "successInverse"
                  }
                  label={
                    days_left < 0
                      ? "Overdue"
                      : days_left === 0
                        ? "Today"
                        : days_left === 1
                          ? `${days_left} day Left`
                          : `${days_left} days Left`
                  }
                  size="small"
                  sx={{
                    fontSize: { xs: "10px", md: "14px", borderRadius: "20px" },
                  }}
                />
              )}
            </div>
          ) : (
            <p className="flex items-center gap-x-1">
              <Calendar2Icon color="#000" className="h-5 w-4" />{" "}
              <span>Not set yet</span>
            </p>
          )}

          <Button
            component={Link}
            to={
              isInClientRoute
                ? `/client/engagements/${id}`
                : `/service-provider/engagements/${id}`
            }
            sx={{ mt: -1, p: "0.5rem !important" }}
            endIcon={<NavigateNextOutlinedIcon />}
          >
            Details
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SingleEngagement;
