import {createSlice} from "@reduxjs/toolkit";

export const initialState = {status: false};

export const postModalSlice = createSlice({
  name: "postModal",
  initialState,
  reducers: {
    togglePostModal: (state, {payload}) => {
      if (payload) {
        state.status = payload;
      } else {
        state.status = !state.status;
      }
    },
  },
});

export const {togglePostModal} = postModalSlice.actions;

export const togglePostModalState = state => state.postModal.status;

export default postModalSlice.reducer;
