import React from "react";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";
import UserIcon03 from "components/Vectors/UserIcon03";
import UserCircleIcon from "components/Vectors/user-circle";
import UserIcon from "components/Vectors/user";
import {useGetPayoutStatQuery} from "services/admin/adminGeneralApi";

function PayoutsTab() {
  const {data, isLoading, isError} = useGetPayoutStatQuery();
  const stats = data?.data;
  return (
    <div>
      <h3 className="mb-5 font-Bai text-2xl font-medium text-black">
        Overview
      </h3>

      <div className="mb-8 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        <DashboardBox
          isError={isError}
          isLoading={isLoading}
          icon={<UserIcon03 />}
          text={"Total Payouts"}
          value={`${stats?.all_payouts}`}
        />
        <DashboardBox
          isError={isError}
          isLoading={isLoading}
          icon={<UserCircleIcon stroke="#e98100" />}
          text={"Pending Requests"}
          value={`${stats?.pending_payouts}`}
        />
        <DashboardBox
          isError={isError}
          isLoading={isLoading}
          icon={<UserIcon stroke="#e98100" />}
          text={"Completed Payouts"}
          value={`${stats?.completed_payouts}`}
        />
      </div>
    </div>
  );
}

export default PayoutsTab;
