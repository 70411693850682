import CircularLoader from "components/Loader/CircularLoader";
import EmptyResponse from "components/mui-components/EmptyResponse";
import ErrorMsg from "components/mui-components/ErrorMsg";
import TablePagination from "components/mui-components/TablePagination";
import React, {useState} from "react";
import {useGetPayoutListQuery} from "services/admin/adminGeneralApi";
import RequestCard from "../widgets/RequestCard";
import UserCircleIcon from "components/Vectors/user-circle";

function PaymentRequestsTab() {
  const [rowPerPage, setRowPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const queryParams = {
    page,
    per_page: rowPerPage,
    is_paginated: "yes",
  };

  const {data, isLoading, error, isError} = useGetPayoutListQuery({
    ...queryParams,
  });
  const requestList = data?.data?.filter(request => !request?.paid_date_time);

  if (isLoading) return <CircularLoader />;
  if (isError) return <ErrorMsg error={error} />;
  return (
    <>
      {requestList?.length > 0 ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          {requestList?.map((item, i) => (
            <RequestCard request={item} key={i} />
          ))}
        </div>
      ) : (
        <EmptyResponse
          message={"No payout request found"}
          icon={<UserCircleIcon />}
        />
      )}

      {requestList?.length > 0 && (
        <TablePagination
          meta={{
            totalPages: data?.last_page,
            totalCount: requestList?.length,
            currentPage: data?.current_page,
          }}
          from={data?.from}
          to={data?.to < 15 ? requestList?.length : data?.to}
          page={page}
          setPage={setPage}
          perPage={rowPerPage}
          setPerPage={setRowPerPage}
        />
      )}
    </>
  );
}

export default PaymentRequestsTab;
