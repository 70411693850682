export const RequestData = [
  {
    id: "1",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "2",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "3",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "4",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "5",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "6",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "7",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "8",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "9",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "10",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "11",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "12",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "13",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "14",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "15",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "16",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "17",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "18",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "19",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "20",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "21",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "22",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "23",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
  {
    id: "24",
    imgUrl: "/images/jobRequestImage.png",
    requestCategory: "Repairs & Maintenance",
    requestDetails: "Fix my faucet and mixers",
    amount: "₦50,000",
    location: "Physical - Ketu, Lagos",
    dateTime: "Sun, 20th Feb 2022 - Anytime",
  },
];
export const RequestResponse = [
  {
    response:
      "I have a lot of questions about this offer, but let’s see how it goes sha",
    created_at: new Date(),
    user: {
      full_name: "Mirian Achonwa",
      avatar: "/images/notif_img.png",
      id: 23,
      is_top_vetted: true,
    },
  },
  {
    response: "Alright I'm listening",
    created_at: new Date(),
    user: {
      full_name: "You",
      avatar: "/images/bosun_pic.png",
      id: 53,
    },
  },
];
export const EachRequestDetails = Array(10)
  .fill("")
  .map((_, i) => ({
    id: 1 + i,
    name: "Mirian Achonwa",
    email: "@Mirian",
    img: "/images/notif_img.png",
    time: "1hr ago",
    status:
      i % 2 === 0
        ? "pending"
        : i % 3 === 0
        ? "withdrawn"
        : i % 5 === 0
        ? "accepted"
        : "declined",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor lectus mauris nisl mi. Nulla ridiculus ut egestas amet, duis imperdiet. Mauris facilisis tincidunt sed metus magna at maecenas.",
    rating: "4.0",
    price: 45000,
    responses: i % 3 !== 0 ? RequestResponse : null,
  }));
