import SlantedFileIcon from "components/Vectors/SlantedFileIcon";
import ScrollToTopButton from "components/ScrollToTopButton";

const CommunityGuideline = () => {
  return (
    <>
      <ScrollToTopButton />
      <main className="-mt-20 mb-64 px-6 md:mt-20 lg:px-[4vw]">
        <div className="noScroll mx-auto w-[90vw] max-w-[980px] overflow-y-auto rounded-24 bg-white  shadow-sm">
          <div className="p-4 md:p-6">
            <SlantedFileIcon />

            <h3 className="font-Bai text-2xl font-bold text-[#222222] md:text-[50px] md:leading-[60px]">
              Community Guideline
            </h3>
          </div>

          <div className="h-px bg-grey_60"></div>

          <div className="p-4 md:p-6">
            <p className="mb-8 text-base font-medium font-Inter text-grey_20 md:text-lg">
              At Truelocal, our mission is to create a safe and thriving community where job seekers and employers can connect, collaborate, and build successful professional relationships. To achieve this goal, we have established these Community Guidelines to ensure a positive and respectful environment for all users. By using our platform, you agree to abide by these guidelines:
              <br />
              <li className="ml-4 list-disc"> <span className="font-bold text-lg md:text-xl">Respect and Professionalism:</span> Treat all users with respect, professionalism, and courtesy. Discrimination, harassment, hate speech, or any form of offensive behavior will not be tolerated.</li><br />
              <li className="ml-4 list-disc"> <span className="font-bold text-lg md:text-xl"> Accuracy and Honesty:</span> Provide accurate and honest information in your job listings, profiles, and communications. Misleading or false information can harm the trust within our community.</li><br />
              <li className="ml-4 list-disc"> <span className="font-bold text-lg md:text-xl"> Safety and Privacy:</span> Protect your personal information and exercise caution when sharing sensitive data. Do not share personal contact information, financial details, or engage in conversations that make you feel uncomfortable.</li><br />
              <li className="ml-4 list-disc">  <span className="font-bold text-lg md:text-xl"> Legality and Compliance:</span> Adhere to all relevant laws, regulations, and our Terms of Service. This includes but is not limited to intellectual property rights, employment laws, and tax regulations.</li><br />
              <li className="ml-4 list-disc">  <span className="font-bold text-lg md:text-xl"> Content and Language: </span>Keep your content professional and free from offensive or inappropriate language. Any content that is spammy, defamatory, or violates intellectual property rights will be removed.</li><br />
              <li className="ml-4 list-disc">  <span className="font-bold text-lg md:text-xl"> Fair Practices:</span> Promote fair hiring and employment practices. Discrimination based on race, gender, age, disability, sexual orientation, or any other protected status is strictly prohibited.</li><br />
              <li className="ml-4 list-disc">   <span className="font-bold text-lg md:text-xl">Feedback and Reviews:</span> Provide constructive feedback and reviews about your experiences honestly and respectfully. Do not engage in fake reviews or ratings manipulation.</li><br />
              <li className="ml-4 list-disc">   <span className="font-bold text-lg md:text-xl">Messaging and Communication:</span> Use our messaging system for job-related discussions. Unsolicited marketing messages, spam, or harassment via messages will not be tolerated.</li><br />
              <li className="ml-4 list-disc"> <span className="font-bold text-lg md:text-xl"> Conflict Resolution:</span> If you encounter issues or disputes with another user, try to resolve them amicably through open communication. If necessary, report issues to our support team for assistance.</li><br />
              <li className="ml-4 list-disc"> <span className="font-bold text-lg md:text-xl">  Reporting Violations:</span> If you come across content or behavior that violates these guidelines, please report it to our moderation team promptly. Your cooperation is crucial in maintaining a safe community.</li><br />
              <li className="ml-4 list-disc"> <span className="font-bold text-lg md:text-xl"> Multiple Accounts:</span> Do not create multiple accounts to manipulate our platform or engage in fraudulent activities.</li><br />
              <li className="ml-4 list-disc">  <span className="font-bold text-lg md:text-xl"> Community Growth:</span> Contribute positively to the growth of our community by referring trustworthy users and helping to maintain a welcoming atmosphere.</li><br />
              Failure to comply with these Community Guidelines may result in warnings, temporary suspensions, or permanent bans from our platform, depending on the severity of the violation. We reserve the right to enforce these guidelines to ensure the integrity of our job marketplace.
              <br />
              Remember, our community's success depends on the actions of each member. By adhering to these guidelines, you contribute to a vibrant and trustworthy job marketplace that benefits everyone. Thank you for being a part of Truelocal.
            </p>
          </div>
        </div>
      </main>
    </>
  );
};

export default CommunityGuideline;
