import SearchInput from "components/forms/SearchInput";
import FilterDropDown from "components/mui-components/DropDownWrapper/FilterDropDown";
import NoActiveRequest from "components/Vectors/NoActiveRequest";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useGetProviderOffersQuery} from "services/user/userRegApi";
import {debounce, removeEmpty} from "utils";
import OfferCard from "../MarketPlace/Widgets/OfferCard";
import TablePagination from "components/mui-components/TablePagination";
import {Button} from "@mui/material";
import {DisplayData} from "pages/Clients/Dashboard/Main";

const MyJobOffers = () => {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("q"))
      setChoice(prev => ({...prev, query: searchParams.get("q")}));
  }, [searchParams]);

  const initialFilter = {
    status: "",
    query: "",
  };
  const [choice, setChoice] = useState(initialFilter);

  function handleInput(e) {
    setChoice(prev => ({...prev, query: e.target.value}));
  }
  const debouncedHandleInput = debounce(handleInput, 1000);

  const [rowPerPage, setRowPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const queryParams = {
    status: choice?.status,
    query: choice?.query,
    page,
    per_page: rowPerPage,
    is_paginated: "yes",
  };

  const {
    data: jobOffers,
    isLoading,
    error,
  } = useGetProviderOffersQuery(removeEmpty(queryParams));

  const offersList = Array.isArray(jobOffers?.data)
    ? jobOffers?.data
    : jobOffers?.data?.data;

  // console.log(offersList)

  return (
    <div className="mt-6 rounded-t-20 bg-white p-3 md:p-6 md:pb-20">
      <section className="my-6 flex flex-wrap items-center gap-3 gap-y-4">
        <SearchInput
          placeholder="Search Jobs"
          value={choice.query}
          onChange={debouncedHandleInput}
        />
        <FilterDropDown
          name="status"
          choice={choice}
          isSearch={false}
          isLoading={isLoading}
          setChoice={setChoice}
          selectOption={[
            {label: "All Status", value: ""},
            {label: "Pending", value: "Pending"},
            {label: "Accepted", value: "Accepted"},
            {label: "Declined", value: "Declined"},
            {label: "Withdrawn", value: "Withdrawn"},
          ]}
        />
        <Button variant="contained" onClick={() => setChoice(initialFilter)}>
          Clear Filter
        </Button>
      </section>

      <DisplayData
        isLoading={isLoading}
        isError={error}
        error={error}
        isEmpty={offersList?.filter(el => el?.job).length <= 0}
        emptyResponse={{
          message: "No Jobs offers yet",
          extraText:
            "You have not made any job offer yet, go to marketplace to discover jobs",
          icon: <NoActiveRequest />,
        }}
      >
        <div className="my-6 grid gap-6 md:grid-cols-3">
          {offersList
            ?.filter(el => el?.job)
            ?.map(offer => (
              <OfferCard key={offer?.id} offer={offer} />
            ))}
        </div>
        <div className="mt-4">
          {offersList?.filter(el => el?.job)?.length > 0 && (
            <TablePagination
              meta={{
                totalPages: jobOffers?.last_page,
                totalCount: jobOffers?.total,
                currentPage: jobOffers?.current_page,
              }}
              from={jobOffers?.from}
              to={jobOffers?.to}
              page={page}
              setPage={setPage}
              perPage={rowPerPage}
              setPerPage={setRowPerPage}
            />
          )}
        </div>
      </DisplayData>
    </div>
  );
};

export default MyJobOffers;
