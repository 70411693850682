const NoActiveRequest = () => {
  return (
    <svg
      width="54"
      height="50"
      viewBox="0 0 54 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0003 18.006C24.4702 16.6703 25.3976 15.544 26.6183 14.8266C27.839 14.1092 29.2742 13.8469 30.6697 14.0863C32.0652 14.3257 33.331 15.0512 34.2428 16.1344C35.1547 17.2176 35.6537 18.5886 35.6516 20.0045C35.6516 24.0015 29.6561 26 29.6561 26M29.7334 34H29.76M29.3336 47.3333C41.8521 47.3333 52.0003 37.1851 52.0003 24.6667C52.0003 12.1482 41.8521 2 29.3336 2C16.8152 2 6.66697 12.1482 6.66697 24.6667C6.66697 27.2 7.08256 29.6362 7.84929 31.911C8.13782 32.767 8.28209 33.195 8.30811 33.5238C8.33381 33.8485 8.31438 34.0762 8.23404 34.3919C8.15269 34.7115 7.97309 35.044 7.61388 35.7088L3.25213 43.7823C2.62997 44.9339 2.31889 45.5097 2.38851 45.9541C2.44915 46.3411 2.67696 46.6819 3.01143 46.886C3.39544 47.1202 4.04643 47.0529 5.34841 46.9183L19.0045 45.5067C19.418 45.464 19.6248 45.4426 19.8133 45.4498C19.9986 45.4569 20.1295 45.4743 20.3103 45.516C20.494 45.5583 20.7252 45.6474 21.1874 45.8254C23.7155 46.7994 26.4622 47.3333 29.3336 47.3333Z"
        stroke="#3B3D3B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoActiveRequest;
