const DescriptionComp = ({
  description,
  maxWidth,
  customHeaderClass,
  customParaClass,
}) => {
  return (
    <div className={`${maxWidth && maxWidth}`}>
      <h3
        className={`  ${
          customHeaderClass
            ? customHeaderClass
            : "text-prop text-lg font-medium text-grey_10"
        } `}
      >
        Description{" "}
      </h3>
      <p
        className={`${
          customParaClass
            ? customParaClass
            : "text-prop mt-[8px] text-base text-grey_30"
        }`}
      >
        {description
          ? description
          : `Ac duis accumsan consectetur pellentesque pellentesque enim eu duis
        eros. Egestas augue quis posuere semper. Mus consectetur magna tempus
        massa. Semper aliquet amet a pulvinar mi gravida feugiat id pellentesque
        ac ultricies vivamus tincidunt habitant.`}
      </p>
    </div>
  );
};
export default DescriptionComp;
