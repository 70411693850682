import {useNavigate} from "react-router";
import {IconButton} from "@mui/material";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ShieldTickIcon from "components/Vectors/ShieldTickIcon";
import {useGetAllAdminQuery} from "services/admin/adminGeneralApi";
import moment from "moment";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";

const AdminTab = () => {
  const navigate = useNavigate();

  const {
    data: allAdmin,
    isLoading,
    error,
    isError,
  } = useGetAllAdminQuery({
    pollingInterval: 3000,
  });

  const mockData = allAdmin?.data.map(value => ({
    admin: {
      name: `${value.first_name} ${value.last_name}`, // the name property us necessary for searching, so when passing an object make sure you put the name field there
      img: value.photo_url,
    },
    email: `${value.email}`,
    number: `${value.phone_number}`,
    role: value.roles[0]?.name,
    date: `${moment(value.created_at).format("DD/MM/YYYY")}`,
    status: value.status,
    id: `${value.id}`,
    action: (
      <IconButton onClick={() => navigate(`${value.id}?state=admin`)}>
        <ArrowCircleRightOutlinedIcon
          sx={{
            color: "#E98100",
          }}
        />
      </IconButton>
    ),
  }));

  return (
    <>
      <MyDataTable
        options={{
          viewColumns: false,
          filter: false,
        }}
        headCells={headCells}
        data={mockData}
        scrollX={false}
        error={error}
        isError={isError}
        isLoading={isLoading}
        emptyData={{
          icon: ShieldTickIcon,
          message: "No Administrator Created",
          iconColor: "#3B3D3B",
        }}
      />
    </>
  );
};

export default AdminTab;

const headCells = [
  {
    name: "admin",
    label: "Admin",
    // use this options instead for passing custom jsx
    options: {
      customBodyRender: value => (
        <UserDisplay name={value.name} img={value.img} />
      ),
    },
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "number",
    label: "Phone Number",
  },
  {
    name: "role",
    label: "Roles",
    options: {
      // enable filter option, i.e you can filter by roles
      filter: true,
    },
  },
  {
    name: "date",
    label: "Date Added",
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      customBodyRender: value => (
        <span
          className={`rounded-lg px-3 py-1.5 capitalize ${
            value.toLowerCase() === "active"
              ? "bg-[#5010b319] text-[#5010B3] "
              : "bg-grey_70 text-[#3B3D3B] "
          }`}
        >
          {value}
        </span>
      ),
    },
  },
  {
    name: "action",
    label: "",
  },
];
