import Avatar from "@mui/material/Avatar";
import {toCurrency} from "utils";

const NewJobCard = () => {
  return (
    <div className="flex flex-col divide-y rounded-2xl bg-white">
      <div className="flex flex-col gap-y-3 p-5">
        <div className="flex items-center justify-between">
          <span className="font-mulish max-w-[183px] truncate text-xs font-bold uppercase text-primary">
            Housing and Accommodation
          </span>
          <div className="flex items-center gap-x-1">
            <div className="flex items-center gap-x-0.5 rounded-[5px] border border-[#C9C9CF] px-2 py-1">
              <span className="font-mulish text-xs font-bold uppercase text-[#151519]">
                3 offers
              </span>
              <span className="h-1.5 w-1.5 rounded-full bg-[#d92d20]"></span>
            </div>
            <span className="font-mulish rounded-[50px] bg-[#E8F7EE] px-2 py-1 text-xs uppercase text-[#039855]">
              open
            </span>
          </div>
        </div>
        <div>
          <p className="text-lg font-semibold text-[#232329]">
            Happy Ending Massage. A title for two lines and more
          </p>
        </div>
        <div className="flex max-w-[164px] items-center gap-x-1 rounded-lg bg-[#F3ECFD] p-[5px]">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.73827 14.5371L4.86459 10.0669C3.41362 7.80986 4.04041 4.80626 6.27299 3.31787C7.92435 2.21696 10.0757 2.21696 11.7271 3.31787C13.9597 4.80626 14.5864 7.80986 13.1355 10.0669L10.2618 14.5371C9.67135 15.4556 8.32872 15.4556 7.73827 14.5371ZM9.00003 10.1248C10.4498 10.1248 11.625 8.94959 11.625 7.49984C11.625 6.05009 10.4498 4.87484 9.00003 4.87484C7.55029 4.87484 6.37503 6.05009 6.37503 7.49984C6.37503 8.94959 7.55029 10.1248 9.00003 10.1248Z"
              fill="#440E98"
            />
          </svg>
          <span className="font-mulish text-xs font-bold text-[#14042D]">
            Physical - VI, Lagos
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-2xl font-semibold text-[#444444]">
            {toCurrency(30000)}
          </span>
          <Avatar src="" alt="" sx={{height: 40, width: 40}} />
        </div>
      </div>
      <div className="flex items-center justify-between p-5">
        <div className="flex items-center">
          <div className="flex items-center gap-x-1.5 p-[11px]">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.86318 9.32185C3.05361 9.63697 2.95254 10.0468 2.63742 10.2372C2.3223 10.4277 1.91247 10.3266 1.72204 10.0115C1.45131 9.56349 1.26762 9.17745 1.15962 8.92824C1.00496 8.57141 1.0274 8.16966 1.21581 7.8332C1.50332 7.31979 2.22963 6.13289 3.34898 5.06645C4.46576 4.00246 6.03718 3 7.99991 3C9.96264 3 11.5341 4.00246 12.6508 5.06645C13.7702 6.13289 14.4965 7.31979 14.784 7.8332C14.9724 8.16966 14.9949 8.57141 14.8402 8.92824C14.7322 9.17745 14.5485 9.56349 14.2778 10.0115C14.0873 10.3266 13.6775 10.4277 13.3624 10.2372C13.0473 10.0468 12.9462 9.63697 13.1366 9.32185C13.3544 8.96154 13.5055 8.64993 13.5971 8.44295C13.3266 7.96622 12.686 6.94154 11.7311 6.0318C10.7448 5.09211 9.48654 4.33333 7.99991 4.33333C6.51327 4.33333 5.25501 5.09211 4.2687 6.0318C3.31381 6.94155 2.67318 7.96622 2.40267 8.44295C2.49432 8.64993 2.64543 8.96154 2.86318 9.32185Z"
                fill="#69697B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.99991 5.33333C6.15896 5.33333 4.66657 6.82572 4.66657 8.66667C4.66657 10.5076 6.15896 12 7.99991 12C9.84086 12 11.3332 10.5076 11.3332 8.66667C11.3332 6.82572 9.84086 5.33333 7.99991 5.33333ZM5.99991 8.66667C5.99991 7.5621 6.89534 6.66667 7.99991 6.66667C9.10448 6.66667 9.99991 7.5621 9.99991 8.66667C9.99991 9.77124 9.10448 10.6667 7.99991 10.6667C6.89534 10.6667 5.99991 9.77124 5.99991 8.66667Z"
                fill="#69697B"
              />
            </svg>
            <span className="font-mulish text-sm font-bold text-[#151519]">
              2.1k
            </span>
          </div>
          <div className="flex items-center gap-x-1.5 p-[11px]">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.97224 1.82059C7.3991 2.03677 7.7466 2.28979 8.0001 2.50253C8.25359 2.28979 8.60107 2.03677 9.02791 1.82059C9.9952 1.33069 11.3874 1.0277 12.953 1.82058C13.953 2.32707 14.6295 3.09344 14.9979 4.0239C15.3614 4.94186 15.4108 5.98108 15.2358 7.03546C14.9378 8.83185 13.7099 10.4348 12.4489 11.6699C11.1722 12.9204 9.77223 13.8794 8.9827 14.3794C8.37839 14.7622 7.62183 14.7622 7.01751 14.3794C6.22798 13.8794 4.82802 12.9204 3.55124 11.6699C2.2902 10.4348 1.06236 8.83186 0.764294 7.03546C0.589349 5.98108 0.63873 4.94186 1.00217 4.0239C1.37056 3.09344 2.04707 2.32707 3.04715 1.82058C4.61275 1.02769 6.00493 1.33069 6.97224 1.82059ZM7.49219 3.8568C7.61884 4.00577 7.80453 4.09165 8.00012 4.09165C8.19536 4.09165 8.38074 4.00606 8.50737 3.85757C8.50752 3.8574 8.50766 3.85722 8.50781 3.85705C8.50798 3.85685 8.50815 3.85665 8.50832 3.85645L8.51844 3.84509C8.52881 3.83358 8.54624 3.81462 8.57039 3.78962C8.6188 3.73952 8.69358 3.66584 8.79207 3.57968C8.99055 3.40605 9.27699 3.18903 9.63034 3.01007C10.3243 2.65858 11.258 2.45675 12.3506 3.01007C13.0639 3.37133 13.5114 3.89124 13.7582 4.51473C14.01 5.15073 14.0672 5.93314 13.9205 6.81721C13.6928 8.18907 12.7146 9.54336 11.516 10.7173C10.3331 11.8759 9.01976 12.7777 8.26928 13.253C8.10057 13.3599 7.89964 13.3599 7.73093 13.253C6.98044 12.7777 5.66711 11.8759 4.48419 10.7173C3.28553 9.54336 2.30727 8.18907 2.07964 6.81721C1.93296 5.93314 1.99007 5.15073 2.24187 4.51473C2.48873 3.89124 2.93623 3.37133 3.64956 3.01007C4.74211 2.45675 5.67581 2.65858 6.36983 3.01007C6.72319 3.18903 7.00965 3.40606 7.20813 3.57969C7.30663 3.66585 7.38142 3.73953 7.42983 3.78963C7.45398 3.81463 7.47141 3.83359 7.48179 3.84511L7.49219 3.8568Z"
                fill="#69697B"
              />
            </svg>
            <span className="font-mulish text-sm font-bold text-[#151519]">
              82
            </span>
          </div>
        </div>
        <div className="flex items-center gap-x-1 rounded-[200px] bg-[#151519] p-1">
          <span className="font-mulish text-xs font-bold text-white">
            You made an offer
          </span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="16" height="16" rx="8" fill="#DD900D" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 12.5C10.4853 12.5 12.5 10.4853 12.5 8C12.5 5.51472 10.4853 3.5 8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 10.4853 5.51472 12.5 8 12.5ZM8 6.25C8 5.97386 7.77614 5.75 7.5 5.75C7.22386 5.75 7 5.97386 7 6.25V8.75C7 9.02614 7.22386 9.25 7.5 9.25H9C9.27614 9.25 9.5 9.02614 9.5 8.75C9.5 8.47386 9.27614 8.25 9 8.25H8V6.25Z"
              fill="#FEF6E7"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default NewJobCard;
