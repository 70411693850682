import React from "react";

const ActiveEngagement = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7305 16.6396C27.2205 16.6396 29.2155 14.6296 29.2155 12.1396C29.2155 9.64965 27.2205 7.63965 24.7305 7.63965C22.2405 7.63965 20.2305 9.64965 20.2305 12.1396C20.2305 14.6296 22.2405 16.6396 24.7305 16.6396ZM12.7305 16.6396C15.2205 16.6396 17.2155 14.6296 17.2155 12.1396C17.2155 9.64965 15.2205 7.63965 12.7305 7.63965C10.2405 7.63965 8.23047 9.64965 8.23047 12.1396C8.23047 14.6296 10.2405 16.6396 12.7305 16.6396ZM12.7305 19.6396C9.23547 19.6396 2.23047 21.3946 2.23047 24.8896V27.1396C2.23047 27.9646 2.90547 28.6396 3.73047 28.6396H21.7305C22.5555 28.6396 23.2305 27.9646 23.2305 27.1396V24.8896C23.2305 21.3946 16.2255 19.6396 12.7305 19.6396ZM24.7305 19.6396C24.2955 19.6396 23.8005 19.6696 23.2755 19.7146C23.3055 19.7296 23.3205 19.7596 23.3355 19.7746C25.0455 21.0196 26.2305 22.6846 26.2305 24.8896V27.1396C26.2305 27.6646 26.1255 28.1746 25.9605 28.6396H33.7305C34.5555 28.6396 35.2305 27.9646 35.2305 27.1396V24.8896C35.2305 21.3946 28.2255 19.6396 24.7305 19.6396Z"
        fill="#E98100"
      />
    </svg>
  );
};

export default ActiveEngagement;
