import { Link, useMatch } from "react-router-dom";

// custom link wrapper using the useMatch to determine the active side menu
export default function CustomLink({
  children,
  to,
  baseUrl = "/",
  border,
  ...props
}) {
  let match = useMatch(to !== baseUrl ? baseUrl + to + "/*" : baseUrl);
  return to ? (
    <Link
      className={`menu-link my-5 rounded-xl  text-base ${
        match
          ? "svgColorChange bg-primary text-white"
          : "text-grey_20 hover:bg-[#d3d3f031]"
      }`}
      to={baseUrl === to ? to : baseUrl + to}
      {...props}
    >
      {children}
    </Link>
  ) : (
    <button
      className={`menu-link my-7 w-full text-base text-grey_20 hover:bg-[#d3d3f031]`}
      {...props}
    >
      {children}
    </button>
  );
}

export function CustomLink2({ children, to, baseUrl = "/buyer/", ...props }) {
  let match = useMatch(to !== baseUrl ? baseUrl + to + "/*" : baseUrl);
  return (
    <Link
      className={`text- rounded-lg p-2 px-4 ${
        match ? "bg-[#d3d3f031] font-semibold" : "font-medium"
      }`}
      to={to || ""}
      {...props}
    >
      {children}
    </Link>
  );
}
