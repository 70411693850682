import { Skeleton } from "@mui/material";
const DashboardBox = ({
  icon,
  iconBg,
  bg,
  value = "N/A",
  text,
  stats,
  isLoading,
  isError,
}) => {
  return (
    <div
      className={`box flex flex-col justify-center rounded-2xl border border-grey_60 p-4 ${bg || "bg-grey_80"
        }`}
    >
      <div className="mb-2 flex items-center gap-3">
        <span
          className={`icon-box flex h-[52px] w-[52px] items-center justify-center rounded-10 ${iconBg || "bg-white"
            }`}
        >
          {icon}
        </span>
        <div className="stats">
          <p className="text-base font-bold text-grey_20">{text}</p>
        </div>
      </div>
      {value && (
        <h4 className="font-Bai text-[32px] font-semibold text-grey_10">
          {isLoading ? (
            (isError || value === undefined) ? (
              "..."
            ) : (
              <Skeleton variant="text" width={45} />
            )
          ) : (
            value
          )}
        </h4>
      )}

      {stats && (
        <p className="flex items-center gap-1 text-base font-medium text-grey_30">
          {stats}
        </p>
      )}
    </div>
  );
};

export default DashboardBox;
