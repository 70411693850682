import Modal from "components/mui-components/Modal";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import {ReactComponent as UbaLogo} from "assets/Uba.svg";
import moment from "moment";
import {handleError, toCurrency} from "utils";
import {LoadingButton} from "@mui/lab";
import {Link} from "react-router-dom";
import {useCompleteRefundMutation} from "services/admin/adminGeneralApi";
import {showToast} from "redux/store.hooks";
import useCopy from "hooks/useCopy";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

function RefundDetailsModal({openModal, setopenModal, data, allBanks}) {
  const [completeRefund, {isLoading}] = useCompleteRefundMutation();
  const {copied, copyAction} = useCopy(
    `${data?.client?.bank_account?.account_number}`,
  );
  const onCompleteHandler = async () => {
    try {
      let res = await completeRefund({
        refund_history_id: data?.id,
        client_id: data?.client?.id,
      }).unwrap();
      showToast(res?.message);
      setopenModal(false);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Modal
        title={`Refund details`}
        openModal={openModal}
        closeModal={() => setopenModal(false)}
      >
        <div className="mt-6">
          <UserDisplay
            name={data?.client?.full_name}
            img={data?.client?.avatar}
            size="large"
            max_width="200px"
            email={`@${data?.client?.username}`}
          />
          <div className="my-12 flex items-center justify-between">
            <div className="">
              <p className="text- mb-1 text-lg font-medium">Date</p>
              <p className="text-lg font-medium text-black">
                {moment(data?.created_at).format("DD MMMM, YYYY h:mma")}
              </p>
            </div>
            <p className="font-Bai text-[32px] font-semibold text-grey">
              {toCurrency(data?.engagement?.transaction?.amount)}
            </p>
          </div>

          <div className="mb-8">
            <p className="mb-2 text-lg font-medium text-grey_30">Engagement</p>

            <Link
              to={`/admin/engagements/${data?.engagement?.id}`}
              className="group flex gap-3 rounded-[20px] bg-grey_70 p-3"
            >
              <img
                src={
                  data?.engagement?.job_detail?.images[0]?.image_url ||
                  "/images/broken-image.png"
                }
                alt="EngagementImage"
                className="h-[80px] w-[80px] rounded-16"
              />
              <div>
                <p className="font-Bai text-xl font-semibold text-grey_20 transition-colors group-hover:text-primary">
                  {data?.engagement?.job_detail?.job_title}
                </p>
              </div>
            </Link>
          </div>

          <div className="mb-8">
            <p className="mb-2 text-lg font-medium text-grey_30">
              Account details
            </p>

            <div className="flex gap-3 rounded-[16px] bg-grey_80 p-3">
              <UbaLogo />
              <div className="">
                <p className="text-base font-medium text-grey">
                  {data?.client?.bank_account?.account_name}
                </p>
                <p className="text-base font-medium text-grey_30">
                  {data?.client?.bank_account?.account_number} -{" "}
                  {
                    allBanks.find(
                      bank =>
                        bank?.code === data?.client?.bank_account.bank_code,
                    )?.name
                  }
                </p>
              </div>
            </div>
            <div className="mt-3 flex justify-center">
              <LoadingButton
                color={copied ? "success" : "primary"}
                onClick={() => copyAction()}
                startIcon={
                  copied ? <CheckOutlinedIcon /> : <ContentCopyOutlinedIcon />
                }
              >
                {copied ? "Copied" : "Copy number"}
              </LoadingButton>
            </div>
          </div>

          <LoadingButton
            variant="contained"
            fullWidth
            loading={isLoading}
            onClick={onCompleteHandler}
            sx={{mt: 10}}
          >
            Complete Refund
          </LoadingButton>
        </div>
      </Modal>
    </>
  );
}

export default RefundDetailsModal;
