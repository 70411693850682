import {useState} from "react";
import {Button} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MyModal from "components/mui-components/Modal";
import PurchaseTokenModal from "../Subscription/PurchasetokenModal";
import {
  useMakeJobOfferMutation,
  useWithdrawJobOfferMutation,
} from "services/user/userRegApi";
import {showToast} from "redux/store.hooks";
import {formatCurrency, handleError} from "utils";
import {useParams} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import LoadingBtn from "components/mui-components/LoadingButton";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import {useGetUser} from "hooks/getUserHook";
import {useGetUserRequest} from "hooks/useGetUserRequest";

const MakeRequestModal = ({
  open,
  onClose,
  setInfo,
  info,
  reqToken,
  content,
  refetchJob = () => {},
}) => {
  const {id} = useParams();
  const {user} = useGetUser();
  const {refetch} = useGetUserRequest(!user?.user_type);

  const NoTokenComponent = () => {
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);

    return (
      <>
        <div className="my-20 text-center">
          <h2 className="font-Bai text-2xl font-bold md:text-3xl">No tokens</h2>
          <p className="mt-6 text-grey_30">
            You do not have sufficient tokens to make this request.
          </p>
        </div>
        <div className="grid grid-cols-2 gap-x-4">
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              color: "#D27400",
              bgcolor: "#F2F4F7",
              "&:hover": {bgcolor: "#64646456"},
            }}
          >
            Nevermind
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowPurchaseModal(true)}
          >
            Get Tokens
          </Button>
        </div>
        <PurchaseTokenModal
          openModal={showPurchaseModal}
          closeModal={() => {
            setShowPurchaseModal(false);
            onClose();
          }}
          // refetch function runs after payment is complete
          refetch={() => {
            setInfo("");
          }}
        />
      </>
    );
  };

  const OriginalComponent = () => {
    const methods = useForm();
    const [makeOffer, {isLoading: isMakingOffer}] = useMakeJobOfferMutation();
    const onSubmit = ({amount_offered, pitch_message}) => {
      makeOffer({
        client_job_id: id,
        amount_offered,
        pitch_message,
      })
        .unwrap()
        .then(res => {
          setInfo("submitted");
          refetch();
          refetchJob();
          showToast(res?.message, "success");
          methods.reset();
          onClose();
        })
        .catch(err => {
          handleError(err);
          if (
            err?.data?.message ===
            "You do not have sufficient tokens to make this request."
          ) {
            setInfo("no-token");
          }
        });
    };
    return (
      <FormProvider {...methods}>
        <p className="font-p">
          Client's Budget:{" "}
          <span className="font-semibold">
            {content?.price_option === "no_budget"
              ? "No budget"
              : formatCurrency(content?.price)}
          </span>
        </p>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-4">
          <ValidatedInput2
            type="number"
            label="How much can you do this job?"
            name="amount_offered"
            prefixIcon='₦'
            placeholder="Offer a competitive amount"
          />
          <div className="mt-5">
            <ValidatedTextArea2
              minLength={20}
              label="Pitch Message"
              name="pitch_message"
            />
          </div>
          <div className="my-4 rounded-16 bg-[#FDF2E6] py-[18px] px-4">
            <InfoOutlinedIcon sx={{color: "#8C4D00"}} />
            <span className="ml-3 text-sm text-[#8C4D00]">
              You will be charged {reqToken} Token to make this Offer
            </span>
          </div>
          <LoadingBtn
            type="submit"
            // disabled={!methods.formState.isValid}
            loading={isMakingOffer}
            fullWidth
          >
            Send
          </LoadingBtn>
        </form>
      </FormProvider>
    );
  };

  const WithdrawRequestComponent = () => {
    const [withdrawRequest, {isLoading}] = useWithdrawJobOfferMutation();
    const handleWithdraw = () => {
      withdrawRequest({client_job_id: id})
        .unwrap()
        .then(res => {
          setInfo("cancelled");
          refetch();
          showToast(res?.message, "success");
          onClose();
        })
        .catch(err => {
          handleError(err);
        });
    };
    return (
      <>
        <div className="my-5 md:mt-14 md:mb-20">
          <h2 className="text-center text-xl font-bold text-grey md:text-3xl">
            Are you sure you want to Withdraw Offer?
          </h2>
          <p className="mt-6 text-center text-sm text-grey_30 md:text-lg">
            You won’t be able to make request to this job if you cancel, until
            when next the client repost this Job - Connection Tokens are
            non-refundable
          </p>
        </div>
        <div className="grid grid-cols-2 gap-x-4">
          <Button onClick={onClose} variant="contained" color="primaryInverse2">
            Nevermind
          </Button>
          <LoadingBtn
            onClick={handleWithdraw}
            color="error"
            loading={isLoading}
          >
            Withdraw Request
          </LoadingBtn>
        </div>
      </>
    );
  };

  return (
    <MyModal
      closeModal={onClose}
      openModal={open}
      title={info ? "" : "Make an Offer"}
      maxWidth={500}
    >
      {info === "no-token" ? (
        <NoTokenComponent />
      ) : info === "submitted" ? (
        <WithdrawRequestComponent />
      ) : (
        <OriginalComponent />
      )}
    </MyModal>
  );
};

export default MakeRequestModal;
