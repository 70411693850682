import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import TransactionsTab from "./Tabs/TransactionsTab";
import PayoutsTab from "./Tabs/PayoutsTab";
import EngagTranscHistoryTab from "./TableTabs/EngagTranscHistoryTab";
// import ServiceProviderCommTab from "./TableTabs/ServiceProviderCommTab";
import SubscriptionsTab from "./TableTabs/SubscriptionsTab";
// import ClientsPaymentChargesTab from "./TableTabs/ClientsPaymentChargesTab";
import PaymentRequestsTab from "./Tabs/PaymentRequestsTab";
import PaymentHistoryTab from "./TableTabs/PaymentHistoryTab";
import RefundHistoryTab from "./TableTabs/RefundHistoryTab";
import PendingRefundTab from "./Tabs/PendingRefundTab";
import {
  useGetPayoutStatQuery,
  // useGetTransactionHistoryQuery,
} from "services/admin/adminGeneralApi";
const AdminTransactions = () => {
  const [activeTab, setactiveTab] = useState("transactions");
  const [activePayoutTab, setactivePayoutTab] = useState("payment_requests");
  const navigate = useNavigate();
  const [activeTransactionTab, setactiveTransactionTab] = useState(
    "transaction-history",
  );
  let { search } = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get("tab");
  const transactionTab = params.get("transactionTab");
  const payoutTab = params.get("payoutTab");
  useEffect(() => {
    if (tab) {
      setactiveTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (transactionTab) {
      setactiveTransactionTab(transactionTab);
    }
  }, [transactionTab]);

  useEffect(() => {
    if (payoutTab) {
      setactivePayoutTab(payoutTab);
    }
  }, [payoutTab]);
  // const {
  //   data: transactionData,
  //   isLoading,
  //   error,
  //   isError,
  // } = useGetTransactionHistoryQuery();
  const { data } = useGetPayoutStatQuery();
  return (
    <DashboardLayout title={"Transactions"} type="admin">
      <main>
        <TabContext value={activeTab}>
          <div className="rounded-2xl bg-white p-4 md:py-6">
            <TabList
              variant="scrollable"
              value={activeTab}
              className="mb-4"
              onChange={(_, newValue) => {
                navigate(`?tab=${newValue}`);
                setactiveTab(newValue);
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Transactions" value="transactions" />
              <Tab
                label={
                  <div className="flex h-fit items-center gap-2">
                    Payouts
                    <div className=" flex h-[24px] w-[24px] items-center justify-center rounded-full bg-[#D92D20]">
                      <p className="text-base font-semibold text-white">
                        {data?.data?.all_payouts || 0}
                      </p>
                    </div>
                  </div>
                }
                value="payouts"
              />
            </TabList>
            <TabPanel value="transactions">
              <TransactionsTab />
            </TabPanel>
            <TabPanel value="payouts">
              <PayoutsTab />
            </TabPanel>
          </div>

          <TabPanel value="transactions">
            <div className="mt-8 rounded-2xl bg-white p-4 md:py-6">
              <TabContext value={activeTransactionTab}>
                <TabList
                  fullWidth
                  variant="scrollable"
                  value={activeTransactionTab}
                  className="mb-6 min-w-full"
                  onChange={(_, newValue) => {
                    navigate(`?transactionTab=${newValue}`);
                    setactiveTransactionTab(newValue);
                  }}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Engagement Transaction History"
                    value="transaction-history"
                  />
                  {/* <Tab
                    label="Service Provider's Commission"
                    value="service-provider"
                  /> */}
                  <Tab label="Subscriptions" value="subscriptions" />
                  {/* <Tab
                    label="Client's Payment Charges"
                    value="payment-charge"
                  /> */}
                </TabList>
                <TabPanel value="transaction-history">
                  <EngagTranscHistoryTab />
                </TabPanel>
                {/* <TabPanel value="service-provider">
                  <ServiceProviderCommTab
                    data={transactionData?.data}
                    isLoading={isLoading}
                    error={error}
                    isError={isError}
                  />
                </TabPanel> */}
                <TabPanel value="subscriptions">
                  <SubscriptionsTab />
                </TabPanel>
                {/* <TabPanel value="payment-charge">
                  <ClientsPaymentChargesTab
                    data={transactionData?.data}
                    isLoading={isLoading}
                    error={error}
                    isError={isError}
                  />
                </TabPanel> */}
              </TabContext>
            </div>
          </TabPanel>

          <TabPanel value="payouts">
            <div className="mt-8 rounded-2xl bg-white p-4 md:py-6">
              <TabContext value={activePayoutTab}>
                <TabList
                  variant="scrollable"
                  value={activePayoutTab}
                  className="mb-4"
                  onChange={(_, newValue) => {
                    navigate(`?payoutTab=${newValue}`);
                    setactivePayoutTab(newValue);
                  }}
                  aria-label="basic tabs example"
                >
                  <Tab label="Payment Requests" value="payment_requests" />
                  <Tab label="Payment History" value="payment_history" />
                  <Tab label="Pending Refund" value="pending_refund" />
                  <Tab label="Refund History" value="refund_history" />
                </TabList>
                <TabPanel value="payment_requests">
                  <PaymentRequestsTab />
                </TabPanel>

                <TabPanel value="payment_history">
                  <PaymentHistoryTab />
                </TabPanel>

                <TabPanel value="pending_refund">
                  <PendingRefundTab />
                </TabPanel>

                <TabPanel value="refund_history">
                  <RefundHistoryTab />
                </TabPanel>
              </TabContext>
            </div>
          </TabPanel>
        </TabContext>
      </main>
    </DashboardLayout>
  );
}

export default AdminTransactions;
