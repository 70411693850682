const SmallNairaIcon = props => {
  return (
    <svg
      width="25"
      height="12"
      viewBox="0 0 25 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.248 11.7131V7.60106H0V6.57706H1.248V5.26506H0V4.24106H1.248V0.289062H2.912L4.432 4.24106H6.32V0.289062H7.584V4.24106H8.832V5.26506H7.584V6.57706H8.832V7.60106H7.584V11.7131H5.904L4.384 7.60106H2.496V11.7131H1.248ZM2.496 6.57706H4L3.52 5.26506H2.464L2.496 6.57706ZM6.32 9.45706H6.384L6.336 7.60106H5.664L6.32 9.45706ZM2.464 4.24106H3.136L2.464 2.25706H2.4L2.464 4.24106ZM5.28 6.57706H6.352L6.32 5.26506H4.8L5.28 6.57706Z"
        fill={props.color || "#6E726E"}
      />
      <path
        d="M23.832 0.289062V11.7131H25.832V0.289062H23.832Z"
        fill={props.color || "#E4E7EC"}
        mask="url(#path-1-inside-1_2995_21548)"
      />
    </svg>
  );
};
export default SmallNairaIcon;
