import EachEngagementCard from "./EachEngagementCard";
import User2 from "components/Vectors/User_2";
import FileIcon from "components/Vectors/FileIcon";

function EngagementBody({user}) {
  return (
    <div className="mb-5 rounded-2xl bg-grey_80 p-4 md:p-6">
      <EachEngagementCard
        icon={<User2 stroke="#E98100" />}
        title="All Engagements"
        value={user?.all_engagements_count || 0}
      />
      <EachEngagementCard
        icon={<User2 stroke="#E98100" />}
        title="Completed Engagements"
        value={user?.completed_engagements_count || 0}
      />
      <EachEngagementCard
        icon={<User2 stroke="#E98100" />}
        title="Ongoing Engagements"
        value={user?.ongoing_engagements_count || 0}
      />
      <EachEngagementCard
        icon={<FileIcon stroke="#E98100" />}
        title="All Jobs"
        value={user?.jobs_count || 0}
      />
    </div>
  );
}

export default EngagementBody;
