import {Outlet} from "react-router-dom";
import ClientAndProfNavbar from "components/ClientAndProfNavbar";
import {Footer, Nav} from "pages/LandingPage/widgets/components";
import ScrollToTopButton from "components/ScrollToTopButton";
import {useGetUser} from "hooks/getUserHook";
// import Joyride from "react-joyride";

const UserDashboardLayout = ({links, isProfessional, fullWidth}) => {
  const {isLoggedIn, user} = useGetUser();
  const isAdmin = user?.admin_permissions;

  return (
    <>
      <main>
        {/* <Joyride
        run={true}
        scrollToFirstStep={true}
        continuous={true}
        showProgress={true}
        showSkipButton
        hideCloseButton
        steps={[
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[16px] font-semibold leading-[150%] text-grey_30 ">
                  Dashboard
                </p>
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10 ">
                  You manage and an overview of your transactions here.
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext ">
                  Let’s take you on a tour on how to manage your jobs.
                </p>
              </div>
            ),
            target: "body",
            placement: "center",
            locale: {skip: <p>Nevermind Thanks</p>, next: <p>Begin Tour</p>},
            showProgress: false,
          },
          {
            content: (
              <h2>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Ducimus.
              </h2>
            ),
            target: ".first_step",
            locale: {skip: <p>Nevermind Thanks</p>, next: <p>Begin Tour</p>},
            showProgress: false,
          },
        ]}
        styles={{
          tooltip: {
            borderRadius: "24px",
            padding: "10px 20px 30px",
            width: "486px",
          },
          buttonSkip: {
            display: "flex",
            padding: "16px 24px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "12px",
            background: "var(--gray-scale-gray-scale-70, #F2F4F7)",
            color: "#E98100",
            textAlign: "center",
            fontSize: "16px",
            fontFamily: "Mulish",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            letterSpacing: "-0.48px",
          },
          buttonNext: {
            display: "flex",
            padding: "16px 24px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "12px",
            background: "#E98100",
          },
          tooltipFooter: {
            justifyContent: "flex-start",
            gap: "12px",
          },
        }}
      /> */}
        {/* <section className="border-b border-[#DCDCE0]">

      </section> */}
        <section className={`${fullWidth ? "" : "mx-auto max-w-[1400px]"}`}>
          {isLoggedIn ? (
            isAdmin ? (
              <Nav bgColor={"-left-10 w-[105%]"} />
            ) : (
              <ClientAndProfNavbar
                links={links}
                isProfessional={isProfessional}
                updatedPadX
              />
            )
          ) : (
            <Nav bgColor={"-left-10 w-[105%]"} />
          )}

          <section
            className={`w[95%]  mx-auto px-4 py-4 md:px-6 lg:px-[4vw] lg:py-9`}
          >
            <Outlet />
          </section>
        </section>
        <section className="mt-[200px]">
          <Footer isProfessional={isProfessional} isUser={true} />
        </section>
        {/* <ScrollToTop /> */}
        <ScrollToTopButton />
      </main>
    </>
  );
};
export default UserDashboardLayout;
