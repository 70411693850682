import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import moment from "moment";
import React from "react";
import {toCurrency} from "utils";

function EachPayoutRequest({item, onClick}) {
  const service_provider = item?.service_provider;
  return (
    <div onClick={onClick} className="cursor-pointer rounded-xl bg-grey_80 p-3">
      <UserDisplay
        name={
          service_provider?.professional_information?.business_name ||
          service_provider?.full_name
        }
        img={
          service_provider?.professional_information?.business_logo ||
          service_provider?.avatar
        }
        size="text-[1rem]"
        email={`@${service_provider?.username}`}
        badge={service_provider?.is_verified_account}
      />

      <div className="mt-3 flex items-center justify-between">
        <h4 className="font-Bai text-xl font-bold text-grey_20">
          {toCurrency(item?.amount)}
        </h4>
        <p className="text-[13px] font-medium text-[#aaaaaa]">
          {moment(item?.request_date_time).fromNow()}
        </p>
      </div>
    </div>
  );
}

export default EachPayoutRequest;
