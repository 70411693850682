import AutoCompleteField from "components/forms/AutoCompleteField";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import {useEffect, useState} from "react";
import {useFormContext, Controller} from "react-hook-form";
import {useGetAllServiceCategoriesQuery} from "services/generalApi";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const JobOverView = ({locationType}) => {
  const {watch, control, formState} = useFormContext();
  const {errors, touchedFields} = formState;

  const price = watch("price");
  const watchCategory = watch("category_id");
  const {data: categories, isLoading} = useGetAllServiceCategoriesQuery();
  const [expertise, setExpertise] = useState([]);

  useEffect(() => {
    setExpertise(
      categories?.data
        .find(val => String(val.id) === String(watchCategory))
        ?.expertises.map(el => {
          return {
            label: `${el?.name}  (${el?.location_type})`,
            value: el?.id,
          };
        }),
    );
  }, [categories, watchCategory]);

  return (
    <section>
      <h1 className="p-3 font-Bai text-2xl font-bold md:p-10 md:pb-5 md:text-[2rem]">
        Overview
      </h1>
      {/*<div className="h-px bg-grey_60"></div>*/}
      <div className="flex flex-col gap-y-6 p-3 pt-5 md:p-8">
        <AutoCompleteField
          bordered={false}
          loading={isLoading}
          selectOption={categories?.data
            ?.filter(category => category?.expertises_count > 0)
            ?.map(item => ({label: item?.name, value: item?.id}))}
          label="Category"
          name="category_id"
          placeholder="Select Category"
          className="font-Mulish text-sm text-grey_30"
        />
        <ValidatedInput2
          type="text"
          bordered={false}
          label="Job title"
          name="job_title"
          placeholder="Title this Job"
        />

        <div className="flex flex-col gap-y-1">
          {/* <AutoCompleteField
            bordered={false}
            loading={isLoading}
            name="expertise_id"
            selectOption={expertise?.length > 0 ? expertise : []}
            label="Sub-categories"
            placeholder="Select sub-category"
            className="font-Mulish text-sm text-grey_30"
          /> */}
          {/* {locationType && (
            <div className="text-xs capitalize text-error ">
              {locationType?.toLowerCase() === "physical"
                ? "This job will be done physically"
                : "This job will be done virtually/online"}
            </div>
          )} */}
        </div>
        <ValidatedTextArea2
          label="Description"
          name="description"
          loading={isLoading}
          bordered={false}
          placeholder="Describe this job"
          height="h-[130px]"
          maxLength={250}
        />

        <FormControl error={Boolean(errors.price && touchedFields.price)}>
          <FormLabel
            id="price"
            sx={{
              fontFamily: "Mulish",
              fontSize: "0.8rem",
            }}
          >
            Price
          </FormLabel>
          <Controller
            rules={{required: true}}
            control={control}
            name="price"
            render={({field}) => (
              <RadioGroup {...field} aria-labelledby="price" row>
                <FormControlLabel
                  value="enter_amount"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 28,
                        },
                      }}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "Mulish",
                      color: "#3B3D3B",
                    },
                  }}
                  label="Enter Amount"
                />
                <FormControlLabel
                  value="no_budget"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 28,
                        },
                      }}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "Mulish",
                      color: "#3B3D3B",
                    },
                  }}
                  label="No Budget"
                />
              </RadioGroup>
            )}
          />
          {errors.price && (
            <FormHelperText
              error={Boolean(errors.price)}
              sx={{fontFamily: "Mulish"}}
            >
              Field is required
            </FormHelperText>
          )}
        </FormControl>
        {price === "enter_amount" && (
          <div className="">
            <ValidatedInput2
              name="price_amount"
              label="Price"
              placeholder="Enter Amount"
              prefixIcon={"₦"}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default JobOverView;
