import TicketIcon from "components/Vectors/ticket";
import { useEffect, useState } from "react";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Skeleton, Tab } from "@mui/material";
import { CustomTabStyle } from "App";
import { useNavigate, useSearchParams } from "react-router-dom";
import TicketsTab from "./Tabs/TicketsTab";
import { useDashboardStatQuery, useGetAllTicketsQuery } from "services/admin/adminGeneralApi";
import { useDispatch, useSelector } from "react-redux";
import { selectAllTickets, setTickets, setTotalTicketsCount } from "redux/slice/ApiSlice";

const AdminTickets = () => {
  const [activeTab, setActiveTab] = useState("in-progress");
  const [page, setPage] = useState(1);
  const TICKETS_PER_PAGE = 5;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allTickets = useSelector(selectAllTickets);
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  useEffect(() => {
    if (tab) setActiveTab(tab);
  }, [tab]);

  const { data, isLoading, isError, error } = useGetAllTicketsQuery({
    params: {
      is_paginated: "yes",
      per_page: TICKETS_PER_PAGE,
      page,
    }
  });
  useEffect(() => {
    if (data) {
      dispatch(setTickets(data.data));
      dispatch(setTotalTicketsCount(data.pagination.total));
    }
  }, [dispatch, data]);
  const { data: stat } = useDashboardStatQuery();
  const ticketsStats = stat?.data?.tickets

  return (
    <DashboardLayout title="Tickets" type="admin">
      <div className="rounded-2xl bg-white p-4 md:p-6">
        <p className="font-Bai text-2xl font-medium text-black">Overview</p>

        <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <DashboardBox
            text={"In Progress"}
            icon={<TicketIcon stroke={"#5010B3"} />}
            value={
              ticketsStats ? (
                ticketsStats?.in_progress

              ) : (
                <Skeleton variant="text" width={45} />
              )
            }
          />
          <DashboardBox
            text={"New Ticket"}
            icon={<TicketIcon stroke={"#E98100"} />}
            value={
              ticketsStats ? (
                ticketsStats?.new
              ) : (
                <Skeleton variant="text" width={45} />
              )
            }
          />
          <DashboardBox
            text={"Resolved Ticket"}
            icon={<TicketIcon stroke={"#039855"} />}
            value={
              ticketsStats ? (
                ticketsStats?.resolved
              ) : (
                <Skeleton variant="text" width={45} />
              )
            }
          />
        </div>
      </div>

      <div className="mt-8 rounded-2xl bg-white p-4 md:p-6">
        <TabContext value={activeTab}>
          <TabList
            variant="scrollable"
            value={activeTab}
            className="mb-4 w-fit rounded-[16px] border border-grey_60 bg-grey_80 p-2"
            onChange={(_, newValue) => {
              navigate(`?tab=${newValue}`);
              setActiveTab(newValue);
            }}
            sx={{
              fontWeight: 600,
              ...CustomTabStyle,
              textTransform: "unset",
            }}
            aria-label="basic tabs example"
          >
            <Tab label="In Progress" value="in-progress" />
            <Tab
              label={
                <div className="flex items-center justify-center gap-x-3">
                  <span>New Tickets</span>
                  {ticketsStats && (
                    <span className="flex h-6 w-6 items-center justify-center rounded-full bg-primary text-sm text-white">
                      {
                        ticketsStats?.new
                      }
                    </span>
                  )}
                </div>
              }
              value="new"
            />
            <Tab label="Resolved Tickets" value="resolved" />
          </TabList>
          <TabPanel value="in-progress">
            <TicketsTab
              data={
                allTickets.filter(ticket =>
                  ticket.status.toLowerCase().includes("progress"),
                ) || []
              }
              isLoading={isLoading}
              isError={isError}
              error={error}
              action={() => setPage(page + 1)}
            />
          </TabPanel>
          <TabPanel value="new">
            <TicketsTab
              data={
                allTickets.filter(
                  ticket =>
                    ticket.status.toLowerCase().includes("new") ||
                    ticket?.status?.toLowerCase().includes("open"),
                ) || []
              }
              isLoading={isLoading}
              isError={isError}
              error={error}
              action={() => setPage(page + 1)}
            />
          </TabPanel>
          <TabPanel value="resolved">
            <TicketsTab
              data={allTickets
                .filter(ticket => ticket.status.toLowerCase().includes("resolved"))
                .map(ticket => ({
                  ...ticket,
                  updated_at: new Date(ticket.updated_at).toLocaleString(),
                }))
                .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)) || []}
              isLoading={isLoading}
              isError={isError}
              error={error}
              action={() => setPage(page + 1)}
            />
          </TabPanel>
        </TabContext>
      </div>
    </DashboardLayout>
  );
}

export default AdminTickets;
