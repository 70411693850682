import {Button} from "@mui/material";

const RegNotice = ({onClick, userType}) => {
  return (
    <>
      <p className="my-6 text-center font-Bai text-2xl font-bold text-grey_10">
        You're registered as a {userType}
      </p>
      <Button
        variant="contained"
        onClick={onClick}
        color="primary"
        sx={{display: "block", width: "218px", height: "60px", mx: "auto"}}
      >
        Complete Profile Setup
      </Button>
    </>
  );
};

export default RegNotice;
