import React from "react";
import { useGetAllServiceCategoriesQuery } from "services/generalApi";
import FilterDropDown from "components/mui-components/DropDownWrapper/FilterDropDown";

const Category = ({ choice, setChoice, setSelectedCt = () => { } }) => {
  const { data: categoryData, isLoading } = useGetAllServiceCategoriesQuery();

  return (
    <FilterDropDown
      name="category"
      bg='bg-[#F2F4F7]'
      choice={choice}
      isSearch
      onSelect={item =>
        setSelectedCt(categoryData?.data?.find(el => el?.name === item) || null)
      }
      isLoading={isLoading}
      setChoice={setChoice}
      selectOption={[
        { label: "All Categories", value: "" },
        ...(categoryData?.data?.map(el => el?.name) || []),
      ]}
    />
  );
};

export default Category;
