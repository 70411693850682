const BookmarkCheckIcon = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.99967 7L7.33301 8.33333L10.333 5.33333M12.6663 14V5.2C12.6663 4.0799 12.6663 3.51984 12.4484 3.09202C12.2566 2.71569 11.9506 2.40973 11.5743 2.21799C11.1465 2 10.5864 2 9.46634 2H6.53301C5.4129 2 4.85285 2 4.42503 2.21799C4.0487 2.40973 3.74274 2.71569 3.55099 3.09202C3.33301 3.51984 3.33301 4.0799 3.33301 5.2V14L7.99967 11.3333L12.6663 14Z"
        stroke={props.stroke || "#6E726E"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default BookmarkCheckIcon;
