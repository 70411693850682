import React from "react";

const Request = ({ fill }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill={fill} />
      <path
        d="M14.9991 14.0015C15.1166 13.6676 15.3484 13.386 15.6536 13.2066C15.9588 13.0273 16.3176 12.9617 16.6664 13.0216C17.0153 13.0814 17.3318 13.2628 17.5597 13.5336C17.7877 13.8044 17.9125 14.1471 17.9119 14.5011C17.9119 15.5004 16.413 16 16.413 16M16.4324 18H16.439M16.3324 21.3333C19.462 21.3333 21.9991 18.7963 21.9991 15.6667C21.9991 12.5371 19.462 10 16.3324 10C13.2028 10 10.6658 12.5371 10.6658 15.6667C10.6658 16.3 10.7697 16.9091 10.9613 17.4777C11.0335 17.6917 11.0695 17.7987 11.0761 17.881C11.0825 17.9621 11.0776 18.019 11.0575 18.098C11.0372 18.1779 10.9923 18.261 10.9025 18.4272L9.81206 20.4456C9.65652 20.7335 9.57875 20.8774 9.59615 20.9885C9.61131 21.0853 9.66826 21.1705 9.75188 21.2215C9.84788 21.2801 10.0106 21.2632 10.3361 21.2296L13.7501 20.8767C13.8535 20.866 13.9052 20.8606 13.9523 20.8624C13.9987 20.8642 14.0314 20.8686 14.0766 20.879C14.1225 20.8896 14.1803 20.9118 14.2959 20.9564C14.9279 21.1999 15.6146 21.3333 16.3324 21.3333Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Request;
