import { Chip } from "@mui/material";

const About = () => {
  return (
    <>
      <main className="mb-20 md:mb-64">
        <div className="px-4 sm:px-6 flex flex-col items-center">
          <Chip
            label='About Us'
            color='secondaryInverse'
          />
          <h1 className="mt-3 text-center leading-[100%] font-Bai text-[2rem] font-bold max-w-[754px] text-black_text2 md:text-[3rem]">
            Powering a new generation of work life balance in Nigeria
          </h1>

          <p className="font-Mulish text-center max-w-[593px] font-medium text-base mt-3 md;mt-6 text-grey_20">
            We are the technology company shaping the on demand labour economy in Nigeria. Our mission is to transform the way people work and earn their living.
          </p>
        </div>

        <section className="px-4 sm:px-6 mt-16 md:px-12 lg:px-32 max-w-[75rem] mx-auto">
          <div className="md:grid md:grid-cols-3 hidden gap-6 md:items-center overflow-x-clip">
            <img src="/images/about-hero-1.png" alt="about us" className="w-full" />
            <div className="flex flexrow flex-col gap-6 max-w-fll">
              <img src="/images/about-hero-2.png" alt="about us" className="w-full" />
              <img src="/images/about-hero-3.png" alt="about us" className="w-full" />
            </div>
            <img src="/images/about-hero-4.png" alt="about us" className="w-full" />
          </div>

          <div className="md:hidden place-items-ed grid-cols-2 grid gap-2.5">
            <img src="/images/about-hero-1.png" alt="about us" className="w-full !rounded-16" />
            <img src="/images/about-hero-2.png" alt="about us" className="w-full !rounded-16 place-self-end h-[80%]" />
            <img src="/images/about-hero-3.png" alt="about us" className="w-full !rounded-16 place-self-start objectcontain h-[80%]" />
            <img src="/images/about-hero-4.png" alt="about us" className="w-full !rounded-16 object-cover min-h-full" />
          </div>

          <div className="md:flex justify-center my-14 md:mt-[5.44rem] md:mb-[7.625rem]">
            <p className="font-Bai max-w-[651px]  font-medium text-xl md:text-2xl text-grey_10">
              Truelocal is an online and mobile marketplace that matches local service providers with demand, allowing consumers to find help with everyday tasks, including cleaning, app development, delivery service, auto mechanic and more
            </p>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between md:items-center sm:rounded-2xl gap-10 md:rounded-[3rem] sm:bg-white sm:p-6 md:p-9 lg:p-12">
            <div className='flex-1 lg:pl-6'>
              <p className="font-Bai font-bold text-3xl md:text-[2.5rem] text-[#222]">
                Our mission
              </p>

              <p className="font-Bai mt-3 md:mt-10 max-w-[485px] font-medium text-lg md:text-xl text-grey_10">
                Our mission is simple, to strengthen tradespeople to work and earn a living wage doing what they love.
              </p>
            </div>

            <div className="relative flex-1">
              <img src="/images/about-hero-1.png" alt="about us" className="w-full h-full" />
              <img src="/images/our-mission-1.png" alt="our-mission" className="absolute top-10 -left-16 md:-left-10" />
              <img src="/images/our-mission-2.png" alt="our-mission" className="absolute -bottom-16 md:-bottom-8 -left-28 md:-left-36" />
              <img src="/images/our-mission-3.png" alt="our-mission" className="absolute bottom-6 md:bottom-0 -right-6 md:-right-16" />

              {/* <div className="md:hidden block flx flex-col gap-2">
                <img src="/images/our-mission-1.png" alt="our-mission" className="absolute top-10 -left-12 h6" />
                <img src="/images/our-mission-2.png" alt="our-mission" className="absolute top-24 -left-16 h-48" />
                <img src="/images/our-mission-3.png" alt="our-mission" className="absolute top-56 -left-8 h-48 " />

              </div> */}
            </div>
          </div>
        </section>

        <section className="mt-20 md:mt-[10.5rem] mb">
          {/* <p className=" px-4 sm:px-6 text-center font-Bai mb-7 md:mb-14 font-bold text-3xl md:text-[2.5rem] text-[#222]">
            We have a wide range of categories
          </p> */}

          {/* <div className="max-w[75rem] mlauto noScrollBar pl-4 sm:pl-6 md:pl-12 lg:pl-[10%] flex overflow-x-auto gap-6 items-center pl[4vw]">
            <EachCategory />
            <EachCategory />
            <EachCategory />
            <EachCategory />
            <EachCategory />
            <EachCategory />
            <EachCategory />
            <EachCategory />
          </div> */}
        </section>
      </main>
      {/* <Question /> */}
    </>
  );
};

export default About;



// const EachCategory = () => {
//   return (
//     <div className='min-w-[16.63rem]'>
//       <img
//         src='/images/category-img.png'
//         alt='category'
//       />
//       <p className="font-Bai mt-4 font-bold text-xl md:text-2xl text-[#222]">
//         IT Services
//       </p>
//     </div>
//   )
// }

