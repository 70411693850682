function MoneyTickIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 20.3327C13.6134 20.3327 11.6667 18.386 11.6667 15.9993C11.6667 13.6127 13.6134 11.666 16 11.666C18.3867 11.666 20.3334 13.6127 20.3334 15.9993C20.3334 18.386 18.3867 20.3327 16 20.3327ZM16 13.666C14.72 13.666 13.6667 14.7193 13.6667 15.9993C13.6667 17.2793 14.72 18.3327 16 18.3327C17.28 18.3327 18.3334 17.2793 18.3334 15.9993C18.3334 14.7193 17.28 13.666 16 13.666Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M24.6667 20.3327C24.12 20.3327 23.6667 19.8793 23.6667 19.3327V12.666C23.6667 12.1193 24.12 11.666 24.6667 11.666C25.2134 11.666 25.6667 12.1193 25.6667 12.666V19.3327C25.6667 19.8793 25.2134 20.3327 24.6667 20.3327Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M6.66665 30.3327C4.45331 30.3327 2.37332 29.1594 1.25332 27.2527C0.653316 26.2927 0.333313 25.1593 0.333313 23.9993C0.333313 20.506 3.17331 17.666 6.66665 17.666C10.16 17.666 13 20.506 13 23.9993C13 25.1593 12.68 26.2927 12.08 27.266C10.96 29.1594 8.87998 30.3327 6.66665 30.3327ZM6.66665 19.666C4.27998 19.666 2.33331 21.6127 2.33331 23.9993C2.33331 24.786 2.54665 25.5593 2.95998 26.226C3.73331 27.5327 5.15998 28.3327 6.66665 28.3327C8.17331 28.3327 9.59998 27.5327 10.3733 26.2393C10.7866 25.5593 11 24.7993 11 23.9993C11 21.6127 9.05331 19.666 6.66665 19.666Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M5.9065 26.3194C5.65316 26.3194 5.39983 26.2261 5.19983 26.0261L3.87984 24.7061C3.49318 24.3195 3.49318 23.6794 3.87984 23.2927C4.26651 22.9061 4.90651 22.9061 5.29317 23.2927L5.93319 23.9328L8.06649 21.9594C8.46649 21.5861 9.10649 21.6128 9.47982 22.0128C9.85315 22.4128 9.82652 23.0528 9.42652 23.4261L6.58651 26.0527C6.38651 26.2261 6.1465 26.3194 5.9065 26.3194Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M22.6667 27.6673H11.3334C10.7867 27.6673 10.3334 27.214 10.3334 26.6673C10.3334 26.1206 10.7867 25.6673 11.3334 25.6673H22.6667C26.48 25.6673 28.3334 23.814 28.3334 20.0007V12.0007C28.3334 8.18732 26.48 6.33398 22.6667 6.33398H9.33335C5.52002 6.33398 3.66669 8.18732 3.66669 12.0007V20.4006C3.66669 20.9473 3.21335 21.4006 2.66669 21.4006C2.12002 21.4006 1.66669 20.9473 1.66669 20.4006V12.0007C1.66669 7.13398 4.46669 4.33398 9.33335 4.33398H22.6667C27.5334 4.33398 30.3334 7.13398 30.3334 12.0007V20.0007C30.3334 24.8673 27.5334 27.6673 22.6667 27.6673Z"
        fill={props.color || "#8937CE"}
      />
    </svg>
  );
}

export default MoneyTickIcon;
