import {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import JobOverView from "./Components/JobOverView";
// import JobGallery from "./Components/JobGallery";
import JobTimeAndLocation from "./Components/JobTimeAndLocation";
// import JobDetail from "./Components/JobDetail";
import PublishJob from "./Components/PublishJob";
import {Button, LinearProgress} from "@mui/material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import {
  useGetSingleJobDetailQuery,
  usePostJobMutation,
  useUpdateJobMutation,
} from "services/user/clientGeneralApi";
import {handleError} from "utils";
import {useGetAllServiceCategoriesQuery} from "services/generalApi";
import {showToast} from "redux/store.hooks";
import LoadingBtn from "components/mui-components/LoadingButton";
// import BackBtn from "components/BackBtn";
import {useGetOnboardingStatus} from "hooks/getOnboardingStatus";
// import ErrorMsg from "components/mui-components/ErrorMsg";
import MyModal from "components/mui-components/Modal";
import LogoIcon from "components/Vectors/LogoIcon";
// import JobTimeAndLocation from "./JobTimeAndLocation";

const PostAJob = ({openModal, closeModal}) => {
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState("creating");
  const [postJob, {isLoading}] = usePostJobMutation();
  const [updateJob, {isLoading: isUpdating}] = useUpdateJobMutation();
  const {data: categories} = useGetAllServiceCategoriesQuery();
  const [searchParams] = useSearchParams();
  const [presentStep, setPresentStep] = useState(1);
  // const [locationType, setLocationType] = useState("Physical");
  const [stateCity, setStateCity] = useState({state: "", city: ""});
  const [isDrafting, setIsDrafting] = useState(false);
  const isEditing = searchParams.get("edit") === "true";
  const id = searchParams.get("id");
  const {data: jobData} = useGetSingleJobDetailQuery(id);
  const prevData = jobData?.data;

  const defaults = useMemo(
    () => ({
      job_title: prevData?.job_title,
      description: prevData?.description,
      expertise_id: prevData?.expertise?.id,
      category_id: prevData?.category?.id,
      location_type: prevData?.location_type || "Physical",
      video_choice:
        prevData?.videos.length > 0
          ? prevData?.videos[0]?.video_type === "embeded_video"
            ? "Embed Video"
            : "Browse Files"
          : "",
      video_file:
        prevData?.videos.length > 0
          ? prevData?.videos[0]?.video_type === "uploaded_video"
            ? prevData?.videos[0]?.video_url
            : ""
          : "",
      unembed_vid_link:
        prevData?.videos.length > 0
          ? prevData?.videos[0]?.video_type === "embeded_video"
            ? prevData?.videos[0]?.video_url
            : ""
          : "",
      duration: prevData?.duration,
      duration_type: prevData?.duration_type,
      state_id: prevData?.state ? prevData?.state?.id : "",
      city_id: prevData?.state ? prevData?.city?.id : "",
      address: prevData?.address,
      features: prevData?.task_features.map(task => ({
        feature: task?.task_feature,
      })),
      no_of_provider: prevData?.number_of_expected_request,
      price: prevData?.price_option,
      price_amount: prevData?.price,
      image: prevData?.images?.map(el => el?.image_url),
    }),
    [prevData],
  );
  const methods = useForm({
    mode: "all",
    reValidateMode: "all",
    defaultValues: isEditing ? defaults : {features: [{feature: ""}]},
    shouldUnregister: false,
  });

  const {watch, setValue} = methods;

  const locationType = watch("location_type");

  // update defaultValue if page is reloaded
  useEffect(() => {
    if (defaults) {
      Object.entries(defaults).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [setValue, defaults]);

  useEffect(() => {
    if (searchParams.get("q")) setValue("job_title", searchParams.get("q"));
  }, [searchParams, setValue]);

  // const watchCategory = watch("category_id");
  // const watchExp = watch("expertise_id");

  // const categoryID = categories?.data.find(
  //   val => String(val?.id) === String(watchCategory),
  // // )?.id;
  // const categoryID = watchCategory

  // const category = categories?.data.find(
  //   category => category?.id === categoryID,
  // );

  // useEffect(() => {
  //   setLocationType(
  //     category?.expertises?.find(({ id }) => String(id) === String(watchExp))
  //       ?.location_type,
  //   );
  // }, [category, watchExp]);
  // console.log(categories, watchCategory, locationType, watchExp);

  const onSubmit = async data => {
    const formData = new FormData();
    formData.append("job_title", data?.job_title);
    formData.append("category_id", data?.category_id);
    formData.append("expertise_id", data?.expertise_id);
    formData.append("description", data?.description);
    if (data?.image?.length > 0) {
      for (let i = 0; i < data?.image.length; i++) {
        if (typeof data?.image[i] === "string") {
          continue;
        } else {
          formData.append(`images[${i}]`, data?.image[i]);
        }
      }
    }
    if (data?.video_link) {
      formData.append("embede_videos[1]", data?.video_link);
    }
    if (data?.video_file) {
      if (data?.video_file instanceof File) {
        formData.append("uploade_videos[0]", data?.video_file);
      }
    }
    formData.append("location_type", isEditing ? locationType : data?.location);
    if (locationType === "Physical") {
      formData.append("city_id", data?.city_id);
      formData.append("state_id", data?.state_id);
      formData.append("address", data?.address);
    }
    formData.append("duration", data?.duration);
    formData.append("duration_type", data?.duration_type);
    if (data?.features?.length > 0) {
      for (let i = 0; i < data?.features.length; i++) {
        formData.append(`feature_tasks[${i}]`, data?.features[i].feature);
      }
    }
    formData.append("price_option", data?.price);
    if (data?.price?.toLowerCase() === "enter_amount") {
      formData.append(`price`, data?.price_amount);
    }
    formData.append("number_of_expected_request", data?.no_of_provider);
    formData.append(
      "redirect_url",
      `${window.location.origin}/service-provider/marketplace`,
    );
    setLoadingState("creating");
    if (isDrafting) formData.append("job_status", "Drafted");
    else if (isEditing) formData.append("job_status", "Active");
    for (const [key, value] of formData) {
      console.log(key, value);
    }
    try {
      let res = isEditing
        ? await updateJob({id, body: formData}).unwrap()
        : await postJob(formData).unwrap();
      showToast(isDrafting ? "Job successfully saved as draft" : res?.message);
      navigate(
        `/client/jobs/${res?.data?.id}/${res?.data?.status.toLowerCase()}`,
      );
      window.scrollTo({top: 0, behavior: "smooth"});
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingState("");
    }
  };

  const handleDraftClick = () => {
    setIsDrafting(true);
    setLoadingState("drafting");
    methods.handleSubmit(onSubmit);
  };

  const goNext = async num => {
    await methods.trigger();
    if (methods.formState.isValid) {
      setPresentStep(presentStep + num);
      window.scrollTo({top: 0, behavior: "smooth"});
    }
  };

  useEffect(() => {
    if (prevData) {
      setStateCity({state: prevData?.state?.name, city: prevData?.city?.name});
    }
  }, [prevData]);

  const stepComponents = {
    1: <JobOverView locationType={locationType} />,
    2: (
      <JobTimeAndLocation
        setStateCity={setStateCity}
        locationType={locationType}
        isEditing={isEditing}
      />
    ),
    // 3: <JobDetail features={prevData && prevData?.task_features} />,
    // 4: <JobGallery skip={() => setPresentStep(prev => prev + 1)} />,
    3: (
      <PublishJob
        stateCity={stateCity}
        locationType={locationType}
        isEditing={isEditing}
      />
    ),
  };

  const {isOnboardingComplete, status} = useGetOnboardingStatus("client");

  const skippedSteps = Object.keys(status || {}).filter(
    step => status[step] === false,
  );
  const objMap = {
    BasicInformation: "Basic Information",
    ProfessionalInformation: "Professional Information",
    Kyc: "KYC",
    BankAccount: "Account details",
    CacCertificate: "CAC certificate",
  };

  // if (!isOnboardingComplete)
  //   return (
  //     <ErrorMsg
  //       error={{
  //         data: {
  //           message: `You're yet to get verified because your ${skippedSteps.map(
  //             (step, index) => `${objMap[step]}
  // ${index === skippedSteps.length - 2 ? " and " : ", "}`,
  //           )} is still pending completion`,
  //         },
  //       }}
  //     />
  //   );
  return (
    <MyModal
      openModal={openModal}
      closeModal={closeModal}
      maxWidth={1600}
      postJobModal={true}
      hideScroll
      className="!min-h-screen !min-w-full !rounded-none"
    >
      {/* <BackBtn
        to={isEditing ? null : "/client/jobs"}
        text={isEditing ? "Job Details" : "My jobs"}
      /> */}

      <div className="!min-wfull !-mx-6 mb-6 flex items-center justify-center border-b border-[#DCDCE0] pb-4">
        <LogoIcon />
      </div>

      <button
        className="flex w-fit items-center gap-2 !rounded-10 !border border-[#C9C9CF] px-4 py-3 text-sm font-semibold text-grey-10 "
        onClick={closeModal}
      >
        <ClearIcon sx={{fontSize: "18px"}} />
        Close
      </button>
      <div className="flex flex-col md:flex-row">
        <div className="mt-4 flex w-full flex-col gap-5 p-2 md:mt-8 md:justify-start  md:p-0">
          <h1 className="p-3 font-Bai text-2xl font-bold md:ml-9 md:text-[2rem]">
            {isEditing ? "Edit Task" : "Post a Task"}
          </h1>
          <div className="flex flex-col gap-y-5">
            <div className="relative mb-4 w-full md:hidden">
              <LinearProgress
                className="flex-grow !bg-[#efefef] md:flex-grow-0"
                variant="determinate"
                value={(presentStep / steps.length) * 100}
                sx={{
                  borderRadius: 12,
                  height: 6,
                  maxHeight: 6,
                  mb: 2,
                }}
              />{" "}
              <p className="flex items-start">
                <span className="mr-1 text-3xl font-bold text-grey">
                  {presentStep}{" "}
                </span>
                <span className="text-base font-normal text-grey_40">
                  / {steps.length}
                </span>
              </p>
            </div>
            {/* desktop  */}
            <div
              className="hidden h-fit min-w-max flex-col
             flex-wrap justify-between rounded-2xl bg-white
           p-8 md:flex md:gap-6 md:px-12"
            >
              {steps.map(value => (
                <button
                  key={value.id}
                  type="button"
                  disabled={presentStep < value.id}
                  className="flex items-center gap-4"
                >
                  {/* <span
                    className={`flex h-[42px] w-[42px] items-center justify-center rounded-16
                 text-lg font-medium text-white
                 ${
                   presentStep === value.id
                     ? "bg-primary text-grey_60"
                     : presentStep > value.id
                     ? "bg-grey_20"
                     : "bg-grey_60 text-grey_30"
                 }`}
                  >
                    {value.id}
                </span>*/}
                  <span
                    className={`font-Mulish text-lg font-medium text-grey_40 ${
                      presentStep >= value.id && "!text-black_text"
                    } `}
                  >
                    {value.title}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>
        {/* Form  */}
        <div className="w-full">
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
              className="relative w-full"
            >
              <div className="mdflex-row flex w-full flex-col gap-8">
                <div className="flex flex-1 flex-col gap-6 rounded-24 bg-white pb-6 md:max-w-[500px] md:pb-[40px]">
                  {stepComponents[presentStep]}

                  <div className="ml-3 flex gap-x-4 md:ml-8">
                    {presentStep !== 1 && (
                      <Button
                        onClick={() => {
                          window.scrollTo({top: 0, behavior: "smooth"});
                          setPresentStep(prev => prev - 1);
                        }}
                        startIcon={<ArrowBackOutlinedIcon />}
                        type="button"
                        variant="outlined"
                        fullWidth
                      >
                        Previous
                      </Button>
                    )}

                    {presentStep < steps.length ? (
                      <Button
                        endIcon={<ArrowForwardOutlinedIcon />}
                        type="button"
                        onClick={() => goNext(1)}
                        variant="contained"
                        sx={{minWidth: "130px"}}
                        fullWidth
                      >
                        Next
                      </Button>
                    ) : (
                      <LoadingBtn
                        loading={
                          isLoading ||
                          (isUpdating && loadingState === "creating")
                        }
                        type="submit"
                        sx={{minWidth: "130px"}}
                        fullWidth
                      >
                        {isEditing ? "Update" : "Publish"}
                      </LoadingBtn>
                    )}
                  </div>
                </div>
                <div className="flex flex-1 flex-col items-start justify-start gap-3">
                  {isEditing && presentStep < steps.length && (
                    <LoadingBtn
                      loading={
                        isLoading || (isUpdating && loadingState === "creating")
                      }
                      type="submit"
                      sx={{width: "130px"}}
                    >
                      Save
                    </LoadingBtn>
                  )}
                  {presentStep === steps.length && (
                    <LoadingBtn
                      type="submit"
                      onClick={handleDraftClick}
                      variant="text"
                      loading={
                        isLoading || (isUpdating && loadingState === "drafting")
                      }
                      fullWidth
                      color="primary"
                      sx={{
                        textTransform: "none",
                        position: "absolute",
                        fontWeight: 700,
                        top: {xs: 35, md: 75},
                        left: {xs: 0, md: 30},
                        display: isEditing ? "none" : "",
                      }}
                      iconColor="#E98100"
                    >
                      Save as Draft
                    </LoadingBtn>
                  )}
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </MyModal>
  );
};

export default PostAJob;
const steps = [
  {
    title: "Overview",
    id: 1,
  },
  {
    title: "Duration & Location",
    id: 2,
  },
  // {
  //   title: "Details",
  //   id: 3,
  // },
  // {
  //   title: "Gallery",
  //   id: 4,
  // },
  {
    title: "Preview and Publish",
    id: 3,
  },
];
