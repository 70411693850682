import {toCurrency} from "utils";

function Expertise({
  item = {
    expertise: {name: "Car Consultancy"},
    min_price: 2000,
    max_price: 4000,
  },
}) {
  return (
    <div className="rounded-[16px] border border-grey_60 bg-white p-4">
      <div className="mb-3 flex items-center justify-between">
        <p className="text-base font-semibold text-grey">
          Car {item?.expertise.name}
        </p>
        <p className="text-xs font-normal text-[#5010B3]">
          {item?.expertise?.location_type}
        </p>
      </div>

      <div className="flex items-center gap-3">
        <p className="text-sm font-normal text-grey_30">Price Range</p>

        <p className="text-base font-semibold text-grey">
          {toCurrency(item?.min_price)} &nbsp;{" "}
          <span className="text-sm font-normal text-grey_30">to</span> &nbsp;{" "}
          {toCurrency(item?.max_price)}
        </p>
      </div>
    </div>
  );
}

export default Expertise;
