import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Chip } from "@mui/material";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import JobDetailSingleOffer from "./JobDetailSingleOffer";
import MakeRequestModal from "./MakeRequestModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ImageSlideShow from "components/mui-components/Modal/ImageSlideshow";
import {
  useFollowJobMutation,
  useGetAJobDetailQuery,
  useGetAllSPServicesQuery,
  useUnFollowJobMutation,

} from "services/user/providerGeneralApi";
import CircularLoader from "components/Loader/CircularLoader";
import CategoryInfo from "components/CategoryCard";
import moment from "moment";
import { useGetSingleJobQuery } from "services/generalApi";
import { useGetUser } from "hooks/getUserHook";
import ErrorMsg, { PageNotFound } from "components/mui-components/ErrorMsg";
import { formatCurrency, handleError, moneyFormatter } from "utils";
import { showToast } from "redux/store.hooks";
import LoadingBtn from "components/mui-components/LoadingButton";
import CurrentProviderOffer from "./Widgets/CurrentProviderOffer";
import Video from "components/mui-components/Modal/Video";
import EmptyResponse from "components/mui-components/EmptyResponse";
import JobIcon from "components/Vectors/JobIcon";
import AlertIcon from "components/Vectors/AlertIcon";
import {
  useGetMinTokenRequiredQuery,
  useMarkOfferChatAsReadMutation,
} from "services/user/userRegApi";
import BackBtn from "components/BackBtn";
import { BootstrapTooltip } from "components/TicketCards";
import UserProfileCard from "pages/Admin/Dashboard/Engagements/Widgets/UserProfileCard";
import { JobsStatusColor } from "utils/constants";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import NoServiceModal from "../Modals/NoServiceModal";
import Title from "components/Title";
import UnApprovedKycModal from "pages/Clients/Dashboard/UnApprovedKycModal";

const JobDetail = () => {
  const [isFav, setIsFav] = useState(false);
  const [showKycModal, setShowKycModal] = useState(false);
  const [follow, { isLoading: isFollowing }] = useFollowJobMutation();
  const [unfollow, { isLoading: isUnfollowing }] = useUnFollowJobMutation();
  const [isRequestOpen, setIsRequestOpen] = useState(false);
  const [showNoserviceModal, setShowNoServiceModal] = useState(false);
  const { isLoggedIn, user } = useGetUser();
  const { data: services } = useGetAllSPServicesQuery();
  const navigate = useNavigate();
  const [info, setInfo] = useState("");
  const { id } = useParams();
  const [markOfferMsgsAsRead] = useMarkOfferChatAsReadMutation();
  const isProvider =
    isLoggedIn && user?.user_type?.toLowerCase() === "service provider";

  const { data: tokenData } = useGetMinTokenRequiredQuery("", {
    skip:
      isLoggedIn === false ||
      user?.user_type?.toLowerCase() !== "service provider",
    refetchOnMountOrArgChange: true,
  });
  const reqToken = Number(tokenData?.data?.value || "0");
  const {
    data: singleJobData,
    isFetching: isJobLoading,
    error: jobError,
    isSuccess,
    refetch,
  } = useGetSingleJobQuery(id, { refetchOnMountOrArgChange: true });

  const {
    data: singleJob,
    isFetching: isSPJobLoading,
    error: spJobError,
    isSuccess: isSpSuccess,
    refetch: spRefetch,
  } = useGetAJobDetailQuery(id, { refetchOnMountOrArgChange: true });

  const content = isProvider ? singleJob?.data : singleJobData?.data;
  // console.log({ content })
  useEffect(() => {
    if (content?.offers_count === Number(content?.number_of_expected_request)) {
      setInfo("filled");
    }
    const userID = user?.id;
    const offerIDs = content?.offers?.map(offer => offer?.user_id);
    if (offerIDs?.includes(userID)) {
      setInfo("submitted");
    }
    const userOffer = content?.offers?.find(offer => offer?.user_id === userID);
    if (userOffer?.status === "Withdrawn") {
      setInfo("cancelled");
    }
  }, [content, reqToken, user]);

  useEffect(() => {
    setIsFav(content?.is_following);
  }, [content?.is_following]);

  const topDetails = [
    {
      title: "Date Posted",
      text: moment(content?.created_at).format("DD MMM YYYY"),
      icon: <CalendarTodayIcon />,
    },
    {
      title: "Category",
      text: content?.category?.name,
      icon: <GridViewIcon />,
      info: content?.expertise?.name
    },
    {
      title: "Delivery time",
      text: `${content?.duration} ${content?.duration === 1
        ? content?.duration_type.slice(0, -1)
        : content?.duration_type
        }`,
      icon: <AccessTimeOutlinedIcon />,
    },
    {
      title: "Location",
      text:
        content?.location_type === "Physical"
          ? `${content?.city?.name}, ${content?.state?.name}`
          : "Virtual",
      icon: <FmdGoodOutlinedIcon />,
    },
  ];

  const infoMessage = {
    submitted: "You’ve sent a request for this job",
    filled: "Number of Applicants is full",
    cancelled: "You can’t send request at this time until the next offer.",
  };

  const handleFollow = () => {
    follow({ client_job_id: id })
      .unwrap()
      .then(res => {
        setIsFav(true);
        showToast(res?.message);
      })
      .catch(err => {
        handleError(err);
      });
  };
  const handleUnfollow = () => {
    unfollow(id)
      .unwrap()
      .then(res => {
        setIsFav(false);
        showToast(res?.message, "success");
      })
      .catch(err => {
        handleError(err);
      });
  };

  const [itemCount, setItemCount] = useState(4);
  const myOffer = content?.offers?.find(
    offer => offer?.user?.id === user?.id && !user?.admin_permissions,
  );
  useEffect(() => {
    if (isProvider) markOfferMsgsAsRead(myOffer?.id).unwrap();
  }, [myOffer, isProvider, markOfferMsgsAsRead]);

  const isDisabled =
    info === "cancelled" ||
    info === "filled" ||
    (myOffer && myOffer?.status?.toLowerCase() !== "pending");

  if (isJobLoading || isSPJobLoading) return <CircularLoader />;
  if (jobError || spJobError) <ErrorMsg error={jobError || spJobError} />;
  if (content === null && (isSuccess || isSpSuccess)) return <PageNotFound />;
  return (
    <>
      <BackBtn
        text={isProvider ? "Marketplace" : "All Jobs"}
        className={isLoggedIn ? "" : "!mt-16"}
      />
      <Title
        titleProp={`${content?.category?.name} ${isProvider ? "| Marketplace" : "| Jobs"
          }`}
      />
      <section className="mt-8 rounded-20 bg-white p-6">
        <div className="flex flex-col items-start justify-between gap-y-3 md:flex-row">
          <div>
            <h1 className="basis-2/5 font-Bai text-2xl font-bold text-grey_20 md:text-4xl">
              {content?.job_title}
            </h1>
            <div className="mt-2 flex flex-wrap items-center gap-3">
              <Chip
                label={content?.status?.replace("Active", "Open")}
                sx={{ borderRadius: "8px" }}
                size="small"
                color={JobsStatusColor[content?.status?.toLowerCase()]}
              />
              <Chip
                label={`Expected Timeline: ${content?.duration} ${content?.duration === 1
                  ? content?.duration_type.slice(0, -1)
                  : content?.duration_type
                  } `}
                avatar={<VisibilityOutlinedIcon />}
                size="small"
                sx={{ borderRadius: "8px" }}
              />
              <Chip
                label={`${moneyFormatter(content?.views_count)}  ${content?.views_count === 1 ? "View" : "Views"
                  } `}
                avatar={<VisibilityOutlinedIcon />}
                size="small"
                sx={{ borderRadius: "8px" }}
              />
              <Chip
                label={`${moneyFormatter(content?.saves_count)} ${content?.saves_count === 1 ? "Follower" : "Followers"
                  }`}
                avatar={<BookmarkBorderRoundedIcon />}
                size="small"
                sx={{ borderRadius: "8px" }}
              />
            </div>
          </div>
          {(user?.user_type?.toLowerCase() === "service provider" ||
            !isLoggedIn) &&
            user?.id !== content?.creator?.id && (
              <div>
                <div className="fixed bottom-0 left-0 z-[60] flex w-screen items-center gap-x-3 bg-white p-4 md:static md:w-auto md:bg-transparent md:p-0 md:pb-2">
                  <BootstrapTooltip
                    title={`${isFav ? "Unfollow" : "Follow"}`}
                    placement="top"
                  >
                    <div>
                      <LoadingBtn
                        variant="outlined"
                        onClick={() =>
                          isLoggedIn
                            ? isFav
                              ? handleUnfollow()
                              : handleFollow()
                            : navigate("/user/login")
                        }
                        sx={{
                          width: "20px !important",
                          height: "50px !important",
                        }}
                        loading={isFollowing || isUnfollowing}
                        iconColor="#E98100"
                      >
                        {isFav ? (
                          <BookmarkRoundedIcon
                            fontSize="small"
                            color="primary"
                          />
                        ) : (
                          <BookmarkBorderOutlinedIcon
                            fontSize="small"
                            color="primary"
                          />
                        )}
                      </LoadingBtn>
                    </div>
                  </BootstrapTooltip>
                  <Button
                    disabled={isDisabled}
                    color={info === "submitted" ? "error" : "primary"}
                    onClick={() => {
                      if (!isDisabled) {
                        if (isLoggedIn) {
                          if (user?.kyc?.status === 'Approved') {
                            if (services?.data?.length > 0) {
                              setIsRequestOpen(true)
                            } else setShowNoServiceModal(true)
                          } else setShowKycModal(true)
                        } else navigate("/user/login")
                      }
                    }}
                    variant="contained"
                    className="flex-grow"
                  >
                    {info === "submitted"
                      ? "Withdraw Request"
                      : "Make an offer"}
                  </Button>
                </div>
                {info && info !== "no-token" && (
                  <div className="rounded-xl bg-[#F9FAFB] py-2 px-4">
                    <InfoOutlinedIcon sx={{ color: "#3B3D3B" }} />
                    <span className="ml-2 text-xs text-[#3B3D3B] md:ml-3 md:text-sm">
                      {infoMessage[info]}
                    </span>
                  </div>
                )}
              </div>
            )}
        </div>

        <div className="mt-4 grid grid-cols-2 gap-4 md:gap-8 lg:grid-cols-4">
          {topDetails?.map(({ title, text, icon, info }, index) => (
            <CategoryInfo
              key={index}
              title={title}
              content={text}
              icon={icon}
              info={info}
            />
          ))}
        </div>
        <div className="mt-10 grid grid-cols-1 justify-between gap-4 md:grid-cols-2 md:gap-10">
          <div>
            {content?.images?.length === 0 ? (
              <img
                src={"/images/broken-image.png"}
                alt=""
                className="h-[400px] w-full rounded-[16px] object-cover"
              />
            ) : (
              <ImageSlideShow images={content?.images} />
            )}

            <div className="flex items-center justify-between">
              <div>
                <h2 className="mt-7 font-Bai text-lg font-semibold capitalize text-primary md:text-2xl">
                  price
                </h2>
                <p className="font-Bai text-2xl font-bold text-grey_20 md:text-3xl">
                  {content?.price
                    ? formatCurrency(content?.price)
                    : "No Budget"}
                </p>
              </div>
            </div>

            <h2 className="mt-7 font-Bai text-lg font-semibold capitalize text-primary md:text-2xl">
              Description
            </h2>
            <p className="mt-2 mb-8 font-medium text-grey_20 md:pr-20">
              {content?.description}
            </p>
            {content?.task_features?.length > 0 && (
              <>
                <h2 className="mt-7 font-Bai text-lg font-semibold capitalize text-primary md:text-2xl">
                  Job features
                </h2>
                <div className="mt-2 mb-8 flex flex-wrap items-center gap-1.5 font-medium text-grey_20 md:pr-20">
                  {content?.task_features?.map(task => (
                    <Chip
                      key={task?.id}
                      label={task?.task_feature}
                      sx={{ borderRadius: "100px" }}
                    />
                  ))}
                </div>
              </>
            )}

            {content?.videos?.length > 0 && (
              <div className="mt-6">
                <p className="mb-3 font-Bai text-lg font-semibold capitalize text-primary md:text-2xl">
                  Video Reference{" "}
                </p>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  {content?.videos.map(el => (
                    <Video
                      type={el?.video_type}
                      videoUrl={el?.video_url}
                      key={el?.video_url}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          {/* second column */}
          <div>
            <UserProfileCard
              user={{ ...content?.creator, user_type: "client" }}
              isAdmin={false}
            />
            <section className="mt-16">
              <h2 className="font-Bai text-2xl font-semibold text-grey">
                All Offers received{" "}
                <span className="mx-1 inline-flex h-[28px] w-[28px] items-center justify-center rounded-full bg-primary text-base font-medium text-white">
                  {content?.offers_count}
                </span>
              </h2>
              {content?.offers?.length > 0 ? (
                <div className="mt-6 flex flex-col gap-y-5 rounded-24 bg-[#F2F4F7] p-3 md:gap-y-3 md:p-4">
                  {myOffer ? (
                    <>
                      <div className="mb-6 rounded-[14px] p-4 md:p-6 bg-[#FF1D0D] bg-opacity-[0.06]">
                        <span className="flex gap-3 items-center">
                          <AlertIcon />
                          <p className="font-Mulish font-medium text-base md:text-lg text-[#B42318]">Notice</p>
                        </span>
                        <p className="mt-3 font-Mulish font-medium text-sm md:text-base text-[#271A19]">
                          Please ensure to keep all engagements and transactions within the system for security, transparency, and fair compensation in case of conflicts with clients
                        </p>
                      </div>

                      <p className="mb-2 text-lg font-bold text-grey">
                        My Offer
                      </p>
                      <CurrentProviderOffer
                        creatorId={content?.creator?.id}
                        offer={myOffer}
                      />
                    </>
                  ) : null}
                  {content?.offers?.filter(
                    offer => offer?.user?.id !== user?.id,
                  )?.length > 0 && (
                      <>
                        {content?.offers?.find(
                          offer => offer?.user?.id === user?.id,
                        ) && (
                            <p className="mt-6 mb-2 text-lg font-bold text-grey">
                              Offers from other Service Providers
                            </p>
                          )}
                        {content?.offers
                          ?.filter(offer => offer?.user?.id !== user?.id)
                          ?.slice(0, itemCount)
                          .map(offer => (
                            <JobDetailSingleOffer
                              key={offer?.id}
                              offer={{
                                rating: offer?.user?.average_rating,
                                name:
                                  offer?.user?.professional_information
                                    ?.business_name || offer?.user?.full_name,
                                user_id: offer?.user_id,
                                img:
                                  offer?.user?.professional_information
                                    ?.business_logo || offer?.user?.avatar,
                                pitch_message: offer?.pitch_message,
                                created_at: offer?.created_at,
                                is_verified: offer?.user?.is_verified_account,
                              }}
                            />
                          ))}
                        {itemCount <
                          content?.offers?.filter(
                            offer => offer?.user?.id !== user?.id,
                          )?.length && (
                            <Button
                              onClick={() => setItemCount(count => count + 10)}
                              endIcon={<KeyboardArrowDownIcon />}
                            >
                              See More
                            </Button>
                          )}
                      </>
                    )}
                </div>
              ) : (
                <EmptyResponse icon={<JobIcon />} message="No offers yet" />
              )}
            </section>
          </div>
        </div>
        <MakeRequestModal
          reqToken={reqToken}
          refetchJob={() => {
            refetch();
            spRefetch()
          }}
          open={isRequestOpen}
          onClose={() => setIsRequestOpen(false)}
          info={info}
          setInfo={setInfo}
          content={content}
        />
        <NoServiceModal
          open={showNoserviceModal}
          close={() => setShowNoServiceModal(false)}
        />
        <UnApprovedKycModal open={showKycModal} close={() => setShowKycModal(false)} />
      </section>
    </>
  );
};

export default JobDetail;
