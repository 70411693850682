// import CreationCompletedIcon from "components/Vectors/CreationCompletedIcon";
// import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
// import {setUserDetails} from "redux/slice/AuthSlice";
// import {useSwitchAccountMutation} from "services/user/userRegApi";
// import {handleError} from "utils";
// import LoadingBtn from "../LoadingButton";
// import MessageModal from "./MessageModal";
import OnboardingModal from "./OnboardingModal";
import OnboardingClientModal from "./OnboardingClientModal";

const SwitchAccountModal = ({open, close}) => {
  // const [switchAccount, {isLoading: isSwitchingAccount}] =
  //   useSwitchAccountMutation();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const location = useLocation();
  const isProvider = location.pathname.includes("service-provider");

  if (isProvider) {
    return (
      <OnboardingClientModal open={open} close={close} />
      // <div>
      //   <MessageModal
      //     openModal={open}
      //     closeModal={close}
      //     icon={<CreationCompletedIcon />}
      //     loading={isSwitchingAccount}
      //     cta={
      //       <LoadingBtn
      //         fullWidth
      //         loading={isSwitchingAccount}
      //         onClick={async () => {
      //           try {
      //             let res = await switchAccount().unwrap();
      //             close();
      //             dispatch(setUserDetails(res?.data));
      //             navigate("/client", {state: "upgrading"});
      //           } catch (error) {
      //             handleError(error);
      //           }
      //         }}
      //       >
      //         Proceed
      //       </LoadingBtn>
      //     }
      //     title="Proceed to being a client"
      //     description=""
      //   />
      // </div>
    );
  } else {
    return <OnboardingModal open={open} close={close} />;
  }
};
export default SwitchAccountModal;
