import MyModal from "components/mui-components/Modal";
import BlockedUserCard from "../widgets/BlockedUserCard";
import {useGetBlockedUsersQuery} from "services/user/userRegApi";
import {DisplayData} from "pages/Clients/Dashboard/Main";
import UsersIcon from "components/Vectors/UsersIcon";

const BlockedUsersModal = ({openModal, setopenModal}) => {
  const {data, isLoading, isError, error} = useGetBlockedUsersQuery();

  return (
    <>
      <MyModal
        openModal={openModal}
        closeModal={() => setopenModal(false)}
        maxWidth={550}
        title="Blocked Users"
      >
        <DisplayData
          isLoading={isLoading}
          isError={isError}
          error={error}
          isEmpty={data?.data?.length <= 0}
          emptyResponse={{
            message: "No blocked users yet",
            icon: <UsersIcon color="#3b3d3b" />,
          }}
        >
          {data?.data.map(el => (
            <BlockedUserCard key={el?.id} user={el?.blocked_users} />
          ))}
        </DisplayData>
      </MyModal>
    </>
  );
};

export default BlockedUsersModal;
