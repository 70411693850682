import React from 'react'

const ContactUsLinkedInIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM8.48027 9.93892H5.76081V18.1098H8.48027V9.93892ZM8.65938 7.41135C8.64173 6.6102 8.06883 6 7.13852 6C6.20821 6 5.6 6.6102 5.6 7.41135C5.6 8.1959 6.19023 8.82367 7.10322 8.82367H7.12059C8.06883 8.82367 8.65938 8.1959 8.65938 7.41135ZM18.2877 13.4249C18.2877 10.9151 16.9461 9.74706 15.1566 9.74706C13.7127 9.74706 13.0663 10.5401 12.7053 11.0965V9.93914H9.98555C10.0214 10.7059 9.98555 18.11 9.98555 18.11H12.7053V13.5467C12.7053 13.3025 12.723 13.0589 12.7949 12.8841C12.9914 12.3962 13.4389 11.8911 14.1903 11.8911C15.1747 11.8911 15.5682 12.6404 15.5682 13.7384V18.1098H18.2876L18.2877 13.4249Z" fill="#E98100" />
        </svg>

    )
}

export default ContactUsLinkedInIcon