import { useEffect } from "react";
import AutoCompleteField from "components/forms/AutoCompleteField";
import FormDatePicker from "components/forms/FormDatePicker";
import { useForm, FormProvider } from "react-hook-form";
import { useGetUser } from "hooks/getUserHook";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import LoadingBtn from "components/mui-components/LoadingButton";
import { useUserBasicInfoMutation } from "services/user/userRegApi";
import moment from "moment";
import { handleError, removeEmpty } from "utils";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import { showToast } from "redux/store.hooks";
import ValidatedRadioBtns from "components/forms/ValidatedRadioBtns";
import { PrevBtn } from ".";
import StateCityField from "pages/ServiceProviders/Settings/Components/StateCityField";
import { Country } from "country-state-city";
import CustomFileUploader from "components/forms/CustomFileUploader";
// import PhoneNumberInput from "components/forms/PhoneNumberInput/PhoneNumberInput";
import { ListOfLanguages } from "utils/constants";
import { LinearProgress } from "@mui/material";
import ValidatedInput2 from "components/forms/ValidatedInput2";

const MIN_DATE = new Date().setFullYear(new Date().getFullYear() - 18);

const ClientBasicInfo = ({ onBtnClick, onPrevClick, presentStep, steps }) => {
  const [createInfo, { isLoading }] = useUserBasicInfoMutation();
  const { user: currentUser } = useGetUser();

  const methods = useForm({
    defaultValues: {
      dob: moment(MIN_DATE).format("DD/MM/YYYY"), phone_number: currentUser?.phone_number,
      country: currentUser?.country || "",
      state_id: currentUser?.state?.id || "",
      city_id: currentUser?.city?.id || "",
      profile_image: currentUser?.avatar || "",
      languages: currentUser?.languages?.map(lang => lang?.name) || [],
    },
  });

  const { setValue, handleSubmit } = methods;
  //   useEffect(()=>{
  // register('stateId');
  // register('countryValue');
  // register('cityId');
  // register('register');
  //   },[])



  // const watch stateValue = 
  const allCountries = Country.getAllCountries()?.filter(
    country => country.name === "Nigeria",
  );

  useEffect(() => {
    const FIELDS = ["about", "country", "gender", "dob", "residential_address", 'phone_number'];
    FIELDS.forEach(field => setValue(field, currentUser?.[field]));
  }, [setValue, currentUser]);



  const onSubmit = async data => {
    console.log(data)
    const formData = new FormData();
    Object.entries(removeEmpty(data)).forEach(([key, value]) => {
      if (key !== "profile_image") {
        if (value instanceof Array) {
          for (const val of value) {
            formData.append(`${key}[]`, val);
          }
        } else {
          formData.append(key, value);
        }
      }
    });
    if (typeof data?.profile_image !== "string") {
      formData.append("profile_image", data.profile_image);
    }
    formData.append("dob", moment(data?.dob).format("DD/MM/YYYY"));

    formData.append("phone_number", data?.phone_number)

    try {
      let res = await createInfo(formData).unwrap();
      showToast(res?.message, "success");
      onBtnClick();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <section className="w-full rounded-24 bg-white py-10">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start px-6 md:px-8">
            <div className="relative w-full mb-7">
              <LinearProgress
                className="flex-grow !bg-[#efefef] md:flex-grow-0"
                variant="determinate"
                value={(presentStep / steps.length) * 100}
                sx={{
                  borderRadius: 12,
                  height: 4,
                  maxHeight: 6,
                  mb: 2,
                }}
              />{" "}
              <p className="flex items-start">
                <span className="mr-1 text-3xl font-bold text-grey">
                  {presentStep}{" "}
                </span>
                <span className="text-base font-normal text-grey_40">
                  / {steps.length}
                </span>
              </p>
            </div>
            <h1 className="text-prop mb-8 font-Bai text-2xl font-bold md:text-[2rem]">
              Personal Information
            </h1>
          </div>
          {/* <div className="my4 w-full bordert-[1px] border-grey_60 mdmy-5"></div> */}

          <div className="mb-28 flex flex-col gap-y-10 px-6 md:px-8">
            <div className="flex flex-col gap-y-7">
              <ValidatedInput2
                textSize="text-[14px]"
                label="First Name"
                placeholder="Emmanuel"
                name="first_name"
                bg="#F4F4F5"
                // rules={{ required: "Business name is required" }}
              />
               <ValidatedInput2
                textSize="text-[14px]"
                label="Last Name"
                placeholder="Adeshina"
                name="last_name"
                bg="#F4F4F5"
                // rules={{ required: "Business name is required" }}
              />
              <AutoCompleteField
                label="Languages"
                name="languages"
                placeholder="Select"
                selectOption={ListOfLanguages}
                multiple
                bg="#F4F4F5"
              />

              <ValidatedRadioBtns
                containerClass="md:py3"
                name="gender"
                options={["Male", "Female", "Prefer Not To Say"]}
                label="Sex"
              />

              {/* PHONE NUMBER INPUT */}
              {/* <PhoneNumberInput
                name='phone_number'
                border
              /> */}
              {/* date of birth  */}
              <div className="flex flex-col gap-y-2 text-grey_30">
                <label htmlFor="dob" className="text-sm">
                  Date of birth
                </label>
                <FormDatePicker openTo={"year"} maxDate={MIN_DATE} name="dob" />
              </div>
              {/* address */}
              <ValidatedTextArea2
                type="text"
                label="Residential Address"
                name="residential_address"
                placeholder="Ikeja"
                bg2="#F4F4F5"
              />
              <p className="font-semibold text-lg text-[#3B3D3B] font-Bai -mb-3">Location</p>
              {/* countries  */}
              <AutoCompleteField
                name="country"
                selectOption={allCountries?.map(item => item.name)}
                label="Country"
                placeholder="Select Country"
                className="font-Mulish text-sm text-grey_30"
                bg="#F4F4F5"
              />
              <div className=" flex justify-between gap-3 ">
                {/* state  */}
                <StateCityField
                  defaultCityId={currentUser?.city?.id}
                  defaultStateId={currentUser?.state?.id}
                  bg="#F4F4F5"
                  />
              </div>
              <CustomFileUploader
                required={currentUser?.avatar ? false : true}
                fileType={"image"}
                defaultUrl={currentUser?.avatar}
                name="profile_image"
                label="Profile Image"
                className="min-h-[180px] !flex-col justify-center"
                types={["png", "jpg", "jpeg"]}
              />
            </div>
          </div>
          <div className="flex items-stretch justify-start gap-x-6 px-6 md:px-8">
            {onPrevClick && <PrevBtn onClick={onPrevClick} />}
            <LoadingBtn
              type="submit"
              loading={isLoading}
              endIcon={<EastRoundedIcon fontSize="large" />}
            >
              Next
            </LoadingBtn>
          </div>
        </form>
      </FormProvider>
    </section>
  );
};

export default ClientBasicInfo;
