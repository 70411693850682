import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import { Chip } from "@mui/material";
import { useGetAllServiceChargeOptionsQuery, useGetTransactionHistoryQuery } from "services/admin/adminGeneralApi";
import { toCurrency } from "utils";
import { JobsStatusColor } from "utils/constants";
import moment from "moment";

const EngagTranscHistoryTab = () => {
  const { data, isLoading, error, isError } = useGetTransactionHistoryQuery();
  const { data: allOptions } =
    useGetAllServiceChargeOptionsQuery();
  const clientCharges = allOptions?.data?.find(item => item.option === "buyers_project_payment_Commission")?.value
  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
  };
  const mockData = Array.from(data?.data || [])?.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  })?.map((item, i) => ({
    sn: i + 1,
    client: {
      name: item?.client?.full_name,
      avatar: item?.client?.avatar,
      searchBy: item?.client?.username,
    },
    service_provider: {
      name: item?.service_provider?.full_name,
      avatar: item?.service_provider?.avatar,
      searchBy: item?.service_provider?.username,
    },
    engagements: {
      name: item?.engagement?.job_detail?.job_title,
      image:
        item?.engagement?.job_detail?.images?.length > 0
          ? item?.engagement?.job_detail?.images[0]?.image_url
          : "/images/broken-image.png",
    },
    date: moment(item?.created_at).format("DD/MM/YYYY hh:mm A"),
    completion_date: item?.engagement?.status === 'Completed' ? moment(item?.engagement?.completed_at).format('DD/MM/YYYY') : 'Pending',
    client_charges: toCurrency((parseFloat(clientCharges) / 100) * parseFloat(item?.amount)),
    amount: toCurrency(item?.amount),
    service_charge: toCurrency(item?.service_charge),
    vat: toCurrency(item?.vat),
    trasaction_ref: `#${item?.transaction_ref}`,
    status: item?.status,
  }));

  return (
    <div>
      <div className="w-full">
        <MyDataTable
          scrollX={false}
          data={mockData}
          headCells={headCells}
          options={options}
          hideChipDot
          isLoading={isLoading}
          error={error}
          isError={isError}
        />
      </div>
    </div>
  );
};

export default EngagTranscHistoryTab;

const headCells = [
  {
    name: "sn",
    numeric: false,
    disablePadding: false,
    label: "S/N",
  },
  {
    name: "client",
    label: "client",
    options: {
      customBodyRender: value => (
        <UserDisplay
          // imgRounded={false}
          name={value.name}
          img={value.avatar}
          size="text-[1rem]"
        />
      ),
    },
  },
  {
    name: "service_provider",
    label: "service provider",
    options: {
      customBodyRender: value => (
        <UserDisplay
          // imgRounded={false}
          name={value.name}
          img={value.avatar}
          size="text-[1rem]"
        />
      ),
    },
  },
  {
    name: "engagements",
    label: "engagements",
    options: {
      customBodyRender: value => (
        <UserDisplay
          imgRounded={false}
          name={value.name}
          img={value.image}
          size="text-[1rem]"
        />
      ),
    },
  },
  {
    name: "date",
    label: "Transaction Payment",
  },
  {
    name: "completion_date",
    label: "Engagement Completion",
  },
  {
    name: "amount",
    label: "amount",
  },
  {
    name: "service_charge",
    label: "service charge",
  },
  {
    name: "client_charges",
    label: "Charges on Client",
  },
  {
    name: "vat",
    label: "VAT",
  },

  {
    name: "trasaction_ref",
    label: "trasaction ref",
  },
  {
    name: "status",
    label: "Status",
    options: {
      customBodyRender: value => (
        <Chip
          sx={{ borderRadius: "8px", paddingLeft: "0px !important" }}
          color={JobsStatusColor[value]}
          label={value}
        />
      ),
    },
  },
];
