import { Avatar, Chip } from "@mui/material";
import BackBtn from "components/BackBtn";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import KYC from "components/KYC";
import {
  useActivateUserAccountMutation,
  useGetOneUnIndentifiedUserQuery, useDeleteUserAccountMutation
} from "services/admin/adminGeneralApi";
import { useNavigate, useParams } from "react-router-dom";
import { getUserDetails, handleError } from "utils";
import moment from "moment";
import { showToast } from "redux/store.hooks";
import { LoadingButton } from "@mui/lab";

const EachAdminUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, error } = useGetOneUnIndentifiedUserQuery(id);
  const [activateAccount, { isLoading: isActivating }] =
    useActivateUserAccountMutation();
  const [deleteAccount, { isLoading: isDeleting }] =
    useDeleteUserAccountMutation();
  const user = data?.data;
  const userStatus = user?.status;

  return (
    <DashboardLayout
      isLoading={isLoading}
      error={error}
      title="Users"
      type="admin"
    >
      <main className="mt-8">
        <BackBtn to="/admin/users" text="All Users" />
        <div className="rounded-2xl bg-white p-4 md:p-8">
          <div className="flex flex-col justify-between md:flex-row md:items-start">
            <div className="relative flex flex-col gap-4 md:flex-row">
              <div className="relative h-[60px] w-[60px]  md:h-[120px] md:w-[120px]">
                <Avatar
                  src={user?.avatar}
                  sx={{
                    width: { xs: "60px", md: "120px" },
                    height: { xs: "60px", md: "120px" },
                  }}
                />
              </div>
              <div className="mt-3">
                <div className="flex items-center gap-x-2.5">
                  <h3 className="mb-2 font-Bai text-[32px] font-semibold text-black">
                    {getUserDetails(user).name}
                  </h3>
                </div>
                <p className="font-Bai text-lg font-medium text-grey_20">
                  @{user?.username || "N/A"}
                </p>
                <div className="mt-4 flex items-center gap-3">
                  <Chip
                    sx={{ borderRadius: "8px" }}
                    color={userStatus === 'Active' ? 'successInverse' : userStatus === 'Suspended' ? 'errorInverse' : 'default'
                    }
                    label={userStatus}
                  />
                  <p className="text-sm">
                    <span className="text-grey_30">Date Registered:</span>{" "}
                    <span className="text-grey">
                      {moment(user?.created_at).format("DD MMM, YYYY")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-3 flex items-center gap-2">
              <LoadingButton
                onClick={() => {
                  activateAccount(id)
                    .unwrap()
                    .then(res => {
                      showToast(res?.message);
                    })
                    .catch(err => {
                      handleError(err);
                    });
                }}
                variant="contained"
                loading={isActivating}
              >
                Activate Account
              </LoadingButton>

              <LoadingButton
                onClick={() => {
                  deleteAccount(id)
                    .unwrap()
                    .then(res => {
                      showToast(res?.message);
                      navigate('/admin/users')
                    })
                    .catch(err => {
                      handleError(err);
                    });
                }}
                variant="contained"
                color='errorInverse'
                loading={isDeleting}
              >
                Delete Account

              </LoadingButton>
            </div>
          </div>
          <div className="mt-8 grid gap-6 md:grid-cols-2">
            <div className='bg-grey_80 rounded-24 p-6'>
              <h2 className='font-Bai font-semibold text-2xl'>Overview</h2>
              <div className="my-3">
                <p className="text-grey_30">
                  Email Address
                </p>
                <p className="">{user?.email || 'N/A'}</p>
              </div>
              <div className="">
                <p className="text-grey_30">
                  Phone
                </p>
                <p className="">{user?.phone_number || 'N/A'}</p>
              </div>
            </div>
            {/* Second column */}
            <div className='bg-grey_80 rounded-24 p-6'>
              <KYC data={data?.data?.kyc} />
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
}

export default EachAdminUser;
