import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {API_URL} from "utils/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, {getState}) => {
    headers.set("Accept", `application/json`);
    const token = getState().auth.token;
    if (token) headers.append("authorization", `Bearer ${token}`);
    return headers;
  },
});

export const authApi = createApi({
  baseQuery,
  reducerPath: "authApi",
  tagTypes: ["user"],
  endpoints: builder => ({
    adminLogin: builder.mutation({
      query: body => ({
        url: `/admin/auth/login`,
        method: "POST",
        body,
      }),
    }),

    adminSendPwdResetLink: builder.mutation({
      query: body => ({
        url: `/admin/auth/send-password-reset-link`,
        method: "POST",
        body,
      }),
    }),

    adminResetPassword: builder.mutation({
      query: body => ({
        url: `/admin/auth/reset-password`,
        method: "POST",
        body,
      }),
    }),

    userLogin: builder.mutation({
      query: body => ({
        url: `/user/auth/login`,
        method: "POST",
        body,
      }),
    }),

    userSignUpWithEmail: builder.mutation({
      query: body => ({
        url: `/user/auth/signup-email`,
        method: "POST",
        body,
      }),
    }),

    userAuthWithGoogle: builder.mutation({
      query: body => ({
        url: `/user/auth/signup-with-google`,
        method: "POST",
        body,
      }),
    }),

    userVerifyOtp: builder.mutation({
      query: body => ({
        url: `/user/auth/verify-otp`,
        method: "POST",
        body,
      }),
    }),

    userResendOtp: builder.mutation({
      query: body => ({
        url: `/user/auth/resend-otp`,
        method: "POST",
        body,
      }),
    }),

    // forgot password
    userSendPwdOtp: builder.mutation({
      query: body => ({
        url: `user/auth/send-password-reset-otp`,
        method: "POST",
        body,
      }),
    }),

    userPwdVerifyOtp: builder.mutation({
      query: body => ({
        url: `user/auth/verify-password-reset-otp`,
        method: "POST",
        body,
      }),
    }),

    userResetPwd: builder.mutation({
      query: body => ({
        url: `user/auth/reset-password`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useAdminLoginMutation,
  useAdminSendPwdResetLinkMutation,
  useAdminResetPasswordMutation,
  useUserLoginMutation,
  useUserSignUpWithEmailMutation,
  useUserVerifyOtpMutation,
  useUserResendOtpMutation,
  useUserAuthWithGoogleMutation,
  useUserSendPwdOtpMutation,
  useUserPwdVerifyOtpMutation,
  useUserResetPwdMutation,
} = authApi;
