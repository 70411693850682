import {Link, useNavigate} from "react-router-dom";
import {Avatar, Button} from "@mui/material";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RequestModal from "components/RequestModal";
import {useState} from "react";
const SingleRequest = ({request, isDeclined}) => {
  const navigate = useNavigate();
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const {
    id,
    img,
    title,
    desc,
    profilePic,
    profileName,
    price,
    location,
    duration,
    date,
    time,
    status,
  } = request;
  return (
    <div className="rounded-24 bg-grey_80 p-3 md:basis-1/2 md:p-6">
      <Link to={id} className="flex gap-x-3 md:gap-x-6">
        <img src={img} alt={title} className="h-20 md:h-full" />
        <p className="flex flex-col gap-y-2 text-grey_30">
          <span className="font-Bai font-semibold text-grey_20 md:text-lg">
            {title}
          </span>
          <span className="max-h-[4rem] overflow-hidden text-sm md:max-h-[75px] md:text-base">
            {desc}
          </span>
        </p>
      </Link>
      <div className="my-6 rounded-20 bg-white p-4">
        <div className="flex flex-col items-center justify-between md:flex-row">
          <Link to="/user-profile/mirian" className="flex items-center gap-x-3">
            <Avatar src={profilePic} />
            <p className="font-medium text-grey_20">
              {profileName}{" "}
              {/* <VerifiedIcon fontSize="small" sx={{ color: "#E98100" }} /> */}
            </p>
          </Link>
          <p className="font-Bai text-lg font-bold text-grey_20 md:text-xl">
            {price}
          </p>
        </div>
        <div className="mt-6 flex flex-col items-center justify-between gap-y-3 text-sm text-grey_30 md:flex-row md:gap-y-0 md:text-xs">
          <span className="flex items-center gap-x-1">
            <AccessTimeIcon fontSize="small" />
            {duration}
          </span>
          <span className="flex items-center gap-x-1">
            <DateRangeOutlinedIcon fontSize="small" />
            {date}
          </span>
          <span className="flex items-center gap-x-1">
            <FmdGoodOutlinedIcon fontSize="small" />
            {location}
          </span>
          <span
            className={`mt-3 rounded-lg px-3 py-2 capitalize md:mt-0 md:py-1.5 md:px-2
          ${
            status === "pending"
              ? "bg-grey_70 text-grey_20"
              : "bg-[#d92d2019] text-[#D92D20]"
          }
          `}
          >
            {status}
          </span>
        </div>
      </div>
      <div
        className={`flex flex-col-reverse items-center md:flex-row ${
          isDeclined ? "justify-end" : "justify-between"
        }`}
      >
        {isDeclined ? null : (
          <div className="flex items-center gap-x-6 md:gap-x-4">
            <Button
              variant="outlined"
              onClick={() => {
                setIsRejecting(true);
                setShowRequestModal(true);
              }}
            >
              Decline
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsRejecting(false);
                setShowRequestModal(true);
              }}
            >
              Approve
            </Button>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between">
        <p className="text-sm font-normal text-grey_40">Sent {time} ago</p>
        <Button
          onClick={() => navigate(id)}
          endIcon={<EastRoundedIcon fontSize="large" />}
        >
          View details
        </Button>
      </div>
      <RequestModal
        open={showRequestModal}
        close={() => setShowRequestModal(false)}
        isRejecting={isRejecting}
      />
    </div>
  );
};

export default SingleRequest;
