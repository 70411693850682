import {CircularProgress} from "@mui/material";
import MyModal from "components/mui-components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import {useEffect} from "react";
import {useUpdateCityMutation} from "services/admin/adminGeneralApi";
import {LoadingButton} from "@mui/lab";
import {handleError} from "utils";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import {showToast} from "redux/store.hooks";

const EditCityModal = ({
  openModal,
  setopenModal,
  stateID,
  cityID,
  cityName,
}) => {
  const methods = useForm();
  const {setValue} = methods;

  const [updateCity, {isLoading}] = useUpdateCityMutation();

  useEffect(() => {
    setValue("name", cityName);
  }, [setValue, cityName]);

  const onSubmit = async data => {
    const body = {state_id: stateID, name: data.name};

    try {
      let res = await updateCity({
        id: cityID,
        ...body,
      }).unwrap();
      showToast(res?.message, "success");
      methods.reset();
      setopenModal(false);
    } catch (error) {
      handleError(error);
      setopenModal(false);
    }
  };

  return (
    <MyModal
      openModal={openModal}
      closeModal={() => setopenModal(false)}
      title="Edit city"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex h-[290px] flex-col justify-between"
        >
          <ValidatedInput2 name="name" required label="City name" />

          <LoadingButton
            sx={{mt: 4}}
            loadingIndicator={
              <CircularProgress
                sx={{
                  color: "#fff",
                }}
                size="1.2rem"
              />
            }
            type="submit"
            loading={isLoading}
            fullWidth
            variant="contained"
            color="primary"
          >
            Update City
          </LoadingButton>
        </form>
      </FormProvider>
    </MyModal>
  );
};

export default EditCityModal;
