import UploadImage3 from "components/forms/UploadImage3";
import {FormProvider, useForm} from "react-hook-form";
import FlagIcon from "components/Vectors/FlagIcon";
import {useCreateTicketMutation} from "services/user/userRegApi";
import MyModal from ".";
import ValidatedInput from "components/forms/ValidatedInput";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import {handleError} from "utils";
import {showToast} from "redux/store.hooks";
import ValidatedRadioBtns from "components/forms/ValidatedRadioBtns";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";

const IssueTicketModal = ({
  closeModal,
  openModal,
  isCreating,
  engagementId,
}) => {
  const methods = useForm();
  const [createTicket, {isLoading: isCreateTicketLoading}] =
    useCreateTicketMutation();
  const [submittedImg, setSubmittedImg] = useState("");
  const onSubmit = async data => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("priority", data.priority);
    if (engagementId) {
      formData.append("engagement_id", engagementId);
    }
    formData.append("module", engagementId ? "Engagement" : "Others");

    if (data.attachment && data.attachment.length > 0)
      formData.append("attachment", data.attachment[0]);
    try {
      const res = await createTicket(formData).unwrap();
      showToast(res?.message, "success");
      methods.reset();
      setSubmittedImg("submitted");
      closeModal();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <MyModal openModal={openModal} closeModal={closeModal} title="Issue Ticket">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="px-[4px]">
            {!isCreating && (
              <div className="flex items-center gap-4 rounded-20 bg-[#F9FAFB] p-4">
                <img
                  src="/images/eng-1.png"
                  alt="img"
                  className="h-[80px] w-[80px]"
                />
                <p className="text-prop font-Bai text-[20px] font-semibold text-grey_20 ">
                  I will repair everything that is faulty in your house
                </p>
              </div>
            )}
            <div>
              <ValidatedInput
                name="title"
                placeholder="Ticket Title"
                label="Ticket Title"
                textSize="text-[14px]"
              />

              <ValidatedRadioBtns
                name="priority"
                options={[
                  {
                    label: (
                      <span className="flex space-x-2">
                        <FlagIcon color="#8585A3" stroke="#8585A3" />
                        <span className="text-prop text-[14px] font-medium text-grey_10 ">
                          Low
                        </span>
                      </span>
                    ),
                    value: "Low",
                  },
                  {
                    label: (
                      <span className="flex space-x-2">
                        <FlagIcon color="#FFB72D" stroke="#FFB72D" />
                        <span className="text-prop text-[14px] font-medium text-grey_10 ">
                          Normal
                        </span>
                      </span>
                    ),
                    value: "Medium",
                  },
                  {
                    label: (
                      <span className="flex space-x-2">
                        <FlagIcon
                          height="17"
                          width="13"
                          color="#D61E34"
                          stroke="#D61E34"
                        />
                        <span className="text-prop text-[14px] font-medium text-grey_10 ">
                          High
                        </span>
                      </span>
                    ),
                    value: "High",
                  },
                ]}
                label="Ticket Priority"
              />
              {/* <AutoCompleteField
                name="engagement"
                selectOption={[
                  {id: 1, name: "Lagos"},
                  {id: 2, name: "Abuja"},
                ]}
                label="Add Engagements"
                // loading={isStatesLoading}
                className="font-Mulish text-sm text-grey_30"
                placeholder="Select Engagement"
              /> */}
              <ValidatedTextArea
                name="description"
                label="Description"
                bgColor="bg-[#F9FAFB]"
                borderRadius="rounded-[16px]"
                placeholder="Ticket Description"
              />
              <div className="mt-4">
                <p className="text-sm font-normal text-[#3B3D3B] ">
                  Add Attachment (Optional){" "}
                </p>
                <div className="mt-3 rounded-[10]">
                  <UploadImage3
                    containerHeight="h-[200px]"
                    iconColor="#E98100"
                    iconHeight="48"
                    iconWidth="48"
                    name="attachment"
                    required={false}
                    bgColor="bg-grey_80"
                    touchedCover={submittedImg}
                    borderRadius="rounded-[10px]"
                  />
                </div>
              </div>
            </div>

            <div className="mt-[70px] flex gap-3">
              <LoadingButton
                loading={isCreateTicketLoading}
                type="submit"
                fullWidth
                variant="contained"
              >
                Create Ticket
              </LoadingButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </MyModal>
  );
};
export default IssueTicketModal;
