import { useEffect, useState } from "react";
import { Button, Skeleton, Tab } from "@mui/material";
import IssueTicketModal from "components/mui-components/Modal/IssueTicketModal";
import OverviewCard from "components/OverviewCard";
import TicketIcon from "components/Vectors/ticket";
import { TabContext, TabList } from "@mui/lab";
import { useGetAllTicketsQuery } from "services/user/userRegApi";
import { useNavigate } from "react-router-dom";
import TicketsTab from "pages/Admin/Dashboard/Tickets/Tabs/TicketsTab";
import { setTickets, setTotalTicketsCount } from "redux/slice/ApiSlice";
import { useDispatch } from "react-redux";

const Tickets = () => {
  const [activeTab, setActiveTab] = useState("in-progress");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const TICKETS_PER_PAGE = 5;
  const navigate = useNavigate();
  const {
    data: tickets,
    isLoading: isAllTicketsLoading,
    isError,
    error,
  } = useGetAllTicketsQuery({
    params: {
      is_paginated: "yes",
      per_page: TICKETS_PER_PAGE,
      page,
    }
  });
  useEffect(() => {
    if (tickets) {
      dispatch(setTickets(tickets.data));
      dispatch(setTotalTicketsCount(tickets.pagination.total));
    }
  }, [dispatch, tickets]);
  console.log({ tickets })
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const openTicketsCount = tickets?.data.filter(
    ticket => ticket.status === "Open" || ticket.status === "New",
  ).length;
  const inProgressTicketsCount = tickets?.data.filter(
    ticket => ticket.status === "In Progress",
  ).length;
  const resolvedTicketsCount = tickets?.data.filter(
    ticket => ticket.status === "Resolved",
  ).length;

  return (
    <section>
      <h1 className="font-h1">Tickets</h1>

      {/* top card  */}
      <div className="mt-8 rounded-16 bg-white p-4 md:rounded-24 md:px-8 md:py-[28px]">
        <div className="flex items-center justify-between border-b-[1px] border-b-[#F2F4F7] pb-2">
          <h2 className="font-h2 pb-2">Overview</h2>
          <Button onClick={() => setOpenTicketModal(true)} variant="contained">
            Create new Ticket
          </Button>
        </div>

        {/* overview cards  */}
        <div className="mt-3 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <OverviewCard
            title="In Progress"
            icon={<TicketIcon color="#E98100" />}
            value={
              tickets ? (
                inProgressTicketsCount
              ) : (
                <Skeleton variant="text" width={45} />
              )
            }
          />
          <OverviewCard
            title="New Tickets"
            icon={<TicketIcon color="#E98100" />}
            value={
              tickets ? (
                openTicketsCount
              ) : (
                <Skeleton variant="text" width={45} />
              )
            }
          />
          <OverviewCard
            title="Resolved Tickets"
            icon={<TicketIcon color="#E98100" />}
            value={
              tickets ? (
                resolvedTicketsCount
              ) : (
                <Skeleton variant="text" width={45} />
              )
            }
          />
        </div>
      </div>

      {/* List of tickets  */}
      <div className="mt-8 rounded-16 bg-white px-6 py-8 md:rounded-24">
        <div className="flex items-center justify-between pb-2">
          <h2 className="text-prop font-Bai text-[32px] font-semibold text-grey_20">
            List of Tickets
          </h2>

          {/* TABS */}
          <TabContext value={activeTab}>
            <TabList
              variant="scrollable"
              value={activeTab}
              className="mb-4 w-fit rounded-[16px] border border-grey_60 bg-grey_80 p-2"
              onChange={(_, newValue) => {
                navigate(`?tab=${newValue}`);
                setActiveTab(newValue);
              }}
              sx={{
                fontWeight: 600,
                ".MuiTab-root": {
                  minWidth: "max-content",
                },
                textTransform: "unset",
              }}
              aria-label="tickets tab"
            >
              <Tab label="In Progress" value="in-progress" />
              <Tab
                label={
                  <div className="flex items-center justify-center gap-x-3">
                    <span>New</span>
                    {tickets?.data && (
                      <span className="flex h-6 w-6 items-center justify-center rounded-full bg-error text-sm text-white">
                        {
                          tickets?.data.filter(ticket =>
                            ticket.status.toLowerCase().includes("open") || ticket.status.toLowerCase().includes("new"),
                          ).length
                        }
                      </span>
                    )}
                  </div>
                }
                value="new"
              />
              <Tab label="Completed" value="resolved" />
            </TabList>
          </TabContext>
        </div>
        <TicketsTab
          data={
            tickets?.data.filter(ticket =>
              ticket.status
                .toLowerCase()
                .includes(
                  activeTab.split("-").join(" "),
                ),
            ) || []
          }
          isLoading={isAllTicketsLoading}
          isError={isError}
          error={error}
          action={() => setPage(page + 1)}
        />
      </div>

      <IssueTicketModal
        openModal={openTicketModal}
        closeModal={() => setOpenTicketModal(false)}
        isCreating
      />
    </section>
  );
};
export default Tickets;
