import {Button, Chip} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import moment from "moment";
import ValidatedInput from "components/forms/ValidatedInput";
import {FormProvider, useForm} from "react-hook-form";
import {LoadingButton} from "@mui/lab";
import SendIcon2 from "components/Vectors/SendIcon2";
import UserAvatar from "components/mui-components/TableComponent/UserAvatar";
import {Link} from "react-router-dom";
import {CancelEngBtn} from "pages/Clients/JobRequest/widgets/EachRequestCard";
import {handleError, toCurrency} from "utils";
import {
  useGetOfferChatsQuery,
  useSendOfferMsgMutation,
} from "services/generalApi";
import {useGetUser} from "hooks/getUserHook";
import {useEffect} from "react";
import {ConversationList} from "pages/Clients/Engagements/EachEngagements/MessageChat";
import Pusher from "pusher-js";

export const SendOfferMsgForm = ({module_id, receiver_id, status}) => {
  const methods = useForm();
  const [sendMsg, {isLoading}] = useSendOfferMsgMutation();
  const onSubmit = async body => {
    try {
      await sendMsg({
        module: "Offer",
        module_id,
        receiver_id,
        message: body?.message,
      }).unwrap();
      methods.reset();
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="flex items-end gap-2"
      >
        <div className="w-full rounded-lg bg-white">
          <ValidatedInput
            showErrMsg={false}
            name="message"
            bg="#F9FAFB"
            placeholder="Send a reply, negotiate, agree on timeline, etc"
            variant={false}
            disabled={status === "Declined"}
          />
        </div>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          disabled={status === "Declined"}
        >
          <SendIcon2 />
        </LoadingButton>
      </form>
    </FormProvider>
  );
};

const ChipStatusColor = {
  pending: "default",
  accepted: "successInverse",
  withdrawn: "errorInverse",
  declined: "errorInverse",
  ongoing: "secondaryInverse",
  active: "success",
  suspended: "greyDark",
  terminated: "errorInverse",
  cancelled: "errorInverse",
  closed: "error",
  drafted: "greyBorder",
  open: "greyBorder",
  completed: "successInverse",
  refunded: "secondaryInverse",
};

const CurrentProviderOffer = ({offer, creatorId}) => {
  const {pitch_message, amount_offered, created_at, status} = offer;
  const {user} = useGetUser();

  const {
    data: allChats,
    isLoading: isChatsLoading,
    refetch,
  } = useGetOfferChatsQuery(offer?.id);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("offer-conversation-created");
    channel.bind("offer-conversations-event", data => {
      refetch();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [refetch]);

  // generate engagement status
  const isPaymentPending =
    offer?.engagement?.payment_status === "pending" &&
    offer?.engagement?.status?.toLowerCase() === "ongoing";

  const hasEngagementStarted =
    !isPaymentPending &&
    !offer?.engagement?.start_date &&
    offer?.engagement?.status?.toLowerCase() === "ongoing";

  const engagementStatus = isPaymentPending
    ? "Pending payment"
    : hasEngagementStarted
    ? "Pending Start"
    : offer?.engagement?.status;

  return (
    <div className="rounded-16 bg-white p-3 md:rounded-20 md:p-6">
      <div className="flex items-start justify-between">
        <Link
          to={`/user-profile/${offer?.user_id}?type=service provider`}
          className="flex items-center gap-x-3"
        >
          <UserAvatar
            src={
              offer?.user?.professional_information?.business_logo ||
              offer?.user?.avatar
            }
            alt={
              offer?.user?.professional_information?.business_name ||
              offer?.user?.full_name
            }
            showBadge={offer?.user?.is_verified_account}
            size={42}
          />

          <div className="flex flex-col">
            <span className="cursor-pointer font-Bai text-sm font-bold text-grey_20 hover:text-primary md:text-lg">
              {offer?.user?.professional_information?.business_name ||
                offer?.user?.full_name}{" "}
              (You)
            </span>
            <span className="flex items-center text-xs text-primary md:text-sm">
              <StarIcon fontSize="small" sx={{color: "#E98100"}} />
              <span>{user?.average_rating}</span>
            </span>
          </div>
        </Link>
        <span className="text-xs capitalize text-grey_30 md:text-sm">
          {moment(created_at).fromNow()}
        </span>
      </div>
      <div className="mt-4">
        <div className="flex items-center justify-between">
          <p className="mb-1 font-bold text-grey_20">Offer Message</p>
          <p className="text-sm font-bold text-grey_20">
            {toCurrency(amount_offered)}
          </p>
        </div>
        <p className="text-xs font-medium text-grey_30 md:text-[13px]">
          {pitch_message}
        </p>
      </div>
      <div className="mt-5 flex gap-2">
        <div>
          <p className="mb-1 text-[13px] text-grey_30">Offer Status</p>
          <Chip
            sx={{borderRadius: "8px"}}
            // size="small"
            label={status}
            color={ChipStatusColor[status?.toLowerCase()]}
          />
        </div>
        {offer?.engagement && (
          <div>
            <p className="mb-1 text-[13px] text-grey_30">Engagement Status</p>
            <Chip
              sx={{borderRadius: "8px"}}
              // size="small"
              label={engagementStatus}
              color={ChipStatusColor[engagementStatus?.toLowerCase()]}
            />
          </div>
        )}
      </div>

      {status?.toLowerCase() === "accepted" && (
        <div className="mt-3 flex h-[40px] gap-2">
          <Button
            component={Link}
            to={`/service-provider/engagements/${offer?.engagement?.id}`}
            size="small"
            variant="contained"
          >
            View engagement
          </Button>
          {offer?.engagement?.payment_status === "pending" &&
            offer?.engagement?.status.toLowerCase() === "ongoing" && (
              <CancelEngBtn engagement_id={offer?.engagement?.id} />
            )}
        </div>
      )}

      <div className="mt-4">
        {isChatsLoading && (
          <p className="flex py-4">conversations loading...</p>
        )}
        {(allChats?.conversations || [])?.length > 0 && (
          <>
            <p className="mb-1 font-semibold text-grey_20">Replies</p>
            <div className="mb-4 flex flex-col gap-y-4">
              <ConversationList
                provider_id={allChats?.user_id}
                chats={allChats?.conversations}
                isOffer={true}
              />
            </div>
          </>
        )}
        {status?.toLowerCase() !== "accepted" && (
          <SendOfferMsgForm
            module_id={offer?.id}
            receiver_id={user?.id === creatorId ? offer?.user?.id : creatorId}
          />
        )}
      </div>
    </div>
  );
};

export default CurrentProviderOffer;
