import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({ data }) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          backgroundImage: `url('${
            data?.cover_photo || "/images/broken-image.png"
          }'), linear-gradient(to right, #eee4d7, #eee4d7)`,
        }}
        className={`category-card flex min-h-[380px] items-end rounded-[35px] bg-cover bg-center bg-no-repeat p-5 font-medium text-white md:min-h-[473px] md:p-8`}
      >
        <div className="category-desc content">
          <h3 className="pointer-events-none mb-3 flex items-center gap-2 text-xl md:text-3xl">
            {data?.name || "N/A"}
            <p className="min-w-max rounded-10 bg-[#1D1D1D66] px-3.5 py-2 text-sm font-bold text-white md:text-lg">
              {Number(data?.jobs_count || 0).toLocaleString()}{" "}
              {Number(data?.jobs_count || 0) === 1 ? "Job" : "Jobs"}
            </p>
          </h3>
          <p className="mb-4 font-Mulish text-base font-[500] leading-[28px] tracking-[-1%] md:text-xl">
            {data?.description || "N/A"}
          </p>
          <Button
            variant="contained"
            onClick={() =>
              navigate(`/jobs`, {
                state: {
                  category: data?.name,
                  description: data?.description,
                  cover_photo: data?.cover_photo,
                  expertises: data?.expertises?.map(el => el?.name),
                },
              })
            }
          >
            View more
          </Button>
        </div>
      </div>
    </>
  );
};

export default CategoryCard;
