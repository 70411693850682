import {Button, Chip, Tooltip} from "@mui/material";
import FlagIcon from "components/Vectors/FlagIcon";
import LinkIcon from "components/Vectors/LinkIcon";
import LongArrowRightIcon from "components/Vectors/LongArrowRightIcon";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {tooltipClasses} from "@mui/material/Tooltip";
import UserAvatar from "./mui-components/TableComponent/UserAvatar";
import {useGetUser} from "hooks/getUserHook";
import {convertToSlug} from "utils";

export const BootstrapTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& > *`]: {
    cursor: "pointer",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#3B3D3B",
    boxShadow:
      "0px 6px 6px rgba(157, 157, 157, 0.04), 0px 10px 20px rgba(157, 157, 157, 0.1)",
    fontSize: 13,
    fontFamily: "Mulish",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

const TicketCards = ({ticket}) => {
  const {
    id,
    attachment,
    title,
    description,
    user: {username, avatar},
    priority,
    created_at,
    user_role_type,
    last_message,
    engagement_id,
    ticket_tag,
  } = ticket;
  const navigate = useNavigate();
  const {user} = useGetUser();

  return (
    <div className="grey-border-2px flex justify-between rounded-20 px-4 py-6">
      <div className="flex flex-1 flex-col">
        {/* image and info  */}
        <div className="flex items-center justify-start gap-2">
          <UserAvatar
            size={55}
            showBadge={ticket?.user?.is_verified_account}
            src={avatar}
          />
          <div className="flex flex-col">
            <span className="text-prop text-lg font-medium text-grey_20">
              {username}
            </span>
            <span className="text-[13px] text-grey_30">{user_role_type}</span>
          </div>
        </div>

        {/* priority  */}
        <div className="mt-3 flex items-center gap-2">
          <h3 className="text-prop text-base font-normal text-grey_20">
            Ticket Priority:
          </h3>
          <div className="flex items-center gap-2">
            <span>
              <FlagIcon color={flagColor(priority)} />
            </span>
            <h3 className="text-prop text-base font-normal text-grey_20">
              {priority}
            </h3>
          </div>
        </div>

        {/* icon and engagement  */}
        <div className="my-6 flex items-center gap-2">
          {attachment && (
            <BootstrapTooltip title="This contains an attachment">
              <div className="grey-border-1px relative w-fit rounded-[8px] p-3">
                <LinkIcon stroke="#3B3D3B" />
              </div>
            </BootstrapTooltip>
          )}
          {engagement_id && (
            <Button
              endIcon={<LongArrowRightIcon />}
              onClick={() =>
                navigate(
                  `/${
                    user?.admin_permissions
                      ? "admin"
                      : convertToSlug(user?.user_type)
                  }/engagements/${engagement_id}`,
                )
              }
            >
              View Engagement
            </Button>
          )}
        </div>

        {/* date  */}
        <div>
          <p className="text-normal text-prop text-[14px] text-grey_30">
            Issued on:{" "}
            <span className="inline">
              {moment(created_at).format("MMM Do YYYY, h:mm a")}
            </span>
          </p>
        </div>
        {last_message && (
          <Chip
            label={`Last message: ${moment(last_message?.created_at).format(
              "MMM Do YYYY, h:mm a",
            )}`}
            sx={{borderRadius: "8px", mt: 3, width: "fit-content"}}
          />
        )}
      </div>

      <div className="flex flex-1 flex-col gap-4 rounded-20 border border-grey_60 bg-grey_80 p-4">
        <h3 className="text-prop font-Bai text-[24px] font-semibold">
          {title}
        </h3>
        <p className="max-h-[200px] max-w-[600px] overflow-hidden text-sm text-grey_30">
          {description}
        </p>
        <Button
          variant="contained"
          sx={{width: "fit-content"}}
          onClick={() => navigate(`${id}?tag=${ticket_tag}`)}
        >
          View Ticket
        </Button>
      </div>
    </div>
  );
};
export default TicketCards;
export const flagColor = priority => {
  switch (priority) {
    case "Low":
      return "#8585A3";
    case "Medium":
      return "#FFB72D";
    case "High":
      return "#D61E34";
    default:
      return "#8585A3";
  }
};
