import {Avatar, Button, Chip, IconButton, Skeleton} from "@mui/material";
import EditIcon2 from "components/Vectors/EditIcon2";
import {useState} from "react";
import ActionModal from "./ActionModal";
import {
  useActivateAdminMutation,
  useGetSingleAdminQuery,
} from "services/admin/adminGeneralApi";
import {useParams} from "react-router-dom";
import moment from "moment";
import {
  useSuspendAdminMutation,
  useDeleteAdminMutation,
} from "services/admin/adminGeneralApi";
import EditAdminModal from "./EditAdminModal";
import DropDownWrapper from "components/mui-components/DropDownWrapper";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon2 from "components/Vectors/DeleteIcon2";
import UserCheckIcon from "components/Vectors/UserCheckIcon";

const Admin = () => {
  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openActivateModal, setopenActivateModal] = useState(false);
  const [openEditAdminModal, setOpenEditAdminModal] = useState(false);
  const {id} = useParams();

  const {data: adminInfo, isLoading} = useGetSingleAdminQuery(id);
  const [activateAdmin, {isLoading: isActivateLoading}] =
    useActivateAdminMutation();
  const [deleteAdmin, {isLoading: isDeleteLoading}] = useDeleteAdminMutation();
  const [suspendAdmin, {isLoading: isSuspendLoading}] =
    useSuspendAdminMutation();

  return (
    <div className="flex flex-col-reverse justify-between md:flex-row md:items-start">
      <div className="relative flex flex-col gap-4 md:flex-row">
        <Avatar
          src={adminInfo?.data.photo_url || ""}
          alt="img"
          sx={{
            height: "60px",
            width: "60px",
            "@media (min-width: 780px)": {
              width: "120px",
              height: "120px",
            },
          }}
        />

        <div className="mt-3">
          <h2 className="mb-2 font-Bai text-[32px] font-semibold text-black">
            {isLoading ? (
              <Skeleton variant="rounded" width={210} height={35} />
            ) : (
              <span>
                {adminInfo?.data?.first_name} {adminInfo?.data?.last_name}{" "}
              </span>
            )}
          </h2>
          <p className="max-w-[350px] font-Bai text-lg font-medium text-grey_20 ">
            {isLoading ? (
              <Skeleton variant="rounded" width={100} height={35} />
            ) : (
              <>
                {adminInfo?.data.roles?.length < 0
                  ? " "
                  : adminInfo?.data.roles.map((role, index, arr) => (
                      <span key={role.id}>
                        {role.name}
                        {index !== arr.length - 1 ? ", " : ""}
                      </span>
                    ))}
              </>
            )}
          </p>
          <div className="mt-4 mb-8 flex items-center gap-3">
            <>
              {isLoading ? (
                <Skeleton variant="rounded" width={100} height={30} />
              ) : (
                <Chip
                  sx={{borderRadius: "8px"}}
                  color={
                    adminInfo?.data.status.toLowerCase() === "active"
                      ? "secondaryInverse"
                      : "greyBorder"
                  }
                  label={adminInfo?.data.status}
                />
              )}
            </>

            <p className="flex items-center gap-2 text-sm ">
              <span className="text-grey_30">Date Added:</span>{" "}
              <span className="text-grey">
                {isLoading ? (
                  <Skeleton variant="rounded" width={100} height={30} />
                ) : (
                  <> {moment(adminInfo?.data?.created_at).format("LL")}</>
                )}
              </span>
            </p>
          </div>

          <div className="rounded-24 bg-grey_80 px-8 py-6 md:-ml-32 md:-mr-10">
            <h3 className="font-Bai text-2xl font-semibold text-grey">
              Overview
            </h3>
            <div className="mt-4 mb-3 md:mt-8 md:mb-6">
              <span className="text-lg font-medium text-grey_30">
                Email Address
              </span>
              <p className="mt-1 font-medium text-black md:mt-2">
                {isLoading ? (
                  <Skeleton variant="rounded" width={250} height={40} />
                ) : (
                  <>{adminInfo?.data.email}</>
                )}
              </p>
            </div>
            <div>
              <span className="text-lg font-medium text-grey_30">Phone</span>
              <p className="mt-2 mb-3 font-medium text-black md:mt-2 md:mb-6">
                {isLoading ? (
                  <Skeleton variant="rounded" width={250} height={40} />
                ) : (
                  <>{adminInfo?.data.phone_number}</>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {adminInfo?.data.roles.find(
        role => role.name === "Super Admin",
      ) ? null : (
        <div className="mt-8 flex flex-wrap items-center justify-center gap-2 md:mt-0 md:flex-nowrap md:justify-start">
          {adminInfo?.data?.status.toLowerCase() === "pending" &&
            adminInfo?.data?.email_verified_at === null && (
              <Button
                onClick={() => setopenActivateModal(true)}
                color="greyDark"
                startIcon={<UserCheckIcon />}
              >
                Activate Account
              </Button>
            )}
          <IconButton
            onClick={() => setOpenEditAdminModal(prev => !prev)}
            size="large"
            sx={{
              bgcolor: "#E98100",
              borderRadius: "16px",
              "&:hover": {
                bgcolor: "#c77107",
              },
            }}
          >
            <EditIcon2 width={24} height={24} />
          </IconButton>
          <DropDownWrapper
            position="right"
            contentPadding="px-0"
            closeOnBtnClick={false}
            action={
              <IconButton
                size="large"
                sx={{
                  bgcolor: "#F2F4F7",
                  borderRadius: "16px",
                  "&:hover": {
                    bgcolor: "#F2F4F7",
                  },
                }}
              >
                <MoreHoriz color="primary" width={24} height={24} />
              </IconButton>
            }
          >
            <Button
              color="greyDark"
              startIcon={<BlockIcon />}
              onClick={() => {
                setOpenSuspendModal(true);
              }}
            >
              {adminInfo?.data?.status.toLowerCase() === "suspended"
                ? "Unsuspend Account"
                : "Suspend Account"}
            </Button>
            <Button
              startIcon={<DeleteIcon2 />}
              color="error"
              onClick={() => setOpenDeleteModal(true)}
            >
              Delete Admin
            </Button>
          </DropDownWrapper>
        </div>
      )}
      <ActionModal
        close={() => setOpenSuspendModal(prev => !prev)}
        openModal={openSuspendModal}
        action={
          adminInfo?.data?.status.toLowerCase() === "suspended"
            ? "unsuspend"
            : "suspend"
        }
        isLoading={isSuspendLoading}
        onConfirm={() =>
          suspendAdmin({
            id,
            type:
              adminInfo?.data?.status.toLowerCase() === "suspended"
                ? "unsuspend"
                : "suspend",
          }).unwrap()
        }
      />
      <ActionModal
        close={() => setOpenDeleteModal(prev => !prev)}
        action={"delete"}
        openModal={openDeleteModal}
        isLoading={isDeleteLoading}
        onConfirm={() => deleteAdmin(id).unwrap()}
      />
      <ActionModal
        close={() => setopenActivateModal(prev => !prev)}
        action={"activate"}
        openModal={openActivateModal}
        isLoading={isActivateLoading}
        onConfirm={() =>
          activateAdmin({
            id,
            reference_id: adminInfo?.data?.reference_id,
          }).unwrap()
        }
      />
      <EditAdminModal
        open={openEditAdminModal}
        setOpenEditAdminModal={setOpenEditAdminModal}
        adminData={adminInfo?.data}
        id={id}
        onClose={() => setOpenEditAdminModal(false)}
      />
    </div>
  );
};

export default Admin;
