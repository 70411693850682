import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import {useGetCompletedRefundHistoriesQuery} from "services/admin/adminGeneralApi";
import {toCurrency} from "utils";
import {useGetAllBanksQuery} from "services/generalApi";
import moment from "moment";

const RefundHistoryTab = props => {
  const {data, isLoading, error, isError} =
    useGetCompletedRefundHistoriesQuery();
  const {data: allBanks} = useGetAllBanksQuery();

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
  };

  // mock data to be updated by api
  const mockData = data?.data.map((item, i) => ({
    client: {
      name: item?.client?.full_name,
      avatar: item?.client?.avatar,
      searchBy: item?.client?.username,
    },
    amount: toCurrency(item?.engagement?.transaction?.amount),
    termination_date: moment(item?.engagement?.terminated_at).format(
      "DD MMMM, YYYY h:mma",
    ),
    date_paid: moment(item?.date_paid).format("DD MMMM, YYYY h:mma"),
    account: `${item?.client?.bank_account?.account_number} - 
    ${
      allBanks?.data?.find(
        bank => bank?.code === item?.client?.bank_account.bank_code,
      )?.name
    }`,
    transaction_ref: `#${item?.engagement?.transaction?.transaction_ref}`,
  }));

  return (
    <div>
      <div className=" w-full ">
        <MyDataTable
          isLoading={isLoading}
          error={error}
          isError={isError}
          scrollX={false}
          data={mockData}
          headCells={headCells}
          options={options}
          emptyData={{
            message: "No refund history found",
          }}
        />
      </div>
    </div>
  );
};

export default RefundHistoryTab;

const headCells = [
  {
    name: "client",
    label: "client",
    options: {
      customBodyRender: value => (
        <UserDisplay
          // imgRounded={false}
          name={value.name}
          img={value.avatar}
          size="text-[1rem]"
        />
      ),
    },
  },
  {
    name: "amount",
    label: "amount",
  },
  {
    name: "termination_date",
    label: "termination date",
  },
  {
    name: "date_paid",
    label: "date paid",
  },
  {
    name: "account",
    label: "account",
  },
  {
    name: "transaction_ref",
    label: "transaction ref",
  },
];
