import BackBtn from "components/BackBtn";
import UserDetailCard from "components/UserDetailCard";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import JobReceived from "./Components/JobReceived";
import JobRequestMedia from "./Components/JobRequestMedia";
import JobRequestOverview from "./Components/JobRequestOverview";
import RequestOffers from "./Components/RequestOffer";

const EachAdminJobRequest = () => {
  return (
    <DashboardLayout title={"Job Offers"} type="admin">
      <section className="mt-2">
        <BackBtn text="All Job Offers" />
        <div className="rounded-2xl bg-white p-4 md:p-8">
          <div className="flex items-center justify-between">
            <h2 className="settings-font-h2  md:max-w-[500px]">
              I will repair everything that is faulty in your house
            </h2>
          </div>

          <div className="mt-[24px] grid grid-cols-1 gap-[24px] md:grid-cols-2 ">
            <div className="flex flex-col gap-[24px] ">
              <UserDetailCard
                headerText={"Received by Client"}
                link="/admin/clients/1234"
              />
              <JobRequestOverview />
              <JobRequestMedia />
            </div>
            <div className="flex flex-col gap-6">
              <JobReceived
                link="/admin/clients/1234"
                headerText={"Sent by Service Provider"}
              />
              <RequestOffers />
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};
export default EachAdminJobRequest;
