function FlagIcon(props) {
  return (
    <svg
      width={props.width || "19"}
      height={props.height || "22"}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 13C2.5 13 1.5 14 1.5 14V3C1.5 3 2.5 2 5.5 2C8.5 2 10.5 4 13.5 4C16.5 4 17.5 3 17.5 3V14C17.5 14 16.5 15 13.5 15C10.5 15 8.5 13 5.5 13Z"
        fill={props.color || "#8585A3"}
      />
      <path
        d="M1.5 14C1.5 14 2.5 13 5.5 13C8.5 13 10.5 15 13.5 15C16.5 15 17.5 14 17.5 14V3C17.5 3 16.5 4 13.5 4C10.5 4 8.5 2 5.5 2C2.5 2 1.5 3 1.5 3M1.5 21L1.5 1"
        stroke={props.stroke || "#1D1D1D"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FlagIcon;
