export default function LinkIcon({stroke}) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.47154 12.7423L7.52874 13.6851C6.22699 14.9868 4.11644 14.9868 2.81469 13.6851C1.51294 12.3834 1.51294 10.2728 2.81469 8.97106L3.7575 8.02825M12.2428 8.97106L13.1856 8.02825C14.4873 6.7265 14.4873 4.61595 13.1856 3.3142C11.8838 2.01245 9.77329 2.01245 8.47154 3.3142L7.52874 4.25701M5.66681 10.833L10.3335 6.1663"
        stroke={stroke || "#3B3D3B"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
