import {Button, CircularProgress} from "@mui/material";
import {PrevBtn} from "..";
import {ReactComponent as Guard} from "assets/icons/guard.svg";
import {useForm, FormProvider} from "react-hook-form";
import {handleError} from "utils";
import {useSubmitKycInfoMutation} from "services/user/userRegApi";
import {useGetUser} from "hooks/getUserHook";
import {LoadingButton} from "@mui/lab";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import CustomFileUploader from "components/forms/CustomFileUploader";
import {showToast} from "redux/store.hooks";
import ValidatedRadioBtns from "components/forms/ValidatedRadioBtns";
import {useGetUserRequest} from "hooks/useGetUserRequest";

const KYC = ({onNextClick, onPrevClick, isServiceProvider}) => {
  const {user: currentUser} = useGetUser();
  const {refetch} = useGetUserRequest();
  const methods = useForm({
    mode: "all",
  });

  const {
    watch,
    formState: {errors},
  } = methods;
  const id_type = watch("id_type");
  const [submitInfo, {isLoading}] = useSubmitKycInfoMutation();

  const onSubmit = async data => {
    const formData = new FormData();
    formData.append("id_type", data?.id_type);
    formData.append("front_image", data?.front);
    formData.append("back_image", data?.back);
    if (data?.cac) {
      formData.append("cac_cert", data?.cac);
    }

    try {
      let res = await submitInfo(formData).unwrap();
      showToast(res?.message, "success");
      onNextClick();
      refetch();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <section className="w-full rounded-24 bg-white py-10">
      <div className="flex flex-col items-start px-6 md:px-8">
        <h1 className="text-prop font-Bai text-2xl font-bold md:text-[2rem]">
          KYC
        </h1>
        <Button
          sx={{
            padding: "0 !important",
            justifyContent: "unset !important",
          }}
          onClick={onNextClick}
        >
          Skip
        </Button>
      </div>
      <div className="my-4 w-full border-t-[1px] border-grey_60 md:my-5"></div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="mb-16 px-6 md:mb-28 md:px-8">
            <p className="mb-8 flex items-center gap-x-2 rounded-20 bg-[#F6FEF9] p-4 font-Mulish text-base text-grey_20">
              <Guard />
              <span className="max-w-[450px]">
                We value safety and we are taking it very serious.
              </span>
            </p>
            {/* CAC Certificate */}
            {isServiceProvider &&
              currentUser?.service_provider_type?.toLowerCase() !==
                "unregistered service provider" && (
                <>
                  <p className="font-Bai text-lg font-medium text-grey md:text-xl">
                    CAC Certificate
                  </p>
                  <label className="text-sm font-semibold text-[#8585A3]"></label>

                  <CustomFileUploader
                    required={true}
                    name="cac"
                    label="Upload Your CAC Certificate"
                    types={["png", "jpg", "jpeg"]}
                  />
                </>
              )}
            {/* ID */}
            <div className="mt-8">
              <p className="mb-2 font-Bai text-lg font-medium text-grey md:text-xl">
                Upload a Government Issued ID
              </p>
              <div>
                <label
                  htmlFor="id_type"
                  className="mb-[.8rem] text-[.8rem] font-bold text-[#3B3D3B] md:text-[1rem] "
                >
                  Select your preferred valid ID image (international Passport,
                  NIN, drivers license, voters card).
                </label>
                <ValidatedRadioBtns
                  flexDirection="column"
                  name="id_type"
                  options={[
                    {
                      label: "International Passport",
                      value: "international-passport",
                    },
                    {label: "Driver’s License", value: "drivers-license"},
                    {label: "Voter’s Card", value: "voters-card"},
                  ]}
                />
              </div>
              {errors.id_type && (
                <div
                  className={` ${
                    !errors.id_type && "hidden"
                  } input-err-msg pt-[-10rem] `}
                >
                  {errors.id_type.message || ""}
                </div>
              )}
            </div>
            {id_type && (
              <div className="mt-2 flex flex-col gap-4 ">
                {/* Front */}
                <CustomFileUploader
                  required={true}
                  name="front"
                  label="Upload Front"
                  types={["png", "jpg", "jpeg"]}
                />

                {/* Back */}
                <CustomFileUploader
                  required={true}
                  name="back"
                  label="Upload Back"
                  types={["png", "jpg", "jpeg"]}
                />
              </div>
            )}
          </div>
          <div className="flex items-stretch justify-start gap-x-6 px-6 md:px-8">
            <PrevBtn onClick={onPrevClick} />
            <LoadingButton
              loading={isLoading}
              loadingIndicator={
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size="1.2rem"
                />
              }
              endIcon={<EastRoundedIcon fontSize="large" />}
              variant="contained"
              type="submit"
            >
              Next
            </LoadingButton>
          </div>
        </form>
      </FormProvider>
    </section>
  );
};
export default KYC;
