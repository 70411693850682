import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import {Chip} from "@mui/material";
import UserAvatar from "components/mui-components/TableComponent/UserAvatar";
import moment from "moment";
import {Link} from "react-router-dom";
import {JobsStatusColor} from "utils/constants";

const ActiveEngagementCard = ({engagement}) => {
  return (
    <Link
      to={`/admin/engagements/${engagement?.id}`}
      className="rounded-2xl bg-grey_80 p-4"
    >
      <div className="mb-4 flex gap-3">
        <img
          src={
            engagement?.job_without_relationship?.images[0]?.image_url ||
            "/images/broken-image.png"
          }
          alt={engagement?.job_without_relationship?.job_title}
          className="h-[64px] w-[64px] rounded-[12px] object-cover"
        />
        <div>
          <p className="text-base font-semibold text-grey">
            {engagement?.job_without_relationship?.job_title}
          </p>
          <Chip
            sx={{fontSize: "11px", p: "1px !important"}}
            color={JobsStatusColor[engagement?.status?.toLowerCase()]}
            label={engagement?.status.replace("Active", "Open")}
          />
        </div>
      </div>

      <div className="mb-2 flex gap-2">
        <UserAvatar src={engagement?.client?.avatar} alt="client" size={24} />
        <p className="flex items-center text-base font-medium text-grey_20">
          {engagement?.client?.full_name}{" "}
          <span className="text-sm font-normal text-grey_40">
            &nbsp; - Client
          </span>
        </p>
      </div>
      <div className="mb-2 flex gap-2">
        <UserAvatar
          src={
            engagement?.service_provider?.professional_information
              ?.business_logo || engagement?.service_provider?.avatar
          }
          alt="service provider"
          size={24}
        />
        <p className="text-base font-medium text-grey_20">
          {engagement?.service_provider?.professional_information
            ?.business_name || engagement?.service_provider?.full_name}{" "}
          <span className="text-sm font-normal text-grey_40">
            &nbsp; - Service Provider
          </span>
        </p>
      </div>

      <div className="item-center mt-1 flex gap-3">
        {engagement?.start_date && engagement?.end_date ? (
          <div className="flex gap-x-2.5">
            <p className="w-full items-center gap-1 text-xs text-grey_30">
              <DateRangeOutlinedIcon />{" "}
              {moment(engagement?.start_date).format("DD MMM YYYY")}{" "}
              <span className="mx-1 inline-block text-black"> to </span>{" "}
              {moment(engagement?.end_date).format("DD MMM YYYY")}
            </p>
            {engagement.status?.toLowerCase() !== "completed" && (
              <Chip
                color="successInverse"
                label={`${moment(engagement?.end_date).diff(
                  moment(engagement?.start_date),
                  "days",
                )} days Left`}
                size="small"
                sx={{
                  fontSize: {xs: "10px", md: "12px", borderRadius: "20px"},
                }}
              />
            )}
          </div>
        ) : (
          <p className="space-x-2 text-xs text-grey_30">
            <DateRangeOutlinedIcon /> Not set yet
          </p>
        )}
      </div>
    </Link>
  );
};

export default ActiveEngagementCard;
