import CustomLink from "components/CustomRouteLink";
import Pusher from "pusher-js";
import {createElement, useEffect, useState} from "react";
import "./style.scss";
import {Badge, Button, IconButton, Tooltip} from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {Link, Outlet} from "react-router-dom";
import LogOutIcon from "components/Vectors/logout-icon";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LogoIcon from "components/Vectors/LogoIcon";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProfessionalMenuLinks from "./widgets/ProfessionalMenuLinks";
import AdminMenuLinks from "./widgets/AdminMenuLinks";
import SingleLogoIcon from "components/Vectors/SingleLogoIcon";
import SettingsIcon from "components/Vectors/settings-icon";
import HomeIcon2 from "components/Vectors/homeIcon-2";
import AddNewClientModal from "pages/Admin/Dashboard/Client/Modals/AddNewClientModal";
import AddNewServiceProviderModal from "pages/Admin/Dashboard/ServiceProviders/Modals/AddNewServiceProviderModal";
import CreateAdminModal from "pages/Admin/Dashboard/Administrators/CreateAdminModal";
import {logOutHandler} from "redux/store.hooks";
import {useGetUser} from "hooks/getUserHook";
import LogOutModal from "./widgets/LogOutModal";
import ErrorMsg from "components/mui-components/ErrorMsg";
import CircularLoader from "components/Loader/CircularLoader";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import {BootstrapTooltip} from "components/TicketCards";
import {useGetAllAdminNotificationQuery} from "services/admin/adminGeneralApi";
function DashboardLayout({
  type = "admin",
  children,
  title,
  showSearchBar,
  error,
  isLoading,
  ...props
}) {
  const {user} = useGetUser();
  const {data, refetch} = useGetAllAdminNotificationQuery({
    params: {
      is_paginated: "yes",
    },
  });
    useEffect(() => {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: "mt1",
        encrypted: true,
      });
      const channel = pusher.subscribe("admin-in-app-notification-received");
      channel.bind("admin-in-app-notification-event", data => {
        refetch();
      });
      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }, [refetch]);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [subMenuOpened, setSubMenuOpened] = useState("");
  const [addClientModal, setAddClientModal] = useState(false);
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [addProviderModal, setAddProviderModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);

  const isSuperAdmin = user?.roles?.some(item =>
    item.name.toLowerCase().includes("super admin"),
  );

  const LINKS =
    type === "admin"
      ? isSuperAdmin
        ? AdminMenuLinks
        : AdminMenuLinks.filter(link =>
            user?.admin_permissions?.some(permission =>
              permission.name.toLowerCase().includes(link.name.toLowerCase()),
            ),
          )
      : type === "professional"
      ? ProfessionalMenuLinks
      : ProfessionalMenuLinks;

  return (
    <div className="DashboardLayout flex max-h-screen items-stretch overflow-hidden bg-[#eeeeee] px-3 py-0 md:pl-6 lg:py-6">
      {/* aside */}
      <aside
        className={`scrollbar-style white-scrollbar fixed top-0 left-0 z-[50] flex max-h-screen min-h-screen w-[70%] min-w-[80px] max-w-[290px] flex-col border border-grey_70 bg-[#ffffff] px-6 pt-10 text-secondary transition-all  duration-500 lg:sticky lg:min-w-[290px] lg:rounded-2xl ${
          isSideBarOpen
            ? "left-0 w-[70%] lg:min-w-[290px]"
            : "-left-[100vw] lg:left-0 lg:w-[100px]"
        }  `}
      >
        {/* logo */}
        <div className="logo mx-auto hidden w-fit pb-6 lg:block">
          <Link to={`/${type}`}>
            <LogoIcon width="149" height="39" />
          </Link>
        </div>
        <Link
          to={`/${type}/account`}
          className="flex items-center justify-between px-2 pb-3 text-white lg:hidden"
        >
          <UserDisplay
            textWhite
            size="medium"
            name={`${user?.first_name} ${user?.last_name}`}
            img={user?.profile_picture}
            email={
              user?.roles?.length > 0 ? user?.roles[0]?.name || "N/A" : "N/A"
            }
          />
          <ArrowForwardIosIcon color="white" fontSize="small" />
        </Link>

        {/* links */}
        {/* <p className="font-medium text-sm text-grey_20 mt-3">Main Menu</p> */}
        <ul
          style={{maxHeight: "calc(100vh - 40px)"}}
          className={`max-w-unset mr-auto min-w-full flex-grow overflow-y-auto`}
        >
          {LINKS.map(({icon, name, link, border, submenu}) => (
            <li
              onClick={() => {
                submenu && setIsSubMenuOpen(!isSubMenuOpen);
                submenu && setSubMenuOpened(name);
              }}
              key={name}
              className="min-w-[52px]"
            >
              <CustomLink  baseUrl={`/${type}/`} to={link || `/${type}/`}>
                <Tooltip placement="right-end" title={name}>
                  <div>
                    {createElement(icon, {
                      width: 24,
                      height: 24,
                      color: "#3b3b3b",
                    })}
                  </div>
                </Tooltip>
                <span className={`ml-4 duration-200`}>{name}</span>
                {submenu &&
                  isSideBarOpen &&
                  (isSubMenuOpen && subMenuOpened === name ? (
                    <KeyboardArrowUpIcon className={`ml-auto`} />
                  ) : (
                    <KeyboardArrowDownIcon className={`ml-auto`} />
                  ))}
              </CustomLink>
              {submenu && subMenuOpened === name && isSubMenuOpen && (
                <ul
                  className={`ml-7 border-l pl-8  duration-200 ${
                    !isSideBarOpen ? "hidden" : "block"
                  }`}
                >
                  {submenu.map(el => (
                    <li key={el.name}>
                      <CustomLink baseUrl={`/${type}/`} to={el.link}>
                        <span>{el.name}</span>
                      </CustomLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
          <p className="-mb-3 mt-8 text-sm font-medium text-grey_20">
            Preference
          </p>
          {/* logout section */}
          {(isSuperAdmin ||
            type === "professional" ||
            user?.admin_permissions?.some(permission =>
              permission.name.toLowerCase().includes("settings"),
            )) && (
            <li className="min-w-[52px]">
              <CustomLink baseUrl={`/${type}/`} to={"settings"}>
                <Tooltip placement="right-end" title={"Settings"}>
                  <div>
                    {createElement(SettingsIcon, {width: 24, height: 24})}
                  </div>
                </Tooltip>
                <span className="ml-4">Settings</span>
              </CustomLink>
            </li>
          )}

          <li
            className={`${
              type === "professional" ? "hidden" : ""
            } -mt-3 min-w-[52px]`}
          >
            <CustomLink onClick={() => setMessageModal(true)}>
              <Tooltip placement="right-end" title={"Log out"}>
                <div>{createElement(LogOutIcon, {width: 24, height: 24})}</div>
              </Tooltip>
              <span className="ml-4">Log Out</span>
            </CustomLink>
          </li>
          <li
            className={`${
              type === "professional" ? "" : "hidden"
            } -mt-3 min-w-[52px]`}
          >
            <CustomLink baseUrl={`/${type}/`} to={`/${type}/`}>
              <Tooltip placement="right-end" title={"Home Page"}>
                <div>{createElement(HomeIcon2, {width: 24, height: 24})}</div>
              </Tooltip>
              <span className="ml-4">Home Page</span>
            </CustomLink>
          </li>
        </ul>
      </aside>

      {/* main */}

      <div
        id="scroller"
        className={` scrollbar-style  relative flex min-h-screen flex-col overflow-y-auto md:pr-3 lg:pl-6 ${
          props.overflow_Y_hidden && "overflow-y-hidden"
        } flex-grow overflow-x-hidden`}
      >
        {/* mobile nav */}
        <nav className="bg-secondary-dark sticky top-0 z-[70] mb-4 flex items-center justify-between border-b bg-black bg-opacity-30 py-4 px-4 lg:hidden">
          <Link to={`/${type}`}>
            <SingleLogoIcon width={45} height={45} />
          </Link>
          <div className="flex gap-2">
            <IconButton
              component={Link}
              to={`/${type}/notifications`}
              sx={{backgroundColor: "#F9F9FB"}}
            >
              <Badge color="primary" badgeContent={data?.unread_notifications}>
                <NotificationsNoneOutlinedIcon sx={{color: "#000000"}} />
              </Badge>
            </IconButton>
            <IconButton
              sx={{backgroundColor: "#ffffff19"}}
              onClick={() => setIsSideBarOpen(prev => !prev)}
            >
              {!isSideBarOpen ? (
                <MenuIcon color="white" />
              ) : (
                <CloseIcon color="white" />
              )}
            </IconButton>
          </div>
        </nav>

        {/* desktop nav */}
        <nav className="mb-6 hidden items-center justify-between rounded-2xl bg-white px-6 py-3 lg:flex">
          <div className="font-Bai text-2xl font-semibold text-grey_20">
            {title}
          </div>
          <div className="ml-2 flex flex-1 items-center justify-end gap-4">
            {isSuperAdmin && (
              <Button
                onClick={() => setAddClientModal(prev => !prev)}
                variant="contained"
                color="primaryInverse2"
                sx={{
                  minWidth: "max-content",
                }}
              >
                Add Client
              </Button>
            )}
            {isSuperAdmin && (
              <Button
                onClick={() => setAddProviderModal(prev => !prev)}
                variant="contained"
                color="primaryInverse2"
                sx={{
                  minWidth: "max-content",
                }}
              >
                Add Service Provider
              </Button>
            )}
            {isSuperAdmin && (
              <Button
                onClick={() => setAddAdminModal(prev => !prev)}
                variant="contained"
                color="primaryInverse2"
                sx={{
                  minWidth: "max-content",
                }}
              >
                Add Admin
              </Button>
            )}

            <BootstrapTooltip title="Visit website">
              <IconButton
                component={Link}
                to={`/`}
                sx={{backgroundColor: "#F9F9FB"}}
              >
                <HomeOutlinedIcon sx={{color: "#000000"}} />
              </IconButton>
            </BootstrapTooltip>
            <IconButton
              component={Link}
              to={`/${type}/notifications`}
              sx={{backgroundColor: "#F9F9FB"}}
            >
              <Badge color="primary" badgeContent={data?.unread_notifications}>
                <NotificationsNoneOutlinedIcon sx={{color: "#000000"}} />
              </Badge>
            </IconButton>
          </div>
          <div className="ml-6 flex items-center justify-end border-l border-grey_60 pl-6">
            <Link to={`/${type}/account`}>
              <UserDisplay
                name={user?.first_name + " " + user?.last_name || "N/A"}
                email={
                  user?.roles.length > 0 ? user?.roles[0].name || "N/A" : "N/A"
                }
                img={user?.photo_url || ""}
                isOnline={false}
                reverse
                highlight
                size="medium"
              />
            </Link>
          </div>
        </nav>
        {error ? (
          <ErrorMsg error={error} />
        ) : isLoading ? (
          <CircularLoader />
        ) : (
          <div className="flex-grow pb-12">{children || <Outlet />}</div>
        )}
      </div>

      {/* modals  */}
      <AddNewClientModal
        openModal={addClientModal}
        setOpenModal={setAddClientModal}
      />

      <AddNewServiceProviderModal
        openModal={addProviderModal}
        setopenModal={setAddProviderModal}
      />

      <CreateAdminModal
        open={addAdminModal}
        onClose={() => setAddAdminModal(false)}
      />
      <LogOutModal
        open={messageModal}
        close={() => setMessageModal(false)}
        onLogout={() => logOutHandler("admin")}
        btnText="Cancel"
      />
    </div>
  );
}

export default DashboardLayout;
