import {useState} from "react";
import moment from "moment";
import {Button} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import {StarRounded} from "@mui/icons-material";

export const EachReview = ({review}) => {
  return (
    <div key={review?.id} className="w-full rounded-2xl bg-grey_80 p-4">
      <div className="flex justify-between">
        <UserDisplay
          name={
            review?.rated_by?.user_type?.toLowerCase() === "client"
              ? review?.rated_by?.full_name
              : review?.rated_by?.professional_information?.business_name ||
                review?.rated_by?.full_name
          }
          img={
            review?.rated_by?.user_type?.toLowerCase() === "client"
              ? review?.rated_by?.avatar
              : review?.rated_by?.professional_information?.business_logo
          }
          email={moment(review?.created_at).fromNow()}
        />

        <div className="flex w-fit items-center gap-0.5">
          <StarRounded color="primary" fontSize="small" />
          <p className="text-[13px] font-medium text-grey_20">
            {Number(review?.rating || "0")?.toFixed(1)}
          </p>
        </div>
      </div>

      <p className="mt-3 text-base font-normal text-grey_20">
        {review?.rating_message}
      </p>
    </div>
  );
};

function Reviews({reviews}) {
  const [reviewCount, setReviewCount] = useState(3);
  return (
    <div className="flex flex-col items-center rounded-2xl bg-white p-4 md:p-6">
      <h1 className="mb-2 font-Bai text-4xl font-medium text-black">
        {reviews?.length} Review{reviews?.length <= 1 ? "" : "s"}
      </h1>

      <div className="mb-4 flex w-full flex-col gap-4">
        {reviews?.slice(0, reviewCount).map(item => (
          <EachReview review={item} />
        ))}
      </div>
      {reviewCount < reviews?.length && (
        <Button
          onClick={() => setReviewCount(count => count + 1)}
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
        >
          See More
        </Button>
      )}
    </div>
  );
}

export default Reviews;
