const MainTokenIcon = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9997 1.45825L9.12467 9.12492L1.45801 11.9999L9.12467 14.8749L11.9997 22.5416L14.8747 14.8749L22.5413 11.9999L14.8747 9.12492L11.9997 1.45825Z"
        fill={props.color || "white"}
      />
    </svg>
  );
};
export default MainTokenIcon;
