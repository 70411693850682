import { Chip, IconButton, Skeleton } from "@mui/material";
import UserIcon from "components/Vectors/user";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";
import { Link } from "react-router-dom";
import { useDashboardStatQuery, useGetAllUnidentifiedUsersQuery } from "services/admin/adminGeneralApi";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import moment from "moment";
import { useState } from "react";
import AddNewUserModal from "./Modal/AddNewUserModal";

const AdminUsers = () => {
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const { data, isLoading: isUsersLoading, isError: isUsersError, error: usersError } = useGetAllUnidentifiedUsersQuery();
  const { data: stats, isLoading, isError } = useDashboardStatQuery();

  const headCells = [
    {
      name: "user",
      label: "User",
      options: {
        customBodyRender: value => (
          <UserDisplay name={value.name} img={value.img} />
        ),
      },
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "phone_number",
      label: "Phone Number",
    },
    {
      name: "date_joined",
      label: "Date Joined",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: value => (
          <Chip
            sx={{ borderRadius: "8px" }}
            color={
              value.toLowerCase() === "active"
                ? "secondaryInverse"
                : "greyBorder"
            }
            label={value}
          />
        ),
      },
    },
    {
      name: "action",
      label: "",
    },
  ];



const sortedData = (data?.data || []).slice().sort((a, b) => new Date(b.created_at)- new Date(a.created_at) );


  const mockData = sortedData?.map((item, i) => ({
    user: {
      name: `${item.first_name} ${item.last_name}`,
      searchBy: item?.username,
      img: item?.avatar,
    },
    type: item?.is_hybrid === "Yes" ? "Hybrid" : item.user_type || 'Uncategorized',
    email: item?.email || 'N/A',
    phone_number: item?.phone_number || 'N/A',
    date_joined: moment(item?.created_at).format('DD MMM YY, hh:mm a'),
    status: item?.status,
    id: `row_${i}`,
    action: (
      <IconButton component={Link} to={`/admin/users/${item?.id}`}>
        <ArrowCircleRightOutlinedIcon color="primary" />
      </IconButton>
    ),
  }));

  return (
    <DashboardLayout title={"Users"} type="admin">
      <main>
        <div className="rounded-2xl bg-white p-4 md:py-6">
          <h3 className="font-Bai mb-4 text-2xl font-medium text-black">
            Overview
          </h3>

          <div className="boxes grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
            <DashboardBox
              text={"All Users"}
              icon={<UserIcon stroke={"#E98100"} />}
              value={
                isLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : isError ? (
                  "..."
                ) : (
                  String(stats?.data?.users?.total)
                )
              }
            />
            <DashboardBox
              text={"Clients"}
              icon={<UserIcon stroke={"#039855"} />}
              value={
                isLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : isError ? (
                  "..."
                ) : (
                  String(
                    stats?.data?.users?.clients
                  )
                )
              }
            />
            <DashboardBox
              text={"Service Providers"}
              icon={<UserIcon stroke={"#5010B3"} />}
              value={
                isLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : isError ? (
                  "..."
                ) : (
                  String(
                    stats?.data?.users?.services_providers
                  )
                )
              }
            />
            <DashboardBox
              text={"Uncategorized"}
              icon={<UserIcon stroke={"#3B3D3B"} />}
              value={
                isUsersLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : isError ? (
                  "..."
                ) : (
                  String(
                    data?.data?.length
                  )
                )
              }
            />
          </div>
        </div>

        <div className="mt-6 rounded-2xl bg-white p-4 md:py-6">
          <MyDataTable
            options={{
              viewColumns: false,
            }}
            scrollX={false}
            data={mockData}
            isLoading={isUsersLoading}
            isError={isUsersError}
            error={usersError}
            hideChipDot
            headCells={headCells}
          />

        </div>
      </main>
      <AddNewUserModal open={showAddUserModal} close={() => setShowAddUserModal(false)} />
    </DashboardLayout>
  );
}

export default AdminUsers;
