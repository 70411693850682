import { MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useRef, useState } from "react";
import SearchInput from "components/forms/SearchInput";

const FilterDropDown = ({
  choice,
  setChoice,
  selectOption,
  name,
  isSearch = false,
  optionIcon,
  isLoading = false,
  onSelect,
  bg,
}) => {
  const [openDropDown, setopenDropDown] = useState(false);
  const [searchQuery, setsearchQuery] = useState("");

  const filteredOptions = selectOption.filter(option =>
    (option?.value || option) === ""
      ? option
      : typeof option !== "string"
        ? option?.label?.toLowerCase().includes(searchQuery?.toLowerCase())
        : option?.toLowerCase().includes(searchQuery?.toLowerCase()),
  );

  const onsearchQueryChange = event => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setsearchQuery(searchFieldString);
  };

  const ref = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (openDropDown && ref.current && !ref.current.contains(e.target)) {
        setopenDropDown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [openDropDown]);
  return (
    <div ref={ref} className="relative flex flex-col gap-3">
      <button
        onClick={e => {
          e.stopPropagation();
          setopenDropDown(prev => !prev);
        }}
        type="button"
        className={`filter-select relative flex items-center gap-x-0.5 rounded-xl ${bg || 'bg-grey_80'} p-3 text-sm capitalize md:p-4 md:text-base`}
      >
        <span className="text-grey_30">{name}: </span>
        <span className="ml-[8px] text-grey ">
          {choice[name] || selectOption[0]?.label}
        </span>
        <span className="ml-4">
          {openDropDown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </span>
      </button>
      {openDropDown && (
        <div className="box-shdw absolute top-[70px] right-0 z-[100] flex w-fit flex-col justify-between gap-x-0.5 rounded-xl bg-grey_80 p-1 text-sm capitalize md:w-[220px] md:p-2 md:text-base">
          {isSearch && (
            <SearchInput
              bg="#fff"
              placeholder={`Search ${name}`}
              maxWidth="none"
              value={searchQuery}
              onChange={e => onsearchQueryChange(e)}
            />
          )}
          <div>
            <div className="scrollbar-style mt-3 flex h-[150px] w-full flex-col gap-[5px] overflow-y-auto overflow-x-hidden">
              {filteredOptions?.length > 0 ? (
                filteredOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option?.value}
                    onClick={() => {
                      setChoice({
                        ...choice,
                        [name]:
                          typeof option !== "string" ? option?.value : option,
                      });
                      setopenDropDown(false);
                      if (onSelect) {
                        onSelect(option);
                      }
                    }}
                    sx={{
                      color: "#6E726E",
                      display: "flex",
                      gap: "4px",
                      paddingBlock: "5px !important",
                      paddingInline: "4px !important",
                      alignItems: "center",
                      fontSize: "14px",
                      borderRadius: "4px",
                    }}
                  >
                    {optionIcon && <span>{optionIcon}</span>}
                    {option?.label || option}
                  </MenuItem>
                ))
              ) : (
                <div className=" flex h-[80px] flex-col items-center justify-center gap-3 ">
                  {optionIcon && <span>{optionIcon}</span>}
                  <p className="text-[14px] font-semibold capitalize text-[#6E726E] ">
                    {isLoading
                      ? `${name} loading`
                      : searchQuery
                        ? `${name} not found`
                        : `${name} not available`}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterDropDown;
