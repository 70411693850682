import { useState } from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FileIcon from "components/Vectors/FileIcon";
import { Button, Modal } from "@mui/material";
import MyModal from ".";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
// import CreationCompletedIcon from "components/Vectors/CreationCompletedIcon";
import { useSwitchAccountMutation } from "services/user/userRegApi";
import { useDispatch } from "react-redux";
import { setUserDetails } from "redux/slice/AuthSlice";
import { handleError } from "utils";
// import MessageModal from "./MessageModal";
// import LoadingBtn from "../LoadingButton";
import { LoadingButton } from "@mui/lab";

const OnboardingModal = ({ open, close }) => {
  const [active, setActive] = useState(1);
  const handleNext = () => {
    setActive(active + 1);
    // if (active === 3) setShowDoneModal(true);
  };
  const [switchAccount, { isLoading: isSwitchingAccount }] =
    useSwitchAccountMutation();
  const dispatch = useDispatch();
  const slides = [1, 2, 3];

  return (
    <Modal open={open} onClose={close}>
      <MyModal openModal={open} closeModal={close} maxWidth={450}>
        <div className="mt-16 flex flex-col items-center gap-y-4">
          <div className="overflow-x-hidden">
            {slides.map((item, i) => (
              <Slide open={item === active} key={i} index={item} />
            ))}
          </div>
          <div className="flex items-center gap-4">
            {slides.map(item => (
              <button
                onClick={() => setActive(item)}
                key={item}
                className={`h-2 w-2 rounded-full transition-all duration-300 ${item === active ? "bg-primary" : "bg-grey_60"
                  }`}
              ></button>
            ))}
          </div>
          <div className="flex w-full items-center gap-x-5">
            {active !== 3 && (
              <Button
                variant="outlined"
                onClick={
                  active === 1 ? () => close() : () => setActive(active - 1)
                }
                startIcon={active === 1 ? null : <WestOutlinedIcon />}
                sx={{ flexBasis: "100%" }}
              >
                {active === 1 ? "Cancel" : "Previous"}
              </Button>
            )}
            <LoadingButton
              loading={isSwitchingAccount}
              variant="contained"
              endIcon={active === 3 ? null : <EastOutlinedIcon />}
              onClick={() => {
                active === 3
                  ? switchAccount()
                    .unwrap()
                    .then(res => {
                      close();
                      dispatch(setUserDetails(res?.data));
                      window.location.href = "/user/upgrade-account/1";
                    })
                    .catch(err => {
                      handleError(err);
                    })
                  : handleNext();
              }}
              sx={{ flexBasis: "100%" }}
            >
              {active === 3 ? "Proceed" : "Next"}
            </LoadingButton>
          </div>
          {/* <DoneModal
          open={showDoneModal}
          close={() => {
            setShowDoneModal(false);
          }}
        /> */}
        </div>
      </MyModal>
    </Modal>
  );
};

export default OnboardingModal;

const Slide = ({ open, index }) => {
  const itemIcon = {
    1: (
      <AccountCircleOutlinedIcon
        color="primary"
        sx={{ width: "2rem", height: "2rem" }}
      />
    ),
    2: <FileIcon width="2rem" height="2rem" stroke="#E98100" />,
    3: <FileIcon width="2rem" height="2rem" stroke="#E98100" />,
  };
  return (
    <div
      className={`flex flex-col items-center gap-y-4 overflow-x-hidden  transition-all ${open
        ? "translate-x-0"
        : "h-0 translate-x-[100rem] overflow-hidden opacity-0"
        }`}
    >
      <div className="flex h-16 w-16 items-center justify-center rounded-full bg-[#F2F4F7]">
        {itemIcon[index]}
      </div>
      <p className="mb-2 text-center font-Bai text-3xl font-semibold">
        {index === 1
          ? "Become a Service Provider"
          : index === 2
            ? "Quam eleifend"
            : "Euismod consequat Service Provider"}
      </p>
      <p className="max-w-md text-center font-medium text-grey_30">
        As a Service Provider You want to carry out tasks for clients and earn
        money.
      </p>
    </div>
  );
};

// const DoneModal = ({open, close}) => {
//   const [switchAccount, {isLoading: isSwitchingAccount}] =
//     useSwitchAccountMutation();
//   const dispatch = useDispatch();

//   return (
//     <MessageModal
//       openModal={open}
//       closeModal={close}
//       icon={<CreationCompletedIcon />}
//       loading={isSwitchingAccount}
//       cta={
//         <LoadingBtn
//           fullWidth
//           loading={isSwitchingAccount}
//           onClick={() => {
//             switchAccount()
//               .unwrap()
//               .then(res => {
//                 close();
//                 dispatch(setUserDetails(res?.data));
//                 window.location.href = "/user/upgrade-account/1";
//               })
//               .catch(err => {
//                 handleError(err);
//               });
//           }}
//         >
//           Proceed
//         </LoadingBtn>
//       }
//       title="You are now a Client"
//       description=""
//     />
//   );
// };
