import {Button, Chip} from "@mui/material";
import {useState} from "react";
import EditCapabilityModal from "../Modals/EditCapabilityModal";
import {StatusChip} from "../SingleCategoryPage";

const SingleCapacityCard = ({exp}) => {
  const [showFull, setShowFull] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);
  const descLength = exp?.description.length > 120;

  return (
    <section className="rounded-16 bg-grey_80 p-5 md:p-6">
      <div className="mb-5 flex  items-center justify-between gap-2">
        <h3 className="font-Bai text-xl font-semibold text-grey_20 md:text-2xl">
          {exp.name || ""}
        </h3>
        <Chip
          sx={{borderRadius: "8px !important"}}
          label={exp?.status}
          color={StatusChip[exp.status?.toLowerCase()]}
        />
      </div>

      <p className={`$ text-sm text-grey_20 transition-all md:text-[14px]`}>
        {exp.description && (
          <>{showFull ? exp?.description : exp?.description.slice(0, 120)}</>
        )}
      </p>
      <div className="mt-3 flex flex-col items-start ">
        {descLength && (
          <Button onClick={() => setShowFull(!showFull)}>
            Read {showFull ? "Less" : "More"}
          </Button>
        )}
        <Button variant="contained" onClick={() => setShowManageModal(true)}>
          Manage
        </Button>
      </div>
      <EditCapabilityModal
        open={showManageModal}
        close={() => setShowManageModal(false)}
        info={exp}
      />
    </section>
  );
};

export default SingleCapacityCard;
