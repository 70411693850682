import {Chip} from "@mui/material";
import DescriptionComp from "components/DescriptionComp";
import IconInContainer from "components/IconInContainer";
import MessageSquareIcon from "components/Vectors/MessageSquareIcon";
import StarFilledIcon from "components/Vectors/StarFilledIcon";
import {useSearchParams} from "react-router-dom";

const JobRequestOverview = () => {
  const [searchParams] = useSearchParams();
  const overviewType = searchParams.get("overview");

  return (
    <div className="rounded-24 bg-grey_80 p-4 md:p-8  ">
      <h3 className="text-prop font-Bai text-[24px] font-semibold text-grey_10 ">
        Overview
      </h3>

      {/* budget  */}
      <div className="mt-8 flex justify-between border-b border-b-[#F2F4F7] pb-2 ">
        <div className=" ">
          <h3 className="text-prop font-Bai text-[24px] font-semibold text-grey_30 ">
            Budget
          </h3>
          <span className="settings-font-h2 !text-[32px] ">
            {overviewType?.includes("open") ? "No Budget" : "₦500,000"}
          </span>
        </div>

        <div
          className="h-fit rounded-[8px] border 
        border-grey_70 bg-white px-[12px]
         py-[6px] text-[14px] font-medium capitalize text-primary "
        >
          assigned
        </div>
      </div>

      {/* description  */}
      <div className="mt-6 md:mt-[45px] ">
        <DescriptionComp />
      </div>

      {/* category, location...  */}
      <div className="mt-8 flex flex-col gap-4 ">
        {/* category  */}
        <div className="flex items-center justify-between ">
          <h4 className="text-lg font-medium text-grey_20">Category</h4>
          <span className="text-lg font-semibold text-grey_20  ">Design</span>
        </div>

        {/* location  */}
        <div className="flex items-center justify-between ">
          <h4 className="text-lg font-medium text-grey_20">Location</h4>
          <span className="text-lg font-semibold text-grey_20  ">Virtual</span>
        </div>

        {/* timeline  */}
        <div className="flex items-center justify-between ">
          <h4 className="text-lg font-medium text-grey_20">Timeline</h4>
          <span className="text-lg font-semibold text-grey_20  ">4 Weeks</span>
        </div>

        {/* date created  */}
        <div className="flex items-center justify-between ">
          <h4 className="text-lg font-medium text-grey_20">Date Created</h4>
          <span className="text-lg font-semibold text-grey_20  ">
            23 June 2022
          </span>
        </div>
      </div>
    </div>
  );
};
export default JobRequestOverview;
