import {useEffect, useState} from "react";
import LogoIcon from "components/Vectors/LogoIcon";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {Link} from "react-router-dom";
import UserName from "./AccountCreationComp/UserName";
import ProfessionalInfo from "./AccountCreationComp/ProfessionalInfo";
import KYC from "./AccountCreationComp/KYC";
import CreationCompleted from "./AccountCreationComp/CreationCompleted";
import {useNavigate} from "react-router-dom";
import {logOutHandler} from "redux/store.hooks";
import LogOutModal from "templates/DashboardLayout/widgets/LogOutModal";
import LogoutIcon from "components/Vectors/logout-icon";
import {useGetUserRequest} from "hooks/useGetUserRequest";
import { useGetUser } from "hooks/getUserHook";

const UpgradeAccount = ({number}) => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [presentStep, setPresentStep] = useState(number);
  useEffect(() => {
    setPresentStep(number);
  }, [number]);
  const navigate = useNavigate();
  const {refetch} = useGetUserRequest();
  const {user} = useGetUser()
const notVerifiedSteps = [
  {
    title: "Let’s Know Who You Are",
    id: 1,
  },
  {
    title: "Professional Information",
    id: 2,
  },
  {
    title: "KYC",
    id: 3,
  },
];



  const [steps, setSteps] =useState(notVerifiedSteps)


  useEffect(()=>{
    const alreadyVerifiedSteps = [
      {
        title: "Let’s Know Who You Are",
        id: 1,
      },
      {
        title: "Professional Information",
        id: 2,
      }
    ];
    
    if(user?.kyc?.status === "Approved"){
      setSteps(alreadyVerifiedSteps)
    }
  },[user])

  

 
  
  

  const handleNext = () => {
    setPresentStep(currentStep => currentStep + 1);
    navigate(`/user/upgrade-account/${presentStep + 1}`);
    refetch();
  };
  const handlePrev = () => {
    setPresentStep(currentStep => currentStep - 1);
    navigate(`/user/upgrade-account/${presentStep - 1}`);
  };

  const getStepComponent = step => {
    let components
    if(user?.kyc?.status === "Approved"){
       components = [
        <UserName onBtnClick={handleNext} />,
        <ProfessionalInfo onNextClick={handleNext} onPrevClick={handlePrev} />,
        // <KYC
        //   onNextClick={handleNext}
        //   onPrevClick={handlePrev}
        //   isServiceProvider={true}
        // />,
      ];
    }else{
       components = [
        <UserName onBtnClick={handleNext} />,
        <ProfessionalInfo onNextClick={handleNext} onPrevClick={handlePrev} />,
        <KYC
          onNextClick={handleNext}
          onPrevClick={handlePrev}
          isServiceProvider={true}
        />,
      ];
    }
  
    return components[step - 1];
  };

  return (
    <>
      <section className="h-full min-h-[100vh] w-full overflow-y-auto bg-grey_100 ">
        <div
          className={`${
            presentStep <= steps.length ? "flex" : "hidden"
          } w-full justify-center pt-[30px]`}
        >
          {presentStep <= steps.length && (
            <LogoIcon color="#E98100" sx={{width: "170px", height: "40px"}} />
          )}
        </div>
        {/* content  */}
        {presentStep <= steps.length ? (
          <div className="flex w-full flex-col p-4 md:flex-row md:gap-[3.75rem] lg:px-[7.5rem] lg:py-[5rem]">
            <div className="flex flex-col gap-y-5">
              {/* mobile  */}
              <div className="flex flex-wrap justify-between md:hidden">
                {steps.map(value => (
                  <button
                    onClick={() => {
                      setPresentStep(value.id);
                      navigate(`/user/upgrade-account/${value.id}`);
                    }}
                    disabled={presentStep < value.id}
                    key={value.id}
                    className={`flex h-[30px] w-[30px] items-center justify-center
                bg-grey_60 text-lg font-medium
                 ${presentStep === value.id && "!bg-primary text-white "} ${
                      presentStep > value.id && "!bg-grey_20 text-white "
                    } rounded-[10px] `}
                  >
                    {value.id}
                  </button>
                ))}
              </div>
              {/* desktop  */}
              <div
                className="hidden h-fit w-full max-w-[350px] flex-col
             flex-wrap justify-between rounded-2xl bg-white
           p-8 md:flex md:gap-6 lg:gap-6 "
              >
                {steps.map(value => (
                  <button
                    type="button"
                    onClick={() => {
                      setPresentStep(value.id);
                      navigate(`/user/upgrade-account/${value.id}`);
                    }}
                    disabled={presentStep < value.id}
                    className="flex items-center gap-4"
                    key={value.id}
                  >
                    <span
                      className={`flex h-[42px] w-[42px] items-center justify-center rounded-16
                 text-lg font-medium text-white
                 ${
                   presentStep === value.id
                     ? "bg-primary text-grey_60"
                     : presentStep > value.id
                     ? "bg-grey_20"
                     : "bg-grey_60 text-grey_30"
                 }`}
                    >
                      {value.id}
                    </span>
                    <span
                      className={`font-Mulish text-lg font-medium text-grey_40 ${
                        presentStep >= value.id && "!text-black_text"
                      } `}
                    >
                      {value.title}
                    </span>
                  </button>
                ))}
              </div>
              <div
                className="flex h-fit w-full max-w-[350px] gap-x-2.5 rounded-2xl bg-white
           p-8"
              >
                <InfoOutlinedIcon className="text-primary" />
                <p className="font-Mulish font-medium text-grey_20">
                  If you have issue filling the details or need help,{" "}
                  <Link to="/" className="text-primary underline">
                    Contact Us
                  </Link>{" "}
                  {/* or use the{" "}
                  <Link to="/" className="text-primary underline">
                    Live Chat
                  </Link>{" "}
                  to chat with an agent. */}
                </p>
              </div>

              <button
                onClick={() => setOpenLogoutModal(true)}
                className="mt-2 flex w-full items-center rounded-xl bg-white p-5 text-left text-sm font-bold text-[#D92D20]"
              >
                <LogoutIcon color="#D92D20" />
                <span className="ml-3">Log out</span>
              </button>
            </div>
            <div className="mt-4 w-full md:mt-[unset] md:max-w-[600px]">
              {getStepComponent(presentStep)}
            </div>
          </div>
        ) : (
          <CreationCompleted isProvider={true} />
        )}
      </section>
      <LogOutModal
        open={openLogoutModal}
        close={() => setOpenLogoutModal(false)}
        onLogout={() => logOutHandler("user")}
        desc="You did not finish setting up your profile, your information is saved already, you will continue from where you stopped when you log back in."
      />
    </>
  );
};
export default UpgradeAccount;
// form steps
