import {Button, Chip, IconButton} from "@mui/material";
import BackBtn from "components/BackBtn";
import FlagIcon from "components/Vectors/FlagIcon";
import ProjectOverdue from "./ProjectOverdue";
import Responses from "./Responses";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
  useGetSingleTicketQuery,
  useReplyTicketMutation,
  useUpdateTicketStatusMutation,
} from "services/admin/adminGeneralApi";
import {flagColor} from "components/TicketCards";
import moment from "moment";
import {handleError} from "utils";
import LoadingBtn from "components/mui-components/LoadingButton";
import {showToast} from "redux/store.hooks";
import DropDownWrapper from "components/mui-components/DropDownWrapper";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import UserAvatar from "components/mui-components/TableComponent/UserAvatar";

function AdminTicketsDetails() {
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const ticketTag = searchParam.get("tag");
  const {data, isLoading, error} = useGetSingleTicketQuery(ticketTag);
  const [update, {isLoading: isUpdateLoading}] =
    useUpdateTicketStatusMutation();
  const handleUpdateTicket = status => {
    update({tag: ticketTag, body: {status}})
      .unwrap()
      .then(res => showToast(res?.message, "success"))
      .catch(err => handleError(err));
  };
  const ticket = data?.data;
  const [reply, {isLoading: isReplyLoading}] = useReplyTicketMutation();
  return (
    <DashboardLayout
      isLoading={isLoading}
      error={error}
      title={"Tickets"}
      type="admin"
    >
      <main>
        <BackBtn text="Tickets" />
        <div className="mt-6 rounded-2xl bg-white p-4 md:p-6">
          <div className="mb-6 flex items-center justify-between">
            <h1 className=" font-Bai text-4xl font-semibold text-grey_20">
              Ticket Details
            </h1>
            <div className="flex gap-x-3">
              <DropDownWrapper
                contentPadding="px-0"
                className="more-actions"
                position="right"
                border="dropdown-boxShadow border border-[#E4E7EC] !rounded-16"
                action={
                  <button
                    type="button"
                    className="flex min-w-[140px] items-center justify-between rounded-[16px] bg-grey_80 py-1.5 px-3 pl-3.5 transition-all hover:bg-grey_70"
                  >
                    <span>
                      {ticket?.status === "Open" ? "New" : ticket?.status}
                    </span>
                    <IconButton>
                      <KeyboardArrowDownRoundedIcon color="action" />
                    </IconButton>
                  </button>
                }
              >
                <div className="min-w-[150px]">
                  {[
                    {
                      text: "In Progress",
                      value: "In Progress",
                    },
                    {
                      text: "Resolved",
                      value: "Resolved",
                    },
                  ].map((item, index) => (
                    <Button
                      key={index}
                      fullWidth
                      color="inherit"
                      onClick={() => handleUpdateTicket(item.value)}
                    >
                      {item.text}
                    </Button>
                  ))}
                </div>
              </DropDownWrapper>
              <div className="hidden sm:block">
                <LoadingBtn
                  loading={isUpdateLoading}
                  onClick={() => handleUpdateTicket("Resolved")}
                >
                  Update
                </LoadingBtn>
              </div>
            </div>
            <div className="mb-4 flex w-full justify-end sm:hidden">
              <LoadingBtn
                sx={{fontSize: "0.75rem", px: "0.5rem"}}
                loading={isUpdateLoading}
                onClick={() => handleUpdateTicket("Resolved")}
              >
                Update
              </LoadingBtn>
            </div>
          </div>
          <div className="flex flex-col gap-8 md:flex-row">
            <div className="h-fit min-w-fit rounded-xl bg-grey_80 p-4">
              <div className="mb-4 flex items-center gap-4">
                <UserAvatar
                  size={60}
                  showBadge={ticket?.user?.is_verified_account}
                  src={ticket?.user.avatar}
                  alt={ticket?.user.first_name}
                />
                <div className="flex flex-col items-start">
                  <h3 className="font-Bai text-xl font-semibold text-grey_20">
                    {ticket?.user.first_name} {ticket?.user.last_name}
                  </h3>
                  <span className="text-[13px] text-grey_30">
                    {ticket?.user_role_type}
                  </span>
                </div>
              </div>

              <div className="rounded-[10px] border border-grey_70 bg-grey_60 p-3">
                <p className="flex gap-2 font-Bai text-xl font-normal text-grey_30 ">
                  <span> Ticket Priority:</span>
                  <span className="flex items-center gap-2 font-semibold text-grey_20">
                    <FlagIcon color={flagColor(ticket?.priority)} />
                    {ticket?.priority}
                  </span>
                </p>
              </div>

              <div className="my-3 flex items-center justify-between gap-2">
                <p className="text-[14px] font-normal text-grey_30">
                  Issued on:{" "}
                  {moment(ticket?.created_at).format("DD MMM YYYY: hh:mm a")}
                </p>

                <Chip
                  sx={{borderRadius: "8px"}}
                  color="secondaryInverse"
                  label={ticket?.status}
                />
              </div>
              {ticket?.engagement_id && (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() =>
                    navigate(`/admin/engagements/${ticket?.engagement_id}`)
                  }
                >
                  View Engagement
                </Button>
              )}
            </div>
            <div className="basis-full">
              <ProjectOverdue
                reply={reply}
                isLoading={isReplyLoading}
                detail={ticket || {}}
              />
              <Responses responses={ticket?.responses || []} />
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
}

export default AdminTicketsDetails;
