import JobCard from "../Widgets/JobCard";
import EmptyHistory from "components/EmptyHistory";
import ShoppingBagIcon from "components/Vectors/shopping-bag";

const JobsContent = ({
  data,
  emptyStateMsg = "No job offer",
  setShouldReload,
  subtitle,
}) => {
  if (data?.data?.length === 0)
    return (
      <EmptyHistory
        icon={<ShoppingBagIcon stroke="#000" width={"3rem"} height={"3rem"} />}
        title={emptyStateMsg}
        subtitle={subtitle || "No job has been posted yet"}
      />
    );
  return (
    <div className="my-6 grid gap-6 md:grid-cols-3">
      {(Array.isArray(data) ? data : data?.data).map(job => (
        <JobCard
          key={job?.job_title + job?.id}
          setShouldReload={setShouldReload}
          job={job}
        />
      ))}
    </div>
  );
};
export default JobsContent;
