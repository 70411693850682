import UploadMultiImage from "components/forms/UploadMultiImage";
import {useState} from "react";
import {useFormContext} from "react-hook-form";
import UploadVideo from "components/forms/UploadVideo";
import UploadVidLink from "components/forms/UploadVidLink";
import BrowseIcon from "components/Vectors/BrowseIcon";
import CodeIcon from "components/Vectors/CodeIcon";

const AddMediaFiles = ({isEdit}) => {
  const [choice, setChoice] = useState(null);
  const methods = useFormContext();
  const handleChoice = e => {
    setChoice(e.currentTarget.value);
  };

  const users = [
    {
      icon: <BrowseIcon />,
      title: "Browse files",
    },

    {
      name: "unregistered",
      icon: <CodeIcon />,
      title: "Embedded Code",
    },
  ];

  return (
    <section className=" ">
      <div className="flex items-center justify-between px-4 md:px-10">
        <p className="text-sm font-medium text-grey_30 md:text-lg">
          Add media files to show your service
        </p>

        <p className="text-base font-bold text-grey md:text-2xl">
          3 <sup className="text-[13px] font-normal text-grey_40">3</sup>
        </p>
      </div>
      <div className="mt-5 h-px bg-grey_60"></div>
      <div className="w-full rounded-20 bg-grey_80 p-4">
        <p className="font-Mulish text-[14px] font-medium text-grey_30">
          Portfolio{" "}
        </p>
        <div className="mt-6 rounded-16 bg-[#FCFCFD] p-2 md:px-5">
          <div className="flex flex-col gap-y-2 text-grey_30">
            <span className="my-3 text-base font-bold text-black">Images</span>
            <UploadMultiImage
              name="images"
              required={!isEdit}
              dashed={true}
              maxFiles={5}
            />
          </div>
          <div className="mt-[22px] ">
            <span className=" text-sm font-bold text-black">Videos</span>
            <div className="my-4 flex flex-col gap-y-6 md:flex-row md:items-center md:gap-x-4">
              {users.map(({icon, title}, index) => (
                <button
                  key={index}
                  onClick={e => {
                    handleChoice(e);
                    methods.setValue("video_link", null);
                    methods.setValue("unembed_vid_link", null);
                    methods.setValue("video_file", null);
                  }}
                  value={title}
                  type="button"
                  className="flex min-h-[150px] basis-1/2 flex-col items-center justify-center gap-y-3 rounded-20 bg-white p-6 font-Bai"
                >
                  {icon}
                  <p className="text-prop text-[20px] font-bold text-primary">
                    {title}
                  </p>
                </button>
              ))}
            </div>
            {choice === "Browse files" ? (
              <UploadVideo name="video_file" required={false} />
            ) : choice === "Embedded Code" ? (
              <UploadVidLink
                name="unembed_vid_link"
                onClick={() => setChoice("")}
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddMediaFiles;
