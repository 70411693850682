import { useEffect, useState } from "react";
import { Collapse, IconButton, useMediaQuery } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import LogoIcon from "components/Vectors/LogoIcon";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SidebarModal from "./mui-components/Modal/SidebarModal";
import { useDispatch, useSelector } from "react-redux";
import { toggleState, toggle } from "redux/slice/userModalSlice";
import { togglePostModalState, togglePostModal } from "redux/slice/postModalSlice";
// import NavContent from "./NavContent";
import NavContent2 from "./NavContent2";
import MyModal from "./mui-components/Modal";
import PostAJob from "pages/Clients/Jobs/PostAJob";

const ClientAndProfNavbar = ({
  links,
  isProfessional,
  padx = false,
  updatedPadX = 'false'
}) => {
  const matches = useMediaQuery('(min-width:1240px)');
  const { pathname } = useLocation();
  const [openNav, setOpenNav] = useState(false);
  const toggleVal = useSelector(toggleState);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(toggle());
  };

  const togglePostModalVal = useSelector(togglePostModalState);
  const dispatchPostModal = useDispatch();
  const handlePostModalClick = () => {
    dispatchPostModal(togglePostModal());
  };

  useEffect(() => {
    setOpenNav(false);
  }, [pathname]);

  return (
    <div className="border-b border-[#DCDCE0]">

      <nav
        className={`w-full  ${openNav && 'min-h-screen lg:min-h-0 bg-white px-5 pb-[73px]'} ${padx &&
          "fixed top-0 z-[100] w-full bg-white shadow transition-all duration-300"
          } ${updatedPadX && "px-4 md:px-6 lg:px-[4vw]"} lg:flex lg:items-center lg:justify-between lg:py-5 ${padx && "px-4 md:px-6 lg:px-[4vw]"
          }`}
      >
        <div className="flex items-center justify-between py-3 md:px-4 lg:p-0">
          <Link
            to={
              /\/(client|service-provider)\//.test(pathname)
                ? isProfessional
                  ? "/service-provider"
                  : "/client"
                : "/"
            }
            className="text-xl font-bold text-white"
          >
            <LogoIcon />
          </Link>
          <div className="block lg:hidden">
            <IconButton onClick={() => setOpenNav(!openNav)}>
              {openNav ? (
                <CloseIcon fontSize="large" sx={{ color: "#3B3D3B" }} />
              ) : (
                <MenuIcon fontSize="large" sx={{ color: "#3B3D3B" }} />
              )}
            </IconButton>
          </div>
        </div>
        <Collapse in={matches ? true : openNav} className='lg:flex-grow'>
          {/* <NavContent links={links} setOpenNav={setOpenNav} isProfessional={isProfessional} handleClick={handleClick} /> */}
          <NavContent2
            links={links}
            setOpenNav={setOpenNav}
            isProfessional={isProfessional}
            handleClick={handleClick}
            handlePostModalClick={handlePostModalClick}
          />
        </Collapse>

        <SidebarModal
          openModal={toggleVal}
          closeModal={handleClick}
          isProfessional={isProfessional}
        />

        <PostAJob
          openModal={togglePostModalVal}
          closeModal={handlePostModalClick}
        />

      </nav>
    </div>

  );
};

export default ClientAndProfNavbar;
