import {useState} from "react";
import {
  Avatar,
  Button,
  Fade,
  IconButton,
  MobileStepper,
  Slide,
} from "@mui/material";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import ClearIcon from "@mui/icons-material/Clear";
import {useTheme} from "@mui/material/styles";

const imgList = [
  "https://media.istockphoto.com/id/1449364000/es/foto/habitaci%C3%B3n-peque%C3%B1a-de-estilo-minimalista.jpg?s=612x612&w=0&k=20&c=19UTeQkTwaKGMZEVJyEHd_gg0dqFA2qLQYzQ63GzvwU=",
  "https://media.istockphoto.com/id/1395324831/es/foto/equipo-de-trabajadores-manuales-examinando-el-proyecto-de-vivienda-mientras-trabajan-en-la-obra.jpg?s=612x612&w=0&k=20&c=s7hGWI72mZKWBSo06L-IChUyOGwA1gwk0LnoaIFM_ow=",
  "https://cdn.pixabay.com/photo/2016/06/24/10/47/house-1477041__340.jpg",
  "https://media.istockphoto.com/id/1393885905/es/foto/agente-de-bienes-ra%C3%ADces-mostrando-a-una-pareja-madura-una-nueva-casa.jpg?s=612x612&w=0&k=20&c=uacxMnPz94JsAu_EGA4g8azwhVR4_G2sh4FLLjFlZ0Q=",
];

const ImageSlideShow = ({images = imgList}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const imageList = images?.map(img => img?.image_url);

  const handleNext = () => {
    setActiveStep(step => (step < imageList.length ? step + 1 : 0));
  };

  const handlePrev = () => {
    setActiveStep(step => (step > 1 ? step - 1 : 0));
  };

  return (
    <div>
      <div className="relative h-[400px] w-full overflow-hidden rounded-[16px]">
        {imageList?.length > 0 && (
          <IconButton
            onClick={handlePrev}
            disabled={activeStep === 0}
            sx={{
              bgcolor: "#fff",
              borderRadius: "50%",
              position: "absolute",
              top: "50%",
              left: 4,
              "&: disabled": {
                bgcolor: "#fff",
                opacity: 0.5,
              },
            }}
          >
            <KeyboardArrowLeft color="primary" />
          </IconButton>
        )}
        <button className="h-full w-full" onClick={() => setOpenModal(true)}>
          <img
            src={imageList[activeStep] || "/images/broken-image.png"}
            alt=""
            height="100%"
            className="h-full w-full object-cover"
          />
        </button>
        {imageList?.length > 0 && (
          <IconButton
            onClick={handleNext}
            disabled={activeStep === imageList.length - 1}
            sx={{
              bgcolor: "#fff",
              borderRadius: "50%",
              position: "absolute",
              top: "50%",
              right: 4,
              "&: disabled": {
                bgcolor: "#fff",
                opacity: 0.5,
              },
            }}
          >
            <KeyboardArrowRight color="primary" />
          </IconButton>
        )}
      </div>

      <Fade in={openModal}>
        <div
          onClick={e => e.target === e.currentTarget && setOpenModal(false)}
          className="modal-wrap"
        >
          <Slide direction="up" in={openModal} mountOnEnter unmountOnExit>
            <div
              style={{
                maxWidth: 650,
                flexGrow: 1,
                position: "relative",
              }}
              className="mt-10 w-[90%]"
            >
              <IconButton
                sx={{background: "rgba(0, 0, 0, 0.04) !important"}}
                className="close-modal-btn"
                onClick={() => setOpenModal(false)}
              >
                <ClearIcon sx={{fontSize: "40px"}} color="white" />
              </IconButton>
              <Avatar
                src={imageList[activeStep] || "/images/broken-image.png"}
                sx={{
                  height: 450,
                  width: "100%",
                  mt: 2,
                  maxWidth: 650,
                  display: "block",
                  overflow: "hidden",
                  borderRadius: "20px",
                }}
                alt={"active pic"}
              />
              <MobileStepper
                variant="text"
                position="static"
                activeStep={activeStep}
                steps={images?.length}
                sx={{background: "transparent"}}
                nextButton={
                  <Button
                    size="medium"
                    onClick={handleNext}
                    color="white"
                    disabled={activeStep === images?.length - 1}
                  >
                    Next
                    {theme.direction !== "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="medium"
                    onClick={handlePrev}
                    color="white"
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </div>
          </Slide>
        </div>
      </Fade>
    </div>
  );
};

export default ImageSlideShow;
