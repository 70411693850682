import ValidatedInput2 from "components/forms/ValidatedInput2";
import {FormProvider, useForm} from "react-hook-form";
import {useUpdateUserPasswordMutation} from "services/user/userRegApi";
import {useDispatch} from "react-redux";
import {showToast} from "redux/store.hooks";
import {logOut} from "redux/slice/AuthSlice";
import {handleError} from "utils";
import LoadingBtn from "components/mui-components/LoadingButton";

function SecuritySettings() {
  const methods = useForm();
  const [updatePassword, {isLoading: isUpdatePasswordLoading}] =
    useUpdateUserPasswordMutation();
  const dispatch = useDispatch();
  const onSubmit = async data => {
    try {
      const res = await updatePassword(data).unwrap();
      showToast(res?.message, "success");
      dispatch(logOut({redirect: false, type: "user"}));
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <FormProvider {...methods}>
      <form
        className="rounded-24 bg-white px-6 py-6 md:px-10 md:py-10 "
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <h1 className="font-Bai text-3xl font-semibold text-grey_20">
          Security Setting
        </h1>

        <div className="mx-auto max-w-[440px]">
          <p className="my-6 text-xl font-medium text-grey_10">
            Change your Password
          </p>
          <div className="space-y-6">
            <ValidatedInput2
              type="password"
              name={"current_password"}
              bordered={false}
              label="Current Password"
              placeholder="Your current password"
            />
            <ValidatedInput2
              type="password"
              label="New Password"
              name={"new_password"}
              bordered={false}
              placeholder="Create new password"
            />
            <ValidatedInput2
              name="new_password_confirmation"
              type="password"
              label="Confirm Password"
              bordered={false}
              placeholder="Confirm your password"
              rules={{
                validate: value =>
                  value === methods.watch("new_password") ||
                  "Password do not match with new password",
              }}
            />
          </div>
          <LoadingBtn
            loading={isUpdatePasswordLoading}
            type="submit"
            sx={{mt: 4}}
          >
            Save Changes
          </LoadingBtn>
        </div>
      </form>
    </FormProvider>
  );
}

export default SecuritySettings;
