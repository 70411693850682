const IconAndTitle = ({icon, text, title, bg, onClick}) => {
  return (
    <div className="flex gap-4">
      <div
        role={onClick && "button"}
        onClick={onClick}
        className={`flex h-[48px] w-[48px] items-center justify-center rounded-full ${bg} `}
      >
        {icon}
      </div>
      <div className="mt-1">
        <h3 className="font-Bai text-3xl font-medium text-black">{text}</h3>
        <p className="text-sm font-normal text-grey_30">{title}</p>
      </div>
    </div>
  );
};
export default IconAndTitle;
