import { useGetUser } from "hooks/getUserHook";
import ProtectedBtn from "./ProtectedBtn";
import { useEffect } from "react";
import { IconButton } from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";
import StarIcon from "./Vectors/FourStar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import { BootstrapTooltip } from "./TicketCards";
import { useGetFollowedJobsQuery } from "services/user/providerGeneralApi";
import {
    useGetAllUserNotificationsQuery,
    useGetUnreadEngagementMsgCountQuery,
    useGetUnreadOfferMsgCountQuery,
} from "services/user/userRegApi";
import Pusher from "pusher-js";

import { Button } from "@mui/material";
import UserCircleIcon from "components/Vectors/user-circle";
import { useNavigate } from "react-router-dom";
import CreditCardIcon from "components/Vectors/credit-card";
import TicketsIcon from "components/Vectors/TicketIcon";
import SettingsIcon from "components/Vectors/SettingsIcon";
import { logOutHandler } from "redux/store.hooks";
import LogOutModal from "templates/DashboardLayout/widgets/LogOutModal";
import { useState } from "react";
import LogoutIcon from "components/Vectors/logout-icon";
import SwitchAccountModal from "./mui-components/Modal/SwitchAccountModal";
import { useSwitchAccountMutation } from "services/user/userRegApi";
import { getUserDetails, handleError } from "utils";
import { LoadingButton } from "@mui/lab";
import { useGetUserRequest } from "hooks/useGetUserRequest";
import UserAvatar from "./mui-components/TableComponent/UserAvatar";
// import HomeIcon from "./Vectors/home-icon"
// import { VerifiedBadge } from "./Vectors/SmallTickIcon";
// import HomeIcon from "./Vectors/home-icon";
import UserDisplay from "./mui-components/TableComponent/UserDisplay";
import { NotificationsOutlined } from "@mui/icons-material";

const NavContent2 = ({ links = ClientLinks, isProfessional, handleClick, setOpenNav, handlePostModalClick }) => {
    const { data: unreadOfferMsgCount } = useGetUnreadOfferMsgCountQuery();
    const { data: unreadEngagementMsgCount } =
        useGetUnreadEngagementMsgCountQuery();

    const { user } = useGetUser();

    // if (user) console.log(user)
    const { pathname } = useLocation();

    const counts = {
        jobs_count: unreadOfferMsgCount?.data,
        ongoing_engagements_count: unreadEngagementMsgCount?.data,
    };
    const { data: followedJobs } = useGetFollowedJobsQuery();
    const followedJobsCount = followedJobs?.data?.data
        ?.map(job => job?.creator?.full_name !== user?.full_name)
        .filter(Boolean).length;
    const { data: notifs, refetch: refetchNotification } =
        useGetAllUserNotificationsQuery({
            type: isProfessional ? "service-provider" : "client",
            params: {
                is_paginated: "yes",
            },
        });

    // if(notifs) console.log(notifs)
    // if(notifs) console.log(notifs)


    const navigate = useNavigate();
    const [switchAccount, { isLoading: isSwitchingAccount }] =
        useSwitchAccountMutation();
    const location = useLocation();
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [switchAcct, setSwitchAcct] = useState(false);
    const { refetch } = useGetUserRequest(!user?.user_type);
    const handleSwitchClick = async () => {
        try {
            await switchAccount().unwrap();
            setOpenNav(false)
            // dispatch(setUserDetails(res?.data));
            refetch();
            navigate(
                location.pathname.includes("service-provider")
                    ? "/client"
                    : "/service-provider",
                { state: "upgrading" },
            );
        } catch (error) {
            handleError(error);
        }
    };


    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: "mt1",
            encrypted: true,
        });
        const channel = pusher.subscribe("engagement-conversation-created");
        channel.bind("engagement-conversations", data => {
            refetchNotification();
        });
        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, [refetchNotification]);
    // Notification
    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: "mt1",
            encrypted: true,
        });
        const channel = pusher.subscribe("user-in-app-notification-received");
        channel.bind("user-in-app-notification-event", () => {
            refetchNotification();
        });
        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, [refetchNotification]);

    return (
        <>
            <div className="my-2 hidden w-full flex-col lg:my-0 lg:flex lg:w-auto lg:flex-grow lg:flex-row lg:items-center font-Bai text-sm font-medium lg:ml-12  lg:text-base">
                {links.map(({ link, to, countKey }) => (
                    <span
                        key={link}
                        className={`${countKey === "jobs_count" ? "sp_marketplace_second" : ""
                            } relative md:min-w-max`}
                    >
                        <NavLink
                            key={link}
                            to={to}
                            className={({ isActive }) =>
                                `${isActive
                                    ? "underline decoration-primary underline-offset-4"
                                    : ""
                                } mt-4 block lg:mr-4 lg:mt-0 lg:inline-block`
                            }
                        >
                            {link}
                        </NavLink>
                        {counts[countKey] ? (
                            counts[countKey] > 0 ? (
                                <span className="absolute right-0 top-2 h-5 w-5 rounded-full bg-primary pt-0.5 text-center text-xs text-white lg:right-1 lg:-top-2">
                                    <span className="absolute inset-0 animate-ping rounded-full bg-primary"></span>
                                    {counts[countKey]}
                                </span>
                            ) : null
                        ) : null}
                    </span>
                ))}
                {user?.user_type === "Client" ? (
                    <ProtectedBtn
                        color="primary"
                        sx={{ maxHeight: "42px !important" }}
                        size="small"
                        variant='contained'
                        className="client_dashboard_first"
                        btnText="Post a Task"
                        link=""
                        handleExternalClick={handlePostModalClick}
                    />
                ) : (
                    <ProtectedBtn
                        sx={{
                            ml: { xs: "0", lg: "1.5rem" },
                            mt: { xs: "1rem", lg: 0 },
                            order: { xs: 2, lg: 0 },
                            bgcolor:
                                pathname === "/service-provider/marketplace" ||
                                    pathname === "/client/post-job"
                                    ? "#e9800013"
                                    : "",
                            display: "block",
                            fontWeight: '600'
                        }}
                        isProfessional={true}
                        link="/service-provider/marketplace"
                        btnText="Discover Jobs"
                        className="sp_dashboard_first"
                    />
                )}
                {/* <div className="client_dashboard_second flex flex-col items-center gap-x-6 gap-y-6 font-Bai text-sm font-semibold lg:ml-auto lg:mr-2 lg:max-w-min lg:flex-grow lg:flex-row lg:justify-end lg:gap-y-0 lg:text-base">

                </div> */}
                <div className="mt-6 flex items-center justify-end lg:flex-grow gap-x-6 pl-6 lg:mt-0 lgborder-l lgborder-l-primary">
                    <div className="flex flex-col justify-center gap-y-6 lg:flex-row">
                        {/* {isProfessional ? ( */}
                        <Link
                            to="/service-provider/subscription"
                            className="sp_dashboard_seventh border border-[#C9C9CF] px-3 py-2 rounded-[200px] flex items-center justify-center gap-x-2 font-Mulish font-semibod text-[#151519]"
                        >
                            <StarIcon />
                            {user?.token_wallet?.current_balance}
                        </Link>
                        {/* ) : null} */}

                        <div className="inline-flex items-center gap-x-4 pl-4">
                            {/* <NavLink
                                to={`/`}
                                className={({ isActive }) => `${isActive ? "hidden" : "flex"}`}
                            >
                                <BootstrapTooltip title="Visit website">
                                    <IconButton
                                        sx={{
                                            backgroundColor: isProfessional
                                                ? "#E98100 !important"
                                                : "#1D1D1D !important",
                                            p: 1,
                                        }}
                                    >
                                        <HomeIcon stroke='#ffffff' />
                                    </IconButton>
                                </BootstrapTooltip>
                            </NavLink> */}

                            {/* {isProfessional && ( */}
                            <NavLink
                                to={"/service-provider/followed-jobs"}
                                className={({ isActive }) =>
                                    `${isActive
                                        ? isProfessional
                                            ? "rounded-full border border-primary p-1"
                                            : "rounded-full border border-black p-1"
                                        : ""
                                    } sp_dashboard_third`
                                }
                            >
                                <BootstrapTooltip
                                    title={isProfessional ? "Followed Jobs" : "Favourites"}
                                    placement="top"
                                >
                                    <IconButton
                                        sx={{
                                            // backgroundColor: isProfessional
                                            //     ? "#E98100 !important"
                                            //     : "#1D1D1D !important",
                                            p: 1,
                                        }}
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4582 2.73137C11.0985 3.05564 11.6198 3.43518 12 3.75429C12.3803 3.43518 12.9015 3.05564 13.5417 2.73137C14.9927 1.99652 17.0809 1.54203 19.4293 2.73136C20.9294 3.49109 21.9442 4.64064 22.4968 6.03634C23.0419 7.41328 23.116 8.97211 22.8536 10.5537C22.4065 13.2483 20.5648 15.6527 18.6732 17.5053C16.7581 19.3811 14.6582 20.8196 13.4739 21.5696C12.5675 22.1438 11.4326 22.1438 10.5261 21.5697C9.34185 20.8196 7.24191 19.3811 5.32674 17.5053C3.43518 15.6527 1.59341 13.2483 1.14632 10.5537C0.883901 8.97212 0.957972 7.41328 1.50313 6.03634C2.05573 4.64064 3.07049 3.49109 4.5706 2.73136C6.919 1.54203 9.00728 1.99653 10.4582 2.73137ZM11.2382 5.78568C11.4281 6.00915 11.7067 6.13797 12.0001 6.13797C12.2929 6.13796 12.571 6.00958 12.7609 5.78684C12.7612 5.78658 12.7614 5.78633 12.7616 5.78607C12.7618 5.78577 12.7621 5.78547 12.7624 5.78516L12.7775 5.76813C12.7931 5.75086 12.8192 5.72242 12.8555 5.68492C12.9281 5.60977 13.0402 5.49926 13.188 5.37001C13.4857 5.10957 13.9154 4.78403 14.4454 4.51559C15.4864 3.98837 16.8869 3.68562 18.5257 4.51559C19.5957 5.05748 20.2669 5.83735 20.6372 6.77259C21.0149 7.72659 21.1006 8.9002 20.8806 10.2263C20.5391 12.2841 19.0718 14.3155 17.2738 16.0765C15.4995 17.8143 13.5295 19.167 12.4038 19.88C12.1507 20.0403 11.8493 20.0403 11.5963 19.88C10.4705 19.167 8.50054 17.8143 6.72617 16.0765C4.92818 14.3155 3.46078 12.2841 3.11934 10.2263C2.89931 8.9002 2.98498 7.72659 3.36269 6.77259C3.73297 5.83735 4.40422 5.05748 5.47421 4.51559C7.11305 3.68562 8.51359 3.98836 9.55463 4.5156C10.0847 4.78403 10.5144 5.10958 10.8121 5.37002C10.9598 5.49927 11.072 5.60978 11.1446 5.68494C11.1809 5.72244 11.207 5.75088 11.2226 5.76815L11.2382 5.78568Z" fill="#151519" />
                                        </svg>

                                        {/* {followedJobsCount ? (
                                                <span
                                                    className={`absolute -right-2 top-2 h-5 w-5 rounded-full ${!isProfessional ? "bg-[#E98100]" : "bg-[#1D1D1D]"
                                                        } pt-0.5 text-center text-xs text-white lg:-top-1`}
                                                >
                                                    {followedJobsCount}
                                                </span>
                                            ) : null}
                                            {isProfessional ? (
                                                <BookmarkRoundedIcon sx={{ color: "#fff" }} />
                                            ) : (
                                                <HeartFilledIcon height={23} width={23} color="#fff" />
                                            )} */}
                                    </IconButton>
                                </BootstrapTooltip>
                            </NavLink>
                            {/* )} */}

                            <NavLink
                                to={
                                    isProfessional
                                        ? "/service-provider/notification"
                                        : "/client/notification"
                                }
                                className={({ isActive }) =>
                                    `${isActive
                                        ? isProfessional
                                            ? "rounded-full border border-primary p-1"
                                            : "rounded-full border border-black p-1"
                                        : ""
                                    } client_dashboard_third`
                                }
                            >
                                <BootstrapTooltip title="Notifications" placement="top">
                                    <IconButton
                                    // sx={{
                                    //     backgroundColor: isProfessional
                                    //         ? "#E98100 !important"
                                    //         : "#1D1D1D !important",
                                    //     p: 1,
                                    // }}
                                    >
                                        {notifs?.unread_notifications > 0 ? (
                                            <span
                                                className={`absolute -right-2 top-2 h-5 w-5 rounded-full ${!isProfessional ? "bg-[#E98100]" : "bg-[#1D1D1D]"
                                                    } pt-0.5 text-center text-xs text-white lg:-top-1`}
                                            >
                                                <span className="absolute inset-0 animate-ping rounded-full bg-primary"></span>
                                                {notifs?.unread_notifications}
                                            </span>
                                        ) : null}
                                        <NotificationsOutlined sx={{ color: "#151519" }} />
                                    </IconButton>
                                </BootstrapTooltip>
                            </NavLink>

                            <BootstrapTooltip
                                title={Boolean(user?.is_online) ? "Online" : "Offline"}
                                placement="top"
                            >
                                <UserDisplay
                                    handleClick={handleClick}
                                    img={user?.user_type === "Service provider"
                                        ? user?.professional_information?.business_logo ||
                                        user?.avatar
                                        : user?.avatar || ""}
                                    isOnline={Boolean(user?.is_online)}
                                    badge={user?.user_type === "Service provider" &&
                                        user?.is_verified_account}
                                    name={getUserDetails(user).name}
                                    email={getUserDetails(user).username}
                                    reverse
                                />
                            </BootstrapTooltip>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex lg:hidden flex-col items-center">
                <NavLink
                    to={
                        isProfessional
                            ? "/service-provider/notification"
                            : "/client/notification"
                    }
                    className='self-end'

                >
                    <BootstrapTooltip title="Notifications" placement="top">
                        <IconButton
                            sx={{
                                backgroundColor: isProfessional
                                    ? "#E98100 !important"
                                    : "#1D1D1D !important",
                                p: 1,
                            }}
                        >
                            {notifs?.unread_notifications > 0 ? (
                                <span
                                    className={`absolute -right-2 top-2 h-5 w-5 rounded-full ${!isProfessional ? "bg-[#E98100]" : "bg-[#1D1D1D]"
                                        } pt-0.5 text-center text-xs text-white lg:-top-1`}
                                >
                                    <span className="absolute inset-0 animate-ping rounded-full bg-primary"></span>
                                    {notifs?.unread_notifications}
                                </span>
                            ) : null}
                            <NotificationsIcon sx={{ color: "#FFF" }} />
                        </IconButton>
                    </BootstrapTooltip>
                </NavLink>

                <UserAvatar
                    src={
                        user?.user_type?.toLowerCase() === "service provider"
                            ? user?.professional_information?.business_logo || user?.avatar
                            : user?.avatar || ""
                    }
                    size={100}
                    showBadge={
                        user?.user_type?.toLowerCase() === "service provider" &&
                        user?.is_verified_account
                    }
                />

                {/* name  */}
                <h2 className="mt-[11px] font-Bai text-[24px] font-medium text-[#000]">
                    {user?.user_type === "Client"
                        ? user?.full_name || `${user?.first_name} ${user?.last_name}`
                        : user?.professional_information?.business_name ||
                        user?.full_name}
                </h2>

                {/* username */}
                {user?.user_type && (
                    <h3 className="mt-[1px] font-Bai text-base font-normal text-grey_20">
                        @{user?.username}
                    </h3>
                )}

                {/* TOKENS */}
                {isProfessional ? (
                    <Link
                        to="/service-provider/subscription"
                        className="flex items-center justify-center gap-x-2 mt-10 mb-[1.125rem] font-Bai font-medium text-primary"
                    >
                        <StarIcon />
                        {user?.token_wallet?.current_balance} Tokens
                    </Link>
                ) : null}

                {/* info and btn  */}
                <div className="mt-[11px] flex w-full flex-col items-center gap-[13px] rounded-16 bg-[#F2F4F7] px-[25px] py-4">
                    <div className="flex flex-wrap items-center justify-center gap-2">
                        <div className="rounded-[12px] bg-white px-3 py-2 text-[14px] capitalize text-[#000]">
                            {user?.user_type || "Admin"}
                        </div>
                    </div>


                    {user?.kyc &&
                        user?.kyc?.status === "Approved" &&
                        user?.is_verified_account && (
                            <Button
                                // fullWidth
                                variant="contained"
                                color='secondaryInverse2'
                                onClick={() => {
                                    setOpenNav(false)
                                    if (user?.user_type) {
                                        if (user?.is_hybrid !== "Yes") {
                                            setOpenNav(false)
                                            setSwitchAcct(true);
                                        } else {
                                            handleSwitchClick();
                                        }
                                    } else if (user?.admin_permissions) {
                                        navigate("/admin");
                                    }
                                }}
                                sx={{ display: user?.is_hybrid === "Yes" ? "none" : "" }}
                            >
                                {user?.user_type
                                    ? `Become a ${user?.user_type === "Service provider"
                                        ? "Client"
                                        : "Service Provider"
                                    }`
                                    : "Go to Dashboard"}
                            </Button>
                        )}

                    <LoadingButton
                        loading={isSwitchingAccount}
                        // fullWidth
                        variant="contained"
                        onClick={() => {
                            if (user?.is_hybrid === "Yes") {
                                handleSwitchClick();
                            } else {
                                setOpenNav(false)
                                setSwitchAcct(true);
                            }
                        }}
                        sx={{
                            display: user?.is_hybrid === "Yes" ? "" : "none",
                            bgcolor: user?.is_hybrid === "Yes" ? "" : "purple",
                            '&:hover': {
                                bgcolor: user?.is_hybrid === "Yes" ? "" : "purple",
                                opacity: user?.is_hybrid === "Yes" ? "" : "0.8",
                            }
                        }}
                    >
                        {user?.user_type
                            ? `${user?.is_hybrid === "Yes" ? "Switch to " : "Become a "}${user?.user_type === "Service provider"
                                ? "Client"
                                : "Service Provider"
                            }`
                            : "Go to Dashboard"}
                    </LoadingButton>
                </div>

                {/* actions  */}
                {user?.user_type && (
                    <div className="mt-[26px] flex w-full flex-col gap-2">
                        {links.map(({ link, to, countKey }) => (
                            <button
                                onClick={() => {
                                    setOpenNav(false)
                                    navigate(
                                        `${to}`,
                                    );
                                }}
                                className={`${pathname?.includes(`${to}`) ? 'bg-[#d1d1d1]' : ''} w-full rounded-[12px] bg-[#F2F4F7] px-[26px] py-4 transition-all hover:bg-[#d1d1d1]`}
                                key={link}
                            >
                                <div className="flex items-center gap-[14px]">
                                    <UserCircleIcon height="20" width="20" stroke="#1D1D1D" />
                                    <span
                                        className={`relative md:min-w-max text-sm font-semibold text-grey_10`}
                                    >
                                        {link}
                                        {counts[countKey] ? (
                                            counts[countKey] > 0 ? (
                                                <span
                                                    className={`absolute -right-4 -top-2 h-5 w-5 rounded-full bg-[#1D1D1D] pt-0.5 text-center text-xs text-white`}
                                                >
                                                    {counts[countKey]}
                                                </span>
                                            ) : null
                                        ) : null}
                                    </span>
                                </div>
                            </button>
                        ))}

                        {isProfessional &&
                            <button
                                onClick={() => {
                                    setOpenNav(false)
                                    navigate('/service-provider/followed-jobs');
                                }}
                                className={`${pathname?.includes(`followed`) ? 'bg-[#d1d1d1]' : ''} w-full rounded-[12px] bg-[#F2F4F7] px-[26px] py-4 transition-all hover:bg-[#d1d1d1]`}

                            >
                                <div className="flex items-center gap-[14px]">
                                    <BookmarkRoundedIcon sx={{ color: "#1D1D1D", fontSize: '20px' }} />

                                    <span
                                        className={`relative md:min-w-max text-sm font-semibold text-grey_10`}
                                    >
                                        Followed Jobs
                                        {followedJobsCount ? (
                                            <span
                                                className={`absolute -right-4 -top-2 h-5 w-5 rounded-full bg-[#1D1D1D] pt-0.5 text-center text-xs text-white`}
                                            >
                                                {followedJobsCount}
                                            </span>
                                        ) : null}
                                    </span>
                                </div>
                            </button>
                        }

                        {routeLinks.map((val, index) => (
                            <button
                                onClick={() => {
                                    setOpenNav(false)
                                    navigate(
                                        `${user?.user_type === "Client"
                                            ? "/client/"
                                            : "/service-provider/"
                                        }${val.link}`,
                                    );
                                }}
                                className={`${pathname?.includes(`${val.link}`) ? 'bg-[#d1d1d1]' : ''} w-full rounded-[12px] bg-[#F2F4F7] px-[26px] py-4 transition-all hover:bg-[#d1d1d1]`}
                                key={index}
                            >
                                <div className="flex items-center gap-[14px]">
                                    {val.icon}
                                    <span className="text-sm font-semibold text-grey_10">
                                        {val.title}
                                    </span>
                                </div>
                            </button >
                        ))}
                    </div >
                )}

                <button
                    onClick={() => {
                        // setOpenNav(false)
                        setShowLogoutModal(true);
                    }}
                    className="mt-[10px]  flex w-full items-center gap-[14px] self-center rounded-[12px] bg-[#FFF1F0] px-6 py-4 text-[14px] font-bold text-[#D92D20]"
                >
                    <LogoutIcon color={"#D92D20"} />
                    Logout
                </button>
            </div >


            <LogOutModal
                open={showLogoutModal}
                close={() => setShowLogoutModal(false)}
                onLogout={() => {
                    logOutHandler({
                        type: user?.user_type ? "user" : "admin",
                        redirect: true,
                    });
                }}
            />
            <SwitchAccountModal
                open={switchAcct}
                close={() => {
                    setSwitchAcct(false);
                }}
            />
        </>
    );
};

export default NavContent2;
const ClientLinks = [
    { link: "My Jobs", to: "/client/jobs", countKey: "jobs_count" },
    {
        link: "Engagements",
        to: "/client/engagements",
        countKey: "ongoing_engagements_count",
    },
    {
        link: "Discover Jobs",
        to: "/client/jobs",
        countKey: "discover_jobs_count",
    },
];


const routeLinks = [
    // {
    //   icon: <HomeIcon height="20" width="20" stroke="#1D1D1D" />,
    //   link: "",
    //   title: "My Dashboard",
    // },
    {
        icon: <UserCircleIcon height="20" width="20" stroke="#1D1D1D" />,
        link: "account",
        title: "My Account",
    },
    {
        icon: <CreditCardIcon height="20" width="20" stroke="#1D1D1D" />,
        link: "transactions",
        title: "Transactions",
    },
    {
        icon: <TicketsIcon height="20" width="20" stroke="#1D1D1D" />,
        link: "tickets",
        title: "Tickets",
    },
    {
        icon: <SettingsIcon height="20" width="20" stroke="#1D1D1D" />,
        link: "settings",
        title: "Settings",
    },
];