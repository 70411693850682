import {useState} from "react";

import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {Avatar, Chip, IconButton, Button} from "@mui/material";
import MyAccordion from "components/mui-components/Accordion";
import moment from "moment";
import CompleteProjectModal from "../Modal/CompleteProjectModal";
const FinalFile = ({isProvider, file, submissions}) => {
  console.log(submissions);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);

  return (
    <div className="flex flex-col gap-y-6 rounded-2xl border border-[#DCDCE0] p-6">
      <h4 className="mb-[18px] font-Bai text-[24px] font-semibold text-grey_10">
        Make Final Submission {file?.length > 0 ? " log" : ""}
      </h4>
      <p className="text-grey-900 font-Mulish text-sm font-semibold">
        Upload the completed file or any relevant document here
      </p>
      {submissions?.length > 0 ? (
        <MyAccordion
          bgcolor="#fff"
          data={submissions?.map((submission, index) => ({
            title: (
              <span className="flex w-full items-center justify-between">
                <span className="mr-24">Submission {index + 1}</span>

                <Chip
                  color={
                    submission?.status === "Approved"
                      ? "successInverse"
                      : submission?.status === "Declined"
                      ? "errorInverse"
                      : "default"
                  }
                  label={submission?.status}
                  sx={{borderRadius: "8px"}}
                />
              </span>
            ),
            content: <EachSubmission submission={submission} />,
          }))}
        />
      ) : (
        <div className="">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenSubmitModal(true)}
          >
            Complete Project
          </Button>
        </div>
      )}
      <CompleteProjectModal
        open={openSubmitModal}
        close={() => setOpenSubmitModal(false)}
      />
    </div>
  );
};

export default FinalFile;

const EachSubmission = ({submission}) => {
  const fileName = submission?.file_url?.split("/").pop();

  return (
    <div className="ml-2">
      {submission?.file_url ? (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <Avatar
              src={submission?.file_url}
              sx={{width: "64px", height: "64px", borderRadius: "12px"}}
            />
            <div className="flex flex-col gap-y-1 font-medium">
              <span className="text-grey_10">{fileName}</span>
              <span className="text-grey_30">{submission?.file_size}</span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <IconButton
              onClick={() => {
                window.open(submission?.file_url, "_blank");
              }}
              sx={{bgcolor: "#F2F4F7", borderRadius: "8px"}}
            >
              <VisibilityOutlinedIcon color="primary" />
            </IconButton>
            <IconButton
              download={true}
              href={submission?.file_url}
              target="_blank"
              sx={{bgcolor: "#F2F4F7", borderRadius: "8px"}}
            >
              <SaveAltOutlinedIcon color="primary" />
            </IconButton>
          </div>
        </div>
      ) : (
        <p className="text-prop my-3 text-lg font-medium text-grey_10">
          No file uploaded
        </p>
      )}

      <div className="mt-4 flex items-center justify-between">
        <p className="font-Bai text-xl font-medium text-grey_30">Comment</p>
        <p className="text-sm font-medium text-grey_20">
          {moment(submission?.created_at).format("DD MMM yyy, hh:mm a")}
        </p>
      </div>
      <p className="my-1 font-medium text-grey_20">{submission?.comment}</p>
    </div>
  );
};
