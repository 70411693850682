import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import {toCurrency} from "utils";
import {Chip} from "@mui/material";
import moment from "moment";
import MoneyAddIcon from "components/Vectors/MoneyAddIcon";

const EarningComponent = ({data, isLoading, isError, error}) => {
  const headCells = [
    {
      name: "client",
      label: "Client",
      options: {
        customBodyRender: value => (
          <UserDisplay name={value.name} img={value.img} />
        ),
      },
    },
    {
      name: "engagement",
      label: "Engagement",
      options: {
        customBodyRender: value => (
          <UserDisplay name={value.name} img={value.img} imgRounded={false} />
        ),
      },
    },
    {
      name: "date",
      label: "Date",
    },
    {
      name: "total_earning",
      label: "Total Earning",
    },
    {
      name: "truelocal_commission",
      label: "Truelocal Commission",
    },
    {
      name: "net_earning",
      label: "Net Earning",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: value => (
          <Chip
            sx={{borderRadius: "8px"}}
            color={statusColor[value.toLowerCase()]}
            label={value}
          />
        ),
      },
    },
  ];
  const mockData = data?.map(item => ({
    client: {
      name: item.client.full_name,
      img: item.client.avatar,
    },
    engagement: {
      name: item.engagement.job_detail?.job_title,
      img: item.engagement?.job_detail?.images[0]?.image_url,
    },
    date: moment(item.date_time).format("ll"),
    total_earning: toCurrency(item.total_earning),
    truelocal_commission: toCurrency(item.service_charge),
    net_earning: toCurrency(item.net_earning),
    status: item.status,
  }));

  return (
    <MyDataTable
      headCells={headCells}
      data={mockData}
      emptyData={{
        message: (
          <div className="flex flex-col items-center gap-y-2 font-Bai text-4xl font-semibold">
            No Earning History
            <span className="font-Mulish text-lg text-grey_30">
              Earnings will be updated on this page.
            </span>
          </div>
        ),
        icon: MoneyAddIcon,
      }}
      isLoading={isLoading}
      isError={isError}
      error={error}
      options={{
        selectableRows: "none",
        search: false,
        viewColumns: false,
        filter: false,
      }}
    />
  );
};

export default EarningComponent;
export const statusColor = {
  pending: "default",
  completed: "successInverse",
  failed: "error",
  refunded: "secondaryInverse",
  escrow: "warning",
  terminated: "errorInverse",
};
