import React from "react";

function UserArrowDownIcon() {
  return (
    <svg
      width="56"
      height="40"
      viewBox="0 0 56 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.94 6C13.13 6 11.51 6.88 10.5 8.23C9.49 6.88 7.87 6 6.06 6C2.99 6 0.5 8.5 0.5 11.59C0.5 12.78 0.69 13.88 1.02 14.9C2.6 19.9 7.47 22.89 9.88 23.71C10.22 23.83 10.78 23.83 11.12 23.71C13.53 22.89 18.4 19.9 19.98 14.9C20.31 13.88 20.5 12.78 20.5 11.59C20.5 8.5 18.01 6 14.94 6Z"
        fill="#F8D8B0"
      />
      <circle cx="35.5" cy="10" r="10" fill="#E98100" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5112 40H55.4889C55.1651 31.1087 47.8551 24 38.8847 24H31.1154C22.145 24 14.835 31.1087 14.5112 40Z"
        fill="#E98100"
      />
    </svg>
  );
}

export default UserArrowDownIcon;
