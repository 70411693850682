const TrashIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.9985 4.48633C13.9851 4.48633 13.9651 4.48633 13.9451 4.48633C10.4185 4.13299 6.89848 3.99966 3.41181 4.35299L2.05181 4.48633C1.77181 4.51299 1.52515 4.31299 1.49848 4.03299C1.47181 3.75299 1.67181 3.51299 1.94515 3.48633L3.30515 3.35299C6.85181 2.99299 10.4451 3.13299 14.0451 3.48633C14.3185 3.51299 14.5185 3.75966 14.4918 4.03299C14.4718 4.29299 14.2518 4.48633 13.9985 4.48633Z"
        fill={props.color ? props.color : "white"}
      />
      <path
        d="M5.66846 3.81301C5.6418 3.81301 5.61513 3.81301 5.5818 3.80634C5.31513 3.75967 5.12846 3.49967 5.17513 3.23301L5.3218 2.35967C5.42846 1.71967 5.57513 0.833008 7.12846 0.833008H8.87513C10.4351 0.833008 10.5818 1.75301 10.6818 2.36634L10.8285 3.23301C10.8751 3.50634 10.6885 3.76634 10.4218 3.80634C10.1485 3.85301 9.88846 3.66634 9.84846 3.39967L9.7018 2.53301C9.60846 1.95301 9.58846 1.83967 8.8818 1.83967H7.13513C6.42846 1.83967 6.41513 1.93301 6.31513 2.52634L6.1618 3.39301C6.1218 3.63967 5.90846 3.81301 5.66846 3.81301Z"
        fill={props.color ? props.color : "white"}
      />
      <path
        d="M10.1416 15.1667H5.86156C3.53489 15.1667 3.44156 13.8801 3.36823 12.8401L2.93489 6.12672C2.91489 5.85338 3.12823 5.61338 3.40156 5.59338C3.68156 5.58005 3.91489 5.78672 3.93489 6.06005L4.36823 12.7734C4.44156 13.7867 4.46823 14.1667 5.86156 14.1667H10.1416C11.5416 14.1667 11.5682 13.7867 11.6349 12.7734L12.0682 6.06005C12.0882 5.78672 12.3282 5.58005 12.6016 5.59338C12.8749 5.61338 13.0882 5.84672 13.0682 6.12672L12.6349 12.8401C12.5616 13.8801 12.4682 15.1667 10.1416 15.1667Z"
        fill={props.color ? props.color : "white"}
      />
      <path
        d="M9.10672 11.5H6.88672C6.61339 11.5 6.38672 11.2733 6.38672 11C6.38672 10.7267 6.61339 10.5 6.88672 10.5H9.10672C9.38005 10.5 9.60672 10.7267 9.60672 11C9.60672 11.2733 9.38005 11.5 9.10672 11.5Z"
        fill={props.color ? props.color : "white"}
      />
      <path
        d="M9.66536 8.83301H6.33203C6.0587 8.83301 5.83203 8.60634 5.83203 8.33301C5.83203 8.05967 6.0587 7.83301 6.33203 7.83301H9.66536C9.9387 7.83301 10.1654 8.05967 10.1654 8.33301C10.1654 8.60634 9.9387 8.83301 9.66536 8.83301Z"
        fill={props.color ? props.color : "white"}
      />
    </svg>
  );
};
export default TrashIcon;
