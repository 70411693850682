import VideoIcon2 from "components/Vectors/VideoIcon2";
import {useRef} from "react";

import {useEffect, useState} from "react";
import {useFormContext, get} from "react-hook-form";

function UploadVideo({
  img,
  mockVideo = "",
  replaceVideo = false,
  containerHeight,
  borderRadius,
  bgColor,
  closeChoice,
  name,
  replaceBtnClass,
  ...props
}) {
  const {
    register,
    setValue,
    setError, getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const value = getValues(name);
  const [localImgUrl, setLocalImgUrl] = useState(mockVideo || value);
  const [videoFile, setVideoFile] = useState([]);
  const videoRef = useRef();

  const openFile = () => {
    videoRef.current.click();
  };

  useEffect(() => {
    register(name, {
      required: props?.required ? "This field is required" : props?.required,
    });
  }, [name, register, props?.required]);

  useEffect(() => {
    setValue(name, videoFile);
  }, [videoFile, name, setValue]);

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file.size > 5_000_000) {
      setError(name, {
        message: "File size greater than 5MB",
      });
      return;
    } else {
      clearErrors(name);
      setVideoFile(file);
      const url = URL.createObjectURL(file);
      setLocalImgUrl(url);
    }
  };

  const error = get(errors, name);

  const handleOff = () => {
    setLocalImgUrl("");
    closeChoice();
  };

  return (
    <div className="w-full">
      <div
        className={`${containerHeight ? containerHeight : "h-full"} w-full ${
          borderRadius && borderRadius
        } ${
          bgColor ? bgColor : "bg-white"
        }  flex items-center justify-center rounded-20 border-[2px] border-dashed border-grey_60
      p-3 `}
      >
        <label
          htmlFor={props.name ? props.name : "logo"}
          className="relative flex h-fit w-full cursor-pointer items-center justify-center rounded-[20px] "
        >
          {localImgUrl ? (
            <div className="relative h-full w-full ">
              {/* <video className="w-full rounded-[8px] " controls>
                <source src={localImgUrl} type="video/mp4" />
                Your browser does not support HTML video.
              </video> */}

              <iframe
                className={`!h-[200px] w-full rounded-[8px] `}
                loading="lazy"
                // width="800"
                // height="500"
                controls
                src={localImgUrl}
                title="video player"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              >
                Your browser does not support HTML video.
              </iframe>

              {replaceVideo ? (
                <button
                  onClick={openFile}
                  type="button"
                  className={
                    replaceBtnClass ||
                    "text-medium mt-4 rounded-lg bg-[#F2F4F7] py-[12px] px-[18px] text-[14px] font-semibold text-[#B42318]  "
                  }
                >
                  Replace Video
                </button>
              ) : (
                <button
                  onClick={() => handleOff()}
                  type="button"
                  className="text-medium mt-4 rounded-lg bg-[#F2F4F7] py-[12px]
             px-[18px] text-[14px] font-semibold text-[#B42318]  "
                >
                  Cancel{" "}
                </button>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-center pt-4 text-xs md:text-sm">
              <VideoIcon2 />
              <p className="mt-[10px] text-center text-base font-medium ">
                <span className="text-primary "> Upload a file </span> or drag
                and drop{" "}
              </p>
              <p className="pb-6 text-[14px] font-[400] text-grey_30  ">
                {" "}
                .mp4, .mov, .webm max size - 5mb
              </p>
              <input
                required
                ref={videoRef}
                {...props}
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                onChange={handleFileChange}
                id={props.name ? props.name : "video"}
                name={props.name ? props.name : "video"}
                className="absolute inset-0 opacity-0"
              />
            </div>
          )}
        </label>
      </div>
      {error && error?.message && (
        <div className={` ${!error && "hidden"} input-err-msg pt-[-10rem] `}>
          {error?.message || error?.name?.message || ""}
        </div>
      )}
    </div>
  );
}

export default UploadVideo;
