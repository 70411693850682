import {Button, Skeleton} from "@mui/material";
import {useState, useEffect} from "react";
import Cities from "../components/Cities";
import LocationCard from "../components/LocationCard";
import AddNewStateModal from "../Modals/AddNewStateModal";
import {
  useGetAllStatesQuery,
  useGetAllCitiesQuery,
} from "services/admin/adminGeneralApi";
import LocationCityCard from "../components/LocationCityCard";
import StateCard from "../components/StateCard";

const LocationsTab = () => {
  const [openAddNewStateModal, setopenAddNewStateModal] = useState(false);
  const [activeState, setActiveState] = useState([]);
  const {data: allStates, isLoading: isAllStateLoading} = useGetAllStatesQuery({
    pollingInterval: 3000,
  });

  useEffect(() => {
    if (allStates) {
      setActiveState(allStates?.data[0]);
    }
  }, [allStates]);

  const {data: allCities, isLoading: isAllCityLoading} = useGetAllCitiesQuery({
    pollingInterval: 3000,
  });

  return (
    <>
      <div className="mt-3 flex items-center justify-between">
        <div>
          <h3 className="font-Bai text-[32px] font-semibold text-grey_20">
            Locations
          </h3>
          <p className="text-base font-medium text-black">
            The Following are locations TrueLocal is physically available
          </p>
        </div>

        <div className="hidden sm:block">
          <Button
            variant="contained"
            onClick={() => setopenAddNewStateModal(true)}
          >
            Add New State
          </Button>
        </div>
      </div>
      <div className="mt-3 flex justify-end sm:hidden">
        <Button
          variant="contained"
          onClick={() => setopenAddNewStateModal(true)}
        >
          Add New State
        </Button>
      </div>

      <div className="mb-8 mt-5 grid grid-cols-1 gap-4 md:grid-cols-2">
        <LocationCard
          data={allStates?.data}
          type="States"
          isLoading={isAllStateLoading}
        />
        <LocationCityCard
          data={allCities?.data}
          type="Cities/Towns"
          isLoading={isAllCityLoading}
        />
      </div>

      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        <div>
          <h3 className="mb-4 font-Bai text-[32px] font-semibold text-grey_20">
            States
          </h3>

          <div className="noScroll flex h-full max-h-[723px] flex-col gap-2 overflow-y-auto">
            {isAllStateLoading ? (
              <Skeleton variant="rounded" width="100%" height={250} />
            ) : (
              <>
                {allStates?.data?.map((value, i) => (
                  <StateCard
                    onCardClick={() => setActiveState(value)}
                    key={i}
                    data={value}
                  />
                ))}
              </>
            )}
          </div>
        </div>
        <Cities state={activeState} isLoading={isAllStateLoading} />
      </div>

      <AddNewStateModal
        openModal={openAddNewStateModal}
        setopenModal={setopenAddNewStateModal}
      />
    </>
  );
};

export default LocationsTab;
