const AddImageIcon = props => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1666 2.33398H8.16663C6.23363 2.33398 4.66663 3.90099 4.66663 5.83398V22.1673C4.66663 24.1003 6.23363 25.6673 8.16663 25.6673H10.5V22.0884C10.1834 21.9056 9.89022 21.6697 9.63403 21.3815C8.34981 19.9367 8.47995 17.7245 9.92469 16.4403L11.6747 14.8847C13.0008 13.706 14.9991 13.706 16.3252 14.8847L18.0752 16.4403C19.52 17.7245 19.6501 19.9367 18.3659 21.3815C18.1097 21.6697 17.8166 21.9056 17.5 22.0884V25.6673H19.8333C21.7663 25.6673 23.3333 24.1003 23.3333 22.1673V11.6673H19.8333C17.256 11.6673 15.1666 9.57798 15.1666 7.00065V2.33398Z"
        fill="#E98100"
      />
      <path
        d="M23.0958 9.33398C22.9596 8.98316 22.7666 8.654 22.5221 8.36051L18.5494 3.59334C18.2547 3.23971 17.8972 2.95219 17.5 2.74163V7.00065C17.5 8.28932 18.5446 9.33398 19.8333 9.33398H23.0958Z"
        fill="#E98100"
      />
      <path
        d="M14.7751 16.6287C14.333 16.2358 13.6669 16.2358 13.2249 16.6287L11.4749 18.1842C10.9933 18.6123 10.9499 19.3497 11.378 19.8313C11.7525 20.2526 12.3637 20.3385 12.8333 20.0668V25.6673C12.8333 26.3116 13.3556 26.834 14 26.834C14.6443 26.834 15.1666 26.3116 15.1666 25.6673V20.0668C15.6362 20.3385 16.2474 20.2526 16.6219 19.8313C17.05 19.3497 17.0066 18.6123 16.5251 18.1842L14.7751 16.6287Z"
        fill="#E98100"
      />
    </svg>
  );
};
export default AddImageIcon;
