import { FormProvider, useForm } from "react-hook-form";
import ValidatedRadioBtns from "components/forms/ValidatedRadioBtns";
import { Button } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useGetUser } from "hooks/getUserHook";
import {
  useSubmitCacCertMutation,
  useSubmitKycInfoMutation,
} from "services/user/userRegApi";
import { getFileNameFromUrl, handleError, truncateString } from "utils";
import { showToast } from "redux/store.hooks";
import CustomFileUploader from "components/forms/CustomFileUploader";
import FileDisplayUi from "components/FileDisplayUI";
import LoadingBtn from "components/mui-components/LoadingButton";
import { useGetUserRequest } from "hooks/useGetUserRequest";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { PrevBtn } from "pages/User/auth";

export const CacKyc = ({ cacUploaded, setcacUploaded }) => {
  const { user } = useGetUser();

  useEffect(() => {
    if (user?.cac_certificate)
      setcacUploaded(user?.cac_certificate ? true : false);
  }, [setcacUploaded, user?.cac_certificate]);
  return (
    <>
      {!cacUploaded ? (
        <>
          <p className="my-4 font-Bai text-xl font-medium text-grey">
            CAC Certificate
          </p>
          <CustomFileUploader
            required={true}
            name="cac"
            label="Upload Your CAC Certificate"
            types={["png", "jpg", "jpeg", "pdf"]}
          />
        </>
      ) : (
        <>
          <IDStatus
            type="CAC Certificate"
            status={user?.cac_certificate?.status}
            reupload={() => setcacUploaded(false)}
          />
          <FileDisplayUi
            name={truncateString(
              getFileNameFromUrl(user?.cac_certificate?.cac_cert || ""),
              40,
            )}
            downloadType
            link={user?.cac_certificate?.cac_cert}
          />
        </>
      )}
    </>
  );
};

export const IDStatus = ({ reupload, status, type = "ID" }) => {
  return (
    <>
      {status === "Approved" ? (
        <div className="mt-14 mb-4 rounded-20 border-[2px] border-dashed border-success bg-success-light p-4">
          <p className="text-xl font-medium">{type} Verified</p>
        </div>
      ) : status === "Pending" ? (
        <div className="mt-14 mb-4 rounded-20 border-[2px] border-dashed border-primary bg-[#F2F4F7] p-4">
          <p className="text-xl font-medium">{type} Verification Pending</p>
        </div>
      ) : (
        <div className="mt-14 mb-4 rounded-20 border-[2px] border-dashed border-error bg-error-light p-4">
          <p className="text-xl font-medium">{type} Not Verified</p>
          <p className="mt-1 font-Mulish">
            The images uploaded are blur, kindly upload clearer copies. Thanks.
          </p>
          <Button
            onClick={reupload}
            sx={{ mt: 2, p: "0.8rem !important" }}
            variant="contained"
          >
            Reupload {type}
          </Button>
        </div>
      )}
    </>
  );
};

const KycSettings = memo(
  ({ isOnBoarding = false, onNextClick, onPrevClick, isServiceProvider }) => {
    const methods = useForm();
    const { user } = useGetUser();
    const [kycUploaded, setkycUploaded] = useState(user?.kyc ? true : false);
    const [cacUploaded, setcacUploaded] = useState(
      user?.cac_certificate ? true : false,
    );

    const [isLoading, setisLoading] = useState(false);
    const { refetch } = useGetUserRequest(!user?.user_type);
    const [submitInfo] = useSubmitKycInfoMutation();
    const [submitCac] = useSubmitCacCertMutation();
    useEffect(() => {
      if (user) setkycUploaded(user?.kyc ? true : false);
    }, [user]);

    useEffect(() => {
      if (kycUploaded) {
        methods.setValue("id_type", user?.kyc?.id_type);
      }
    }, [user, methods, kycUploaded]);

    const onSubmit = async data => {
      const formData = new FormData();
      if (data?.front) {
        formData.append("id_type", data?.id_type);
        formData.append("front_image", data?.front);
        formData.append("back_image", data?.back);
        formData.append("back_image", data?.back);
        if (data?.cac) {
          formData.append("cac_cert", data?.cac);
        }
      } else {
        formData.append("cac_cert", data?.cac);
      }
      try {
        setisLoading(true);
        if (data?.front) {
          await submitInfo(formData).unwrap();
        } else {
          await submitCac(formData).unwrap();
        }
        showToast("Profile updated successfully", "success");
        refetch();
        // setkycUploaded(true);
        // setcacUploaded(true);
        if (isOnBoarding) {
          onNextClick();
        }
      } catch (error) {
        handleError(error);
      } finally {
        setisLoading(false);
      }
    };
    const requireCac =
      user?.user_type === "Service provider" &&
      user?.service_provider_type?.toLowerCase() !==
      "unregistered service provider";
    return (
      <FormProvider {...methods}>
        <form
          className="rounded-24 bg-white px-6 py-6 md:px-10 md:py-10 "
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="flex justify-between gap-3">
            <h1 className="font-Bai text-3xl font-semibold text-grey_20">
              KYC
            </h1>
          </div>
          <div className="mx-auto max-w-[440px]">
            {requireCac && (
              <CacKyc
                cacUploaded={cacUploaded}
                setcacUploaded={setcacUploaded}
              />
            )}
            {kycUploaded ? (
              <div className="space-y-6">
                <IDStatus
                  status={user?.kyc?.status}
                  reupload={() => setkycUploaded(false)}
                />

                <p className="my-8 text-xl font-medium capitalize text-grey_10">
                  {user.kyc.id_type.split("-").join(" ")}
                </p>
                <div>
                  <label className="block pb-2 text-sm text-grey_30">
                    Upload Front
                  </label>
                  <img
                    src={user.kyc.front_image}
                    alt="kyc front view"
                    className={"h-[170px] w-full rounded-20 object-cover"}
                  />
                </div>
                <div>
                  <label className="block pb-2 text-sm text-grey_30">
                    Upload Back
                  </label>
                  <img
                    src={user.kyc.back_image}
                    alt="kyc front view"
                    className={"h-[170px] w-full rounded-20 object-cover"}
                  />
                </div>
              </div>
            ) : (
              <div className="space-y-6">
                <p className="my-8 text-xl font-medium text-grey_10">
                  Upload a Government Issued ID
                </p>
                <div>
                  <p className="text-lg font-bold">
                    Select your preferred valid ID image (international
                    Passport, NIN, drivers license, voters card).
                  </p>
                  <ValidatedRadioBtns
                    flexDirection="column"
                    name="id_type"
                    options={[
                      {
                        label: "International Passport",
                        value: "international-passport",
                      },
                      { label: "Driver’s License", value: "drivers-license" },
                      { label: "Voter’s Card", value: "voters-card" },
                    ]}
                  />
                </div>
                <>
                  <CustomFileUploader
                    required={true}
                    name="front"
                    label="Upload Front"
                    types={["png", "jpg", "jpeg"]}
                  />
                  <CustomFileUploader
                    required={true}
                    name="back"
                    label="Upload Back"
                    types={["png", "jpg", "jpeg"]}
                  />
                </>
              </div>
            )}

            {isOnBoarding ? (
              <div className="mt-8 flex items-stretch justify-start gap-x-6">
                <PrevBtn onClick={onPrevClick} />
                {(cacUploaded === false && requireCac) ||
                  kycUploaded === false ? (
                  <LoadingBtn
                    type="submit"
                    loading={isLoading}
                    disabled={!methods?.formState.isValid}
                    variant="contained"
                    endIcon={<EastRoundedIcon fontSize="large" />}
                  >
                    Next
                  </LoadingBtn>
                ) : (
                  <LoadingBtn
                    type="button"
                    onClick={onNextClick}
                    variant="contained"
                    endIcon={<EastRoundedIcon fontSize="large" />}
                  >
                    Next
                  </LoadingBtn>
                )}
              </div>
            ) : (
              ((cacUploaded === false && requireCac) ||
                kycUploaded === false) && (
                <LoadingBtn
                  type="submit"
                  loading={isLoading}
                  disabled={!methods?.formState.isValid}
                  sx={{ mt: 3 }}
                >
                  Submit
                </LoadingBtn>
              )
            )}
          </div>
        </form>
      </FormProvider>
    );
  },
);

export default KycSettings;
