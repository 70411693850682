import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AccordionWrap({ title, children, ...props }) {
  return (
    <Accordion
      // defaultExpanded
      sx={{
        backgroundColor: "#FDF3E6",
        borderRadius: '20px',
        boxShadow: "none",
        "&::before": {
          display: "none",
        },
      }}
      {...props}
    >
      <AccordionSummary
        sx={{ backgroundColor: "#FDF3E6", padding: '1.2rem', borderRadius: '20px' }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <p className="font-Bai font-semibold text-grey text-xl">{title}</p>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default AccordionWrap;
