import MarkerPinIcon from "components/Vectors/MarkerPinIcon";
import { useGetAllStatesQuery } from "services/generalApi";
import FilterDropDown from "components/mui-components/DropDownWrapper/FilterDropDown";

const StateCity = ({ choice, setChoice }) => {
  const { data: statesData, isLoading } = useGetAllStatesQuery();
  const states = statesData?.data?.map(el => `${el?.name}, Nigeria`) || [];

  return (
    <FilterDropDown
      name="state"
      choice={choice}
      isSearch
      isLoading={isLoading}
      setChoice={setChoice}
      optionIcon={<MarkerPinIcon stroke="#AAAAAA" />}
      selectOption={[{ label: "All States", value: "" }, ...states]}
      bg='bg-[#F2F4F7]'
    />
  );
};

export default StateCity;
