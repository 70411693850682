import {LoadingButton} from "@mui/lab";
import {Button, CircularProgress} from "@mui/material";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import Modal from "components/mui-components/Modal";
import MessageModal from "components/mui-components/Modal/MessageModal";
import SendIcon from "components/Vectors/SendIcon";
import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useAddServiceProviderMutation} from "services/admin/adminGeneralApi";
import {handleError} from "utils";

function AddNewServiceProviderModal({openModal, setopenModal, otherState}) {
  const methods = useForm();
  const [openSendCredential, setopenSendCredential] = useState(false);
  const [addProvider, {isLoading}] = useAddServiceProviderMutation();
  const onSubmit = async data => {
    try {
      await addProvider(data).unwrap();
      setopenModal(false);
      setopenSendCredential(true);
    } catch (error) {
      handleError(error);
      setopenModal(false);
    }
  };

  return (
    <>
      <Modal
        title={`Add New Service Provider`}
        openModal={openModal}
        closeModal={() => setopenModal(false)}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex flex-col gap-y-4"
          >
            <div className="flex items-center justify-between gap-4">
              <ValidatedInput2
                name="first_name"
                label="First Name"
                type="text"
                placeholder="Input First name"
              />
              <ValidatedInput2
                name="last_name"
                label="Last Name"
                type="text"
                placeholder="Input Last name"
              />
            </div>
            <ValidatedInput2
              name="email"
              label="Email address"
              type="email"
              placeholder="Input Email Address"
            />
            <ValidatedInput2
              name="phone_number"
              label="Phone Number"
              type="text"
              placeholder="+234"
            />

            <LoadingButton
              sx={{mt: 5}}
              loadingIndicator={
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size="1.2rem"
                />
              }
              type="submit"
              loading={isLoading}
              fullWidth
              variant="contained"
              color="primary"
            >
              Send Credential
            </LoadingButton>
          </form>
        </FormProvider>
      </Modal>

      <MessageModal
        openModal={openSendCredential}
        icon={<SendIcon />}
        closeModal={() => setopenSendCredential(false)}
        iconBg={"bg-[#FDF2E6]"}
        title={"Credential Sent"}
        buttonTitle="Close"
        description="The credential has been sent to the service provider’s email"
      />
    </>
  );
}

export default AddNewServiceProviderModal;
