export const JobsStatusColor = {
  active: "success",
  suspended: "greyDark",
  terminated: "errorInverse",
  cancelled: "errorInverse",
  closed: "error",
  drafted: "greyBorder",
  ongoing: "secondaryInverse",
  open: "greyBorder",
  completed: "successInverse",
  pending: "greyBorder",
  refunded: "secondaryInverse",
};
export const ProfDashboardLinks = [{
    link: "My Job Offers",
    to: "/service-provider/job-offers",
    countKey: "jobs_count",
  },
  {
    link: "Engagements",
    to: "/service-provider/engagements",
    countKey: "ongoing_engagements_count",
  },
];

export const ListOfLanguages = [
  'English',
  'Yoruba',
  'Hausa',
  'Igbo',
  'Nigerian Pidgin',
  'French',
  'German',
  'Portuguese',
  'Arabic',
  'Mandarin Chhinese',
  'Spanish',
  'Japanese',
  'Russian',
  'Hindi',
]
export const ClientDashboardLinks = [{
    link: "My Jobs",
    to: "/client/jobs",
    countKey: "jobs_count"
  },
  {
    link: "Engagements",
    to: "/client/engagements",
    countKey: "ongoing_engagements_count",
  },
  {
    link: "Discover Jobs",
    to: "/client/discover-jobs",
    countKey: "discover_jobs_count",
  },
];
export const IS_DEV_MODE =
  // window.location.origin.includes("staging") ||
  window.location.origin.includes("staging") ||
  process.env.NODE_ENV === "development";

// console.log(process.env.NODE_ENV)

export const API_URL = IS_DEV_MODE || window.location.origin.includes("staging") ?
  process.env.REACT_APP_STAGING_API_URL :
  process.env.REACT_APP_PRD_API_URL;

console.log(API_URL)

export const PAYSTACK_BASE_CONFIG = {
  publicKey: IS_DEV_MODE ?
    process.env.REACT_APP_PAYSTACK_TEST_PUBLIC_KEY : process.env.REACT_APP_PAYSTACK_LIVE_PUBLIC_KEY,
  channels: ["card", "mobile_money", "ussd", "bank", "qr"],
};