import NewJobCard from 'components/NewJobCard';
import SearchInput from 'components/forms/SearchInput'
import Physical from 'pages/LandingPage/widgets/Dropdowns/Physical';
import React, { useState } from 'react'

const DiscoverJobs = () => {
    const [filterChoice, setFilterChoice] = useState("");


    const initialFilter = {
        type: "",
        category: "",
        order: "newest",
        state: "",
        query: "",
    };
    const [choice, setChoice] = useState(initialFilter);
    return (
        <main className='-mx-4 md:-mx-6 lg:-mx-[4vw] -my-4 lg:-my-9'>


            <header className="bg-grey-10 min-h-[294px] text-white py-12">
                <div className="max-w-[1200px] mx-auto px-4 md:px-6">
                    <h1 className="font-semibold text-[2.5rem] md:text-[4rem] font-Bai">
                        Discover Jobs
                    </h1>

                    <div className="flex flex-col md:flex-row gap-3 md:justify-between lg:items-center mt-12">
                        <SearchInput
                            placeholder='Search Jobs, Location, category...'
                            maxWidth={"max-w-[335px]"}
                            className="!rounded-[12px] !max-h-[56px] !text-white"
                            bg="#34343D"
                        />

                        {/* <div className="flex gap-3 items-center"> */}
                        <Physical
                            filterChoice={filterChoice}
                            setFilterChoice={setFilterChoice}
                            choice={choice}
                            setChoice={setChoice}
                            optionsProps={options}

                            btnClassname={"!bg-[#34343D] !rounded-[12px]"}
                            labelColor="text-white"
                            title={true}
                        />
                        {/* </div> */}
                    </div>
                </div>
            </header>

            <section className="px-4 md:px-6 lg:px-[4vw] py-6 lg:py-12 bg-grey-100">
                <div className="grid grid-cols-1 max-w-[1200px] mx-auto md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {Array(9).fill("").map((_, i) =>
                        <NewJobCard />
                    )}
                </div>

            </section>
        </main>
    )
}

export default DiscoverJobs

const options = [
    {
        name: "category",
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 15V20C13.5 20.8284 14.1716 21.5 15 21.5H20C20.8284 21.5 21.5 20.8284 21.5 20V15C21.5 14.1716 20.8284 13.5 20 13.5H15C14.1716 13.5 13.5 14.1716 13.5 15Z" fill="#440E98" />
            <path d="M2.5 15L2.5 20C2.5 20.8284 3.17157 21.5 4 21.5H9C9.82843 21.5 10.5 20.8284 10.5 20V15C10.5 14.1716 9.82843 13.5 9 13.5H4C3.17157 13.5 2.5 14.1716 2.5 15Z" fill="#440E98" />
            <path d="M2.5 4L2.5 9C2.5 9.82843 3.17157 10.5 4 10.5H9C9.82843 10.5 10.5 9.82843 10.5 9V4C10.5 3.17157 9.82843 2.5 9 2.5L4 2.5C3.17157 2.5 2.5 3.17157 2.5 4Z" fill="#440E98" />
            <path d="M13.5 4V9C13.5 9.82843 14.1716 10.5 15 10.5H20C20.8284 10.5 21.5 9.82843 21.5 9V4C21.5 3.17157 20.8284 2.5 20 2.5L15 2.5C14.1716 2.5 13.5 3.17157 13.5 4Z" fill="#440E98" />
            <path d="M13.5 15V20C13.5 20.8284 14.1716 21.5 15 21.5H20C20.8284 21.5 21.5 20.8284 21.5 20V15C21.5 14.1716 20.8284 13.5 20 13.5H15C14.1716 13.5 13.5 14.1716 13.5 15Z" fill="#440E98" />
            <path d="M2.5 15L2.5 20C2.5 20.8284 3.17157 21.5 4 21.5H9C9.82843 21.5 10.5 20.8284 10.5 20V15C10.5 14.1716 9.82843 13.5 9 13.5H4C3.17157 13.5 2.5 14.1716 2.5 15Z" fill="#440E98" />
            <path d="M2.5 4L2.5 9C2.5 9.82843 3.17157 10.5 4 10.5H9C9.82843 10.5 10.5 9.82843 10.5 9V4C10.5 3.17157 9.82843 2.5 9 2.5L4 2.5C3.17157 2.5 2.5 3.17157 2.5 4Z" fill="#440E98" />
            <path d="M13.5 4V9C13.5 9.82843 14.1716 10.5 15 10.5H20C20.8284 10.5 21.5 9.82843 21.5 9V4C21.5 3.17157 20.8284 2.5 20 2.5L15 2.5C14.1716 2.5 13.5 3.17157 13.5 4Z" fill="#440E98" />
        </svg>,
        selectOption: [
            { label: "All Categories", value: "" },
            { label: "My Interests", value: "interests" },
        ],
    },

    {
        name: "budget",
        icon: (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12Z" fill="#5010B3" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5H19C20.6569 5 22 6.34315 22 8V16C22 17.6569 20.6569 19 19 19H5C3.34315 19 2 17.6569 2 16V8C2 6.34315 3.34315 5 5 5ZM5 6.5C4.44772 6.5 4 6.94772 4 7.5C4 8.05228 4.44772 8.5 5 8.5H7C7.55228 8.5 8 8.05228 8 7.5C8 6.94772 7.55228 6.5 7 6.5H5ZM16 7.5C16 6.94772 16.4477 6.5 17 6.5H19C19.5523 6.5 20 6.94772 20 7.5C20 8.05228 19.5523 8.5 19 8.5H17C16.4477 8.5 16 8.05228 16 7.5ZM5 15.5C4.44772 15.5 4 15.9477 4 16.5C4 17.0523 4.44772 17.5 5 17.5H7C7.55228 17.5 8 17.0523 8 16.5C8 15.9477 7.55228 15.5 7 15.5H5ZM16 16.5C16 15.9477 16.4477 15.5 17 15.5H19C19.5523 15.5 20 15.9477 20 16.5C20 17.0523 19.5523 17.5 19 17.5H17C16.4477 17.5 16 17.0523 16 16.5ZM12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9Z" fill="#5010B3" />
            </svg>
        ),
        selectOption: [
            { label: "All", value: "" },
            { label: "No Budget", value: "no-budget" },
            { label: "Budget Set", value: "budget-set" },
        ],
    },
    {
        name: "Location",
        icon: (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3177 19.3831L6.48612 13.4229C4.55149 10.4135 5.38721 6.40867 8.36399 4.42415C10.5658 2.95627 13.4343 2.95627 15.6361 4.42415C18.6129 6.40867 19.4486 10.4135 17.514 13.4229L13.6824 19.3831C12.8951 20.6078 11.105 20.6078 10.3177 19.3831ZM12 13.5001C13.933 13.5001 15.5 11.9331 15.5 10.0001C15.5 8.06712 13.933 6.50011 12 6.50011C10.067 6.50011 8.50004 8.06712 8.50004 10.0001C8.50004 11.9331 10.067 13.5001 12 13.5001Z" fill="#5010B3" />
            </svg>
        ),
        selectOption: [
            { label: "State", value: "state" },
            { label: "City", value: "city" },
        ],
    },
];