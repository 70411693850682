import DropDownWrapper from "components/mui-components/DropDownWrapper";
import {FormProvider, useForm} from "react-hook-form";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {Slider} from "@mui/material";
import InputField2 from "components/forms/InputField2";
import {useEffect, useState} from "react";
import ValidatedRadioBtns from "components/forms/ValidatedRadioBtns";
import {debounce} from "utils";

const RangeDropdown = ({setParams, name}) => {
  const {control, watch, ...methods} = useForm({
    defaultValues: {
      price_option: "no_budget",
    },
  });
  const priceOpt = watch("price_option");

  const [value, setValue] = useState([5000, 10000]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setParams(prev => ({...prev, [name]: newValue}));
  };

  useEffect(() => {
    if (value[1] <= 0 || isNaN(value[1])) {
      setValue([0, 100]);
    }
  }, [value]);

  function getNum(val) {
    if (isNaN(val)) {
      return 0;
    }
    return val;
  }

  function handleInput(e, index) {
    let newValue = value;
    newValue[index] = e.target.valueAsNumber;
    setValue(newValue);
  }

  function setFilterValue() {
    setParams(prev => ({
      ...prev,
      [name]: priceOpt !== "no_budget" ? value : [],
    }));
  }
  const debouncedHandleInput = debounce(setFilterValue, 1000);
  useEffect(() => {
    if (value) {
      debouncedHandleInput();
    }
  }, [debouncedHandleInput, value]);

  return (
    <div className="filter-select flex h-[56px] items-center gap-1 rounded-xl bg-[#F9FAFB] p-4  ">
      <span className="text-base font-normal text-grey_30  ">Budget: </span>

      <DropDownWrapper
        position="right"
        closeOnBtnClick={false}
        action={
          <div className="flex cursor-pointer items-center gap-3 text-base font-normal capitalize text-grey_10 ">
            <>
              {priceOpt === "no_budget"
                ? "No Budget"
                : `₦${getNum(value[0])?.toLocaleString() || 0} - ₦${getNum(
                    value[1],
                  )?.toLocaleString()}`}{" "}
            </>
            <span>
              <KeyboardArrowDownRoundedIcon />
            </span>
          </div>
        }
      >
        <FormProvider {...methods}>
          <form noValidate className="relative">
            <div className="flex cursor-pointer flex-col gap-3  ">
              <div className="">
                <ValidatedRadioBtns
                  name="price_option"
                  options={[
                    {label: "No Budget", value: "no_budget"},
                    {label: "Price Range", value: "price_range"},
                  ]}
                />
              </div>

              <div className="">
                {priceOpt === "price_range" && (
                  <div className="">
                    <div className="px-2 ">
                      <Slider
                        sx={{
                          width: "100%",

                          "& .MuiSlider-rail": {
                            background: "#E4E7EC",
                          },
                        }}
                        min={100}
                        max={1000000}
                        getAriaLabel={() => "Price range"}
                        value={value}
                        onChange={handleChange}
                        disableSwap
                        valueLabelDisplay="off"
                      />
                    </div>
                    <div className="flex items-center justify-between gap-4 ">
                      <InputField2
                        bordered={false}
                        label=""
                        mainValue={value[0]}
                        onChange={e => {
                          handleInput(e, 0);
                        }}
                        type="number"
                        placeholder="From"
                        prefixIcon={"₦"}
                      />
                      <InputField2
                        bordered={false}
                        label=""
                        type="number"
                        mainValue={value[1]}
                        onChange={e => {
                          handleInput(e, 1);
                        }}
                        placeholder="To"
                        prefixIcon={"₦"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </DropDownWrapper>
    </div>
  );
};
export default RangeDropdown;
