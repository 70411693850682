import { KeyboardArrowRightOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'

const NewStatCard = ({ text, value, btnColor = "#00BFFF", link }) => {
    return (
        <div className='lg:col-span-4 p-4 grid border border-[#DCDCE0] rounded-2xl'>
            <p className="text-grey-10 text-xs font-medium">
                {text}
            </p>

            <div className="flex justify-between mt-2.5">
                <p className="font-Bai font-semibold text-xl md:text-2xl text-[#444444]">
                    {value}
                </p>

                <IconButton
                    href={link}
                    sx={{ backgroundColor: btnColor }}
                    size='small'
                >
                    <KeyboardArrowRightOutlined fontSize="medium" color="white" />
                </IconButton>
            </div>
        </div>
    )
}

export default NewStatCard