import React from "react";
import LocationIcon from "components/Vectors/LocationIcon";
import {Skeleton} from "@mui/material";

function LocationCityCard({data, isLoading, type}) {
  const activeStatusLength = data?.filter(
    item => item.state.status.toLowerCase() === "active",
  ).length;

  const inActiveStatusLength = data?.filter(
    item => item.state.status.toLowerCase() !== "active",
  ).length;

  //   const {
  //     // type = "States",
  //     noOfType = "12",
  //     noOfActive = "12",
  //     noOfInActive = "0",
  //   } = data;

  return (
    <div className="flex justify-between rounded-[20px] border border-grey_70 bg-grey_70 p-4">
      <div className="left flex-1">
        <div className="flex items-center gap-2">
          <div className="flex h-[52px] w-[52px] items-center justify-center rounded-[10px] bg-white">
            <LocationIcon width={"24px"} height="24px" stroke={"#e98100"} />
          </div>
          <p className="text-base font-bold text-grey_20">{type}</p>
        </div>
        <p className="mt-3 font-Bai text-[32px] font-semibold text-grey_20">
          {isLoading ? (
            <Skeleton variant="rounded" width={30} height={30} />
          ) : (
            <>{data?.length}</>
          )}
        </p>
      </div>
      <div className="right basis-[60%] rounded-xl bg-white p-4">
        <div className="flex w-4/5 justify-between">
          <div className="">
            <p className="mb-4 text-base font-bold text-success">Active</p>
            <p className="font-Bai text-2xl font-semibold text-grey">
              {" "}
              {isLoading ? (
                <Skeleton variant="rounded" width={30} height={30} />
              ) : (
                <>{activeStatusLength}</>
              )}
            </p>
          </div>

          <div className="">
            <p className="mb-4 text-base font-bold text-error">Inactive</p>
            <p className="font-Bai text-2xl font-semibold text-grey">
              {" "}
              {isLoading ? (
                <Skeleton variant="rounded" width={30} height={30} />
              ) : (
                <>{inActiveStatusLength}</>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationCityCard;
