import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {Button, CircularProgress, IconButton} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {handleError} from "utils";

const MessageModal2 = ({
  otherChildren,
  closeModal,
  openModal,
  title,
  icon,
  description,
  otherClasses,
  iconBg,
  actionBtnText,
  otherState,
  ...props
}) => {
  const handleClick = async () => {
    try {
      await otherState();
      closeModal();
    } catch (error) {
      handleError(error);
      closeModal();
    }
  };

  return (
    <div>
      <Modal open={openModal} onClose={closeModal}>
        <Fade in={openModal}>
          <div className="flex h-full items-center justify-center  ">
            <div className="flex w-[90%] max-w-[570px] flex-col justify-center rounded-20 bg-white p-[32px] md:w-fit ">
              <div
                className="flex h-[40px] w-[40px]  items-center justify-center self-end rounded-full bg-[#F9FAFB] "
                onClick={closeModal}
              >
                <IconButton>
                  <CloseRoundedIcon fontSize="15px" />
                </IconButton>
              </div>
              <h3 className="mt-[34px] text-center font-Bai text-[32px] font-semibold text-[#1D1D1D] ">
                {title}
              </h3>
              <p className="text-prop mt-[28px] text-center text-base font-[500] text-grey_30 ">
                {description}
              </p>

              <div className="mt-[70px] flex gap-3 ">
                <Button
                  variant="contained"
                  color="primaryInverse2"
                  fullWidth
                  onClick={closeModal}
                >
                  Nevermind
                </Button>

                <LoadingButton
                  loadingIndicator={
                    <CircularProgress
                      sx={{
                        color: "#fff",
                      }}
                      size="1.2rem"
                    />
                  }
                  loading={props.isLoading}
                  fullWidth
                  variant="contained"
                  color="error"
                  onClick={handleClick}
                >
                  {actionBtnText}
                </LoadingButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default MessageModal2;
