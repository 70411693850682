import React from "react";

function AccountBalWalletIcon() {
  return (
    <svg
      width="47"
      height="46"
      viewBox="0 0 47 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0C3.13401 0 0 3.13401 0 7V39C0 42.866 3.13401 46 7 46H39C42.866 46 46 42.866 46 39V38H24C20.6863 38 18 35.3137 18 32V14C18 10.6863 20.6863 8 24 8H46V7C46 3.13401 42.866 0 39 0H7Z"
        fill="#F8D8B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 13C22.8954 13 22 13.8954 22 15V31C22 32.1046 22.8954 33 24 33H45C46.1046 33 47 32.1046 47 31V15C47 13.8954 46.1046 13 45 13H24ZM32 27C34.2091 27 36 25.2091 36 23C36 20.7909 34.2091 19 32 19C29.7909 19 28 20.7909 28 23C28 25.2091 29.7909 27 32 27Z"
        fill="#E98100"
      />
    </svg>
  );
}

export default AccountBalWalletIcon;
