function SingleLogoIcon({ ...props }) {
  return (
    <svg
      width="25"
      height="29"
      viewBox="0 0 25 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.47157 5.25149C4.61988 2.57957 8.10292 0.847274 11.7162 0.743728C14.4342 0.604355 17.1909 1.37011 19.3974 2.83906C22.9678 5.17094 25.0288 9.38595 24.3607 13.3964C23.9671 15.5302 22.9013 17.5143 21.5901 19.2901C20.2831 19.2785 18.9775 19.2875 17.6719 19.2862C16.8902 19.2734 16.0752 19.1865 15.3947 18.8004C14.2512 18.2034 13.4903 17.0323 13.4834 15.8165C13.4834 14.6736 13.482 13.5307 13.4861 12.3865C15.5901 12.3878 17.6941 12.3852 19.798 12.3878C19.8105 10.7833 19.8036 9.17885 19.8022 7.5744C17.7024 7.57951 15.6026 7.57696 13.5042 7.57696C11.7619 9.17885 10.0212 10.7821 8.28583 12.3903C8.36763 14.3016 8.05166 16.2653 8.66421 18.1267C9.38078 20.3742 11.1882 22.2995 13.4931 23.2827C14.7045 23.817 16.053 24.0548 17.3919 24.0702C15.7731 25.5864 14.0863 27.0464 12.2651 28.3529C10.4882 27.0643 8.81949 25.6491 7.24222 24.1571C5.24913 22.2522 3.39604 20.1965 1.97399 17.8889C0.963601 16.2295 0.170807 14.4077 0.0529962 12.4887C-0.0731304 9.91526 0.787576 7.30981 2.47157 5.25149ZM8.30805 5.26427C8.29833 6.81121 8.30528 8.3581 8.30528 9.90504C8.3635 9.89096 8.47989 9.8628 8.53952 9.84748C8.98301 9.35399 9.50553 8.92828 9.98785 8.46806C11.087 7.47216 12.15 6.43915 13.2713 5.46625C13.2907 5.41516 13.3309 5.31541 13.3503 5.26557C11.6691 5.25791 9.98927 5.25916 8.30805 5.26427Z"
        fill={props.color || "#F6A001"}
      />
    </svg>
  );
}

export default SingleLogoIcon;
