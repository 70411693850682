import sideBarReducer from "./slice/sideBarSlice";
import userModalReducer from "./slice/userModalSlice";
import postModalReducer from "./slice/postModalSlice";
import ToastReducer from "./slice/ToasterSlice";
import ApiQueryReducer from "./slice/ApiSlice";
import {combineReducers} from "@reduxjs/toolkit";
import {authApi} from "services/auth.api";
import {generalApi} from "services/generalApi";
import AuthReducer from "./slice/AuthSlice";
import {adminProfileApi} from "services/admin/adminProfileApi";
import {adminGeneralApi} from "services/admin/adminGeneralApi";
import {userRegistrationApi} from "services/user/userRegApi";
import {clientGeneralApi} from "services/user/clientGeneralApi";
import {providerGeneralApi} from "services/user/providerGeneralApi";

const rootReducer = combineReducers({
  sidebarOpen: sideBarReducer,
  userModal: userModalReducer,
  postModal: postModalReducer,
  auth: AuthReducer,
  toasts: ToastReducer,
  apiQuery: ApiQueryReducer,
  [authApi.reducerPath]: authApi.reducer,
  [generalApi.reducerPath]: generalApi.reducer,
  [adminProfileApi.reducerPath]: adminProfileApi.reducer,
  [adminGeneralApi.reducerPath]: adminGeneralApi.reducer,
  [userRegistrationApi.reducerPath]: userRegistrationApi.reducer,
  [clientGeneralApi.reducerPath]: clientGeneralApi.reducer,
  [providerGeneralApi.reducerPath]: providerGeneralApi.reducer,
});

export default rootReducer;
