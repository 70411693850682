import StarIcon from "@mui/icons-material/Star";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
// import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import {handleError} from "utils";
import {useNavigate} from "react-router-dom";
import {showToast} from "redux/store.hooks";
import {
  useAddServiceProviderToFavoriteMutation,
  useDeleteServiceProviderFromFavoriteMutation,
} from "services/user/clientGeneralApi";
import LoadingBtn from "components/mui-components/LoadingButton";
import {BootstrapTooltip} from "components/TicketCards";
import {useEffect, useState} from "react";
import {useGetUser} from "hooks/getUserHook";
import UserAvatar from "./mui-components/TableComponent/UserAvatar";

function ServiceProviderCard({data}) {
  const [favorite, {isLoading: isFavoriting}] =
    useAddServiceProviderToFavoriteMutation();
  const [removeFromFavourite, {isLoading: isUnfavouriting}] =
    useDeleteServiceProviderFromFavoriteMutation();
  const {user} = useGetUser();
  const navigate = useNavigate();
  const [isFav, setIsFav] = useState(false);

  useEffect(() => {
    setIsFav(data?.is_favourite);
  }, [data?.is_favourite]);

  const id = data?.id;
  const handleFavoriteClick = () => {
    data?.is_favourite
      ? removeFromFavourite({
          id,
          body: {service_provider_id: id},
        })
          .unwrap()
          .then(res => {
            setIsFav(false);
            showToast(res?.message);
          })
          .catch(err => {
            handleError(err);
          })
      : favorite({service_provider_id: id})
          .unwrap()
          .then(res => {
            setIsFav(true);
            showToast(res?.message);
          })
          .catch(err => {
            handleError(err);
          });
  };
  return (
    <div className="relative rounded-24 bg-grey_70 p-4" key={id}>
      {/* {user?.id !== data?.id && (
        <BootstrapTooltip
          title={`${isFav ? "Remove from" : "Add to"} favorites`}
          placement="top"
        >
          <div className="grey_90 absolute top-4 right-4 flex h-[48px] w-[48px] items-center justify-center rounded-2xl bg-white">
            <LoadingBtn
              variant="text"
              loading={isFavoriting || isUnfavouriting}
              onClick={handleFavoriteClick}
              color="info"
            >
              {isFav ? (
                <FavoriteOutlinedIcon sx={{color: "#D92D20"}} />
              ) : (
                <FavoriteBorderIcon sx={{color: "#6E726E"}} />
              )}
            </LoadingBtn>
          </div>
        </BootstrapTooltip>
      )} */}

      <button
        type="button"
        onClick={() => {
          navigate(`/user-profile/${data?.id}`);
        }}
        className="flex w-full flex-col items-start"
      >
        <UserAvatar
          size={80}
          showBadge={data?.is_verified_account}
          src={data?.professional_information?.business_logo || data?.avatar}
        />

        <p className="mt-2 text-lg font-bold text-grey_20">
          {data.full_name || "Mirian Achonwa"}
        </p>
        <p className="mt-0 text-sm font-normal text-grey_30">
          @{data?.username || "@mimi"}
        </p>

        <div className="mt-2 flex w-full items-center justify-between">
          <div className="flex items-center gap-1">
            <PlaceOutlinedIcon color="black" fontSize="small" />
            <p className="text-sm font-semibold text-grey_20">
              {data.location}
            </p>
          </div>

          <div className=" flex  items-center gap-0.5 ">
            <StarIcon color="primary" fontSize="small" />
            <p className="text-base font-medium text-primary">
              {data.rating || "4.0"}
            </p>
          </div>
        </div>

        <p className="mt-2 font-Bai text-xl font-semibold text-[#444444]">
          {data.priceRange}
        </p>
      </button>
    </div>
  );
}

export default ServiceProviderCard;
