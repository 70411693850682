import Physical from "./Dropdowns/Physical";
import StateCity from "./Dropdowns/StateCity";
import Category from "./Dropdowns/Category";

import SearchInput from "components/forms/SearchInput";
import TablePagination from "components/mui-components/TablePagination";
import { useEffect, useState } from "react";
import JobCard from "pages/ServiceProviders/MarketPlace/Widgets/JobCard";
import {
  useGetAllJobsQuery,
  useGetAllServiceCategoriesQuery,
} from "services/generalApi";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";
import EmptyResponse from "components/mui-components/EmptyResponse";
import JobIcon from "components/Vectors/JobIcon";
import { debounce, removeEmpty } from "utils";
import { useLocation } from "react-router-dom";
import { CategoriesSlider } from "./components/FirstSlider";
import { Button } from "@mui/material";

const AllJobs = () => {
  const [rowPerPage, setRowPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const [filterChoice, setFilterChoice] = useState("");
  const [selectedCt, setSelectedCt] = useState("");
  const { data: categories } = useGetAllServiceCategoriesQuery();

  const initialFilter = {
    type: "",
    category: "",
    order: "newest",
    state: "",
    query: "",
  };
  const [choice, setChoice] = useState(initialFilter);

  const location = useLocation();
  useEffect(() => {
    if (location?.state) {
      setChoice(prev => ({ ...prev, category: location.state?.category }));
      setSelectedCt({ ...location?.state, name: location.state?.category });
    }
  }, [location.state]);

  const queryParams = {
    location_type: choice?.type,
    order: choice?.order,
    state: choice?.state,
    category: choice?.category,
    query: choice?.query,
    page,
    per_page: rowPerPage,
    is_paginated: "yes",
  };

  const {
    data: allJobs,
    isLoading: isJobsLoading,
    error,
  } = useGetAllJobsQuery(removeEmpty(queryParams), {
    refetchOnMountOrArgChange: true,
  });

  const jobs = allJobs?.data;

  const meta = {
    totalPages: jobs?.last_page,
    totalCount: jobs?.total,
    currentPage: jobs?.current_page,
  };

  function handleInput(e) {
    setChoice(prev => ({ ...prev, query: e.target.value }));
  }

  const debouncedHandleInput = debounce(handleInput, 1000);

  if (isJobsLoading) {
    return <CircularLoader />;
  }
  if (error) {
    return <ErrorMsg error={error} />;
  }

  return (
    <>
      <main className="mb-64 mt-[-1rem] flex flex-col">
        <section className="relative mx-auto mb-14 w-[90%]">
          <p className="text-center text-sm font-medium text-primary md:mb-1">
            All Categories <span className="text-grey_30">/ All Jobs</span>
          </p>
          <h1 className="p-0 text-center font-Bai text-5xl font-semibold text-black_text2 md:text-[80px]">
            {selectedCt?.name || "All Jobs"}
          </h1>
          <p className="font-inter mx-auto mt-4 max-w-[800px] text-center text-base font-normal text-[#444444] md:text-xl">
            {selectedCt?.description ||
              "From cleaning to accounting, app design to babysitting etc."}
          </p>
        </section>
        <div
          style={{
            backgroundImage: `url('${selectedCt?.cover_photo || "/images/all-jobs-default.png"
              }'), linear-gradient(to right, #eee4d7, #eee4d7)`,
          }}
          className="relative flex h-[341px] flex-col items-center bg-cover bg-center bg-no-repeat px-6"
        >
          <div className="-mt-8 flex flex-wrap items-center justify-center gap-2 sm:-mt-7">
            <Physical
              filterChoice={filterChoice}
              setFilterChoice={setFilterChoice}
              choice={choice}
              setChoice={setChoice}
            />
            <StateCity
              filterChoice={filterChoice}
              setFilterChoice={setFilterChoice}
              choice={choice}
              setChoice={setChoice}
            />
            <Category
              filterChoice={filterChoice}
              setFilterChoice={setFilterChoice}
              choice={choice}
              setSelectedCt={setSelectedCt}
              setChoice={setChoice}
            />
            <Button
              variant="contained"
              onClick={() => {
                setChoice(initialFilter);
                setSelectedCt(null);
              }}
            >
              Clear Filter
            </Button>
          </div>
          <div className="absolute bottom-[40px] z-0 mx-auto flex w-[80%] justify-center">
            <SearchInput
              value={choice.query}
              onChange={debouncedHandleInput}
              maxWidth={"max-w-[628px]"}
              placeholder="Search Job..."
              bg="white"
              className='!bg-[#F2F4F7]'
            />
          </div>
        </div>
        {jobs?.data?.length > 0 ? (
          <div className="mt-[50px] grid grid-cols-1 place-content-center gap-5 px-6 sm:grid-cols-2 md:gap-[40px] lg:grid-cols-3 lg:px-[8vw]">
            {jobs?.data
              .filter(data => data?.job_title?.toLocaleLowerCase().includes(choice.query.toLocaleLowerCase()))
              .map((job, index) => (
                <JobCard hideLikeBtn={true} key={index} job={job} />
              ))}
          </div>
        ) : (
          <EmptyResponse icon={<JobIcon />} message={"No Job Found"} />
        )}

        {jobs?.data?.length > 0 && (
          <div className="mt-[10px] px-6 lg:px-[8vw]">
            <TablePagination
              meta={meta}
              from={jobs?.from}
              to={jobs?.to}
              page={page}
              setPage={setPage}
              perPage={rowPerPage}
              setPerPage={setRowPerPage}
            />
          </div>
        )}

        <div className="inner mx-auto mt-6 w-[90%] max-w-[1300px]">
          <p className="pb-12 text-center text-3xl font-semibold">
            Explore more <span className="text-primary">categories</span>
          </p>
          <CategoriesSlider
            desktopSlides={4}
            categories={categories?.data || []}
          />
        </div>
      </main>
    </>
  );
};

export default AllJobs;
