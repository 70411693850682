import NewAuthLayout from "templates/AuthLayout/NewAuthLayout";
import {LoadingButton} from "@mui/lab";
import {Button} from "@mui/material";
import {useEffect, useState} from "react";
import OtpInput from "components/forms/OtpInput";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectEmail, setUserOTP} from "redux/slice/AuthSlice";

const AdminEnterOTP = () => {
  const [counter, setCounter] = useState(59);
  const navigate = useNavigate();
  const [otp, setotp] = useState("");
  const [error, seterror] = useState(false);
  const dispatch = useDispatch();

  const email = useSelector(selectEmail);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearTimeout(timer);
  }, [counter]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (otp.length < 4) {
      seterror(true);
    } else {
      dispatch(setUserOTP(otp));
    }
  };

  return (
    <NewAuthLayout>
      <div className="flex flex-col items-center">
        <h3 className="mb-3 text-center font-Bai text-2xl font-semibold text-[#362E1F] md:text-4xl">
          Verify It's you
        </h3>
        <p className="max-w-[411px] text-center text-sm font-normal text-grey_30 md:text-base">
          We sent a one time verification code to{" "}
          <span className="text-primary">{email} </span>
          {/* and
          <span className="text-primary">+2348098765432</span> */}
        </p>

        <div className="my-2">
          <OtpInput
            error={error}
            value={otp}
            onChange={val => {
              if (val.length === 4) seterror(false);
              setotp(val);
            }}
          />
        </div>

        <p className="mb-2 text-sm font-medium text-primary">
          00:{counter < 10 ? "0" + counter : counter}
        </p>
        <p className="text-center text-base font-normal text-[#6E726E]">
          Didn’t Recieve OTP,{" "}
          <span className="cursor-pointer text-[#F6A001]">Resend Code</span>
        </p>
        <LoadingButton
          variant="contained"
          fullWidth
          sx={{mt: 4, mb: 2}}
          onClick={handleSubmit}
        >
          Verify
        </LoadingButton>

        <Button variant="" fullWidth onClick={() => navigate("/admin/login")}>
          Cancel
        </Button>
      </div>
    </NewAuthLayout>
  );
};

export default AdminEnterOTP;
