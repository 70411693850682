import React from "react";

const RequestOutline = ({stroke, transparent, width, height}) => {
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!transparent && <rect width="32" height="32" rx="16" fill="#F3ECFD" />}
      <path
        d="M15.0001 14.0015C15.1175 13.6676 15.3494 13.386 15.6546 13.2066C15.9597 13.0273 16.3185 12.9617 16.6674 13.0216C17.0163 13.0814 17.3327 13.2628 17.5607 13.5336C17.7887 13.8044 17.9134 14.1471 17.9129 14.5011C17.9129 15.5004 16.414 16 16.414 16M16.4333 18H16.44M16.3334 21.3333C19.463 21.3333 22.0001 18.7963 22.0001 15.6667C22.0001 12.5371 19.463 10 16.3334 10C13.2038 10 10.6667 12.5371 10.6667 15.6667C10.6667 16.3 10.7706 16.9091 10.9623 17.4777C11.0345 17.6917 11.0705 17.7987 11.077 17.881C11.0835 17.9621 11.0786 18.019 11.0585 18.098C11.0382 18.1779 10.9933 18.261 10.9035 18.4272L9.81303 20.4456C9.65749 20.7335 9.57972 20.8774 9.59713 20.9885C9.61229 21.0853 9.66924 21.1705 9.75286 21.2215C9.84886 21.2801 10.0116 21.2632 10.3371 21.2296L13.7511 20.8767C13.8545 20.866 13.9062 20.8606 13.9533 20.8624C13.9997 20.8642 14.0324 20.8686 14.0776 20.879C14.1235 20.8896 14.1813 20.9118 14.2968 20.9564C14.9289 21.1999 15.6156 21.3333 16.3334 21.3333Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RequestOutline;
