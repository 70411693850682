import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {useFormContext} from "react-hook-form";

const PublishJob = ({locationType, stateCity, isEditing}) => {
  const {watch} = useFormContext();
  const image = watch("image");
  const category = watch("category");
  const title = watch("job_title");
  const priceAmount = watch("price_amount");
  const duration = watch("duration");
  const durationType = watch("duration_type");
  const imageLink =
    image?.length > 0
      ? typeof image[0] === "string"
        ? image[0]
        : URL.createObjectURL(image[0]) || ""
      : "";

  return (
    <section className="">
      <h1
        className={`p-3 pb-8 font-Bai text-2xl font-bold md:p-10 md:text-[2rem] ${
          isEditing ? "md:pb-8" : "md:pb-12"
        }`}
      >
        Preview & Publish
      </h1>
      {/*<div className="h-px bg-grey_60"></div>*/}
      <div className="flex flex-col gap-y-5 p-3 pt-5 md:p-8">
        <p className="text-grey_20">
          This is how it will look like in the marketplace
        </p>
        <div
          className={`relative flex min-h-[350px] w-full flex-col items-center justify-between rounded-[39px] bg-grey_80 bg-cover bg-center bg-no-repeat p-3`}
        >
          <div className="relative h-[220px] w-full rounded-25">
            <img
              src={imageLink || "/images/broken-image.png"}
              alt=""
              className="absolute inset-0 h-full w-full rounded-[29px] object-cover"
            />
            <span className="absolute left-2 top-2 rounded-[50px] bg-white px-3 py-1 text-xs capitalize text-text md:text-sm">
              active
            </span>
          </div>

          <div className="py-45 relative w-full p-2">
            <p className="text-xs font-medium capitalize text-primary md:text-sm">
              {category}
            </p>
            <p className="font-Bai text-base font-semibold text-ltext md:text-lg">
              {title}
            </p>
            <p className="my-2 flex items-center gap-x-1 text-xs text-ltext md:text-sm">
              <FmdGoodOutlinedIcon /> Location{" "}
              <span className="capitalize">
                {locationType === "Virtual"
                  ? "(Virtual)"
                  : `(Physical) - ${stateCity.state} ${stateCity.city}`}
              </span>
            </p>
            <p className="my-2 flex items-center gap-x-1 text-xs text-ltext md:text-sm">
              <AccessTimeIcon /> Expected Duration:{" "}
              <span>{`${duration} ${
                duration === 1 ? durationType.slice(0, -1) : durationType
              }`}</span>
            </p>
            <p className="font-Bai text-base font-semibold text-ltext md:text-xl">
              {priceAmount ? (
                <>₦{Number(priceAmount).toLocaleString()}</>
              ) : (
                "No Budget"
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PublishJob;
