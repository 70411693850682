import {Checkbox, CircularProgress, FormControlLabel} from "@mui/material";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import MyModal from "components/mui-components/Modal";
import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {LoadingButton} from "@mui/lab";
import {handleError} from "utils";
import {useUpdateStateMutation} from "services/admin/adminGeneralApi";
import {useEffect} from "react";
import {showToast} from "redux/store.hooks";

const EditStateModal = ({
  openModal,
  setopenModal,
  stateID,
  stateName,
  stateAvailability,
}) => {
  const methods = useForm();
  const {setValue} = methods;
  const [availability, setAvailability] = useState(stateAvailability);

  const [updateState, {isLoading}] = useUpdateStateMutation();

  useEffect(() => {
    setTimeout(() => {
      setValue("state", stateName);
    });
  }, [setValue, stateName]);

  const handleChange = event => {
    if (event.target.checked === true) {
      setAvailability("Physical Availability");
    } else {
      setAvailability("Virtual Only");
    }
  };

  const onSubmit = async data => {
    const body = {name: data.state, availability};

    try {
      let res = await updateState({stateID, ...body}).unwrap();
      showToast(res?.message, "success");
      setopenModal(false);
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <>
      <MyModal
        openModal={openModal}
        closeModal={() => setopenModal(false)}
        title="Edit State"
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex h-[290px] flex-col justify-between"
          >
            <div>
              <ValidatedInput2
                label="State"
                name="state"
                placeholder="Input state"
              />
              <FormControlLabel
                sx={{mt: 2}}
                value="available"
                control={
                  <Checkbox
                    onChange={handleChange}
                    defaultChecked={
                      stateAvailability.toLowerCase() ===
                      "physical availability"
                    }
                  />
                }
                label="Available for physical jobs"
              />
            </div>

            <LoadingButton
              sx={{mt: 4}}
              loadingIndicator={
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size="1.2rem"
                />
              }
              type="submit"
              loading={isLoading}
              fullWidth
              variant="contained"
              color="primary"
            >
              Update State
            </LoadingButton>
          </form>
        </FormProvider>
      </MyModal>
    </>
  );
};

export default EditStateModal;
