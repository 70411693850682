import React from "react";

function CheckVerifiedIcon({color}) {
  return (
    <div>
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.5007 29.4997L26.834 34.833L38.834 22.833M45.2371 10.829C45.7863 12.1571 46.8403 13.2127 48.1675 13.7639L52.8213 15.6916C54.1494 16.2418 55.2046 17.297 55.7547 18.6252C56.3049 19.9533 56.3049 21.4457 55.7547 22.7738L53.8284 27.4245C53.2781 28.7532 53.2773 30.2471 53.8302 31.5752L55.7531 36.2245C56.0258 36.8823 56.1663 37.5874 56.1664 38.2995C56.1665 39.0117 56.0263 39.7168 55.7538 40.3747C55.4813 41.0327 55.0818 41.6304 54.5782 42.1339C54.0746 42.6374 53.4767 43.0366 52.8187 43.3089L48.1683 45.2353C46.8402 45.7844 45.7846 46.8385 45.2335 48.1657L43.3058 52.8197C42.7557 54.1479 41.7005 55.2031 40.3724 55.7533C39.0443 56.3034 37.552 56.3034 36.2239 55.7533L31.5734 53.8269C30.2452 53.2781 28.7535 53.2792 27.4262 53.83L22.7724 55.755C21.445 56.3039 19.954 56.3034 18.627 55.7537C17.2999 55.204 16.2453 54.1501 15.6948 52.8233L13.7666 48.1679C13.2175 46.8399 12.1634 45.7842 10.8362 45.2331L6.18242 43.3053C4.85488 42.7554 3.8 41.7009 3.24967 40.3735C2.69934 39.046 2.69859 37.5544 3.2476 36.2265L5.17389 31.5758C5.72266 30.2476 5.72154 28.7558 5.17077 27.4284L3.24724 22.7711C2.97453 22.1133 2.8341 21.4082 2.83398 20.696C2.83387 19.9839 2.97407 19.2787 3.24658 18.6208C3.51909 17.9629 3.91856 17.3651 4.42216 16.8617C4.92577 16.3582 5.52365 15.9589 6.18163 15.6866L10.8321 13.7603C12.159 13.2116 13.2139 12.1589 13.7655 10.8332L15.6931 6.17917C16.2433 4.85099 17.2985 3.79577 18.6266 3.24562C19.9547 2.69547 21.447 2.69547 22.7751 3.24562L27.4256 5.17198C28.7537 5.72078 30.2455 5.71965 31.5728 5.16886L36.2286 3.24861C37.5565 2.69877 39.0485 2.69888 40.3763 3.24892C41.7042 3.79896 42.7592 4.85388 43.3095 6.18171L45.2377 10.8371L45.2371 10.829Z"
          stroke={color || "#3B3D3B"}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default CheckVerifiedIcon;
