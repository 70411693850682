import AutoCompleteField from "components/forms/AutoCompleteField";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import {PrevBtn} from "..";
import {Avatar, Button, CircularProgress} from "@mui/material";
import {useForm, FormProvider} from "react-hook-form";
import {handleError} from "utils";
import {useGetAllBanksQuery} from "services/generalApi";
import {
  useValidateBankAccountMutation,
  useSubmitBankAccountMutation,
} from "services/user/userRegApi";
import {LoadingButton} from "@mui/lab";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import {useCallback, useEffect} from "react";
import {useState} from "react";
import {useGetUser} from "hooks/getUserHook";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {useNavigate} from "react-router-dom";

const AccountDetails = ({
  onNextClick,
  onPrevClick,
  isOnboarding = true,
  isOnboardingComplete,
}) => {
  const [acctName, setAcctName] = useState("");
  const methods = useForm({
    mode: "all",
  });
  const {watch, resetField} = methods;
  const watchBank = watch("bank");
  const watchAcctNumber = watch("account_number");
  const navigate = useNavigate();

  const {data: allBanks, isLoading: isAllBanksLoading} = useGetAllBanksQuery();
  const [validateBank, {isLoading: isValidateLoading, isSuccess}] =
    useValidateBankAccountMutation();
  const [submitBank, {isLoading: isSubmitLoading}] =
    useSubmitBankAccountMutation();
  const {user} = useGetUser();

  const bankCode = allBanks?.data.find(
    bank => bank?.name.toLowerCase() === watchBank?.toLowerCase(),
  )?.code;

  const handleValidate = useCallback(async () => {
    const formData = new FormData();
    formData.append("bank_code", bankCode);
    formData.append("account_number", watchAcctNumber);
    try {
      let res = await validateBank(formData).unwrap();
      setAcctName(res?.data?.account_name);
    } catch (error) {
      handleError(error);
    }
  }, [bankCode, validateBank, watchAcctNumber]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (watchAcctNumber && String(watchAcctNumber)?.length >= 9) {
        handleValidate();
      }
    }, 1700);
    if (watchAcctNumber && String(watchAcctNumber)?.length < 5) {
      setAcctName("");
    }
    return () => clearTimeout(timer);
  }, [handleValidate, watchAcctNumber]);

  useEffect(() => {
    resetField("account_number");
    setAcctName("");
  }, [resetField, watchBank]);

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("bank_code", bankCode);
    formData.append("account_number", watchAcctNumber);

    try {
      await submitBank(formData).unwrap();
      if (isOnboarding) {
        if (!isOnboardingComplete) {
          navigate(
            user?.user_type?.toLowerCase() === "service provider"
              ? `/service-provider/`
              : `client`,
          );
        } else {
          onNextClick();
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const [isAccountAdded, setisAccountAdded] = useState(
    user?.bank_account ? true : false,
  );

  return (
    <section
      className={`w-full rounded-24 bg-white ${
        isOnboarding ? "py-10" : "py-4"
      }`}
    >
      <div className="flex flex-col items-start px-6 md:px-8">
        <h1
          className={
            isOnboarding
              ? "text-prop font-Bai text-2xl font-bold md:text-[2rem]"
              : "font-Bai text-3xl font-semibold text-grey_20"
          }
        >
          Account Details
        </h1>
        {isOnboarding && (
          <Button
            sx={{
              padding: "0 !important",
              justifyContent: "unset !important",
            }}
            onClick={() => {
              if (isOnboardingComplete === false || isAccountAdded === false) {
                navigate(
                  user?.user_type?.toLowerCase() === "service provider"
                    ? `/service-provider`
                    : `/client`,
                );
              } else {
                onNextClick();
              }
            }}
          >
            Skip
          </Button>
        )}
      </div>
      {isOnboarding && (
        <div className="my-4 w-full border-t-[1px] border-grey_60 md:my-5"></div>
      )}
      <div
        className={`${
          isOnboarding ? "" : "mx-auto mt-8 max-w-[460px]"
        } mb-6 px-4`}
      >
        {isAccountAdded && !isOnboarding ? (
          <>
            <p className="mb-4 font-Bai text-2xl font-medium text-grey">
              Account 1
            </p>
            <div className="mb-6 flex gap-3 rounded-[16px] bg-grey_80 p-3">
              <Avatar sx={{width: 52, height: 52}}>
                <AccountBalanceIcon />
              </Avatar>
              <div className="">
                <p className="text-base font-medium text-grey">
                  {user?.bank_account.account_name}
                </p>
                <p className="mt-1 text-base font-medium text-grey_30">
                  {user?.bank_account.account_number} -{" "}
                  {
                    allBanks?.data.find(
                      bank => bank?.code === user?.bank_account.bank_code,
                    )?.name
                  }
                </p>
              </div>
            </div>

            <LoadingButton
              variant="contained"
              onClick={() => setisAccountAdded(false)}
            >
              Change
            </LoadingButton>
          </>
        ) : (
          <FormProvider {...methods}>
            {/* form content  */}
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div
                className={`${
                  isOnboarding ? "mb-28" : "mb-5"
                } flex flex-col gap-y-7 md:px-6`}
              >
                {/* bank  */}
                <AutoCompleteField
                  name="bank"
                  selectOption={allBanks?.data.map(item => item.name)}
                  label="Bank Name"
                  loading={isAllBanksLoading}
                  placeholder="Select Bank"
                />

                {/* account number  */}
                <ValidatedInput2
                  type="number"
                  disabled={!watchBank}
                  label="Account number"
                  name="account_number"
                  placeholder="10101010000"
                />
                {/* </div> */}
                <div>
                  {isValidateLoading ? (
                    <CircularProgress
                      sx={{
                        color: "#1d1d1d",
                      }}
                      size="1.2rem"
                    />
                  ) : (
                    <p className="mt-1 text-base font-medium uppercase">
                      {acctName}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-stretch justify-start gap-x-6 md:px-8">
                {isOnboarding && <PrevBtn onClick={onPrevClick} />}
                <LoadingButton
                  disabled={!isSuccess}
                  loading={isSubmitLoading}
                  loadingIndicator={
                    <CircularProgress
                      sx={{
                        color: "#fff",
                      }}
                      size="1.2rem"
                    />
                  }
                  endIcon={
                    isOnboarding ? <EastRoundedIcon fontSize="large" /> : null
                  }
                  sx={{
                    padding: {
                      xs: "12px 32px !important",
                      md: "18px 40px !important",
                    },
                    textTransform: "none",
                    minWidth: {xs: "none", md: "150px"},
                  }}
                  variant="contained"
                  type="submit"
                >
                  {isOnboarding ? "Submit" : "Save"}
                </LoadingButton>
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </section>
  );
};
export default AccountDetails;
