import {Button, Chip, IconButton} from "@mui/material";
import NewFileIcon from "components/Vectors/NewFileIcon";
import UserSquareIcon from "components/Vectors/UserSquareIcon";
import {useState} from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import DeclineKYCModal from "components/Messages/Modal/DeclineKYCModal";
import {
  useApproveCacMutation,
  useApproveKycMutation,
  useGetCacStatusQuery,
  useGetKycStatusQuery,
} from "services/admin/adminGeneralApi";
import LoadingBtn from "components/mui-components/LoadingButton";
import {handleError} from "utils";
import {showToast} from "redux/store.hooks";
import {useParams} from "react-router-dom";
import MessageModal from "components/mui-components/Modal/MessageModal";
import CheckIcon2 from "components/Vectors/CheckIcon2";

function KYC({data, cac, type, isClient}) {
  const {id} = useParams();
  const {data: kycStatus} = useGetKycStatusQuery(id);
  const {data: cacStatus} = useGetCacStatusQuery(id);
  const [approveKyc, {isLoading: isApproveLoading}] = useApproveKycMutation();
  const [approveCac, {isLoading: isCacApproveLoading}] =
    useApproveCacMutation();
  const uploaded =
    data?.back_image?.length > 0 && data?.front_image?.length > 0;
  const cacUploaded = cac?.cac_cert;
  const [isCac, setIsCac] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [showDecliningModal, setShowDecliningModal] = useState(false);

  return (
    <>
      <div className="rounded-2xl bg-grey_80 p-4 md:mb-6 md:p-6">
        <div className="mb-4 flex items-center gap-4">
          <h4 className="font-Bai text-2xl font-semibold text-grey">KYC</h4>
        </div>
        {uploaded ? (
          <>
            <div>
              <div className="flex items-center gap-x-2.5">
                <p className="my-6 font-Bai text-xl font-medium capitalize text-grey">
                  {data?.id_type?.replace("-", " ")}
                </p>
                <Chip
                  color={chipStatusColor[kycStatus?.data?.status]}
                  label={kycStatus?.data?.status}
                  sx={{borderRadius: "8px"}}
                />
              </div>

              <div>
                <p className="mb-2 text-sm font-semibold text-grey_50">Front</p>
                <img
                  className="h-[210px] w-[390px] rounded-2xl object-cover"
                  src={data?.front_image}
                  alt="KYCUpload"
                />
              </div>
              <div className="mt-4 mb-6">
                <p className="mb-2 text-sm font-semibold text-grey_50">Back</p>
                <img
                  className="h-[210px] w-[390px] rounded-2xl object-cover"
                  src={data?.back_image}
                  alt="KYCUpload"
                />
              </div>
              {kycStatus?.data?.status !== "Approved" && (
                <div className="flex items-center gap-4">
                  <LoadingBtn
                    onClick={() => {
                      approveKyc(data?.user_id)
                        .then(res => {
                          showToast(res?.data?.message, "success");
                          setIsCac(false);
                          setOpenApproveModal(true);
                        })
                        .catch(err => {
                          handleError(err);
                        });
                    }}
                    loading={isApproveLoading}
                  >
                    Approve
                  </LoadingBtn>
                  <Button
                    sx={{fontSize: "16px"}}
                    variant="contained"
                    color="errorInverse2"
                    onClick={() => {
                      setIsCac(false);
                      setShowDecliningModal(true);
                    }}
                  >
                    Decline
                  </Button>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="relative mb-8 flex h-[220px] flex-col items-center justify-center rounded-2xl bg-white md:h-[196px]">
              <Chip
                color="greyBorder"
                label="Pending Upload"
                sx={{
                  borderRadius: "8px",
                  position: "absolute",
                  top: {xs: "8px", md: "16px"},
                  left: "16px",
                  fontSize: {xs: "0.75rem", md: "0.8rem"},
                }}
              />

              <UserSquareIcon
                color="#6E726E"
                width="53.33px"
                height="53.33px"
              />
              <p className="mt-4 max-w-[217px] text-center font-Bai text-lg font-semibold text-grey_30">
                Government Issued ID not uploaded
              </p>
            </div>
          </>
        )}
        {type === "Registered Service Provider" ? (
          cacUploaded ? (
            <div className="mt-14">
              <div className="flex items-center gap-x-2.5">
                <p className="my-6 font-Bai text-xl font-medium text-grey">
                  CAC Certificate
                </p>
                <Chip
                  color={chipStatusColor[cacStatus?.data?.status]}
                  label={cacStatus?.data?.status}
                  sx={{borderRadius: "8px"}}
                />
              </div>
              <div className="flex items-center justify-between rounded-20 border border-dashed border-grey_50 bg-white py-4 px-6">
                <div className="flex w-[70%] items-center gap-x-2.5">
                  <NewFileIcon />
                  <span className="max-w-[70%] overflow-hidden text-ellipsis font-bold text-grey_20">
                    {
                      cac?.cac_cert?.split("/")[
                        cac?.cac_cert?.split("/").length - 1
                      ]
                    }
                  </span>
                </div>
                <div className="flex gap-x-2">
                  <IconButton
                    onClick={() => {
                      const fileUrl = cac?.cac_cert;
                      window.open(fileUrl, "_blank", "noopener,noreferrer");
                    }}
                    sx={{bgcolor: "#FDF2E6", borderRadius: "12px"}}
                  >
                    <VisibilityOutlinedIcon sx={{color: "#E98100"}} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      const fileUrl = cac?.cac_cert;
                      const link = document.createElement("a");
                      link.href = fileUrl;
                      link.setAttribute("download", "");
                      link.setAttribute("target", "_blank");
                      link.setAttribute("rel", "noopener noreferrer");
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    }}
                    sx={{bgcolor: "#FDF2E6", borderRadius: "12px"}}
                  >
                    <ArrowCircleDownOutlinedIcon sx={{color: "#E98100"}} />
                  </IconButton>
                </div>
              </div>
              {cacStatus?.data?.status !== "Approved" && (
                <div className="mt-6 mb-24 flex items-center gap-4">
                  <LoadingBtn
                    onClick={() => {
                      approveCac(data?.user_id)
                        .then(res => {
                          showToast(res?.data?.message, "success");
                          setOpenApproveModal(true);
                          setIsCac(true);
                        })
                        .catch(err => {
                          handleError(err);
                        });
                    }}
                    loading={isCacApproveLoading}
                  >
                    Approve
                  </LoadingBtn>
                  <Button
                    variant="contained"
                    color="errorInverse2"
                    onClick={() => {
                      setShowDecliningModal(true);
                      setIsCac(true);
                    }}
                  >
                    Decline
                  </Button>
                </div>
              )}
            </div>
          ) : (
            isClient === false && (
              <div className="relative mb-8 flex h-[220px] flex-col items-center justify-center rounded-2xl bg-white md:h-[196px]">
                <Chip
                  color="greyBorder"
                  label="Pending Upload"
                  sx={{
                    borderRadius: "8px",
                    position: "absolute",
                    top: {xs: "8px", md: "16px"},
                    left: "16px",
                    fontSize: {xs: "0.75rem", md: "0.8rem"},
                  }}
                />

                <NewFileIcon width={50} height={60} stroke="#6E726E" />
                <p className="mt-4 max-w-[217px] text-center font-Bai text-lg font-semibold text-grey_30">
                  CAC Certificate not uploaded
                </p>
              </div>
            )
          )
        ) : null}
      </div>
      <MessageModal
        openModal={openApproveModal}
        icon={<CheckIcon2 />}
        closeModal={() => setOpenApproveModal(false)}
        iconBg={"bg-[#FDF2E6]"}
        title={
          isCac ? "CAC Certificate Approved" : "Government Issued ID Approved"
        }
        buttonTitle="Close"
      />
      <DeclineKYCModal
        isProfessional={true}
        open={showDecliningModal}
        close={() => setShowDecliningModal(false)}
        userId={data?.user_id}
        isCac={isCac}
      />
    </>
  );
}

export default KYC;
const chipStatusColor = {
  Approved: "successInverse",
  Pending: "default",
  Declined: "errorInverse",
};
