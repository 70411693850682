import Modal from "components/mui-components/Modal";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import moment from "moment";
import {handleError, toCurrency} from "utils";
import {useMarkPayoutAsCompletedMutation} from "services/admin/adminGeneralApi";
import {showToast} from "redux/store.hooks";
import {LoadingButton} from "@mui/lab";

function RequestDetailsModal({request, openModal, setopenModal}) {
  const [markAsCompleted, {isLoading}] = useMarkPayoutAsCompletedMutation();

  return (
    <>
      <Modal
        title={`Payout Request details`}
        openModal={openModal}
        closeModal={() => setopenModal(false)}
        maxWidth={500}
      >
        <div className="mt-6">
          <UserDisplay
            name={
              request?.service_provider?.professional_information?.business_name
            }
            img={
              request?.service_provider?.professional_information?.business_logo
            }
            size="large"
            max_width="200px"
            email={`@${request?.service_provider?.username}`}
          />

          <div className="my-12 flex items-center justify-between">
            <div className="">
              <p className="text- mb-1 text-lg font-medium">Date</p>
              <p className="moment(request?.request_date_time)font-medium text-lg text-black">
                {moment(request?.request_date_time).format(
                  "DD MMM YYYY hh:mm a",
                )}
              </p>
            </div>
            <p className="font-Bai text-[32px] font-semibold text-grey">
              {toCurrency(request?.amount)}
            </p>
          </div>

          <div className="mb-32">
            <p className="mb-1 text-lg font-medium text-grey_30">Description</p>
            <p className="text-lg font-medium text-black">
              {request?.description}
            </p>
          </div>

          <LoadingButton
            loading={isLoading}
            variant="contained"
            fullWidth
            onClick={() => {
              markAsCompleted({
                payout_id: request?.id,
                service_provider_id: request?.service_provider?.id,
              })
                .unwrap()
                .then(res => {
                  setopenModal(false);
                  showToast(res?.message);
                })
                .catch(err => {
                  handleError(err);
                });
            }}
          >
            Mark as completed
          </LoadingButton>
        </div>
      </Modal>
    </>
  );
}

export default RequestDetailsModal;
