import {FormProvider, useForm} from "react-hook-form";
import MyModal from "components/mui-components/Modal";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import LoadingBtn from "components/mui-components/LoadingButton";
import {showToast} from "redux/store.hooks";
import {handleError} from "utils";
import {useSuspendJobMutation} from "services/admin/adminGeneralApi";
import {useParams} from "react-router-dom";
import {useState} from "react";
import MessageModal from "components/mui-components/Modal/MessageModal";
import CheckIcon2 from "components/Vectors/CheckIcon2";

function SuspendJobModal({closeModal, openModal}) {
  const methods = useForm();
  const [showMsgModal, setShowMsgModal] = useState(false);
  const [suspendJob, {isLoading: isSuspendingJob}] = useSuspendJobMutation();
  const {id} = useParams();

  const onSubmit = data => {
    const body = {
      status: "Suspended",
      message: data?.message,
      redirect_url: `${window.location.origin}/client/jobs/${id}/suspended`,
    };
    suspendJob({id, body})
      .unwrap()
      .then(res => {
        setShowMsgModal(true);
        closeModal();
        methods.reset();
        showToast(res?.message);
      })
      .catch(err => {
        handleError(err);
      });
  };

  return (
    <>
      <MyModal
        title="Suspend Job"
        openModal={openModal}
        closeModal={closeModal}
        maxWidth={550}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods?.handleSubmit(onSubmit)}
            className="mt-5 w-full"
          >
            <ValidatedTextArea2
              label="Send Message"
              name="message"
              placeholder="Type Something"
              height="min-h-[150px]"
              spaceY={false}
              bg="#F9FAFB"
              bordered={false}
            />
            <LoadingBtn
              loading={isSuspendingJob}
              fullWidth
              sx={{mt: 6}}
              color="errorBtn"
              type="submit"
            >
              Suspend Job
            </LoadingBtn>
          </form>
        </FormProvider>
      </MyModal>
      <MessageModal
        openModal={showMsgModal}
        icon={<CheckIcon2 />}
        closeModal={() => setShowMsgModal(false)}
        iconBg="bg-[#FDF2E6]"
        title="Job Suspended"
        buttonTitle="Close"
      />
    </>
  );
}

export default SuspendJobModal;
