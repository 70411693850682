import {Button} from "@mui/material";

const Subscription = ({registered, tokens, onBtnClick}) => {
  return (
    <div className="mt-8 rounded-24 bg-grey_80 p-8" id="token">
      <div className="flex items-center gap-x-2.5">
        <p className="font-Bai text-xl font-semibold">Subscription</p>
        <span
          className={`rounded-lg px-3 py-1.5 text-sm ${
            registered
              ? " bg-[#12b76a19] text-[#039855]"
              : "bg-[#d92d2019] text-[#D92D20]"
          }`}
        >
          {registered ? "Subscribed" : "Unsubscribed"}
        </span>
      </div>
      <div className="my-8 flex flex-col items-center justify-center">
        <span className="font-medium text-grey_20">Available Tokens</span>
        <p className="mb-4 font-Bai text-3xl font-semibold">{tokens || 0}</p>
        <Button variant="outlined" onClick={onBtnClick}>
          Give Tokens
        </Button>
      </div>
      {/* {registered && (
        <>
          <div className="flex items-center justify-between rounded-16 bg-white p-4 font-Bai font-semibold">
            <span className="text-xl md:text-2xl">Package A</span>
            <span className="text-xl md:text-[24px]">₦1,000</span>
          </div>
          <div className="mt-6 flex flex-col items-center justify-center gap-y-4 text-sm md:text-base">
            <div className="flex items-center gap-x-1">
              <span className="text-grey_30">from</span>
              22 June, 2022
              <span className="text-grey_30">to</span>
              30 July, 2022
            </div>
            <span className="rounded-lg bg-[#12b76a19] px-3 py-1.5 text-center text-sm text-[#039855]">
              24 days Left
            </span>
          </div>
        </>
      )} */}
    </div>
  );
};

export default Subscription;
