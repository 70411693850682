import EmailLayout from "./widgets/EmailLayout";
import EmailTitle from "./widgets/EmailTitle";
import {VerifiedBadge} from "components/Vectors/SmallTickIcon";

const GeneralNotification = () => {
  return (
    <EmailLayout>
      <EmailTitle
        title={
          <span className="flex flex-col items-center gap-y-2.5">
            <VerifiedBadge height="42" width="42" /> Verify your email address
          </span>
        }
      />
      <p className="my-12 mx-auto max-w-sm px-6 text-center leading-relaxed text-grey_30">
        You're almost there, copy the code below to verify your email address.
      </p>
      <div className="mx-auto flex w-5/12 mb-20 items-center justify-around gap-x-2 rounded-16 bg-grey_100 px-4 py-2 text-4xl font-bold text-grey_20">
        {[2, 2, 3, 1, 2].map((num, i) => (
          <span key={i}>{num}</span>
        ))}
      </div>
    </EmailLayout>
  );
};

export default GeneralNotification;
