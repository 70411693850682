// import SearchInput from "components/forms/SearchInput";
import Loader from "components/Loader/Loader";
import EmptyResponse from "components/mui-components/EmptyResponse";
import ScrollToTopButton from "components/ScrollToTopButton";
import {useState} from "react";
import {useGetAllServiceCategoriesQuery} from "services/generalApi";
import CategoryCard from "./components/CategoryCard";
import CategoryIcon from "@mui/icons-material/Category";
import TablePagination from "components/mui-components/TablePagination";

const CategoriesPage = () => {
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(15);

  const {data, isLoading} = useGetAllServiceCategoriesQuery({
    is_paginated: "yes",
    page,
    per_page: rowPerPage,
  });
  const [filterChoice, setFilterChoice] = useState("");
  const categories = data?.data;
  const allCt = categories?.data?.filter(el =>
    filterChoice
      ? el?.name.toLowerCase().indexOf(filterChoice.toLowerCase()) >= 0
      : el,
  );

  if (isLoading) return <Loader className="fixed inset-0 z-[100]" />;
  
  return (
    <main className="mb-64">
      <ScrollToTopButton />
      <section
        // style={{
        //   backgroundImage: `url(/images/all-jobs-default.png), linear-gradient(to right, #eee4d7, #eee4d7)`,
        // }}
        className="relative bg-no-repeat bg-cover -mt-16 pt-28 pb-16"
      >
        <p className="text-center text-[2.5rem] md:text-[4rem] font-medium text-primary md:-mb-7">
          All
        </p>
        <h1 className="p-3 text-center font-Bai text-5xl font-semibold text-black_text2 md:text-[80px]">
          Categories
        </h1>
        {/* <div className="mt-8 flex w-full justify-center">
          <SearchInput
            value={filterChoice}
            onChange={e => setFilterChoice(e.target.value)}
            maxWidth={"max-w-[628px]"}
            placeholder="Search categories"
            bg="white"
          />
        </div> */}
      </section>
      <div className="px-6 lg:px-[4vw]">
        {allCt?.length > 0 ? (
          <>
            <div className="mt-8 grid grid-cols-1 gap-5 sm:grid-cols-2 md:gap-[60px] lg:grid-cols-3">
              {allCt.map((data, i) => (
                <CategoryCard key={i} data={data} />
              ))}
            </div>
            <TablePagination
              meta={{
                totalCount: categories.length,
                currentPage: categories?.current_page,
                totalPages: categories?.last_page,
              }}
              from={categories?.from}
              to={categories?.to}
              page={page}
              setPage={setPage}
              perPage={rowPerPage}
              setPerPage={setRowPerPage}
            />
          </>
        ) : (
          <EmptyResponse
            message={"No category found"}
            icon={<CategoryIcon />}
          />
        )}
      </div>
    </main>
  );
};

export default CategoriesPage;
