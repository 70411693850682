import {Button, Fade, IconButton, Modal, Slide} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "./style.scss";

const MessageModal = ({
  otherChildren,
  closeModal,
  otherState,
  openModal = true,
  title,
  icon,
  description,
  cta,
  otherClasses,
  iconBg,
  maxWidth = 440,
  className = "",
  closeOnOverlayClick = true,
  ...props
}) => {
  return (
    <Modal open={openModal} onClose={closeModal}>
      <Fade in={openModal}>
        <div
          onClick={e => {
            if (e.target === e.currentTarget && closeOnOverlayClick) {
              closeModal();
            }
          }}
          className={`modal-wrap !bg-transparent ${otherClasses || ""}`}
        >
          <Slide direction="up" in={openModal} mountOnEnter unmountOnExit>
            <div
              className={`modal-content msg-modal ${className}`}
              style={{maxWidth}}
            >
              <IconButton
                className="close-modal-btn absolute top-1.5 right-1.5 z-30 lg:top-4 lg:right-4"
                onClick={closeModal}
              >
                <ClearIcon />
              </IconButton>
              <div className={`icon-wrap ${iconBg || "bg-primary-100"}`}>
                {icon}
              </div>
              <div className="my-auto mx-auto md:mb-7">
                <p className="font-Bai text-4xl font-semibold text-[#362E1F]">
                  {title}
                </p>
                <div className="my-2 mx-auto max-w-[350px] text-lg text-grey_30">
                  {description}
                </div>
                {otherChildren}
              </div>
              {cta || (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={closeModal || otherState}
                  sx={{mb: -2}}
                >
                  {props.buttonTitle || "Thanks"}
                </Button>
              )}
            </div>
          </Slide>
        </div>
      </Fade>
    </Modal>
  );
};

export default MessageModal;
