import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {logOut, setUserDetails} from "redux/slice/AuthSlice";
import {API_URL} from "utils/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/admin/`,
  prepareHeaders: (headers, api) => {
    const {auth} = api.getState();

    if (auth.token) {
      headers.set("authorization", `Bearer ${auth.token}`);
      headers.set("Access-Control-Allow-Origin", `*`);
    }
    headers.set("Accept", `application/json`);
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  let err = result?.error;
  if (result?.error && err.data?._meta?.error?.message.includes("logged in")) {
    // logout
    console.log("Something went wrong, Please login again", "error");
    setTimeout(() => {
      api.dispatch(logOut({redirect: true}));
    }, 1000);
  }
  return result;
};

export const adminProfileApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "adminProfileApi",
  tagTypes: ["adminData"],
  endpoints: builder => ({
    getAdminProfile: builder.query({
      query: () => `profile`,
      providesTags: ["adminData"],
      // dispatch new entry each time this endpoint is called with a new entry
      async onCacheEntryAdded(id, {dispatch, cacheDataLoaded}) {
        try {
          const {data} = await cacheDataLoaded;
          dispatch(setUserDetails(data?.data));
        } catch (err) {}
      },
    }),

    updateAdminInfo: builder.mutation({
      query: body => ({
        url: `update-profile`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["adminData"],
    }),
    adminRemoveProfilePic: builder.mutation({
      query: () => ({
        url: "remove-profile-picture",
        method: "POST",
      }),
      invalidatesTags: ["adminData"],
    }),
    updateProfilePic: builder.mutation({
      query: body => ({
        url: "update-profile-picture",
        method: "POST",
        body,
      }),
      invalidatesTags: ["adminData"],
    }),
    updatePassword: builder.mutation({
      query: body => ({
        url: "change/password",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAdminProfileQuery,
  useUpdateAdminInfoMutation,
  useAdminRemoveProfilePicMutation,
  useUpdateProfilePicMutation,
  useUpdatePasswordMutation,
} = adminProfileApi;
