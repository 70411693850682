import {toCurrency} from "utils";
import EmailLayout from "./widgets/EmailLayout";
import EmailTitle from "./widgets/EmailTitle";

const WithdrawalRequest = () => {
  const service_provider = {
    professional_information: {
      business_name: "Mirian Achonwa",
    },
  };
  const transaction = {
    bank: "United bank of Africa (UBA)",
    account_name: "Olobiryipo Esther Anu",
    amount: "50000",
    transaction_ref: 232239043,
  };

  return (
    <EmailLayout>
      <EmailTitle title="Withdrawal Request" />
      <div className="mx-auto mt-12 w-10/12">
        <p>
          Hello, {service_provider?.professional_information?.business_name}
        </p>
        <p className="mt-4 text-sm text-grey_30">
          Your withdrawal request has been received. The funds will be sent to
          the account provided by you within a working day. Below is the
        </p>
        <div className="mx-auto my-6 flex w-3/4 flex-col gap-y-3">
          {Object.entries(transaction).map((item, i) => {
            if (item[0] === "amount") {
              return (
                <p
                  key={i}
                  className="flex items-center justify-between text-sm"
                >
                  <span className="capitalize text-grey_30">
                    {item[0]?.split("_").join(" ")}
                  </span>
                  <span className="font-semibold capitalize text-grey_10">
                    {toCurrency(item[1])}
                  </span>
                </p>
              );
            } else
              return (
                <p
                  key={i}
                  className="flex items-center justify-between text-sm"
                >
                  <span className="capitalize text-grey_30">
                    {item[0]?.split("_").join(" ")}
                  </span>
                  <span className="font-semibold capitalize text-grey_10">
                    {item[1]}
                  </span>
                </p>
              );
          })}
        </div>
        <p className="text-sm text-grey_30">
          If you have any issues with this, please contact us immediately at{" "}
          <br />
          <a
            href="mailto: hello@truelocal.com"
            className="text-primary underline"
          >
            hello@truelocal.com
          </a>
        </p>
      </div>
    </EmailLayout>
  );
};

export default WithdrawalRequest;
