import {useState} from "react";
import {CircularProgress} from "@mui/material";
import AutoCompleteField from "components/forms/AutoCompleteField";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import MyModal from "components/mui-components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import SentModal from "./SentModal";
import {
  useAddAdminMutation,
  useGetAllRolesQuery,
} from "services/admin/adminGeneralApi";
import {handleError} from "utils";
import {LoadingButton} from "@mui/lab";

const CreateAdminModal = ({open, onClose}) => {
  const [showDoneModal, setShowDoneModal] = useState(false);
  const methods = useForm();

  const {reset} = methods;

  const [addAdmin, {isLoading}] = useAddAdminMutation(),
    {data: roles, isLoading: isRoleLoading} = useGetAllRolesQuery();

  const onSubmit = async data => {
    try {
      await addAdmin(data).unwrap();
      setShowDoneModal(true);
      onClose();
      reset();
    } catch (error) {
      handleError(error);
      onClose();
    }
  };
  return (
    <>
      <MyModal openModal={open} closeModal={onClose} title="Create Admin">
        <FormProvider {...methods}>
          <form
            className="flex flex-col gap-y-4"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="flex items-center gap-x-4">
              <ValidatedInput2
                name="first_name"
                label="First Name"
                placeholder="Input first name"
              />
              <ValidatedInput2
                name="last_name"
                label="Last Name"
                placeholder="Input last name"
              />
            </div>
            <ValidatedInput2
              name="email"
              label="Email Address"
              placeholder="Input Email Address"
              type="email"
            />
            <ValidatedInput2
              name="phone_number"
              label="Phone Number"
              placeholder="+234"
              type="tel"
            />
            <AutoCompleteField
              selectOption={roles?.data?.map(role => role.name)}
              name="role_name"
              label="Role"
              loading={isRoleLoading}
              required={false}
              placeholder="Select Role"
            />

            <LoadingButton
              sx={{mt: 4}}
              loadingIndicator={
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size="1.2rem"
                />
              }
              type="submit"
              loading={isLoading}
              fullWidth
              variant="contained"
              color="primary"
            >
              Submit
            </LoadingButton>
          </form>
        </FormProvider>
      </MyModal>

      <SentModal
        open={showDoneModal}
        close={() => {
          setShowDoneModal(false);
        }}
      />
    </>
  );
};

export default CreateAdminModal;
