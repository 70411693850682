import {Button} from "@mui/material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import {ReactComponent as Logo} from "assets/images/logo.svg";
import {Link} from "react-router-dom";
export {default as UserResetPwd} from "./UserResetPwd";
export {default as UserLogin} from "./UserLogin";
export {default as UserForgotPassword} from "./UserForgotPassword";
export {default as UserCreateUsername} from "./UserCreateUsername";
export {default as UserAccountCreation} from "./UserAccountCreation";
export {default as UserAccountCreated} from "./UserAccountCreated";
export {default as UserVerifyOtp} from "./UserVerifyOtp";
export {default as UserCreateProvider} from "./UserCreateProvider";
export {default as UserCreateClient} from "./UserCreateClient";
export {default as UserSelectUser} from "./UserSelectUser";

export const UserSkeleton = ({children, className = "bg-grey_100"}) => (
  <main
    className={`flex h-full min-h-screen flex-col items-center justify-between py-3 px-6 ${className}`}
  >
    <Link to="/">
      <Logo />
    </Link>
    {children}
    <p className="font-Bai font-medium text-grey_30">
      &copy; {new Date().getFullYear()} All rights reserved, Truelocal
    </p>
  </main>
);
export const PrevBtn = ({onClick}) => (
  <Button
    startIcon={<KeyboardBackspaceRoundedIcon />}
    sx={{
      maxWidth: "150px",
      padding: {xs: "10px 24px !important", md: "16px 40px !important"},
      borderColor: "#E98100",
      color: "#E98100",
    }}
    onClick={() => {
      window.scrollTo({top: 0, behavior: "smooth"});
      onClick();
    }}
    type="button"
    variant="outlined"
  >
    Previous
  </Button>
);
export const NextBtn = ({onClick, final, type}) => (
  <Button
    endIcon={<EastRoundedIcon fontSize="large" />}
    sx={{
      padding: {xs: "12px 32px !important", md: "18px 40px !important"},
      textTransform: "none",
      minWidth: {xs: "none", md: "150px"},
    }}
    onClick={() => {
      window.scrollTo({top: 0, behavior: "smooth"});
      onClick && onClick();
    }}
    variant="contained"
    type={type || "button"}
  >
    {final ? "Complete" : "Next"}
  </Button>
);
