const SlantedFileIcon = ({stroke}) => {
  return (
    <svg
      width="129"
      height="108"
      viewBox="0 0 129 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="63.5" cy="50.6321" r="42.5" fill="#E98100" />
      <circle opacity="0.04" cx="86.5" cy="64.6321" r="42.5" fill="#E98100" />
      <g clipPath="url(#clip0_6492_49047)">
        <path
          d="M46.1533 37.0104L28.9677 40.3123M36.8975 50.6688L31.169 51.7694M49.6806 24.4526L26.7664 28.8552M61.0276 21.6784L66.751 51.4669C67.6756 56.2794 68.1379 58.6856 67.5545 60.7036C67.0414 62.4788 65.9794 64.0459 64.5208 65.1803C62.8627 66.4701 60.4565 66.9324 55.644 67.857L37.3127 71.3791C32.5002 72.3037 30.094 72.766 28.0759 72.1826C26.3008 71.6695 24.7337 70.6075 23.5992 69.1489C22.3095 67.4908 21.8472 65.0846 20.9226 60.2721L15.1992 30.4836C14.2745 25.6712 13.8122 23.265 14.3956 21.2469C14.9088 19.4718 15.9708 17.9047 17.4293 16.7702C19.0875 15.4805 21.4937 15.0182 26.3061 14.0935L44.6375 10.5715C49.45 9.64683 51.8562 9.18452 53.8742 9.76792C55.6494 10.2811 57.2165 11.3431 58.3509 12.8016C59.6407 14.4598 60.103 16.866 61.0276 21.6784Z"
          stroke={stroke || "#E98100"}
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6492_49047">
          <rect
            width="70"
            height="70"
            fill="white"
            transform="translate(0 13.2078) rotate(-10.8759)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SlantedFileIcon;
