import { Button, useMediaQuery } from "@mui/material";
import { MobileApp } from "./widgets/components";
import { useNavigate } from "react-router-dom";
import FreqQuestionDropdown from "./widgets/components/FreqQuestionDropdown";
import { useEffect } from "react";
import { useGetUser } from "hooks/getUserHook";

const HowItWorksServiceProviders = () => {
  const navigate = useNavigate();
  const { user, isLoggedIn } = useGetUser();
  const isDesktopScreen = useMediaQuery("(min-width:1240px)");

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const pageScrollTop =
  //       document.documentElement.scrollTop || document.body.scrollTop || 0;
  //     const firstElementHeight = document
  //       .querySelector(".timeline-el-1")
  //       .getBoundingClientRect().height;
  //     const firstElementVisible = pageScrollTop < firstElementHeight * 3;
  //     const secondElementVisible =
  //       pageScrollTop > firstElementHeight * 3 &&
  //       pageScrollTop < firstElementHeight * 6;
  //     const thirdElementVisible = pageScrollTop >= firstElementHeight * 6;
  //     const firstElement = document.querySelector(".timeline-el-1");
  //     const secondElement = document.querySelector(".timeline-el-2");
  //     const thirdElement = document.querySelector(".timeline-el-3");
  //     const elements = [firstElement, secondElement, thirdElement];
  //     if (isDesktopScreen) {
  //       if (firstElementVisible) {
  //         elements.forEach(el => {
  //           el.classList.remove("opacity-100");
  //           el.classList.add("opacity-0");
  //         });
  //         firstElement.classList.add("opacity-100");
  //         firstElement.classList.remove("invisible");
  //       } else if (secondElementVisible) {
  //         elements.forEach(el => {
  //           el.classList.remove("opacity-100");
  //           el.classList.add("opacity-0");
  //         });
  //         secondElement.classList.add("opacity-100");
  //       } else if (thirdElementVisible) {
  //         elements.forEach(el => {
  //           el.classList.remove("opacity-100");
  //           el.classList.add("opacity-0");
  //         });
  //         thirdElement.classList.add("opacity-100");
  //       }
  //     }
  //   };
  //   document.addEventListener("scroll", handleScroll);
  //   return () => {
  //     document.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isDesktopScreen]);

  return (
    <main className="!-mt-16 -mb-60 bg-white !pt-16 font-Bai">
      <section className="mx-auto max-w-[1440px] px-4  sm:px-6 md:px-12">
        {/* MOBILE */}
        <div className="h-[427px] relative block md:hidden !rounded-[1.5rem] text-white">
          <img src="/images/how-it-works-mob-1.png" alt="" className="!rounded-[1.5rem] object-cover w-full h-full" />

          <div className="absolute translate-x-[10%] -translatey-1/2 w-full mx-auto max-w-[80%] flex flex-col justify-center items-center bottom-12 left10">
            <span className="rounded-full bg-white bg-opacity-25 px-3 py-2 text-center text-xl text-white md:px-4 md:py-3 md:text-2xl">
              For Service Providers
            </span>

            <h4 className="mb-8 mt-4 max-w-[647px] text-center font-Bai text-[2rem] font-bold leading-[126.5%] md:text-[3.125rem]">
              Ignite Your Success: Discover, Pitch, and Prosper in Rewarding Job
              Opportunities
            </h4>
            <Button
              variant='contained'
              onClick={() =>
                isLoggedIn
                  ? user?.user_type?.toLowerCase() === "service provider"
                    ? navigate("/service-provider/marketplace")
                    : navigate("/client")
                  : navigate("/user/login")
              }
            >
              Explore Marketplace
            </Button>
          </div>
        </div>
        
        {/* DESKTOP */}
        <div
          style={{
            backgroundImage: 'url("/images/howitworks-serviceprov.png")',
            backgroundRepeat: "no-repeat",
            objectFit: "cover",
          }}
          className="hidden md:flex h-[300px] flex-col items-center justify-center !rounded-[56px] p-8 text-white md:h-[555px] md:py-24"
        >
          <span className="rounded-full bg-white bg-opacity-25 px-4 py-3 text-center text-xl text-white md:text-2xl">
            For Service Providers
          </span>

          <h4 className="mb-6 mt-4 max-w-[769px] text-center font-Bai text-4xl font-bold leading-[100%] md:text-[50px]">
            Ignite Your Success: Discover, Pitch, and Prosper in Rewarding Job
            Opportunities
          </h4>
          <Button
            variant='contained'
            onClick={() =>
              isLoggedIn
                ? user?.user_type?.toLowerCase() === "service provider"
                  ? navigate("/service-provider/marketplace")
                  : navigate("/client")
                : navigate("/user/login")
            }
          >
            Explore Marketplace
          </Button>
        </div>
      </section>

      <h3 className="my-10 text-center font-Bai text-4xl font-bold text-grey_20 md:my-[70px] md:text-5xl">
        Here's how it works
      </h3>

      {/* <div className="relative flex flex-col gap-12 md:block md:h-[310rem]">
      </div> */}
        <FirstSlide />
        <SecondSlide />
        <ThirdSlide />

      <section className="bg-[#f6f6f6] mt-16 md:mt-0 p-4 py-20 sm:p-6 md:p-10 lg:px-32 lg:py-[134px]">
        <div className=" mx-auto grid max-w-[1440px] grid-cols-1 gap-10 md:grid-cols-2 md:gap-28">
          <p className="max-w-[376px] text-3xl font-bold !leading-[108%] text-[#222222] md:text-[40px]">
            Frequently asked questions
          </p>

          <div className="flex flex-col gap-2">
            {FreqQuestions.map(el => (
              <FreqQuestionDropdown
                key={el.id}
                actionText={el.actionText}
                listItems={el.listItems}
              />
            ))}
          </div>
        </div>

        <div className="mt-[152px]">
          <MobileApp />
        </div>
      </section>
    </main>
  );
};

export default HowItWorksServiceProviders;

const FirstSlide = () => {
  return (
    <section
      className={`timeline-el-1 top-24 mx-auto my-[200px] grid w-10/12 items-center justify-center gap-8 font-Mulish  md:w-9/12 md:grid-cols-2`}
    >
      <img
        src="/images/howitworks-serviceprov-1.png"
        alt="banner"
        className="h-[34rem] max-h-full rounded-[48px] object-contain"
      />
      <div>
        <p className="text-base text-[#222222] md:text-xl">Step 01</p>
        <p className="font-Bai text-4xl font-bold text-[#222222] md:text-5xl">
          Find Job Opportunities that Value Your Skills
        </p>
        <p className="my-4 text-sm font-medium text-grey_20 md:text-base">
          At our marketplace, we recognize the unique skills and expertise of
          service providers like you. We put your skills in the spotlight by
          presenting a wide array of job opportunities where clients are
          specifically seeking your capabilities.
        </p>

        <div className="flex gap-4 bg-[#FDF2E6] p-3">
          <img src="/images/Dizzy.png" alt="dizzy" className="h-6 w-6" />
          <p className="font-Mulish text-sm font-semibold text-[#693A00] md:text-base">
            Explore our curated list of available jobs and let your skills
            shine.
          </p>
        </div>
      </div>
    </section>
  );
};
const SecondSlide = () => {
  const navigate = useNavigate();
  return (
    <section
      className={`timeline-el-2 top-24 mx-auto my-[200px] grid w-10/12 items-center justify-center gap-8 bg-white font-Mulish  md:w-9/12 md:grid-cols-2`}
    >
      <img
        src="/images/howitworks-serviceprov-2.png"
        alt="banner"
        className="h-[34rem] max-h-full rounded-[48px] object-contain"
      />
      <div>
        <p className="text-base text-[#222222] md:text-xl">Step 02</p>
        <p className="font-Bai text-4xl font-bold text-[#222222] md:text-5xl">
          Apply with Confidence, set your desired offer amount
        </p>
        <p className="my-4 text-sm font-medium text-grey_20 md:text-base">
          When you find a job that aligns with your skills and interests, it's
          time to make a lasting impression. Submit your application directly
          through our user-friendly app, showcasing your qualifications and
          demonstrating your passion.
          <br />
          Specify the amount you wish to be offered for your services,
          reflecting the value you bring to the table. Clearly communicate your
          expectations to ensure a fair and mutually beneficial agreement.
        </p>

        <Button
          onClick={() => navigate("/client/post-job")}
          sx={{ width: "fit-content" }}
          variant="outlined"
        >
          Find jobs
        </Button>
      </div>
    </section>
  );
};
const ThirdSlide = () => {
  const navigate = useNavigate();
  return (
    <section
      className={`timeline-el-3 top-24 mx-auto my-[200px] grid w-10/12 items-center justify-center gap-8 bg-white font-Mulish  md:w-9/12 md:grid-cols-2`}
    >
      <img
        src="/images/howitworks-serviceprov-3.png"
        alt="banner"
        className="h-[33.8rem] max-h-full rounded-[48px] object-contain pb-4"
      />
      <div className="pb-4 md:pb-0">
        <p className="text-base text-[#222222] md:text-xl">Step 03</p>
        <p className="font-Bai text-4xl font-bold text-[#222222] md:text-5xl">
          Celebrate Success and Secure Job Offers
        </p>
        <p className="my-4 text-sm font-medium text-grey_20 md:text-base">
          Sit back and wait for clients to review your proposal. If accepted,
          you'll receive a notification to proceed with the next steps. If
          rejected, don't worry! Keep exploring other job opportunities on the
          platform and find the right fit for your services.
        </p>

        <Button
          sx={{ width: "fit-content" }}
          onClick={() => navigate("/client/post-job")}
          variant="outlined"
        >
          Post a job
        </Button>
      </div>
    </section>
  );
};

const FreqQuestions = [
  {
    id: 1,
    actionText: "How do I upload a task on the platform?",
    listItems: [
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    ],
  },
  {
    id: 2,
    actionText: "Can I set a specific budget for my task?",
    listItems: [
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    ],
  },
  {
    id: 3,
    actionText:
      "How long does it take to receive quotes from service providers?",
    listItems: [
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    ],
  },
  {
    id: 4,
    actionText:
      "What factors should I consider when evaluating quotes from service providers?",
    listItems: [
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    ],
  },
  {
    id: 5,
    actionText: "What happens after I accept a quote?",
    listItems: [
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    ],
  },
];
