import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import "./style.scss";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@mui/material";
import OverviewCard from "components/OverviewCard";
import User2 from "components/Vectors/User_2";
import EngagementsTable from "./Widgets/EngagementsTable";
import {useGetEngagementStatsQuery} from "services/admin/adminGeneralApi";

function AdminEngagements() {
  const [activeTab, setactiveTab] = useState("ongoing");
  const {data: stats = {}} = useGetEngagementStatsQuery();

  const navigate = useNavigate();
  let {search} = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get("tab");
  useEffect(() => {
    if (tab) setactiveTab(tab);
  }, [tab]);

  return (
    <DashboardLayout
      title={<h1 className="w-[20%] text-2xl">Engagements</h1>}
      type="admin"
    >
      <main>
        <div className="rounded-20 border border-grey_60 bg-white p-4 md:p-6">
          <h1 className="mb-2 text-2xl font-semibold">Overview</h1>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
            <OverviewCard
              value={stats?.ongoing_engagements_count}
              title={"Ongoing Engagement"}
              icon={<User2 />}
            />
            <OverviewCard
              value={stats?.completed_engagements_count}
              title={"Completed Engagement"}
              icon={<User2 stroke="#5010B3" />}
            />
            <OverviewCard
              value={stats?.cancelled_engagements_count}
              title={"Cancelled Engagement"}
              icon={<User2 stroke="#5010B3" />}
            />
            <OverviewCard
              value={stats?.terminated_engagements_count}
              title={"Terminated Engagement"}
              icon={<User2 stroke="#5010B3" />}
            />
          </div>
        </div>

        <div className="mt-8 rounded-20 border border-grey_60 bg-white p-5 ">
          <TabContext value={activeTab}>
            <TabList
              variant="fullWidth"
              value={activeTab}
              onChange={(_, newValue) => {
                navigate(`?tab=${newValue}`);
                setactiveTab(newValue);
              }}
              sx={{
                "& .MuiButtonBase-root": {
                  minWidth: "max-content",
                },
              }}
              aria-label="engagements"
            >
              <Tab label="Ongoing Engagement" value="ongoing" />
              <Tab label="Completed Engagement" value="completed" />
              <Tab label="Cancelled Engagement" value="cancelled" />
              <Tab label="Terminated Engagement" value="terminated" />
            </TabList>

            <TabPanel value={"ongoing"}>
              <EngagementsTable status="Ongoing" />
            </TabPanel>
            <TabPanel value={"completed"}>
              <EngagementsTable status="Completed" />
            </TabPanel>
            <TabPanel value={"cancelled"}>
              <EngagementsTable status="Cancelled" />
            </TabPanel>
            <TabPanel value={"terminated"}>
              <EngagementsTable status="Terminated" />
            </TabPanel>
          </TabContext>
        </div>
      </main>
    </DashboardLayout>
  );
}

export default AdminEngagements;
