import { ErrorOutlineOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';
import { FormControlLabel, Switch } from '@mui/material';
import StarIcon from "components/Vectors/FourStar";
import ValidatedInput from 'components/forms/ValidatedInput';
import ValidatedTextArea from 'components/forms/ValidatedTextArea';
import MyModal from 'components/mui-components/Modal'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form';

const NewMakeOfferModal = ({ openModal, setOpenModal }) => {
    const methods = useForm({
        mode: "all"
    })


    const onSubmit = () => { }
    return (
        <MyModal
            openModal={openModal}
            closeModal={() => setOpenModal(false)}
            maxWidth={476}
            hideScroll
            title={"Make an Offer"}
        >
            <div
                style={{
                    backgroundImage: "url('/images/earn-money-3d.png')",
                    backgroundPosition: "right 150%",
                    backgroundSize: "22%"
                }}
                className="rounded-2xl bg-[#F3ECFD] p-3 bg-no-repeat bgcover">
                <div className="flex gap-2 items-center font-medium text-sm text-[#1C063F]">
                    <span className="rounded-full bg-white flex items-center justify-center w-10 h-10">
                        <ErrorOutlineOutlined
                            sx={{ color: "#440E98" }}
                        />

                    </span>
                    You will be charged 12 tokens to make this offer
                </div>

                <div className="w-fit mt-3 px-3 py-2 border border-[#C9C9CF] rounded-full bg-white flex gap-1 items-center font-semibold text-sm text-grey_base">
                    <StarIcon />
                    <p>
                        Token Balance {" "}
                        <span className="text-grey-10">2,019</span>
                    </p>
                </div>
            </div>


            <FormProvider {...methods}>
                <form
                    className="flex flex-col gap-4 mt-4"
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <ValidatedTextArea
                        label="Pitch Message"
                        placeholder="Make your offer message good and competitive"
                        name={"message"}
                    />

                    {methods.watch("client-budget") ?
                        <ValidatedInput
                            name="amount"
                            placeholder="Enter Amount"
                            label="Amount"
                        />
                        : null
                    }

                    <FormControlLabel
                        control={
                            <Switch
                                {...methods.register("client-budget")}
                                // defaultChecked
                            />
                        }
                        label="Use Client’s budget"
                    />

                    <LoadingButton
                        variant='contained'
                        type='submit'
                        sx={{ mt: "64px", width: "fit-content" }}
                    >
                        Make an offer
                    </LoadingButton>

                </form>
            </FormProvider>
        </MyModal>
    )
}

export default NewMakeOfferModal