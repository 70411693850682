import HomeIcon from "components/Vectors/home-icon";
// import UserCircleIcon from "components/Vectors/user-circle";
import UsersIcon2 from "components/Vectors/user";
import CreditCardIcon from "components/Vectors/credit-card";
import TicketIcon from "components/Vectors/ticket";
// import JobIcon from "components/Vectors/JobIcon";
// import EngagementIcon from "components/Vectors/EngagementIcon";
import TokenIcon from "components/Vectors/TokenIcon";
import ShieldTickIcon from "components/Vectors/ShieldTickIcon";
import UserIcon1 from "components/Vectors/UserIcon1";
import { HandHeartIcon2 } from "components/Vectors/HandHeartIcon";
import { JobIcon3 } from "components/Vectors/JobIcon2";
import Users3 from "components/Vectors/Users3";
// import BellIcon from "components/Vectors/BellIcon";

const AdminMenuLinks = [
  {
    icon: HomeIcon,
    name: "Dashboard",
    link: "",
  },
  {
    icon: UsersIcon2,
    name: "Users",
    link: "users",
  },
  {
    icon: UserIcon1,
    name: "Clients",
    link: "clients",
  },
  {
    icon: HandHeartIcon2,
    name: "Service Providers",
    link: "service-providers",
  },
  {
    icon: JobIcon3,
    name: "Jobs",
    link: "jobs",
  },
  // {
  //   icon: BriefcaseIcon,
  //   name: "Services",
  //   link: "services",
  // },
  {
    icon: Users3,
    name: "Engagements",
    link: "engagements",
    border: true,
  },
  // {
  //   icon: JobRequestsIcon,
  //   name: "Job Offers",
  //   link: "job-offers",
  // },
  {
    icon: TokenIcon,
    name: "Token Packages",
    link: "token-packages",
    desktop: true,
  },
  {
    icon: CreditCardIcon,
    name: "Transactions",
    link: "transactions",
    border: true,
  },
  {
    icon: ShieldTickIcon,
    name: "Administrators",
    link: "administrators",
  },
  {
    icon: TicketIcon,
    name: "Tickets",
    link: "tickets",
    // border: true,
  },
  // {
  //   icon: BellIcon,
  //   name: "Mobile App Push",
  //   link: "MobileAppPushNotification",
  //   border: true,
  // },
];

export default AdminMenuLinks;
