import {useState} from "react";
import {useGetUser} from "hooks/getUserHook";
import IncompleteModal from "pages/Clients/Dashboard/IncompleteModal";
import OverviewCard from "./OverviewCard";
import { useGetJobStatisticsQuery } from "services/user/clientGeneralApi";
import JobIcon2 from "components/Vectors/JobIcon2";
import ProtectedBtn from "./ProtectedBtn";
import ClosedJobIcon from "./Vectors/ClosedJobIcon";
import SuspendedJobIcon from "./Vectors/SuspendedJobIcon";
import DraftedJobIcon from "./Vectors/DraftedJobIcon";


const TopDetail = ({title}) => {
   const {data: stat, isLoading} = useGetJobStatisticsQuery();
   const details = [
     {
       title: "Open Jobs",
       text: stat?.data?.active_jobs_count,
       icon: <JobIcon2 color="#039855" />,
     },
     {
       title: "Closed Jobs",
       text: stat?.data?.closed_jobs_count,
       icon: <ClosedJobIcon color="#D92D20" />,
     },
     {
       title: "Suspended Jobs",
       text: stat?.data?.suspended_jobs_count,
       icon: <SuspendedJobIcon color="#6E726E" />,
     },
     {
       title: "Drafted Jobs",
       text: stat?.data?.drafted_jobs_count,
       icon: <DraftedJobIcon color="#5010B3" />,
     },
   ];
  const {user} = useGetUser();
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);

  return (
    <>
      <h1 className="font-h1">{title || "Job Requests"}</h1>
      <p className="font-p text-grey_30 font-Bai">This is where you manage all your jobs</p>
      <div className="my-8 rounded-24 border-grey_60 md:border md:bg-white md:px-8 md:py-[28px]">
        <div className="mb-2 flex items-center justify-between">
          <p className="dashboard-overview !border-b-[0] !pb-[0]">Overview</p>
          {title === "My Jobs" && (
            <ProtectedBtn
              variant="contained"
              sx={{
                display: user?.kyc && user?.kyc.verified ? "block" : "block",
              }}
              className='client_create_job_fifth_step'
            />
          )}
        </div>
        <div className="border-[#F2F4F7] md:border-t"></div>
        <div
          className={`mt-[12px] grid grid-cols-2 gap-4 md:gap-8 md:grid-cols-${details.length} md:gap-x-4`}
        >
          {details.map(({title, text, icon}, index) => (
            <OverviewCard
              icon={icon}
              title={title}
              value={text}
              key={title + index}
              id={index}
              isLoading={isLoading}
            />
          ))}
        </div>
      </div>
      <IncompleteModal
        open={showIncompleteModal}
        close={() => setShowIncompleteModal(false)}
      />
    </>
  );
};

export default TopDetail;
