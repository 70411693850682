import UserProfileCard from "../../Engagements/Widgets/UserProfileCard";
import JobRequestContent from "pages/Clients/JobRequest/widgets/JobRequestContent";
const RequestOffers = ({
  jobData,
  isAdmin,
  handleAddToMarketplace,
  isLoading,
}) => {
  return (
    <div>
      {isAdmin && (
        <UserProfileCard
          className="mb-6 bg-[#F2F4F7]"
          isAdmin
          user={jobData?.creator}
        />
      )}
      <p className="mb-4 flex items-center gap-2.5 font-Bai text-2xl font-semibold">
        All Offers received
        <span className="ml2.5 inline-flex h-[25px] w-[25px] items-center justify-center rounded-full bg-primary p-1 text-base font-medium text-white">
          {jobData?.offers?.length}
        </span>
      </p>
      <div className="sticky top-0 rounded-24 bg-[#F2F4F7] p-4 md:py-6 md:px-8">
        <JobRequestContent
          isAdmin={isAdmin}
          handleAddToMarketplace={handleAddToMarketplace}
          isLoading={isLoading}
          creatorId={jobData?.creator?.id}
          data={jobData?.status !== "Drafted" ? jobData?.offers : []}
          status={jobData?.status || ""}
        />
      </div>
    </div>
  );
};
export default RequestOffers;
