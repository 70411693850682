import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import moment from "moment";

function Overviews({data}) {
  return (
    <div className="h-fit rounded-2xl bg-grey_80">
      <h3 className="mb-12 font-Bai text-2xl font-semibold">Overviews</h3>

      <div>
        <p className="text-lg font-medium text-grey_30">Type</p>
        <p className="my-2 font-medium text-black md:text-lg">
          {data?.is_hybrid === "Yes" ? "Hybrid" : data?.user_type}
        </p>
        {data?.is_hybrid?.toLowerCase() === "yes" && (
          <Button
            sx={{padding: "0px !important"}}
            endIcon={
              <ArrowForwardIosIcon
                sx={{
                  height: "16px",
                  width: "16px",
                }}
              />
            }
          >
            <Link to={`/admin/clients/${data.id}`}>View Client Profile</Link>
          </Button>
        )}
      </div>

      <div className="mt-6">
        <p className="mb-0 text-lg font-medium text-grey_30">About</p>
        <p className="my-2 font-medium text-black md:text-lg">{data?.about}</p>
      </div>

      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Email Address</p>
        <p className="my-2 font-medium text-black md:text-lg">{data?.email}</p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">Languages</p>
        <p className="my-2 text-lg font-medium text-[#000000]">
          {data?.languages.map((value, index) => (
            <span key={value.id}>{(index ? ", " : "") + value.name}</span>
          ))}{" "}
        </p>
      </div>
      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Phone</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {data?.phone_number || "N/A"}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">Sex</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {data?.gender || "N/A"}
        </p>
      </div>
      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Date of Birth</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {moment(data?.dob).format("DD MMM YYYY") || "N/A"}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">Country</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {data?.country === "undefined" ? "N/A" : data?.country}
        </p>
      </div>
      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Location</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {data?.location === "undefined" ? "N/A" : data?.location}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">
          Residential Address
        </p>
        <p className="my-2 font-medium capitalize text-[#000000] md:text-lg">
          {data?.residential_address || "N/A"}
        </p>
      </div>
    </div>
  );
}

export default Overviews;
