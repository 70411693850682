import {useGetUser} from "hooks/getUserHook";
import React from "react";

function ProfessionalInformation() {
  const {user} = useGetUser();
  const profInfo = user?.professional_information;
  return (
    <div className="h-fit rounded-2xl md:bg-grey_80 md:p-6">
      <h3 className="font-Bai text-2xl font-semibold text-grey">
        Professional Information
      </h3>
      <div className="mt-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Business Name</p>
        <p className="my-2 text-lg font-medium text-[#000000]">
          {profInfo?.business_name}
        </p>
      </div>
      <div className="mt-6 ">
        <p className="mb-0 text-lg font-medium text-grey_30">About Business</p>
        <p className="my-2 text-lg font-medium text-[#000000]  break-words">
          {profInfo?.about || user?.about}
        </p>
      </div>

      <div className="mt-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Email Address</p>
        <p className="my-2 text-lg font-medium text-[#000000]">{user?.email}</p>
      </div>
      <div className="mt-6">
        <p className="mb-0 text-lg font-medium text-grey_30">
          Years of Experience
        </p>
        <p className="my-2 text-lg font-medium text-[#000000]">
          {profInfo?.years_of_experience} Year(s)
        </p>
      </div>
      <div className="mt-6">
        <p className="mb-0 text-lg font-medium text-grey_30">
          Professional Address
        </p>
        <p className="my-2 text-lg font-medium text-[#000000]">
          {profInfo?.residential_address || "_"}
        </p>
      </div>
      <div className="mt-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Country</p>
        <p className="my-2 text-lg font-medium text-[#000000]">
          {profInfo?.country}
        </p>
      </div>
      <div className="mt-6">
        <p className="mb-0 text-lg font-medium text-grey_30">City, State</p>
        <p className="my-2 text-lg font-medium text-[#000000]">
          {profInfo?.state.name}, {profInfo?.city.name}
        </p>
      </div>
    </div>
  );
}

export default ProfessionalInformation;
