import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";
import {useState} from "react";
import PackageCard from "../Components/PackageCard";
import AddPackageModal from "../Modal/PackageModal";

const PackagesTab = ({data, isLoading, error}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setselectedItem] = useState();
  if (isLoading) return <CircularLoader />;
  if (error) return <ErrorMsg error={error} />;
  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3 ">
        {data?.map((item, ind) => (
          <PackageCard
            onManage={() => {
              setselectedItem(item);
              setOpenModal(true);
            }}
            item={item}
            key={item.id}
            id={ind}
          />
        ))}
      </div>
      <AddPackageModal
        type="edit"
        openModal={openModal}
        data={selectedItem}
        closeModal={() => setOpenModal(false)}
      />
    </>
  );
};
export default PackagesTab;
