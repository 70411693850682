import { createSlice } from "@reduxjs/toolkit";

export const SideBarSlice = createSlice({
  name: "sidebarOpen",
  initialState: {
    isSideBarOpen: false,
  },
  reducers: {
    update: (state) => {
      state.isSideBarOpen = !state.isSideBarOpen;
    },
  },
});

export const { update } = SideBarSlice.actions;

export default SideBarSlice.reducer;
