import { Button } from "@mui/material";
import EmptyResponse from "components/mui-components/EmptyResponse";
import TicketCard from "../widgets/TicketCard";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import TicketsIcon from "components/Vectors/TicketIcon";
import { useNavigate } from "react-router-dom";
import ErrorMsg from "components/mui-components/ErrorMsg";
import CircularLoader from "components/Loader/CircularLoader";
import { useDashboardOpenTicketsQuery } from "services/admin/adminGeneralApi";

const OpenTickets = () => {
  const { data, isLoading, isError, error } = useDashboardOpenTicketsQuery({
    is_paginated: "yes", per_page: 15
  });
  const navigate = useNavigate();
  if (isError) return <ErrorMsg error={error} />;
  if (data?.data?.length === 0)
    return (
      <EmptyResponse
        icon={<TicketsIcon width={"62px"} height="62px" stroke={"#3B3D3B"} />}
        message={"No new tickets available"}
      />
    );
  return (
    <div>
      <div className="heading mb-4 flex items-center justify-between">
        <h3 className="font-Bai text-base font-medium text-grey_10 sm:text-2xl">
          Open Tickets
        </h3>
        <Button
          onClick={() => navigate("/admin/tickets?tab=in-progress")}
          variant="text"
          endIcon={<ArrowForwardOutlinedIcon color="primary" />}
        >
          View all
        </Button>
      </div>
      {isLoading && <CircularLoader />}
      {data?.data && (
        <div className="noScroll flex h-full max-h-[569px] flex-col gap-4 overflow-y-auto rounded-2xl bg-white p-4">
          {data?.data?.slice(0, 12).map(ticket => (
            <TicketCard
              key={`ticket_${ticket?.id}`}
              id={ticket.id}
              name={ticket?.user?.full_name}
              avatar={ticket?.user?.avatar}
              type={ticket?.user_role_type}
              tag={ticket?.ticket_tag}
              badge={ticket?.user?.is_verified_account}
              priority={ticket.priority}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default OpenTickets;
