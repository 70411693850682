import NewAuthLayout from "templates/AuthLayout/NewAuthLayout";
import {LoadingButton} from "@mui/lab";
import {Button} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import {handleError} from "utils";
import {useAdminResetPasswordMutation} from "services/auth.api";
import CircularProgress from "@mui/material/CircularProgress";

const AdminEnterNewPassword = () => {
  const navigate = useNavigate();
  const methods = useForm();

  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get("token");

  const [setNewPwd, {isLoading}] = useAdminResetPasswordMutation();

  const password = methods.watch("password");

  const onSubmit = async data => {
    const formData = new FormData();

    formData.append("password", data.password);
    formData.append("password_confirmation", data.password_confirmation);
    formData.append("token", resetToken);

    try {
      await setNewPwd(formData).unwrap();
      navigate("/admin/reset-successful");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <NewAuthLayout>
      <div className="flex flex-col items-center ">
        <h3 className="mb-3 text-center font-Bai text-2xl font-semibold text-[#362E1F] md:text-4xl">
          Set New Password
        </h3>
        <FormProvider {...methods}>
          <form
            className=" flex w-full flex-col gap-4 "
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <ValidatedInput2
              name={"password"}
              textSize="text-sm"
              label="Password"
              type="password"
              placeholder="*******"
            />

            <ValidatedInput2
              textSize="text-sm"
              name="password_confirmation"
              label="Confirm Password"
              type="password"
              placeholder="*******"
              rules={{
                validate: value =>
                  value === password || "Password does not match",
              }}
            />
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              sx={{mt: 2, mb: 2}}
              loading={isLoading}
              loadingIndicator={
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size="1.2rem"
                />
              }
            >
              Proceed
            </LoadingButton>
          </form>
        </FormProvider>

        <Button variant="" fullWidth onClick={() => navigate("/admin/login")}>
          Cancel
        </Button>
      </div>
    </NewAuthLayout>
  );
};

export default AdminEnterNewPassword;
