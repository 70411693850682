import {Link} from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import Admin from "./Admin";
import Role from "./Role";
import {useSearchParams} from "react-router-dom";

const SingleAdminAdmin = () => {
  // const [state, setState] = useState("");
  const [searchParams] = useSearchParams();

  const state = searchParams.get("state");

  return (
    <DashboardLayout title="Administrators" type="admin">
      <main>
        <Link
          to="/admin/administrators"
          className="flex w-fit items-center gap-x-2.5 font-medium text-grey_20"
        >
          <span className="flex h-6 w-6 items-center justify-center rounded-full bg-white">
            <KeyboardArrowLeftIcon sx={{fontSize: "1rem"}} />
          </span>
          Administrators
        </Link>
        <div className="my-8 rounded-24 bg-white p-3 md:p-8 md:pb-32">
          {state?.toLowerCase().includes("admin") && <Admin />}
          {state?.toLowerCase().includes("role") && <Role />}
        </div>
      </main>
    </DashboardLayout>
  );
};

export default SingleAdminAdmin;
