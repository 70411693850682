import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";
import TablePagination from "components/mui-components/TablePagination";
import {useState} from "react";
import {useGetAllJobsQuery} from "services/user/providerGeneralApi";
import {removeEmpty} from "utils";
import JobsContent from "../MarketPlace/Tabs/JobsContent";

const FollowedJobs = () => {
  const [rowPerPage, setRowPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const queryParams = {
    page,
    per_page: rowPerPage,
    is_paginated: "yes",
  };

  const {
    data: allJobs,
    isLoading,
    error,
  } = useGetAllJobsQuery(removeEmpty(queryParams));
  const jobs = allJobs?.data;
  if (isLoading) {
    return <CircularLoader />;
  }
  if (error) {
    return <ErrorMsg error={error} />;
  }
  return (
    <>
      <JobsContent
        emptyStateMsg="No Followed Job"
        subtitle="No job has been followed yet"
        data={{
          ...allJobs?.data,
          data: allJobs?.data?.data?.filter(job => job?.is_following),
        }}
      />
      {!isLoading && (
        <div className="mt-4 w-full">
          {allJobs?.data?.data?.filter(job => job?.is_following).length > 0 && (
            <TablePagination
              meta={{
                totalPages: jobs?.last_page,
                totalCount: jobs?.data?.filter(job => job?.is_following).length,
                currentPage: jobs?.current_page,
              }}
              from={jobs?.from}
              to={jobs?.data?.filter(job => job?.is_following).length}
              page={page}
              setPage={setPage}
              perPage={rowPerPage}
              setPerPage={setRowPerPage}
            />
          )}
        </div>
      )}
    </>
  );
};
export default FollowedJobs;
