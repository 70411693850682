function ErrorMascot(props) {
  return (
    <svg
      width="220"
      height="220"
      viewBox="0 0 220 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M107.947 178.017C107.507 178.127 107.067 178.163 106.627 178.163C106.554 178.163 106.481 178.163 106.407 178.163C103.327 178.053 100.467 175.633 98.7072 172.407C90.8972 158.033 88.7705 149.16 85.9472 139.443C85.2505 137.023 85.4339 134.567 86.4605 132.807C87.2672 131.45 88.5505 130.46 90.3105 130.277C90.5305 130.24 90.7139 130.24 90.9339 130.24C94.0505 130.24 97.3139 132.733 98.5972 135.96L111.981 168.74C113.667 172.883 111.834 177.137 107.947 178.017Z"
        fill="#FFB96C"
      />
      <path
        d="M106.407 178.163C103.327 178.053 100.467 175.633 98.7072 172.407C90.8972 158.033 88.7705 149.16 85.9472 139.443C85.2505 137.023 85.4339 134.567 86.4605 132.807C89.9439 141.24 101.384 168.923 104.904 175.377C105.454 176.403 105.967 177.32 106.407 178.163Z"
        fill="#FCAF63"
      />
      <path
        d="M124.739 173.067C124.409 173.91 123.969 174.57 123.529 174.9C120.889 176.99 118.286 177.467 113.923 178.42C109.559 179.373 105.049 179.153 102.959 177.173C102.263 176.513 101.749 175.78 101.456 175.01C100.539 172.81 101.346 170.5 103.949 169.51C105.489 168.923 119.496 165.623 121.806 166.063C125.803 166.833 125.729 170.61 124.739 173.067Z"
        fill="#FFB96C"
      />
      <path
        d="M124.74 173.067C124.41 173.91 123.97 174.57 123.53 174.9C120.89 176.99 118.287 177.467 113.924 178.42C109.56 179.373 105.05 179.153 102.96 177.173C102.264 176.513 101.75 175.78 101.457 175.01C102.63 175.34 104.977 175.78 108.974 175.34C112.347 174.973 119.277 173.91 124.74 173.067Z"
        fill="#FCAF63"
      />
      <path
        d="M121.073 166.32C116.71 167.787 117.187 170.683 117.847 173.507C118.507 176.33 123.933 183.59 125.583 183.957C127.233 184.323 127.343 183.663 126.903 182.307C126.463 180.95 123.347 176.22 123.347 176.22C123.347 176.22 126.793 180.913 128.223 182.307C129.653 183.7 130.863 183.59 130.827 182.05C130.753 180.51 127.343 175.34 127.343 175.34C127.343 175.34 130.35 179.3 131.597 180.657C132.843 182.013 134.493 181.793 133.907 179.96C133.357 178.127 130.863 174.13 130.863 174.13C130.863 174.13 133.283 178.163 134.897 178.567C136.51 178.97 135.52 176.55 133.797 173.47C132.11 170.317 126.5 164.487 121.073 166.32Z"
        fill="#FEA691"
      />
      <path
        d="M117.407 170.353C117.407 170.353 116.49 173.947 117.077 177.1C117.664 180.253 118.654 180.877 119.314 181.023C119.974 181.17 120.194 180.327 119.864 178.97C119.534 177.613 120.414 174.937 120.414 174.937C120.414 174.937 119.46 169.547 117.407 170.353Z"
        fill="#FEA691"
      />
      <path
        d="M100.136 181.867H63.1397C63.1397 181.867 58.043 146.227 61.2697 137.463C62.1863 134.97 63.3963 132.953 64.7897 131.267C68.273 127.013 72.7463 125.07 75.7897 124.3C79.7863 123.273 83.2697 123.237 86.2763 123.97C94.2697 125.877 98.7063 133.173 100.136 141.13C102.116 152.093 100.136 181.867 100.136 181.867Z"
        fill="#FEC272"
      />
      <path
        d="M76.1206 131.45C71.2806 132.697 66.8439 132.55 64.7539 131.267C68.2372 127.013 72.7106 125.07 75.7539 124.3C79.7506 123.273 83.2339 123.237 86.2406 123.97C86.9006 126.463 82.3539 129.837 76.1206 131.45Z"
        fill="#F9B35F"
      />
      <path
        d="M72.9674 139.81C72.9674 140.397 72.9307 140.983 72.8207 141.533C71.3174 151.287 62.664 174.753 62.664 174.753C62.0407 176.623 60.7207 178.017 59.1074 178.787C57.494 179.52 55.624 179.667 53.8274 178.97C51.0407 177.87 49.7207 175.23 49.7207 172.333C49.7207 171.71 49.794 171.087 49.904 170.463C52.434 156.787 56.944 145.457 60.6474 137.463C62.2607 133.943 66.2207 131.743 69.704 133.503C70.7674 134.017 71.5007 134.86 72.0507 135.813C72.7107 136.987 72.9674 138.38 72.9674 139.81Z"
        fill="#FFCD92"
      />
      <path
        d="M72.9674 139.81C72.9674 140.397 72.9308 140.983 72.8208 141.533C71.3174 151.287 62.6641 174.753 62.6641 174.753C62.0408 176.623 60.7208 178.017 59.1074 178.787L72.0141 135.777C72.7108 136.987 72.9674 138.38 72.9674 139.81Z"
        fill="#FCB765"
      />
      <path
        d="M84.517 178.713C83.7103 180.693 81.0703 180.767 81.0703 180.767C81.0703 180.767 73.6636 181.207 67.247 181.207C60.8303 181.207 54.5236 181.207 51.957 178.31C51.4436 177.723 51.0403 177.063 50.8203 176.367C49.867 173.69 51.077 170.5 54.8903 168.887C57.6403 167.713 60.7936 168.117 64.2403 168.447C67.687 168.777 74.3236 170.317 78.1736 171.343C82.0603 172.333 84.3703 173.323 84.7003 176.99C84.8103 177.687 84.7003 178.237 84.517 178.713Z"
        fill="#FFCD92"
      />
      <path
        d="M84.517 178.713C83.7103 180.693 81.0703 180.767 81.0703 180.767C81.0703 180.767 73.6636 181.207 67.247 181.207C60.8303 181.207 54.5236 181.207 51.957 178.31C51.4436 177.723 51.0403 177.063 50.8203 176.367C53.6803 177.247 57.5303 178.273 60.6103 178.53C69.3003 179.263 80.8136 178.897 84.517 178.713Z"
        fill="#FCB765"
      />
      <path
        d="M92.2533 172.81C92.2533 173.397 92.18 174.02 92.0333 174.68C91.2633 177.833 87.1933 180.253 80.9967 180.583C78.2467 180.73 76.3767 180.73 75.13 180.437C73.5167 180.033 72.8933 179.08 72.7467 177.1C72.5633 174.57 74.47 172.26 75.9 171.27C80.19 168.373 84.1867 168.153 87.67 168.483C90.7867 168.813 92.2167 170.537 92.2533 172.81Z"
        fill="#FEA691"
      />
      <path
        d="M92.2539 172.81C92.2539 173.397 92.1805 174.02 92.0339 174.68C91.2639 177.833 87.1939 180.253 80.9972 180.583C78.2472 180.73 76.3772 180.73 75.1305 180.437C74.9839 180.07 74.8739 179.63 74.8372 179.08C74.6539 176.917 76.4505 174.9 77.8439 174.057C81.9139 171.563 85.7272 171.343 89.0272 171.673C90.4939 171.783 91.5572 172.187 92.2539 172.81Z"
        fill="#FC8172"
      />
      <path
        d="M97.0568 179.19C97.0568 179.593 96.9834 179.96 96.8734 180.363H76.7068C76.5968 179.997 76.5234 179.593 76.5234 179.19C76.5234 175.67 81.1068 172.81 86.7901 172.81C92.4734 172.81 97.0568 175.67 97.0568 179.19Z"
        fill="#C7DCF9"
      />
      <path
        opacity="0.55"
        d="M96.6175 179.74C96.6175 179.96 96.5809 180.143 96.4709 180.363H78.0275C77.9175 180.143 77.8809 179.96 77.8809 179.74C77.8809 177.87 82.0609 176.33 87.2309 176.33C92.4375 176.33 96.6175 177.87 96.6175 179.74Z"
        fill="#C7DCF9"
      />
      <path
        d="M92.6207 167.42C93.9407 167.567 94.8574 168.41 95.3707 170.17C95.884 171.93 95.994 174.533 95.444 175.45C94.894 176.367 93.8673 176.77 92.7307 175.853C91.594 174.937 90.9707 172.37 90.604 171.087C90.164 169.4 89.6507 167.127 92.6207 167.42Z"
        fill="#FEA691"
      />
      <path
        d="M87.7086 166.577C89.1386 166.723 89.7253 167.933 90.2753 169.767C90.8253 171.6 90.9353 174.387 90.3486 175.377C89.762 176.33 88.662 176.77 87.452 175.817C86.242 174.863 85.5453 172.113 85.1786 170.757C84.702 168.96 84.482 166.283 87.7086 166.577Z"
        fill="#FEA691"
      />
      <path
        d="M89.1384 176.293C88.6251 176.403 88.0384 176.293 87.4518 175.78C86.2418 174.827 85.5818 172.077 85.1784 170.72C84.7751 169.253 84.5551 167.163 86.2784 166.65C85.9484 167.64 86.1318 168.997 86.3884 170.023C86.7551 171.6 87.4518 174.79 88.6618 175.89C88.8451 176.073 88.9918 176.22 89.1384 176.293Z"
        fill="#FF8D76"
      />
      <path
        d="M82.3537 167.42C83.7837 167.567 84.737 168.483 85.287 170.317C85.837 172.15 85.947 174.937 85.3603 175.927C84.7737 176.88 83.6737 177.32 82.4637 176.367C81.2537 175.413 80.557 172.663 80.1903 171.307C79.7137 169.51 79.1637 167.09 82.3537 167.42Z"
        fill="#FEA691"
      />
      <path
        d="M83.7841 176.88C83.3807 176.88 82.9407 176.697 82.5007 176.33C81.2907 175.377 80.6307 172.627 80.2274 171.27C79.8607 169.877 79.4207 168.117 80.7774 167.53C80.6674 168.447 80.924 169.62 81.1807 170.573C81.6207 172.223 82.3907 175.597 83.7474 176.807C83.7474 176.88 83.7474 176.88 83.7841 176.88Z"
        fill="#FF8D76"
      />
      <path
        d="M77.5515 168.447C78.7982 168.08 79.7515 169.437 80.2282 171.123C80.7048 172.81 80.8515 175.377 80.3015 176.257C79.7882 177.137 78.7615 177.54 77.6615 176.66C76.5615 175.78 76.2682 174.093 75.9382 172.847C75.4982 171.233 75.1315 169.18 77.5515 168.447Z"
        fill="#FEA691"
      />
      <path
        d="M78.9075 177.173C78.5041 177.173 78.1008 177.027 77.6608 176.66C76.5608 175.78 76.2675 174.093 75.9375 172.847C75.5342 171.453 75.2408 169.73 76.7075 168.813C76.5975 169.803 76.7808 170.867 76.9641 171.82C77.2941 173.507 77.5508 175.817 78.7975 177.063C78.8341 177.1 78.8708 177.137 78.9075 177.173Z"
        fill="#FF8D76"
      />
      <path
        d="M94.1613 176.33C93.7213 176.367 93.2446 176.22 92.7679 175.817C91.6313 174.9 91.0079 172.333 90.6413 171.05C90.2746 169.693 89.8346 167.933 91.3013 167.493C91.3013 168.3 91.4846 169.217 91.6679 170.023C92.0346 171.673 92.7313 174.973 93.9046 176.147C94.0146 176.22 94.0879 176.293 94.1613 176.33Z"
        fill="#FF8D76"
      />
      <path
        d="M76.5586 86.167C76.5586 86.167 79.9686 84.7736 82.2786 89.3936C84.5886 94.0503 82.0953 97.0203 82.0953 97.0203C82.0953 97.0203 79.8219 90.3103 76.5586 86.167Z"
        fill="#FF8D76"
      />
      <path
        d="M78.9049 128.663C75.6783 130.167 73.4416 128.077 73.4416 128.077L71.4249 123.713L67.7949 115.867C73.2583 114.693 78.0249 113.777 78.0249 113.777L78.8683 117.26L80.4449 123.75C80.3716 123.787 82.1316 127.197 78.9049 128.663Z"
        fill="#FEA691"
      />
      <path
        d="M78.8331 117.297C78.3564 117.993 77.8431 118.727 77.2198 119.423C75.6431 121.293 73.3698 122.723 71.3898 123.713L67.7598 115.867C73.2231 114.693 77.9898 113.777 77.9898 113.777L78.8331 117.297Z"
        fill="#FF8D76"
      />
      <path
        d="M75.8403 116.707C84.0078 111.742 85.709 99.6237 79.6401 89.6393C73.5712 79.6549 62.0303 75.5854 53.8628 80.55C45.6953 85.5145 43.9941 97.633 50.063 107.617C56.1319 117.602 67.6728 121.671 75.8403 116.707Z"
        fill="#FEA691"
      />
      <path
        d="M79.7123 113.227C78.6489 114.584 77.3289 115.757 75.8256 116.71C67.6489 121.66 56.1356 117.59 50.0489 107.617C44.0356 97.7536 45.6489 85.7636 53.6056 80.7036C48.9856 86.6436 48.7656 96.1769 53.7156 104.28C59.7656 114.254 71.3156 118.324 79.4923 113.374C79.5656 113.337 79.6389 113.3 79.7123 113.227Z"
        fill="#FF8D76"
      />
      <path
        d="M57.6387 113.484C57.6387 113.484 51.4054 108.57 49.8287 99.7703C48.252 90.9703 50.012 87.4136 57.2354 83.1603C64.4587 78.9436 69.8487 80.4469 69.8487 80.4469C69.8487 80.4469 64.7154 76.5969 56.3187 78.0269C47.922 79.4203 39.3787 87.4136 41.1387 99.4403C42.972 112.274 50.5987 116.05 57.6387 113.484Z"
        fill="#283575"
      />
      <path
        d="M65.1926 90.4935C64.2026 91.1902 65.596 94.8935 68.6026 97.6435C71.6093 100.394 74.506 98.9635 74.5793 96.2502C74.6526 93.4635 68.6026 88.0735 65.1926 90.4935Z"
        fill="#F76F59"
      />
      <path
        d="M50.4153 106.847C50.4153 106.847 44.5487 112.603 49.8287 117.627C54.8887 122.43 61.2687 115.977 61.2687 115.977C61.2687 115.977 52.322 111.173 50.4153 106.847Z"
        fill="#FF8D76"
      />
      <path
        d="M51.1125 110.183C51.1125 110.183 47.8125 113.41 50.7825 116.27C53.6425 118.983 57.2359 115.353 57.2359 115.353C57.2359 115.353 52.1759 112.64 51.1125 110.183Z"
        fill="#F76F59"
      />
      <path
        d="M69.1159 79.9699C69.1159 79.9699 69.7392 68.8966 63.4325 64.3866C56.3192 59.3266 52.3225 66.6232 54.6692 72.5999C55.6959 75.2766 56.7959 76.8899 57.6759 77.8432C58.4825 78.7232 59.5825 79.2366 60.7559 79.3099L69.1159 79.9699Z"
        fill="#283575"
      />
      <path
        d="M44.9892 70.6198C39.3792 75.0932 44.2192 81.5098 46.5292 83.2332C47.5192 83.9665 48.5825 84.5532 49.4992 84.9932C50.7458 85.5798 52.2125 85.5065 53.3858 84.7732L61.3058 79.7498C61.3058 79.7865 52.4325 64.7165 44.9892 70.6198Z"
        fill="#283575"
      />
      <path
        d="M58.2994 63.1764C56.5394 63.3964 58.4827 66.7698 61.0494 67.2098C63.6161 67.6498 62.8094 62.6264 58.2994 63.1764Z"
        fill="#3C4E8E"
      />
      <path
        d="M47.1892 70.6932C46.1625 71.8666 48.8392 73.3699 51.0025 72.6366C53.2025 71.9399 49.4625 68.1266 47.1892 70.6932Z"
        fill="#3C4E8E"
      />
      <path
        d="M79.8234 104.5C79.6401 104.867 79.3834 105.197 79.0168 105.49C77.6601 106.553 76.6334 108.093 75.9001 109.707C75.2401 111.137 73.5534 112.823 71.9768 111.613C71.7934 111.467 71.6101 111.32 71.4634 111.1C69.8134 109.01 70.4368 103.62 73.0034 101.237C74.9834 99.4034 77.4401 99.8801 78.6868 100.907C79.8968 101.823 80.4101 103.29 79.8234 104.5Z"
        fill="#F76F59"
      />
      <path
        d="M79.8232 104.5C79.6399 104.867 79.3832 105.197 79.0166 105.49C77.6599 106.553 76.6332 108.093 75.8999 109.707C75.2399 111.137 73.5532 112.823 71.9766 111.613C71.9766 109.303 72.8199 106.59 74.4332 105.123C76.3032 103.437 78.5032 103.657 79.8232 104.5Z"
        fill="#FF6EA9"
      />
      <path
        d="M57.1258 98.1564C55.9524 98.1564 55.0358 97.7898 54.9624 97.7531C54.4858 97.5698 54.2658 97.0198 54.4491 96.5431C54.6324 96.0664 55.1824 95.8464 55.6591 96.0298C55.6958 96.0298 57.7124 96.8364 58.8124 95.6264C60.2424 94.1231 58.7391 92.1798 58.7024 92.1064C58.3724 91.7031 58.4458 91.1164 58.8491 90.7864C59.2524 90.4564 59.8391 90.5298 60.1691 90.9331C61.0491 92.0698 62.1858 94.7464 60.1691 96.8731C59.2524 97.8631 58.1158 98.1564 57.1258 98.1564Z"
        fill="#1C3177"
      />
      <path
        d="M70.7675 88.9534C68.8975 88.9534 67.3208 87.5601 67.2108 87.4867C66.8075 87.1567 66.8075 86.5334 67.1375 86.1667C67.4675 85.8 68.0541 85.7634 68.4575 86.0934C68.4575 86.0934 70.1441 87.5234 71.3908 86.9734C71.9408 86.7167 71.5375 85.58 71.5008 85.5434C71.3175 85.0667 71.5375 84.5167 72.0508 84.3334C72.5275 84.15 73.0775 84.37 73.2608 84.8467C73.8108 86.2034 73.6641 88 72.1608 88.66C71.6841 88.88 71.2075 88.9534 70.7675 88.9534Z"
        fill="#1C3177"
      />
      <path
        d="M52.2137 95.9566C52.177 95.9566 52.1403 95.9566 52.1037 95.9566C51.5903 95.8833 51.2237 95.4433 51.2603 94.9299C51.2603 94.8199 51.517 92.6199 53.057 90.3833C54.5603 88.1466 56.1003 87.3766 56.1737 87.3399C56.6503 87.1199 57.2003 87.3033 57.4203 87.7799C57.6403 88.2566 57.457 88.8066 56.9803 89.0266C56.9803 89.0266 55.807 89.6499 54.597 91.4099C53.3503 93.2433 53.1303 95.0766 53.1303 95.1133C53.0937 95.5899 52.6903 95.9566 52.2137 95.9566Z"
        fill="#1C3177"
      />
      <path
        d="M65.6326 85.1034C65.3759 85.1034 65.0826 84.9934 64.8992 84.7734C64.5692 84.3701 64.6426 83.7834 65.0459 83.4534C65.1559 83.3801 65.9992 82.6834 67.5026 82.2434C69.0059 81.8034 70.2526 81.8401 70.2892 81.8401C70.8026 81.8401 71.2059 82.2801 71.2059 82.7934C71.2059 83.3068 70.7292 83.7101 70.2526 83.7101C70.2526 83.7101 69.2626 83.6734 68.0526 84.0401C66.8792 84.4068 66.2192 84.9201 66.2192 84.9201C66.0359 85.0301 65.8526 85.1034 65.6326 85.1034Z"
        fill="#1C3177"
      />
      <path
        d="M185.498 185.643H36.0443C34.5776 185.643 33.4043 184.47 33.4043 183.003C33.4043 181.537 34.5776 180.363 36.0443 180.363H185.498C186.964 180.363 188.138 181.537 188.138 183.003C188.138 184.433 186.964 185.643 185.498 185.643Z"
        fill="#99ADF9"
      />
      <path
        d="M185.242 185.643H57.6048C56.1382 185.643 54.9648 184.47 54.9648 183.003C54.9648 181.537 56.1382 180.363 57.6048 180.363H185.242C186.708 180.363 187.882 181.537 187.882 183.003C187.918 184.433 186.708 185.643 185.242 185.643Z"
        fill="#789FEF"
      />
      <path
        d="M167.786 163.057H99.5864C98.3764 163.057 97.4231 161.957 97.6431 160.747L104.903 117.223C105.05 116.27 105.893 115.573 106.846 115.573H175.046C176.256 115.573 177.21 116.673 176.99 117.883L169.766 161.407C169.583 162.36 168.776 163.057 167.786 163.057Z"
        fill="#E1ECFF"
      />
      <path
        d="M169.252 163.057H101.932C100.722 163.057 99.7688 161.957 99.9888 160.747L107.249 117.223C107.395 116.27 108.239 115.573 109.192 115.573H176.512C177.722 115.573 178.675 116.673 178.455 117.883L171.195 161.407C171.049 162.36 170.205 163.057 169.252 163.057Z"
        fill="#C7DCF9"
      />
      <path
        d="M162.14 158.4H110.734C109.78 158.4 109.084 157.556 109.23 156.64L114.767 123.42C114.877 122.686 115.537 122.136 116.27 122.136H167.64C168.594 122.136 169.29 122.98 169.144 123.896L163.607 157.116C163.497 157.85 162.874 158.4 162.14 158.4Z"
        fill="#B7D4F7"
      />
      <path
        d="M140.176 177.65H130.826C129.946 177.65 129.286 176.917 129.359 176.037L131.816 151.653C131.889 150.92 132.512 150.333 133.282 150.333H142.632C143.512 150.333 144.172 151.067 144.099 151.947L141.642 176.33C141.569 177.1 140.946 177.65 140.176 177.65Z"
        fill="#E1ECFF"
      />
      <path
        d="M140.836 177.65H132.256C131.706 177.65 131.266 177.173 131.303 176.623L133.87 151.177C133.906 150.7 134.31 150.333 134.823 150.333H143.403C143.953 150.333 144.393 150.81 144.356 151.36L141.79 176.807C141.716 177.283 141.313 177.65 140.836 177.65Z"
        fill="#C7DCF9"
      />
      <path
        d="M155.723 180.326H116.783C115.976 180.326 115.316 179.666 115.316 178.86V175.633C115.316 174.827 115.976 174.167 116.783 174.167H155.723C156.53 174.167 157.19 174.827 157.19 175.633V178.86C157.19 179.666 156.53 180.326 155.723 180.326Z"
        fill="#E1ECFF"
      />
      <path
        d="M156.529 180.326H119.899C119.056 180.326 118.359 179.63 118.359 178.787V175.707C118.359 174.863 119.056 174.167 119.899 174.167H156.529C157.373 174.167 158.069 174.863 158.069 175.707V178.787C158.033 179.63 157.373 180.326 156.529 180.326Z"
        fill="#C7DCF9"
      />
      <path
        d="M183.884 109.523C183.517 109.78 183.041 109.963 182.527 109.963H147.401C145.641 109.963 144.541 108.13 145.347 106.553L158.107 82.0231L162.801 73.0031C163.681 71.3531 166.027 71.3531 166.907 73.0031L184.581 106.553C185.167 107.653 184.764 108.9 183.884 109.523Z"
        fill="#FDA29B"
      />
      <path
        d="M183.884 109.523C183.517 109.78 183.041 109.963 182.527 109.963H147.401C145.641 109.963 144.541 108.13 145.347 106.553L158.107 82.0229L154.221 102.666C153.891 104.426 155.101 106.076 156.897 106.296L183.884 109.523Z"
        fill="#F97066"
      />
      <path
        d="M163.241 96.8362L161.408 83.7095C161.334 83.2695 161.701 82.8662 162.141 82.8662H167.348C167.788 82.8662 168.118 83.2695 168.081 83.7095L166.248 96.8362C166.211 97.2029 165.881 97.4595 165.514 97.4595H163.938C163.571 97.4962 163.278 97.2029 163.241 96.8362Z"
        fill="white"
      />
      <path
        d="M164.744 104.94C166.182 104.94 167.347 103.774 167.347 102.336C167.347 100.898 166.182 99.7329 164.744 99.7329C163.306 99.7329 162.141 100.898 162.141 102.336C162.141 103.774 163.306 104.94 164.744 104.94Z"
        fill="white"
      />
      <path
        d="M69.5564 51.7732L70.2164 53.1299C70.2897 53.3132 70.4731 53.4232 70.6564 53.4232L72.1231 53.6432C72.5997 53.7165 72.7831 54.2665 72.4164 54.5965L71.3531 55.6232C71.2064 55.7699 71.1697 55.9532 71.2064 56.1365L71.4631 57.6032C71.5364 58.0432 71.0597 58.4099 70.6564 58.1899L69.3364 57.4932C69.1897 57.4199 68.9697 57.4199 68.8231 57.4932L67.5031 58.1899C67.0997 58.4099 66.6231 58.0432 66.6964 57.6032L66.9531 56.1365C66.9897 55.9532 66.9164 55.7699 66.8064 55.6232L65.7431 54.5965C65.4131 54.2665 65.5964 53.7165 66.0364 53.6432L67.5031 53.4232C67.6864 53.3865 67.8331 53.2765 67.9431 53.1299L68.6031 51.7732C68.7497 51.3699 69.3364 51.3699 69.5564 51.7732Z"
        fill="#FEC272"
      />
      <path
        d="M140.91 62.4068C140.91 67.4668 139.444 72.1601 136.914 76.1568C135.997 78.2834 131.377 87.2668 115.684 94.4534C99.2203 102.007 84.627 96.6168 84.627 96.6168C84.627 96.6168 93.6103 90.7134 91.9236 80.8501C90.6036 73.0768 89.687 67.5034 89.7603 62.8101C89.7603 62.6634 89.7603 62.5534 89.7603 62.4068C89.7603 48.2901 101.2 36.8501 115.317 36.8501C129.434 36.8501 140.91 48.2901 140.91 62.4068Z"
        fill="#FDF2E6"
      />
      <path
        d="M87.3036 79.3835C85.947 75.9002 84.5903 76.0469 83.6736 76.7069C82.8303 77.3302 82.647 79.4935 83.6736 81.5469C84.7003 83.6002 85.727 83.4535 86.7536 82.9035C87.7803 82.3535 87.927 80.9969 87.3036 79.3835Z"
        fill="#C7DCF9"
      />
      <path
        d="M132.586 87.0835C126.94 90.0902 127.453 92.4002 128.773 93.7935C130.02 95.1135 133.723 94.9668 136.986 92.8402C140.25 90.7135 139.846 88.9902 138.71 87.3402C137.573 85.6902 135.19 85.6535 132.586 87.0835Z"
        fill="#C7DCF9"
      />
      <path
        d="M133.687 70.3265C133.687 71.7199 133.027 73.1132 131.78 73.9565C129.067 75.8632 124.74 78.6865 119.973 81.1799C116.93 82.7932 114.29 84.0399 112.163 84.9565C110.403 85.7265 108.46 85.5799 106.883 84.6999C105.82 84.1132 104.94 83.1965 104.39 82.0232C104.023 81.2165 103.803 80.3365 103.803 79.4565C103.803 78.7232 103.95 77.9899 104.207 77.2565L104.353 76.8532L110.66 60.8665L102.227 65.4132C99.8801 66.6599 96.9835 65.8532 95.6268 63.5432C95.5901 63.4699 95.5535 63.3965 95.5168 63.3232C95.1501 62.5899 94.9668 61.8199 94.9668 61.0499C94.9668 59.4365 95.7735 57.8232 97.2401 56.9065C99.9168 55.1832 103.803 52.8365 108.423 50.3799C117.297 45.6499 121.697 46.2365 122.943 48.6199C124.19 51.0399 117.04 71.2799 117.04 71.2799C117.04 71.2799 126.61 66.4765 127.637 66.1099C129.837 65.2665 132.293 66.2932 133.283 68.4199C133.503 68.8965 133.613 69.4099 133.65 69.9232C133.687 70.1065 133.687 70.2165 133.687 70.3265Z"
        fill="#E98100"
      />
      <path
        d="M113.337 58.2263C111.211 63.4697 106.187 75.533 104.427 76.8897L110.734 60.903L102.301 65.4497C99.9541 66.733 96.9841 65.8897 95.6641 63.543C95.7374 63.4697 95.6641 63.543 95.7374 63.4697C96.5807 63.873 98.0841 64.2397 100.321 63.1397C104.501 61.1597 109.267 58.5563 112.017 57.053C112.787 56.6497 113.667 57.4197 113.337 58.2263Z"
        fill="#F8D8B0"
      />
      <path
        d="M133.686 70.3265C133.686 71.7199 133.026 73.1132 131.779 73.9565C129.066 75.8632 124.739 78.6865 119.973 81.1799C116.929 82.7932 114.289 84.0399 112.163 84.9565C110.403 85.7265 108.459 85.5799 106.883 84.6999C107.213 84.6265 107.543 84.5532 107.836 84.4432C109.486 83.8932 116.929 79.9699 124.226 76.0465C128.993 73.4799 132.219 71.4265 133.649 70.0332C133.686 70.1065 133.686 70.2165 133.686 70.3265Z"
        fill="#F8D8B0"
      />
      <path
        opacity="0.49"
        d="M115.207 48.2532C111.907 49.3532 110.22 51.4066 114.18 51.1866C118.14 50.9666 120.23 48.9499 119.533 48.2899C118.837 47.6666 116.71 47.7399 115.207 48.2532Z"
        fill="white"
      />
      <path
        opacity="0.49"
        d="M127.858 66.953C125.768 67.6496 124.668 68.9696 127.198 68.823C129.728 68.6763 131.048 67.393 130.608 66.9896C130.131 66.5863 128.811 66.623 127.858 66.953Z"
        fill="white"
      />
    </svg>
  );
}

export default ErrorMascot;
