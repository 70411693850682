import Modal from "components/mui-components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import {
  useDeclineCacMutation,
  useDeclineKycMutation,
} from "services/admin/adminGeneralApi";
import LoadingBtn from "components/mui-components/LoadingButton";
import {showToast} from "redux/store.hooks";
import {handleError} from "utils";

function DeclineKYCModal({open, close, userId, isCac, isProfessional}) {
  const methods = useForm();
  const [declineKyc, {isLoading: isDecliningKyc}] = useDeclineKycMutation();
  const [declineCac, {isLoading: isDecliningCac}] = useDeclineCacMutation();
  const onSubmit = async data => {
    try {
      const res = isCac
        ? await declineCac({id: userId, body: data}).unwrap()
        : await declineKyc({id: userId, body: data}).unwrap();
      showToast(res?.message, "success");
      methods.reset();
      close();
    } catch (err) {
      handleError(err);
    }
  };
  return (
    <Modal
      title={
        isCac
          ? "Decline CAC Certificate"
          : isProfessional
          ? "Decline Government Issued ID"
          : "Decline KYC"
      }
      openModal={open}
      maxWidth={600}
      closeModal={close}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full">
          <ValidatedTextArea2
            name="comment"
            label="Send Reason"
            placeholder="Type Something"
            height="min-h-[120px]"
          />
          <LoadingBtn
            loading={isCac ? isDecliningCac : isDecliningKyc}
            fullWidth
            color="error"
            sx={{mt: "3rem"}}
            type="submit"
          >
            Send
          </LoadingBtn>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default DeclineKYCModal;
