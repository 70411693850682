import Pusher from "pusher-js";
import "pages/LandingPage/myStyles.scss";
import ActiveEngagement from "components/Vectors/ActiveEngagement";
import Request from "components/Vectors/Request";
import Services from "components/Vectors/Services";
import NoActiveEngagement from "components/Vectors/NoActiveEngagement";
import NoActiveRequest from "components/Vectors/NoActiveRequest";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {Swiper, SwiperSlide} from "swiper/react";
import {
  useGetAllTicketsQuery,
  useGetOnboardingStatusQuery,
  useGetProviderOffersQuery,
  useGetAllEngagementByStatusQuery,
  useHasVisitedLandingPageMutation,
} from "services/user/userRegApi";
import {useGetNewestJobsQuery} from "services/user/providerGeneralApi";
import SwiperCore, {Navigation} from "swiper";
import CircularLoader from "components/Loader/CircularLoader";
import JobCard from "pages/ServiceProviders/MarketPlace/Widgets/JobCard";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useEffect, useState} from "react";
import {Tab} from "@mui/material";
import {useGetUser} from "hooks/getUserHook";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import Header from "pages/Clients/Dashboard/Header";
import SingleEngagement from "pages/Clients/Dashboard/SingleEngagement";
import OfferCard from "./MarketPlace/Widgets/OfferCard";
import {DisplayData} from "pages/Clients/Dashboard/Main";
import ReactJoyride from "react-joyride";
SwiperCore.use([Navigation]);

const ProfessionalDashboard = () => {
  const [activeTab, setActiveTab] = useState("jobs");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab) setActiveTab(tab);
  }, [searchParams]);
  const [markAsVisited] = useHasVisitedLandingPageMutation();
  const handleJoyrideCallback = data => {
    const {action} = data;
    if (action === "reset") markAsVisited().unwrap();
  };
  const {data: ticketsCount} = useGetAllTicketsQuery();
  const {
    data: newestJobs,
    isLoading: isLoadingNewestJobs,
    refetch: refetchNewestJobs,
    error,
  } = useGetNewestJobsQuery();
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("new-job-created-channel");
    channel.bind("new-job-created-event", () => {
      refetchNewestJobs();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [refetchNewestJobs]);

  const {
    data: jobOffers,
    isLoading: isOffersLoading,
    error: offersError,
  } = useGetProviderOffersQuery();
  const {data: onBoardingStatus} = useGetOnboardingStatusQuery();
  const {user} = useGetUser();
  const {
    data: allEngagements,
    isLoading,
    error: engError,
  } = useGetAllEngagementByStatusQuery({
    type: "service-provider",
    status: "Ongoing",
  });

  const tabs = [
    {
      title: "Ongoing Engagements",
      count: user?.ongoing_engagements_count,
      icon: <ActiveEngagement />,
    },
    {
      title: "My Job Offers",
      count: jobOffers?.filter(el => el?.job)?.length,
      icon: <Request fill="#E98100" />,
    },
    {
      title: "All Open Tickets",
      count: ticketsCount?.data.filter(ticket => ticket.status === "Open")
        .length,
      icon: <Services />,
    },
  ];

  if (isLoadingNewestJobs || isLoading || isOffersLoading)
    return <CircularLoader />;

  // console.log(newestJobs?.data,user,newestJobs?.data
  //   ?.filter(job => job?.user_id !== user?.id))
  return (
    <div>
      <ReactJoyride
        run={user?.has_visited_landing_page ? false : true}
        callback={handleJoyrideCallback}
        scrollToFirstStep={true}
        continuous={true}
        showProgress={true}
        locale={{back: "Previous"}}
        spotlightClick={false}
        hideCloseButton={true}
        steps={[
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[16px] font-semibold leading-[150%] text-grey_30">
                  Dashboard
                </p>
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  You manage and an overview of your transactions here.
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                  Let’s take you on a tour on how to manage your jobs.
                </p>
              </div>
            ),
            target: "body",
            placement: "center",
            locale: {
              skip: (
                <button
                  onClick={() => {
                    markAsVisited().unwrap();
                  }}
                >
                  Nevermind, Thanks
                </button>
              ),
              next: <p>Begin Tour</p>,
            },
            showProgress: false,
            hideCloseButton: true,
            showSkipButton: true,
          },
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  Discover Jobs
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext ">
                  Mauris pellentesque dictumst consectetur malesuada egestas
                  augue feugiat ut. Varius neque amet quis est id tortor vitae
                  vitae.
                </p>
              </div>
            ),
            target: ".sp_dashboard_first",
            locale: {next: <p>Next</p>},
            showProgress: true,
            hideBackButton: false,
          },
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  My Jobs and Engagements
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                  Mauris pellentesque dictumst consectetur malesuada egestas
                  augue feugiat ut. Varius neque amet quis est id tortor vitae
                  vitae.
                </p>
              </div>
            ),
            target: ".client_dashboard_second",
            locale: {next: <p>Next</p>},
            showProgress: true,
            hideBackButton: false,
          },
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  Followed Jobs
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                  Mauris pellentesque dictumst consectetur malesuada egestas
                  augue feugiat ut. Varius neque amet quis est id tortor vitae
                  vitae.
                </p>
              </div>
            ),
            target: ".sp_dashboard_third",
            locale: {next: <p>Next</p>},
            showProgress: true,
            hideBackButton: false,
          },
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  Notifications
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext ">
                  Mauris pellentesque dictumst consectetur malesuada egestas
                  augue feugiat ut. Varius neque amet quis est id tortor vitae
                  vitae.
                </p>
              </div>
            ),
            target: "a.client_dashboard_third",
            locale: {next: <p>Next</p>},
            showProgress: true,
            hideBackButton: false,
          },
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  More
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                  Mauris pellentesque dictumst consectetur malesuada egestas
                  augue feugiat ut. Varius neque amet quis est id tortor vitae
                  vitae.
                </p>
              </div>
            ),
            target: "button.client_dashboard_fifth",
            locale: {next: <p>Next</p>},
            showProgress: true,
            hideBackButton: false,
          },
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  Newest Jobs and My job offers
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                  Mauris pellentesque dictumst consectetur malesuada egestas
                  augue feugiat ut. Varius neque amet quis est id tortor vitae
                  vitae.
                </p>
              </div>
            ),
            target: ".sp_dashboard_sixth",
            locale: {next: <p>Next</p>},
            showProgress: true,
            hideBackButton: false,
          },
          {
            content: (
              <div className="text-left">
                <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                  Tokens
                </p>
                <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                  Mauris pellentesque dictumst consectetur malesuada egestas
                  augue feugiat ut. Varius neque amet quis est id tortor vitae
                  vitae.
                </p>
              </div>
            ),
            target: ".sp_dashboard_seventh",
            locale: {next: <p>Next</p>},
            showProgress: true,
            hideBackButton: false,
          },
        ]}
        styles={{
          tooltip: {
            borderRadius: "24px",
            padding: "10px 20px 30px",
            width: "486px",
          },
          buttonSkip: {
            display: "flex",
            padding: "16px 24px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "12px",
            background: "var(--gray-scale-gray-scale-70, #F2F4F7)",
            color: "#E98100",
            textAlign: "center",
            fontSize: "16px",
            fontFamily: "Mulish",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            letterSpacing: "-0.48px",
          },
          buttonBack: {
            display: "flex",
            padding: "16px 24px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "12px",
            background: "var(--gray-scale-gray-scale-70, #F2F4F7)",
            color: "#E98100",
            textAlign: "center",
            fontSize: "16px",
            fontFamily: "Mulish",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            letterSpacing: "-0.48px",
            marginLeft: "none",
          },
          buttonNext: {
            display: "flex",
            padding: "16px 24px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            minWidth: "max-content",
            borderRadius: "12px",
            background: "#E98100",
          },
          tooltipFooter: {
            justifyContent: "flex-start",
            gap: "12px",
          },
          buttonClose: {
            left: "10px",
            fontWeight: 800,
          },
        }}
      />
      <main className="rounded-lg md:rounded-2xl md:bg-white">
        <Header
          onBoardingStatus={onBoardingStatus?.data}
          isProfessional={true}
        />
        <div className="hidden h-px bg-[#EEEEEE] md:block"></div>
        <div className="p-2 md:py-10 md:px-10">
          <div className="hideScroll flex flex-row gap-8 overflow-scroll md:overflow-auto">
            {tabs.map(({title, count, icon}, index) => (
              <div
                key={index}
                className="flex min-w-[200px] basis-1/3 flex-col justify-between gap-y-5 rounded-20 bg-white p-6 md:bg-grey_70"
              >
                <p className="font-Inter text-text">{title}</p>
                <div className="flex flex-row justify-between md:flex-col md:gap-y-5">
                  <span className="font-Bai text-2xl font-semibold text-ltext md:font-normal">
                    {count}
                  </span>
                  {icon}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-6">
            <TabContext value={activeTab}>
              <div className="flex flex-wrap items-center justify-between gap-4">
                <TabList
                  variant="scrollable"
                  value={activeTab}
                  className="sp_dashboard_sixth mb-4 w-fit rounded-[16px] border border-grey_60 bg-grey_80 p-2"
                  onChange={(_, newValue) => {
                    navigate(`?tab=${newValue}`);
                    setActiveTab(newValue);
                  }}
                  aria-label="basic tabs example"
                >
                  <Tab label="Newest Jobs" value="jobs" />
                  <Tab label="My Job Offers" value="offers" />
                </TabList>
                <Button
                  endIcon={<ChevronRightOutlinedIcon />}
                  sx={{p: "0.5rem !important"}}
                  component={Link}
                  to="/service-provider/marketplace"
                >
                  Go to Marketplace
                </Button>
              </div>

              <TabPanel value="jobs">
                <DisplayData
                  error={error}
                  isError={error}
                  isEmpty={
                    newestJobs?.data?.filter(job => job?.user_id !== user?.id)
                      ?.data?.length === 0
                  }
                  emptyResponse={{
                    message: "No Jobs yet",
                    extraText: "No jobs have been posted yet",
                    icon: <NoActiveRequest />,
                  }}
                  isLoading={isLoadingNewestJobs}
                >
                  <div className="my-6 grid gap-6 md:grid-cols-3">
                    {newestJobs?.data
                      ?.filter(job => job?.user_id !== user?.id)
                      ?.slice(0, 6)
                      .map(job => (
                        <JobCard
                          key={job?.id}
                          job={job}
                          isProfessional={true}
                        />
                      ))}
                  </div>
                </DisplayData>
              </TabPanel>

              <TabPanel value="offers">
                <DisplayData
                  error={offersError}
                  isError={offersError}
                  isEmpty={jobOffers?.length === 0}
                  emptyResponse={{
                    message: "No Jobs offers yet",
                    extraText:
                      "You have not made any job offer yet, go to marketplace to discover jobs",
                    icon: <NoActiveRequest />,
                  }}
                  isLoading={isOffersLoading}
                >
                  <div className="my-6 grid gap-6 md:grid-cols-3">
                    {jobOffers
                      ?.filter(el => el?.job)
                      .slice(0, 6)
                      ?.map(offer => (
                        <OfferCard key={offer?.id} offer={offer} />
                      ))}
                  </div>
                </DisplayData>
              </TabPanel>
            </TabContext>
          </div>

          {/* Engagement */}
          <section className="mt-16 mb-8">
            <header className="mb-4 flex items-center justify-between font-Bai font-semibold">
              <h2 className="text-xl md:text-3xl">Ongoing Engagements</h2>
              <Button
                endIcon={<ChevronRightOutlinedIcon />}
                sx={{p: "0.5rem !important"}}
                component={Link}
                to="/service-provider/engagements"
              >
                View all
              </Button>
            </header>
            <DisplayData
              error={engError}
              isError={engError}
              isEmpty={allEngagements?.data?.length === 0}
              emptyResponse={{
                message: "No Ongoing engagement yet",
                icon: <NoActiveEngagement />,
              }}
              isLoading={isLoading}
            >
              <Swiper
                spaceBetween={40}
                slidesPerView={2.3}
                modules={[Navigation]}
                navigation={true}
                freeMode
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  640: {
                    slidesPerView: 1.5,
                  },
                  900: {
                    slidesPerView: 2.3,
                  },
                  1300: {
                    slidesPerView: 3.3,
                  },
                }}
              >
                {allEngagements?.data.map(engagement => (
                  <SwiperSlide key={engagement?.created_at}>
                    <SingleEngagement engagement={engagement} user={user} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </DisplayData>
          </section>
        </div>
      </main>
    </div>
  );
};

export default ProfessionalDashboard;
