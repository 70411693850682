const Header = () => {
  return (
    <header>
      <h1 className="font-Bai text-3xl font-semibold text-grey_20">
        Marketplace
      </h1>
      <p className="mt-2 font-medium text-grey_30">
        Discover jobs filtered by the categories you belong to.
      </p>
    </header>
  );
};

export default Header;
