import React from "react";

function ShieldTickIcon({color, width, height}) {
  return (
    <>
      <svg
        width={width || "18"}
        height={height || "22"}
        viewBox="0 0 18 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 10.5004L8 12.5004L12.5 8.00036M17 11.0004C17 15.9088 11.646 19.4788 9.69799 20.6152C9.4766 20.7444 9.3659 20.809 9.20968 20.8425C9.08844 20.8685 8.91156 20.8685 8.79032 20.8425C8.6341 20.809 8.5234 20.7444 8.30201 20.6152C6.35396 19.4788 1 15.9088 1 11.0004V6.21796C1 5.41845 1 5.01869 1.13076 4.67506C1.24627 4.3715 1.43398 4.10064 1.67766 3.88589C1.9535 3.6428 2.3278 3.50243 3.0764 3.22171L8.4382 1.21103C8.6461 1.13307 8.75005 1.09409 8.85698 1.07864C8.95184 1.06493 9.04816 1.06493 9.14302 1.07864C9.24995 1.09409 9.3539 1.13307 9.5618 1.21103L14.9236 3.22171C15.6722 3.50243 16.0465 3.6428 16.3223 3.88589C16.566 4.10064 16.7537 4.3715 16.8692 4.67506C17 5.01869 17 5.41845 17 6.21796V11.0004Z"
          stroke={color || "#3B3D3B"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default ShieldTickIcon;
