import UserCircleIcon from "components/Vectors/user-circle";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";
import BriefcaseIcon from "components/Vectors/briefcase";
import PayoutRequest from "./components/PayoutRequest";
import ActiveEngagements from "./components/ActiveEngagements";
import OpenTickets from "./components/OpenTickets";
import UserIcon from "components/Vectors/user";
import EngagementIcon from "components/Vectors/EngagementIcon";
import JobIcon from "components/Vectors/JobIcon";
import JobRequestsIcon from "components/Vectors/JobRequestsIcon";
import MoneyAddIcon from "components/Vectors/MoneyAddIcon";
import NewUserAccount from "./components/NewUserAccount";
import PendingVerification from "./components/PendingVerification";
// import TopServiceProviders from "./components/TopServiceProviders";
import {useState, useEffect} from "react";
import RepeatIcon from "components/Vectors/RepeatIcon";
import {
  useDashboardStatQuery,
  useGetJobsStatQuery,
} from "services/admin/adminGeneralApi";
import DashboardJobs from "./components/DashboardJobs";

function MainAdminDashboard() {
  const {data: stat, isLoading, isError} = useDashboardStatQuery();
  const [emptyState, setemptyState] = useState(true);

  useEffect(() => {
    setTimeout(() => setemptyState(false), 5000);
  }, []);

  const {data: jobsStatsData} = useGetJobsStatQuery();
  const jobStat = jobsStatsData?.data;

  return (
    <>
      <div className="rounded-2xl bg-white p-4 md:py-6">
        <h3 className="mb-4 font-Bai text-2xl font-medium text-black">
          Overview
        </h3>
        <div className="boxes md:grid-cols-f3 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
          <DashboardBox
            text="Total Users"
            icon={<UserIcon stroke={"#E98100"} />}
            isLoading={isLoading}
            isError={isError}
            value={String(stat?.data?.users?.total)}
          />
          <DashboardBox
            text={"Service Providers"}
            icon={<UserCircleIcon stroke={"#E98100"} />}
            isLoading={isLoading}
            isError={isError}
            value={String(stat?.data?.users?.services_providers)}
          />
          <DashboardBox
            text={"Clients"}
            isLoading={isLoading}
            isError={isError}
            icon={<UserIcon stroke={"#E98100"} />}
            value={String(stat?.data?.users?.clients)}
          />
          <DashboardBox
            text={"Hybrid"}
            icon={<EngagementIcon color={"#E98100"} />}
            isLoading={isLoading}
            isError={isError}
            value={String(stat?.data?.users?.hybrid)}
          />
          <DashboardBox
            text={"All Engagements"}
            isLoading={isLoading}
            isError={isError}
            icon={<EngagementIcon color={"#E98100"} />}
            value={String(stat?.data?.engagements?.total)}
          />
          <DashboardBox
            isLoading={isLoading}
            text={"Services"}
            isError={isError}
            icon={<BriefcaseIcon stroke={"#E98100"} />}
            value={String(stat?.data?.engagements?.services)}
          />
          <DashboardBox
            text={"Open Jobs"}
            isLoading={isLoading}
            isError={isError}
            icon={<JobIcon color={"#E98100"} />}
            value={jobStat?.active_jobs_count || "0"}
          />
          <DashboardBox
            text={"Closed Jobs"}
            isLoading={isLoading}
            isError={isError}
            icon={<JobRequestsIcon color={"#E98100"} />}
            value={jobStat?.closed_jobs_count || "0"}
          />
          <DashboardBox
            text={"All Withdrawal"}
            isLoading={isLoading}
            isError={isError}
            icon={<MoneyAddIcon />}
            value={String(stat?.data?.payouts?.total)}
          />
          <DashboardBox
            bg={"bg-[#FBE6CC]"}
            text={"Pending Refund"}
            isLoading={isLoading}
            isError={isError}
            icon={<RepeatIcon />}
            value={String(stat?.data?.refund?.pending)}
          />
          <DashboardBox
            bg={"bg-[#FBE6CC]"}
            isLoading={isLoading}
            text={"Open Tickets"}
            icon={<MoneyAddIcon />}
            value={String(stat?.data?.tickets?.new)}
          />
          <DashboardBox
            bg={"bg-[#FBE6CC]"}
            isLoading={isLoading}
            isError={isError}
            text={"Payout Requests"}
            icon={<RepeatIcon />}
            value={String(stat?.data?.payouts?.payouts)}
          />
        </div>
      </div>

      <section>
        <h3 className="text-bae mb-4 mt-6 font-Bai font-medium text-grey_10 sm:text-2xl">
          New user Accounts
        </h3>
        <div className="rounded-2xl bg-white p-5">
          <NewUserAccount />
        </div>
      </section>

      <div className="mt-8 grid grid-cols-1 gap-5 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
        <div className="flex-1">
          <PayoutRequest data={emptyState ? false : true} />
        </div>
        <div className=" flex-1">
          <PendingVerification />
        </div>
        <div className="New_Tickets flex-1">
          <OpenTickets />
        </div>

        <div className="flex-1">
          <ActiveEngagements />
        </div>
        <div className="flex-1">
          <DashboardJobs status="open" />
        </div>
        <div className="New_Tickets flex-1">
          <DashboardJobs status="closed" />
        </div>
      </div>
    </>
  );
}

export default MainAdminDashboard;
