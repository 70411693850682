import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@mui/material";
import {CustomTabStyle2} from "App";
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import EngagementTab from "pages/Clients/Engagements/EachEngagements/EngagementTab";

const EngagementDetails = ({type}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeTab, setactiveTab] = useState("ongoing");
  const tab = searchParams.get("tab");
  useEffect(() => {
    if (tab) setactiveTab(tab);
  }, [tab]);

  return (
    <div>
      <TabContext value={activeTab}>
        <div className="mb-5 flex items-center justify-between md:mb-10">
          <TabList
            variant="scrollable"
            value={activeTab}
            indicator="none"
            onChange={(_, newValue) => {
              navigate(`?tab=${newValue}`);
              setactiveTab(newValue);
            }}
            sx={{
              fontWeight: 500,
              color: "#6E726E",
              backgroundColor: "#F9FAFB !important",
              borderWidth: "1px",
              borderRadius: "16px",
              width: "fit-content",
              padding: "8px",
              ...CustomTabStyle2,
              textTransform: "unset",
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
            aria-label="basic tabs example"
          >
            <Tab
              label="Ongoing Engagements"
              value="ongoing"
              className={
                type === "client" ? "client_engagement_first_step" : "sp_engagement_first_step"
              }
            />
            <Tab
              label="Completed Engagements"
              value="completed"
              className={
                type === "client" ? "client_engagement_second_step" : "sp_engagement_second_step"
              }
            />
            <Tab
              label={"Cancelled Engagements"}
              value="cancelled"
              className={
                type === "client" ? "client_engagement_third_step" : "sp_engagement_third_step"
              }
            />
            <Tab
              label={"Terminated Engagements"}
              value="terminated"
              className={
                type === "client" ? "client_engagement_fourth_step" : "sp_engagement_fourth_step"
              }
            />
          </TabList>
        </div>
        <TabPanel value={"ongoing"}>
          <EngagementTab type={type} status="Ongoing" />
        </TabPanel>
        <TabPanel value={"completed"}>
          <EngagementTab type={type} status="Completed" />
        </TabPanel>
        <TabPanel value={"cancelled"}>
          <EngagementTab type={type} status="Cancelled" />
        </TabPanel>
        <TabPanel value={"terminated"}>
          <EngagementTab type={type} status="Terminated" />
        </TabPanel>
      </TabContext>
    </div>
  );
};
export default EngagementDetails;
