import {Button, Chip} from "@mui/material";
import {toCurrency} from "utils";

const PackageCard = ({item, onManage}) => {
  return (
    <div className="w-full rounded-16 bg-[#F9FAFB] p-[24px]">
      <div className="mb-[40px] flex items-center justify-between">
        <h3 className="text-prop font-Bai text-[24px] font-semibold text-grey_20 ">
          {item?.name}
        </h3>
        <Chip
          color={
            item?.status?.toLowerCase() === "active"
              ? "secondaryInverse"
              : "primaryInverse"
          }
          sx={{borderRadius: "8px !important"}}
          label={item?.status}
        />
      </div>
      <div className="flex flex-col gap-3 ">
        <h4 className="font-Bai text-[20px] font-semibold text-grey_20">
          {toCurrency(item?.amount)}/
          <span className="inline !text-base leading-[19px] text-grey_30">
            {item?.tokens} Tokens
          </span>
        </h4>
        <h5 className="text-prop font-medium text-[base] text-black">
          {100} <span className="text-grey_30 ">All Time Subscribers</span>
        </h5>
      </div>
      <div className="mt-6">
        <Button
          sx={{
            padding: "12px 18px !important",
          }}
          onClick={onManage}
          variant="contained"
        >
          Manage
        </Button>
      </div>
    </div>
  );
};
export default PackageCard;
