import {createSelector, createSlice} from "@reduxjs/toolkit";

const apiSlice = createSlice({
  name: "apiQuery",
  initialState: {
    tickets: [],
    totalTicketsCount: 0,
  },
  reducers: {
    setTickets: (state, action) => {
      action.payload.forEach(ticket => {
        if (!state.tickets.find(n => n.id === ticket.id)) {
          state.tickets.push(ticket);
        }
      });
    },
    setTotalTicketsCount: (state, action) => {
      state.totalTicketsCount = action.payload;
    },
  },
});

const selectTickets = state => state.apiQuery.tickets;

export const selectAllTickets = createSelector(
  selectTickets,
  tickets => tickets,
);
export const selectTotalTicketsCount = createSelector(
  state => state.apiQuery.totalTicketsCount,
  totalTicketsCount => totalTicketsCount,
);
export const {setTickets, setTotalTicketsCount} = apiSlice.actions;

export default apiSlice.reducer;
