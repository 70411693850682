// InfluencerMenuLinks
// import HomeIcon from "components/Vectors/TicketIcon";
import HomeIcon from "components/Vectors/home-icon";
import CreditCardIcon from "components/Vectors/credit-card";
import TicketIcon from "components/Vectors/ticket";
import StarIcon from "components/Vectors/star";
import MessageIcon from "components/Vectors/messageIcon";

const ProfessionalMenuLinks = [
  {
    icon: HomeIcon,
    name: "Dashboard",
    link: "",
  },
  {
    icon: CreditCardIcon,
    name: "Transactions",
    link: "transactions",
    border: true,
  },
  {
    icon: TicketIcon,
    name: "Tickets",
    link: "tickets",
    border: true,
  },
  {
    icon: MessageIcon,
    name: "Messages",
    link: "chats",
    border: true,
  },
  {
    icon: StarIcon,
    name: "Reviews",
    link: "reviews",
    border: true,
  },
];

export default ProfessionalMenuLinks;
