import {useState} from "react";
import AutoCompleteField from "components/forms/AutoCompleteField";
import ValidatedInput from "components/forms/ValidatedInput";
import MyModal from "components/mui-components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import {useGetAllBanksQuery} from "services/generalApi";
import {useRequestWithdrawalMutation} from "services/user/providerGeneralApi";
import AccountDoesNotExistModal from "./AccountDoesNotExistModal";
import {LoadingButton} from "@mui/lab";
import {handleError} from "utils";
import {showToast} from "redux/store.hooks";
import {useGetUser} from "hooks/getUserHook";
import {useNavigate} from "react-router-dom";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";

const RequestWithdrawalModal = ({openModal, closeModal}) => {
  const navigate = useNavigate();
  const {data: allBanks} = useGetAllBanksQuery();
  const [request, {isLoading}] = useRequestWithdrawalMutation();
  const {user} = useGetUser();
  const bankName = allBanks?.data?.find(
    bank => bank.code === user?.bank_account?.bank_code,
  )?.name;
  const [showModal, setShowModal] = useState(false);
  const method = useForm();
  const handleSubmit = data => {
    request(data)
      .unwrap()
      .then(res => {
        showToast(res?.message);
        method.reset();
        navigate("?tab=withdrawal");
      })
      .catch(err => {
        handleError(err);
      });
  };
  return (
    <MyModal
      title="Request Withdrawal"
      openModal={openModal}
      maxWidth={500}
      closeModal={closeModal}
    >
      <FormProvider {...method}>
        <form onSubmit={method.handleSubmit(handleSubmit)}>
          <div className="mt-2 flex flex-col items-center justify-center gap-y-2 md:mt-6">
            <p className="text-center font-Bai text-xl font-semibold capitalize text-grey_30">
              Enter Amount
            </p>
            <ValidatedInput name="amount" spaceY={false} />
          </div>
          <div className="my-3 space-y-3 md:my-8">
            <AutoCompleteField
              label="Account"
              name="bank_account_id"
              selectOption={[
                {
                  label: `${user?.bank_account?.account_number} - ${bankName}`,
                  value: user?.bank_account?.id,
                },
              ]}
            />
            <ValidatedTextArea2
              name="description"
              label="Description"
              placeholder="Description"
              bg="grey_80"
            />
          </div>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading}
            fullWidth
          >
            Request Withdrawal
          </LoadingButton>
        </form>
      </FormProvider>
      <AccountDoesNotExistModal
        open={showModal}
        close={() => setShowModal(false)}
      />
    </MyModal>
  );
};

export default RequestWithdrawalModal;
