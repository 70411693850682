import {useLocation, useParams} from "react-router-dom";
import {CategoriesSlider} from "pages/LandingPage/widgets/components/FirstSlider";
import {Avatar} from "@mui/material";
import UserIcon from "components/Vectors/user";
import BackBtn from "components/BackBtn";
import {
  useGetAllServiceCategoriesQuery,
  useGetProvidersByCategoriesQuery,
} from "services/generalApi";
import RangeDropdown from "./widgets/RangeDropdown";
import RatingDropdown from "./widgets/RatingDropdown";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";
import EmptyResponse from "components/mui-components/EmptyResponse";
import UserIcon03 from "components/Vectors/UserIcon03";
import StateCity from "pages/LandingPage/widgets/Dropdowns/StateCity";
import {useState} from "react";
import FilterDropDown from "components/mui-components/DropDownWrapper/FilterDropDown";
import {removeEmpty} from "utils";
import ServiceProviderCard from "components/ServiceProviderCard";

function EachServices() {
  const {state} = useLocation();
  const category = state?.category || "Fashion And Beauty";
  const {data: allCategories = []} = useGetAllServiceCategoriesQuery();
  const {id} = useParams();
  const [choice, setChoice] = useState({
    state: "",
    expertise: "",
    price_range: [],
  });

  const params = {
    state: choice?.state,
    expertise: choice?.expertise,
    budget_minimum: choice?.expertise ? choice?.price_range[0] : "",
    budget_maximum: choice?.expertise ? choice?.price_range[1] : "",
  };
  const {data, isFetching, error} = useGetProvidersByCategoriesQuery({
    id,
    params: removeEmpty(params),
  });
  const expertises =
    state?.expertises ||
    allCategories?.data
      ?.find(el => String(el.id) === String(id))
      ?.expertises?.map(expertise => expertise.name);

  return (
    <>
      <BackBtn text="Services" />
      <div className="relative mt-4 h-[269px] rounded-xl">
        <Avatar
          className="!h-full !w-full !rounded-xl"
          src={state?.cover_photo || "/images/fashion.png"}
          alt={`${category}`}
        />
        <div
          style={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
          }}
          className="absolute inset-0 flex items-center justify-center rounded-xl text-white"
        >
          <div className="space-y-4 text-center">
            <h1 className="relative font-Bai text-5xl font-bold">{category}</h1>
            <p className="flex justify-center gap-2">
              <UserIcon stroke="#fff" />
              <span className="capitalize">
                {data?.data?.length}{" "}
                {data?.data?.length === 1
                  ? "service provider"
                  : "service providers"}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 flex w-full flex-col p-2 md:flex-row md:gap-10 md:p-0">
        <div className="w-full rounded-24 bg-white p-4 md:p-6">
          <h1 className="font-Bai text-2xl font-semibold text-grey_20">
            {category}
          </h1>

          <div className="my-8 flex flex-wrap items-center gap-2">
            <FilterDropDown
              name="expertise"
              choice={choice}
              isSearch
              setChoice={setChoice}
              selectOption={
                expertises
                  ? [{label: "All Expertise", value: ""}, ...expertises]
                  : [{label: "All Expertise", value: ""}]
              }
            />
            {/* Range dropdown  */}
            {choice?.expertise && (
              <RangeDropdown name={"price_range"} setParams={setChoice} />
            )}
            {/* Location dropdown  */}
            <StateCity choice={choice} setChoice={setChoice} />
            {/* rating dropdown  */}
            <RatingDropdown />
          </div>
          {isFetching ? (
            <CircularLoader />
          ) : error ? (
            <ErrorMsg error={error} />
          ) : (
            <>
              {data?.data?.length <= 0 && (
                <div>
                  <EmptyResponse
                    icon={<UserIcon03 color="#3B3D3B" />}
                    message={"No Service providers found for this category"}
                  />
                </div>
              )}
              <div className="grid grid-cols-1 gap-5  md:grid-cols-3">
                {data?.data?.map((ServiceProvider, i) => (
                  <ServiceProviderCard key={i + 1} data={ServiceProvider} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="inner mx-auto mt-16 w-full max-w-[1300px]">
        <p className="pb-12 font-Bai text-3xl font-semibold text-grey_20">
          Other categories
        </p>
        <CategoriesSlider
          link="/services/1"
          desktopSlides={4}
          className="w-full md:w-[90%]"
          categories={allCategories?.data?.filter(
            category => category?.name !== state?.category,
          )}
        />
      </div>
    </>
  );
}

export default EachServices;
