
import MyModal from "components/mui-components/Modal"
import {FormProvider, useForm} from "react-hook-form";
import TagsInput from "../components/TagsInput";
import {useAddBannedWordMutation} from "services/admin/adminGeneralApi";
import {handleError} from "utils";
import {LoadingButton} from "@mui/lab";
import {showToast} from "redux/store.hooks";
import { CircularProgress } from "@mui/material";

const AddWordModal = ({open, close}) => {
  const [addWord, {isLoading}] = useAddBannedWordMutation();

  const methods = useForm();
  const onSubmit = async body => {


    const data = {banned_words: body.tags};



    try {
      let res = await addWord(data).unwrap();
      showToast(res?.message, "success");
      close();
      methods.reset();
    } catch (error) {
      handleError(error);
      close();
    }
  };
  return (
    <MyModal openModal={open} closeModal={close} title="Add Words">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex h-[280px] flex-col justify-between "
        >
          <div className="">
            <label className="mb-[12px] text-[14px] font-normal text-[#3B3D3B] ">
              Separate words by comma ‘,’
            </label>
            <TagsInput name="tags" required={true} />
          </div>

          <LoadingButton
            sx={{mt: 4}}
            loadingIndicator={
              <CircularProgress
                sx={{
                  color: "#fff",
                }}
                size="1.2rem"
              />
            }
            type="submit"
            loading={isLoading}
            fullWidth
            variant="contained"
            color="primary"
          >
            Submit
          </LoadingButton>
        </form>
      </FormProvider>
    </MyModal>
  );
};

export default AddWordModal;
