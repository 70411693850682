import "./myStyles.scss";
import MobileApp from "./widgets/components/MobileApp";
import Header from "./widgets/components/Header";
import FirstSlider from "./widgets/components/FirstSlider";
import SecondSlider from "./widgets/components/SecondSlider";
import {
  useGetAllJobsQuery,
  useGetAllServiceCategoriesQuery,
  useGetHomepageBannersQuery,
} from "services/generalApi";
import Loader from "components/Loader/Loader";
import Title from "components/Title";

const LandingPage = () => {
  const { data: categories, isLoading } = useGetAllServiceCategoriesQuery();
  const { data: allJobs, isLoading: isJobsLoading } = useGetAllJobsQuery({
    is_paginated: "yes",
  });

  const featuredCategories = categories?.data?.filter(category => category?.is_featured === 'Yes')

  const { data: banners, isLoading: isBannerLoading } =
    useGetHomepageBannersQuery();

  if (isLoading || isJobsLoading || isBannerLoading)
    return <Loader className="fixed inset-0 z-[100]" />;

  return (
    <>
      <Title titleProp="Truelocal | Hire The Best Hands for Your Tasks on Agreed Terms" />
      <Header banners={banners?.data} />
      <FirstSlider categories={featuredCategories || []} />
      <SecondSlider allJobs={allJobs?.data?.data || []} />
      <MobileApp />
    </>
  );
};

export default LandingPage;
