import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import {useGetPayoutListQuery} from "services/admin/adminGeneralApi";
import {toCurrency} from "utils";
import moment from "moment";
import {useGetAllBanksQuery} from "services/generalApi";

const PaymentHistoryTab = () => {
  const {data, isLoading, error, isError} = useGetPayoutListQuery();
  const {data: allBanks} = useGetAllBanksQuery();

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
  };

  const mockData = data?.data
    ?.filter(history => history?.paid_date_time)
    ?.map(payout => ({
      service_provider: {
        name: payout?.service_provider?.professional_information?.business_name,
        avatar:
          payout?.service_provider?.professional_information?.business_logo,
      },
      amount: toCurrency(payout?.amount),
      request_date: moment(payout?.request_date_time).format(
        "DD MMMM, YYYY hh:mma",
      ),
      date_paid: moment(payout?.paid_date_time).format("DD MMMM, YYYY hh:mma"),
      account: `${payout?.bank_account?.account_number} - ${
        allBanks?.data?.find(
          bank => bank.code === payout?.bank_account?.bank_code,
        )?.name
      }`,
      transaction_ref: payout?.transaction_ref,
    }));

  return (
    <MyDataTable
      scrollX={false}
      data={mockData}
      headCells={headCells}
      options={options}
      isLoading={isLoading}
      error={error}
      isError={isError}
    />
  );
};

export default PaymentHistoryTab;

const headCells = [
  {
    name: "service_provider",
    label: "service provider",
    options: {
      customBodyRender: value => (
        <UserDisplay name={value.name} img={value.avatar} size="text-[1rem]" />
      ),
      truncate: false,
    },
  },
  {
    name: "amount",
    label: "amount",
  },
  {
    name: "request_date",
    label: "request date",
  },
  {
    name: "date_paid",
    label: "date paid",
  },
  {
    name: "account",
    label: "account",
  },
  {
    name: "transaction_ref",
    label: "transaction ref",
  },
];
