import {Button, Chip} from "@mui/material";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import MoneyAddIcon from "components/Vectors/MoneyAddIcon";
import {toCurrency} from "utils";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useState} from "react";
import DetailsModal from "../Modals/DetailsModal";
import {useGetClientTransactionsQuery} from "services/user/clientGeneralApi";

export const TChipStatus = {
  completed: "success",
  escrow: "warning",
  refunded: "secondary",
};

const TransactionsTab = ({type}) => {
  const {data, isLoading, isError, error} = useGetClientTransactionsQuery();

  const [openDetailsModal, setopenDetailsModal] = useState(false);
  const [selectedDetails, setselectedDetails] = useState(null);

  const headCells = [
    {
      name: "engagement",
      label: "Engagement",
      options: {
        customBodyRender: value => (
          <UserDisplay
            imgRounded={false}
            name={value.name}
            img={value.avatar}
          />
        ),
      },
    },
    {
      name: "service_provider",
      label: "Service Provider",
      options: {
        customBodyRender: value => (
          <UserDisplay
            badge={value?.badge}
            name={value.name}
            img={value.avatar}
          />
        ),
      },
    },
    {
      name: "transactionId",
      label: "Transaction ID",
    },
    {
      name: "amount",
      label: "Amount",
    },
    {
      name: "vat",
      label: "vat",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: value => (
          <Chip label={value} color={TChipStatus[value.toLowerCase()]} />
        ),
      },
    },
    {
      name: "option",
      label: "Option",
    },
  ];

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
  };

  // const navigate = useNavigate();

  return (
    <div>
      <div className=" w-full ">
        <MyDataTable
          scrollX={false}
          isLoading={isLoading}
          isError={isError}
          error={error}
          emptyData={{
            icon: MoneyAddIcon,
            message: "No Transactions",
            extraText:
              "transactions transactions will be updated on this page.",
          }}
          data={data?.data?.map(el => ({
            engagement: {
              name: el?.engagement?.job_detail?.job_title,
              avatar:
                el?.engagement?.job_detail?.images?.length > 0
                  ? el?.engagement?.job_detail?.images[0]?.image_url
                  : "/images/broken-image.png",
            },
            service_provider: {
              name: el?.service_provider?.full_name,
              avatar: el?.service_provider?.avatar,
              badge: el?.service_provider?.is_verified_account,
            },
            transactionId: `#${el?.transaction_ref}`,
            amount: toCurrency(el?.amount),
            vat: toCurrency(el?.vat || 0),
            status: el?.status,
            option: (
              <>
                <Button
                  onClick={() => {
                    setopenDetailsModal(true);
                    setselectedDetails(el);
                  }}
                  sx={{p: "0.5rem !important"}}
                  endIcon={<ArrowForwardIcon />}
                >
                  VIew Details
                </Button>
              </>
            ),
            id: `row_${el.id}`,
          }))}
          headCells={headCells}
          options={options}
          hideChipDot
        />
      </div>

      <DetailsModal
        type="Completed"
        data={selectedDetails}
        openModal={openDetailsModal}
        setopenModal={setopenDetailsModal}
      />
    </div>
  );
};

export default TransactionsTab;
