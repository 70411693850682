import SingleNotification from "components/SingleNotification";
import {
  useGetAllUserNotificationsQuery,
  useMarkMultipleAsReadMutation,
} from "services/user/userRegApi";
import {useEffect, useRef, useState} from "react";
import Pusher from "pusher-js";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";

const ClientNotification = ({isProfessional}) => {
  const containerRef = useRef(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const {data, isLoading, error, isError, refetch} =
    useGetAllUserNotificationsQuery({
      type: isProfessional ? "service-provider" : "client",
      params: {
        is_paginated: "yes",
        per_page: perPage,
        page,
      },
    });
    if(data) console.log(data)

  const [markAsRead] = useMarkMultipleAsReadMutation();
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("user-in-app-notification-received");
    channel.bind("user-in-app-notification-event", () => {
      refetch();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [refetch]);

  useEffect(() => {
    markAsRead().unwrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <div
        ref={containerRef}
        className="overflow-x-hidden rounded-2xl bg-white p-4 md:p-10"
      >
        <h1 className="my-4 font-Bai text-2xl font-semibold text-grey_20 md:text-[32px]">
          Notifications
        </h1>
        <div className="relative -left-10 mt-5 mb-7 h-px w-[120%] bg-[#EEEEEE]"></div>
        <MyDataTable
          isLoading={isLoading}
          error={error}
          isError={isError}
          emptyData={data?.total === 0}
          scrollX={false}
          hideChipDot={true}
          options={{
            viewColumns: false,
            filter: false,
            print: false,
            count: data?.total,
            rowsPerPage: perPage,
            serverSide: true,
            onTableChange: (action, tableState) => {
              switch (action) {
                case "changePage":
                  setPage(tableState.page + 1);
                  containerRef.current.scrollIntoView({
                    behavior: "smooth",
                  });
                  break;
                case "changeRowsPerPage":
                  setPerPage(tableState.rowsPerPage);
                  containerRef.current.scrollIntoView({
                    behavior: "smooth",
                  });
                  break;
                case "propsUpdate":
                  containerRef.current.scrollIntoView({
                    behavior: "smooth",
                  });
                  break;
                default:
                  break;
              }
            },
          }}
          headCells={[
            {
              name: "notifications",
              label: "",
              options: {
                customBodyRender: notification => {
                  return <SingleNotification detail={notification} />;
                },
              },
            },
          ]}
          data={data?.data?.data?.map(notification => ({
            notifications: notification,
          }))}
        />
      </div>
    </section>
  );
};

export default ClientNotification;
