import {useState} from "react";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import LoadingBtn from "components/mui-components/LoadingButton";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "components/Vectors/SendIcon";
import {FormProvider, useForm} from "react-hook-form";
import {
  useGetEngagementChatsQuery,
  useSendEngagementMsgMutation,
} from "services/generalApi";
import {handleError} from "utils";
import {useEffect, useRef} from "react";
import CircularLoader from "components/Loader/CircularLoader";
import moment from "moment";
import UserAvatar from "components/mui-components/TableComponent/UserAvatar";
import DropDownWrapper from "components/mui-components/DropDownWrapper";
import EmptyResponse from "components/mui-components/EmptyResponse";
import MessageIcon from "components/Vectors/messageIcon";
import {useGetUser} from "hooks/getUserHook";
// import pusher from "hooks/pusher";
import Pusher from "pusher-js";
import {BootstrapTooltip} from "components/TicketCards";
import TerminateEngagementModal from "./Modal/TerminateEngagementModal";

export const ConversationList = ({
  chats = [],
  showUserType = false,
  height = "40vh",
  provider_id,
  isAdmin,
  isOffer,
}) => {
  const {user} = useGetUser();
  // scroll to bottom when new messages enter
  const messagesEndRef = useRef();
  const chatsRef = useRef();
  useEffect(() => {
    if (chatsRef.current && messagesEndRef.current && chats)
      setTimeout(() => {
        chatsRef.current.scroll({
          top: messagesEndRef.current.offsetTop,
          behavior: "smooth",
        });
      }, 1000);
  }, [chats]);

  return (
    <div
      style={{maxHeight: height}}
      className={`scrollbar-style space-y-4 overflow-y-auto`}
      ref={chatsRef}
    >
      {chats?.map((value, index) => {
        let {sender} = value;
        return (
          <div className="flex gap-2.5" key={index}>
            <div className="relative">
              <UserAvatar
                size={40}
                showBadge={
                  sender?.id === provider_id &&
                  sender?.user_type?.toLowerCase() === "service provider" &&
                  sender?.is_verified_account
                }
                src={
                  sender?.user_type.toLowerCase() === "client"
                    ? sender?.avatar
                    : sender?.professional_information?.business_logo ||
                      sender?.avatar
                }
              />
              {user?.id !== sender?.id && !isAdmin && (
                <BootstrapTooltip
                  title={Boolean(sender?.is_online) ? "Online" : "Offline"}
                  placement="top"
                >
                  <span
                    className={`absolute right-0 top-5 h-3 w-3 rounded-full border-2 border-grey_80 ${
                      Boolean(sender?.is_online) ? "bg-green-600" : "bg-red-600"
                    }`}
                  ></span>
                </BootstrapTooltip>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex items-start gap-3">
                <div className="flex flex-col justify-start">
                  <h3 className="text-prop -mt-1 text-base font-bold text-grey_20">
                    {sender?.user_type.toLowerCase() === "client"
                      ? sender?.full_name
                      : sender?.professional_information?.business_name ||
                        sender?.full_name}{" "}
                    {user?.id === sender?.id && "(You)"}
                  </h3>
                  {showUserType && (
                    <span className="text-[13px] text-grey_30">
                      {sender?.user_type}
                    </span>
                  )}
                </div>
                <span className="text-xs font-[400] text-grey_30">
                  {moment(value?.created_at)?.fromNow()}
                </span>
              </div>
              <p className="text-prop text-sm font-medium text-grey_20">
                {value.message}
              </p>
            </div>
          </div>
        );
      })}
      <div ref={messagesEndRef}></div>
    </div>
  );
};

const MessageChat = ({
  isAdmin,
  status,
  isPaymentPending,
  receiver_id,
  module_id,
}) => {
  const methods = useForm();

  const [sendMsg, {isLoading}] = useSendEngagementMsgMutation();
  const [openTerminateJobModal, setOpenTerminateJobModal] = useState(false);
  const {
    data: allChats,
    isLoading: isChatsLoading,
    refetch,
  } = useGetEngagementChatsQuery(module_id);

  const onSubmit = async body => {
    try {
      await sendMsg({
        module: "Engagement",
        module_id,
        receiver_id,
        message: body?.message,
      }).unwrap();
      methods.reset();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("engagement-conversation-created");
    channel.bind("engagement-conversations", data => {
      refetch();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [refetch]);

  if (isChatsLoading)
    return (
      <div className={`rounded-24 bg-white ${isAdmin ? "pb-6" : ""}`}>
        <CircularLoader />
      </div>
    );
  return (
    <div className={`rounded-24 bg-[#ECECEE] ${isAdmin ? "pb-6" : ""}`}>
      <div className="flex items-center justify-between rounded-se-24 bg-white p-6">
        <div className="flex items-center gap-x-2">
          <Avatar src="" alt="" sx={{width: 48, height: 48}} />
          <p className="font-Mulish text-sm font-medium text-[#3B3D3B]">
            Mirian Achonwa
          </p>
          <div className="flex items-center gap-x-1 rounded-[200px] border border-[#039855] px-1.5 py-0.5 text-xs font-semibold text-[#151519]">
            Online{" "}
            <span className="h-1.5 w-1.5 rounded-full bg-[#039855]"></span>
          </div>
        </div>
        <DropDownWrapper action={<MoreVertIcon />} position="right">
          <button className="mb-4 flex items-center gap-2.5 p-2 font-Mulish text-sm font-medium text-[#151519]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 8.5C12 7.94772 11.5523 7.5 11 7.5C10.4477 7.5 10 7.94772 10 8.5V13.5C10 14.0523 10.4477 14.5 11 14.5H14C14.5523 14.5 15 14.0523 15 13.5C15 12.9477 14.5523 12.5 14 12.5H12V8.5Z"
                fill="#151519"
              />
            </svg>{" "}
            Activity Log
          </button>
          <button className="mb-4 flex items-center gap-2.5 p-2 font-Mulish text-sm font-medium text-[#151519]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                fill="#151519"
              />
              <path
                d="M5.30565 18C3.64905 20.6701 9.13273 22 12 22C14.8673 22 20.3509 20.6701 18.6943 18C17.4486 15.9921 14.9288 14 12 14C9.07123 14 6.55143 15.9921 5.30565 18Z"
                fill="#151519"
              />
            </svg>
            View Profile
          </button>
          <button className="mb-4 flex items-center gap-2.5 p-2 font-Mulish text-sm font-medium text-[#151519]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 20H6C4.34315 20 3 18.6569 3 17V6.56692C3 5.14925 4.14925 4 5.56692 4H8.11436C9.09281 4 10.785 6.18446 11.5191 6.8314C11.6586 6.95435 12.0987 7.2 12.2847 7.2H18.0014C19.6582 7.2 21 8.54315 21 10.2V17C21 18.6569 19.6569 20 18 20Z"
                fill="#151519"
              />
            </svg>
            Open Ticket
          </button>
          <button
            className="flex items-center gap-2.5 p-2 font-Mulish text-sm font-medium text-[#151519]"
            onClick={() => setOpenTerminateJobModal(true)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H9Z"
                fill="#D92D20"
              />
            </svg>
            Terminate Job
          </button>
        </DropDownWrapper>
      </div>
      <div className="p-2 md:p-0">
        <div className="flex flex-col gap-[14px] rounded-20 pb-6 pt-4 md:gap-y-6 md:p-6">
          {allChats?.conversations?.length <= 0 && (
            <EmptyResponse
              message={"No conversations yet"}
              icon={<MessageIcon />}
            />
          )}
          <ConversationList
            height="60vh"
            provider_id={allChats?.service_provider_id}
            chats={allChats?.conversations}
            showUserType
            isAdmin={isAdmin}
          />
        </div>
      </div>
      {!isPaymentPending && !isAdmin && status !== "Completed" && (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex items-center gap-2.5 pb-4 md:px-6"
          >
            <ValidatedInput2
              name="message"
              bordered={false}
              showErrMsg={false}
              placeholder="Type a message"
            />
            <LoadingBtn loading={isLoading} type="submit">
              <SendIcon height="16.5" width="16.5" color="#fff" />
            </LoadingBtn>
          </form>
        </FormProvider>
      )}
      <TerminateEngagementModal
        openModal={openTerminateJobModal}
        closeModal={() => setOpenTerminateJobModal(false)}
      />
    </div>
  );
};
export default MessageChat;
