import { Button } from "@mui/material";
import "./style.scss";
import UserCircleIcon from "components/Vectors/user-circle";
import { useLocation, useNavigate } from "react-router-dom";
import CreditCardIcon from "components/Vectors/credit-card";
import TicketsIcon from "components/Vectors/TicketIcon";
import SettingsIcon from "components/Vectors/SettingsIcon";
import { useGetUser } from "hooks/getUserHook";
import { logOutHandler } from "redux/store.hooks";
import LogOutModal from "templates/DashboardLayout/widgets/LogOutModal";
import { useState } from "react";
import LogoutIcon from "components/Vectors/logout-icon";
import SwitchAccountModal from "./SwitchAccountModal";
import { useSwitchAccountMutation } from "services/user/userRegApi";
import { handleError } from "utils";
import { LoadingButton } from "@mui/lab";
import DrawerWrapper from "./DrawerWrapper";
import { useGetUserRequest } from "hooks/useGetUserRequest";
import UserAvatar from "../TableComponent/UserAvatar";
import HomeIcon from "components/Vectors/home-icon";

const SidebarModal = ({ closeModal, openModal = true }) => {
  const navigate = useNavigate();
  const { user } = useGetUser();
  const [switchAccount, { isLoading: isSwitchingAccount }] =
    useSwitchAccountMutation();
  const location = useLocation();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [switchAcct, setSwitchAcct] = useState(false);
  const { refetch } = useGetUserRequest(!user?.user_type);
  const handleSwitchClick = async () => {
    try {
      await switchAccount().unwrap();
      closeModal();
      // dispatch(setUserDetails(res?.data));
      refetch();
      navigate(
        location.pathname.includes("service-provider")
          ? "/client"
          : "/service-provider",
        { state: "upgrading" },
      );
    } catch (error) {
      handleError(error);
    }
  };
  // console.log(user)
  return (
    <>
      <DrawerWrapper open={openModal} closeDrawer={closeModal}>
        <div className="flex flex-col items-center">
          <UserAvatar
            src={
              user?.user_type?.toLowerCase() === "service provider"
                ? user?.professional_information?.business_logo || user?.avatar
                : user?.avatar || ""
            }
            size={100}
            showBadge={
              user?.user_type?.toLowerCase() === "service provider" &&
              user?.is_verified_account
            }
          />

          {/* name  */}
          <h2 className="mt-[11px] font-Bai text-[24px] font-medium text-[#000]">
            {user?.user_type === "Client"
              ? user?.full_name || `${user?.first_name} ${user?.last_name}`
              : user?.professional_information?.business_name ||
              user?.full_name}
          </h2>

          {/* username */}
          {user?.user_type && (
            <h3 className="mt-[1px] font-Bai text-base font-normal text-grey_20">
              @{user?.username}
            </h3>
          )}

          {/* info and btn  */}
          <div className="mt-[11px] flex w-full flex-col items-center gap-[13px] rounded-16 bg-[#F2F4F7] px-[25px] py-4">
            <div className="flex flex-wrap items-center justify-center gap-2">
              <div className="rounded-[12px] bg-white px-3 py-2 text-[14px] capitalize text-[#000]">
                {user?.user_type || "Admin"}
              </div>
            </div>


            {user?.kyc &&
              user?.kyc?.status === "Approved" &&
              user?.is_verified_account && (
                <Button
                  fullWidth
                  variant="contained"
                  color='secondaryInverse2'
                  onClick={() => {
                    closeModal();
                    if (user?.user_type) {
                      if (user?.is_hybrid !== "Yes") {
                        closeModal();
                        setSwitchAcct(true);
                      } else {
                        handleSwitchClick();
                      }
                    } else if (user?.admin_permissions) {
                      navigate("/admin");
                    }
                  }}
                  sx={{ display: user?.is_hybrid === "Yes" ? "none" : "" }}
                >
                  {user?.user_type
                    ? `Become a ${user?.user_type === "Service provider"
                      ? "Client"
                      : "Service Provider"
                    }`
                    : "Go to Dashboard"}
                </Button>
              )}

            <LoadingButton
              loading={isSwitchingAccount}
              fullWidth
              variant="contained"
              onClick={() => {
                if (user?.is_hybrid === "Yes") {
                  handleSwitchClick();
                } else {
                  closeModal();
                  setSwitchAcct(true);
                }
              }}
              sx={{
                display: user?.is_hybrid === "Yes" ? "" : "none",
                bgcolor: user?.is_hybrid === "Yes" ? "" : "purple",
                '&:hover': {
                  bgcolor: user?.is_hybrid === "Yes" ? "" : "purple",
                  opacity: user?.is_hybrid === "Yes" ? "" : "0.8",
                }
              }}
            >
              {user?.user_type
                ? `${user?.is_hybrid === "Yes" ? "Switch to " : "Become a "}${user?.user_type === "Service provider"
                  ? "Client"
                  : "Service Provider"
                }`
                : "Go to Dashboard"}
            </LoadingButton>
          </div>

          {/* actions  */}
          {user?.user_type && (
            <div className="mt-[26px] flex w-full flex-col gap-2">
              {routeLinks.map((val, index) => (
                <button
                  onClick={() => {
                    closeModal();
                    navigate(
                      `${user?.user_type === "Client"
                        ? "/client/"
                        : "/service-provider/"
                      }${val.link}`,
                    );
                  }}
                  className="w-full rounded-[12px] bg-[#F2F4F7] px-[26px] py-4 transition-all hover:bg-[#d1d1d1]"
                  key={index}
                >
                  <div className="flex items-center gap-[14px]">
                    {val.icon}
                    <span className="text-sm font-semibold text-grey_10">
                      {val.title}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          )}

          <button
            onClick={() => {
              closeModal();
              setShowLogoutModal(true);
            }}
            className="mt-[10px]  flex w-full items-center gap-[14px] self-center rounded-[12px] bg-[#FFF1F0] px-6 py-4 text-[14px] font-bold text-[#D92D20]"
          >
            <LogoutIcon color={"#D92D20"} />
            Logout
          </button>
        </div>
      </DrawerWrapper>

      <LogOutModal
        open={showLogoutModal}
        close={() => setShowLogoutModal(false)}
        onLogout={() => {
          logOutHandler({
            type: user?.user_type ? "user" : "admin",
            redirect: true,
          });
        }}
      />
      <SwitchAccountModal
        open={switchAcct}
        close={() => {
          setSwitchAcct(false);
        }}
      />
    </>
  );
};

export default SidebarModal;

const routeLinks = [
  {
    icon: <HomeIcon height="20" width="20" stroke="#1D1D1D" />,
    link: "",
    title: "My Dashboard",
  },
  {
    icon: <UserCircleIcon height="20" width="20" stroke="#1D1D1D" />,
    link: "account",
    title: "My Account",
  },
  {
    icon: <CreditCardIcon height="20" width="20" stroke="#1D1D1D" />,
    link: "transactions",
    title: "Transactions",
  },
  {
    icon: <TicketsIcon height="20" width="20" stroke="#1D1D1D" />,
    link: "tickets",
    title: "Tickets",
  },
  {
    icon: <SettingsIcon height="20" width="20" stroke="#1D1D1D" />,
    link: "settings",
    title: "Settings",
  },
];
