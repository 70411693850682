import ImageIcon from "components/Vectors/ImageIcon";
import {useRef, useState} from "react";
import {CircularProgress, IconButton} from "@mui/material";
import LinkIcon from "components/Vectors/LinkIcon";
import SendIcon2 from "components/Vectors/SendIcon2";
import moment from "moment";
import {FormProvider, useForm} from "react-hook-form";
import ValidatedInput from "components/forms/ValidatedInput";
import {
  getFileNameFromUrl,
  getFileSize,
  handleError,
  truncateString,
} from "utils";
import {showToast} from "redux/store.hooks";
import FileDisplayUi from "components/FileDisplayUI";
import {LoadingButton} from "@mui/lab";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";

export const DownloadAttachment = ({url, size}) =>
  url ? (
    <div
      className={`${
        size === "small" ? "text-sm" : "text-lg"
      } flex items-center justify-between rounded-lg bg-[#FDF2E680] p-3`}
    >
      <div className="flex items-center gap-3">
        <ImageIcon />
        <p className="font-medium text-grey_20">
          {truncateString(getFileNameFromUrl(url), 40)}
        </p>
      </div>
      <IconButton
        component={"a"}
        download
        rel="noreferrer"
        target="_blank"
        href={url}
      >
        <ArrowCircleDownOutlinedIcon color="primary" />
      </IconButton>
    </div>
  ) : null;

function ProjectOverdue({detail, bg = "bg-grey_80", reply, isLoading}) {
  const [attachment, setAttachment] = useState(null);
  const methods = useForm({
    mode: "all",
  });
  const fileRef = useRef(null);
  const {id, title, status, description, created_at, ticket_tag} = detail;
  // const [reply, {isLoading: isReplyLoading}] = useReplyTicketMutation();

  const handleSubmit = async data => {
    const replyData = new FormData();
    replyData.append("ticket_id", id);
    replyData.append("response", data.response);
    if (attachment) replyData.append("attachment", attachment);
    try {
      let res = await reply({tag: ticket_tag, body: replyData}).unwrap();
      showToast(res?.message, "success");
      methods.reset();
      setAttachment(null);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <div className={`rounded-xl ${bg} p-4`}>
      <h1 className="font-Bai text-[32px] font-semibold text-grey_20">
        {title}
      </h1>
      <p className="text-base font-normal text-grey_30">
        {moment(created_at).format("DD MMM YYYY hh:mm a")}
      </p>

      <p className="my-4 text-lg font-medium text-grey_20">{description}</p>
      <DownloadAttachment url={detail?.attachment} />
      {status !== "Resolved" && (
        <div className="mt-4">
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(handleSubmit)}
              className="flex items-end gap-2"
            >
              <div
                className={`w-full rounded-lg bg-white ${
                  bg === "bg-white" ? "grey-border-1px" : ""
                }`}
              >
                {attachment && (
                  <div className="p-3">
                    <FileDisplayUi
                      name={truncateString(attachment.name, 30)}
                      isFileLarge={attachment?.size > 1000000}
                      downloadType={false}
                      onCancel={() => setAttachment(null)}
                      size={getFileSize(attachment?.size)}
                    />
                  </div>
                )}
                <ValidatedInput
                  showErrMsg={false}
                  name="response"
                  bg="white"
                  variant={false}
                  placeholder="Say something"
                  suffixIcon={
                    <IconButton onClick={() => fileRef.current.click()}>
                      <LinkIcon stroke={"#e98100"} />
                    </IconButton>
                  }
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={fileRef}
                  onChange={e => setAttachment(e.target.files[0])}
                  hidden
                />
              </div>
              <LoadingButton
                disabled={
                  (attachment && attachment?.size > 1000000) ||
                  !methods.formState.isValid
                }
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                {isLoading ? (
                  <CircularProgress
                    sx={{
                      color: "#FFF",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  <SendIcon2 />
                )}
              </LoadingButton>
            </form>
          </FormProvider>
        </div>
      )}
    </div>
  );
}

export default ProjectOverdue;
