import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import LongArrowRightIcon from "components/Vectors/LongArrowRightIcon";
import MarkerPinIcon from "components/Vectors/MarkerPinIcon";
import StarFilledIcon from "components/Vectors/StarFilledIcon";
import {Link} from "react-router-dom";

const JobReceived = ({headerText, link}) => {
  return (
    <section className="flex flex-col gap-[30px] rounded-20 bg-grey_80 p-[24px] ">
      <h2 className="font-Bai text-lg font-medium text-grey_10 ">
        {" "}
        {headerText}
      </h2>
      <div className="flex items-center justify-between">
        <UserDisplay
          // imgRounded={false}
          name="Mirian Achonwa"
          email="@Mirian"
          img="/images/notif_img.png"
          size="large"
          emailTextColor="#6E726E"
          fontSize="font-[700]"
          className=" flex-1"
        />
        <div
          className="text-prop flex items-center gap-[3px] rounded-[8px]
           bg-white p-[8px] text-base font-medium text-[#E98100] "
        >
          <StarFilledIcon color="#E98100" />
          <span>4.0</span>
        </div>
      </div>
      <div className="flex items-center justify-between border-b border-b-[#C8C8C8] pb-8 ">
        <div className="text-prop flex items-center gap-[8px] text-base font-normal text-grey_30 ">
          <MarkerPinIcon />
          <span>Physical - Ketu, Lagos</span>
        </div>
        <Link
          to={link}
          className="text-prop flex items-center gap-[8px] text-[15px] font-medium text-[#E98100]  "
        >
          <span>View Profile</span>
          <LongArrowRightIcon />
        </Link>
      </div>

      <div className="">
        <h3 className="text-prop font-Bai text-lg font-medium text-grey_30 ">
          Request Message
        </h3>

        <p className="mt-2 text-base font-medium leading-[29px] tracking-[-1%] text-grey_20 ">
          Ac duis accumsan consectetur pellentesque pellentesque enim eu duis
          eros. Egestas augue quis posuere semper. Mus consectetur magna tempus
          massa. Semper aliquet amet a pulvinar mi gravida feugiat id
          pellentesque ac ultricies vivamus tincidunt habitant.
        </p>
      </div>
    </section>
  );
};
export default JobReceived;
