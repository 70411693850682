const DeleteIcon2 = ({ stroke, ...props }) => {
    return (
        <>
            <svg width={props.width || "20"} height={props.height || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.4991 5.60742C17.4824 5.60742 17.4574 5.60742 17.4324 5.60742C13.0241 5.16575 8.62408 4.99909 4.26574 5.44075L2.56574 5.60742C2.21574 5.64075 1.90741 5.39075 1.87408 5.04075C1.84074 4.69075 2.09074 4.39075 2.43241 4.35742L4.13241 4.19075C8.56574 3.74075 13.0574 3.91575 17.5574 4.35742C17.8991 4.39075 18.1491 4.69909 18.1157 5.04075C18.0907 5.36575 17.8157 5.60742 17.4991 5.60742Z" fill={stroke || "#D61E34"} />
                <path d="M7.08265 4.76602C7.04932 4.76602 7.01598 4.76602 6.97432 4.75768C6.64098 4.69935 6.40765 4.37435 6.46598 4.04102L6.64932 2.94935C6.78265 2.14935 6.96598 1.04102 8.90765 1.04102H11.091C13.041 1.04102 13.2243 2.19102 13.3493 2.95768L13.5326 4.04102C13.591 4.38268 13.3576 4.70768 13.0243 4.75768C12.6826 4.81602 12.3576 4.58268 12.3076 4.24935L12.1243 3.16602C12.0076 2.44102 11.9826 2.29935 11.0993 2.29935H8.91598C8.03265 2.29935 8.01598 2.41602 7.89098 3.15768L7.69932 4.24102C7.64932 4.54935 7.38265 4.76602 7.08265 4.76602Z" fill={stroke || "#D61E34"} />
                <path d="M12.676 18.9577H7.32597C4.41764 18.9577 4.30097 17.3493 4.20931 16.0493L3.66764 7.65766C3.64264 7.316 3.90931 7.016 4.25097 6.991C4.60097 6.97433 4.89264 7.23266 4.91764 7.57433L5.45931 15.966C5.55097 17.2327 5.58431 17.7077 7.32597 17.7077H12.676C14.426 17.7077 14.4593 17.2327 14.5426 15.966L15.0843 7.57433C15.1093 7.23266 15.4093 6.97433 15.751 6.991C16.0926 7.016 16.3593 7.30766 16.3343 7.65766L15.7926 16.0493C15.701 17.3493 15.5843 18.9577 12.676 18.9577Z" fill={stroke || "#D61E34"} />
                <path d="M11.3824 14.375H8.60742C8.26576 14.375 7.98242 14.0917 7.98242 13.75C7.98242 13.4083 8.26576 13.125 8.60742 13.125H11.3824C11.7241 13.125 12.0074 13.4083 12.0074 13.75C12.0074 14.0917 11.7241 14.375 11.3824 14.375Z" fill={stroke || "#D61E34"} />
                <path d="M12.0827 11.041H7.91602C7.57435 11.041 7.29102 10.7577 7.29102 10.416C7.29102 10.0743 7.57435 9.79102 7.91602 9.79102H12.0827C12.4243 9.79102 12.7077 10.0743 12.7077 10.416C12.7077 10.7577 12.4243 11.041 12.0827 11.041Z" fill={stroke || "#D61E34"} />
            </svg>

        </>
    );
}

export default DeleteIcon2;