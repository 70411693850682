import { Avatar } from "@mui/material";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import { useState, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import SaveChangeBtn from "../Components/SaveChangeBtn";
import { Country } from "country-state-city";
import AutoCompleteField from "components/forms/AutoCompleteField";
import FormDatePicker from "components/forms/FormDatePicker";
import { useGetUser } from "hooks/getUserHook";
import { useEffect } from "react";
import { useUpdateBasicInfoMutation } from "services/user/userRegApi";
import { setUserDetails } from "redux/slice/AuthSlice";
import { handleError, removeEmpty } from "utils";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { showToast } from "redux/store.hooks";
import ValidatedRadioBtns from "components/forms/ValidatedRadioBtns";
import StateCityField from "../Components/StateCityField";
import PhoneNumberInput from "components/forms/PhoneNumberInput/PhoneNumberInput";
import { ListOfLanguages } from "utils/constants";

const MIN_DATE = new Date().setFullYear(new Date().getFullYear() - 18);

const BasicInfoTab = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useGetUser();
  const imageRef = useRef();

  const [updateBasicInfo, { isLoading: isUpdateLoading }] =
    useUpdateBasicInfoMutation();

  const [imagePreview, setImagePreview] = useState(currentUser?.avatar);
  const [imageFile, setImageFile] = useState([]);
  const [showError, setShowError] = useState(false);

  const openFile = () => {
    imageRef.current.click();
  };

  const methods = useForm({
    defaultValues: {
      dob: new Date(currentUser?.dob),
      country: currentUser?.country || "",
      state_id: currentUser?.state?.id || "",
      city_id: currentUser?.city?.id || "",
      languages: currentUser?.languages?.map(lang => lang?.name) || [],
    },
  });

  const { setValue } = methods;

  useEffect(() => {
    const FIELDS = [
      "first_name",
      "last_name",
      "gender",
      "about",
      "languages",
      "phone_number",
      "residential_address",
      "country",
    ];
    FIELDS.forEach(el => {
      if (el === "residential_address") {
        if (currentUser?.el === "undefined" || currentUser?.el === undefined) {
          setValue(el, "");
        } else {
          setValue(el, currentUser?.el);
        }
      }
      if (el === "languages") {
        setValue(
          el,
          currentUser?.languages?.map(lang => lang?.name),
        );
      } else {
        setValue(el, currentUser[el] || "");
      }
    });
  }, [currentUser, setValue]);

  const allCountries = Country.getAllCountries()?.filter(
    country => country.name === "Nigeria",
  );

  const imageChange = e => {
    if (e.target.files[0].size > 1000000) {
      showToast("File too large", "error");
      setShowError(true);
      return;
    }
    setImageFile(e.target.files[0]);
    setShowError(false);
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setImagePreview(imageUrl);
  };

  const onSubmit = async data => {
    const formData = new FormData();
    formData.append("profile_image", imageFile);

    Object.entries(removeEmpty(data)).forEach(([key, value]) => {
      if (value instanceof Array) {
        for (const val of value) {
          formData.append(`${key}[]`, val);
        }
      } else {
        formData.append(key, value);
      }
    });

    formData.append("dob", moment(data?.dob).format("DD/MM/YYYY"));

    try {
      let res = await updateBasicInfo(formData).unwrap();
      showToast("Updated Successfully", "success");
      dispatch(setUserDetails(res?.data));
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <div className="w-full rounded-24 bg-white px-6 py-6 md:px-10 md:py-10 ">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex w-full flex-col"
        >
          <h2 className="settings-font-h2">Basic Info Settings</h2>

          <fieldset className="mt-8 pb-[50px] md:mt-[50px] md:min-w-[420px] md:self-center">
            <legend className="mb-[45px] font-Bai text-xl font-medium text-grey_10">
              Edit your Personal Information
            </legend>
            <div className="mb-8 flex flex-col gap-[25px]">
              {/* image  */}
              <div className="flex cursor-pointer items-center gap-4">
                <input
                  ref={imageRef}
                  hidden
                  className="opacity-0"
                  type="file"
                  name="profile_image"
                  accept="image/*"
                  onChange={e => imageChange(e)}
                />
                <div className="flex flex-col items-start">
                  <Avatar
                    sx={{ width: 120, height: 120 }}
                    src={imagePreview}
                    onClick={openFile}
                  />
                  {showError && (
                    <span className="mt-4 ml-3 text-xs text-error">
                      File size too large
                    </span>
                  )}
                </div>
                <button
                  onClick={openFile}
                  type="button"
                  className="text-prop relative rounded-[14px] bg-[#F2F4F7]
                   px-6 py-[18px] text-[15px] text-[#E98100]"
                >
                  Change Profile Picture
                </button>
              </div>

              {/* firstname and lastname  */}
              <div className="flex items-center justify-between gap-4 ">
                <ValidatedInput2
                  label="Firstname"
                  placeholder="Kolade"
                  name="first_name"
                  required={true}
                />

                <ValidatedInput2
                  label="Lastname"
                  placeholder="Kolade"
                  name="last_name"
                  required={true}
                />
              </div>

              {/* about  */}
              <ValidatedTextArea2
                name="about"
                maxLength="250"
                label="About"
                height="h-[130px]"
                placeholder="A brief introduction of who you are"
              />



              {/* languages  */}
              <AutoCompleteField
                label="Languages"
                name="languages"
                placeholder="Select"
                selectOption={ListOfLanguages}
                multiple
              />
              {/* sex / gender  */}
              <ValidatedRadioBtns
                name="gender"
                options={["Male", "Female", "Prefer Not To Say"]}
                label="Sex"
              />

              {/* PHONE NUMBER INPUT */}
              <PhoneNumberInput
                name='phone_number'
                border
              />

              {/* date of birth  */}
              <div className="flex flex-col gap-y-2 text-grey_30">
                <label className="font-400 text-prop mb-[-2px] text-[14px] text-[#6E726E] ">
                  Date of birth
                </label>
                <FormDatePicker name="dob" openTo={"year"} maxDate={MIN_DATE} />
              </div>

              {/* countries  */}
              <AutoCompleteField
                name="country"
                selectOption={allCountries.map(item => item.name)}
                label="Country"
                placeholder="Select Country"
                className="font-Mulish text-sm text-grey_30"
              />
              <div className="grid items-end gap-4 md:grid-cols-2">
                {/* state  */}
                <StateCityField
                  defaultCityId={currentUser?.city?.id}
                  defaultStateId={currentUser?.state?.id}
                />
                {/* <AutoCompleteField
                  name="state_id"
                  selectOption={states}
                  label="State"
                  loading={isStatesLoading}
                  className="font-Mulish text-sm text-grey_30"
                  placeholder="Lagos"
                />

                <AutoCompleteField
                  name="city_id"
                  selectOption={stateCities}
                  label="City"
                  className="font-Mulish text-sm text-grey_30"
                  placeholder="Ikeja"
                />
                */}
              </div>

              {/* address  */}
              <ValidatedTextArea2
                label="Residential Address"
                placeholder="Ikeja"
                name="residential_address"
              />
            </div>
            <SaveChangeBtn isLoading={isUpdateLoading} type="submit" />
          </fieldset>
        </form>
      </FormProvider>
    </div>
  );
};
export default BasicInfoTab;