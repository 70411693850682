import React from "react";

function NewFileIcon({stroke, width, height}) {
  return (
    <>
      <svg
        width={width || "25"}
        height={height || "30"}
        viewBox="0 0 25 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0013 2.02627V7.53366C15.0013 8.2804 15.0013 8.65376 15.1466 8.93898C15.2745 9.18986 15.4784 9.39384 15.7293 9.52167C16.0145 9.66699 16.3879 9.66699 17.1346 9.66699H22.642M23.0013 12.318V21.9337C23.0013 24.1739 23.0013 25.294 22.5653 26.1496C22.1818 26.9023 21.5699 27.5142 20.8173 27.8977C19.9616 28.3337 18.8415 28.3337 16.6013 28.3337H8.06797C5.82776 28.3337 4.70765 28.3337 3.85201 27.8977C3.09936 27.5142 2.48744 26.9023 2.10394 26.1496C1.66797 25.294 1.66797 24.1739 1.66797 21.9337V8.06699C1.66797 5.82678 1.66797 4.70668 2.10394 3.85103C2.48744 3.09838 3.09936 2.48646 3.85201 2.10297C4.70765 1.66699 5.82776 1.66699 8.06797 1.66699H12.3503C13.3287 1.66699 13.8179 1.66699 14.2782 1.77751C14.6864 1.8755 15.0766 2.03712 15.4344 2.25643C15.8381 2.5038 16.184 2.8497 16.8758 3.54151L21.1268 7.79247C21.8186 8.48428 22.1645 8.83019 22.4119 9.23385C22.6312 9.59174 22.7928 9.98192 22.8908 10.3901C23.0013 10.8504 23.0013 11.3396 23.0013 12.318Z"
          stroke={stroke || "#E98100"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default NewFileIcon;
