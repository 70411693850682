import { Button } from '@mui/material'
import MyModal from 'components/mui-components/Modal'
import React, { useState } from 'react'

const SelectCategoryModal = ({
    openModal,
    setOpenModal
}) => {
    const [step, setstep] = useState(1)
    // let selectedValues = false
    return (
        <MyModal
            openModal={openModal}
            closeModal={() => setOpenModal(false)}
            title={"Select you categories of interest"}
            maxWidth={588}
        >
            <p className="font-medium text-sm text-grey_base mb-4">
                Get a more tailored view of jobs by selecting up to 5 categories of your interest.
            </p>

            <span className="px-1.5 py-2.5 w-fit mb-6 bg-primary-10 rounded-full font-semibold text-primary-70 text-xs">
                3 of 5 selected
            </span>

            <div className="flex gap-2.5 flex-wrap max-h-[300px] overflow-y-auto">
                {categories?.map((category, i) =>
                    <div
                        key={category.id}
                        onClick={() => setstep(category?.id)}
                        className={`rounded-full relative p-2 flex items-center gap-2 5 w-fit ${category?.id === step ? "bg-grey-30 border border-grey-30" : "border border-[#DCDCE0]"}`}>
                        <div className={`flex justify-center items-center rounded-full w-10 h-10 ${category?.id === step ? "bg-[#FFE5C4]" : "bg-grey-100"}`}>
                            <img src={category?.imgUrl} alt="money" className='w-6 h-6' />
                        </div>
                        <p className="font-Bai text-sm text-grey-40 font-semibold">
                            {category?.title}
                        </p>

                        <span
                            className={`flex items-center justify-center top-4 right-4 rounded-full border-2 before:rounded-full before:absolute transition-all duration-200 ease-in-out  before:top-[16px] before:right-[9px] md:before:top-[18px] md:before:right-[10px] before:w-5 before:bg-primary before:h-5
                         ${category?.id === step
                                    ? "border-primary   before:block"
                                    : "border-[#C9C9CF]  before:hidden"
                                } w-6 h-6`}
                        >
                            {category?.id === step &&
                                <svg className='z-10' width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.66968 1.08762C8.80539 1.21201 8.81456 1.42287 8.69016 1.55858L3.80127 6.89191C3.73984 6.95893 3.65369 6.99795 3.5628 6.99992C3.4719 7.0019 3.38414 6.96666 3.31985 6.90237L1.09763 4.68015C0.967456 4.54997 0.967456 4.33892 1.09763 4.20874C1.22781 4.07857 1.43886 4.07857 1.56904 4.20874L3.54508 6.18479L8.19872 1.10809C8.32312 0.972387 8.53398 0.96322 8.66968 1.08762Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                        </span>
                    </div>
                )}
            </div>

            <div className="w-full my-7 mx-auto max-w-[327px]">
                <Button
                    variant="contained"
                    fullWidth
                >
                    Confirm Selection
                </Button>
            </div>
        </MyModal>
    )
}

export default SelectCategoryModal

const categories = [
    {
        id: 1,
        imgUrl: "/images/money.png",
        title: "Accounting and FInance",
    },
    {
        id: 2,
        imgUrl: "/images/Lipstick.png",
        title: "Beauty and Makeup",
    },
    {
        id: 3,
        imgUrl: "/images/Construction.png",
        title: "Building and Construction",
    },
    {
        id: 4,
        imgUrl: "/images/Chart.png",
        title: "Business and Admin",
    },
    {
        id: 5,
        imgUrl: "/images/Sparkles.png",
        title: "Cleaning",
    },
    {
        id: 6,
        imgUrl: "/images/palette.png",
        title: "Design",
    },
    {
        id: 7,
        imgUrl: "/images/Electric-plug.png",
        title: "Electrical",
    },
    {
        id: 8,
        imgUrl: "/images/Bread.png",
        title: "Food and Baking",
    },
    {
        id: 9,
        imgUrl: "/images/Stethoscope.png",
        title: "Health and Fitness",
    },
    {
        id: 10,
        imgUrl: "/images/House.png",
        title: "Housing and Accomodation",
    },
    {
        id: 11,
        imgUrl: "/images/Laptop.png",
        title: "IT and Development",
    },
    {
        id: 12,
        imgUrl: "/images/antenna.png",
        title: "IT and Network",
    },
    {
        id: 13,
        imgUrl: "/images/Balance-scale.png",
        title: "Legal",
    },
]