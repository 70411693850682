import {IconButton} from "@mui/material";
import CancelIcon from "./Vectors/CancelIcon";
import DownloadIcon from "./Vectors/DownloadIcon";
import NewFileIcon from "./Vectors/NewFileIcon";

function FileDisplayUi({
  name,
  link,
  size,
  isFileLarge,
  downloadType = true,
  onCancel,
}) {
  return (
    <div
      className="flex justify-between rounded-20 border-[2px] border-dashed
             border-[#DCDCE5] py-[25px] px-[30px]  "
    >
      <div className="flex items-center gap-4">
        <NewFileIcon />
        <div>
          <p className="text-prop text-base font-bold text-grey_20">{name}</p>
          <div>
            {isFileLarge && (
              <span className="text-xs text-error">File size too large - </span>
            )}{" "}
            {!downloadType && <span className="text-xs">{size}</span>}
          </div>
        </div>
      </div>
      {downloadType ? (
        <a
          href={link}
          download
          className="flex items-center justify-center rounded-[12px] bg-[#FDF2E6] p-[12px] "
        >
          <DownloadIcon />
        </a>
      ) : (
        <IconButton onClick={onCancel}>
          <CancelIcon color="#e98100" />
        </IconButton>
      )}
    </div>
  );
}

export default FileDisplayUi;
