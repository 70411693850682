const MailIcon2 = props => {
  return (
    <>
      <svg
        width="105"
        height="101"
        viewBox="0 0 114 109"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M47.7722 7.72585C51.1125 5.55463 52.7827 4.46902 54.5822 4.04663C56.1729 3.67326 57.8284 3.67326 59.4191 4.04663C61.2186 4.46902 62.8888 5.55463 66.2291 7.72585L106.065 33.6191C107.472 34.534 108.176 34.9915 108.686 35.6017C109.138 36.1419 109.477 36.7667 109.684 37.4396C109.917 38.1997 109.917 39.0391 109.917 40.7179V79.5148C109.917 88.4057 109.917 92.8511 108.187 96.2469C106.665 99.234 104.236 101.663 101.249 103.185C97.8536 104.915 93.4081 104.915 84.5173 104.915H29.484C20.5932 104.915 16.1477 104.915 12.7519 103.185C9.76481 101.663 7.33625 99.234 5.81426 96.2469C4.08398 92.8511 4.08398 88.4057 4.08398 79.5148V40.7179C4.08398 39.0391 4.08398 38.1997 4.31772 37.4396C4.52462 36.7667 4.86369 36.1419 5.31508 35.6017C5.825 34.9915 6.52881 34.534 7.93642 33.6191L47.7722 7.72585Z"
          fill="#FAEFD7"
        />
        <path
          d="M47.7722 64.5205L9.54462 39.6726C7.72469 38.4896 6.81472 37.8982 6.49956 37.1481C6.22414 36.4926 6.22414 35.7538 6.49956 35.0983C6.81472 34.3482 7.72469 33.7567 9.54462 32.5738L47.7722 7.72585C51.1125 5.55463 52.7827 4.46902 54.5822 4.04663C56.1729 3.67326 57.8284 3.67326 59.4191 4.04663C61.2186 4.46902 62.8888 5.55463 66.2291 7.72585L104.457 32.5738C106.277 33.7567 107.187 34.3482 107.502 35.0983C107.777 35.7538 107.777 36.4926 107.502 37.1481C107.187 37.8982 106.277 38.4896 104.457 39.6726L66.2291 64.5205C62.8888 66.6917 61.2186 67.7773 59.4191 68.1997C57.8284 68.5731 56.1729 68.5731 54.5822 68.1997C52.7827 67.7773 51.1125 66.6917 47.7722 64.5205Z"
          fill="#FAEFD7"
        />
        <path
          d="M66.2291 7.72585L106.065 33.6191C107.472 34.534 108.176 34.9915 108.686 35.6017C109.138 36.1419 109.477 36.7667 109.684 37.4396C109.917 38.1997 109.917 39.0391 109.917 40.7179V79.5148C109.917 88.4057 109.917 92.8511 108.187 96.2469C106.665 99.234 104.236 101.663 101.249 103.185C97.8536 104.915 93.4081 104.915 84.5173 104.915H29.484C20.5932 104.915 16.1477 104.915 12.7519 103.185C9.76481 101.663 7.33625 99.234 5.81426 96.2469C4.08398 92.8511 4.08398 88.4057 4.08398 79.5148V40.7179C4.08398 39.0391 4.08398 38.1997 4.31772 37.4396C4.52462 36.7667 4.86369 36.1419 5.31508 35.6017C5.825 34.9915 6.52881 34.534 7.93642 33.6191L47.7722 7.72585M66.2291 7.72585C62.8888 5.55463 61.2186 4.46902 59.4191 4.04663C57.8284 3.67326 56.1729 3.67326 54.5822 4.04663C52.7827 4.46902 51.1125 5.55463 47.7722 7.72585M66.2291 7.72585L104.457 32.5738C106.277 33.7567 107.187 34.3482 107.502 35.0983C107.777 35.7538 107.777 36.4926 107.502 37.1481C107.187 37.8981 106.277 38.4896 104.457 39.6726L66.2291 64.5205C62.8888 66.6917 61.2186 67.7773 59.4191 68.1997C57.8284 68.5731 56.1729 68.5731 54.5822 68.1997C52.7827 67.7773 51.1125 66.6917 47.7722 64.5205L9.54462 39.6726C7.72469 38.4896 6.81472 37.8981 6.49956 37.1481C6.22414 36.4926 6.22414 35.7538 6.49956 35.0983C6.81472 34.3482 7.72469 33.7567 9.54462 32.5738L47.7722 7.72585"
          stroke="#F6A001"
          strokeWidth="7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default MailIcon2;
