import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import {Chip, IconButton} from "@mui/material";
import {Link} from "react-router-dom";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import {toCurrency} from "utils";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import Users2 from "components/Vectors/Users2";
import moment from "moment";
import {useGetEngagementByStatusQuery} from "services/admin/adminGeneralApi";

// display status color based on the value passed
const ChipStatus = {
  completed: "success",
  active: "secondary",
  cancelled: "error",
  terminated: "error",
  ongoing: "secondary",
};

const EngagementsTable = ({status}) => {
  const {data, isError, isLoading, error} = useGetEngagementByStatusQuery({
    status,
  });

  const engagements = data?.data;

  const options = {
    download: false,
    print: false,
    viewColumns: false,
  };

  const mockData = engagements?.map(engagement => ({
    job: {
      img: engagement?.job_without_relationship?.images[0]?.image_url,
      name: engagement?.job_without_relationship?.job_title,
    },
    client: {
      name: engagement?.client?.full_name,
      avatar: engagement?.client?.avatar,
      searchBy: engagement?.client?.username,
    },
    serviceProvider: {
      name: engagement?.service_provider?.full_name,
      avatar: engagement?.service_provider?.avatar,
      searchBy: engagement?.service_provider?.username,
      badge: engagement?.service_provider?.is_verified_account,
    },
    category: engagement?.job_without_relationship?.category?.name,
    price: `${toCurrency(engagement?.amount)} `,
    startDate: engagement?.start_date
      ? moment(engagement?.start_date).format("DD MMM YYYY")
      : "N/A",
    endDate: engagement?.end_date
      ? moment(engagement?.end_date).format("DD MMM YYYY")
      : "N/A",
    status: engagement?.status,
    option: {
      id: engagement?.id,
    },
    id: `row_${engagement?.id}`,
  }));

  return (
    <div className="mt-6 w-full">
      <MyDataTable
        isLoading={isLoading}
        isError={isError}
        hideChipDot={true}
        scrollX={true}
        error={error}
        data={mockData}
        headCells={headCells}
        options={options}
        emptyData={{
          icon: Users2,
          message: "No Engagements yet",
        }}
      />
    </div>
  );
};

export default EngagementsTable;

const headCells = [
  {
    name: "job",
    label: "Job",
    options: {
      customBodyRender: value => (
        <UserDisplay name={value?.name} imgRounded={false} img={value?.img} />
      ),
    },
  },
  {
    name: "client",
    label: "Client",
    options: {
      customBodyRender: value => (
        <UserDisplay name={value?.name} img={value?.avatar} />
      ),
    },
  },
  {
    name: "serviceProvider",
    label: "Service Provider",
    options: {
      customBodyRender: value => (
        <UserDisplay
          name={value?.name}
          img={value?.avatar}
          badge={value?.badge}
        />
      ),
    },
  },
  {
    name: "category",
    label: "Category",
    options: {
      filter: true,
    },
  },
  {
    name: "price",
    label: "Price",
  },
  {
    name: "startDate",
    label: "Start date",
  },
  {
    name: "endDate",
    label: "End date",
  },
  {
    name: "status",
    label: "Status",
    options: {
      customBodyRender: value => (
        <Chip color={ChipStatus[value.toLowerCase()]} label={value} />
      ),
      filter: true,
    },
  },
  {
    name: "option",
    label: "",
    options: {
      customBodyRender: value => (
        <IconButton component={Link} to={`${value?.id}`}>
          <ArrowCircleRightOutlinedIcon color="primary" />
        </IconButton>
      ),
    },
  },
];
