import AlignBoxIcon from "components/Vectors/AlignBoxIcon";
import {useGetUser} from "hooks/getUserHook";
import ProfessionalInfoDetails from "pages/Clients/Profile/widgets/ProfessionalInfoDetails";
import React from "react";
import EngagementBody from "../widgets/EngagementBody";
import ProfessionalInformation from "../widgets/ProfessionalInformation";

const ProviderEngagementData = [
  {
    icon: AlignBoxIcon,
    text: "All Engagements",
    valueKey: "all_engagements_count",
    buttonText: "View Engagements",
    link: "/service-provider/engagements",
  },
  {
    icon: AlignBoxIcon,
    text: "Completed Engagements",
    valueKey: "completed_engagements_count",
    buttonText: "View Engagements",
    link: "/service-provider/engagements",
  },
  {
    icon: AlignBoxIcon,
    text: "Ongoing Engagements",
    valueKey: "ongoing_engagements_count",
    buttonText: "View Engagements",
    link: "/service-provider/engagements",
  },
];

function ProfileInformationTab() {
  const {user} = useGetUser();
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
      <div className="">
        <ProfessionalInfoDetails user={user} />
      </div>

      <div className="flex flex-col gap-7">
        <ProfessionalInformation />
        <EngagementBody user={user} list={ProviderEngagementData} />
      </div>
    </div>
  );
}

export default ProfileInformationTab;
