function EditIcon({width, height, color, ...props}) {
  return (
    <svg
      {...props}
      width={width || "18"}
      height={height || "19"}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 17.5625H6.75C2.6775 17.5625 0.9375 15.8225 0.9375 11.75V7.25C0.9375 3.1775 2.6775 1.4375 6.75 1.4375H8.25C8.5575 1.4375 8.8125 1.6925 8.8125 2C8.8125 2.3075 8.5575 2.5625 8.25 2.5625H6.75C3.2925 2.5625 2.0625 3.7925 2.0625 7.25V11.75C2.0625 15.2075 3.2925 16.4375 6.75 16.4375H11.25C14.7075 16.4375 15.9375 15.2075 15.9375 11.75V10.25C15.9375 9.9425 16.1925 9.6875 16.5 9.6875C16.8075 9.6875 17.0625 9.9425 17.0625 10.25V11.75C17.0625 15.8225 15.3225 17.5625 11.25 17.5625Z"
        fill={color || "#2C2C3A"}
      />
      <path
        d="M6.375 13.7674C5.9175 13.7674 5.4975 13.6024 5.19 13.3024C4.8225 12.9349 4.665 12.4024 4.7475 11.8399L5.07 9.58242C5.13 9.14742 5.415 8.58492 5.7225 8.27742L11.6325 2.36742C13.125 0.874922 14.64 0.874922 16.1325 2.36742C16.95 3.18492 17.3175 4.01742 17.2425 4.84992C17.175 5.52492 16.815 6.18492 16.1325 6.85992L10.2225 12.7699C9.915 13.0774 9.3525 13.3624 8.9175 13.4224L6.66 13.7449C6.5625 13.7674 6.465 13.7674 6.375 13.7674ZM12.4275 3.16242L6.5175 9.07242C6.375 9.21492 6.21 9.54492 6.18 9.73992L5.8575 11.9974C5.8275 12.2149 5.8725 12.3949 5.985 12.5074C6.0975 12.6199 6.2775 12.6649 6.495 12.6349L8.7525 12.3124C8.9475 12.2824 9.285 12.1174 9.42 11.9749L15.33 6.06492C15.8175 5.57742 16.0725 5.14242 16.11 4.73742C16.155 4.24992 15.9 3.73242 15.33 3.15492C14.13 1.95492 13.305 2.29242 12.4275 3.16242Z"
        fill={color || "#2C2C3A"}
      />
      <path
        d="M14.8875 7.8727C14.835 7.8727 14.7825 7.8652 14.7375 7.8502C12.765 7.2952 11.1975 5.7277 10.6425 3.7552C10.56 3.4552 10.7325 3.1477 11.0325 3.0577C11.3325 2.9752 11.64 3.1477 11.7225 3.4477C12.1725 5.0452 13.44 6.3127 15.0375 6.7627C15.3375 6.8452 15.51 7.1602 15.4275 7.4602C15.36 7.7152 15.135 7.8727 14.8875 7.8727Z"
        fill={color || "#2C2C3A"}
      />
    </svg>
  );
}

export default EditIcon;
