const GetThingsDone = ({steps = thingsToDo}) => {
  return (
    <div className="mx-auto mt-12 mb-24 w-11/12">
      <p className="text-center font-Bai text-2xl font-bold text-grey_10">
        Here's how to get things done
      </p>
      <div className="mx-auto my-8 h-px w-10/12 bg-[#D7D7DC]"></div>
      <div className="flex flex-col gap-y-8">
        {steps.map((step, index) => (
          <div className="flex items-start gap-x-4">
            <span className="flex h-[52px] w-[52px] items-center justify-center rounded-full bg-[#FDF2E6] font-Bai text-xl font-bold text-primary">
              {index}
            </span>
            <div className="flex flex-col gap-y-1">
              <p className="font-Bai text-xl font-bold">{step.title}</p>
              <p className="text-grey_30">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GetThingsDone;
const thingsToDo = [
  {
    title: "Title of the first step",
    description:
      "You're almost there, copy the code below to verify your email address.",
  },
  {
    title: "Title of the second step",
    description:
      "You're almost there, copy the code below to verify your email address.",
  },
  {
    title: "Title of the third step",
    description:
      "You're almost there, copy the code below to verify your email address.",
  },
];
