import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import {Avatar, Button, IconButton} from "@mui/material";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import {useState} from "react";
import CreateCategoryModal from "../Modals/CreateCategoryModal";
import {useGetAllCategoriesQuery} from "services/admin/adminGeneralApi";
import moment from "moment/moment";
import {Link} from "react-router-dom";

const CategoriesTab = () => {
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const {data: categories, isLoading} = useGetAllCategoriesQuery({
    pollingInterval: 3000,
  });

  const headCells = [
    {
      name: "category",
      label: "Category",
    },
    {
      name: "featured",
      label: "Featured",
    },
    {
      name: "icon",
      label: "Mobile App Icon",
    },
    {
      name: "capabilities",
      label: "Sub Category",
    },

    {
      name: "modified",
      label: "Last Modified",
    },
    {
      name: "action",
      label: "",
    },
  ];
  const mockData = categories?.data.map(category => ({
    id: `row_${category.id}`,
    category: (
      <div className="flex items-center gap-x-1 md:gap-x-2">
        <Avatar
          src={category.cover_photo}
          alt="img"
          sx={{height: "35px", width: "40px", borderRadius: "8px"}}
        />
        <span className="text-xs font-medium text-grey_20 md:text-base">
          {category.name}
        </span>
      </div>
    ),
    featured: <span className="capitalize">{category?.is_featured}</span>,

    icon: (
      <Avatar
        src={category.mobile_app_icon}
        alt="mobile_app_icon"
        sx={{height: "35px", width: "40px", borderRadius: "8px"}}
      />
    ),
    capabilities: `${category.expertises.length}`,
    modified: `${moment(category.updated_at).format("Do MMMM, YYYY")}`,
    action: (
      <IconButton component={Link} to={`category/${category.id}`}>
        <ArrowCircleRightOutlinedIcon
          sx={{
            color: "#E98100",
          }}
        />
      </IconButton>
    ),
  }));

  return (
    <div>
      <div className="my-8 flex items-center justify-between">
        <h1 className="font-Bai text-2xl font-semibold text-grey_20 md:text-3xl">
          Categories
        </h1>
        <Button
          variant="contained"
          onClick={() => setShowCreateCategoryModal(true)}
        >
          Create Category
        </Button>
      </div>
      <MyDataTable
        options={{
          viewColumns: false,
          filter: false,
        }}
        headCells={headCells}
        data={mockData}
        scrollX={false}
        isLoading={isLoading}
        emptyData={{
          message: "No Category Created",
        }}
      />
      <CreateCategoryModal
        open={showCreateCategoryModal}
        close={() => setShowCreateCategoryModal(false)}
      />
    </div>
  );
};

export default CategoriesTab;
