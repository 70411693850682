import GoogleIcon from "assets/socials/google.svg";
import {useGoogleLogin} from "@react-oauth/google";
import {handleError} from "utils";
import {useUserAuthWithGoogleMutation} from "services/auth.api";
import {setUserDetails, setUserToken} from "redux/slice/AuthSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const GoogleBtn = ({socialIcon, text, arrowIcon}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, {isLoading}] = useUserAuthWithGoogleMutation();
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        const userInfo = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {Authorization: `Bearer ${tokenResponse.access_token}`},
          },
        ).then(res => res.json());

        const res = await login({
          email: userInfo?.email || "",
          social_provider_id: userInfo?.sub,
          avatar: userInfo?.picture,
          name: userInfo?.name,
        }).unwrap();

        // refetch();
        dispatch(setUserToken(res?.data.access_token));
        dispatch(setUserDetails(res?.data));
        setTimeout(() => {
          if (res?.data?.username === null) {
            navigate("/user/create-username");
            return;
          } else if (res?.data?.user_type === null) {
            navigate("/user/select-user");
            return;
          } else if (
            res?.data?.user_type === "Service provider" &&
            res?.data?.service_provider_type === null
          ) {
            navigate("/user/provider/register/1");
            return;
          } else if (res?.data?.about === null) {
            navigate(
              res?.data.user_type === "Client"
                ? "/user/client/register/1"
                : "/user/provider/register/2",
            );
            return;
          } else if (
            res?.data?.user_type === "Service provider" &&
            res?.data?.professional_information === null
          ) {
            navigate("/user/provider/register/3");
            return;
          } else if (res?.data?.kyc === null) {
            navigate(
              res?.data?.user_type === "Client"
                ? "/user/client/register/2"
                : "/user/provider/register/4",
            );
            return;
          } else if (res?.data?.bank_account === null) {
            navigate(
              res?.data?.user_type === "Client"
                ? "/user/client/register/3"
                : "/user/provider/register/5",
            );
            return;
          }
          navigate(
            `/${
              res?.data?.user_type?.toLowerCase().replace(" ", "-") ||
              "user/account-created?from=google"
            }`,
        );
        }, 500);
      } catch (err) {
        handleError(err);
      }
    },
  });

  return (
    <button
      onClick={() => googleLogin()}
      type="button"
      className="flex w-full items-center justify-around gap-4 rounded-2xl bg-grey_80 px-[2rem] py-[1rem] md:gap-[50px]"
    >
      {isLoading ? (
        <span
          className={`${!isLoading && "hidden"} flex w-full justify-center `}
        >
          Authenticating...
        </span>
      ) : (
        <div className="flex w-full items-center justify-around gap-4 md:gap-[50px]">
          <span className="flex items-center gap-2.5">
            <img src={GoogleIcon} alt="icon" />
            <p className="text-prop font-Mulish text-base font-medium md:ml-5">
              Continue with Google
            </p>
          </span>

          <span>
            <ChevronRightRoundedIcon sx={{color: "#6e6e6e"}} />
            {/* <ArrowRightIcon color="#6e6e6e" /> */}
          </span>
        </div>
      )}
    </button>
  );
};
export default GoogleBtn;
