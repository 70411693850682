const OverviewCard = ({
  title,
  value,
  icon,
  id,
  iconBg = "bg-white",
  containerClass = "",
  isLoading = false,
}) => {
  return (
    <div key={id}>
      {/* tablet and desktop view */}
      <div
        className={`${containerClass} hidden flex-grow flex-col rounded-16 border-[1px]
         border-[#F2F4F7] bg-[#F9FAFB] p-4 md:flex md:rounded-20 `}
      >
        {/* top  */}
        <div className=" mb-2 flex items-center gap-4 ">
          <div
            className={`flex  h-[52px]  w-[52px] items-center justify-center rounded-10 ${iconBg}`}
          >
            {icon}
          </div>
          <p className="text-base font-bold leading-[120%] tracking-[-0.02em]">
            {title}
          </p>
        </div>

        {/* bottom  */}
        <span className="font-Bai text-[32px] font-semibold leading-[150%] tracking-[-0.04em] text-grey_10 ">
          {isLoading ? "..." : value || 0}
        </span>
      </div>

      {/* mobile  */}
      <div className="flex h-full flex-1 flex-grow flex-col justify-between rounded-16 bg-white p-4 md:hidden">
        {/* top  */}
        <div className=" mb-2 flex items-center gap-4 ">
          <p className="text-base font-bold leading-[120%] tracking-[-0.02em] text-[#222222]  ">
            {title}
          </p>
        </div>

        {/* bottom  */}

        <div className="flex justify-between">
          <span className="font-Bai text-[32px] font-semibold leading-[150%] tracking-[-0.04em] ">
            {isLoading ? "..." : value || 0}
          </span>
          <div className="w-fit rounded-16 bg-white p-[17px] ">{icon}</div>
        </div>
      </div>
    </div>
  );
};
export default OverviewCard;
