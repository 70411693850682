import {Button} from "@mui/material";
import EditIcon2 from "components/Vectors/EditIcon2";
import {useState} from "react";
import Expertise from "components/Expertise";
import DeleteIcon2 from "components/Vectors/DeleteIcon2";
import Video from "components/mui-components/Modal/Video";
import ImageSlideShow from "components/mui-components/Modal/ImageSlideshow";
import AddService from "../AddServiceWidgets";
import MessageModal from "components/mui-components/Modal/MessageModal";
import {LoadingButton} from "@mui/lab";
import {useDeleteSingleServiceMutation} from "services/user/providerGeneralApi";
import {handleError} from "utils";
import DeleteIcon from "components/Vectors/DeleteIcon";

function ServiceDetailsTab({service, showCta = true}) {
  const [openAddServicesModal, setOpenAddServicesModal] = useState(false);
  const [openDelServicesModal, setopenDelServicesModal] = useState(false);
  const [deleteService, {isLoading: isDeleteLoading}] =
    useDeleteSingleServiceMutation();

  const handleDelete = async () => {
    try {
      await deleteService(service?.id).unwrap();
      setopenDelServicesModal(false);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div
      className={`flex flex-col gap-8 rounded-[20px] bg-grey_80 ${
        showCta ? "p-4 md:p-6" : "p-4"
      }`}
    >
      {showCta && (
        <div className="flex justify-between">
          <p className="font-Bai text-2xl font-semibold text-grey">
            {service?.category.name}
          </p>
          <div className="flex items-center gap-2">
            <Button
              variant="outlined"
              color="error"
              onClick={() => setopenDelServicesModal(true)}
              sx={{borderRadius: "12px !important", px: "15px !important"}}
            >
              <DeleteIcon2 stroke="#D92D20" width="24" height="24" />
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenAddServicesModal(true);
              }}
              sx={{borderRadius: "12px !important", px: "15px !important"}}
            >
              <EditIcon2 stoke="white" />
            </Button>
          </div>
        </div>
      )}
      <div className="">
        <p className="mb-0 text-lg font-medium text-grey_30">Description</p>
        <p className="my-2 text-lg font-medium text-[#000000]">
          {service?.description}
        </p>
      </div>
      <div className="">
        <p className="mb-2 text-lg font-medium text-grey_30">
          Areas of Expertise
        </p>
        <div className="flex flex-col gap-2">
          {service?.expertise?.map((item, i) => (
            <Expertise key={`expertise${item.id}`} item={item} />
          ))}
        </div>
      </div>
      <div>
        <p className="mb-4 text-lg font-medium text-grey_30">Portfolio</p>
        {service?.images?.length > 0 && (
          <>
            <p className="mb-2 text-lg font-medium text-[#000000]">Images</p>
            <ImageSlideShow images={service?.images?.map(el => ({image_url: el.image}))} />
          </>
        )}
      </div>

      {service?.videos?.length > 0 && (
        <div>
          <p className="mb-2 text-lg font-medium text-[#000000]">Videos</p>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {service?.videos?.map(el => (
              <Video videoUrl={el?.video} key={el?.video} />
            ))}
          </div>
        </div>
      )}
      {showCta && (
        <>
          <AddService
            serviceData={service}
            openModal={openAddServicesModal}
            setOpenModal={setOpenAddServicesModal}
          />
          <MessageModal
            title={"Are you sure you want to delete this service?"}
            openModal={openDelServicesModal}
            icon={<DeleteIcon width={50} height={50} />}
            closeModal={() => setopenDelServicesModal(false)}
            cta={
              <div className="flex w-full items-center gap-4">
                <Button
                  className="flex-1"
                  variant="contained"
                  sx={{fontWeight: 600}}
                  color="primaryInverse2"
                  onClick={() => setopenDelServicesModal(false)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  className="flex-1"
                  variant="contained"
                  color="error"
                  loading={isDeleteLoading}
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Delete
                </LoadingButton>
              </div>
            }
          />
        </>
      )}
    </div>

    // </AccordionWrap>
  );
}

export default ServiceDetailsTab;
