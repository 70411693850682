import {Button} from "@mui/material";
import User_2 from "components/Vectors/User_2";
import React, {createElement} from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {Link} from "react-router-dom";

function EachEngagementCard(props) {
  const {
    icon = User_2,
    text = "All Engagements",
    value = "10",
    buttonText,
    link = "",
    className = "bg-white",
  } = props;

  return (
    <div className={`mb-5 rounded-2xl ${className} p-4`}>
      <div className="flex w-full gap-4">
        <div className="flex h-[52px] w-[52px] items-center justify-center rounded-[10px] bg-[#FEF8F1]">
          {createElement(icon, {color: "#e98100"})}
        </div>
        <div className="mt-3 flex-1">
          <p className="mb-7 text-base font-normal text-grey_20">{text}</p>

          <div className="flex items-center justify-between">
            <p className="font-Bai text-3xl font-semibold text-grey">{value}</p>
            {buttonText && (
              <Button component={Link} to={link} endIcon={<ArrowForwardIcon />}>
                {buttonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EachEngagementCard;
