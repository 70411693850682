import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {authApi} from "services/auth.api";
import {adminProfileApi} from "services/admin/adminProfileApi";
import {adminGeneralApi} from "services/admin/adminGeneralApi";
import {userRegistrationApi} from "services/user/userRegApi";
import {generalApi} from "services/generalApi";
import {clientGeneralApi} from "services/user/clientGeneralApi";
import {providerGeneralApi} from "services/user/providerGeneralApi";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth", "toasts"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authApi.middleware)
      .concat(generalApi.middleware)
      .concat(adminProfileApi.middleware)
      .concat(adminGeneralApi.middleware)
      .concat(userRegistrationApi.middleware)
      .concat(clientGeneralApi.middleware)
      .concat(providerGeneralApi.middleware),
});

export const persistor = persistStore(store);

export default store;
