import { useState } from "react";
import { useNavigate } from "react-router";
import { UserSkeleton } from ".";
import { ReactComponent as Client } from "assets/icons/as-client.svg";
import { FormProvider, useForm } from "react-hook-form";
import { ReactComponent as ClientActive } from "assets/icons/as-client-active.svg";
import { ReactComponent as Provider } from "assets/icons/as-provider.svg";
import { ReactComponent as ProviderActive } from "assets/icons/as-provider-active.svg";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import { useChooseUserTypeMutation } from "services/user/userRegApi";
import { handleError } from "utils";
import { useGetUser } from "hooks/getUserHook";
import { setUserDetails } from "redux/slice/AuthSlice";
import { useDispatch } from "react-redux";
import LogOutModal from "templates/DashboardLayout/widgets/LogOutModal";
import { logOutHandler } from "redux/store.hooks";

const UserSelectUser = () => {
  const [choice, setChoice] = useState("");
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm({
    mode: "onSubmit",
  });
  const { user: currentUser } = useGetUser();

  const [createUserType, { isLoading }] = useChooseUserTypeMutation();

  const handleChoice = e => {
    setChoice(e.currentTarget.value);
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("user_type", choice);

    try {
      let res = await createUserType(formData).unwrap();
      dispatch(setUserDetails({ ...currentUser, ...res?.data }));
      navigate(
        choice.includes("provider")
          ? "/user/provider/register/1"
          : "/client",
      );
    } catch (error) {
      handleError(error);
    }
  };
  const users = [
    {
      name: "Client",
      icon: <Client />,
      activeIcon: <ClientActive />,
      title: "As a Client",
      description:
        "You want to post tasks to be carried out by service providers.",
    },
    {
      name: "Service provider",
      icon: <Provider />,
      activeIcon: <ProviderActive />,
      title: "As a Service Provider",
      description: "You want to carry out tasks for clients and earn money.",
    },
  ];

  return (
    <>
      <UserSkeleton>
        <div
          className="my-8 flex w-full max-w-[600px] flex-col rounded-2xl bg-grey_90
          p-2 lg:my-16 lg:rounded-[35px] lg:px-11 lg:py-12"
        >
          <h1 className="mb-6 p-3 text-center font-Bai text-2xl font-semibold tracking-[-4%] md:p-0 md:text-[2rem]">
            How do you want to use Truelocal?
          </h1>
          <FormProvider {...methods}>
            <form
              className="darkBgForm flex flex-col items-center w-full justify-center gap-y-8"
              onSubmit={methods.handleSubmit(handleSubmit)}
            >
              <div className="flex flex-col items-center justify-between gap-6 text-black_text md:flex-row md:gap-3">
                {users.map(
                  ({ name, icon, activeIcon, title, description }, index) => (
                    <button
                      key={index}
                      onClick={e => handleChoice(e)}
                      value={name}
                      type="button"
                      className={`group relative min-h-[220px] flex justify-center basis-1/2 flex-col gap-y-3 rounded-2xl p-4 font-Bai transition-all hover:scale-105 hover:bg[#FEFAF6] ${choice === name ? "bg-[#FEFAF6] border border-primary" : "bg-[#f4f4f5]"
                        }`}
                    >
                      <span
                        className={`absolute top-4 right-4 rounded-full border-2 before:bg-primary before:absolute transition-all duration-200 ease-in-out  before:top-[4px] before:right-[4px] before:rounded-full before:w-3 before:h-3 ${choice === name
                          ? "border-primary  before:block"
                          : "border-[#C9C9CF] bg-white  before:hidden"
                          } w-6 h-6`}
                      ></span>
                      {choice === name ? activeIcon : icon}
                      <p
                        className={`text-lg font-bold group-hovertext-[#8C4D00] ${choice === name ? "text[#8C4D00]" : ""
                          }`}
                      >
                        {title}
                      </p>
                      <p
                        className={`text-left font-Mulish text-[13px] group-hovertext-[#AF6100] ${choice === name ? "text[#AF6100]" : "text-grey_30"
                          }`}
                      >
                        {description}
                      </p>
                    </button>
                  ),
                )}
              </div>
              {/* {choice && ( */}
              <LoadingButton
                sx={{
                  // marginTop: "32px",
                  maxWidth: "314px",
                }}
                loadingIndicator={
                  <CircularProgress
                    sx={{
                      color: "#fff",
                    }}
                    size="1.2rem"
                  />
                }
                type="submit"
                loading={isLoading}
                disabled={!choice}
                fullWidth
                variant="contained"
                color="primary"
              >
                {`Continue 
                    ${choice.toLowerCase().includes("provider")
                    ? "as Service Provider"
                    : choice.toLowerCase().includes("client")
                      ? "as Client"
                      : ""
                  }`}
              </LoadingButton>
              <Button
                sx={{
                  marginTop: "-24px",
                  maxWidth: "314px",
                  borderColor: "#C9C9CF !important"
                }}
                onClick={() => setOpenLogoutModal(true)}
                fullWidth
                variant="outlined"
                color="error"
              >
                Logout
              </Button>
              {/* )} */}
            </form>
          </FormProvider>
        </div>
      </UserSkeleton>

      <LogOutModal
        open={openLogoutModal}
        close={() => setOpenLogoutModal(false)}
        onLogout={() => logOutHandler({ type: "user" })}
        // desc="You did not finish setting up your profile, your information is saved already, you will continue from where you stopped when you log back in."
      />
    </>
  );
};

export default UserSelectUser;
