import "./loader.scss";
import Image1 from "../../assets/preloader-images/img1.png";
import Image2 from "../../assets/preloader-images/img2.png";
import Image3 from "../../assets/preloader-images/img3.png";
import Image4 from "../../assets/preloader-images/img4.png";
import Image5 from "../../assets/preloader-images/img5.png";
import Image6 from "../../assets/preloader-images/img6.png";
import Image7 from "../../assets/preloader-images/img7.png";

import {useEffect, useState} from "react";

const Loader = ({bg, className = ""}) => {
  //   const [index, setIndex] = useState(0);
  const imageArr = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];

  const [currentImage, setCurrentImage] = useState(Image1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage(imageArr[Math.floor(Math.random() * imageArr.length)]);
    }, 1500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={`loader-container ${className} ${bg && bg} `}>
      <div className="content">
        <div className="right-shape"></div>
        <div className="left-shape">
          <img src={currentImage} alt="img" />
        </div>
      </div>
      <div className="text">
        <h2>Loading...</h2>
      </div>
    </div>
  );
};
export default Loader;
