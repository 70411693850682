const MessageChatIcon = ({stroke, ...props}) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.34436 10.2288C5.28221 9.82823 5.24996 9.41786 5.24996 9C5.24996 4.58172 8.85525 1 13.3026 1C17.7499 1 21.3552 4.58172 21.3552 9C21.3552 9.99807 21.1713 10.9535 20.8352 11.8345C20.7654 12.0175 20.7304 12.109 20.7146 12.1804C20.6989 12.2512 20.6928 12.301 20.6911 12.3735C20.6894 12.4466 20.6993 12.5272 20.7192 12.6883L21.1217 15.9585C21.1653 16.3125 21.1871 16.4895 21.1282 16.6182C21.0766 16.731 20.985 16.8205 20.8711 16.8695C20.7411 16.9254 20.5646 16.8995 20.2117 16.8478L17.0265 16.3809C16.8601 16.3565 16.777 16.3443 16.7012 16.3448C16.6263 16.3452 16.5745 16.3507 16.5011 16.3661C16.427 16.3817 16.3323 16.4172 16.143 16.4881C15.2597 16.819 14.3024 17 13.3026 17C12.8844 17 12.4737 16.9683 12.0727 16.9073M6.88158 21C9.84648 21 12.25 18.5376 12.25 15.5C12.25 12.4624 9.84648 10 6.88158 10C3.91668 10 1.51316 12.4624 1.51316 15.5C1.51316 16.1106 1.61028 16.6979 1.78955 17.2467C1.86533 17.4787 1.90322 17.5947 1.91566 17.6739C1.92864 17.7567 1.93091 17.8031 1.92608 17.8867C1.92145 17.9668 1.90141 18.0573 1.86134 18.2383L1.25 21L4.2448 20.591C4.40827 20.5687 4.49 20.5575 4.56137 20.558C4.63652 20.5585 4.67641 20.5626 4.75011 20.5773C4.8201 20.5912 4.92416 20.6279 5.13227 20.7014C5.68059 20.8949 6.26911 21 6.88158 21Z"
        stroke={stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MessageChatIcon;
