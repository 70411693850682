import {useParams} from "react-router-dom";
import EachEngagement from "pages/Clients/Engagements/EachEngagements/EachEngagement";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import {useGetOneEngagementQuery} from "services/admin/adminGeneralApi";

const AdminEachEngagement = () => {
  const {id} = useParams();
  const {data, isLoading, error} = useGetOneEngagementQuery(id);
  return (
    <DashboardLayout
      isLoading={isLoading}
      error={error}
      title="Engagements"
      type="admin"
    >
      <EachEngagement data={data?.data} isAdmin />
    </DashboardLayout>
  );
};
export default AdminEachEngagement;
