import { Avatar, Button, IconButton } from "@mui/material";
import SidebarModal from "components/mui-components/Modal/SidebarModal";
import { useGetUser } from "hooks/getUserHook";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toggle, toggleState } from "redux/slice/userModalSlice";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import DropDownWrapper from "components/mui-components/DropDownWrapper";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useGetAllAdminNotificationQuery } from "services/admin/adminGeneralApi";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";

const NavLinks = () => {
  const { isLoggedIn, user } = useGetUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleVal = useSelector(toggleState);

  const handleClick = () => {
    dispatch(toggle(false));
  };
  const { data, refetch } = useGetAllAdminNotificationQuery();
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("admin-in-app-notification-received");
    channel.bind("admin-in-app-notification-event", () => {
      refetch();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [refetch]);

  return (
    <div className="flex flex-col items-center font-Bai text-nav-text lg:flex-row">
      {!isLoggedIn && (
        <ul className="flex flex-col items-center gap-5 border-r-primary lg:flex-row">
          <li>
            <Link to={"/jobs"}>Jobs</Link>
          </li>
          <li>
            <Link to={"/categories"}>{"Categories"}</Link>
          </li>
          <li>
            <Link to={"/about-us"}>About Us</Link>
          </li>
          <li>
            <DropDownWrapper
              action={
                <span className="flex items-center gap-1">
                  How it Works
                  <KeyboardArrowDown />
                </span>
              }
            >
              <div className="flex flex-col gap-4 p-2 py-3">
                <Link to="/how-it-works/service-providers">
                  For service providers
                </Link>
                <Link to="/how-it-works/clients">For clients</Link>
              </div>
            </DropDownWrapper>
          </li>
        </ul>
      )}
      <ul className="mt-5 flex flex-col items-center gap-5 pl-5 text-primary lg:mt-0 lg:ml-5 lg:flex-row lg:border-l">
        {isLoggedIn ? (
          <>
            <IconButton
              component={Link}
              to={`/admin/notifications`}
              sx={{
                width: "45px",
                height: "45px",
                bgcolor: "#fff",
                position: "relative",
              }}
            >
              <NotificationsNoneOutlinedIcon
                size="large"
                sx={{ color: "#000" }}
              />
              {data?.unread_notifications > 0 && (
                <span className="absolute right-3 top-1 h-5 w-5 rounded-full bg-primary pt-0.5 text-center text-xs text-white lg:right-1.5 lg:top-0.5">
                  {data?.unread_notifications}
                </span>
              )}
            </IconButton>
            <div className="flex flex-col items-center gap-y-1">
              <span className="font-medium text-black">
                {user?.full_name || "N/A"}
              </span>
              <span className="rounded-md bg-grey_80 py-1 px-2 text-[13px] text-grey_30">
                {user?.roles.length > 0 ? user?.roles[0].name : "N/A"}
              </span>
            </div>
            <IconButton onClick={handleClick} sx={{ position: "relative" }}>
              <Avatar
                src={user["user_type"] ? user?.avatar : user?.photo_url}
                alt="profile"
              />
              <span className="absolute bottom-3.5 right-2 h-3 w-3 rounded-full border-2 border-grey_80 bg-green-600"></span>
            </IconButton>
          </>
        ) : (
          <>
            <li>
              <Link to="/user/login">Log In</Link>
            </li>
            <li>
              <Link to="/user/create-account">Sign Up</Link>
            </li>
          </>
        )}
        {!isLoggedIn && (
          <li>
            <Button variant="contained" onClick={() => navigate("/user/login")}>
              Provide Services
            </Button>
          </li>
        )}
      </ul>
      <SidebarModal openModal={toggleVal} closeModal={handleClick} />
    </div>
  );
};

export default NavLinks;

const Btn = () => {
  const { isLoggedIn } = useGetUser();
  const navigate = useNavigate();
  return (
    <Button
      variant="outlined"
      sx={{ minWidth: '108px' }}
      onClick={() => navigate(isLoggedIn ? "/admin" : "/client/post-job")}
    >
      {isLoggedIn ? "Go to Dashboard" : "Post a Job"}
    </Button>
  );
};


export const NavLinksMobile = () => {
  const navigate = useNavigate();
  const [showHowItWorks, setShowHowItWorks] = useState(false)

  return (
    <div className="flex px-4 md:px-6 flex-col  mt-28">
      <ul className="font-medium font-Bai text-2xl text-[#4A5168]">
        <li className='py-5 border-b hover:bg-main-bg'>
          <Link to={"/about-us"}>About Us</Link>
        </li>
        <li className=' border-b'>
          <span
            className="flex justify-between py-5 items-center hover:bg-main-bg cursor-pointer"
            onClick={()=> setShowHowItWorks(!showHowItWorks)}
          >
            How it Works

            {showHowItWorks ?
              <KeyboardArrowUp fontSize='large' />
              :
              <KeyboardArrowDown fontSize='large' />
            }
          </span>

          {showHowItWorks &&
            <span className="transition-all ease-in-out duration-200 text-xl flex flex-col gap-5 px-3 py-5">
              <Link to="/how-it-works/service-providers">
                For service providers
              </Link>
              <Link to="/how-it-works/clients">For clients</Link>
            </span>
          }
        </li>
        <li className='py-5 border-b hover:bg-main-bg'>
          <Link to={"/jobs"}>Jobs</Link>
        </li>
        <li className='py-5 hover:bg-main-bg'>
          <Link to={"/categories"}>{"Categories"}</Link>
        </li>
      </ul>

      <div className="flex self-center gap-3 mt-10 items-center">
        <Button variant="contained" onClick={() => navigate("/user/login")}>
          Provide Services
        </Button>

        <Btn />
      </div>

      <div className="mt-14 gap-20 text-primary text-base font-Bai font-medium self-center flex items-center">
        <Link to="/user/login">Log In</Link>
        <Link to="/user/create-account">Sign Up</Link>
      </div>
    </div>
  );
};
