import EmailLayout from "./widgets/EmailLayout";
import EmailTitle from "./widgets/EmailTitle";
import GetThingsDone from "./widgets/GetThingsDone";
import RegNotice from "./widgets/RegNotice";

const RegisteredAsSP = () => {
  const handleClick = () => {
    console.log("click");
  };

  return (
    <EmailLayout>
      <EmailTitle title='Welcome to Truelocal' hasDecoration />
      <RegNotice userType="Service Provider" onClick={handleClick} />
      <GetThingsDone />
    </EmailLayout>
  );
};

export default RegisteredAsSP;
