import {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import TransactionDetailModal from "./TransactionDetailModal";
import {Button, Chip} from "@mui/material";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import moment from "moment";
import MoneyAddIcon from "components/Vectors/MoneyAddIcon";
import {statusColor} from "./Earning";
const WithdrawalComponent = ({data, isLoading, error, isError}) => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const headCells = [
    {
      name: "amount",
      label: "Amount",
    },
    {
      name: "date",
      label: "Date",
    },
    {
      name: "description",
      label: "Description",
    },
    {
      name: "account_number",
      label: "Account Number",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: value => (
          <Chip
            sx={{borderRadius: "8px"}}
            color={statusColor[value.toLowerCase()]}
            label={value}
          />
        ),
      },
    },
    {
      name: "action",
      label: "",
      options: {
        customBodyRender: id => (
          <Button
            onClick={() => handleViewDetailClick(id)}
            endIcon={<EastOutlinedIcon size="small" />}
          >
            View Details
          </Button>
        ),
      },
    },
  ];
  const mockData = data?.map(item => ({
    amount: item.amount,
    date: moment(item.request_date_time).format("ll"),
    description: item.description,
    account_number: item.bank_account?.account_number,
    status: item.status,
    action: item.id,
  }));
  const selectedItem = data?.find(item => item.id === selectedId);
  const handleViewDetailClick = id => {
    setSelectedId(id);
    setShowDetailModal(true);
  };
  return (
    <>
      <MyDataTable
        headCells={headCells}
        data={mockData}
        emptyData={{
          message: (
            <div className="flex flex-col items-center gap-y-2 font-Bai text-4xl font-semibold">
              No Withdrawal History
              <span className="font-Mulish text-lg text-grey_30">
                Withdrawals will be updated on this page.
              </span>
            </div>
          ),
          icon: MoneyAddIcon,
        }}
        isLoading={isLoading}
        isError={isError}
        error={error}
        options={{
          selectableRows: "none",
          search: false,
        }}
      />

      <TransactionDetailModal
        open={showDetailModal}
        close={() => setShowDetailModal(false)}
        transaction={selectedItem}
      />
    </>
  );
};

export default WithdrawalComponent;
