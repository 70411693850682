import React from "react";

function MoneyAddIcon({ width, height, color }) {
  return (
    <>
      <svg
        width={width || "24"}
        height={height || "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 15.25C10.21 15.25 8.75 13.79 8.75 12C8.75 10.21 10.21 8.75 12 8.75C13.79 8.75 15.25 10.21 15.25 12C15.25 13.79 13.79 15.25 12 15.25ZM12 10.25C11.04 10.25 10.25 11.04 10.25 12C10.25 12.96 11.04 13.75 12 13.75C12.96 13.75 13.75 12.96 13.75 12C13.75 11.04 12.96 10.25 12 10.25Z"
          fill={color || "#E98100"}
        />
        <path
          d="M18.5 15.25C18.09 15.25 17.75 14.91 17.75 14.5V9.5C17.75 9.09 18.09 8.75 18.5 8.75C18.91 8.75 19.25 9.09 19.25 9.5V14.5C19.25 14.91 18.91 15.25 18.5 15.25Z"
          fill={color || "#E98100"}
        />
        <path
          d="M5 22.75C3.34 22.75 1.78 21.87 0.940002 20.44C0.490002 19.72 0.25 18.87 0.25 18C0.25 15.38 2.38 13.25 5 13.25C7.62 13.25 9.75 15.38 9.75 18C9.75 18.87 9.51 19.72 9.06 20.45C8.22 21.87 6.66 22.75 5 22.75ZM5 14.75C3.21 14.75 1.75 16.21 1.75 18C1.75 18.59 1.91 19.17 2.22 19.67C2.8 20.65 3.87 21.25 5 21.25C6.13 21.25 7.2 20.65 7.78 19.68C8.09 19.17 8.25 18.6 8.25 18C8.25 16.21 6.79 14.75 5 14.75Z"
          fill={color || "#E98100"}
        />
        <path
          d="M6.49976 18.7305H3.50977C3.09977 18.7305 2.75977 18.3905 2.75977 17.9805C2.75977 17.5705 3.09977 17.2305 3.50977 17.2305H6.49976C6.90976 17.2305 7.24976 17.5705 7.24976 17.9805C7.24976 18.3905 6.90976 18.7305 6.49976 18.7305Z"
          fill={color || "#E98100"}
        />
        <path
          d="M5 20.2595C4.59 20.2595 4.25 19.9195 4.25 19.5095V16.5195C4.25 16.1095 4.59 15.7695 5 15.7695C5.41 15.7695 5.75 16.1095 5.75 16.5195V19.5095C5.75 19.9295 5.41 20.2595 5 20.2595Z"
          fill={color || "#E98100"}
        />
        <path
          d="M17 20.75H8.5C8.09 20.75 7.75 20.41 7.75 20C7.75 19.59 8.09 19.25 8.5 19.25H17C19.86 19.25 21.25 17.86 21.25 15V9C21.25 6.14 19.86 4.75 17 4.75H7C4.14 4.75 2.75 6.14 2.75 9V15.3C2.75 15.71 2.41 16.05 2 16.05C1.59 16.05 1.25 15.71 1.25 15.3V9C1.25 5.35 3.35 3.25 7 3.25H17C20.65 3.25 22.75 5.35 22.75 9V15C22.75 18.65 20.65 20.75 17 20.75Z"
          fill={color || "#E98100"}
        />
      </svg>
    </>
  );
}

export default MoneyAddIcon;
