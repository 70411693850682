import SingleEngagement from "pages/Clients/Dashboard/SingleEngagement";
import EmptyResponse from "components/mui-components/EmptyResponse";
import EngagementIcon from "components/Vectors/EngagementIcon";
import {useState} from "react";
import {useGetAllEngagementByStatusQuery} from "services/user/userRegApi";
import ErrorMsg from "components/mui-components/ErrorMsg";
import CircularLoader from "components/Loader/CircularLoader";
import TablePagination from "components/mui-components/TablePagination";

const EngagementTab = ({status, type}) => {
  const [rowPerPage, setRowPerPage] = useState(15);
  const [page, setPage] = useState(1);

  const {
    data: engagements,
    isLoading,
    isError,
    error,
  } = useGetAllEngagementByStatusQuery({
    type,
    status,
    params: {
      is_paginated: "yes",
      page,
      per_page: rowPerPage,
    },
  });

  if (isLoading) return <CircularLoader />;
  if (isError) return <ErrorMsg error={error} />;
  if (engagements?.data?.length === 0)
    return (
      <EmptyResponse
        message={`No ${status} Engagements yet`}
        icon={<EngagementIcon />}
      />
    );
  return (
    <>
      <div className="flex flex-col">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          {engagements?.data?.map(engagement => (
            <SingleEngagement
              key={engagement?.created_at}
              engagement={engagement}
            />
          ))}
        </div>
      </div>
      {!isLoading && (
        <div className="mt-4">
          {engagements?.data?.length > 0 && (
            <TablePagination
              meta={{
                totalPages: engagements?.last_page,
                totalCount: engagements?.total,
                currentPage: engagements?.current_page,
              }}
              from={engagements?.from}
              to={engagements?.to}
              page={page}
              setPage={setPage}
              perPage={rowPerPage}
              setPerPage={setRowPerPage}
            />
          )}
        </div>
      )}
    </>
  );
};
export default EngagementTab;
