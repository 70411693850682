import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { Button, Chip } from "@mui/material";
import UserAvatar from "components/mui-components/TableComponent/UserAvatar";
import LocationIcon from "components/Vectors/LocationIcon";
import StarFilledIcon from "components/Vectors/StarFilledIcon";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Link } from "react-router-dom";

const defaultUser = {
  avatar: "/images/service_banner.png",
  full_name: "Benjamin Lokwaje",
  username: "Mirian",
  average_rating: 4.0,
  location: "Ketu, Lagos",
  id: "123",
  user_type: "Client",
};
function UserProfileCard({
  user = defaultUser,
  badge = false,
  className = "bg-[#F2F4F7]",
  link,
  isUsername = true,
  isAdmin = true,
}) {
  const { avatar, full_name, location, id, username, user_type } = user;
  const linkTo = !isAdmin
    ? link || `/user-profile/${id}?type=${user?.user_type}`
    : user_type.toLowerCase() === "client"
      ? `/admin/clients/${id}`
      : `/admin/service-providers/${id}`;
  return (
    <div
      className={`flex min-h-[165px] flex-col justify-between rounded-20  p-4 md:p-6 ${className}`}
    >
      <Chip
        label={user_type}
        color="secondaryInverse"
        sx={{ borderRadius: "8px", fontWeight: 700, width: "fit-content" }}
      />
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <UserAvatar
            size={isAdmin ? 54 : 64}
            showBadge={badge}
            src={
              user_type.toLowerCase() === "client"
                ? avatar
                : user?.professional_information?.business_logo || avatar
            }
            toHref={linkTo}
          />
          <div>
            <div className="flex justify-start gap-x-1">
              <p className="min-w-fit text-base font-bold text-grey_20">
                {user_type.toLowerCase() === "client"
                  ? full_name
                  : user?.professional_information?.business_name || full_name}
              </p>{" "}
              {user?.is_top_vetted &&
                user?.user_type.toLowerCase() === "service provider" && (
                  <Chip
                    avatar={<StarRoundedIcon />}
                    color="secondaryInverse"
                    label="Top Vetted"
                    sx={{
                      fontSize: "10px",
                      border: "1px solid #5010B3",
                      "& .MuiChip-avatar": {
                        color: "#5010B3",
                        fontSize: "11px",
                      },
                    }}
                    size="small"
                  />
                )}
            </div>
            <p className="text-sm text-grey_30 ">
              {isUsername ? `${username ? "@" + username : "N/A"}` : username}
            </p>
          </div>
        </div>
        <p
          className={`text-prop flex items-center gap-1 rounded-10 bg-white p-1 font-medium text-[#E98100] ${isAdmin ? "text-xs" : ""
            }`}
        >
          <span>
            <StarFilledIcon height="16" width="17" color="#E98100" />
          </span>
          <span>{user?.average_rating?.toFixed(1)}</span>
        </p>
      </div>
      <div className="mt-2 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <LocationIcon color="black" />
          <span>{location || "N/A"}</span>
        </div>
        <Button
          component={Link}
          to={linkTo}
          sx={{ p: "0.5rem !important" }}
          endIcon={<EastRoundedIcon />}
        >
          View Profile
        </Button>
      </div>
    </div>
  );
}

export default UserProfileCard;
