import { Button } from "@mui/material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import EmptyResponse from "components/mui-components/EmptyResponse";
import EachPayoutRequest from "../widgets/EachPayoutRequest";
import CoinHandIcon from "components/Vectors/CoinHandIcon";
import { useGetPayoutListQuery } from "services/admin/adminGeneralApi";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";
import { useState } from "react";
import RequestDetailsModal from "../../Transactions/widgets/RequestDetailsModal";
import { Link } from "react-router-dom";

const PayoutRequest = props => {
  const [openModal, setopenModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);

  const { data, isLoading, error, isError } = useGetPayoutListQuery({
    is_paginated: "yes", per_page: 15
  });
  return (
    <>
      <div className="heading mb-4 flex items-center justify-between">
        <h3 className="font-Bai text-base font-medium text-grey_10 sm:text-2xl">
          Payout Request
        </h3>
        <Button
          variant="text"
          component={Link}
          to={"/admin/transactions?tab=payouts"}
          endIcon={<ArrowForwardOutlinedIcon color="primary" />}
        >
          View all
        </Button>
      </div>

      <div className="noScroll flex h-full max-h-[569px] flex-col gap-4 overflow-y-auto rounded-2xl bg-white p-4">
        {isLoading ? (
          <CircularLoader />
        ) : isError ? (
          <ErrorMsg error={error} />
        ) : data?.data?.length >= 0 ? (
          data?.data?.slice(0, 10).map((item, i) => (
            <EachPayoutRequest
              onClick={() => {
                setopenModal(true);
                setselectedItem(item);
              }}
              item={item}
              key={`payout_${item?.id}`}
            />
          ))
        ) : (
          <EmptyResponse
            icon={<CoinHandIcon />}
            message={"No payout request"}
          />
        )}
      </div>
      <RequestDetailsModal
        openModal={openModal}
        setopenModal={setopenModal}
        request={selectedItem}
      />
    </>
  );
};

export default PayoutRequest;
