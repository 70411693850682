import {Avatar, Button, Chip, CircularProgress} from "@mui/material";
import EditIcon from "components/Vectors/EditIcon";
import {useNavigate} from "react-router-dom";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import moment from "moment";
import {
  useGetAdminProfileQuery,
  useUpdatePasswordMutation,
} from "services/admin/adminProfileApi";
import {FormProvider, useForm} from "react-hook-form";
import ValidatedInput from "components/forms/ValidatedInput";
import {LoadingButton} from "@mui/lab";
import {useDispatch} from "react-redux";
import {logOut} from "redux/slice/AuthSlice";
import {handleError} from "utils";
import {showToast} from "redux/store.hooks";
import {useGetUser} from "hooks/getUserHook";

function AdminProfile() {
  const [updatePassword, {isLoading: isUpdatePasswordLoading}] =
    useUpdatePasswordMutation();
  const dispatch = useDispatch();
  const {handleSubmit, getValues, ...methods} = useForm();
  const {data} = useGetAdminProfileQuery();
  const navigate = useNavigate();
  const {user: storeUser} = useGetUser();
  const user = data?.data || storeUser;

  const onSubmit = async data => {
    try {
      const res = await updatePassword(data).unwrap();
      showToast(res?.message, "success");
      dispatch(logOut({redirect: false, type: "admin"}));
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <DashboardLayout title={"Account"} type="admin">
      <main className="rounded-2xl bg-white p-4 sm:p-8">
        <div className="mb-10 flex justify-between">
          <div className="flex gap-4">
            <Avatar
              src={user?.photo_url}
              alt={user?.first_name}
              sx={{
                height: "120px",
                width: "120px",
              }}
            />
            <div className="mt-3 w-full">
              <h3 className="mb-2 font-Bai text-[32px] font-semibold capitalize text-black">
                {user?.first_name + " " + user?.last_name || "N/A "}
              </h3>
              {/* <p className="font-medium text-grey_20 text-lg">@ladyelon</p> */}
              <div className="mt-4 flex items-center gap-3">
                <Chip
                  sx={{borderRadius: "8px"}}
                  color="secondaryInverse"
                  label={user?.roles[0]?.name || "N/A"}
                />
                <p className="text-sm font-normal ">
                  <span className="text-grey_30">Date added: </span>{" "}
                  <span className="text-grey">
                    {moment(user?.created_at).format("LL")}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-3 hidden sm:block">
            <Button
              variant="contained"
              sx={{fontSize: "16px", width: "171px"}}
              startIcon={<EditIcon color="white" />}
              onClick={() => navigate("/admin/edit-profile")}
            >
              Edit Profile
            </Button>
          </div>
        </div>

        <div className="w-full md:w-1/2">
          <div className="rounded-2xl bg-grey_80 p-4 md:p-6">
            <h3 className="mb-8 text-2xl font-semibold text-grey">Overview</h3>

            <div className="mt-2">
              <p className="mb-0 text-lg font-medium text-grey_30">
                Email Address
              </p>
              <p className="my-2 text-lg font-medium text-[#000000]">
                {user?.email}
              </p>
            </div>

            <div className="mt-6">
              <p className="mb-0 text-lg font-medium text-grey_30">Phone</p>
              <p className="my-2 text-lg font-medium text-[#000000]">
                {user?.phone_number}
              </p>
            </div>
            <div className="mt-6">
              <p className="mb-0 text-lg font-medium text-grey_30">Gender</p>
              <p className="my-2 text-lg font-medium capitalize text-[#000000]">
                {user?.gender}
              </p>
            </div>
          </div>

          <div className="mt-7 rounded-2xl bg-grey_80 p-4 md:p-6">
            <h3 className="mb-8 text-2xl font-semibold text-grey">
              Change Password
            </h3>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                <ValidatedInput
                  bg="white"
                  name="old_password"
                  textSize="text-sm"
                  label="Current Password"
                  placeholder="Enter your current password"
                  type="password"
                />

                <ValidatedInput
                  bg="white"
                  name="password"
                  textSize="text-sm"
                  label="New Password"
                  placeholder="Create New Password"
                  type="password"
                />
                <ValidatedInput
                  bg="white"
                  name="password_confirmation"
                  textSize="text-sm"
                  label="Confirm Password"
                  type="password"
                  placeholder="Enter your new password"
                  rules={{
                    validate: value =>
                      value === getValues("password") ||
                      "Passwords do not match",
                  }}
                />
                <LoadingButton
                  variant="contained"
                  sx={{width: "171px", mt: 5}}
                  type="submit"
                  loading={isUpdatePasswordLoading}
                  loadingIndicator={
                    <CircularProgress size={24} sx={{color: "#FFF"}} />
                  }
                >
                  Change Password
                </LoadingButton>
              </form>
            </FormProvider>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
}

export default AdminProfile;
