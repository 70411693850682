import MyAccordion from "components/mui-components/Accordion";
import ServicesIcon from "components/Vectors/ServicesIcon";
import ServiceDetailsTab from "pages/ServiceProviders/Profile/Tabs/ServiceDetailsTab";

const Services = ({services = []}) => {
  return (
    <div>
      <h4 className="mt-6 mb-3 font-Bai text-3xl font-semibold">Services</h4>
      {services?.length > 0 ? (
        <MyAccordion
          data={services?.map(item => ({
            title: item?.category.name,
            content: <ServiceDetailsTab service={item} showCta={false} />,
          }))}
        />
      ) : (
        <div className="relative mb-8 flex h-[300px] w-full flex-col items-center justify-center rounded-2xl bg-grey_80">
          <ServicesIcon color="#6E726E" width="53.33px" height="53.33px" />
          <p className="mt-4 max-w-[217px] text-center font-Bai text-lg font-semibold text-grey_30">
            No Service uploaded yet
          </p>
        </div>
      )}
    </div>
  );
};

export default Services;
