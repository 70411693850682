import CreationCompletedIcon from "components/Vectors/CreationCompletedIcon";
import {Link} from "react-router-dom";
import {UserSkeleton} from "..";

const CreationCompleted = ({isProvider}) => {
  return (
    <UserSkeleton className="bg-white md:bg-grey_100">
      <div
        className="flex min-h-[280px] w-full max-w-[600px] flex-col items-center justify-center
        gap-3 rounded-2xl p-4 py-6 md:h-[450px] md:gap-5 md:bg-grey_90 lg:rounded-[35px]"
      >
        <CreationCompletedIcon />
        <p className="font-Bai text-3xl font-semibold leading-[60px] tracking-[-4%] md:text-4xl">
          You’re all set 🎉
        </p>

        <Link
          to={isProvider ? "/service-provider" : "/client"}
          className="text-prop w-[80%] rounded-16 bg-primary py-4 text-center font-Mulish text-base font-medium text-white"
        >
          Proceed to Dashboard
        </Link>
      </div>
    </UserSkeleton>
  );
};
export default CreationCompleted;
