import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

const Title = ({titleProp}) => {
  const location = useLocation();
  const {pathname} = location;
  const title = pathname.split("/")[1].split("-").join(" ");
  const capitalize = str => {
    return str
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Helmet>
      <title>{capitalize(titleProp) || capitalize(title)} | Truelocal</title>
    </Helmet>
  );
};

export default Title;
