import decorationBg from "assets/images/decoration.png";

const EmailTitle = ({title, hasDecoration}) => {
  return (
    <p
      style={{backgroundImage: hasDecoration ? `url('${decorationBg}')` : ""}}
      className="flex h-[150px] items-center justify-center rounded-[17px] bg-[#FCFCFD] font-Bai text-2xl font-bold text-grey_10"
    >
      {title}
    </p>
  );
};

export default EmailTitle;
