import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import {useGetAdminSubscriptionsQuery} from "services/admin/adminGeneralApi";
import {toCurrency} from "utils";
import moment from "moment";

const SubscriptionsTab = props => {
  const {data, isLoading, error, isError} = useGetAdminSubscriptionsQuery();

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
  };

  // mock data to be updated by api
  const mockData = data?.data?.map((item, i) => ({
    service_provider: {
      name: item?.user?.full_name,
      avatar: item?.user?.avatar,
      searchBy: item?.user?.username,
    },
    amount: toCurrency(Number(item?.amount)),
    source: (
      <p className="capitalize">
        {item?.source === "purchased" ? item?.package?.name : item?.source}
      </p>
    ),
    vat: "₦15,000",
    date: moment(item?.created_at).format("DD MMM YYYY h:mm a"),
    trasaction_ref: "123934821",
  }));

  return (
    <div>
      <div className=" w-full ">
        <MyDataTable
          scrollX={false}
          data={mockData}
          headCells={headCells}
          options={options}
          isLoading={isLoading}
          error={error}
          isError={isError}
        />
      </div>
    </div>
  );
};

export default SubscriptionsTab;

const headCells = [
  {
    name: "service_provider",
    label: "service provider",
    options: {
      customBodyRender: value => (
        <UserDisplay
          // imgRounded={false}
          name={value.name}
          img={value.avatar}
          size="text-[1rem]"
        />
      ),
    },
  },
  {
    name: "amount",
    label: "amount",
  },
  {
    name: "source",
    label: "source",
  },
  {
    name: "date",
    label: "date purchased",
  },
  // {
  //   name: "vat",
  //   label: "VAT",
  // },

  // {
  //   name: "trasaction_ref",
  //   label: "trasaction ref",
  // },
  // {
  //   name: "status",
  //   label: "Status",
  //   options: {
  //     customBodyRender: value => (
  //       <Chip
  //         sx={{borderRadius: "8px", paddingLeft: "0px !important"}}
  //         color={"successInverse"}
  //         label={value}
  //       />
  //     ),
  //   },
  // },
];
