import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@mui/material";
import JobIcon from "components/Vectors/JobIcon";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useGetJobsStatQuery} from "services/admin/adminGeneralApi";

import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";
import AllJobs from "./Tabs/AllJobs";

const AdminJobs = () => {
  const [activeTab, setactiveTab] = useState("open");
  const navigate = useNavigate();

  const {data: jobsStatsData} = useGetJobsStatQuery();
  const jobStat = jobsStatsData?.data;
  return (
    <DashboardLayout title={"Jobs"} type="admin">
      <main>
        <div className="rounded-2xl bg-white p-4 md:py-6">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="font-Bai text-2xl font-medium text-black">
              Overview
            </h3>
          </div>

          <div className=" grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
            <DashboardBox
              text={"Open Jobs"}
              icon={<JobIcon height="20" width="16" stroke={"#E98100"} />}
              value={jobStat?.active_jobs_count || "0"}
            />
            <DashboardBox
              text={"Closed Jobs"}
              icon={<JobIcon height="20" width="16" stroke={"#039855"} />}
              value={jobStat?.closed_jobs_count || "0"}
            />
            <DashboardBox
              text={"Drafted Jobs"}
              icon={<JobIcon height="20" width="16" stroke={"#D92D20"} />}
              value={jobStat?.drafted_jobs_count || "0"}
            />
            <DashboardBox
              text={"Suspended Jobs"}
              icon={<JobIcon height="20" width="16" stroke={"#6E726E"} />}
              value={jobStat?.suspended_jobs_count || "0"}
            />
          </div>
        </div>

        <div className="mt-6 rounded-2xl bg-white p-4 md:py-6">
          <TabContext value={activeTab}>
            <TabList
              variant="scrollable"
              value={activeTab}
              className="mb-4 w-fit rounded-[16px] border border-grey_60 bg-grey_80 py-2"
              onChange={(_, newValue) => {
                navigate(`?tab=${newValue}`);
                setactiveTab(newValue);
              }}
              aria-label="Jobs tab"
            >
              <Tab label="Open Jobs" value="open" />
              <Tab label="Closed Jobs" value="closed" />
              <Tab label="Drafted Jobs" value="drafted" />
              <Tab label="Suspended Jobs" value="suspended" />
            </TabList>
            <TabPanel value="open">
              <AllJobs status="Active" title="No Open job" />
            </TabPanel>
            <TabPanel value="closed">
              <AllJobs status="Closed" title="No closed job" />
            </TabPanel>
            <TabPanel value="drafted">
              <AllJobs status="Drafted" title="No drafted job" />
            </TabPanel>
            <TabPanel value="suspended">
              <AllJobs status="Suspended" title="No suspended job" />
            </TabPanel>
          </TabContext>
        </div>
      </main>
    </DashboardLayout>
  );
};
export default AdminJobs;
