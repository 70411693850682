import React from 'react'

const ContactUsIGIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12.0006 5.6C10.2624 5.6 10.0443 5.6076 9.36164 5.63867C8.6803 5.66987 8.21523 5.77773 7.80829 5.936C7.38735 6.09947 7.03028 6.31814 6.67454 6.67401C6.31854 7.02975 6.09987 7.38682 5.93587 7.80762C5.7772 8.21469 5.6692 8.6799 5.63853 9.36097C5.608 10.0436 5.6 10.2619 5.6 12.0001C5.6 13.7382 5.60773 13.9557 5.63867 14.6384C5.67 15.3197 5.77787 15.7848 5.936 16.1917C6.0996 16.6126 6.31827 16.9697 6.67414 17.3255C7.02975 17.6815 7.38682 17.9007 7.80749 18.0641C8.21469 18.2224 8.6799 18.3303 9.36111 18.3615C10.0438 18.3925 10.2618 18.4001 11.9998 18.4001C13.7381 18.4001 13.9556 18.3925 14.6382 18.3615C15.3196 18.3303 15.7852 18.2224 16.1924 18.0641C16.6132 17.9007 16.9697 17.6815 17.3253 17.3255C17.6813 16.9697 17.9 16.6126 18.064 16.1918C18.2213 15.7848 18.3293 15.3196 18.3613 14.6385C18.392 13.9558 18.4 13.7382 18.4 12.0001C18.4 10.2619 18.392 10.0438 18.3613 9.36111C18.3293 8.67977 18.2213 8.21469 18.064 7.80776C17.9 7.38682 17.6813 7.02975 17.3253 6.67401C16.9693 6.31801 16.6133 6.09934 16.192 5.936C15.784 5.77773 15.3186 5.66987 14.6373 5.63867C13.9546 5.6076 13.7373 5.6 11.9986 5.6H12.0006Z" fill="#E98100" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4265 6.75335C11.5969 6.75308 11.787 6.75335 12.0006 6.75335C13.7094 6.75335 13.912 6.75948 14.5868 6.79015C15.2108 6.81868 15.5494 6.92295 15.775 7.01055C16.0737 7.12655 16.2866 7.26522 16.5105 7.48922C16.7345 7.71322 16.8732 7.92656 16.9895 8.22523C17.0771 8.45056 17.1815 8.78923 17.2099 9.41324C17.2405 10.0879 17.2472 10.2906 17.2472 11.9986C17.2472 13.7066 17.2405 13.9093 17.2099 14.584C17.1813 15.208 17.0771 15.5466 16.9895 15.772C16.8735 16.0706 16.7345 16.2833 16.5105 16.5072C16.2865 16.7312 16.0738 16.8699 15.775 16.9859C15.5497 17.0739 15.2108 17.1779 14.5868 17.2064C13.9121 17.2371 13.7094 17.2437 12.0006 17.2437C10.2916 17.2437 10.0891 17.2371 9.41444 17.2064C8.79043 17.1776 8.45176 17.0733 8.22603 16.9857C7.92736 16.8697 7.71402 16.731 7.49002 16.507C7.26602 16.283 7.12735 16.0702 7.01108 15.7714C6.92348 15.5461 6.81908 15.2074 6.79068 14.5834C6.76001 13.9088 6.75388 13.7061 6.75388 11.997C6.75388 10.2879 6.76001 10.0863 6.79068 9.41164C6.81921 8.78763 6.92348 8.44896 7.01108 8.22336C7.12708 7.92469 7.26602 7.71136 7.49002 7.48735C7.71402 7.26335 7.92736 7.12468 8.22603 7.00841C8.45163 6.92041 8.79043 6.81641 9.41444 6.78775C10.0048 6.76108 10.2336 6.75308 11.4265 6.75175V6.75335ZM15.4169 7.81602C14.9929 7.81602 14.6489 8.15963 14.6489 8.58376C14.6489 9.00777 14.9929 9.35177 15.4169 9.35177C15.8409 9.35177 16.1849 9.00777 16.1849 8.58376C16.1849 8.15976 15.8409 7.81602 15.4169 7.81602ZM12.0006 8.71337C10.1855 8.71337 8.7139 10.185 8.7139 12.0001C8.7139 13.8152 10.1855 15.2861 12.0006 15.2861C13.8157 15.2861 15.2868 13.8152 15.2868 12.0001C15.2868 10.185 13.8157 8.71337 12.0006 8.71337Z" fill="#E98100" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0006 9.86671C13.1787 9.86671 14.134 10.8218 14.134 12.0001C14.134 13.1782 13.1787 14.1334 12.0006 14.1334C10.8223 14.1334 9.86724 13.1782 9.86724 12.0001C9.86724 10.8218 10.8223 9.86671 12.0006 9.86671Z" fill="#E98100" />
        </svg>

    )
}

export default ContactUsIGIcon