import {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {LoadingButton} from "@mui/lab";
import ValidatedRadioBtns from "components/forms/ValidatedRadioBtns";
import {
  useGetUserNotificationSettingQuery,
  useUpdateUserNotificationSettingMutation,
} from "services/user/userRegApi";
import {showToast} from "redux/store.hooks";
import {handleError} from "utils";

function NotificationsSettings() {
  const methods = useForm();
  const {data} = useGetUserNotificationSettingQuery();
  const [update, {isLoading: isUpdating}] =
    useUpdateUserNotificationSettingMutation();

  const {setValue} = methods;
  // update defaultvalue if page is reloaded
  useEffect(() => {
    const defaultValues = {
      new_message_option: data?.data?.message_received?.replace("-", "_"),
      new_service_option:
        data?.data?.service_added_to_followed_category?.replace("-", "_"),
    };
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [data?.data, setValue]);

  const onSubmit = async data => {
    try {
      const res = await update(data).unwrap();
      showToast(res?.message);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="rounded-24 bg-white px-6 py-6 md:px-10 md:py-10"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <h1 className="font-Bai text-3xl font-semibold text-grey_20">
          Notification
        </h1>

        <div className="mx-auto max-w-[440px]">
          <p className="my-8 text-xl font-medium text-grey_10">
            How do you want to receive notifications?
          </p>
          <div className="space-y-6">
            {/* <div className="font-Mulish">
              <p className="text-lg font-bold">New Messages</p>
              <p className="text-[#8585A3]">
                This is the notification you receive when you receive a new
                message
              </p>
              <ValidatedRadioBtns
                justifyBetween
                containerClass="py-1"
                name="new_message_option"
                required={false}
                options={[
                  {
                    label: "Both",
                    value: "both",
                  },
                  {
                    label: "In app",
                    value: "in_app",
                  },
                  {
                    label: "Email",
                    value: "email",
                  },
                ]}
              />
            </div> */}
            <div className="font-Mulish">
              <p className="text-lg font-bold">
                When Service is Posted in my Interest Category
              </p>
              <p className="text-[#8585A3]">
                This is the notification you receive when a service is posted in
                your category of interest
              </p>
              <ValidatedRadioBtns
                justifyBetween
                containerClass="py-1"
                name="new_service_option"
                required={false}
                options={[
                  {
                    label: "Both",
                    value: "both",
                  },
                  {
                    label: "In app",
                    value: "in_app",
                  },
                  {
                    label: "Email",
                    value: "email",
                  },
                  {
                    label: "Turn Off",
                    value: "off",
                  },
                ]}
              />
            </div>
          </div>
          <LoadingButton
            disabled={!methods.formState.isDirty}
            loading={isUpdating}
            type="submit"
            variant="contained"
            sx={{mt: 4}}
          >
            Save Changes
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
}

export default NotificationsSettings;
