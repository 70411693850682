import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as Logo} from "assets/images/logo.svg";
import NavLinks, {NavLinksMobile} from "./NavLinks";
import {useGetUser} from "hooks/getUserHook";
import {Button} from "@mui/material";

const Btn = () => {
  const {isLoggedIn} = useGetUser();
  const navigate = useNavigate();
  return (
    <Button
      variant="outlined"
      // sx={{width:'fit-content'}}
      onClick={() => navigate(isLoggedIn ? "/admin" : "/client/post-job")}
    >
      {isLoggedIn ? "Go to Dashboard" : "Post a Job"}
    </Button>
  );
};

const Nav = ({bgColor}) => {
  const [navOpen, setNavOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setNavOpen(false);
  }, [location]);

  return (
    <>
      <nav
        className={`${
          bgColor || ""
        } fixed top-0 z-[100] flex w-full items-center justify-between bg-white px-4 py-7 shadow md:px-6 lg:hidden`}
      >
        <div className="flex basis-full items-center justify-between lg:hidden">
          <Link className="z-20 " to={"/"}>
            <Logo />
          </Link>
          <button
            type="button"
            onClick={() => setNavOpen(open => !open)}
            aria-label="toggle mobile navigation menu"
            className="z-20 flex flex-col items-end transition-all duration-700 ease-in-out"
          >
            <span
              className={`my-1 h-px bg-[#3B3B3B] transition-all duration-300 ease-linear ${
                navOpen ? "w-8 rotate-45" : "w-9"
              }`}
            ></span>
            <span
              className={`my-1 h-px bg-[#3B3B3B] transition-all duration-300 ease-linear ${
                navOpen ? "relative -top-2 w-8 -rotate-45" : "w-6"
              }`}
            ></span>
          </button>
        </div>

        <div
          className={`fixed right-0 top-0 min-h-screen w-full flex-col  gap-x-6 bg-white text-sm ${
            navOpen ? "flex" : "hidden"
          }`}
        >
          <NavLinksMobile />
        </div>
      </nav>

      {/* DESKTOP NAV */}
      <nav
        className={`${
          bgColor || ""
        } fixed top-0 z-[100] hidden w-full items-center justify-between bg-white px-8 py-4 shadow transition-all duration-300 lg:flex lg:px-[4vw]`}
      >
        <div className="flex items-center gap-8">
          <Link to="/">
            <Logo />
          </Link>
          <Btn />
        </div>
        <NavLinks />
      </nav>
    </>
  );
};

export default Nav;
