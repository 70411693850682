import TicketsIcon from "components/Vectors/TicketIcon";

const EmptyTicket = () => {
  return (
    <div className="flex items-center justify-center py-20">
      <div className="flex flex-col items-center justify-center">
        <div>
          <TicketsIcon color="#000" height="45" width="55" />
        </div>
        <h2 className="settings-font-h2 text-prop mt-6 !text-[32px]">
          No ticket received
        </h2>
        <p className="text-prop mt-2 text-base font-medium text-grey_30">
          You will be notified when you receive a request
        </p>
      </div>
    </div>
  );
};
export default EmptyTicket;
