import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUser, setUserDetails} from "redux/slice/AuthSlice";
import {useGetUserProfileQuery} from "services/user/userRegApi";

export const useGetUserRequest = (skip = false) => {
  const user = useSelector(selectCurrentUser);
  const {data, isLoading, isError, error, refetch} = useGetUserProfileQuery(
    "",
    {
      skip: !user?.user_type && skip,
    },
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && data?.data) {
      dispatch(setUserDetails(data.data));
    }
  }, [data, dispatch, isLoading]);
  return {data, isLoading, isError, error, refetch};
};
