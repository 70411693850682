import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import "components/forms/formInput.styles.scss";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import CreationCompletedIcon from "components/Vectors/CreationCompletedIcon";
import {UserSkeleton} from ".";
import {LoadingButton} from "@mui/lab";
import {CircularProgress} from "@mui/material";
import {useUserResetPwdMutation} from "services/auth.api";
import {showToast} from "redux/store.hooks";
import {handleError} from "utils";
import {useSelector} from "react-redux";
import {selectOTP} from "redux/slice/AuthSlice";

const UserResetPwd = () => {
  const navigate = useNavigate();
  const userOTP = useSelector(selectOTP);
  const [presentStep, setPresentStep] = useState(1);

  const [resetPwd, {isLoading}] = useUserResetPwdMutation();

  const methods = useForm({
    mode: "all",
  });

  const password = methods.watch("password");

  const onSubmit = async data => {
    const formData = new FormData();

    formData.append("otp", userOTP);
    formData.append("password", data.password);
    formData.append("password_confirmation", data.confirm_password);

    try {
      let res = await resetPwd(formData).unwrap();
      setPresentStep(3);
      showToast(res?.message, "success");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <UserSkeleton>
      {presentStep <= 1 ? (
        <div
          className="my-8 flex h-fit w-full max-w-fit flex-col items-center justify-center rounded-2xl bg-grey_90 p-5
        md:h-fit md:p-8 lg:my-16 lg:max-w-[550px] lg:rounded-[35px] lg:p-8 lg:px-16
        "
        >
          <p className="mt-8 font-Bai text-2xl font-semibold leading-[60px] tracking-[-4%] md:mt-[30px] md:text-4xl lg:mb-4">
            Reset Password
          </p>

          <FormProvider {...methods}>
            <form
              className="darkBgForm mt-[24px] flex w-full flex-col gap-[24px] "
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <ValidatedInput2
                label="Create new Password"
                placeholder="*******"
                type="password"
                name="password"
                showForgetPwd={false}
              />
              <ValidatedInput2
                label="Confirm Password"
                placeholder="*******"
                type="password"
                name="confirm_password"
                showForgetPwd={false}
                rules={{
                  validate: value =>
                    value === password || "Password does not match",
                }}
              />
              <div className="mt-4 md:mt-5">
                <LoadingButton
                  fullWidth
                  loadingIndicator={
                    <CircularProgress
                      sx={{
                        color: "#fff",
                      }}
                      size="1.2rem"
                    />
                  }
                  sx={{
                    fontFamily: "Bai Jamjuree",
                    borderRadius: "16px",
                    fontWeight: "500",
                    marginTop: "8px",
                  }}
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Proceed
                </LoadingButton>
                <button
                  onClick={() => navigate("/user/login")}
                  type="button"
                  className="mt-2 w-full py-5 font-Bai font-medium text-grey_20"
                >
                  Cancel
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      ) : (
        <div
          className="flex h-fit w-full max-w-fit flex-col items-center justify-center rounded-2xl bg-grey_90 p-5
        md:h-fit md:p-8 lg:rounded-[35px] lg:p-8 lg:pb-12
        "
        >
          <CreationCompletedIcon />
          <p className="mt-8 w-full max-w-[400px] text-center font-Bai text-4xl font-semibold leading-[52px] tracking-[-4%] md:mt-[30px]">
            Password Reset Successful
          </p>
          <button
            onClick={() => navigate("/user/login")}
            type="submit"
            className="mt-[40px] w-full rounded-[1rem] bg-primary py-[16px] font-Bai font-medium text-white "
          >
            Proceed to Log in
          </button>
        </div>
      )}
    </UserSkeleton>
  );
};
export default UserResetPwd;
