// import ReactJoyride from "react-joyride";
// import Main from "./Main";
// import { useGetUser } from "hooks/getUserHook";
// import { useHasVisitedLandingPageMutation } from 'services/user/userRegApi'
import NewMain from "./NewMain";

const ClientsDashBoard = () => {
  // const { user } = useGetUser();
  // const [markAsVisited] = useHasVisitedLandingPageMutation();

  // const handleJoyrideCallback = (data) => {
  //   const { action } = data;
  //   if (action === 'reset') markAsVisited().unwrap()
  // };

  return (
    <>
      {/* <ReactJoyride
      run={user?.has_visited_landing_page ? false : true}
      scrollToFirstStep={true}
      callback={handleJoyrideCallback}
      continuous={true}
      showProgress={true}
      locale={{ back: "Previous" }}
      spotlightClick={false}
      hideCloseButton={true}
      steps={[
        {
          content: (
            <div className="text-left">
              <p className="font-Bai text-[16px] font-semibold leading-[150%] text-grey_30">
                Dashboard
              </p>
              <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                You manage and an overview of your transactions here.
              </p>
              <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                Let's take you on a tour on how to manage your jobs.
              </p>
            </div>
          ),
          target: "body",
          placement: "center",
          // locale: {
          //   skip: <button onClick={() => {
          //     markAsVisited().unwrap()
          //   }}>Nevermind, Thanks</button>,
          //   next: <p>Begin Tour</p>,
          // },
          showProgress: false,
          hideCloseButton: true,
          showSkipButton: true,
        },
        {
          content: (
            <div className="text-left">
              <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                Create a Job
              </p>
              <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext ">
                Mauris pellentesque dictumst consectetur malesuada
                egestas augue feugiat ut. Varius neque amet quis est
                id tortor vitae vitae.
              </p>
            </div>
          ),
          target: '.client_dashboard_first',
          // locale: { next: <p>Next</p> },
          showProgress: true,
          hideBackButton: false,
        },
        {
          content: (
            <div className="text-left">
              <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                My Jobs and Engagements
              </p>
              <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext ">
                Mauris pellentesque dictumst consectetur malesuada
                egestas augue feugiat ut. Varius neque amet quis est
                id tortor vitae vitae.
              </p>
            </div>
          ),
          target: ".client_dashboard_second",
          locale: { next: <p>Next</p> },
          showProgress: true,
          hideBackButton: false,
        },
        {
          content: (
            <div className="text-left">
              <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                Notifications
              </p>
              <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext ">
                Mauris pellentesque dictumst consectetur malesuada
                egestas augue feugiat ut. Varius neque amet quis est
                id tortor vitae vitae.
              </p>
            </div>
          ),
          target: "a.client_dashboard_third",
          locale: { next: <p>Next</p> },
          showProgress: true,
          hideBackButton: false,
        },
        {
          content: (
            <div className="text-left">
              <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                Open Jobs and Closed Jobs
              </p>
              <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                Mauris pellentesque dictumst consectetur malesuada
                egestas augue feugiat ut. Varius neque amet quis est
                id tortor vitae vitae.
              </p>
            </div>
          ),
          target: "div.client_dashboard_fourth",
          locale: { next: <p>Next</p> },
          placement: "bottom",
          showProgress: true,
          hideBackButton: false,
        },
        {
          content: (
            <div className="text-left">
              <p className="font-Bai text-[24px] font-semibold leading-[134%] text-grey_10">
                More menu
              </p>
              <p className="mt-[14px] font-Mulish text-[15px] leading-[140%] text-ltext">
                Mauris pellentesque dictumst consectetur malesuada
                egestas augue feugiat ut. Varius neque amet quis est
                id tortor vitae vitae.
              </p>
            </div>
          ),
          target: "button.client_dashboard_fifth",
          locale: { next: <p>Next</p> },
          showProgress: true,
          hideBackButton: false,
        },
      ]}
      styles={{
        tooltip: {
          borderRadius: "24px",
          padding: "10px 20px 30px",
          width: "486px",
        },
        buttonSkip: {
          display: "flex",
          padding: "16px 24px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          borderRadius: "12px",
          background: "var(--gray-scale-gray-scale-70, #F2F4F7)",
          color: "#E98100",
          textAlign: "center",
          fontSize: "16px",
          fontFamily: "Mulish",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          letterSpacing: "-0.48px",
        },
        buttonBack: {
          display: "flex",
          padding: "16px 24px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          borderRadius: "12px",
          background: "var(--gray-scale-gray-scale-70, #F2F4F7)",
          color: "#E98100",
          textAlign: "center",
          fontSize: "16px",
          fontFamily: "Mulish",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          letterSpacing: "-0.48px",
          marginLeft: "none",
        },
        buttonNext: {
          display: "flex",
          padding: "16px 24px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          minWidth: 'max-content',
          borderRadius: "12px",
          background: "#E98100",
        },
        tooltipFooter: {
          justifyContent: "flex-start",
          gap: "12px",
        },
        buttonClose: {
          left: "10px",
          fontWeight: 800
        },
      }}
    />*/}
      {/* <Main /> */}
      <NewMain />
    </>
  );
};

export default ClientsDashBoard;
