import {Button} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {Link} from "react-router-dom";

function EachEngagementCard({title, value, icon, iconBg, btnText, to}) {
  return (
    <div className="mb-5 rounded-2xl bg-white p-4">
      <div className="flex w-full gap-4">
        <div
          className={`flex h-[52px] w-[52px] items-center justify-center rounded-[10px] ${
            iconBg || "bg-[#FEF8F1]"
          }`}
        >
          {icon}
        </div>
        <div className="mt-3 flex-1">
          <p className="mb-7 text-base font-normal text-grey_20">{title}</p>

          <div className="flex items-center justify-between">
            <p className="font-Bai text-3xl font-semibold text-grey">{value}</p>
            {
              to &&
            <Button
              sx={{p: "0.5rem !important"}}
              component={Link}
              to={to}
              endIcon={<ArrowForwardIcon />}
            >
              {btnText}
            </Button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default EachEngagementCard;
