import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {logOut} from "redux/slice/AuthSlice";
import {showToast} from "redux/store.hooks";
import {API_URL} from "utils/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/admin/`,
  prepareHeaders: (headers, api) => {
    const {auth} = api.getState();

    if (auth.token) {
      headers.set("authorization", `Bearer ${auth.token}`);
      headers.set("Access-Control-Allow-Origin", `*`);
    }
    headers.set("Accept", `application/json`);
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  let err = result?.error;
  if (result?.error && err.data?._meta?.error?.message.includes("logged in")) {
    // logout
    showToast("Something went wrong, Please login again", "error");
    setTimeout(() => {
      api.dispatch(logOut({redirect: true}));
    }, 1000);
  }
  return result;
};

export const adminGeneralApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "adminGeneralApi",
  tagTypes: [
    "subAdminData",
    "subAdminRoles",
    "subAdminPermissions",
    "categories",
    "bannedWords",
    "serviceChargeOptions",
    "stateAndCity",
    "users",
    "tickets",
    "kycStatus",
    "jobs",
    "tokens",
    "engagement",
    "notification",
  ],
  endpoints: builder => ({
    dashboardStat: builder.query({
      query: () => "dashboard/stats",
    }),
    dashboardUnverifiedUsers: builder.query({
      query: () => "users/list/unverified",
    }),
    dashboardOpenTickets: builder.query({
      query: () => "dashboard/open-tickets",
    }),
    getAllUsers: builder.query({
      query: params => ({
        url: "/dashboard",
        params,
      }),
    }),
    // sub-admins
    getAllAdmin: builder.query({
      query: () => `list`,
      providesTags: ["subAdminData"],
    }),

    getSingleAdmin: builder.query({
      query: id => `show/${id}`,
      providesTags: ["subAdminData"],
    }),

    suspendAdmin: builder.mutation({
      query: ({id, type = "suspend"}) => ({
        url: `${type}-admin/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["subAdminData"],
    }),
    activateAdmin: builder.mutation({
      query: body => ({
        url: `activate-admin`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["subAdminData"],
    }),

    updateAdmin: builder.mutation({
      query(data) {
        const {id, ...body} = data;
        return {
          url: `update-admin/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["subAdminData"],
    }),

    addAdmin: builder.mutation({
      query: body => ({
        url: `add-admin`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["subAdminData"],
    }),

    deleteAdmin: builder.mutation({
      query: id => ({
        url: `delete-admin/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["subAdminData"],
    }),

    // roles and permission
    getAllRoles: builder.query({
      query: () => "role/list",
      providesTags: ["subAdminRoles"],
    }),

    getSingleRole: builder.query({
      query: id => `role/show/${id}`,
      providesTags: ["subAdminRoles"],
    }),

    createRole: builder.mutation({
      query: body => ({
        url: "role/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["subAdminRoles"],
    }),

    revokeAllUserRoles: builder.mutation({
      query: id => ({
        url: `role/revoke-all-user-roles/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["subAdminRoles"],
    }),

    deleteAdminRole: builder.mutation({
      query: id => ({
        url: `role/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["subAdminRoles"],
    }),

    getAllPermissions: builder.query({
      query: () => "permission/list",
      providesTags: ["subAdminPermissions"],
    }),

    getAdminPermissions: builder.query({
      query: id => ({
        url: `get-permissions/${id}`,
      }),
      providesTags: ["subAdminPermissions"],
    }),

    assignPermission: builder.mutation({
      query: body => ({
        url: "permission/assign-permissions-role",
        method: "POST",
        body,
      }),
      invalidatesTags: ["subAdminPermissions"],
    }),

    revokeRolePermission: builder.mutation({
      query: body => ({
        url: "permission/revoke-role-permission",
        method: "POST",
        body,
      }),
      invalidatesTags: ["subAdminPermissions"],
    }),

    revokeAllRolesPermissions: builder.mutation({
      query: id => ({
        url: `permission/revoke-all-role-permissions/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["subAdminPermissions"],
    }),
    // KYC
    getKycStatus: builder.query({
      query: id => `service-provider/${id}/kyc-info`,
      providesTags: ["kycStatus"],
    }),
    approveKyc: builder.mutation({
      query: id => ({
        url: `kyc/approve-kyc/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["kycStatus"],
    }),
    declineKyc: builder.mutation({
      query: ({id, body}) => ({
        url: `kyc/decline-kyc/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["kycStatus"],
    }),
    getCacStatus: builder.query({
      query: id => `service-provider/${id}/cac-cert`,
      providesTags: ["kycStatus"],
    }),
    approveCac: builder.mutation({
      query: id => ({
        url: `service-provider/approve/cac-cert/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["kycStatus"],
    }),
    declineCac: builder.mutation({
      query: ({id, body}) => ({
        url: `service-provider/decline/cac-cert/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["kycStatus"],
    }),
    // categories
    getAllCategories: builder.query({
      query: () => `category/list`,
      providesTags: ["categories"],
    }),

    getSingleCategory: builder.query({
      query: id => `category/show/${id}`,
      providesTags: ["categories"],
    }),

    createCategory: builder.mutation({
      query: body => ({
        url: "category/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["categories"],
    }),

    updateCategory: builder.mutation({
      query: ({id, body}) => ({
        url: `category/update/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["categories"],
    }),
    updateCategoryStatus: builder.mutation({
      query: id => ({
        url: `category/update-status/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["categories"],
    }),

    getSingleExpertise: builder.query({
      query: id => `expertise/show/${id}`,
      providesTags: ["categories"],
    }),

    createExpertise: builder.mutation({
      query: body => ({
        url: "expertise/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["categories"],
    }),

    updateExpertise: builder.mutation({
      query: ({id, body}) => ({
        url: `expertise/update/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["categories"],
    }),

    updateExpertiseStatus: builder.mutation({
      query: id => ({
        url: `expertise/update-status/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["categories"],
    }),

    // banned words
    addBannedWord: builder.mutation({
      query: body => ({
        url: "banned-word/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["bannedWords"],
    }),

    getSingleBannedWord: builder.query({
      query: id => `banned-word/show/${id}`,
      providesTags: ["bannedWords"],
    }),

    getAllBannedWords: builder.query({
      query: () => `banned-word/list`,
      providesTags: ["bannedWords"],
    }),

    deleteBannedWord: builder.mutation({
      query: id => ({
        url: `banned-word/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["bannedWords"],
    }),
    // Service Charges
    getAllServiceChargeOptions: builder.query({
      query: () => ({
        url: "service-charge/list",
        method: "GET",
      }),
      providesTags: ["serviceChargeOptions"],
    }),

    updateSingleServiceChargeOption: builder.mutation({
      query: ({id, body}) => ({
        url: `service-charge/update-option/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["serviceChargeOptions"],
    }),
    enableDisableServiceCharge: builder.mutation({
      query: ({id, body}) => ({
        url: `service-charge/update-status/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["serviceChargeOptions"],
    }),

    // state and cities
    getAllStates: builder.query({
      query: () => `state/list`,
      providesTags: ["stateAndCity"],
    }),

    createState: builder.mutation({
      query: body => ({
        url: "state/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["stateAndCity"],
    }),

    updateState: builder.mutation({
      query(data) {
        const {stateID, ...body} = data;
        return {
          url: `state/update/${stateID}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["stateAndCity"],
    }),

    getOneState: builder.query({
      query: id => `state/show/${id}`,
      providesTags: ["stateAndCity"],
    }),

    updateStateStatus: builder.mutation({
      query(data) {
        const {id, ...body} = data;
        return {
          url: `state/update-status/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["stateAndCity"],
    }),

    getStateByStatus: builder.query({
      query: status => `state/status/${status}`,
      providesTags: ["stateAndCity"],
    }),

    updateStateAvailability: builder.mutation({
      query(data) {
        const {id, ...body} = data;
        return {
          url: `state/update-availablity/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["stateAndCity"],
    }),

    deleteState: builder.mutation({
      query: id => ({
        url: `state/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["stateAndCity"],
    }),

    createCity: builder.mutation({
      query: body => ({
        url: "city/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["stateAndCity"],
    }),

    updateCity: builder.mutation({
      query(data) {
        const {id, ...body} = data;
        return {
          url: `city/update/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["stateAndCity"],
    }),

    getAllCities: builder.query({
      query: () => `city/list`,
      providesTags: ["stateAndCity"],
    }),

    getOneCity: builder.query({
      query: id => `city/show/${id}`,
      providesTags: ["stateAndCity"],
    }),

    // service providers
    addServiceProvider: builder.mutation({
      query: body => ({
        url: "service-provider/add-service-provider",
        method: "POST",
        body,
      }),
      invalidatesTags: ["users"],
    }),
    markAsTopVetted: builder.mutation({
      query: ({id, body}) => ({
        url: `service-provider/${id}/top-vetted`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["users"],
    }),
    getAllServiceProvider: builder.query({
      query: () => `service-provider/list`,
      providesTags: ["users"],
    }),

    getSingleServiceProvider: builder.query({
      query: id => `service-provider/show/${id}`,
      providesTags: ["users"],
    }),

    getSingleHybridProviderInfo: builder.query({
      query: id => `service-provider/client-profile/${id}`,
      providesTags: ["users"],
    }),

    // clients
    activateClientAccount: builder.mutation({
      query: id => ({
        url: `/client/activate-account/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["users"],
    }),
    giveToken: builder.mutation({
      query: body => ({
        url: `give-tokens`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["users"],
    }),
    suspendUser: builder.mutation({
      query: ({id, type = "suspend", body}) => ({
        url: `users/${id}/${type}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["users"],
    }),
    addClient: builder.mutation({
      query: body => ({
        url: "client/add-client",
        method: "POST",
        body,
      }),
      invalidatesTags: ["users"],
    }),
    getAllClients: builder.query({
      query: () => `client/list`,
      providesTags: ["users"],
    }),
    getSingleClient: builder.query({
      query: id => `client/show/${id}`,
      providesTags: ["users"],
    }),
    // Users
    getAllUnidentifiedUsers: builder.query({
      query: () => 'users-with-unverify-email',
       providesTags: ["users"],
      }),
      getOneUnIndentifiedUser: builder.query({
        query: (id) => `user/detail/${id}`,
        providesTags: ["users"],
    }),
    activateUserAccount: builder.mutation({
      query: (id) => ({
        url: `user/activate-account/${id}`,
        method: 'POST'
      }),
       invalidatesTags: ["users"],
    }),
    deleteUserAccount: builder.mutation({
      query: (id) => ({
        url: `user/delete-account/${id}`,
        method: 'DELETE'
      }),
       invalidatesTags: ["users"],
    }),
    // token packages
    addPackage: builder.mutation({
      query: body => ({
        url: "package/add-token-package",
        method: "POST",
        body,
      }),
      invalidatesTags: ["tokens"],
    }),
    editPackage: builder.mutation({
      query: ({body, id}) => ({
        url: `package/update/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["tokens"],
    }),
    getAllPackages: builder.query({
      query: () => `package/list`,
      providesTags: ["tokens"],
    }),
    getBonusTokens: builder.query({
      query: () => `bonus-tokens`,
    }),
    getSubscriptionHistory: builder.query({
      query: () => `subscription-history`,
    }),
    delPackage: builder.mutation({
      query: id => ({
        url: `package/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["tokens"],
    }),
    // Tickets
    getAllTickets: builder.query({
      query: ({params}) =>({
        url: 'tickets',
        params
      }) ,
      providesTags: ["tickets"],
    }),
    getSingleTicket: builder.query({
      query: tag => `tickets/${tag}`,
      providesTags: ["tickets"],
    }),
    replyTicket: builder.mutation({
      query: ({body, tag}) => ({
        url: `tickets/${tag}/respond`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["tickets"],
    }),
    updateTicketStatus: builder.mutation({
      query: ({tag, body}) => ({
        url: `tickets/${tag}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["tickets"],
    }),

    // jobs
    getAllJobs: builder.query({
      query: params => ({
        url: "job/list",
        params: {...params},
      }),
      providesTags: ["jobs"],
    }),

    getAllJobsByStatus: builder.query({
      query: ({status, params}) => ({
        url: `job/status/${status}`,
        params,
      }),
      providesTags: ["jobs"],
    }),

    getJobsStat: builder.query({
      query: () => `job/statistics`,
      providesTags: ["jobs"],
    }),

    getSingleJob: builder.query({
      query: id => `job/show/${id}`,
      providesTags: ["jobs"],
    }),

    deleteJob: builder.mutation({
      query: id => ({
        url: `job/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["jobs"],
    }),

    updateJobStatus: builder.mutation({
      query(data) {
        const {id, ...body} = data;
        return {
          url: `job/update-status/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["jobs"],
    }),
    suspendJob: builder.mutation({
      query: ({id, body}) => ({
        url: `job/suspend-unsuspend/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["jobs"],
    }),
    // engagements
    getAllEngagements: builder.query({
      query: params => ({
        url: "engagement/list",
        params,
      }),
      providesTags: ["engagements"],
    }),
    getOneEngagement: builder.query({
      query: id => `engagement/show/${id}`,
      providesTags: ["engagements"],
    }),
    getEngagementByStatus: builder.query({
      query: ({status, params}) => ({
        url: `engagement/status/${status}`,
        params,
      }),
      providesTags: ["engagements"],
    }),
    getEngagementStats: builder.query({
      query: () => ({
        url: "engagement/statistics",
      }),
      providesTags: ["engagements"],
    }),
    terminateAdminEngagement: builder.mutation({
      query: body => ({
        url: `engagement/terminate`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["engagements"],
    }),
    // transactions
    getCompletedRefundHistories: builder.query({
      query: params => ({
        url: "refund-history/refunded",
        params,
      }),
      providesTags: ["transactions"],
    }),
    completeRefund: builder.mutation({
      query: body => ({
        url: `refund-history/complete-refund`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["transactions", 'engagements'],
    }),
    getPendingRefundHistories: builder.query({
      query: params => ({
        url: "refund-history/pending",
        params,
      }),
      providesTags: ["transactions"],
    }),
    getPayoutList: builder.query({
      query: params => ({
        url: "payout/list",
        params,
      }),
      providesTags: ["transactions"],
    }),
    getPayoutStat: builder.query({
      query: () => "payout/get-stats",
      providesTags: ["transactions"],
    }),
    markPayoutAsCompleted: builder.mutation({
      query: body => ({
        url: "payout/complete-payout",
        method: "POST",
        body,
      }),
      invalidatesTags: ["transactions"],
    }),
    getTransactionHistory: builder.query({
      query: () => "transaction/list",
      providesTags: ["transactions"],
    }),
    getTransactionStats: builder.query({
      query: () => "transaction/statistics",
      providesTags: ["transactions"],
    }),
    getAdminSubscriptions: builder.query({
      query: () => "subscription/list",
      providesTags: ["transactions"],
    }),
    getAdminBanners: builder.query({
      query: () => "home-page-banner/list",
      providesTags: ["banners"],
    }),
    createEditBanner: builder.mutation({
      query: ({body, type = "create"}) => ({
        url: `home-page-banner/${type}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["banners"],
    }),
    delBanner: builder.mutation({
      query: id => ({
        url: `home-page-banner/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["banners"],
    }),
    // Notification
    getAllAdminNotification: builder.query({
      query: ({params}) =>({
        url:"in-app-notification/get-all",
        params
      }) ,
      providesTags: ["notification"],
    }),
    markMultipleNotificationAsRead: builder.mutation({
      query: () => ({
        url: "in-app-notification/mark-multiple-as-read",
        method: "POST",
      }),
      invalidatesTags: ["notification"],
    }),
    markOneNotificationAsRead: builder.mutation({
      query: body => ({
        url: "in-app-notification/mark-one-as-read",
        method: "POST",
        body,
      }),
      invalidatesTags: ["notification"],
    }),
    sendNotificationToAllUsers: builder.mutation({
      query: body => ({
        url :'notify-users',
        method: 'POST',
        body
      })
    })
  }),
});

export const {
  //Dashboard
  useDashboardStatQuery,
  useDashboardUnverifiedUsersQuery,
  useDashboardOpenTicketsQuery,
  useGetAllUsersQuery,
  // admins
  useGetAllAdminQuery,
  useGetSingleAdminQuery,
  useSuspendAdminMutation,
  useDeleteAdminMutation,
  useUpdateAdminMutation,
  useAddAdminMutation,
  useGetKycStatusQuery,
  useApproveKycMutation,
  useDeclineKycMutation,
  useGetCacStatusQuery,
  useApproveCacMutation,
  useDeclineCacMutation,
  useActivateAdminMutation,
  // roles
  useGetAllRolesQuery,
  useGetSingleRoleQuery,
  useCreateRoleMutation,
  useRevokeAllUserRolesMutation,
  useDeleteAdminRoleMutation,
  // permission
  useGetAllPermissionsQuery,
  useGetAdminPermissionsQuery,
  useAssignPermissionMutation,
  useRevokeRolePermissionMutation,
  useRevokeAllRolesPermissionsMutation,
  // category
  useGetAllCategoriesQuery,
  useGetSingleCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategoryStatusMutation,
  useUpdateCategoryMutation,
  useGetSingleExpertiseQuery,
  useCreateExpertiseMutation,
  useUpdateExpertiseMutation,
  useUpdateExpertiseStatusMutation,
  // banned word
  useAddBannedWordMutation,
  useGetAllBannedWordsQuery,
  useDeleteBannedWordMutation,
  useGetSingleBannedWordQuery,
  useGetAllServiceChargeOptionsQuery,
  useUpdateSingleServiceChargeOptionMutation,
  useEnableDisableServiceChargeMutation,
  // state and city
  useGetAllStatesQuery,
  useCreateStateMutation,
  useUpdateStateMutation,
  useGetOneStateQuery,
  useUpdateStateStatusMutation,
  useGetStateByStatusQuery,
  useUpdateStateAvailabilityMutation,
  useDeleteStateMutation,
  useCreateCityMutation,
  useUpdateCityMutation,
  useGetAllCitiesQuery,
  useGetOneCityQuery,
  // service provider
  useAddServiceProviderMutation,
  useGetAllServiceProviderQuery,
  useGetSingleServiceProviderQuery,
  useMarkAsTopVettedMutation,
  // Clients
  useActivateClientAccountMutation,
  useAddClientMutation,
  useGetAllClientsQuery,
  useGetSingleClientQuery,
  useGetSingleHybridProviderInfoQuery,
  useSuspendUserMutation,
  useGiveTokenMutation,
  // users
  useGetAllUnidentifiedUsersQuery,
  useGetOneUnIndentifiedUserQuery,
  useActivateUserAccountMutation,
  useDeleteUserAccountMutation,
  // token packages
  useAddPackageMutation,
  useEditPackageMutation,
  useGetAllPackagesQuery,
  useDelPackageMutation,
  useGetBonusTokensQuery,
  useGetSubscriptionHistoryQuery,
  // tickets
  useGetAllTicketsQuery,
  useGetSingleTicketQuery,
  useReplyTicketMutation,
  useUpdateTicketStatusMutation,
  // jobs
  useGetAllJobsQuery,
  useGetAllJobsByStatusQuery,
  useGetJobsStatQuery,
  useGetSingleJobQuery,
  useDeleteJobMutation,
  useUpdateJobStatusMutation,
  useSuspendJobMutation,
  // Engagement
  useGetAllEngagementsQuery,
  useGetOneEngagementQuery,
  useGetEngagementByStatusQuery,
  useGetEngagementStatsQuery,
  useTerminateAdminEngagementMutation,
  // transactions
  useGetCompletedRefundHistoriesQuery,
  useGetPendingRefundHistoriesQuery,
  useGetPayoutListQuery,
  useGetPayoutStatQuery,
  useMarkPayoutAsCompletedMutation,
  useCompleteRefundMutation,
  useGetTransactionHistoryQuery,
  useGetTransactionStatsQuery,
  useGetAdminSubscriptionsQuery,

  // homepage banners
  useGetAdminBannersQuery,
  useCreateEditBannerMutation,
  useDelBannerMutation,
  // Notification
  useGetAllAdminNotificationQuery,
  useMarkMultipleNotificationAsReadMutation,
  useMarkOneNotificationAsReadMutation,
  useSendNotificationToAllUsersMutation
} = adminGeneralApi;
