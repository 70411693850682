import {useFormContext, get} from "react-hook-form";
import InputField from "./InputField";

function ValidatedInput({
  name,
  required = true,
  rules,
  errMsg,
  showErrMsg = true,
  ...otherProps
}) {
  const {
    register,
    formState: {errors},
  } = useFormContext();
  const error = get(errors, name);
  return (
    <div className="form-group-wrapper">
      <InputField
        isInvalid={error}
        {...otherProps}
        {...register(name, {
          required: required ? "This field is required" : false,
          ...(otherProps.type === "password"
            ? {
                minLength: {
                  value: 8,
                  message: "Password must have at least 8 characters",
                },
              }
            : {}),
          ...rules,
        })}
      />
      {showErrMsg && error && (
        <div className="input-err-msg">{error.message || errMsg}</div>
      )}
    </div>
  );
}

export default ValidatedInput;
