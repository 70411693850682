import SearchInput from "components/forms/SearchInput";
import ExpertiseWidget from "pages/User/auth/AccountCreationComp/Widgets/ExpertiseWid";
import { useEffect, useState } from "react";

const AddAreaOfExpertise = ({ expertises, serviceData }) => {
  const [checked, setChecked] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (serviceData) {
      serviceData?.expertise.forEach((el, i) => {
        setChecked(prev => [...new Set([...prev, el?.expertise?.name])]);
      });
    }
  }, [serviceData]);

  const generateDefaultValue = value => {
    return value
      ? {
        id: value?.expertise_id,
        min: value?.min_price,
        max: value?.max_price,
        checked: true,
      }
      : {};
  };
  return (
    <section className=" ">
      <div className="flex items-center justify-between px-4 md:px-10">
        <p className="text-sm font-medium text-grey_30 md:text-lg">
          Add areas of expertises
        </p>

        <p className="text-base font-bold text-grey md:text-2xl">
          2 <sup className="text-[13px] font-normal text-grey_40">3</sup>
        </p>
      </div>
      <div className="mt-5 h-px bg-grey_60"></div>
      <div className=" w-full p-4">
        <p className="mb-4 text-sm font-normal text-grey_30">
          Areas of Expertise
        </p>
        <div className="flex flex-col gap-y-6  rounded-20 bg-[#F2F4F7] p-4 md:p-6">
          {expertises?.length > 0 ? (
            <div className="h-fit">
              <SearchInput
                className="!bg-white"
                maxWidth="max-w-[unset]"
                placeholder="Search Expertise"
                onChange={e => {
                  setQuery(e.target.value);
                }}
              />
              <div className="my-[20px]">
                <p className="text-[14px] font-normal text-grey_20">
                  {checked.length} of {expertises?.length} selected
                </p>
                <div className="my-[12px] flex flex-wrap items-center gap-3">
                  {checked.map((item, i) => (
                    <div
                      className="flex w-fit items-center gap-1  rounded-[100px] bg-[#1d1d1d] px-[12px] py-[8px] text-[14px] font-medium text-[#fff]"
                      key={`${item}_${i}`}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div className="noScroll flex max-h-[450px] flex-col gap-3 overflow-scroll pb-4">
                {expertises?.map((val, index) => (
                  <div
                    className={`${query === "" ||
                        val?.name.toLowerCase().includes(query?.toLowerCase())
                        ? "block"
                        : "hidden"
                      }`}
                    key={val?.id}
                  >
                    <ExpertiseWidget
                      defaultValue={generateDefaultValue(
                        serviceData?.expertise
                          ? serviceData?.expertise?.find(
                            el => el.expertise_id === val.id,
                          )
                          : null,
                      )}
                      name={`expertise_prices.${index}`}
                      index={index}
                      id={val?.id}
                      title={val?.name}
                      value={val?.name}
                      location={val?.location_type}
                      setChecked={setChecked}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="mt-4 w-full text-center text-[15px] font-normal text-grey_20 ">
              The selected category has no expertises
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AddAreaOfExpertise;

export const Expertises = [
  {
    id: 1,
    title: "Car Repair and maintanace",
    isChecked: false,
    priceFrom: "",
    priceTo: "",
    status: "Virtual",
  },
  {
    id: 2,
    title: "Car Consultancy",
    isChecked: false,
    priceFrom: "",
    priceTo: "",
    status: "Virtual",
  },
  {
    id: 3,
    title: "Car Wash",
    isChecked: false,
    priceFrom: "",
    priceTo: "",
    status: "Virtual",
  },
  {
    id: 4,
    title: "Engine Repair",
    isChecked: false,
    priceFrom: "",
    priceTo: "",
    status: "Virtual",
  },
  {
    id: 5,
    title: "Oil Management",
    isChecked: false,
    priceFrom: "",
    priceTo: "",
    status: "Virtual",
  },
];
