import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@mui/material";
import BasicInfoTab from "pages/ServiceProviders/Settings/Tabs/BasicInfoTab";
import AccountDetails from "pages/User/auth/AccountCreationComp/AccountDetails";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import KycSettings from "./Tabs/KycSettings";
// import BvnSettings from "./Tabs/BvnSettings";
// import NotificationsSettings from "./Tabs/NotificationsSettings";
import SecuritySettings from "./Tabs/SecuritySettings";

function ClientSettings() {
  const [activeTab, setActiveTab] = useState("basic_info");
  const navigate = useNavigate();
  let {search} = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get("tab");
  useEffect(() => {
    if (tab) setActiveTab(tab);
  }, [tab]);

  return (
    <div>
      <h1 className="mb-6 font-Bai text-4xl font-semibold">Settings</h1>
      <div className="mt-8 flex w-full flex-col p-2 md:flex-row md:items-start md:justify-start md:gap-[3.75rem] md:p-0">
        <TabContext value={activeTab}>
          <div className="flex flex-col gap-y-5">
            {/* mobile */}
            <TabList
              variant="scrollable"
              scrollButtons
              value={activeTab}
              className="mb-4 w-fit rounded-[16px] border border-grey_60 bg-grey_80 p-2 md:!hidden"
              onChange={(_, newValue) => {
                navigate(`?tab=${newValue}`);
                setActiveTab(newValue);
              }}
              sx={{
                fontWeight: 600,
                ".MuiTab-root": {
                  minWidth: "max-content",
                },
                textTransform: "unset",
              }}
              aria-label="tickets tab"
            >
              {steps.map(({value, label}, index) => (
                <Tab
                  key={`tab_${value}`}
                  onClick={() => {
                    setActiveTab(value);
                    navigate(`?tab=${value}`);
                  }}
                  label={label}
                  value={value}
                />
              ))}
            </TabList>
            {/* desktop  */}
            <div className="hidden h-fit min-w-fit flex-col items-start gap-y-2 rounded-2xl bg-white p-8 md:flex md:min-w-[300px]">
              {steps.map(({value, label}, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => {
                    setActiveTab(value);
                    navigate(`?tab=${value}`);
                  }}
                  className={`min-w-full rounded-[14px] py-3 px-5 text-left font-Mulish text-lg font-medium transition duration-500 hover:bg-grey_60 hover:font-semibold hover:text-[#693A00] ${
                    activeTab === value
                      ? "bg-grey_60 font-semibold text-[#693A00]"
                      : "text-grey_50"
                  }}`}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>

          <div className="w-full md:max-w-[720px]">
            <TabPanel value="basic_info">
              <BasicInfoTab />
            </TabPanel>
            <TabPanel value="kyc">
              <KycSettings />
            </TabPanel>
            <TabPanel value="security">
              <SecuritySettings />
            </TabPanel>
            {/* <TabPanel value="bvn">
              <BvnSettings />
            </TabPanel> */}
            <TabPanel value="account_details">
              <AccountDetails isOnboarding={false} />
            </TabPanel>
            {/* <TabPanel value="notification">
              <NotificationsSettings />
            </TabPanel> */}
          </div>
        </TabContext>
      </div>
    </div>
  );
}

export default ClientSettings;
const steps = [
  {label: "Basic Info", value: "basic_info"},
  {label: "KYC", value: "kyc"},
  // {label: "BVN Verification ", value: "bvn"},
  {label: "Bank Details", value: "account_details"},
  {label: "Security", value: "security"},
  // {label: "Notification", value: "notification"},
];
