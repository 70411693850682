import { Chip } from '@mui/material'
import React from 'react'
import { LocationOn, } from '@mui/icons-material'
import { truncateString } from 'utils'
import UserDisplay from './mui-components/TableComponent/UserDisplay'

const NewOfferCard = () => {
    return (
        <div className='bg-white rounded-2xl px-5 py-4'>
            <div className="flex justify-between items-center">
                <UserDisplay
                    name="Mirian Achonwa"
                    img={""}
                />

                <div className="flex justify-center items-center rounded-md w-9 h-9 bg-grey-100 relative">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 6.5C4 5.94772 4.44772 5.5 5 5.5H10C10.5523 5.5 11 5.94772 11 6.5C11 7.05228 10.5523 7.5 10 7.5H5C4.44772 7.5 4 7.05228 4 6.5Z" fill="black" />
                        <path d="M5 9.5C4.44772 9.5 4 9.94771 4 10.5C4 11.0523 4.44772 11.5 5 11.5H14C14.5523 11.5 15 11.0523 15 10.5C15 9.94771 14.5523 9.5 14 9.5H5Z" fill="black" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4 17.95L14.8 16.9C15.1462 16.6404 15.5673 16.5 16 16.5C18.2091 16.5 20 14.7091 20 12.5V4.5C20 2.29086 18.2091 0.5 16 0.5H4C1.79086 0.5 0 2.29086 0 4.5V12.5C0 14.7091 1.79086 16.5 4 16.5H6C6.43274 16.5 6.85381 16.6404 7.2 16.9L8.6 17.95C10.0222 19.0167 11.9778 19.0167 13.4 17.95ZM16 14.5C15.1345 14.5 14.2924 14.7807 13.6 15.3L12.2 16.35C11.4889 16.8833 10.5111 16.8833 9.8 16.35L8.4 15.3C7.70762 14.7807 6.86548 14.5 6 14.5H4C2.89543 14.5 2 13.6046 2 12.5V4.5C2 3.39543 2.89543 2.5 4 2.5H16C17.1046 2.5 18 3.39543 18 4.5V12.5C18 13.6046 17.1046 14.5 16 14.5Z" fill="black" />
                    </svg>

                    <span className="flex justify-center items-center absolute -top-1 -left-1 bg-error rounded-full w-4 h-4 font-bold text-xs text-white">
                        4
                    </span>
                </div>
            </div>
            <div className="flex justify-between items-center mt-4">
                <p className="font-bold text-xs text-primary">
                    {truncateString("Housing and Accommodation", 21)}
                </p>
                <div className="flex gap-1 items-center">

                    <Chip
                        // color='primaryChip'
                        sx={{ color: "#865503", fontWeight: 700 }}
                        label='OFFER PENDING'
                        size='small'
                        variant='outlined'
                    />
                </div>
            </div>

            <p className="my-3 font-Bai text-grey-20 text-base font-semibold">
                Happy Ending Massage. A title for two lines and more
            </p>

            <div className="flex justify-between items-center">
                <span className="font-bold text-xs p-1 flex gap-1 rounded-lg items-center text-secondary-80 bg-secondary-5">
                    <LocationOn fontSize='small' />
                    Physical - VI, Lagos
                </span>

            </div>

            <div className="flex justify-between items-center mt-3">
                <p className="font-semibold font-Bai text-2xl text-[#444]">
                    ₦30,000
                </p>

                <p className="font-medium text-xs text-[#69697B]">
                    Yesterday, 2:00pm
                </p>
            </div>


        </div>
    )
}

export default NewOfferCard