import {useState} from "react";
import {Checkbox} from "@mui/material";
import ValidatedInput from "components/forms/ValidatedInput";
import NairaIcon from "components/Vectors/NairaIcon ";
import SmallNairaIcon from "components/Vectors/SmallNairaIcon";
import {Controller, useFormContext} from "react-hook-form";

const ExpertiseWidget = ({
  handleChange,
  index,
  value,
  title,
  location,
  name,
  id,
  setChecked,
  defaultValue,
}) => {
  const {control, watch} = useFormContext();

  const [showRange, setShowRange] = useState(true);
  const minValue = watch(name)?.min;
  const maxValue = watch(name)?.max;

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({field: {onChange, value = {}}}) => {
        return (
          <div
            key={index}
            className="flex flex-col rounded-10 border border-[#E4E7EC] bg-[#F2F4F7] py-[0px] px-[9px]"
          >
            <div className=" flex justify-between pr-[18px]">
              <Checkbox
                checked={value.checked}
                onChange={e => {
                  setChecked(prev =>
                    e.target.checked
                      ? [...prev, title]
                      : prev.filter(item => item !== title),
                  );
                  onChange({
                    checked: e.target.checked,
                    min: e.target.checked ? value.min : null,
                    max: e.target.checked ? value.max : null,
                    id, // include the id of the selected checkbox
                  });
                }}
                sx={{"& .MuiSvgIcon-root": {fontSize: 28}}}
              />

              <div className="flex w-full items-center justify-between ">
                <h3 className="text-base font-normal text-grey_10 ">{title}</h3>

                <p className="text-xs font-normal text-[#5010B3] ">
                  {location}
                </p>
              </div>
            </div>
            {value.checked && (
              <div className="mb-3">
                <div
                  className={`${
                    showRange ? "flex" : "hidden"
                  } items-center gap-[20px]`}
                >
                  <label className="pl-[9px] text-[14px] font-normal text-grey_30">
                    Price Range
                  </label>
                  <div>
                    {value?.max?.length <= 0 ? (
                      <div className="flex items-center gap-[20px]">
                        <div className=" flex items-center gap-0 text-base text-grey_10">
                          No Price Range
                        </div>

                        <div
                          role="button"
                          className="cursor-pointer pl-[9px] text-[13px] font-normal text-error"
                          onClick={() => setShowRange(prev => !prev)}
                        >
                          Edit Price Range
                        </div>
                      </div>
                    ) : (
                      <div className="5 flex items-center">
                        <div className="flex items-center gap-2 px-[9px]">
                          <span className="flex items-center gap-[-5px] text-base text-grey_10">
                            <NairaIcon />
                            <span className="-ml-2">{minValue}</span>
                          </span>

                          <span className="text-[14px] font-normal text-grey_30">
                            to
                          </span>
                          <span className="flex items-center gap-0 text-base text-grey_10">
                            <NairaIcon />
                            <span className="-ml-2">{maxValue}</span>
                          </span>
                        </div>
                        <div
                          role="button"
                          className="cursor-pointer pl-[9px] text-[14px] font-normal text-error"
                          onClick={() => setShowRange(prev => !prev)}
                        >
                          Edit Price Range
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={`${!showRange ? "block" : "hidden"}`}>
                  <label className="pl-[9px] text-[14px] font-normal text-grey_30 ">
                    Price Range{" "}
                    <span className="inline text-grey_40 ">(Optional)</span>
                  </label>
                  <div className="flex items-center gap-2 px-[9px]">
                    <ValidatedInput
                      prefixIcon={<SmallNairaIcon />}
                      name={`${name}.min`}
                      bordered={false}
                      required={false}
                      className="!mt-5"
                      type="number"
                    />
                    <span>to</span>
                    <div
                      className="border-none outline-none"
                      tabIndex={index}
                      onBlur={() => setShowRange(true)}
                    >
                      <ValidatedInput
                        prefixIcon={<SmallNairaIcon />}
                        name={`${name}.max`}
                        required={false}
                        bordered={false}
                        className="!mt-5"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }}
      control={control}
    />
  );
};
export default ExpertiseWidget;
