import { MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Physical = ({ setFilterChoice, filterChoice, choice, setChoice, gap, optionsProps = options, btnClassname, labelColor, title = false }) => {
  return (
    <div className="flex items-center ">
      <div className={`flex flex-wrap items-center ${gap ? gap : "gap-2"} `}>
        {optionsProps.map(({ name, selectOption, icon, }, index) => (
          <button
            onClick={e => {
              setFilterChoice(filterChoice === name ? "" : name);
            }}
            type="button"
            key={index}
            className={`bg-[#F2F4F7] filter-select relative flex items-center gap-x-0.5 rounded-xl bggre80 p-3 text-sm capitalize md:p-4 md:text-base ${btnClassname}`}
          >
            <span className="text-grey_30">{name}: </span>
            <span className={`ml-[8px] ${labelColor || "text-grey"}`}>
              {choice[name] || selectOption[0]?.label}
            </span>
            <span className="ml-4">
              {filterChoice === name ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
            {filterChoice === name && (
              <div className=" dropdown-boxShadwn absolute top-[70px] left-0 z-10 w-full rounded-xl bg-[#E4E7EC] ">
                {title &&
                  <div className="flex gap-3 items-center mt-4 ml-4 mb-2.5">
                    <span className="flex justify-center items-center w-10 h-10 rounded-lg bg-[#F3ECFD]">
                      {icon && icon}
                    </span>

                    <p className="font-semibold text-base text-[#232329]">
                      {name}
                    </p>
                  </div>
                }
                {selectOption.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option?.value}
                    onClick={() => {
                      setChoice({ ...choice, [name]: option?.value });
                      setFilterChoice("");
                    }}
                    sx={{ py: 1.5, color: "#6E726E" }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </div>
            )}
          </button>
        ))}
      </div>

      {/* <DropDownWrapper
        action={
          <div className="flex cursor-pointer items-center gap-3 text-base font-normal capitalize text-grey_10 ">
            {statusTab}
            <span>
              <KeyboardArrowDownRoundedIcon />
            </span>
          </div>
        }
      >
        <div className="flex cursor-pointer flex-col gap-3  ">
          <p className="" onClick={() => setStatusTab("Physcial")}>
            Physical
          </p>
          <p className="" onClick={() => setStatusTab("Virtual")}>
            Virtual
          </p>
        </div>
      </DropDownWrapper> */}
    </div>
  );
};

export default Physical;

const options = [
  {
    name: "type",
    selectOption: [
      { label: "All", value: "" },
      { label: "Physical", value: "physical" },
      { label: "Virtual", value: "virtual" },
    ],
  },

  {
    name: "order",
    selectOption: [
      { label: "Newest", value: "newest" },
      { label: "Oldest", value: "oldest" },
    ],
  },
];
