import {Avatar, Chip} from "@mui/material";
import FlagIcon from "components/Vectors/FlagIcon";
import {flagColor} from "./TicketCards";
import moment from "moment";

const TicketSenderInfo = ({ticket}) => {
  return (
    <div className="h-fit w-fit rounded-20 bg-white p-6">
      <div className="flex items-center gap-2">
        <Avatar
          sx={{width: "60px", height: "60px"}}
          src={ticket?.user.avatar}
          alt={ticket?.user.first_name}
        />
        <h2 className="settings-font-h2 !text-xl">
          {ticket?.user.first_name} {ticket?.user.last_name}
        </h2>
      </div>
      <div
        className="text-prop grey-border-1px mt-4 flex gap-[14px] rounded-[10px] bg-grey_80
       py-[12px] px-[16px] font-Bai text-xl font-semibold text-grey_30"
      >
        <p>Ticket Priority:</p>
        <p className="flex items-center gap-2 ">
          <span>
            <FlagIcon color={flagColor(ticket?.priority)} />
          </span>
          <span className="text-grey_20">{ticket?.priority}</span>
        </p>
      </div>
      <div className="my-3 flex items-center justify-between gap-2">
        <p className="text-[14px] font-normal text-grey_30">
          Issued on: {moment(ticket?.created_at).format("DD MMM YYYY: hh:mm a")}
        </p>

        <Chip
          sx={{borderRadius: "8px"}}
          color="secondaryInverse"
          label={ticket?.status === "Open" ? "New" : ticket?.status}
        />
      </div>
    </div>
  );
};
export default TicketSenderInfo;
