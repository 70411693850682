import AllRoutes from "Routes";
import {BrowserRouter} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import SwiperCore, {Autoplay, Pagination, Navigation} from "swiper";
import "react-modal-video/scss/modal-video.scss";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import {useEffect, useState} from "react";
import Loader from "components/Loader/Loader";
import ToastWidget from "components/ToastWidget";
import ScrollToTop from "components/mui-components/ScrollToTop";
import {ErrorBoundaryFallback} from "components/mui-components/ErrorMsg";
import {GoogleOAuthProvider} from "@react-oauth/google";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

SwiperCore.use([Autoplay, Pagination, Navigation]);
export const CustomTabStyle = {
  ".MuiTab-root": {
    fontSize: {
      xs: "16px",
      md: "20px",
    },
    textTransform: "inherit",
    minWidth: "275px !important",
    "&.Mui-selected": {
      color: "#693A00",
      background: "white",
      padding: "20px !important",
      border: "1px solid #E4E7EC",
      borderRadius: "14px",
    },
  },
  ".MuiTabs-indicator": {
    height: "0px",
  },
};

export const CustomTabStyle2 = {
  ".MuiTab-root": {
    fontSize: {
      xs: "16px",
      md: "20px",
    },

    "&.Mui-selected": {
      color: "#693A00 !important",
      fontWeight: 600,
      backgroundColor: "#ffffff !important",
      borderWidth: "1px !important",
      borderColor: "#E4E7EC  !important",
      borderRadius: "14px !important",
    },
  },
  ".MuiTabs-indicator": {
    height: "0px",
  },
};

export const CustomTabStyle3 = {
  ".MuiTab-root": {
    fontSize: {
      xs: "16px",
      md: "20px",
    },
    textTransform: "inherit",
    minWidth: "184px !important",
    "&.Mui-selected": {
      color: "#693A00",
      background: "white",
      padding: "20px !important",
      border: "1px solid #E4E7EC",
      borderRadius: "14px",
    },
  },
  ".MuiTabs-indicator": {
    height: "0px",
  },
};

const theme = createTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    action: {
      disabledBackground: "",
      disabled: "",
    },
    primary: {
      main: "#E98100",
      contrastText: "#ffffff",
      light: "#fafafa",
    },
    primaryInverse: {
      contrastText: "#E98100",
      main: "#000000",
    },
    primaryInverse2: {
      main: "#F2F4F7",
      contrastText: "#E98100",
    },
    primaryInverse3: {
      main: "#FdF2e6",

      contrastText: "#E98100",
    },
    primaryChip: {
      main: "#C9C9CF",
      contrastText: "#865503",
    },
    primaryLight: {
      main: "#FFFFFF",
      contrastText: "#D27400",
    },
    greyInverse: {
      contrastText: "#E98100",
      main: "#F2F4F7",
    },
    greyDark: {
      main: "#6E726E",
      contrastText: "#ffffff",
    },
    success2: {
      main: "#12B76A",
      contrastText: "#ffffff",
    },

    error2: {
      main: "#D92D20",
      contrastText: "#FFFFFF",
    },
    error3: {
      contrastText: "#D92D20",
      main: "#FFFFFF",
    },
    errorBtn: {
      main: "#F04438",
      contrastText: "#FFFFFF",
    },
    yellowInverse: {
      main: "#E981001A",
      contrastText: "#D27400",
    },
    yellowInverse2: {
      main: "#D274001A",
      contrastText: "#E98100",
    },
    orangeInverse: {
      main: "#FDF2E6",
      contrastText: "#E98100",
    },
    secondary: {main: "#000042"},
    secondaryLight: {main: "#BABAEE", contrastText: "white"},
    secondaryInverse: {main: "#5010B31A", contrastText: "#5010B3"},
    secondaryInverse2: {main: "#5010B3", contrastText: "#ffffff"},
    blueInverse: {
      light: "#1570EF",
      contrastText: "#1570EF",
      main: "#D1E9FF",
    },
    success: {main: "#039855", light: "#E5F7ED", dark: "#113924"},
    successInverse: {contrastText: "#039855", main: "#12B76A1A"},
    errorInverse: {contrastText: "#B42318", main: "#FFF1F0"},
    errorInverse2: {contrastText: "#FFFFFF", main: "#F04438"},
    error: {main: "#D92D20", light: "#FAEBED", dark: "#6E2931"},
    greyBorder: {main: "#F2F4F7", contrastText: "#3B3D3B"},
    white: {
      main: "#ffffff",
      contrastText: "#161616",
    },
    btnBlack: {
      main: "#000",
      contrastText: "#fff",
    },
    black: {
      contrastText: "#ffffff",
      main: "#8585A3",
    },
    black2: {
      contrastText: "#ffffff",
      main: "#1D1D1D",
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree, sans-serif",
          "&.Mui-selected": {color: "#8937CE !important"},
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          border: "none !important",
          "&:hover": {
            borderWidth: "1px !important",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontFamily: "Mulish, sans-serif",
          textTransform: "capitalize",
          boxShadow: "none !important",
          borderRadius: "14px !important",
          padding: "16px 25px !important",
          lineHeight: "20px",
          letterSpacing: "-0.03em",

          "&:disabled": {
            opacity: 0.5,
            cursor: "not-allowed",
          },
          "&.MuiButton-sizeMedium": {
            padding: "16px 20px",
            fontSize: "0.875rem !important",
            borderRadius: "1rem !important",
            "@media (max-width: 760px)": {
              fontSize: ".85rem !important",
              padding: ".85rem",
              borderRadius: "1rem !important",
            },
          },
          "&.MuiButton-sizeSmall": {
            padding: "6px 16px",
          },
          "&.MuiButton-text": {
            padding: "0.5rem",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: "Mulish, sans-serif",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "3rem",
          borderWidth: 0,
        },
        fieldSet: {
          borderRadius: 12,
        },
        input: {
          padding: "14px 14px",
          paddingRight: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontWeight: 600,
          color: "#6E726E",
          fontSize: "20px",
          fontFamily: "Bai Jamjuree, sans-serif",
          boxShadow: "none !important",
          borderRadius: "12px !important",
          padding: "16px !important",
          lineHeight: "20px",
          letterSpacing: "-0.03em",
          "&.Mui-selected": {
            color: "#693A00",
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: "14px",
          fontFamily: "Mulish, sans-serif",
          textTransform: "capitalize",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: "16px",
          fontFamily: "Mulish, sans-serif",
          textTransform: "capitalize",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: "none",
          borderWidth: "1px !important",
          fontFamily: "Bai Jamjuree, sans-serif",
          fontSize: "14px !important",
          "&:hover": {
            border: "none !important",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          marginTop: "16px",
          fontFamily: "Mulish, sans-serif",
          fontSize: "16px !important",
          "&:hover": {
            border: "none !important",
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#181820",
          borderRadius: 6,
        },
      },
    },
  },
});

const clientId =
  "636316618783-isi5dneggk8bdp7hqnrue9onbbucc0lu.apps.googleusercontent.com";

const App = () => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 4000);
  }, []);

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <ThemeProvider theme={theme}>
          <GoogleOAuthProvider clientId={clientId}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ToastWidget />
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <BrowserRouter>
                  <ScrollToTop />
                  <AllRoutes />
                </BrowserRouter>
              </ErrorBoundary>
            </LocalizationProvider>
          </GoogleOAuthProvider>
        </ThemeProvider>
      )}
    </>
  );
};

export default App;
