import {Avatar, Chip} from "@mui/material";
import {formatCurrency, moneyFormatter} from "utils";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import {Link} from "react-router-dom";
import {BootstrapTooltip} from "components/TicketCards";

const EachJobCard = ({job}) => {
  return (
    <Link
      to={`/admin/jobs/${job?.id}`}
      key={job?.id}
      className="rounded-2xl bg-grey_80 p-4"
    >
      <div className="mb-4 flex gap-3">
        <Avatar
          src={job?.images[0]?.image_url || "/images/broken-image.png"}
          alt={job?.job_title}
          sx={{
            height: "64px",
            width: "64px",
            borderRadius: "12px",
            objectFit: "cover",
          }}
        />
        <div className="flex flex-col items-start gap-1">
          <p className="text-base font-semibold text-grey">{job?.job_title}</p>
          <Chip
            size="small"
            color={
              job?.status.toLowerCase() === "active"
                ? "successInverse"
                : "errorInverse"
            }
            label={job?.status.replace("Active", "Open")}
          />
        </div>
      </div>

      <div className="mb-2 flex gap-2">
        <Avatar
          src={job?.creator?.avatar}
          alt={job?.creator?.full_name}
          sx={{height: "24px", width: "24px"}}
        />
        <p className="flex items-center text-base font-medium text-grey_20">
          <span className="truncate">{job?.creator?.full_name} </span>
          <span className="min-w-fit text-sm font-normal text-grey_40">
            &nbsp; - {"Client"}
          </span>
        </p>
      </div>
      <div className="my-2 flex justify-between">
        <div className="max-w-fit rounded-[12px] bg-[#FDF2E6] px-1.5 py-1 text-xs">
          Offers received
          <span className="mx-1 inline-flex h-4 w-4 items-center justify-center rounded-full bg-primary font-Bai text-white">
            {job?.offers_count}
          </span>
          of {job?.number_of_expected_request}
        </div>
        <p className="font-Bai font-semibold">
          {job?.price ? formatCurrency(job?.price) : "No budget"}
        </p>
      </div>
      <div className="mt-3 rounded-lg bg-white p-1.5">
        <div className="flex min-h-[50px] flex-wrap items-center justify-between gap-3 text-sm">
          <p className="flex items-center gap-x-1 text-sm text-ltext">
            <PlaceOutlinedIcon fontSize="small" /> Location{" "}
            <span className="max-w-[15ch] truncate">
              {job?.location_type?.toLowerCase() === "physical" ? (
                <span>
                  (Physical) - {job?.city?.name}, {job?.state?.name}
                </span>
              ) : (
                <span className="min-w-fit">(Virtual)</span>
              )}
            </span>
          </p>

          <p className="flex items-center gap-x-1 text-ltext">
            <AccessTimeOutlinedIcon fontSize="small" />
            <span className="min-w-fit">
              {`${job?.duration} ${
                job?.duration === 1
                  ? job?.duration_type.slice(0, -1)
                  : job?.duration_type
              }`}
            </span>
          </p>
          <BootstrapTooltip
            title={`${moneyFormatter(job?.views_count)} Views`}
            placement="top"
          >
            <p className="flex items-center gap-1 text-sm md:text-xs">
              <RemoveRedEyeOutlinedIcon sx={{color: "#3B3D3B"}} />
              <span>{moneyFormatter(job?.views_count)}</span>
            </p>
          </BootstrapTooltip>

          <BootstrapTooltip
            title={`${moneyFormatter(job?.views_count)} Views`}
            placement="top"
          >
            <p className="flex items-center gap-1 text-sm">
              <BookmarkBorderOutlinedIcon sx={{color: "#3B3D3B"}} />
              <span>{moneyFormatter(job?.saves_count)}</span>
            </p>
          </BootstrapTooltip>
        </div>

        {/* <div className="flex items-center">

        </div> */}
      </div>
    </Link>
  );
};

export default EachJobCard;
