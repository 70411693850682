import TablePagination from "components/mui-components/TablePagination";
import React, {useState} from "react";
import {useGetPendingRefundHistoriesQuery} from "services/admin/adminGeneralApi";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";
import EmptyResponse from "components/mui-components/EmptyResponse";
import UserCircleIcon from "components/Vectors/user-circle";
import {Button} from "@mui/material";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import RefundDetailsModal from "../widgets/RefundDetailsModal";
import {toCurrency} from "utils";
import moment from "moment";
import {useGetAllBanksQuery} from "services/generalApi";

function PendingRefundTab() {
  const [rowPerPage, setRowPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const queryParams = {
    page,
    per_page: rowPerPage,
    is_paginated: "yes",
  };
  const {data, isLoading, error, isError} =
    useGetPendingRefundHistoriesQuery(queryParams);
  const [openModal, setopenModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const {data: allBanks} = useGetAllBanksQuery();

  if (isLoading) return <CircularLoader />;
  if (isError) return <ErrorMsg error={error} />;
  return (
    <>
      {data?.data?.length > 0 ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          {data?.data?.map((item, i) => (
            <div className="rounded-2xl bg-grey_80 p-4">
              <UserDisplay
                name={item?.client?.full_name}
                img={item?.client?.avatar}
                size="text-[1rem]"
                email={`@${item?.client?.username}`}
              />

              <div className="mt-6 mb-3 flex items-center justify-between">
                <p className="font-Bai text-xl font-bold text-grey_20">
                  {toCurrency(item?.engagement?.transaction?.amount)}
                </p>
                <p className="text-sm font-medium text-grey_40">
                  {moment(item?.created_at).fromNow()}
                </p>
              </div>
              <div className="flex justify-end">
                <Button
                  variant="text"
                  sx={{fontSize: "16px !important", p: "0.6rem !important"}}
                  endIcon={<KeyboardArrowRightIcon />}
                  onClick={() => {
                    setopenModal(true);
                    setselectedItem(item);
                  }}
                >
                  Refund
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyResponse
          message={"No pending refund record"}
          icon={<UserCircleIcon />}
        />
      )}

      {data?.data?.length > 0 && (
        <TablePagination
          meta={{
            totalPages: data?.last_page,
            totalCount: data?.last_page * data?.per_page,
            currentPage: data?.current_page,
          }}
          from={data?.from}
          to={data?.to}
          page={page}
          setPage={setPage}
          perPage={rowPerPage}
          setPerPage={setRowPerPage}
        />
      )}

      <RefundDetailsModal
        allBanks={allBanks?.data || []}
        data={selectedItem}
        openModal={openModal}
        setopenModal={setopenModal}
      />
    </>
  );
}

export default PendingRefundTab;
