import ValidatedInput2 from "components/forms/ValidatedInput2";
import LoadingBtn from "components/mui-components/LoadingButton";
import MyModal from "components/mui-components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import {showToast} from "redux/store.hooks";
import {useGiveTokenMutation} from "services/admin/adminGeneralApi";
import {handleError} from "utils";

const GiveTokenModal = ({open, close, user_id}) => {
  const methods = useForm();
  const [giveToken, {isLoading}] = useGiveTokenMutation();
  const onSubmit = async data => {
    try {
      let res = await giveToken({user_id, ...data}).unwrap();
      methods.reset();
      showToast(res?.message);
      close();
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <MyModal
      openModal={open}
      closeModal={close}
      title="Give Tokens"
      maxWidth={500}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="mt-10 mb-28 flex flex-col items-center justify-center gap-y-3.5">
            <p className="font-Bai text-xl font-semibold text-grey_30 md:text-2xl">
              Enter Amount of Points
            </p>
            <ValidatedInput2 name="quantity" type="num" />
          </div>
          <LoadingBtn loading={isLoading} fullWidth type="submit">
            Submit
          </LoadingBtn>
        </form>
      </FormProvider>
    </MyModal>
  );
};

export default GiveTokenModal;
