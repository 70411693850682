import "pages/LandingPage/myStyles.scss";
import CategoryCard2 from "pages/LandingPage/widgets/CategoryCard2";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination, Navigation} from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import {Link} from "react-router-dom";
import {Button, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import CategoryIcon from "components/Vectors/CategoryIcon";

export const CategoriesSlider = ({
  categories,
  className = "col-span-4 w-[85%] md:w-[90%]",
  desktopSlides = 3,
  link,
}) => {

  return categories?.length > 0 ? (
    <div
      className={`categories-slider relative mx-auto w-full max-w-[1800px] cursor-pointer md:mx-0 md:w-full ${className}`}
    >
      <Swiper
        spaceBetween={5}
        slidesPerView={3}
        loop={categories?.length > 3 ? true : false}
        modules={[Navigation, Pagination, Autoplay]}
        navigation={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          type: "bullets",
          dynamicBullets: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
          1300: {
            slidesPerView: desktopSlides,
          },
        }}
      >
        {categories?.map(el => (
          <SwiperSlide
            className={`${desktopSlides >= 4 ? "after-next" : ""}`}
            key={el.name}
          >
            {({isActive, isNext}) => (
              <CategoryCard2
                type={el.name}
                image={el.cover_photo}
                count={el?.jobs_count}
                description={el.description}
                isNext={isNext}
                isActive={isActive}
                link={link}
                id={el?.id}
                afterNext={desktopSlides >= 4 ? isNext : false}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : (
    <div className="mx-4 flex min-h-[473px] w-full max-w-[494px] flex-col items-center justify-center rounded-[16px] bg-white md:mx-0 md:min-w-[494px] md:rounded-[35px]">
      <CategoryIcon />

      <p className="mt-5 text-2xl font-semibold text-black md:text-[32px]">
        No categories yet
      </p>
      <p className="text-grey20 mt-1 max-w-[323px] text-center text-sm font-medium md:text-base">
        To provide an excellent service you’ll like, we are taking time to
        create categories to meet your needs. Thanks for your patience.
      </p>
    </div>
  );
};

const FirstSlider = ({categories}) => {
  return (
    <section
      className='mt-28 grid grid-cols-1 items-center gap-8 pb-20 md:pl-[4vw] lg:grid-cols-6'
    >
      <div className="relative col-span-2 w-screen px-8 md:w-auto md:px-0">
        <div className="text-text md:-mt-24 md:pr-14">
          <p className="mb-2 font-semibold text-primary">Monetize your skill</p>
          <h2 className="mb-5 font-Bai text-2xl font-semibold md:text-5xl">
            Explore Jobs by Category
          </h2>

          <FormGroup>
            <FormControlLabel
              control={
                <span className="mr-2 flex h-7 w-7 items-center justify-center rounded-lg bg-white">
                  <Checkbox checked disabled size="small" />
                </span>
              }
              label="No cost to join"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px !important",
                },
              }}
            />
            <FormControlLabel
              control={
                <span className="my-2 mr-2 flex h-7 w-7 items-center justify-center rounded-lg bg-white">
                  <Checkbox checked disabled size="small" />
                </span>
              }
              label="Earn extra income on a flexible schedule "
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px !important",
                },
              }}
            />
            <FormControlLabel
              control={
                <span className="mr-2 flex h-7 w-7 items-center justify-center rounded-lg bg-white">
                  <Checkbox checked disabled size="small" />
                </span>
              }
              label="Grow your business and client base"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px !important",
                },
              }}
            />
          </FormGroup>

          <Button
            size="large"
            component={Link}
            to="/categories"
            variant="contained"
            color="primary"
            sx={{fontSize: "1.1rem", mt: 1.5, textTransform: "none"}}
          >
            See all Categories
          </Button>
        </div>
      </div>
      <CategoriesSlider categories={categories?.slice(0, 5)} />
    </section>
  );
};

export default FirstSlider;
