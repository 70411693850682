const HourglassIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6981 18.9587H7.29806C5.72306 18.9587 4.57306 18.3003 4.1314 17.167C3.67306 15.9837 4.09806 14.517 5.19806 13.5253L9.06473 10.0003L5.19806 6.47533C4.09806 5.48366 3.67306 4.01699 4.1314 2.83366C4.57306 1.69199 5.72306 1.04199 7.29806 1.04199H12.6981C14.2731 1.04199 15.4231 1.70033 15.8647 2.83366C16.3231 4.01699 15.8981 5.48366 14.7981 6.47533L10.9314 10.0003L14.8064 13.5253C15.8981 14.517 16.3314 15.9837 15.8731 17.167C15.4231 18.3003 14.2731 18.9587 12.6981 18.9587ZM9.99806 10.842L6.03973 14.442C5.33973 15.0837 5.0314 16.017 5.29806 16.7087C5.54806 17.3503 6.2564 17.7087 7.29806 17.7087H12.6981C13.7397 17.7087 14.4481 17.3587 14.6981 16.7087C14.9647 16.017 14.6647 15.0837 13.9564 14.442L9.99806 10.842ZM7.29806 2.29199C6.2564 2.29199 5.54806 2.64199 5.29806 3.29199C5.0314 3.98366 5.3314 4.91699 6.03973 5.55866L9.99806 9.15866L13.9564 5.55866C14.6564 4.91699 14.9647 3.98366 14.6981 3.29199C14.4481 2.65033 13.7397 2.29199 12.6981 2.29199H7.29806Z"
        fill={props.color ? props.color : "#2C2C3A"}
      />
    </svg>
  );
};
export default HourglassIcon;
