import {Chip} from "@mui/material";
import {Link} from "react-router-dom";
import UserDisplay from "./mui-components/TableComponent/UserDisplay";
import LongArrowRightIcon from "./Vectors/LongArrowRightIcon";
import MarkerPinIcon from "./Vectors/MarkerPinIcon";
import StarFilledIcon from "./Vectors/StarFilledIcon";

const UserDetailCard = ({link, headerText, userType}) => {
  return (
    <div className="flex flex-col gap-[30px] rounded-20 bg-grey_80 p-[24px] ">
      <Chip
        sx={{width: "fit-content"}}
        label="Service provider"
        color="secondaryInverse"
      />

      <h2 className="mt-3 font-Bai text-lg font-medium text-grey_10 ">
        {" "}
        {headerText}
      </h2>

      <h3
        className={` ${
          !userType && "hidden"
        } w-fit rounded-[8px]  bg-[#8D64CE1A] px-[12px] py-[6px] text-base font-bold text-[#5010B3] `}
      >
        {userType}
      </h3>
      <div className="flex items-center justify-between ">
        <UserDisplay
          // imgRounded={false}
          max_width="100%"
          name="Mirian Achonwa"
          email="@Mirian"
          img="/images/notif_img.png"
          size="large"
          emailTextColor="#6E726E"
          fontSize="font-[700]"
        />
        <div
          className="text-prop flex items-center gap-[3px] rounded-[8px]
           bg-white p-[8px] text-base font-medium text-[#E98100] "
        >
          <StarFilledIcon color="#E98100" />
          <span>4.0</span>
        </div>
      </div>
      <div className="flex items-center justify-between ">
        <div className="text-prop flex items-center gap-[8px] text-base font-normal text-grey_30 ">
          <MarkerPinIcon />
          <span>Physical - Ketu, Lagos</span>
        </div>
        <Link
          to={link}
          className="text-prop flex items-center gap-[8px] text-[15px] font-medium text-[#E98100]  "
        >
          <span>View Profile</span>
          <LongArrowRightIcon />
        </Link>
      </div>
    </div>
  );
};
export default UserDetailCard;
