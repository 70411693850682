import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import moment from "moment";
import {useGetSubscriptionHistoryQuery} from "services/admin/adminGeneralApi";

const HistoryTab = props => {
  const {data, isLoading, error, isError} = useGetSubscriptionHistoryQuery();
  const mockData = data?.data.map(item => ({
    user: {
      name: item?.user?.full_name,
      avatar: `${
        item?.professional_information?.business_logo || item?.avatar
      }`,
    },
    package: item.package.name,
    source: <p className="capitalize">{item?.source}</p>,
    allToken: item?.quantity,
    date: moment(item?.created_at).format("DD MMM YYYY h:mm a"),
    id: item?.id,
  }));

  return (
    <div>
      <div className=" w-full ">
        <MyDataTable
          scrollX={false}
          isLoading={isLoading}
          isError={isError}
          data={mockData}
          error={error}
          headCells={headCells}
        />
      </div>
    </div>
  );
};

export default HistoryTab;

const headCells = [
  {
    name: "user",
    label: "User",
    options: {
      customBodyRender: value => (
        <UserDisplay name={value.name} img={value.avatar} />
      ),
    },
  },
  {
    name: "package",
    label: "Package Name",
    options: {
      filter: true,
    },
  },
  {
    name: "allToken",
    label: "Number Of Tokens",
  },
  {
    name: "remainingToken",
    label: "Remaining Tokens",
  },

  {
    name: "date",
    label: "Date Purchased",
  },
];
