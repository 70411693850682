import {Avatar} from "@mui/material";
import {useEffect, useState} from "react";
import {get, useFormContext} from "react-hook-form";
import ImageFolderIcon from "components/Vectors/ImageFolderIcon";
import {ReactComponent as Video} from "assets/icons/video.svg";
import {useRef} from "react";
function UploadImage3({
  name,
  img,
  video = false,
  height,
  containerHeight,
  dashed,
  bgColor,
  borderRadius,
  iconColor,
  iconWidth = 30,
  iconHeight = 30,
  light = "",
  variant = "column",
  fileText,
  fileTypes,
  touchedCover,
  ...props
}) {
  const imageRef = useRef();
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    formState: {errors},
  } = useFormContext();

  let value = getValues(name);
  const [localImgUrl, setLocalImgUrl] = useState(value || "");
  useEffect(() => {
    register(name, {
      required: props.required ? "This field is required" : false,
      validate:
        typeof value === "string" || Boolean(fileTypes)
          ? undefined
          : {
              lessThan1MB: item =>
                item?.length > 0
                  ? item[0]?.size < 1000000 || "File too large"
                  : true,
            },
    });
    if (touchedCover === "submitted") {
      setLocalImgUrl("");
    }
  }, [name, value, register, props.required, touchedCover, fileTypes]);
  const error = get(errors, name);

  const handleChange = async e => {
    let file = e.target.files && e.target?.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener(
        "load",
        function () {
          setLocalImgUrl(reader?.result || "");
          setValue(name, e.target.files);
          clearErrors(name);
        },
        false,
      );
    }
  };

  return (
    <>
      <div
        className={`${containerHeight ? containerHeight : "h-full"} w-full ${
          borderRadius && borderRadius
        } ${bgColor ? bgColor : "bg-white"} ${
          dashed ? getVariant("dashed") : "rounded-10"
        } ${light && getVariant("light")}`}
      >
        <label
          htmlFor={name}
          className="relative flex h-full cursor-pointer  items-center justify-center gap-[8px]"
        >
          <>
            <input
              {...register(name, {
                required: props.required ? "This field is required" : false,
              })}
              accept={fileTypes ? undefined : "image/*"}
              type="file"
              onChange={handleChange}
              ref={imageRef}
              id={name}
              name={name}
              className="absolute inset-0 opacity-0"
            />

            <div
              className={`flex cursor-pointer items-center p-[10px] ${getVariant(
                variant,
              )}`}
            >
              <div>
                {video ? (
                  <Video />
                ) : (
                  <ImageFolderIcon
                    width={iconWidth}
                    height={iconHeight}
                    color={iconColor}
                  />
                )}
              </div>
              <div className="font-Mulish">
                <p className="text-base font-semibold text-grey_20">
                  <span className="text-primary">
                    {" "}
                    Upload a {fileText ? fileText : "file"}{" "}
                  </span>
                  or drag and drop
                </p>
                <p className="text-prop text-[14px] font-medium text-grey_30">
                  {fileTypes
                    ? fileTypes
                    : video
                    ? " .mp4, .mov, .webm max size - 10mb"
                    : "PNG, JPG, GIF not more than 1mb"}
                </p>
              </div>
            </div>
          </>

          {/* to show the image preview  */}
          {localImgUrl.length > 1 && (
            <div className="absolute inset-0 z-10 mb-4 h-full w-full cursor-pointer object-cover">
              <Avatar
                variant="rounded"
                sx={{
                  width: "100%",
                  objectFit: "cover",
                  height: "100%",
                }}
                src={localImgUrl}
              />
            </div>
          )}
        </label>
      </div>
      <div className="flex w-full items-center justify-between">
        <div className="">
          {localImgUrl.length > 0 && (
            <div className="mt-4 flex items-center gap-6 ">
              <button
                type="button"
                className={` ${
                  localImgUrl.length <= 0 && "hidden"
                }  w-fit rounded-[8px] bg-primary px-[12px] py-[5px] text-[13px] text-[#fff] `}
                onClick={() => imageRef.current.click()}
              >
                {fileTypes ? "Change file" : "Change image"}
              </button>

              <button
                type="button"
                className={` ${
                  localImgUrl.length <= 0 && "hidden"
                }  w-fit rounded-[8px] border border-[#D92D20] px-[12px] py-[5px] text-[13px] text-[#D92D20]`}
                onClick={() => {
                  setValue(name, null);
                  setLocalImgUrl("");
                }}
              >
                {fileTypes ? "Remove file" : "Remove image"}
              </button>
            </div>
          )}
        </div>
        <div className="">
          {error && error?.message && (
            <div className="input-err-msg pt-3 ">
              {error?.message || error?.name?.message}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default UploadImage3;
const getVariant = variant => {
  switch (variant) {
    case "dashed":
      return "border-[2px] p-3 rounded-20 border-dashed border-grey_60";
    case "light":
      return "bg-white";
    case "row":
      return "flex-row gap-4 w-full";
    case "column":
      return "flex-col justify-center gap-3 w-fit text-center";

    default:
      return "w-32 h-32";
  }
};
