const CancelIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill={props.color ? props.color : "#EB5757"}
      />
      <path
        d="M9.17011 15.5799C8.98011 15.5799 8.79011 15.5099 8.64011 15.3599C8.35011 15.0699 8.35011 14.5899 8.64011 14.2999L14.3001 8.63986C14.5901 8.34986 15.0701 8.34986 15.3601 8.63986C15.6501 8.92986 15.6501 9.40986 15.3601 9.69986L9.70011 15.3599C9.56011 15.5099 9.36011 15.5799 9.17011 15.5799Z"
        fill={props.color ? props.color : "#EB5757"}
      />
      <path
        d="M14.8301 15.5799C14.6401 15.5799 14.4501 15.5099 14.3001 15.3599L8.64011 9.69986C8.35011 9.40986 8.35011 8.92986 8.64011 8.63986C8.93011 8.34986 9.41011 8.34986 9.70011 8.63986L15.3601 14.2999C15.6501 14.5899 15.6501 15.0699 15.3601 15.3599C15.2101 15.5099 15.0201 15.5799 14.8301 15.5799Z"
        fill={props.color ? props.color : "#EB5757"}
      />
    </svg>
  );
};
export default CancelIcon;
