import { Chip } from "@mui/material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import StarIcon from "@mui/icons-material/Star";
import LanguageIcon from "components/Vectors/LanguageIcon";
import HourglassIcon from "components/Vectors/HourglassIcon";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
// import {useNavigate} from "react-router-dom";
// import {useEffect, useState} from "react";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
// import {useGetUser} from "hooks/getUserHook";
// import {
//   useAddServiceProviderToFavoriteMutation,
//   useDeleteServiceProviderFromFavoriteMutation,
// } from "services/user/clientGeneralApi";
// import {handleError} from "utils";
// import {showToast} from "redux/store.hooks";
// import LoadingBtn from "components/mui-components/LoadingButton";
import UserAvatar from "components/mui-components/TableComponent/UserAvatar";
import UserMinusIcon from "components/Vectors/UserMinusIcon";
import { BootstrapTooltip } from "components/TicketCards";
import AwardIcon from "components/Vectors/AwardIcon";

// const BlockBtn = ({children, type, user_id, ...props}) => {
//   const [block, {isLoading}] = useBlockUnblockUserMutation();
//   const blockHandler = async () => {
//     try {
//       const res = await block({type, body: {user_id}}).unwrap();
//       showToast(res?.message);
//     } catch (error) {
//       handleError();
//     }
//   };

//   return (
//     <LoadingButton onClick={blockHandler} isLoading={isLoading} {...props}>
//       {children}
//     </LoadingButton>
//   );
// };

function PersonalInformation({ data }) {
  // const [isFav, setIsFav] = useState(false);
  // const [addToFav] = useAddServiceProviderToFavoriteMutation();
  // const [removeFromFav] = useDeleteServiceProviderFromFavoriteMutation();
  // const { user, isLoggedIn } = useGetUser();
  // const { data: blockedUsers } = useGetBlockedUsersQuery(isLoggedIn);
  // const hasBlockedUser = blockedUsers?.data?.some(
  //   item => item?.blocked_user_id === data?.id,
  // );
  // useEffect(() => {
  //   setIsFav(data?.is_favourite);
  // }, [data]);
  // const handleFavoriteClick = () => {
  //   if (isFav) {
  //     handleRemoveFavorite(data?.id);
  //   } else {
  //     handleAddFavorite(data?.id);
  //   }
  // };
  // const handleAddFavorite = async id => {
  //   try {
  //     let res = await addToFav({service_provider_id: id}).unwrap();
  //     setIsFav(true);
  //     showToast(res?.message, "success");
  //   } catch (error) {
  //     handleError(error);
  //   }
  // };
  // const handleRemoveFavorite = async id => {
  //   removeFromFav({id, body: {service_provider_id: id}})
  //     .unwrap()
  //     .then(res => {
  //       setIsFav(false);
  //       showToast(res?.message, "success");
  //     })
  //     .catch(err => {
  //       handleError(err);
  //     });
  // };

  // console.log(data)

  return (
    <div className="rounded-2xl bg-white p-4 md:p-6">
      <div className="border-b pb-4">
        <div className="flex justify-between">
          <div>
            <Chip
              color="secondaryInverse"
              label={data?.user_type}
              sx={{
                borderRadius: "8px",
                fontWeight: 700,
                mb: 2,
                textTransform: "capitalize",
              }}
            />
            {/* {hasBlockedUser && (
              <Chip
                sx={{
                  borderRadius: "8px",
                  fontWeight: 700,
                  mb: 2,
                  ml: 2,
                  textTransform: "capitalize",
                }}
                label="Blocked"
                color="errorInverse"
              />
            )} */}
          </div>
          {/* {isLoggedIn && (
            <DropDownWrapper
              contentPadding="px-0"
              className="more-actions"
              position="right"
              action={
                <IconButton>
                  <MoreHorizOutlinedIcon fontSize="large" />
                </IconButton>
              }
            >
              <BlockBtn
                user_id={data?.id}
                type={hasBlockedUser ? "unblock" : "block"}
                startIcon={
                  hasBlockedUser ? (
                    <CheckCircleOutlineRoundedIcon />
                  ) : (
                    <DoNotDisturbRoundedIcon />
                  )
                }
                color={hasBlockedUser ? "success" : "error"}
              >
                {hasBlockedUser ? "Unblock" : "Block"}
              </BlockBtn>
            </DropDownWrapper>
          )} */}
        </div>
        <div className="flex justify-between">
          <div className="mb-6 flex items-center gap-3">
            <UserAvatar
              size={96}
              showBadge={
                data?.user_type?.toLowerCase() === "service provider" &&
                data?.is_verified_account
              }
              src={
                data?.user_type === "client"
                  ? data?.avatar
                  : data?.professional_information?.business_logo ||
                  data?.avatar
              }
            />

            <div>
              <div className="flex items-center gap-2">
                <p className="text-2xl font-semibold text-grey">
                  {data?.user_type === "client"
                    ? data?.full_name
                    : data?.professional_information?.business_name ||
                    data?.full_name}
                </p>

                {/* {data?.user_type?.toLowerCase() === "service provider" &&
                  data?.is_verified_account &&
                  data?.is_top_vetted && (
                    <BootstrapTooltip
                      title="This is a top vetted Service Provider we can recommend"
                      placement="bottom"
                    >
                      <span className="flex items-center gap-0.5 rounded-16 border-[0.4px] border-[#5010B3] bg-[#5010B314] px-[5px] py-[3px]">
                        <StarIcon sx={{ fontSize: "12px", color: "#5010B3" }} />
                        <p className="font-Mulish text-[10px] font-normal text-[#5010B3]">
                          Top vetted
                        </p>
                      </span>
                    </BootstrapTooltip>
                  )} */}

                {(data?.user_type?.toLowerCase() === "service provider" &&
                  data?.is_verified_account &&
                  data?.cac_certificate && data?.cac_certificate?.status === 'Approved'
                ) && (
                    <BootstrapTooltip
                      title="This is a registered Business recognized by the Corporate Affairs Commission (CAC)"
                      placement="bottom"
                    >
                      <span className="flex items-center gap-0.5 rounded-16 border-[0.4px] border-primary bg-[#FEFAF6] px-[8px] py-[3px]">
                        <p className="font-Mulish text-[10px] font-normal text-primary">
                          A Business
                        </p>
                      </span>
                    </BootstrapTooltip>
                  )}

                {data?.user_type?.toLowerCase() === "service provider" &&
                  data?.cac_certificate === null &&
                  (
                    <BootstrapTooltip
                      title="This user is a freelancer/Independent Professional"
                      placement="bottom"
                    >
                      <span className="flex items-center gap-0.5 rounded-16 border-[0.4px] border-primary bg-[#FEFAF6] px-[8px] py-[3px]">
                        <p className="font-Mulish text-[10px] font-normal text-primary">
                          Independent Professional
                        </p>
                      </span>
                    </BootstrapTooltip>
                  )}


              </div>
              <p className="text-lg font-normal text-grey_30">
                @{data?.username}
              </p>
            </div>
          </div>

          {/* {user?.id !== data?.id &&
            user?.user_type !== "Service provider" &&
            data?.user_type === "service provider" && (
              <div>
                <LoadingBtn
                  loading={isAddLoading || isRemovingFromFav}
                  iconColor="#000"
                  variant="text"
                  onClick={() =>
                    isLoggedIn ? handleFavoriteClick() : navigate("/user/login")
                  }
                >
                  {isFav ? (
                    <FavoriteOutlinedIcon sx={{ color: "#D92D20" }} />
                  ) : (
                    <FavoriteBorderIcon sx={{ color: "#6E726E" }} />
                  )}
                </LoadingBtn>
              </div>
            )} */}
        </div>

        {data?.user_type?.toLowerCase() === "service provider" &&
          !data?.is_verified_account && (
            <div className="my-2 flex items-start gap-6 rounded-20 bg-red-400 p-4 md:p-6">
              <div className="w[34px] h[34px] flex h-fit max-h-fit items-center justify-center rounded-[12px] bg-grey_60 p-[10px]">
                <UserMinusIcon width="24" height="24" color="#1d1d1d" />
              </div>
              <div>
                <p className="font-Bai text-lg font-semibold text-black md:text-xl">
                  Unverified Service Provider
                </p>
                <p className="mt-1 font-Mulish text-sm font-normal text-grey_20 md:text-base">
                  This User is yet to be approved of KYC info, we advice you
                  engage at your own discretion
                </p>
              </div>
            </div>
          )}


        {data?.user_type?.toLowerCase() === "service provider" &&
          data?.is_verified_account &&
          data?.is_top_vetted &&
          (
            <div className="my-2 flex items-start gap-6 rounded-20 bg-[#ECFDF3] p-4 md:p-6">
              <div className="w[34px] h[34px] flex h-fit max-h-fit items-center justify-center rounded-[12px] bg-white bg-opacity-80 p-[10px]">
                <AwardIcon width="24" height="24" color="#1d1d1d" />
              </div>
              <div>
                <p className="font-Bai text-lg font-semibold text-black md:text-xl">
                  Top Vetted
                </p>
                <p className="mt-1 font-Mulish text-sm font-normal text-grey_20 md:text-base">
                  This is a top vetted Service Provider we can recommend
                </p>
              </div>
            </div>
          )}
      </div>

      <div className="my-4 flex items-center gap-8">
        <div className="flex gap-3">
          <div className="flex h-[43px] w-[43px] items-center justify-center rounded-full bg-secondary-10 md:h-[48px] md:w-[48px]">
            <StarIcon color="white" />
          </div>
          <div>
            <h3 className="font-Bai text-2xl font-medium text-black md:text-3xl">
              {Number(data?.average_rating)?.toFixed(1)}
            </h3>
            <p className="text-sm font-normal text-grey_30">Rating</p>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex h-[43px] w-[43px] items-center justify-center rounded-full bg-secondary-10 md:h-[48px] md:w-[48px]">
            <ChatBubbleIcon color="white" />
          </div>
          <div>
            <h3 className="font-Bai text-2xl font-medium text-black md:text-3xl">
              {data?.ratings_received_count}
            </h3>
            <p className="text-sm font-normal text-grey_30">
              Review{data?.ratings_received_count <= 1 ? "" : "s"}
            </p>
          </div>
        </div>
      </div>

      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-grey_70">
            <LanguageIcon />
          </div>
          <p className="text-sm font-normal text-grey_20">Language</p>
        </div>
        <p className="text-base font-medium text-grey">
          {data?.languages?.map(language => language.name).join(", ") || "N/A"}
        </p>
      </div>

      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-grey_70">
            <PlaceOutlinedIcon sx={{ color: "#6E726E" }} />
          </div>
          <p className="text-sm font-normal text-grey_20">Location</p>
        </div>
        <p className="text-base font-medium text-grey">{data?.location}</p>
      </div>
      {data?.user_type === "service provider" &&
        data?.professional_information?.years_of_experience && (
          <div className="mb-4 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-grey_70">
                <HourglassIcon color="#6E726E" />
              </div>
              <p className="text-sm font-normal text-grey_20">
                Years of Experience
              </p>
            </div>
            <p className="text-base font-medium text-grey">
              {data?.professional_information?.years_of_experience || "N/A"}
            </p>
          </div>
        )}
      <p className="mb-1 text-2xl font-semibold text-grey ">About</p>
      <p className="mt-2 text-lg font-medium text-black">
        {data?.professional_information?.about || data?.about}
      </p>
    </div>
  );
}

export default PersonalInformation;
