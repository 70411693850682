import { useState } from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { Chip } from "@mui/material";
import { Link } from "react-router-dom";
import EmptyJob from "../Components/EmptyJob";
import MyDataTable from "components/mui-components/TableComponent/MyDataTable";
import { moneyFormatter, toCurrency } from "utils";
import UserDisplay from "components/mui-components/TableComponent/UserDisplay";
import ErrorMsg from "components/mui-components/ErrorMsg";
import CircularLoader from "components/Loader/CircularLoader";
import { useGetAllJobsByStatusQuery, } from "services/admin/adminGeneralApi";


const AllJobs = ({ title, otherOptions, status }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const {
    data: jobs,
    isLoading,
    error
  } = useGetAllJobsByStatusQuery({
    status,
    params: { is_paginated: "yes", page, per_page: perPage },
  });
  const data = jobs?.data;

  const handleChangePage = (page) => {
    setPage(page + 1)
  }
  const handlePerPage = async (perPage) => {
    setPerPage(perPage)

  }
  const options = {
    search: true,
    download: false,
    print: false,
    count: jobs?.total,
    rowsPerPage: perPage,
    serverSide: true,
    viewColumns: true,
    filter: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page);
          break;
        case 'changeRowsPerPage':
          handlePerPage(tableState.rowsPerPage);
          break;
        default:
          break;
      }
    },
    ...otherOptions,
  };

  const mockData = data?.map((el, i) => ({
    job: {
      image: el?.images[0]?.image_url,
      name: el?.job_title,
    },
    client: {
      name: el?.creator?.full_name,
      avatar: el?.creator?.avatar,
    },
    category: el?.category?.name,
    location: {
      name: `${el?.location_type}`,
      location: `${el?.location_type?.toLowerCase() === "physical"
        ? `${el?.city?.name}, ${el?.state?.name}`
        : ""
        }`,
    },
    duration: `${el?.duration} ${el?.duration === 1 ? el?.duration_type.slice(0, -1) : el?.duration_type
      }`,
    status: {
      name: el?.status.replace("Active", "Open"),
      count: el?.offers_count,
      expected: el?.number_of_expected_request,
    },
    views: moneyFormatter(el?.views_count),
    followers: el?.saves_count,
    budget: ` ${!el?.price ? "No Budget" : toCurrency(el?.price)?.toLocaleString()
      } `,

    option: (
      <>
        <Link to={`${el?.id}`}>
          <ArrowCircleRightOutlinedIcon color="primary" />
        </Link>
      </>
    ),
    id: `row_${i}`,
  }));

  if (isLoading) return <CircularLoader />;
  if (error) return <ErrorMsg error={error} />;
  return (
    <div>
      {data?.length > 0 ? (
        <div className="w-full">
          <MyDataTable
            hideChipDot={true}
            scrollX={true}
            data={mockData}
            headCells={headCells}
            options={options}
          />
        </div>
      ) : (
        <EmptyJob title={title} />
      )}
    </div>
  );
};

export default AllJobs;
const statusTextColor = {
  open: "#5010B3",
  closed: "#D92D20",
  drafted: "#3B3D3B",
  suspended: "#3B3D3B",
};
const headCells = [
  {
    name: "job",
    label: "Job",
    options: {
      customBodyRender: value => (
        <UserDisplay name={value.name} img={value.image} imgRounded={false} />
      ),
    },
  },
  {
    name: "client",
    label: "Client",
    options: {
      customBodyRender: value => (
        <UserDisplay name={value.name} img={value.avatar} />
      ),
    },
  },
  {
    name: "category",
    label: "Category",
    options: {
      filter: true,
    },
  },
  {
    name: "location",
    label: "Location",
    options: {
      customBodyRender: value => (
        <div>
          <p className="text-prop text-base font-medium text-grey_20">
            {value.name}{" "}
            <span className="block text-grey_40 ">{value.location}</span>
          </p>
        </div>
      ),
    },
  },
  {
    name: "budget",
    label: "Price",
  },
  {
    name: "duration",
    label: "Duration",
  },
  {
    name: "status",
    label: "Status",
    options: {
      customBodyRender: value => (
        <Chip
          color={value?.name === "Closed" ? "error" : "greyBorder"}
          label={
            <div className="flex items-center gap-x-2.5">
              <span
                className={`text-sm text-[${statusTextColor[value?.name.toLowerCase()]
                  }]`}
              >
                {value?.name}
              </span>
              <span className="rounded-20 bg-white p-1 text-text">
                <span className="inline-flex h-4 w-4 items-center justify-center rounded-full bg-primary font-Bai text-xs text-white">
                  {value?.count}
                </span>{" "}
                of {value?.expected}
              </span>
            </div>
          }
        />
      ),
    },
  },
  {
    name: "views",
    label: "Views",
  },
  {
    name: "followers",
    label: "Followers",
  },
  {
    name: "option",
    label: "",
  },
];
