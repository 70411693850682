import {useState, useEffect} from "react";
import {ReactComponent as Clock} from "assets/images/clock.svg";
import {ReactComponent as Location} from "assets/images/location.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {Avatar, Button, Chip} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import LoadingBtn from "components/mui-components/LoadingButton";
import {useGetUser} from "hooks/getUserHook";
import {
  useFollowJobMutation,
  useUnFollowJobMutation,
} from "services/user/providerGeneralApi";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import {BootstrapTooltip} from "components/TicketCards";
import {showToast} from "redux/store.hooks";
import {handleError, moneyFormatter} from "utils";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {JobsStatusColor} from "utils/constants";

const JobCard = ({
  job,
  setShouldReload = () => {},
  className = "bg-grey_80",
}) => {
  const {isLoggedIn, user} = useGetUser();
  const isProfessional = user?.user_type?.toLowerCase() === "service provider";

  const {
    id,
    creator,
    category,
    job_title,
    location_type,
    city,
    duration,
    duration_type,
    state,
    is_following,
    images,
    status,
    price,
    user_id,
    saves_count = 0,
    views_count = 0,
    offers_count = 0,
    number_of_expected_request = 1,
  } = job;
  const navigate = useNavigate();
  const [isFav, setIsFav] = useState(false);

  useEffect(() => {
    setIsFav(is_following);
    setShouldReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [follow, {isLoading: isFollowing}] = useFollowJobMutation();
  const [unfollow, {isLoading: isUnfollowing}] = useUnFollowJobMutation();
  const onFav = () => {
    if (isFav) handleUnfollow();
    else handleFollow();
  };

  const handleFollow = () => {
    setShouldReload(false);
    follow({client_job_id: id})
      .unwrap()
      .then(res => {
        setIsFav(true);
        showToast(res?.message, "success");
        setShouldReload(false);
      })
      .catch(err => {
        handleError(err);
      })
      .finally(() => setShouldReload(false));
  };
  const handleUnfollow = () => {
    unfollow(id)
      .unwrap()
      .then(res => {
        setIsFav(false);
        showToast(res?.message, "success");
        setShouldReload(false);
      })
      .catch(err => {
        handleError(err);
      })
      .finally(() => setShouldReload(false));
  };
  return (
    <div
      key={id}
      className={`relative flex min-h-[350px] w-full flex-col items-center justify-between rounded-[39px] ${className} bg-cover bg-center bg-no-repeat p-3`}
    >
      <Link
        className="absolute inset-0 z-[5]"
        to={
          !isProfessional
            ? `/jobs/${id}`
            : `/service-provider/marketplace/${id}`
        }
      ></Link>
      <div className="relative h-[220px] w-full rounded-25">
        <img
          src={images[0]?.image_url || "/images/broken-image.png"}
          alt=""
          className="absolute inset-0 h-full w-full rounded-[29px] object-cover"
        />
        <div className="absolute flex w-full items-center justify-between px-2">
          <Chip
            className="relative z-[5]"
            label={status?.replace("Active", "Open")}
            sx={{my: 2}}
            color={
              JobsStatusColor[status?.replace("Active", "Open")?.toLowerCase()]
            }
          />

          {(user?.user_type?.toLowerCase() === "service provider" ||
            !isLoggedIn) && (
            <BootstrapTooltip
              title={isFav ? "Unfollow Job" : "Follow Job"}
              placement="top"
            >
              <div className="z-[5] flex h-[48px] w-[48px] items-center justify-center rounded-16 bg-[#fcfcfd] shadow">
                <LoadingBtn
                  variant="text"
                  loading={isFollowing || isUnfollowing}
                  onClick={() =>
                    isLoggedIn ? onFav() : navigate("/user/login")
                  }
                  iconColor="#6E726E"
                  color="secondary"
                >
                  {isFav ? (
                    <BookmarkRoundedIcon color="primary" />
                  ) : (
                    <BookmarkBorderRoundedIcon sx={{color: "#6E726E"}} />
                  )}
                </LoadingBtn>
              </div>
            </BootstrapTooltip>
          )}
        </div>
      </div>

      <div className="relative w-full p-2 py-4">
        <button
          className="absolute -top-12 right-6 z-10 h-[60px] w-[60px] rounded-full bg-white md:-top-8 md:right-8"
          onClick={() =>
            navigate(
              isLoggedIn
                ? `/user-profile/${user_id}?type=client`
                : `/job-creator/${user_id}?type=client`,
            )
          }
        >
          <Avatar
            src={creator?.avatar}
            alt={job_title}
            sx={{
              height: "60px",
              width: "60px",
              border: "3px solid #fff",
              "&:hover": {
                border: "1px solid #fff",
              },
            }}
          />
        </button>
        <p className="text-xs font-medium text-primary md:text-sm">
          {category?.name}
        </p>
        <p className="max-w-full truncate font-Bai text-base font-semibold text-ltext md:text-lg">
          {job_title}
        </p>
        <p className="my-2 flex max-w-full items-center gap-x-1 truncate text-xs text-ltext md:text-sm">
          <Location />
          <span>
            Location{" "}
            {location_type.toLowerCase() === "physical" ? (
              <span className="">
                (Physical) - {city?.name}, {state?.name}
              </span>
            ) : (
              <span className="">(Virtual)</span>
            )}
          </span>
        </p>

        <p className="my-2 flex items-center gap-x-1 text-xs capitalize text-ltext md:text-sm">
          <Clock /> Expected Duration -{" "}
          {`${duration} ${
            duration === 1 ? duration_type.slice(0, -1) : duration_type
          }`}
        </p>

        <div className="my-1 flex flex-wrap justify-between gap-3 text-sm">
          <div className="max-w-fit rounded-[10px] bg-[#FDF2E6] p-1.5 text-xs">
            No. of offers received
            <span className="mx-1 inline-flex h-[18px] w-[18px] items-center justify-center rounded-full bg-primary font-Bai text-white">
              {offers_count}
            </span>
            of {number_of_expected_request}
          </div>
          <div className="z-[6] flex flex-wrap items-end gap-2">
            <BootstrapTooltip
              title={`${moneyFormatter(views_count)} Views`}
              placement="top"
            >
              <div className="flex items-center gap-1">
                <VisibilityOutlinedIcon
                  sx={{color: "#3B3D3B", fontSize: "18px"}}
                />
                <span>{moneyFormatter(views_count)}</span>
              </div>
            </BootstrapTooltip>
            <BootstrapTooltip
              title={`${moneyFormatter(saves_count)} followers`}
              placement="top"
            >
              <span className="flex items-center gap-1">
                <BookmarkBorderRoundedIcon
                  sx={{color: "#3B3D3B", fontSize: "18px"}}
                />
                <span>{moneyFormatter(saves_count)}</span>
              </span>
            </BootstrapTooltip>
          </div>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <p className="font-Bai text-base font-semibold md:text-lg">
            {price ? `₦${price.toLocaleString()}` : "No Budget"}
          </p>
          <Button
            onClick={() =>
              navigate(
                !isProfessional
                  ? `/jobs/${id}`
                  : `/service-provider/marketplace/${id}`,
              )
            }
            endIcon={
              <ArrowForwardIosIcon
                sx={{
                  height: "14px",
                  width: "14px",
                }}
              />
            }
            sx={{p: "0 !important"}}
            className="text-sm md:text-base"
          >
            Details
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
