import React from "react";

function UserSquareIcon({color, width, height}) {
  return (
    <>
      <svg
        width={width || "29"}
        height={height || "30"}
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.6667 28.0902C4.47013 28.3337 5.55532 28.3337 7.4 28.3337H21.2667C23.1113 28.3337 24.1965 28.3337 25 28.0902M3.6667 28.0902C3.49443 28.038 3.33511 27.9747 3.18404 27.8977C2.43139 27.5142 1.81947 26.9023 1.43597 26.1496C1 25.294 1 24.1739 1 21.9337V8.06699C1 5.82678 1 4.70668 1.43597 3.85103C1.81947 3.09838 2.43139 2.48646 3.18404 2.10297C4.03969 1.66699 5.15979 1.66699 7.4 1.66699H21.2667C23.5069 1.66699 24.627 1.66699 25.4826 2.10297C26.2353 2.48646 26.8472 3.09838 27.2307 3.85103C27.6667 4.70668 27.6667 5.82678 27.6667 8.06699V21.9337C27.6667 24.1739 27.6667 25.294 27.2307 26.1496C26.8472 26.9023 26.2353 27.5142 25.4826 27.8977C25.3316 27.9747 25.1722 28.038 25 28.0902M3.6667 28.0902C3.66714 27.0111 3.67361 26.4401 3.76915 25.9598C4.18998 23.8442 5.84384 22.1903 7.95952 21.7695C8.47471 21.667 9.09425 21.667 10.3333 21.667H18.3333C19.5724 21.667 20.192 21.667 20.7071 21.7695C22.8228 22.1903 24.4767 23.8442 24.8975 25.9598C24.9931 26.4401 24.9995 27.0111 25 28.0902M19.6667 11.667C19.6667 14.6125 17.2789 17.0003 14.3333 17.0003C11.3878 17.0003 9 14.6125 9 11.667C9 8.72147 11.3878 6.33366 14.3333 6.33366C17.2789 6.33366 19.6667 8.72147 19.6667 11.667Z"
          stroke={color || "#E98100"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default UserSquareIcon;
