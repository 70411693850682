const ImageIcon = props => {
  return (
    <svg
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.69743 24.6372L13.4928 15.8418C14.0208 15.3138 14.2848 15.0498 14.5893 14.9509C14.8571 14.8639 15.1455 14.8639 15.4133 14.9509C15.7178 15.0498 15.9818 15.3138 16.5098 15.8418L25.2465 24.5786M17.668 17L21.4928 13.1752C22.0208 12.6471 22.2848 12.3831 22.5893 12.2842C22.8571 12.1972 23.1455 12.1972 23.4133 12.2842C23.7178 12.3831 23.9818 12.6471 24.5098 13.1752L28.3346 17M12.3346 9C12.3346 10.4728 11.1407 11.6667 9.66797 11.6667C8.19521 11.6667 7.0013 10.4728 7.0013 9C7.0013 7.52724 8.19521 6.33333 9.66797 6.33333C11.1407 6.33333 12.3346 7.52724 12.3346 9ZM8.06797 25H21.9346C24.1748 25 25.295 25 26.1506 24.564C26.9032 24.1805 27.5152 23.5686 27.8987 22.816C28.3346 21.9603 28.3346 20.8402 28.3346 18.6V7.4C28.3346 5.15979 28.3346 4.03969 27.8987 3.18404C27.5152 2.43139 26.9032 1.81947 26.1506 1.43597C25.295 1 24.1748 1 21.9346 1H8.06797C5.82776 1 4.70765 1 3.85201 1.43597C3.09936 1.81947 2.48744 2.43139 2.10394 3.18404C1.66797 4.03969 1.66797 5.15979 1.66797 7.4V18.6C1.66797 20.8402 1.66797 21.9603 2.10394 22.816C2.48744 23.5686 3.09936 24.1805 3.85201 24.564C4.70765 25 5.82776 25 8.06797 25Z"
        stroke={props.color || "#3B3D3B"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ImageIcon;
