const LongArrowRightIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3055 14.1148C10.1705 14.1148 10.0355 14.0623 9.92895 13.9498C9.7229 13.7323 9.7229 13.3723 9.92895 13.1548L13.8653 8.99984L9.92895 4.84484C9.7229 4.62734 9.7229 4.26734 9.92895 4.04984C10.135 3.83234 10.4761 3.83234 10.6821 4.04984L14.995 8.60234C15.2011 8.81984 15.2011 9.17984 14.995 9.39734L10.6821 13.9498C10.5755 14.0623 10.4405 14.1148 10.3055 14.1148Z"
        fill={props.color || "#E98100"}
      />
      <path
        d="M14.4979 9.5625H2.53973C2.24841 9.5625 2.00684 9.3075 2.00684 9C2.00684 8.6925 2.24841 8.4375 2.53973 8.4375H14.4979C14.7892 8.4375 15.0308 8.6925 15.0308 9C15.0308 9.3075 14.7892 9.5625 14.4979 9.5625Z"
        fill={props.color || "#E98100"}
      />
    </svg>
  );
};
export default LongArrowRightIcon;
