import {ReactComponent as Clock} from "assets/images/clock.svg";
import {ReactComponent as Location} from "assets/images/location.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {Avatar, Button, Chip} from "@mui/material";
import {useNavigate, useLocation} from "react-router-dom";
import {useGetUser} from "hooks/getUserHook";
import {JobsStatusColor} from "utils/constants";
import {toCurrency} from "utils";

const ChipStatusColor = {
  pending: "secondaryInverse",
  accepted: "successInverse",
  withdrawn: "errorInverse",
  declined: "errorInverse",
};

const OfferCard = ({offer, className = "bg-grey_80"}) => {
  const {isLoggedIn} = useGetUser();
  const {
    id = "",
    creator = "",
    category = "",
    job_title = "",
    location_type = "",
    city = "",
    duration = "",
    duration_type = "",
    state = "",
    images = "",
    status = "",
    user_id = "",
  } = offer?.job;
  const navigate = useNavigate();
  const location = useLocation();
  const isClientRoute = location.pathname.includes("/client");
  const isSPRoute = location.pathname.includes("/service-provider");

  return (
    <div
      key={id}
      className={`relative flex min-h-[350px] w-full flex-col items-center justify-between rounded-[39px] ${className} bg-cover bg-center bg-no-repeat p-3`}
    >
      <div className="relative h-[220px] w-full rounded-25">
        <img
          src={images[0]?.image_url || "/images/broken-image.png"}
          alt=""
          className="absolute inset-0 h-full w-full cursor-pointer rounded-[29px] object-cover"
          onClick={
            () =>
              navigate(
                isLoggedIn ?`/service-provider/marketplace/${id}` : `/job/${id},`
              )
          }
        />
        <div className="absolute top-2 z-10 flex w-full items-center justify-between px-2">
          <Chip
            label={status?.replace("Active", "Open")}
            color={
              JobsStatusColor[status?.replace("Active", "Open")?.toLowerCase()]
            }
          />
          {offer?.get_new_offer_conversation_count > 0 && (
            <div className="max-w-fit rounded-[50px] bg-white px-1.5 py-1 text-xs">
              <span className="mr-2.5 inline-flex h-[22px] w-[22px] items-center justify-center rounded-full bg-primary font-Bai text-white">
                {offer?.get_new_offer_conversation_count}
              </span>{" "}
              new
              {offer?.get_new_offer_conversation_count <= 1
                ? " message"
                : " messages"}
            </div>
          )}
        </div>
      </div>

      <div className="relative w-full p-2 py-4">
        <button
          className="absolute -top-12 right-6 h-[60px] w-[60px] rounded-full bg-white md:-top-8 md:right-8"
          onClick={() =>
            navigate(
              isLoggedIn
                ? isClientRoute
                  ? `/user-profile/${user_id}?type=service_provider`
                  : isSPRoute
                  ? `/user-profile/${user_id}?type=client`
                  : `/user-profile/${user_id}`
                : `/job-creator/${user_id}`,
            )
          }
        >
          <Avatar
            src={creator?.avatar}
            alt={job_title}
            sx={{
              height: "60px",
              width: "60px",
              border: "3px solid #fff",
              "&:hover": {
                border: "1px solid #fff",
              },
            }}
          />
        </button>
        <p className="text-xs font-medium text-primary md:text-sm">
          {category?.name}
        </p>
        <p className="max-w-full truncate font-Bai text-base font-semibold text-ltext md:text-lg">
          {job_title}
        </p>
        <p className="my-2 flex items-center gap-x-1 text-xs text-ltext md:text-sm">
          <Location />
          <span>
            {location_type.toLowerCase() === "physical" ? (
              <span className="">
                Location (Physical) - {city?.name}, {state?.name}
              </span>
            ) : (
              <span className="">Virtual</span>
            )}
          </span>
        </p>

        <p className="my-2 flex items-center gap-x-1 text-xs capitalize text-ltext md:text-sm">
          <Clock />
          <span>
            Expected Duration -{" "}
            {`${duration} ${
              duration === 1 ? duration_type.slice(0, -1) : duration_type
            }`}
          </span>
        </p>
        <p className="font-Bai text-base font-semibold md:text-lg">
          {offer?.amount_offered
            ? toCurrency(Number(offer?.amount_offered))
            : "No Budget"}
        </p>
        <div className="mt-2 flex items-center justify-between text-primary">
          <div className="flex gap-2 text-sm">
            <span className="text-grey_30">Offer Status</span>
            <Chip
              sx={{borderRadius: "8px"}}
              size="small"
              label={offer?.status}
              color={ChipStatusColor[offer?.status?.toLowerCase()]}
            />
          </div>
          <Button
            onClick={() =>
              navigate(
                !isLoggedIn
                  ? `/jobs/${id}`
                  : `/service-provider/marketplace/${id}`,
              )
            }
            endIcon={<ArrowForwardIosIcon />}
            size={"small"}
            sx={{p: "0 !important"}}
            className="text-sm md:text-base"
          >
            Details
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
