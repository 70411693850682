import {Skeleton, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import UserCircleIcon from "components/Vectors/user-circle";
import {useEffect, useState} from "react";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import DashboardBox from "templates/DashboardLayout/widgets/DashboardBox";
import ServiceProviderTab from "./Tabs/ServiceProviderTab";
import {CustomTabStyle2} from "App";
import {useGetAllServiceProviderQuery} from "services/admin/adminGeneralApi";

function AdminServiceProviders() {
  const [activeTab, setactiveTab] = useState("all");
  const navigate = useNavigate();

  const {
    data: allProviders,
    isLoading,
    isError,
    error,
  } = useGetAllServiceProviderQuery({
    pollingInterval: 3000,
  });

  const activeProviders = allProviders?.data.filter(
    value => value.status.toLowerCase() === "active",
  );

  const suspendedProviders = allProviders?.data.filter(
    value => value.status.toLowerCase() === "suspended",
  );

  useEffect(() => {
    const browserActiveTab = new URLSearchParams(window.location.search).get(
      "tab",
    );
    if (browserActiveTab) setactiveTab(browserActiveTab);
  }, []);

  return (
    <DashboardLayout title={"Service Providers"} type="admin">
      <main>
        <div className="rounded-2xl bg-white p-4 md:p-6">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="mb-4 font-Bai text-2xl font-medium text-black">
              Overview
            </h3>
          </div>
          <div className="boxes grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
            <DashboardBox
              text={"All Service Provider"}
              icon={<UserCircleIcon stroke={"#E98100"} />}
              value={
                isLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : isError ? (
                  "..."
                ) : (
                  String(allProviders?.data?.length)
                )
              }
            />
            <DashboardBox
              text={"Active Service Provider"}
              icon={<UserCircleIcon stroke={"#039855"} />}
              value={
                isLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : isError ? (
                  "..."
                ) : (
                  String(activeProviders?.length)
                )
              }
            />
            <DashboardBox
              text={"Suspended Service Provider"}
              icon={<UserCircleIcon stroke={"#6E726E"} />}
              value={
                isLoading ? (
                  <Skeleton variant="rounded" height={35} width={50} />
                ) : isError ? (
                  "..."
                ) : (
                  String(suspendedProviders?.length)
                )
              }
            />
          </div>
        </div>

        <div className="mt-6 rounded-2xl bg-white p-4 md:py-6 ">
          <TabContext value={activeTab}>
            <TabList
              variant="scrollable"
              value={activeTab}
              className="mb-4 w-fit rounded-[16px] border border-grey_60 bg-grey_80 p-2"
              onChange={(_, newValue) => {
                navigate(`?tab=${newValue}`);
                setactiveTab(newValue);
              }}
              sx={{
                fontWeight: 600,
                ...CustomTabStyle2,
                textTransform: "unset",
              }}
              aria-label="basic tabs example"
            >
              <Tab label="All Service Provider" value="all" />
              <Tab label="Active Service Provider" value="active" />
              <Tab label="Suspended Service Provider" value="suspended" />
            </TabList>
            <TabPanel value="all">
              <ServiceProviderTab
                data={allProviders?.data}
                isLoading={isLoading}
                isError={isError}
                error={error}
              />
            </TabPanel>
            <TabPanel value="active">
              <ServiceProviderTab
                data={activeProviders}
                isLoading={isLoading}
                isError={isError}
                error={error}
              />
            </TabPanel>
            <TabPanel value="suspended">
              <ServiceProviderTab
                data={suspendedProviders}
                isLoading={isLoading}
                isError={isError}
                error={error}
              />
            </TabPanel>
          </TabContext>
        </div>
      </main>
    </DashboardLayout>
  );
}

export default AdminServiceProviders;
