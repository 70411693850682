import {useState, useEffect} from "react";
import {Skeleton} from "@mui/material";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import LoadingBtn from "components/mui-components/LoadingButton";
import {handleError} from "utils";
import {FormProvider, useForm} from "react-hook-form";
import {showToast} from "redux/store.hooks";
import {
  useEnableDisableServiceChargeMutation,
  useGetAllServiceChargeOptionsQuery,
  useUpdateSingleServiceChargeOptionMutation,
} from "services/admin/adminGeneralApi";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

const ChargesTab = () => {
  const [clickedId, setClickedId] = useState(null);
  const methods = useForm({mode: "onChange"});
  const {data: allOptions, isLoading: isAllOptionsLoading} =
    useGetAllServiceChargeOptionsQuery();
  const [update, {isLoading: isUpdateLoading}] =
    useUpdateSingleServiceChargeOptionMutation();
  const [enableDisableOption] = useEnableDisableServiceChargeMutation();
  const {trigger, setValue} = methods;

  useEffect(() => {
    if (allOptions) {
      allOptions?.data.forEach(({option, value}) => {
        setValue(option, value);
      });
      allOptions?.data.forEach(({id, status}) => {
        setradioValues(prev => ({...prev, [id]: status}));
      });
    }
  }, [allOptions, setValue]);

  const [radioValues, setradioValues] = useState({});
  const handleFeildsChange = id => e => {
    const {value} = e.target;
    let newFormValues = {...radioValues};
    newFormValues[id] = value;
    setradioValues({...newFormValues});
    enableDisableHandler(id, {status: value});
  };

  const enableDisableHandler = async (id, body) => {
    try {
      await enableDisableOption({id, body}).unwrap();
      showToast(`Service charge successfully ${body.status.toLowerCase()}`);
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <div>
      <h1 className="my-8 font-Bai text-2xl font-semibold text-grey_20 md:text-3xl">
        Service Charges
      </h1>
      <div className="grid md:grid-cols-2">
        <FormProvider {...methods}>
          <form className="flex flex-col gap-y-10">
            {isAllOptionsLoading
              ? [1, 2, 3, 4, 5, 6].map(val => (
                  <Skeleton key={val} variant="rounded" height={70} />
                ))
              : allOptions?.data.map(
                  ({description, option, label, id}) => (
                    <div key={id} className="flex flex-col gap-y-4">
                      <p className="font-bold text-grey md:text-lg">
                        {description}
                      </p>
                      <div className="w-full">
                        <div className="flex w-full items-center gap-4">
                          <ValidatedInput2
                            type="number"
                            name={option}
                            label={label}
                          />
                          <LoadingBtn
                            sx={{mt: 2}}
                            loading={id === clickedId && isUpdateLoading}
                            onClick={async () => {
                              setClickedId(id);
                              trigger(option).then(async res => {
                                if (res) {
                                  try {
                                    let res = await update({
                                      id,
                                      body: {
                                        option,
                                        value: methods.getValues(option),
                                        description,
                                        label,
                                      },
                                    }).unwrap();
                                    if (res.error === false)
                                      showToast(res?.message, "success");
                                  } catch (error) {
                                    handleError(error);
                                  }
                                }
                              });
                            }}
                          >
                            Update
                          </LoadingBtn>
                        </div>
                        {(description.toLowerCase().includes("request") ||
                          description.toLowerCase().includes("free token")) && (
                          <FormControl
                            sx={{
                              mt: 2,
                            }}
                            fullWidth
                          >
                            <RadioGroup
                              row
                              value={radioValues[id] || ""}
                              onChange={handleFeildsChange(id)}
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                            >
                              <FormControlLabel
                                value="active"
                                control={<Radio />}
                                label={"Active"}
                              />
                              <FormControlLabel
                                value="inactive"
                                control={<Radio />}
                                label={"Inactive"}
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </div>
                      {/* {id === 4 ? (

                      ) : (
                        <div
                          className={`flex gap-x-4 ${
                            errors[option] ? "items-center" : "items-end"
                          }`}
                        >
                          <ValidatedInput2 name={option} label={label} />
                          <LoadingBtn
                            loading={id === clickedId && isUpdateLoading}
                            onClick={async () => {
                              setClickedId(id);
                              trigger(option).then(async res => {
                                if (res) {
                                  try {
                                    let res = await update({
                                      id,
                                      body: {
                                        option,
                                        value: methods.getValues(option),
                                        description,
                                        label,
                                      },
                                    }).unwrap();
                                    if (res.error === false)
                                      showToast(res?.message, "success");
                                  } catch (error) {
                                    handleError(error);
                                  }
                                }
                              });
                            }}
                          >
                            Update
                          </LoadingBtn>
                        </div>
                      )} */}
                    </div>
                  ),
                )}
            {/* : formList?.map(({title, name, label, id}, index) => (
              <div key={index} className="flex flex-col gap-y-4">
                    <p className="font-bold text-grey md:text-lg">{title}</p>
                    <div
                      className={`flex gap-x-4 ${
                        errors[name] ? "items-center" : "items-end"
                      }`}
                    >
                      <ValidatedInput2 name={name} label={label} />
                      <LoadingBtn
                        loading={id === clickedId && isUpdateLoading}
                        onClick={async () => {
                          setClickedId(id);
                          trigger(name).then(async res => {
                            if (res) {
                              try {
                                let res = await update({
                                  id: formList[index].id,
                                  body: {
                                    option: formList[index].name,
                                    value: methods.getValues(name),
                                    description: formList[index].title,
                                    label: formList[index].label,
                                  },
                                }).unwrap();
                                if (res.error === false)
                                  showToast(res?.message, "success");
                              } catch (error) {
                                handleError(error);
                              }
                            }
                          });
                        }}
                      >
                        Update
                      </LoadingBtn>
                    </div>
                  </div>
                ))} */}
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ChargesTab;
