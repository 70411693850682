const RepeatIcon = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.166 20.4996C14.8604 20.4996 18.666 16.694 18.666 11.9996C18.666 9.17407 17.2873 6.67054 15.166 5.12501M11.166 22.3996L9.16602 20.3996L11.166 18.3996M10.166 3.49961C5.4716 3.49961 1.66602 7.30519 1.66602 11.9996C1.66602 14.8251 3.04468 17.3287 5.16602 18.8742M9.16602 5.59961L11.166 3.59961L9.16602 1.59961"
        stroke="#E98100"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RepeatIcon;
