import {LoadingButton} from "@mui/lab";
import {CircularProgress} from "@mui/material";
import UploadImage3 from "components/forms/UploadImage3";
import ValidatedInput2 from "components/forms/ValidatedInput2";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";
import MyModal from "components/mui-components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import {showToast} from "redux/store.hooks";
import {useCreateCategoryMutation} from "services/admin/adminGeneralApi";
import {handleError} from "utils";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useState} from "react";

const CreateCategoryModal = ({open, close}) => {
  const {
    handleSubmit,

    ...methods
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    shouldUnregister: true,
  });
  const isSubmitSuccessful = methods.formState.isSubmitSuccessful
  const [featured, setFeatured] = useState("No");
  const [create, {isLoading}] = useCreateCategoryMutation();

  const handleChange = event => {
    setFeatured(event.target.checked ? "Yes" : "No");
  };

  const onSubmit = async data => {
    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("cover_photo", data.cover_photo[0]);
    formData.append("description", data.description);
    formData.append("is_featured", featured);
    formData.append("mobile_app_icon", data.icon[0]);

    try {
      let res = await create(formData).unwrap();
      showToast(res?.message, "success");
      methods.reset();
      setFeatured("No");
      close();
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <MyModal openModal={open} closeModal={close} title="Create Category">
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-y-4"
        >
          <ValidatedInput2
            name="name"
            label="Category Name"
            placeholder="Enter Category name"
          />
          <div>
            <span className="mb-1 inline-block text-[14px] text-grey_30">
              Cover Photo
            </span>
            <UploadImage3
              name="cover_photo"
              bgColor="bg-grey_80"
              dashed
              required={true}
              iconWidth={24}
              iconHeight={24}
              iconColor="#9CA3AF"
              touchedCover={isSubmitSuccessful ? "submitted" : ""}
            />
          </div>
          <ValidatedTextArea2
            name="description"
            label="Description"
            placeholder="Write something about the role here"
            height="min-h-[140px]"
          />
          <div>
            <span className="mb-1 inline-block text-[14px] text-grey_30">
              Mobile App Icon
            </span>
            <UploadImage3
              name="icon"
              bgColor="bg-grey_80"
              dashed
              iconWidth={24}
              iconHeight={24}
              required={true}
              iconColor="#9CA3AF"
              touchedCover={isSubmitSuccessful ? "submitted" : ""}
            />
          </div>

          <FormControlLabel
            // sx={{mt: 2}}
            value="available"
            control={
              <Checkbox checked={featured === "Yes"} onChange={handleChange} />
            }
            label="Feature On Homepage"
          />

          <LoadingButton
            sx={{mt: 4}}
            loadingIndicator={
              <CircularProgress
                sx={{
                  color: "#fff",
                }}
                size="1.2rem"
              />
            }
            type="submit"
            loading={isLoading}
            fullWidth
            variant="contained"
            color="primary"
          >
            Create
          </LoadingButton>
        </form>
      </FormProvider>
    </MyModal>
  );
};

export default CreateCategoryModal;
