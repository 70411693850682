import React from "react";

function ShieldUserIcon() {
  return (
    <svg
      width="45"
      height="46"
      viewBox="0 0 45 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 20.5C35.85 20.5 36.675 20.6 37.5 20.725V8.675L18.75 0.5L0 8.675V20.95C0 32.3 8 42.925 18.75 45.5C20.125 45.175 21.45 44.7 22.75 44.125C21.025 41.675 20 38.7 20 35.5C20 27.225 26.725 20.5 35 20.5Z"
        fill="#E98100"
      />
      <path
        d="M35 25.5C29.475 25.5 25 29.975 25 35.5C25 41.025 29.475 45.5 35 45.5C40.525 45.5 45 41.025 45 35.5C45 29.975 40.525 25.5 35 25.5ZM35 28.95C36.55 28.95 37.8 30.225 37.8 31.75C37.8 33.275 36.525 34.55 35 34.55C33.475 34.55 32.2 33.275 32.2 31.75C32.2 30.225 33.45 28.95 35 28.95ZM35 42.375C32.675 42.375 30.65 41.225 29.4 39.45C29.525 37.65 33.175 36.75 35 36.75C36.825 36.75 40.475 37.65 40.6 39.45C39.35 41.225 37.325 42.375 35 42.375Z"
        fill="#F8D8B0"
      />
    </svg>
  );
}

export default ShieldUserIcon;
