import {CircularProgress} from "@mui/material";
import MyModal from "components/mui-components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import TagsInput2 from "../components/TagsInput2";
import {useCreateCityMutation} from "services/admin/adminGeneralApi";
import {LoadingButton} from "@mui/lab";
import {handleError} from "utils";

const AddCityModal = ({openModal, setopenModal, stateID}) => {
  const methods = useForm();

  const city = methods.watch("city");

  const [createCity, {isLoading}] = useCreateCityMutation();

  const onSubmit = async body => {
    try {
      createCity({
        state_id: stateID,
        cities: body.city,
      }).unwrap();
      methods.reset();
      setopenModal(false);
    } catch (error) {
      handleError(error);
      setopenModal(false);
    }
  };

  return (
    <MyModal
      openModal={openModal}
      closeModal={() => setopenModal(false)}
      title="Add city"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex h-[290px] flex-col justify-between"
        >
          <div>
            <p className="mb-3  text-sm font-normal text-grey_20">
              City/Town{" "}
              <span className="inline text-grey_40">
                {" "}
                (Separate cities by clicking comma ‘,’){" "}
              </span>
            </p>
            <TagsInput2 name="city" required={true} />
          </div>

          <LoadingButton
            sx={{mt: 4}}
            loadingIndicator={
              <CircularProgress
                sx={{
                  color: "#fff",
                }}
                size="1.2rem"
              />
            }
            type="submit"
            loading={isLoading}
            fullWidth
            variant="contained"
            color="primary"
          >
            {`Add ${city?.length} Cities/Towns`}
          </LoadingButton>
        </form>
      </FormProvider>
    </MyModal>
  );
};

export default AddCityModal;
