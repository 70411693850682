import MyModal from "components/mui-components/Modal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const IncompleteModal = ({
  open,
  close,
  issues = "profile settings",
  isProfessional,
  description,
}) => {
  const navigate = useNavigate();
  // const issueArray = issues.split(",");
  // let tab = "";
  // if (issueArray.length === 1) {
  //   tab = `?tab=${issueArray[0].toLowerCase().replace(/\s/g, "_")}`;
  // }
  return (
    <MyModal openModal={open} closeModal={close} maxWidth={500}>
      <div className="mt-6 flex flex-col items-center">
        <span className="flex h-[90px] w-[90px] items-center justify-center rounded-full bg-[#F2F4F7]">
          <ErrorOutlineOutlinedIcon
            color="primary"
            sx={{ height: "64px", width: "64px" }}
          />
        </span>
        <p className="text-center font-Bai text-4xl font-semibold">
          You’re yet to get verified
        </p>
        <p className="mb-6 mt-2 text-center text-lg font-medium text-grey_30">
          {description ||
            `Your ${issues || "profile settings"
            } is still pending completion. You
          won’t be able to ${isProfessional ? "apply for" : "post"
            } a Job or send
          Messages to ${isProfessional ? "Clients" : "Service Providers"}.
            `}
        </p>
        <Button
          variant="contained"
          onClick={() => {
            close();
            navigate(
              isProfessional
                ? `/service-provider/settings?tab=kyc`
                : `/client/settings?tab=kyc`,
            );
          }}
          sx={{ minWidth: "180px" }}
        >
          Go to settings
        </Button>
      </div>
    </MyModal>
  );
};

export default IncompleteModal;
