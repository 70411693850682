import React from "react";

function UserIcon03({color}) {
  return (
    <div>
      <svg
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.33398 18C3.66977 15.5226 6.841 14 10.334 14C13.827 14 16.9982 15.5226 19.334 18M14.834 5.5C14.834 7.98528 12.8193 10 10.334 10C7.8487 10 5.83398 7.98528 5.83398 5.5C5.83398 3.01472 7.8487 1 10.334 1C12.8193 1 14.834 3.01472 14.834 5.5Z"
          stroke={color || "#E98100"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default UserIcon03;
