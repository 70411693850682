import {Fade, CircularProgress, Button} from "@mui/material";
import {Box} from "@mui/system";
import MyModal from "components/mui-components/Modal";
import {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{position: "relative", display: "inline-flex"}}>
      <CircularProgress
        size={110}
        thickness={1.5}
        sx={{background: "#F9FAFB", borderRadius: "100%"}}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h3 className="text-3xl font-bold text-primary">
          {`${Math.round(props?.label || props.value)}`}
        </h3>
      </Box>
    </Box>
  );
}

function OnboardingServiceModal({
  setIsFinished,
  openModal = true,
  setOpenModal,
}) {
  const [presentStep, setPresentStep] = useState(1);
  const [changeStep, setChangeStep] = useState(true);
  const steps = [
    {
      title: "Select a category",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna",
    },
    {
      title: "Describe your services about this category generally",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna",
    },
    {
      title: "Select areas of expertise (max 5)",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna",
    },
  ];
  const closeModal = () => {
    setOpenModal(false);
    setPresentStep(1);
  };
  return (
    <MyModal
      className="min-h-[605px] !py-0 md:max-h-[605px] lg:!max-w-[1050px]"
      openModal={openModal}
      closeModal={closeModal}
      maxWidth={"unset"}
    >
      <div className="my-6 grid grid-cols-1 gap-4 md:my-0 md:grid-cols-2">
        <Fade in={changeStep}>
          <img
            src={`/images/service_onboarding/step${presentStep}.png`}
            alt={steps[presentStep - 1]?.title}
            className="hidden h-full w-full md:block"
          />
        </Fade>
        <div className="flex items-center justify-center">
          <div className="max-w-[350px] space-y-4">
            <CircularProgressWithLabel
              label={presentStep}
              value={(presentStep / 3) * 80}
            />
            <div className="space-y-3 py-3">
              <p className="font-Bai text-2xl font-bold">
                {steps[presentStep - 1]?.title}
              </p>
              <p>{steps[presentStep - 1]?.description}</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Button
                onClick={() => {
                  setPresentStep(prev => (prev <= 1 ? prev : prev - 1));
                  if (presentStep > 1) {
                    setChangeStep(false);
                    setTimeout(() => {
                      setChangeStep(true);
                    }, 200);
                  }
                }}
                variant="outlined"
                startIcon={<WestOutlinedIcon />}
              >
                Previous
              </Button>
              <Button
                onClick={() => {
                  setPresentStep(prev => (prev >= 3 ? prev : prev + 1));
                  if (presentStep < 3) {
                    setChangeStep(false);
                    setTimeout(() => {
                      setChangeStep(true);
                    }, 200);
                  }
                  if (presentStep === 3) {
                    setIsFinished(true);
                    closeModal();
                  }
                }}
                variant="contained"
                startIcon={<EastOutlinedIcon />}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </MyModal>
  );
}

export default OnboardingServiceModal;
