import React from 'react'
import EachUpdate from './EachUpdate'

const LatestUpdates = ({ maxHeight }) => {
    return (
        <div className={`relative ${maxHeight || "max-h-[440px]"}  lg:col-span-4 p-4 md:p-6 grid border border-[#DCDCE0] rounded-3xl`}>
            <div className="absolute -top-[18px] bg-white left-10 rounded-[200px] font-semibold text-black text-xs px-3 py-2 border-[#DCDCE0] border">
                🔥 Latest Updates
            </div>

            <div className="flex flex-col gap-2 overflow-y-auto scrollBarstyle">
                <EachUpdate />
                <EachUpdate />
                <EachUpdate />
                <EachUpdate />
                <EachUpdate />
                <EachUpdate />
                <EachUpdate />
                <EachUpdate />
                <EachUpdate />
                <EachUpdate />
                <EachUpdate />
            </div>
        </div>
    )
}

export default LatestUpdates