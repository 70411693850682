import AutoCompleteField from "components/forms/AutoCompleteField";
import ValidatedTextArea2 from "components/forms/ValidatedTextArea2";

const SelectCategory = ({isLoading, categories}) => {
  return (
    <section className="">
      <div className="flex items-center justify-between px-4 md:px-10">
        <p className="text-sm font-medium text-grey_30 md:text-lg">
          Select category
        </p>

        <p className="text-base font-bold text-grey md:text-2xl">
          1 <sup className="text-[13px] font-normal text-grey_40">3</sup>
        </p>
      </div>
      <div className="mt-5 h-px bg-grey_60"></div>
      <div className=" w-full p-4">
        <div className="flex flex-col gap-y-6  rounded-20 bg-grey_80 p-4 md:p-6">
          <AutoCompleteField
            label="Category"
            name="category"
            loading={isLoading}
            placeholder="Select Category"
            selectOption={categories?.map(item => ({
              label: item?.name,
              value: item?.id,
            }))}
          />
          <ValidatedTextArea2
            name="description"
            maxLength="250"
            label="Description"
            height="h-[130px]"
            placeholder="Describe this category"
          />
        </div>
      </div>
    </section>
  );
};

export default SelectCategory;
