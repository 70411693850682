import moment from "moment";

function ProfessionalInfoDetails({user}) {
  return (
    <div className="space-y-5 rounded-24 font-medium md:bg-grey_80 md:p-8">
      <h3 className="mb-6 font-Bai text-2xl font-semibold text-grey">
        Personal Information
      </h3>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">First Name</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.first_name || "N/A"}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">Last Name</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.last_name || "N/A"}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">About Me</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.about || "No Bio"}
        </p>
      </div>

      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Email Address</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.email || 'N/A'}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">Languages</p>
        <p className="my-2 text-lg font-medium text-[#000000]">
         {user?.languages?.map((lang) => lang.name).join(', ') || 'N/A'}
        </p>
      </div>
      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Phone</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.phone_number || "N/A"}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">Sex</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.gender || "N/A"}
        </p>
      </div>
      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Date of Birth</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {moment(user?.dob).format('DD MMM yyy') || "N/A"}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">Country</p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.country || "N/A"}
        </p>
      </div>
      <div className="my-6">
        <p className="mb-0 text-lg font-medium text-grey_30">Location</p>

        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.city?.name && user?.state?.name
            ? `${user?.city?.name}, ${user?.state?.name}`
            : "N/A"}
        </p>
      </div>
      <div>
        <p className="mb-0 text-lg font-medium text-grey_30">
          Residential Address
        </p>
        <p className="my-2 font-medium text-[#000000] md:text-lg">
          {user?.residential_address === "undefined"
            ? "N/A"
            : user?.residential_address}
        </p>
      </div>
    </div>
  );
}

export default ProfessionalInfoDetails;
