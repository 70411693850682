import ValidatedInput2 from "components/forms/ValidatedInput2";
import LoadingBtn from "components/mui-components/LoadingButton";
import Modal from "components/mui-components/Modal";
import MessageModal from "components/mui-components/Modal/MessageModal";
import SendIcon from "components/Vectors/SendIcon";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { showToast } from "redux/store.hooks";
import { useAddClientMutation } from "services/admin/adminGeneralApi";
import { handleError } from "utils";

const AddNewUserModal = ({ open, close }) => {
  const methods = useForm();
  const [openSend, setOpenSend] = useState(false);
  const url = window.location.origin;

  const [addClient, { isLoading }] = useAddClientMutation();
  const onSubmit = async data => {
    try {
      const res = await addClient({
        login_redirect_url: `${url}/user/login`,
        ...data,
      }).unwrap();
      close()
      showToast(res?.message, "success");
      setOpenSend(true);
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <>
      <Modal
        title='Add New User'
        openModal={open}
        closeModal={close}
      >
        <FormProvider {...methods}>
          <form
            className="flex w-full flex-col gap-4"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="flex items-center justify-between gap-4">
              <ValidatedInput2
                textSize="text-sm"
                label="First Name"
                type="text"
                placeholder="Enter First name"
                name="first_name"
              />

              <ValidatedInput2
                textSize="text-sm"
                label="Last Name"
                type="text"
                placeholder="Enter Last name"
                name="last_name"
              />
            </div>
            <ValidatedInput2
              textSize="text-sm"
              label="Email Address"
              type="email"
              placeholder="Enter Email Address"
              name="email"
            />

            <ValidatedInput2
              textSize="text-sm"
              label="Phone Number"
              type="number"
              placeholder="+1 234 567 890"
              name="phone_number"
            />
            <LoadingBtn
              loading={isLoading}
              fullWidth
              sx={{ mt: "40px" }}
              type="submit"
            >
              Send Credential
            </LoadingBtn>
          </form>
        </FormProvider>
      </Modal>

      <MessageModal
        openModal={openSend}
        icon={<SendIcon />}
        closeModal={() => setOpenSend(false)}
        iconBg="bg-[#FDF2E6]"
        title="Credential Sent"
        buttonTitle="Close"
        description="The credential has been sent to the user's email"
      />
    </>
  );
}

export default AddNewUserModal;
