import React from "react";

function SendIcon(props) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.9981 32L51.9981 3.99997M24.3383 32.8748L31.3466 50.8962C31.964 52.4837 32.2727 53.2775 32.7175 53.5093C33.1031 53.7102 33.5624 53.7105 33.9483 53.51C34.3933 53.2788 34.703 52.4854 35.3222 50.8985L52.8965 5.8645C53.4555 4.43202 53.735 3.71578 53.5821 3.2581C53.4493 2.86064 53.1374 2.54873 52.7399 2.41595C52.2823 2.26305 51.566 2.54256 50.1336 3.10157L5.09956 20.6758C3.51269 21.2951 2.71926 21.6047 2.48803 22.0498C2.28759 22.4356 2.28786 22.895 2.48876 23.2806C2.72051 23.7254 3.5143 24.0341 5.10189 24.6515L23.1232 31.6598C23.4455 31.7851 23.6066 31.8477 23.7423 31.9445C23.8626 32.0303 23.9677 32.1355 24.0535 32.2557C24.1503 32.3914 24.213 32.5525 24.3383 32.8748Z"
        stroke={props.color || "#E98100"}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SendIcon;
