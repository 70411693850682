import { useState } from "react";
import { Avatar, Button } from "@mui/material";
import PlugIcon from "components/Vectors/PlugIcon";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import StarIcon from "@mui/icons-material/Star";
import { Link } from "react-router-dom";
// import DropDownWrapper from "components/mui-components/DropDownWrapper";
// import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
// import NotInterestedIcon from "@mui/icons-material/NotInterested";
// import BecomeAServiceProviderModal from "./Modals/BecomeAServiceProviderModal";
import ProfessionalInfoDetails from "./widgets/ProfessionalInfoDetails";
import EngagementBody from "pages/ServiceProviders/Profile/widgets/EngagementBody";
import { useGetUserRequest } from "hooks/useGetUserRequest";
import AlignBoxIcon from "components/Vectors/AlignBoxIcon";
import User_2 from "components/Vectors/User_2";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";
import EditIcon2 from "components/Vectors/EditIcon2";
import BlockedUsersModal from "pages/ServiceProviders/Profile/Modals/BlockedUsersModal";
import OnboardingModal from "components/mui-components/Modal/OnboardingModal";

const ClientEngagementData = [
  {
    icon: User_2,
    text: "All Engagements",
    valueKey: "all_engagements_count",
    buttonText: "View Engagements",
    link: "/client/engagements",
  },
  {
    icon: User_2,
    text: "Ongoing Engagements",
    valueKey: "ongoing_engagements_count",
    buttonText: "View Engagements",
    link: "/client/engagements?tab=ongoing",
  },
  {
    icon: User_2,
    text: "Completed Engagements",
    valueKey: "completed_engagements_count",
    buttonText: "View Engagements",
    link: "/client/engagements?tab=completed",
  },
  {
    icon: AlignBoxIcon,
    text: "All Jobs",
    valueKey: "jobs_count",
    buttonText: "View Jobs",
    link: "/client/jobs",
  },
];

function ClientProfile() {
  const [openBecomeServiceProvider, setopenBecomeServiceProvider] =
    useState(false);
  const [openBlockUsersModal, setopenBlockUsersModal] = useState(false);
  const { data, isLoading, error } = useGetUserRequest();
  const user = data?.data;


  if (isLoading) return <CircularLoader />;
  if (error) return <ErrorMsg error={error} />;
  return (
    <div>
      <h1 className="mb-5 font-Bai text-2xl font-semibold text-grey_20 md:text-4xl">
        My Profile
      </h1>
      <section className="mb-10 rounded-20 bg-white p-5 md:p-8">
        <div className="items flex w-full flex-wrap justify-between">
          <div className="flex gap-4">
            <Avatar
              src={user?.avatar}
              alt="profile"
              sx={{
                height: "56px",
                width: "56px",
                "@media (min-width: 780px)": {
                  width: "120px",
                  height: "120px",
                },
              }}
            />
            <div className="w-full md:mt-3">
              <h3 className="flex items-center gap-2 text-xl font-semibold text-black md:mb-2 md:text-3xl">
                {user?.full_name}
              </h3>
              <p className="text-sm font-medium text-grey_20 md:text-lg">
                @{user?.username}
              </p>
            </div>
          </div>
          <div className="mt-0 flex h-12 items-stretch gap-2 md:mt-3 md:h-14 md:w-auto">
            <Button
              variant="contained"
              component={Link}
              to="/client/settings"
              className="w-[40px] !min-w-[unset] md:!min-w-[170px]"
            >
              <span className="hidden md:inline"> Edit Profile</span>
              <span className="inline md:hidden">
                <EditIcon2 color="#fff" />
              </span>
            </Button>

            {/* <DropDownWrapper
              contentPadding="p-[0]"
              position="right"
              border="border border-[#F2F4F7] "
              action={
                <button className="flex h-full w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F2F4F7] md:w-[60px]">
                  <MoreHorizRoundedIcon
                    sx={{
                      fontSize: "16px",
                      color: "#e98100",
                    }}
                  />
                </button>
              }
            >
              <div>
                <Button
                  variant="text"
                  sx={{
                    color: "#6E726E",
                  }}
                  startIcon={
                    <NotInterestedIcon
                      sx={{ fontSize: "16px", color: "#6E726E" }}
                    />
                  }
                  onClick={() => setopenBlockUsersModal(true)}
                >
                  View blocked users
                </Button>
              </div>
            </DropDownWrapper> */}
          </div>
        </div>

        <div className="mx-auto my-10 w-full max-w-[950px]">
          <div className="flex items-center justify-between pb-16 pt-3">
            <div className="flex flex-wrap gap-10 ">
              <div className="flex gap-3">
                <div className="flex h-[43px] w-[43px] items-center justify-center rounded-full bg-secondary-10 md:h-[48px] md:w-[48px]">
                  <StarIcon color="white" />
                </div>
                <div>
                  <h3 className="font-Bai text-2xl font-medium text-black md:text-3xl">
                    {user?.average_rating?.toFixed(1)}
                  </h3>
                  <p className="text-sm font-normal text-grey_30">Rating</p>
                </div>
              </div>
              <div className="flex gap-3">
                <div className="flex h-[43px] w-[43px] items-center justify-center rounded-full bg-secondary-10 md:h-[48px] md:w-[48px]">
                  <ChatBubbleIcon color="white" />
                </div>
                <div>
                  <h3 className="font-Bai text-2xl font-medium text-black md:text-3xl">
                    {user?.ratings_received_count}
                  </h3>
                  <p className="text-sm font-normal text-grey_30">Reviews</p>
                </div>
              </div>
            </div>

            {user?.is_verified_account && (user?.is_hybrid !== "Yes" || !user?.kyc) &&
              <Button
                variant="contained"
                color="secondaryInverse2"
                sx={{
                  fontSize: "16px",
                  // display: user?.is_hybrid === "Yes" ? "none" : "",
                }}
                onClick={() => setopenBecomeServiceProvider(true)}
              >
                Become a Service Provider
              </Button>
            }
          </div>
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
            <ProfessionalInfoDetails user={user} />
            <div className="space-y-6">
              {!user.kyc && user?.kyc.status === "Approved" && (
                <div className="rounded-24 border-[3px] border-dashed border-primary p-6">
                  <div className="mb-5 flex items-center gap-3">
                    <div className="flex items-center justify-center rounded-full bg-primary p-3">
                      <PlugIcon color="white" />{" "}
                    </div>
                    <div>
                      <p className="mb-1 text-xl font-medium">
                        Update KYC to be eligible
                      </p>
                      <p className="font-Mulish text-[#000000CC]">
                        Upload your KYC information
                      </p>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    color="greyBorder"
                    component={Link}
                    to="/client/settings?tab=kyc"
                    sx={{ color: "#BA6700" }}
                  >
                    Upload KYC
                  </Button>
                </div>
              )}
              <EngagementBody user={user} list={ClientEngagementData} />
            </div>
          </div>
        </div>
      </section>

      <OnboardingModal
        close={() => setopenBecomeServiceProvider(false)}
        open={openBecomeServiceProvider}
      />
      <BlockedUsersModal
        openModal={openBlockUsersModal}
        setopenModal={setopenBlockUsersModal}
      />
    </div>
  );
}

export default ClientProfile;
