const DeleteIcon = props => {
  return (
    <>
      <svg
        width="53"
        height="54"
        viewBox="0 0 53 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.375 2.625H34.625M2.125 10.75H50.875M45.4583 10.75L43.559 39.2397C43.2741 43.5142 43.1316 45.6514 42.2084 47.2719C41.3956 48.6986 40.1696 49.8456 38.692 50.5616C37.0137 51.375 34.8717 51.375 30.5878 51.375H22.4122C18.1283 51.375 15.9863 51.375 14.308 50.5616C12.8304 49.8456 11.6044 48.6986 10.7916 47.2719C9.86842 45.6514 9.72594 43.5142 9.44098 39.2397L7.54167 10.75"
          stroke={props.stroke || "#F04438"}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default DeleteIcon;
