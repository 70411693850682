import Button from "@mui/material/Button";
import {useEffect} from "react";
import {useNavigate, Link} from "react-router-dom";

import {ReactComponent as Logo} from "assets/images/logo.svg";
import {ReactComponent as AcctCreated} from "assets/icons/AccountCreated.svg";
import CreateAcct from "assets/images/CreateAcct.png";

const UserAccountCreated = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/client");
    }, 4500);
    console.log("hello");
  }, [navigate]);

  return (
    <div
      className="mx-auto flex min-h-screen flex-col items-center gap-y-[111.56px] bg-cover bg-center bg-no-repeat px-6 py-[60px] md:px-0"
      style={{background: `url(${CreateAcct})`}}
    >
      <div>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="flex h-[436px] items-center justify-center rounded-[24px] border bg-white py-16 md:w-[622px] md:px-[138px]">
        <div className="mx flex flex-col items-center">
          <AcctCreated />
          <p className="my-4 font-Bai text-2xl font-bold">Account Created</p>
          <p className="mb-[26px] text-center">
            Welcome to Truelocal! Either you’re here to get a task done or here
            to make money with your skill.
          </p>
          <Button
            variant="contained"
            onClick={() => navigate("/client")}
            fullWidth
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  );
};
export default UserAccountCreated;
