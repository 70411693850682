import TicketLists from "components/TicketLists";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";

const TicketsTab = ({ data, isLoading, isError, error, action }) => {
  if (isLoading) return <CircularLoader />;
  if (isError) return <ErrorMsg error={error} />;
  return <TicketLists tickets={data} action={action} />;
};

export default TicketsTab;
