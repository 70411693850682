const Calendar2Icon = ({color, ...props}) => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M5.36643 4.1443C5.10312 4.1443 4.88477 3.92547 4.88477 3.66159V1.73076C4.88477 1.46687 5.10312 1.24805 5.36643 1.24805C5.62974 1.24805 5.84809 1.46687 5.84809 1.73076V3.66159C5.84809 3.92547 5.62974 4.1443 5.36643 4.1443Z"
          fill={color || "#E98100"}
        />
        <path
          d="M10.5031 4.1443C10.2398 4.1443 10.0215 3.92547 10.0215 3.66159V1.73076C10.0215 1.46687 10.2398 1.24805 10.5031 1.24805C10.7665 1.24805 10.9848 1.46687 10.9848 1.73076V3.66159C10.9848 3.92547 10.7665 4.1443 10.5031 4.1443Z"
          fill={color || "#E98100"}
        />
        <path
          d="M13.3954 6.77645H2.47776C2.21445 6.77645 1.99609 6.55762 1.99609 6.29374C1.99609 6.02986 2.21445 5.81104 2.47776 5.81104H13.3954C13.6587 5.81104 13.8771 6.02986 13.8771 6.29374C13.8771 6.55762 13.6587 6.77645 13.3954 6.77645Z"
          fill={color || "#E98100"}
        />
        <path
          d="M10.5043 15.0856H5.36657C3.02248 15.0856 1.67383 13.734 1.67383 11.3848V5.91415C1.67383 3.56496 3.02248 2.21338 5.36657 2.21338H10.5043C12.8484 2.21338 14.197 3.56496 14.197 5.91415V11.3848C14.197 13.734 12.8484 15.0856 10.5043 15.0856ZM5.36657 3.1788C3.52983 3.1788 2.63715 4.07342 2.63715 5.91415V11.3848C2.63715 13.2256 3.52983 14.1202 5.36657 14.1202H10.5043C12.341 14.1202 13.2337 13.2256 13.2337 11.3848V5.91415C13.2337 4.07342 12.341 3.1788 10.5043 3.1788H5.36657Z"
          fill={color || "#E98100"}
        />
        <path
          d="M5.68714 9.776C5.60365 9.776 5.52016 9.7567 5.44309 9.72452C5.36603 9.69234 5.29539 9.64727 5.23117 9.58935C5.17337 9.52499 5.1284 9.4542 5.09629 9.37696C5.06418 9.29973 5.04492 9.21606 5.04492 9.13239C5.04492 8.96505 5.11557 8.79772 5.23117 8.67543C5.29539 8.6175 5.36603 8.57244 5.44309 8.54026C5.55869 8.48877 5.68715 8.4759 5.81559 8.50164C5.85412 8.50808 5.89265 8.52095 5.93118 8.54026C5.96972 8.55313 6.00824 8.57246 6.04678 8.5982C6.07889 8.62394 6.11099 8.64969 6.14311 8.67543C6.16879 8.70761 6.2009 8.73979 6.22017 8.77197C6.24586 8.81058 6.26514 8.8492 6.27799 8.88781C6.29725 8.92643 6.31009 8.96504 6.31652 9.00366C6.32294 9.04871 6.32935 9.08734 6.32935 9.13239C6.32935 9.29973 6.2587 9.46706 6.14311 9.58935C6.02108 9.7052 5.85411 9.776 5.68714 9.776Z"
          fill={color || "#E98100"}
        />
        <path
          d="M7.93518 9.77577C7.76821 9.77577 7.60124 9.70497 7.47922 9.58912C7.45353 9.55694 7.42784 9.52476 7.40215 9.49258C7.37647 9.45396 7.35718 9.41535 7.34434 9.37674C7.32507 9.33812 7.31223 9.29951 7.30581 9.26089C7.29938 9.21584 7.29297 9.17721 7.29297 9.13216C7.29297 9.04849 7.31223 8.96482 7.34434 8.88758C7.37645 8.81035 7.42142 8.73956 7.47922 8.6752C7.65904 8.49499 7.94803 8.43705 8.17923 8.54003C8.26272 8.57221 8.32693 8.61728 8.39115 8.6752C8.50675 8.79749 8.5774 8.96482 8.5774 9.13216C8.5774 9.17721 8.57099 9.21584 8.56456 9.26089C8.55814 9.29951 8.5453 9.33812 8.52603 9.37674C8.51319 9.41535 8.4939 9.45396 8.46821 9.49258C8.44253 9.52476 8.41684 9.55694 8.39115 9.58912C8.32693 9.64704 8.26272 9.69211 8.17923 9.72429C8.10216 9.75647 8.01867 9.77577 7.93518 9.77577Z"
          fill={color || "#E98100"}
        />
        <path
          d="M5.68714 12.0282C5.60365 12.0282 5.52016 12.0089 5.44309 11.9767C5.36603 11.9446 5.29539 11.8995 5.23117 11.8416C5.17337 11.7772 5.1284 11.7128 5.09629 11.6292C5.06418 11.5519 5.04492 11.4683 5.04492 11.3846C5.04492 11.2173 5.11557 11.0499 5.23117 10.9276C5.29539 10.8697 5.36603 10.8246 5.44309 10.7925C5.68071 10.6895 5.96329 10.7474 6.14311 10.9276C6.16879 10.9598 6.2009 10.992 6.22017 11.0242C6.24586 11.0628 6.26514 11.1014 6.27799 11.14C6.29725 11.1786 6.31009 11.2173 6.31652 11.2623C6.32294 11.3009 6.32935 11.346 6.32935 11.3846C6.32935 11.5519 6.2587 11.7193 6.14311 11.8416C6.02108 11.9574 5.85411 12.0282 5.68714 12.0282Z"
          fill={color || "#E98100"}
        />
      </svg>
    </>
  );
};

export default Calendar2Icon;
