const CategoryInfo = ({ icon, title, content, minWidth, info }) => {
  return (
    <div className={`rounded-20 bg-[#F2F4F7] p-6 ${minWidth ? minWidth : ""}`}>
      <div className="flex items-center justify-between">
        <p className="text-prop text-base font-medium">{title}</p>
        <span>{icon}</span>
      </div>
      <h4 className="mt-[25px] text-lg font-bold text-ltext">{content}</h4>
      <h4 className="text-[13px] font-medium text-grey_10">{info}</h4>
    </div>
  );
};
export default CategoryInfo;
