import {LoadingButton} from "@mui/lab";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import MyModal from "components/mui-components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import {useTerminateEngagementMutation} from "services/user/userRegApi";
import {handleError} from "utils";
import {useTerminateAdminEngagementMutation} from "services/admin/adminGeneralApi";

const TerminateEngagementModal = ({
  openModal,
  closeModal,
  engagement_id,
  client_id,
  isAdmin = false,
}) => {
  const method = useForm();
  const [terminateEng, {isLoading}] = useTerminateEngagementMutation();
  const [terminateAdminEng, {isLoading: isAdminLoading}] =
    useTerminateAdminEngagementMutation();

  const onSubmit = async body => {
    if (isAdmin) {
      try {
        await terminateAdminEng({
          reason_for_termination: body?.message,
          engagement_id,
          client_id,
        }).unwrap();
        closeModal();
        method.reset();
      } catch (error) {
        handleError(error);
      }
    } else {
      try {
        await terminateEng({
          message: body?.message,
          engagement_id,
        }).unwrap();
        closeModal();
      } catch (error) {
        handleError(error);
      } finally {
        closeModal();
      }
    }
  };

  return (
    <>
      <MyModal
        openModal={openModal}
        closeModal={closeModal}
        maxWidth={575}
        className="!min-h-[unset]"
        title={"Terminate Engagement"}
      >
        <div>
          <div className="mb-7 flex flex-col gap-3 rounded-2xl bg-[#F3ECFD] px-2.5 py-3">
            <div className="flex items-center gap-x-2">
              <div className="flex h-11 w-11 items-center justify-center rounded-full bg-white">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0001 4.99935C10.4603 4.99935 10.8334 5.37245 10.8334 5.83268V10.8327C10.8334 11.2929 10.4603 11.666 10.0001 11.666C9.53984 11.666 9.16675 11.2929 9.16675 10.8327V5.83268C9.16675 5.37245 9.53984 4.99935 10.0001 4.99935Z"
                    fill="#440E98"
                  />
                  <path
                    d="M8.95842 13.9577C8.95842 14.533 9.42478 14.9993 10.0001 14.9993C10.5754 14.9993 11.0417 14.533 11.0417 13.9577C11.0417 13.3824 10.5754 12.916 10.0001 12.916C9.42478 12.916 8.95842 13.3824 8.95842 13.9577Z"
                    fill="#440E98"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935ZM10.0001 3.33268C6.31818 3.33268 3.33341 6.31745 3.33341 9.99935C3.33341 13.6812 6.31818 16.666 10.0001 16.666C13.682 16.666 16.6667 13.6812 16.6667 9.99935C16.6667 6.31745 13.682 3.33268 10.0001 3.33268Z"
                    fill="#440E98"
                  />
                </svg>
              </div>
              <span className="font-Mulish text-sm font-medium text-[#1C063F]">
                You will be charged 12 tokens to make this offer
              </span>
            </div>
            <div className="flex w-fit items-center gap-1 rounded-[200px] border border-[#C9C9CF] bg-white px-3 py-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.8837 2.8624C9.5775 2.04586 8.4225 2.04587 8.1163 2.86241L6.83405 6.28174C6.73834 6.53696 6.53696 6.73834 6.28174 6.83405L2.8624 8.1163C2.04586 8.4225 2.04587 9.5775 2.86241 9.8837L6.28174 11.1659C6.53696 11.2617 6.73834 11.463 6.83405 11.7183L8.1163 15.1376C8.4225 15.9541 9.5775 15.9541 9.8837 15.1376L11.1659 11.7183C11.2617 11.463 11.463 11.2617 11.7183 11.1659L15.1376 9.8837C15.9541 9.5775 15.9541 8.4225 15.1376 8.1163L11.7183 6.83405C11.463 6.73834 11.2617 6.53696 11.1659 6.28174L9.8837 2.8624Z"
                  fill="#E98100"
                />
              </svg>
              <span className="font-Mulish text-sm font-medium text-[#69697B]">
                Token Balance
              </span>
              <span className="font-Mulish text-sm font-semibold text-[#151519]">
                2,019
              </span>
            </div>
          </div>
          <FormProvider {...method}>
            <form
              className="flex-grow-2 flex flex-col gap-y-10"
              onSubmit={method.handleSubmit(onSubmit)}
            >
              <div className="space-y-5">
                <ValidatedTextArea
                  className="flex"
                  name="message"
                  label="Write a message"
                  bg="grey_80"
                  placeholder="Type something"
                />
              </div>
              <LoadingButton
                variant="contained"
                sx={{
                  width: "fit-content",
                  mt: "20px",
                }}
                loading={isLoading || isAdminLoading}
                color={"error"}
                type="submit"
              >
                Cancel Engagment
              </LoadingButton>
            </form>
          </FormProvider>
        </div>
      </MyModal>
    </>
  );
};

export default TerminateEngagementModal;
