import {useEffect, useRef, useState} from "react";
import {useCountDown} from "hooks/countDowHook";
import {countDownTimer} from "utils";

function Timer({time, onTimeEnd = () => {}, className, showSec = true}) {
  const [timer, setTimer] = useState(countDownTimer(time));
  const counterRef = useRef(countDownTimer(time));
  useEffect(() => {
    counterRef.current = timer;
  });
  const intervalRef = useCountDown(() => {
    if (timer !== null) {
      setTimer(countDownTimer(time));
      onTimeEnd(false);
    } else {
      setTimer(0);
      onTimeEnd(true);
      window.clearInterval(intervalRef.current);
    }
  }, 1000);

  return (
    <p className={className}>
      <span>{timer?.days || "00"}</span>
      <span>:</span>
      <span>{timer?.hours || "00"}</span>
      <span>:</span>
      <span>{timer?.minutes || "00"}</span>
      {showSec && (
        <>
          <span className="sec">:</span>
          <span className="sec">{timer?.seconds || "00"}</span>
        </>
      )}
    </p>
  );
}

export default Timer;
