import React from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Button, Tab} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import BriefcaseIcon from "components/Vectors/briefcase";
import AddService from "../AddServiceWidgets";
import {useGetAllSPServicesQuery} from "services/user/providerGeneralApi";
import CircularLoader from "components/Loader/CircularLoader";
import ErrorMsg from "components/mui-components/ErrorMsg";
import ServiceDetailsTab from "./ServiceDetailsTab";
import {AddCircleOutline} from "@mui/icons-material";
import OnboardingServiceModal from "../AddServiceWidgets/OnboardingServiceModal";
import {convertToSlug} from "utils";

function ServicesTab() {
  const [activeTab, setactiveTab] = useState("services");
  const [openAddServicesModal, setOpenAddServicesModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const {data, isLoading, error} = useGetAllSPServicesQuery();
  const services = data?.data;
  const navigate = useNavigate();

  useEffect(() => {
    if (services) {
      setactiveTab(convertToSlug(services[0]?.category.name));
    }
  }, [services]);

  if (isLoading) return <CircularLoader />;
  if (error) return <ErrorMsg error={error} />;

  return (
    <div className="mt-7 flex flex-col gap-8 divide-x-0 bg-white md:flex-row md:gap-0">
      <TabContext value={activeTab}>
        <TabList
          variant="scrollable"
          orientation="vertical"
          value={activeTab}
          className="top-0 h-fit max-h-screen w-full min-w-[330px] p-4 md:sticky md:max-w-[330px]"
          onChange={(e, newValue) => {
            navigate(`?tab=${newValue}`);
            setactiveTab(newValue);
          }}
          sx={{
            textTransform: "inherit",
            background: "white !important",
            padding: "1rem !important",
            "& .MuiTabs-indicator": {
              left: "0 !important",
            },
            button: {
              my: "14px !important",
              alignItems: "flex-start",
            },
            ".MuiTab-root": {
              "&.Mui-selected": {
                color: "#693A00",
                background: "#F2F4F7 !important",
                padding: "20px !important",
                border: "1px solid #E4E7EC",
                borderRadius: "14px",
              },
            },
            fontWeight: 600,
          }}
          aria-label="basic tabs example"
        >
          <h1 className="mb-2 font-Bai text-[32px] font-semibold text-grey">
            Services
          </h1>
          {services?.map(el => (
            <Tab
              key={`service_tab_${el.id}`}
              label={el?.category?.name}
              value={convertToSlug(el?.category?.name)}
            />
          ))}
          <Button
            sx={{width: "fit-content", fontSize: "16px"}}
            variant="outlined"
            startIcon={<AddCircleOutline />}
            onClick={() => setOpenAddServicesModal(true)}
          >
            Add Service
          </Button>
        </TabList>
        <div className="h-full flex-grow md:pl-8">
          {services?.length > 0 ? (
            services?.map(el => (
              <TabPanel
                key={`service_panel_${el.id}`}
                value={convertToSlug(el?.category?.name)}
              >
                <ServiceDetailsTab service={el} />
              </TabPanel>
            ))
          ) : (
            <div className="flex flex-col  items-center justify-center gap-[40px] rounded-20 bg-grey_80 py-20">
              <div className="flex h-[110px] w-[110px] items-center justify-center rounded-full bg-[#FDF2E6]">
                <BriefcaseIcon stroke={"#e98100"} width="64" height="64" />
              </div>
              <div className="w-[90%] max-w-[363px] text-center">
                <p className="font-Bai text-xl font-bold text-[#362E1F] md:text-[32px]">
                  Build up your
                  <br />
                  services and expertise
                </p>
                <p className="font-semibol mt-4 text-sm text-grey_30">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna,
                  odio viverra aliquet libero,
                </p>
              </div>
              <div className="w-[90%] max-w-[363px]">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setOpenModal(true)}
                >
                  Add a Service
                </Button>
              </div>
            </div>
          )}
        </div>
      </TabContext>
      <OnboardingServiceModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setIsFinished={setOpenAddServicesModal}
      />
      <AddService
        openModal={openAddServicesModal}
        setOpenModal={setOpenAddServicesModal}
      />
    </div>
  );
}

export default ServicesTab;
