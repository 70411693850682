import SearchInput from "components/forms/SearchInput";
import JobRequestsIcon from "components/Vectors/JobRequestsIcon";
import SearchIcon from "components/Vectors/SearchIcon";
import {useEffect, useState} from "react";
import DashboardLayout from "templates/DashboardLayout/DashboardLayout";
import RequestTab from "./Components/RequestTab";
import RequestTable from "./Components/RequestTable";
import OverviewCard from "components/OverviewCard";

const AdminJobRequest = () => {
  const [emptyState, setEmptyState] = useState(true);
  const showTable = false;

  useEffect(() => {
    setTimeout(() => setEmptyState(false), 5000);
  }, [emptyState]);

  return (
    <DashboardLayout title={"Job Offers"} type="admin">
      <section>
        <section className="rounded-2xl border border-[#E4E7EC] bg-white p-4 md:py-6 ">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="font-Bai text-2xl font-medium text-black">
              Overview
            </h3>
          </div>

          <div className=" grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
            <OverviewCard
              title={"All Offers"}
              icon={
                <JobRequestsIcon height="24" width="24" stroke={"#E98100"} />
              }
              value={emptyState ? "0" : "10"}
            />
            <OverviewCard
              title={"Pending Offers"}
              icon={
                <JobRequestsIcon height="24" width="24" stroke={"#6E726E"} />
              }
              value={emptyState ? "0" : "10"}
            />
            <OverviewCard
              title={"Withdrawn"}
              icon={
                <JobRequestsIcon height="24" width="24" stroke={"#D92D20"} />
              }
              value={emptyState ? "0" : "10"}
            />
            <OverviewCard
              title={"Declined"}
              icon={
                <JobRequestsIcon height="24" width="24" stroke={"#D92D20"} />
              }
              value={emptyState ? "0" : "10"}
            />
          </div>
        </section>

        <section className="mt-6 rounded-2xl bg-white p-4 md:py-6 ">
          <div className="flex items-center justify-between  ">
            <SearchInput
              icon={<SearchIcon height="18" width="18" />}
              textSize="text-base"
              placeholder="Search"
            />
          </div>

          {/* contents  */}
          <div className="mt-8">
            {showTable ? (
              <RequestTable data={!emptyState} />
            ) : (
              <RequestTab data={!emptyState} />
            )}
          </div>
        </section>
      </section>
    </DashboardLayout>
  );
};
export default AdminJobRequest;
